import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { tagsFormatter } from '@utils/formatters';

interface TagsCellProps {
  tags: string[];
}
const TagsCell: React.FC<TagsCellProps> = ({ tags }) => {
  if (!tags) {
    return null;
  }
  return (
    <SCategoriesCell>
      {tags.map((info, index) => {
        const tag = tagsFormatter(info);
        if (tag) {
          return (
            <Tag key={index} color={tag.color}>
              {tag.text}
            </Tag>
          );
        } else {
          return null;
        }
      })}
    </SCategoriesCell>
  );
};

export default TagsCell;

const SCategoriesCell = styled.div`
  display: flex;
  height: 100%;
  align-content: center;
  flex-wrap: wrap;
  margin-right: 10px;
  padding: 4px;
  gap: 6px 0px;
`;
