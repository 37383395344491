import { red } from '@ant-design/colors';
import {
  BarcodeOutlined,
  CarOutlined,
  DashboardOutlined,
  LogoutOutlined,
  MessageOutlined,
  UserOutlined,
  InfoCircleFilled,
  ExportOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoreOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { gql, useApolloClient } from '@apollo/client';
import {
  Avatar,
  Button,
  Dropdown,
  Menu,
  Tooltip,
  notification,
  PageHeader,
  Popconfirm,
  Alert,
  Space,
  Tag,
  Typography,
  Modal,
} from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';

import { CounselInfoContainerProps } from './CounselInfoContainer';
import { CounselInfoError } from './CounselInfoError';
import { CounselInfoLoadingSkeleton } from './CounselInfoLoadingSkeleton';
import Profile from './Profile';

import TireBlurred from '@assets/icon/tire/blurred.png';
import TireFocused from '@assets/icon/tire/focused.png';
import { useMe, useMessage } from '@hooks';
import { colors } from '@styles';
import {
  CounselStatus,
  TireSize,
  useCounselInfoQuery,
  useCounselorsQuery,
} from '@utils/client';
import { COUNSEL_STATE } from '@utils/constants';
import {
  messageStringifier,
  dateFormatter,
  contactFormatter,
  fuelTypeStringifer,
} from '@utils/formatters';
import { COUNSEL_ROOM_MESSAGE } from '@utils/fragments';

const { Text } = Typography;

gql`
  query counselors {
    counselors {
      id
      nickname
      name
      avatar {
        url
      }
    }
  }
  query counselInfo($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      index
      status
      bookmarkedUntil
      counselee {
        id
        avatar {
          url
        }
        age
        gender
        nickname
        phone
        lastDevice {
          brand
          model
          os
          osVersion
          appVersion
        }
      }

      members {
        id
        nickname
        avatar {
          url
        }
      }
      body
      files {
        url
        description
      }
      type {
        id
        name
      }
      areas {
        radius
        zone {
          province
          district
          neighborhood
        }
      }
      vehicle {
        id
        avatar {
          url
        }
        registeredYear
        mileage
        plateNumber
        fuelType {
          base
          hybrid
        }
        fullModelName
        grade {
          displacement
        }

        tireSize {
          front {
            aspectRatio
            wheelDiameter
            width
          }
          rear {
            aspectRatio
            wheelDiameter
            width
          }
        }
        vin
      }
    }
  }
`;

const COUNSEL_MESSAGES = gql`
  ${COUNSEL_ROOM_MESSAGE}
  query counselMessages($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      messageConnection {
        edges {
          node {
            ...counselRoomMessage
          }
        }
      }
    }
  }
`;

type InvitePopover = {
  visible: boolean;
  invitee?: { id: string; nickname?: string | null };
};
interface CounselInfoPresenterProps extends CounselInfoContainerProps {
  invite: (counselorId: string) => Promise<void>;
  leave: () => Promise<void>;
  showTaskPad: boolean;
  toggleTaskPad: () => void;
}
const CounselInfoPresenter: React.FC<CounselInfoPresenterProps> = ({
  className,
  counselId,
  invite,
  leave,
  showTaskPad,
  toggleTaskPad,
}) => {
  const client = useApolloClient();
  const { viewOnly, id } = useMe();
  const {
    sendAreaInputMessage,
    sendPlateNumberInputMessage,
    sendVinInputMessage,
  } = useMessage({
    counselId,
  });

  const { loading, data, error } = useCounselInfoQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { counselId },
  });

  const { data: counselorsData } = useCounselorsQuery();

  const [invitePopover, setInvitePopover] = React.useState<InvitePopover>({
    visible: false,
  });
  const [leavePopoverVisible, setLeavePopoverVisible] = React.useState(false);
  const [messages, setMessages] = React.useState<string[][]>([]);
  const csvRef = React.useRef<any | null>(null);

  const disabled =
    viewOnly ||
    data?.counsel.status === CounselStatus.Finished ||
    data?.counsel.status === CounselStatus.Pending;

  const inviteCounselor = async (counselorId: string) => {
    setInvitePopover({ visible: false });
    try {
      await invite(counselorId);
      notification.success({ message: '채팅사 초대 완료' });
    } catch (e) {
      notification.error({
        message: '채팅사 초대 실패',
      });
    }
  };

  const leaveChat = async () => {
    try {
      await leave();
      window.close();
    } catch (e) {
      notification.error({ message: '퇴장 실패' });
    }
  };

  const confirmInvite = (invitee: { id: string; nickname?: string | null }) => {
    setInvitePopover({ visible: true, invitee });
  };

  const handleClickVin = () => {
    if (!data) {
      return;
    }

    if (disabled) {
      return;
    }

    if (!data.counsel.vehicle) {
      return;
    }

    if (data.counsel.vehicle.vin) {
      return;
    }

    Modal.confirm({
      title: '차대번호 입력을 요청하시겠습니까?',
      onOk: () => {
        if (data.counsel.vehicle) {
          sendVinInputMessage(data.counsel.id, data.counsel.vehicle.id);
        }
      },
    });
  };

  const tireSizeFormatter = (tireSize: TireSize | undefined) => {
    if (!tireSize) {
      return '-';
    }

    return `${tireSize.width} ${tireSize.aspectRatio}R ${tireSize.wheelDiameter}`;
  };

  const handleExportedMessages = async () => {
    const result: any = await client.query({
      query: COUNSEL_MESSAGES,
      variables: {
        counselId,
      },
      fetchPolicy: 'no-cache',
    });

    if (!result.data) {
      return;
    }

    const counsel = result.data.counsel;
    const messages = counsel.messageConnection.edges.map((edge: any) => {
      const message = edge.node;
      let messageContent = messageStringifier(message);
      if (message.__typename === 'FileMessage') {
        messageContent = message?.file?.url;
      }
      return [
        message.id,
        dateFormatter(message.createdAt),
        message.author ? message.author.nickname : 'unknown',
        messageContent,
      ];
    });

    setMessages([['id', '전송날짜', '보낸 사람', '내용'], ...messages]);
  };

  React.useEffect(() => {
    if (id && data && !data.counsel.members.find(member => member.id === id)) {
      Modal.warning({
        title: '내가 참여중인 채팅이 아닙니다.',
        content:
          '참여 및 대화를 원하신다면 초대를 받거나, 스스로를 이 채팅방에 초대하세요',
      });
    }
  }, [id, data]);

  React.useEffect(() => {
    setTimeout(() => {
      if (messages && csvRef && csvRef.current && csvRef.current.link.click) {
        csvRef.current.link.click();
        setMessages([]);
      }
    });
  }, [messages]);

  const menu = (
    <Menu>
      {data && (
        <>
          <SMenuHeader>
            <MessageOutlined /> #{data.counsel.index}{' '}
            {data.counsel.counselee?.nickname || '(알 수 없음)'}
            님과의 채팅
          </SMenuHeader>
          <Menu.ItemGroup key="invite" title="채팅 매니저 초대">
            {counselorsData?.counselors.map(member => {
              // counselor 목록을 따로 로드해와서 수정해야함
              const participating = !!data.counsel.members.find(
                user => user.id === member.id,
              );
              return (
                <Menu.Item
                  key={member.id}
                  icon={
                    <Avatar
                      size={'small'}
                      src={member.avatar?.url}
                      icon={<UserOutlined />}
                    />
                  }
                  disabled={participating}
                  onClick={() => confirmInvite(member)}
                >
                  {member.nickname || '(알 수 없음)'}{' '}
                  {member.name ? `(${member.name}) ` : ''}
                  {participating && '(참여중)'}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>
          <Menu.Item key="export" icon={<ExportOutlined />}>
            {messages.length > 0 ? (
              <CSVLink
                data={messages}
                filename={`채팅내용-${counselId}`}
                ref={csvRef}
              >
                채팅 내용 내보내기
              </CSVLink>
            ) : (
              <span onClick={handleExportedMessages}>채팅 내용 내보내기</span>
            )}
          </Menu.Item>
          <Menu.Item
            key="exit"
            disabled={data.counsel.members.length < 2}
            icon={<LogoutOutlined />}
            onClick={() => setLeavePopoverVisible(true)}
            style={{ color: red.primary }}
          >
            이 채팅 나가기
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const NO_DEVICE_INFO = '정보 없음';

  const deviceInfo = React.useMemo(() => {
    if (!data || !data.counsel.counselee?.lastDevice) {
      return NO_DEVICE_INFO;
    }
    const device = data.counsel.counselee.lastDevice;

    if (!device.brand && !device.model && !device.os && !device.osVersion) {
      return NO_DEVICE_INFO;
    }

    const brand = device.brand ? `${device.brand} / ` : '';
    const model = device.model ? `${device.model} / ` : '';
    const os = device.os ? `${device.os} / ` : '';
    const version = device.osVersion ? `${device.osVersion} / ` : '';
    const appVersion = device.appVersion ? `${device.appVersion}` : '';

    return `📱 ${brand}${model}${os}${version}${appVersion}`;
  }, [data]);

  if (loading) return <CounselInfoLoadingSkeleton className={className} />;
  if (error) return <CounselInfoError className={className} error={error} />;
  if (data) {
    const active =
      data.counsel.status !== CounselStatus.Pending &&
      data.counsel.status !== CounselStatus.Finished;

    let modelName = data.counsel.vehicle?.fullModelName;
    if (data.counsel.vehicle?.fuelType) {
      modelName += ` (${fuelTypeStringifer(data.counsel.vehicle.fuelType)})`;
    }

    return (
      <PageHeader
        className={className}
        title={'#' + data.counsel.index}
        subTitle={
          <div>
            <Tag>{COUNSEL_STATE[data.counsel.status].title}</Tag>
          </div>
        }
        extra={
          <Space>
            {data.counsel.members.length < 1 ? (
              <Text disabled>채팅방에 채팅사가 없습니다.</Text>
            ) : (
              <Avatar.Group maxCount={4}>
                {data.counsel.members.map(user => (
                  <Tooltip
                    key={user.id}
                    title={user.nickname || '(알 수 없음)'}
                  >
                    <Avatar src={user.avatar?.url} icon={<UserOutlined />} />
                  </Tooltip>
                ))}
              </Avatar.Group>
            )}
            <Popconfirm
              trigger={invitePopover.visible ? ['click'] : []}
              visible={invitePopover.visible}
              onVisibleChange={visible =>
                setInvitePopover(invitePopover => ({
                  ...invitePopover,
                  visible,
                }))
              }
              title={
                (invitePopover.invitee?.nickname || '(알 수 없음)') +
                '를 초대하시겠습니까?'
              }
              onConfirm={() => inviteCounselor(invitePopover.invitee?.id || '')}
              okText="초대"
              cancelText="취소"
              onCancel={() => setInvitePopover({ visible: false })}
            >
              <Popconfirm
                trigger={leavePopoverVisible ? ['click'] : []}
                visible={leavePopoverVisible}
                onVisibleChange={setLeavePopoverVisible}
                title="이 채팅에서 나가시겠습니까?"
                onConfirm={leaveChat}
                onCancel={() => setLeavePopoverVisible(false)}
                okText="나가기"
                cancelText="취소"
              >
                <Dropdown
                  disabled={viewOnly}
                  overlay={menu}
                  trigger={
                    invitePopover.visible || leavePopoverVisible
                      ? []
                      : ['click']
                  }
                >
                  <Button icon={<UsergroupAddOutlined />} />
                </Dropdown>
              </Popconfirm>
            </Popconfirm>
            <Button
              icon={showTaskPad ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              onClick={toggleTaskPad}
            />
          </Space>
        }
      >
        <SBody>
          <Profile
            icon={<UserOutlined />}
            avatar={data.counsel.counselee?.avatar?.url || undefined}
            title={data.counsel.counselee?.nickname || '(알 수 없음)'}
            description={[
              data.counsel.counselee?.phone &&
                contactFormatter(data.counsel.counselee.phone),
              data.counsel.counselee?.gender,
              data.counsel.counselee?.age && `${data.counsel.counselee?.age}세`,
              data.counsel.counselee?.id,
            ]
              .filter(string => !!string)
              .join(' / ')}
          ></Profile>
          <Profile
            icon={<CarOutlined />}
            avatar={data.counsel.vehicle?.avatar?.url || undefined}
            title={modelName || '차량 정보 없음'}
            description={
              data.counsel.vehicle && (
                <>
                  {[
                    data.counsel.vehicle.registeredYear &&
                      `${data.counsel.vehicle.registeredYear}년식`,
                    data.counsel.vehicle.grade?.displacement &&
                      `${data.counsel.vehicle.grade?.displacement}CC`,
                    data.counsel.vehicle.plateNumber,
                  ]
                    .filter(string => !!string)
                    .join(' / ')}
                  <Tooltip
                    title={`주행거리: ${data.counsel.vehicle.mileage || '-'}km`}
                  >
                    <DashboardOutlined
                      style={{
                        fontSize: 14,
                        cursor: 'pointer',
                        color: data.counsel.vehicle.mileage
                          ? colors.TEXT_DEFAULT
                          : 'rgba(0,0,0,0.25)',
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={`차대번호: ${data.counsel.vehicle.vin || '-'}`}
                  >
                    <BarcodeOutlined
                      style={{
                        fontSize: 14,
                        cursor: 'pointer',
                        color: data.counsel.vehicle.vin
                          ? colors.TEXT_DEFAULT
                          : 'rgba(0,0,0,0.25)',
                      }}
                      onClick={() => handleClickVin()}
                    />
                  </Tooltip>
                  <Tooltip
                    title={() => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{`앞 타이어: ${tireSizeFormatter(
                          data?.counsel.vehicle?.tireSize?.front,
                        )}`}</span>
                        <span>{`뒤 타이어: ${tireSizeFormatter(
                          data?.counsel.vehicle?.tireSize?.rear,
                        )}`}</span>
                      </div>
                    )}
                  >
                    <img
                      src={
                        data.counsel.vehicle.tireSize?.front &&
                        data.counsel.vehicle.tireSize?.rear
                          ? TireFocused
                          : TireBlurred
                      }
                      alt="타이어"
                      style={{ cursor: 'pointer', width: 14, height: 14 }}
                    />
                  </Tooltip>
                  <Tooltip title={deviceInfo}>
                    <InfoCircleFilled
                      style={{
                        fontSize: 14,
                        cursor: 'pointer',
                        color:
                          deviceInfo !== NO_DEVICE_INFO
                            ? colors.TEXT_DEFAULT
                            : 'rgba(0,0,0,0.25)',
                      }}
                    />
                  </Tooltip>
                </>
              )
            }
          />
          {active && !data.counsel.vehicle?.plateNumber && (
            <Alert
              showIcon
              message="채팅대상 차량의 차량번호가 없습니다."
              type="info"
              action={
                <Space>
                  <Button
                    disabled={disabled}
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (data.counsel.vehicle)
                        sendPlateNumberInputMessage(data.counsel.vehicle?.id);
                    }}
                  >
                    차량번호 요청하기
                  </Button>
                </Space>
              }
            />
          )}
          {!data.counsel.areas.length && (
            <Alert
              showIcon
              message={'희망 정비소 위치를 선택하지 않은 고객입니다.'}
              type="info"
              action={
                <Space>
                  <Button
                    disabled={disabled}
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (data) sendAreaInputMessage();
                    }}
                  >
                    요청하기
                  </Button>
                </Space>
              }
            />
          )}
        </SBody>
      </PageHeader>
    );
  }

  return null;
};

export default React.memo(CounselInfoPresenter);

const SBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SMenuHeader = styled.li`
  padding: 4px 12px;
  font-size: 14px;
  color: ${props => props.theme.colors.TEXT_MUTED};
`;
