import { Tag } from 'antd';
import _ from 'lodash';
import React from 'react';
import { TableCellProps } from 'react-virtualized';
import styled from 'styled-components';

import { TMergedTableRow } from './';

type PaymentStatusCellProps = TableCellProps;
const PaymentStatusCell: React.FC<PaymentStatusCellProps> = ({ cellData }) => {
  const paymentStatusList = cellData as TMergedTableRow['paymentStatusList'];
  if (paymentStatusList.length === 0) {
    return <SCategoriesCell></SCategoriesCell>;
  }
  return (
    <SCategoriesCell>
      {paymentStatusList.map((payment, index) => (
        <Tag key={index} color={payment.color}>
          {payment.text}
        </Tag>
      ))}
    </SCategoriesCell>
  );
};

export default React.memo(
  PaymentStatusCell,
  (prevProps, nextProps) =>
    prevProps.rowData.id === nextProps.rowData.id &&
    _.isEqual(prevProps.cellData, nextProps.cellData),
);

const SCategoriesCell = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding-left: 8px;
  flex-direction: column;
  gap: 4px;
`;
