import React from 'react';
import { TableCellProps } from 'react-virtualized';
import styled from 'styled-components';

import { TMergedTableRow } from './';

interface FinishedCellProps extends TableCellProps {
  disabled?: boolean;
}
const FinishedCell: React.FC<FinishedCellProps> = props => {
  const createdDate = props.rowData[
    'createdDate'
  ] as TMergedTableRow['createdDate'];

  return <SFinishedDateCell>{createdDate}</SFinishedDateCell>;
};

export default React.memo(
  FinishedCell,
  (prevProps, nextProps) =>
    prevProps.rowData.id === nextProps.rowData.id &&
    prevProps.cellData === nextProps.cellData,
);

const SFinishedDateCell = styled.pre`
  text-align: center;
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
