import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { useParams } from 'react-router-dom';

import CounselWindowPresenter from './CounselWindowPresenter';

import { ApolloClientProvider } from '@utils/providers';

interface CounselWindowParams {
  counselId: string;
}

const CounselWindowContainer: React.FC = () => {
  const { counselId } = useParams<CounselWindowParams>();

  if (!counselId) return <LoadingOutlined />;

  return (
    <ApolloClientProvider>
      <CounselWindowPresenter counselId={counselId} />
    </ApolloClientProvider>
  );
};

export default CounselWindowContainer;
