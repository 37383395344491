import {
  FileAddOutlined,
  FileTextOutlined,
  FolderOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, List, PageHeader, Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

import TemplateBreadcrumb from './TemplateBreadcrumb';

import ErrorTemplate from '@components/ErrorTemplate';
import { useTemplateDirectoryQuery } from '@utils/client';

gql`
  query templateDirectory($directoryId: ID!) {
    templateDirectory(directoryId: $directoryId) {
      id
      name
      path {
        id
        name
      }
      parent {
        id
      }
      templates {
        id
        name
      }
      subdirectories {
        id
        name
        subdirectories {
          id
        }
        templates {
          id
        }
      }
    }
  }
`;

type DirectoryItem = {
  id: string;
  name: string;
  type: 'directory';
  templateCount: number;
  subdirectoryCount: number;
};
type TemplateItem = {
  id: string;
  name: string;
  type: 'template';
};
type ListItem = DirectoryItem | TemplateItem;
interface TemplateDirectoryProps {
  className?: string;
  directoryId: string;
  onChangeDirectory: (directoryId: string) => void;
  onOpenTemplate: (templateId: string) => void;
  onAddTemplate: (directoryId: string) => void;
}
const TemplateDirectory: React.FC<TemplateDirectoryProps> = ({
  className,
  directoryId,
  onChangeDirectory,
  onAddTemplate,
  onOpenTemplate,
}) => {
  const { loading, data, error } = useTemplateDirectoryQuery({
    fetchPolicy: 'no-cache',
    variables: { directoryId },
  });

  const items: ListItem[] = React.useMemo(
    () =>
      data
        ? [
            ...data.templateDirectory.subdirectories.map<DirectoryItem>(
              directory => ({
                id: directory.id,
                name: directory.name,
                type: 'directory',
                subdirectoryCount: directory.subdirectories.length,
                templateCount: directory.templates.length,
              }),
            ),
            ...data.templateDirectory.templates.map<TemplateItem>(template => ({
              id: template.id,
              name: template.name,
              type: 'template',
            })),
          ]
        : [],
    [data],
  );
  const breadcrumb = React.useMemo(
    () =>
      data ? (
        <TemplateBreadcrumb
          directories={data.templateDirectory.path}
          onClickDirectory={onChangeDirectory}
        />
      ) : undefined,
    [data, onChangeDirectory],
  );

  const add = React.useMemo(
    () => (
      <Button key="addTemplate" onClick={() => onAddTemplate(directoryId)}>
        <FileAddOutlined />
        템플릿 추가
      </Button>
    ),
    [directoryId, onAddTemplate],
  );

  const onBack = React.useMemo(
    () =>
      data?.templateDirectory.parent
        ? () => onChangeDirectory(data.templateDirectory.parent?.id || '')
        : undefined,
    [data?.templateDirectory.parent, onChangeDirectory],
  );

  const onClickItem = React.useCallback(
    (item: ListItem) => {
      switch (item.type) {
        case 'directory':
          onChangeDirectory(item.id);
          break;
        case 'template':
          onOpenTemplate(item.id);
          break;
      }
    },
    [onChangeDirectory, onOpenTemplate],
  );

  const renderItem = React.useCallback(
    (item: ListItem) => (
      <SListItem onClick={() => onClickItem(item)}>
        <List.Item.Meta
          title={
            <>
              <SListItemTitle>{item.name}</SListItemTitle>{' '}
              {item.type === 'directory' && (
                <SListItemSubtitle>
                  ( <FolderOutlined /> {item.subdirectoryCount}{' '}
                  <FileTextOutlined /> {item.templateCount} )
                </SListItemSubtitle>
              )}
            </>
          }
          avatar={
            item.type === 'directory' ? (
              <FolderOutlined />
            ) : (
              <FileTextOutlined />
            )
          }
        />
        {item.type === 'directory' && <RightOutlined />}
      </SListItem>
    ),
    [onClickItem],
  );

  if (loading)
    return (
      <SCenter className={className}>
        <Spin />
      </SCenter>
    );

  if (error)
    return (
      <SCenter className={className}>
        <ErrorTemplate
          error={error}
          message="해당 템플릿 폴더를 불러올 수 없습니다!"
        />
      </SCenter>
    );

  if (data)
    return (
      <STemplateDirectory
        className={className}
        title={data.templateDirectory.name}
        breadcrumbRender={() => breadcrumb}
        extra={[add]}
        onBack={onBack}
      >
        <List
          dataSource={items}
          rowKey={item => item.id}
          renderItem={renderItem}
          style={{ height: 'calc(100vh - 172px)', overflow: 'auto' }}
        />
      </STemplateDirectory>
    );

  return null;
};

export default TemplateDirectory;

const STemplateDirectory = styled(PageHeader)``;
const SListItem = styled(List.Item)`
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.HOVER_BACKGROUND};
  }
`;
const SListItemTitle = styled.span``;
const SListItemSubtitle = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.TEXT_MUTED};
`;
const SCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
