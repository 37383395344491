/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import { Checkbox, Input, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import throttle from 'lodash/throttle';
import React from 'react';
import {
  AutoSizer as _AutoSizer,
  Table as _Table,
  Column as _Column,
  TableProps,
  ColumnProps,
  AutoSizerProps,
} from 'react-virtualized';
import styled from 'styled-components';

import FinishedDateCell from '../../cells/FinishedDateCell';
import SimpleTextCell from '../../cells/SimpleTextCell';
import TagsCell from '../../cells/TagsCell';
import UsersCell from '../../cells/UsersCell';
import NoData from '../../utils/NoData';
import Placeholder from '../../utils/Placeholder';

import MemoCell from '@components/CounselTableTabs/cells/memoCell';
import PaymentStatusCell from '@components/CounselTableTabs/cells/PaymentStatusCell';
import VehicleInfoCell from '@components/CounselTableTabs/cells/VehicleInfoCell';
import { FinishFilter } from '@components/CounselTableTabs/searchbars/FinishedCounselSearch';
import DatePicker, { RangeValue } from '@components/DatePicker';
import ErrorTemplate from '@components/ErrorTemplate';
import { openCounselWindow } from '@routes/CounselWindow';
import {
  CounselStatus,
  useFinishedCounselConnectionQuery,
  FinishedCounselsResultFragment,
  FinishedCounselConnectionFilter,
  PaymentStatus,
  useInquiryTypesQuery,
  Insurance,
} from '@utils/client';
import {
  Badge,
  fuelTypeStringifer,
  paymentStatusFormatter,
  tableRowDateFormatter,
} from '@utils/formatters';

const Table = _Table as unknown as React.FC<TableProps>;
const Column = _Column as unknown as React.FC<ColumnProps>;
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;

export const FINISHED_COUNSELS_RESULT = gql`
  fragment finishedCounselsResult on Counsel {
    id
    index
    status
    orders {
      payment {
        id
        status
        amount
      }
      shipping {
        id
        destination
      }
      paymentAmount
    }
    finishedAt

    createdAt
    vehicle {
      id
      plateNumber
      brand {
        id
        logo {
          url
        }
      }
      fullModelName
      fuelType {
        base
        hybrid
      }
    }
    post {
      id
      hashtags {
        id
        name
      }
    }
    type {
      id
      name
    }
    insurance
    memo
    creator {
      id
      nickname
      avatar {
        url
      }
    }
    members {
      id
      nickname
      avatar {
        url
      }
    }
  }
`;

export const FINISHED_COUNSEL_CONNECTION = gql`
  ${FINISHED_COUNSELS_RESULT}
  query finishedCounselConnection(
    $after: String
    $before: String
    $filter: FinishedCounselConnectionFilter!
    $first: Int
    $last: Int
  ) {
    finishedCounselConnection(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          ...finishedCounselsResult
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

type TTableRowUser = {
  id: string;
  nickname: string;
  avatar?: string;
};

type PaymentData = {
  status: PaymentStatus | null;
  amount: number;
  shippingInfo?: { id: string; destination: string };
};

export type TFinishedTableRow = {
  id: string;
  createdDate: string;
  finishedType: string;
  index: number;
  inquiryInfo: string[];
  vehicleInfo: {
    brandLogo?: string;
    modelName?: string;
    plateNumber?: string;
  };
  memo?: string | null;
  members: TTableRowUser[];
  payments: PaymentData[];
  paymentStatusList: Badge[];
};

type Filter = {
  term?: string;
  inquiryTypeIds?: string[];
  index?: number;
  from?: number;
  to?: number;
};

const FinishedTable = () => {
  const [finishFilter, setFinishFilter] = React.useState<Filter>({});

  const { data, loading, error, fetchMore, refetch } =
    useFinishedCounselConnectionQuery({
      fetchPolicy: 'network-only',
      variables: {
        first: 20,
        filter: {},
      },
      notifyOnNetworkStatusChange: true,
    });

  const inquiryTypeQueryResult = useInquiryTypesQuery();

  const queryFilterFormatter = React.useCallback((filter: Filter) => {
    let index = undefined;
    if (filter.term?.startsWith('#')) {
      const indexValue = Number(filter.term.substring(1));
      if (!isNaN(indexValue)) {
        index = indexValue;
      }
    }

    const queryFilter: FinishedCounselConnectionFilter = {
      ...filter,
    };

    if (index) {
      queryFilter.term = undefined;
      queryFilter.index = index;
    }

    if (filter.from && filter.to) {
      queryFilter.range = {
        from: filter.from,
        to: filter.to,
      };
    }

    return {
      inquiryTypeIds:
        queryFilter.inquiryTypeIds && queryFilter.inquiryTypeIds.length > 0
          ? queryFilter.inquiryTypeIds
          : undefined,
      term: queryFilter.term,
      index: queryFilter.index,
      range: queryFilter.range,
      nickname: queryFilter.nickname,
    };
  }, []);

  const handleRefetch = (updatedFilter: FinishFilter) => {
    const mergedFilter = {
      ...finishFilter,
      ...updatedFilter,
    };
    setFinishFilter(mergedFilter);
    const queryFilter = queryFilterFormatter(mergedFilter);
    refetch({
      first: 20,
      filter: queryFilter,
    });
  };

  const handleChangeFinishKeyword = (term: string) => {
    handleRefetch({ term });
  };

  const handleChangeFinishRange = (range: RangeValue) => {
    if (range && range.length === 2) {
      const [from, to] = range;
      const fromDayjs = dayjs(Number(from));
      const convertedFrom = fromDayjs
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .valueOf();

      const toDayjs = dayjs(Number(to));
      const convertedTo = toDayjs
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .valueOf();
      handleRefetch({ from: convertedFrom, to: convertedTo });
    }
  };

  const handleChangeInquiryTypeIds = (selecteds: string[]) => {
    handleRefetch({ inquiryTypeIds: selecteds });
  };

  const handleChangeIncludeNickname = (checked: boolean) => {
    handleRefetch({ nickname: checked });
  };

  const scrollListener = React.useCallback(
    async (e: any) => {
      if (!data) {
        return;
      }

      if (loading) {
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = e;

      const atBottom = scrollTop + clientHeight >= scrollHeight;
      if (atBottom && data.finishedCounselConnection.pageInfo.hasNextPage) {
        try {
          await fetchMore({
            variables: {
              first: 10,
              after: data.finishedCounselConnection.pageInfo.endCursor,
              filter: queryFilterFormatter(finishFilter),
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    [data, fetchMore, finishFilter, loading, queryFilterFormatter],
  );

  const throttleScrollListener = throttle(scrollListener, 500);

  const formatCounselToRow = React.useCallback(
    (counsel: FinishedCounselsResultFragment): TFinishedTableRow => {
      const member = counsel.members
        ? counsel.members.map(user => ({
            id: user.id,
            nickname: user.nickname,
            avatar: user.avatar?.url,
          }))
        : [];

      const payments: PaymentData[] = [];

      if (counsel?.orders && counsel.orders.length > 0) {
        for (const order of counsel.orders) {
          if (!order.payment) {
            continue;
          }

          let paymentAmount = order.paymentAmount;
          let paymentStatus = null;

          if (order.payment.status === PaymentStatus.Paid) {
            paymentStatus = PaymentStatus.Paid;
          } else if (order.payment.status === PaymentStatus.Cancelled) {
            paymentStatus = PaymentStatus.Cancelled;
          } else if (order.payment.status === PaymentStatus.Failed) {
            paymentStatus = PaymentStatus.Failed;
          } else if (order.payment.status === PaymentStatus.Ready) {
            paymentStatus = PaymentStatus.Ready;
          }

          if (
            order.payment.status === PaymentStatus.Paid &&
            order.payment.amount > 0
          ) {
            paymentAmount = order.payment.amount;
          }

          payments.push({
            status: paymentStatus,
            amount: paymentAmount,
            shippingInfo: order.shipping
              ? {
                  id: order.shipping.id,
                  destination: order.shipping.destination,
                }
              : undefined,
          });
        }
      }

      const inquiryInfo = [];
      counsel?.type?.name && inquiryInfo.push(counsel.type.name);
      if (counsel.insurance) {
        if (counsel.insurance === Insurance.Cdw) {
          inquiryInfo.push('자차보험');
        } else if (counsel.insurance === Insurance.Lp) {
          inquiryInfo.push('대물보험');
        } else if (counsel.insurance === Insurance.None) {
          inquiryInfo.push('자비처리');
        }
      }

      if (counsel.post?.hashtags) {
        counsel.post.hashtags.forEach(hashtag => {
          inquiryInfo.push(hashtag.name);
        });
      }

      const paymentStatusList =
        payments?.length > 0
          ? (payments
              .map(payment => paymentStatusFormatter(payment.status))
              .filter(badge => !!badge) as Badge[])
          : [];

      let modelName = counsel.vehicle?.fullModelName;
      if (counsel.vehicle?.fuelType) {
        modelName += ` (${fuelTypeStringifer(counsel.vehicle.fuelType)})`;
      }

      return {
        id: counsel.id,
        index: counsel.index,
        createdDate: counsel.createdAt
          ? tableRowDateFormatter(new Date(counsel.createdAt))
          : '-',
        finishedType: '',
        memo: counsel.memo,
        inquiryInfo,
        payments,
        vehicleInfo: {
          brandLogo: counsel.vehicle?.brand?.logo?.url,
          modelName,
          plateNumber: counsel.vehicle?.plateNumber,
        },
        members: member,
        paymentStatusList,
      };
    },
    [],
  );

  // 캐시에서 잡힌 애들 필터
  const formatedCounselsForRow: TFinishedTableRow[] = React.useMemo(() => {
    if (!data) {
      return [];
    }
    const castedCounsels = data.finishedCounselConnection.edges.map(
      edge => edge.node,
    );
    return castedCounsels.map(formatCounselToRow);
  }, [data, formatCounselToRow]);

  if (!loading && !data) {
    return <NoData />;
  }

  return (
    <SSearchableChatTable>
      <SFinishedSearchContainer>
        <SFinishedChatSearch>
          <Select
            onChange={handleChangeInquiryTypeIds}
            mode="multiple"
            style={{ width: '320px' }}
            placeholder="문의유형"
          >
            {inquiryTypeQueryResult.data &&
              inquiryTypeQueryResult.data.inquiryTypes.map(type => (
                <Select.Option key={`inquiryType-${type.id}`} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
          </Select>
          <DatePicker.RangePicker
            onChange={handleChangeFinishRange}
            showTime={false}
          />
          <Input.Search
            style={{ width: '500px' }}
            onChange={e => {
              if (e.target.value === '') {
                handleRefetch({
                  term: undefined,
                });
              } else {
                setFinishFilter({ ...finishFilter, term: e.target.value });
              }
            }}
            onSearch={handleChangeFinishKeyword}
            placeholder={'고객 닉네임, 메카닉 메모, 차종, 전화번호로 검색...'}
            enterButton={'조회'}
            value={finishFilter.term}
          />
          <Checkbox
            onChange={e => handleChangeIncludeNickname(e.target.checked)}
          >
            닉네임 포함
          </Checkbox>
        </SFinishedChatSearch>
      </SFinishedSearchContainer>
      <Spin spinning={loading}>
        <SChatTableContainer>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={120}
                rowCount={formatedCounselsForRow.length}
                rowGetter={({ index }) => formatedCounselsForRow[index]}
                noRowsRenderer={() =>
                  error ? (
                    <SError
                      error={error}
                      message="상담을 불러오지 못했습니다"
                    />
                  ) : (
                    <Placeholder status={CounselStatus.Ongoing} />
                  )
                }
                onRowDoubleClick={({ rowData }) => {
                  const counsel = rowData as TFinishedTableRow;
                  openCounselWindow(counsel.id);
                }}
                onScroll={throttleScrollListener}
              >
                <Column
                  label="문의일시"
                  dataKey="createdDate"
                  width={74}
                  minWidth={74}
                  headerStyle={{ textAlign: 'center' }}
                  style={{ textAlign: 'center' }}
                  cellRenderer={props => <FinishedDateCell {...props} />}
                />
                <Column
                  label="상담번호"
                  dataKey="index"
                  width={80}
                  minWidth={80}
                  headerStyle={{ textAlign: 'center' }}
                  style={{ textAlign: 'center' }}
                  cellRenderer={props => (
                    <SimpleTextCell text={props.rowData['index']} />
                  )}
                />
                <Column
                  label="문의유형"
                  dataKey="inquiryInfo"
                  width={300}
                  minWidth={120}
                  headerStyle={{ textAlign: 'center' }}
                  style={{ textAlign: 'center' }}
                  cellRenderer={props => (
                    <TagsCell tags={props.rowData['inquiryInfo']} />
                  )}
                />
                <Column
                  label="차량정보"
                  dataKey="vehicleInfo"
                  headerStyle={{ textAlign: 'center' }}
                  width={300}
                  minWidth={220}
                  cellRenderer={props => (
                    <VehicleInfoCell
                      vehicleInfo={props.rowData['vehicleInfo']}
                    />
                  )}
                />
                <Column
                  label="메카닉 메모"
                  dataKey="memo"
                  minWidth={280}
                  width={280}
                  headerStyle={{ textAlign: 'center' }}
                  cellRenderer={props => (
                    <MemoCell {...props} disabled={true} />
                  )}
                />
                <Column
                  label="결제여부"
                  dataKey="paymentStatusList"
                  width={120}
                  minWidth={120}
                  headerStyle={{ textAlign: 'center' }}
                  style={{ textAlign: 'center' }}
                  cellRenderer={props => <PaymentStatusCell {...props} />}
                />
                <Column
                  label="결제/배송정보"
                  dataKey=""
                  width={500}
                  minWidth={500}
                  headerStyle={{ textAlign: 'center' }}
                  style={{ textAlign: 'center' }}
                  cellRenderer={props => {
                    let result = '';
                    if (props.rowData['payments']) {
                      for (const payment of props.rowData['payments']) {
                        let row = result === '' ? '' : '\n';
                        const amount = payment.amount as number;
                        let label = '결제금액';

                        if (payment.status === PaymentStatus.Ready) {
                          label = '미결제 금액';
                        } else if (payment.status === PaymentStatus.Cancelled) {
                          label = '취소금액';
                        }

                        row += `${label}: ${
                          amount ? amount.toLocaleString() : 0
                        }원 / `;

                        const shippingInfo = payment.shippingInfo;
                        if (shippingInfo) {
                          row += `배송지: ${shippingInfo.destination}`;
                        }
                        result += row;
                      }
                    }
                    return (
                      <SimpleTextCell
                        text={result}
                        style={{
                          justifyContent: 'flex-start',
                          width: '100%',
                          textAlign: 'left',
                        }}
                      />
                    );
                  }}
                />
                <Column
                  label="참여 현황"
                  dataKey="members"
                  width={160}
                  minWidth={160}
                  headerStyle={{ textAlign: 'center' }}
                  style={{ textAlign: 'center' }}
                  cellRenderer={props => <UsersCell {...props} />}
                />
              </Table>
            )}
          </AutoSizer>
        </SChatTableContainer>
      </Spin>
    </SSearchableChatTable>
  );
};

export default FinishedTable;

const SSearchableChatTable = styled.div`
  flex: 1;
`;

const SFinishedChatSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;

const SFinishedSearchContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
`;

const SChatTableContainer = styled.div`
  height: calc(100vh - 94px);
`;

const SError = styled(ErrorTemplate)`
  height: 100%;
  background-color: #fafafa;
`;
