import { gql } from '@apollo/client';
import { Tabs, Result, Modal, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

import OrderTab from './OrderTab';
import PaymentHeader from './PaymentHeader';
import PaymentTable, { Company, TOrderItem } from './PaymentTable';

import Loading from '@components/Loading';
import useCounselWindowCache from '@hooks/useCounselWindowCache';
import {
  TaskpadOrderFragment,
  UpdateOrderData,
  CounselRoomMessageFragment,
  CreateShippingData,
  PaymentStatus,
  UpdateShippingData,
  Maybe,
  Payment,
  Shipping,
  useUpsertItemsMutation,
  CounselStatus,
  useCounselOrderQuery,
} from '@utils/client';
import { dateDotFormatter } from '@utils/formatters';
import { COUNSEL_DETAIL_INFO } from '@utils/fragments';

export type EstimateItem = {
  code: string;
  name: string;
  quantity: number;
  discountRate: number;
  price: number;
  paymentAmount: number;
};

export type ShippingFragment =
  | Maybe<
      {
        __typename?: 'Shipping' | undefined;
      } & Pick<
        Shipping,
        | 'destination'
        | 'recipient'
        | 'id'
        | 'status'
        | 'trackingId'
        | 'contact'
        | 'method'
        | 'carrier'
      >
    >
  | undefined;

export type PaymentFragment = { __typename?: 'Payment' } & Pick<
  Payment,
  'id' | 'amount' | 'completedAt' | 'status'
>;

type OrderItem = {
  code: string;
  name: string;
  discountRate: number;
  price: number;
  quantity: number;
  paymentAmount: number;
};

const getOrderName = (
  orderName: string,
  index: number,
  orders: TaskpadOrderFragment[],
): any => {
  const sameOrderName = orders.find(order => {
    if (index === 1) {
      return order.name === orderName;
    }
    return `${orderName}(${index})` === order.name;
  });

  if (!sameOrderName) {
    if (index === 1) {
      return orderName;
    }

    return `${orderName}(${index})`;
  } else {
    return getOrderName(`${orderName}`, index + 1, orders);
  }
};

type PaymentPresenterProps = {
  counselId: string;
  createEmptyOrder: (
    ordername: string,
    counseleeId: string,
    vehicleId: string,
  ) => Promise<TaskpadOrderFragment | null>;
  deleteOrder: (orderId: string) => void;
  updateOrder: (
    orderId: string,
    data: UpdateOrderData,
  ) => Promise<TaskpadOrderFragment | null>;
  sendEstimate: (
    counselId: string,
    orderId: string,
  ) => Promise<CounselRoomMessageFragment | null>;
  createShipping: (
    orderId: string,
    data: CreateShippingData,
  ) => Promise<{ id: string } | null>;
  sendInvoice: (
    counselId: string,
    orderId: string,
  ) => Promise<CounselRoomMessageFragment | null>;
  cancelPayment: (
    paymentId: string,
    reason: string,
  ) => Promise<Maybe<number> | undefined>;
  updateShipping: (
    shippingId: string,
    data: UpdateShippingData,
  ) => Promise<ShippingFragment | null>;
  forceCompletePayment: (
    orderId: string,
    paymentId: string,
  ) => Promise<PaymentFragment | null>;
};

gql`
  mutation upsertItems($items: [CreateItemData!]!) {
    upsertItems(items: $items) {
      items {
        name
      }
    }
  }
`;

gql`
  ${COUNSEL_DETAIL_INFO}
  query CounselOrder($counselId: ID!) {
    counsel(counselId: $counselId) {
      ...counselDetailInfo
      sentCompanies {
        id
        name
        contact
        address {
          name
          road
          region
        }
      }
      orders {
        id
        __typename
        amount
        createdAt
        expiresAt
        discountAmount
        totalItemAmount
        totalAmount
        paymentAmount
        vehicle {
          id
          brand {
            id
            name
          }
        }
        items {
          code
          name
          discountRate
          price
          quantity
          totalAmount
          paymentAmount
        }
        name
        payment {
          id
          amount
          completedAt
          cancelledAt
          status
        }
        shippingCharge
        recommendedReason
        rejectedReason
        shipping {
          id
          status
          trackingId
          contact
          destination
          method
          recipient
          carrier
          deliveredAt
        }
        ... on InsurancedOrder {
          cashbackAmount
          cashbackRate
        }
      }
    }
  }
`;

const PaymentPresenter: React.FC<PaymentPresenterProps> = ({
  counselId,
  createEmptyOrder,
  deleteOrder,
  updateOrder,
  sendEstimate,
  createShipping,
  sendInvoice,
  cancelPayment,
  updateShipping,
  forceCompletePayment,
}) => {
  const { data, loading, error, refetch } = useCounselOrderQuery({
    variables: { counselId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const [upsertItemMuatation] = useUpsertItemsMutation();
  const counselWindowCache = useCounselWindowCache();
  const [openedOrderId, setOpenedOrderId] = React.useState<string | undefined>(
    undefined,
  );
  React.useEffect(() => {
    if (!data) {
      return;
    }
    if (openedOrderId === undefined) {
      const orders = data.counsel.orders;
      if (orders.length > 0) {
        setOpenedOrderId(orders[0].id);
      }
    }
  }, [data, openedOrderId]);

  const companies = React.useMemo<Company[]>(() => {
    if (!data?.counsel) {
      return [];
    }

    return data.counsel.sentCompanies.map(company => ({
      id: company.id,
      address: company.address.road
        ? company.address.road
        : (company.address.region as string),
      contact: company.contact,
      name: company.name,
    }));
  }, [data]);
  if (!data || loading) {
    <Loading />;
  }

  if (error) {
    return (
      <Result
        status="error"
        title="견적/결제 정보 로드중에 문제가 발생했습니다."
        extra={error.message}
      />
    );
  }

  const handleSendEstimate = async () => {
    if (openedOrderId) {
      await sendEstimate(counselId, openedOrderId);
    }
  };

  const tableOrderItem = (orderItems: OrderItem[]): TOrderItem[] => {
    return orderItems.map(order => ({
      code: order.code,
      name: order.name,
      discountRate: order.discountRate,
      price: order.price,
      quantity: order.quantity,
      paymentAmount: order.paymentAmount,
    }));
  };
  const handleChangeEditTab = async (
    key:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: 'add' | 'remove',
  ) => {
    if (!data) {
      return;
    }

    if (
      data.counsel.status === CounselStatus.Pending ||
      data.counsel.status === CounselStatus.Finished
    ) {
      Modal.error({
        title: '진행중인 상담이 아닙니다.',
      });
      return;
    }

    // 없 완 중
    if (action === 'add') {
      const allOrderPaid = data.counsel.orders.every(order => {
        if (!order.payment) {
          return false;
        }
        return [PaymentStatus.Paid, PaymentStatus.Cancelled].includes(
          order.payment.status,
        );
      });

      if (!allOrderPaid) {
        Modal.error({
          title: '견적서를 생성할 수 없습니다.',
          content: '결제가 완료되지 않은 견적서가 있습니다.',
        });
        return;
      }

      if (!data.counsel.counselee) {
        Modal.error({
          title: '견적서를 생성할 수 없습니다.',
          content: '고객정보가 존재하지 않습니다.',
        });
        return;
      }

      if (!data.counsel.vehicle) {
        Modal.error({
          title: '견적서를 생성할 수 없습니다.',
          content: '차량 정보가 존재하지 않는 상담입니다.',
        });
        return;
      }

      let orderName = dateDotFormatter(new Date());

      if (data.counsel.vehicle) {
        orderName = data.counsel.vehicle.shortModelName;
      }

      orderName += ' 결제 견적서';

      const indexingOrderName = getOrderName(orderName, 1, data.counsel.orders);

      const createdOrder = await createEmptyOrder(
        indexingOrderName,
        data.counsel.counselee.id,
        data.counsel.vehicle.id,
      );

      if (!createdOrder) {
        return;
      }

      counselWindowCache.appendOrder(createdOrder, counselId);
      setOpenedOrderId(createdOrder.id);
    } else if (action === 'remove') {
      Modal.warning({
        title: '견적서를 삭제하시겠어요?',
        content: '작성 내용은 저장되지 않습니다.',
        closable: true,
        onOk: () => {
          const orderId = key as string;
          const targetOrder = data.counsel.orders.find(
            order => order.id === orderId,
          );
          if (!targetOrder) {
            Modal.error({
              title: '해당 견적서가 존재하지 않습니다.',
            });
            return;
          }

          if (targetOrder.payment) {
            Modal.error({
              title: '삭제할 수 없는 견적서입니다',
              content: '결제요청이 전송된 견적서는 삭제할 수 없습니다.',
            });
            return;
          }
          const deletedOrderIndex = data.counsel.orders.findIndex(
            order => order.id === orderId,
          );
          deleteOrder(orderId);
          counselWindowCache.removeOrder(orderId, counselId);

          if (orderId !== openedOrderId || data.counsel.orders.length === 0) {
            return;
          }

          if (deletedOrderIndex > 0) {
            const beforeOrder = data.counsel.orders[deletedOrderIndex - 1];
            if (beforeOrder) {
              setOpenedOrderId(beforeOrder.id);
            }
          } else {
            const nextOrder = data.counsel.orders[deletedOrderIndex];
            if (nextOrder) {
              setOpenedOrderId(nextOrder.id);
            }
          }
        },
      });
    }
  };

  const onHandleChangeTab = (orderId: string) => {
    setOpenedOrderId(orderId);
  };

  const onChangeOrderInsurance = async (
    orderId: string,
    insurance: boolean,
  ) => {
    await updateOrder(orderId, {
      insurance,
    });

    await refetch();
  };

  const updateOrderName = async (
    order: TaskpadOrderFragment,
    updatedOrderName: string,
  ) => {
    return await updateOrder(order.id, {
      name: updatedOrderName,
    });
  };

  const updateOrderDiscount = async (
    order: TaskpadOrderFragment,
    updatedDiscount: number,
  ) => {
    if (updatedDiscount < 0 || updatedDiscount > 100000000) {
      notification.error({ message: '주문 할인 가능액 범위를 초과합니다.' });
      return;
    }
    return await updateOrder(order.id, {
      discountAmount: updatedDiscount,
    });
  };

  const updateCashbackRate = async (
    order: TaskpadOrderFragment,
    updatedCashbackRate: number,
  ) => {
    if (order.__typename !== 'InsurancedOrder') {
      return;
    }

    if (updatedCashbackRate < 0 || updatedCashbackRate > 0.15) {
      notification.error({ message: '캐시백 가능액 범위를 초과합니다.' });
      return;
    }

    return await updateOrder(order.id, {
      cashbackRate: updatedCashbackRate,
    });
  };

  const updateEstimate = async (items: EstimateItem[]) => {
    if (!data) {
      return;
    }

    await upsertItemMuatation({
      variables: {
        items: items.map(item => ({ id: item.code, name: item.name })),
      },
    });

    const targetOrder = data.counsel.orders.find(
      order => order.id === openedOrderId,
    );
    if (!targetOrder) {
      return;
    }

    if (!openedOrderId) {
      return;
    }

    const itemOutOfRange = items.some(
      item =>
        item.price < 301 ||
        item.price > 100000000 ||
        item.discountRate > 100 ||
        item.discountRate < 0,
    );

    if (itemOutOfRange) {
      notification.error({ message: '상품가격이 범위를 벗어납니다.' });
      return;
    }

    try {
      await updateOrder(openedOrderId, {
        name: targetOrder.name,
        shippingCharge: targetOrder.shippingCharge,
        items: items.map(item => ({
          itemId: item.code,
          price: item.price,
          quantity: item.quantity,
          discountRate: item.discountRate / 100,
        })),
      });
      notification.success({ message: '견적서가 저장되었습니다.' });
    } catch (err) {
      notification.error({ message: '견적서 저장중 오류가 발생했습니다.' });
    }
  };

  const updateShippingCharge = async (
    order: TaskpadOrderFragment,
    updatedShippingCharge: number,
  ) => {
    if (updatedShippingCharge < 0 || updatedShippingCharge > 1000000) {
      notification.error({
        message: '배송비는 0부터 100만원까지만 입력가능합니다.',
      });
      return;
    }
    await updateOrder(order.id, {
      shippingCharge: updatedShippingCharge,
    });
  };

  const updateRecommendedReason = async (
    orderId: string,
    recommendedReason: string,
  ) => {
    if (recommendedReason.length < 1 || recommendedReason.length > 1000) {
      notification.error({
        message: '추천사유는 1글자부터 1000글자까지 입력가능합니다.',
      });
      return;
    }
    await updateOrder(orderId, {
      recommendedReason,
    });
  };

  const handleSendPayment = async (
    orderId: string,
    shippingData?: CreateShippingData,
  ) => {
    if (shippingData) {
      await createShipping(orderId, shippingData);
    }
    const message = await sendInvoice(counselId, orderId);

    if (message && message.__typename === 'InvoiceMessage' && message.order) {
      const order = message.order;
      counselWindowCache.updateOrder(
        orderId,
        order as TaskpadOrderFragment,
        counselId,
      );
    }
  };

  const handleCancelPayment = async (
    orderId: string,
    paymentId: string,
    reason: string,
  ) => {
    const cancelledAt = await cancelPayment(paymentId, reason);

    const targetOrder = data?.counsel.orders.find(
      order => order.id === orderId,
    );

    if (!targetOrder) {
      return;
    }

    if (!targetOrder.payment) {
      return;
    }

    const updatedOrder = {
      ...targetOrder,

      payment: {
        ...targetOrder.payment,
        cancelledAt,
        status: PaymentStatus.Cancelled,
      },
    };

    if (targetOrder.shipping) {
      updatedOrder.shipping = {
        ...targetOrder.shipping,
        status: undefined,
      };
    }

    counselWindowCache.updateOrder(orderId, updatedOrder, counselId);
  };

  const handleUpdateShipping = async (
    orderId: string,
    shippingId: string,
    data: UpdateShippingData,
  ) => {
    const updatedShipping = await updateShipping(shippingId, data);
    if (!updatedShipping) {
      return;
    }

    counselWindowCache.updateShipping(orderId, counselId, updatedShipping);
  };

  const handleForceComplementPayment = async (
    orderId: string,
    paymentId: string,
  ) => {
    const completedPayment = await forceCompletePayment(orderId, paymentId);

    if (!completedPayment) {
      return;
    }

    counselWindowCache.updatePayment(counselId, orderId, completedPayment);
  };

  return (
    <SContainer>
      <PaymentHeader
        nickname={data?.counsel.counselee?.nickname}
        updatedAt={new Date()}
      />
      <SBody>
        <Tabs
          type="editable-card"
          animated={false}
          tabBarStyle={{ marginBottom: 0, height: '40px' }}
          onEdit={handleChangeEditTab}
          activeKey={openedOrderId}
          onChange={onHandleChangeTab}
        >
          {data?.counsel.orders.map(order => (
            <STabPane
              key={`${order.id}`}
              tab={
                <OrderTab
                  orderName={order.name}
                  opened={order.id === openedOrderId}
                  updateOrderName={updatedOrderName =>
                    updateOrderName(order, updatedOrderName)
                  }
                  status={order.payment?.status}
                />
              }
              closable
            >
              <PaymentTable
                order={order}
                status={data.counsel.status}
                vehicle={data.counsel.vehicle}
                counselId={counselId}
                updateShippingCharge={updateShippingCharge}
                updateOrderDiscount={updateOrderDiscount}
                shippingCharge={order.shippingCharge}
                items={tableOrderItem(order.items)}
                updateEstimate={updateEstimate}
                handleSendPayment={handleSendPayment}
                handleCancelPayment={handleCancelPayment}
                handleUpdateShipping={handleUpdateShipping}
                handleForceComplementPayment={handleForceComplementPayment}
                updateRecommendedReason={updateRecommendedReason}
                sendEstimate={handleSendEstimate}
                companies={companies}
                onChangeOrderInsurance={onChangeOrderInsurance}
                updateCashbackRate={updateCashbackRate}
              />
            </STabPane>
          ))}
        </Tabs>
      </SBody>
    </SContainer>
  );
};

export default PaymentPresenter;

const SContainer = styled.div``;

const SBody = styled.div`
  margin-top: 18px;
`;

const STabPane = styled(Tabs.TabPane)``;
