import {
  ExclamationCircleOutlined,
  PictureOutlined,
  ShopOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import React from 'react';

import {
  Image,
  Video,
  PaymentStatus,
  ShippingMethod,
  QueueReason,
  Carrier,
  Fuel,
  Insurance,
} from './client';
import { isContact, isPhoneNumber } from './validators';

import { MessageInterface } from '@components/CounselRoom/Message';

type DateOptions = {
  dot?: boolean;
  withDay?: boolean;
  withTime?: boolean;
};
export const dateFormatter = (
  milliseconds: number | string | Date,
  options?: DateOptions,
) => {
  let time: Date;
  if (typeof milliseconds === 'number' || typeof milliseconds === 'string') {
    time = new Date(milliseconds);
  } else {
    time = milliseconds;
  }

  const YYYY = time.getFullYear();
  const MM = time.getMonth() + 1;
  const DD = time.getDate();
  const day = dayFormatter(time.getDay());

  const ampm = time.getHours() >= 12 ? '오후' : '오전';
  const hours = time.getHours() % 12 || 12;
  const minutes = time.getMinutes();

  return options?.dot
    ? `${YYYY}.${MM}.${DD}${options?.withDay ? ` ${day}` : ''}${
        options?.withTime
          ? ` ${ampm} ${hours}시 ${minutes ? `${minutes}분` : ''}`
          : ''
      }`
    : `${YYYY}년 ${MM}월 ${DD}일${options?.withDay ? ` ${day}` : ''}${
        options?.withTime
          ? ` ${ampm} ${hours}시 ${minutes ? `${minutes}분` : ''}`
          : ''
      }`;
};

const dateStringToTwoCharacter = (value: string) => {
  if (value.length >= 2) {
    return value;
  }

  return `0${value}`;
};

export const tableRowDateFormatter = (date: Date, isLine = true) => {
  let result = '';
  const today = new Date();
  const todayYear = today.getFullYear();
  const year = date.getFullYear();

  if (todayYear !== year) {
    result += `${year}`;

    if (isLine) {
      result += `\n`;
    } else {
      result += ' ';
    }
  }

  const month = dateStringToTwoCharacter(String(date.getMonth() + 1));
  const day = dateStringToTwoCharacter(String(date.getDate()));

  result += `${month}/${day}`;

  if (isLine) {
    result += `\n`;
  } else {
    result += ' ';
  }

  const hour = dateStringToTwoCharacter(String(date.getHours()));
  const min = dateStringToTwoCharacter(String(date.getMinutes()));

  result += `${hour}:${min}`;

  return result;
};

export const dateDotFormatter = (date: Date) => {
  const year = dateStringToTwoCharacter(String(date.getFullYear()));
  const month = dateStringToTwoCharacter(String(date.getMonth() + 1));
  const day = dateStringToTwoCharacter(String(date.getDate()));
  return `${year}.${month}.${day}`;
};

export const timestampFormatter = (milliseconds: number | Date) => {
  const time =
    typeof milliseconds === 'number' ? new Date(milliseconds) : milliseconds;

  const ampm = time.getHours() >= 12 ? '오후' : '오전';
  const hours = time.getHours() % 12 || 12;
  const minutes = time.getMinutes();

  return `${ampm} ${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
};

const weekMap = {
  0: '월',
  1: '화',
  2: '수',
  3: '목',
  4: '금',
  5: '토',
  6: '일',
};

export const chatListItemTimestampFormatter = (milliseconds: number | Date) => {
  if (!milliseconds) {
    return '';
  }
  const date =
    typeof milliseconds === 'number' ? new Date(milliseconds) : milliseconds;
  const now = new Date();

  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    const ampm = date.getHours() >= 12 ? '오후' : '오전';
    let hours: number | string = date.getHours() % 12 || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes: number | string = date.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${ampm} ${hours}:${minutes}`;
  }

  const YYYY = date.getFullYear();
  const MM = date.getMonth() + 1;

  const DD = date.getDate();
  const weekNumber = date.getDay() as keyof typeof weekMap;
  const week = weekMap[weekNumber];
  let hour = date.getHours().toString();
  let min = date.getMinutes().toString();

  if (hour.length < 2) {
    hour = `0${hour}`;
  }

  if (min.length < 2) {
    min = `0${min}`;
  }

  return `${YYYY}.${MM}.${DD} (${week}) ${hour}:${min}`;
};

export const dayFormatter = (day: number) => {
  switch (day) {
    case 0:
      return '일요일';
    case 1:
      return '월요일';
    case 2:
      return '화요일';
    case 3:
      return '수요일';
    case 4:
      return '목요일';
    case 5:
      return '금요일';
    case 6:
      return '토요일';
  }
};

export const insuranceFormatter = (insurance: Insurance) => {
  switch (insurance) {
    case Insurance.None:
      return '자비 처리';
    case Insurance.Cdw:
      return '자차 보험';
    case Insurance.Lp:
      return '대물 보험';
  }
};

export const phoneFormatter = (tel?: string | null) => {
  if (!tel) {
    return '';
  }
  const handled = tel
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '');
  return handled;
};

export const contactFormatter = (contact: string): string => {
  if (isPhoneNumber(contact)) {
    const handled = contact
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
      .replace(/(-{1,2})$/g, '');
    return handled;
  } else if (isContact(contact)) {
    return contact
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        '$1-$2-$3',
      )
      .replace('--', '-');
  } else {
    return contact;
  }
};

export type Badge = {
  text: string;
  color: string | undefined;
};

export const tagsFormatter = (name: string): Badge | undefined => {
  switch (name) {
    case '일반 부품 구매':
      return {
        text: name,
        color: 'gold',
      };
    case '자비처리':
      return {
        text: '자비처리',
        color: 'cyan',
      };
    case '자차보험':
      return {
        text: '자차보험',
        color: 'cyan',
      };
    case '대물보험':
      return {
        text: '대물보험',
        color: 'cyan',
      };
    case '외장':
    case '사고수리':
      return {
        text: name,
        color: 'purple',
      };
    case '정비/부품':
      return {
        text: name,
        color: 'blue',
      };
    case '타이어':
      return {
        text: name,
        color: 'green',
      };
    case '배터리':
      return {
        text: name,
        color: 'volcano',
      };
    case '소모품':
    case '소모품구매':
    case '소모품 구매':
    case '엔진오일':
    case '엔진오일세트':
    case '엔진오일필터':
    case '에어필터':
    case '오일필터':
    case '미션오일':
    case '미션오일필터':
    case '브레이크오일':
    case '브레이크오일필터':
    case '브레이크패드':
    case '브레이크디스크':
    case '앞바퀴':
    case '뒷바퀴':
    case '에어컨필터':
    case '연료필터':
    case '외부벨트':
    case '베어링세트':
    case '워터펌프':
    case '오일펌프':
    case '타이밍벨트':
    case '엔진마운트':
    case '미션마운트':
    case 'HID전구':
    case '점화플러그':
    case '점화코일':
      return {
        text: name,
        color: 'red',
      };
    default:
      return {
        text: name,
        color: 'default',
      };
  }
};

export const paymentStatusFormatter = (
  status: PaymentStatus | /* ShippingStatus | */ undefined | null,
): Badge | undefined => {
  switch (status) {
    case PaymentStatus.Ready:
      return {
        text: '결제 대기중',
        color: 'red',
      };
    // case ShippingStatus.Preparing:
    //   return {
    //     text: '배송 전',
    //     color: 'orange',
    //   };
    case PaymentStatus.Paid:
      return {
        text: '결제완료',
        color: 'geekblue',
      };
    // case ShippingStatus.InTransit:
    //   return {
    //     text: '배송중',
    //     color: 'purple',
    //   };
    // case ShippingStatus.Delivered:
    //   return {
    //     text: '배송 완료',
    //     color: 'blue',
    //   };
    case PaymentStatus.Cancelled:
      return {
        text: '결제취소',
        color: undefined,
      };
    default:
      return undefined;
  }
};

export const ShippingMethodMap = {
  [ShippingMethod.Delivery]: '닥터차 배송',
  [ShippingMethod.Autopedia]: '닥터차 직원 배송',
  [ShippingMethod.Wholesale]: '업체 직배송',
};

export const QueuedReasonMap = {
  [QueueReason.PriceCheck]: '견적 조회',
  [QueueReason.ShopCheck]: '업체 조회',
  [QueueReason.ShippingCheck]: '배송 상황 조회',
  [QueueReason.CashbackCheck]: '캐시백 안내',
};

export const messageStringifier = (message: MessageInterface): string => {
  switch (message.__typename) {
    case 'AreaInputMessage':
      return '메카닉이 선호 정비 위치를 요청하였습니다.';
    case 'EndMessage':
      return '상담이 종료되었습니다.';
    case 'SuspendedMessage':
      return '상담이 곧 자동종료됩니다.';
    case 'EnterMessage':
      return message.inviter
        ? `${message.inviter.nickname}님이 ${
            message.invitee?.nickname || '(알수없음)'
          }님을 초대했습니다.`
        : `${message.invitee?.nickname || '(알수없음)'}님이 입장했습니다.`;
    case 'EstimateMessage':
      return `${
        message.counsel?.counselee
          ? message.counsel.counselee.nickname
          : '(알수없음)'
      }님의 견적서`;
    case 'InvoiceMessage':
      return '결제가 요청되었습니다.';
    case 'LeaveMessage':
      return `${
        message.user ? message.user.nickname : '(알수없음)'
      }님이 나갔습니다.`;
    case 'PlateNumberInputMessage':
      return '차량번호를 요청하였습니다.';
    case 'QueueMessage':
      if (message.reason === QueueReason.PriceCheck) {
        return '닥터차 메카닉이 💲 가격정보를 알아보는 중입니다.';
      } else if (message.reason === QueueReason.ShippingCheck) {
        return '닥터차 메카닉이 🚛 배송상황을 알아보는 중입니다.';
      } else {
        return '닥터차 메카닉이 🔎 정비업체를 알아보는 중입니다.';
      }
    case 'RestartMessage':
      return '상담이 재시작되었습니다.';
    case 'VinInputMessage':
      return '차대번호를 요청하였습니다.';
    case 'TextMessage':
      return message.body;
    case 'FileMessage': {
      const file = message.file as Image | Video;
      return file.__typename === 'Video' ? '비디오' : '사진';
    }
    case 'CompanyMessage':
      return `[정비소] ${
        message.company ? message.company.name : '찾을 수 없는 정비소입니다'
      }`;
    case 'AppReviewMessage':
      return '앱 리뷰등록을 요청하였습니다.';
    case 'DeletedMessage':
      return '삭제된 메시지입니다.';
    default:
      // @ts-ignore : 지원하지 않는 __typename에 대한 처리
      return `[오류] '${message.__typename}'은/는 지원하지 않는 형식입니다`;
  }
};

export const messageIconifier = (
  message: MessageInterface,
): React.ReactNode => {
  switch (message.__typename) {
    case 'TextMessage':
      return null;
    case 'FileMessage': {
      const file = message.file as Image | Video;
      return file.__typename === 'Video' ? (
        <VideoCameraOutlined />
      ) : (
        <PictureOutlined />
      );
    }
    case 'CompanyMessage':
      return <ShopOutlined />;
    default:
      return <ExclamationCircleOutlined />;
  }
};

export const provinceShortener = (province: string): string => {
  switch (province) {
    case '서울특별시':
      return '서울';
    case '부산광역시':
      return '부산';
    case '인천광역시':
      return '인천';
    case '대구광역시':
      return '대구';
    case '광주광역시':
      return '광주';
    case '대전광역시':
      return '대전';
    case '울산광역시':
      return '울산';
    case '세종특별자치시':
      return '세종';
    case '경기도':
      return '경기';
    case '강원도':
      return '강원';
    case '충청북도':
      return '충북';
    case '충청남도':
      return '충남';
    case '경상북도':
      return '경북';
    case '경상남도':
      return '경남';
    case '전라북도':
      return '전북';
    case '전라남도':
      return '전남';
    case '제주특별자치도':
      return '제주';
    default:
      return province;
  }
};

export const CarrierMap = {
  [Carrier.Chunilps]: '천일택배',
  [Carrier.Cjlogistics]: 'CJ 대한통운',
  [Carrier.Cupost]: 'CU 편의점 택배',
  [Carrier.Cvsnet]: 'GS Postbox 택배',
  [Carrier.Cway]: 'CWAY (우리 택배)',
  [Carrier.Daesin]: '대신택배',
  [Carrier.Dhl]: 'DHL',
  [Carrier.Ems]: 'EMS',
  [Carrier.Epost]: '우체국 택배',
  [Carrier.Fedex]: 'Fedex',
  [Carrier.Hanips]: '한의사랑택배',
  [Carrier.Hanjin]: '한진택배',
  [Carrier.Hdexp]: '합동택배',
  [Carrier.Homepick]: '홈픽',
  [Carrier.Honamlogis]: '한서호남택배',
  [Carrier.Ilyanglogis]: '일양로지스',
  [Carrier.Kdexp]: '경동택배',
  [Carrier.Kunyoung]: '건영택배',
  [Carrier.Logen]: '로젠택배',
  [Carrier.Lotte]: '롯데택배',
  [Carrier.Sagawa]: 'Sagawa',
  [Carrier.Slx]: 'SLX',
  [Carrier.Swgexp]: '성원글로벌카고',
  [Carrier.Tnt]: 'TNT',
  [Carrier.Ups]: 'UPS',
  [Carrier.Usps]: 'USPS',
  [Carrier.Yamato]: 'Kuroneko Yamato',
  [Carrier.Yuubin]: 'Japan Post',
};

export const apolloErrorStringifier = (error: ApolloError): string[] => {
  const graphQLErrors = error.graphQLErrors.map(
    graphQLError => `[${graphQLError.extensions?.code}]: ${error.message}`,
  );
  const networkErrors =
    (error.networkError as any)?.result?.errors.map(
      (networkError: any) => `[NETWORK ERROR]: ${networkError.message}`,
    ) || [];

  return [...graphQLErrors, ...networkErrors];
};

export const minToHour = (min: number) => {
  const hour = min / 60;
  const remainder = min % 60;
  let result = '';
  if (hour >= 1) {
    result += `${hour}시간`;
  }

  if (hour >= 1 && remainder >= 1) {
    result += ' ';
  }

  if (remainder >= 1) {
    result += `${remainder}분`;
  }
  return result;
};

export const fuelTypeStringifer = (fuelType: {
  base: Fuel;
  hybrid: boolean;
}): string => {
  let string;

  switch (fuelType.base) {
    case Fuel.Diesel:
      string = '디젤';
      break;
    case Fuel.Electricity:
      string = '전기';
      break;
    case Fuel.Extra:
      string = '기타';
      break;
    case Fuel.Gasoline:
      string = '휘발유';
      break;
    case Fuel.Hydrogen:
      string = '수소 전지';
      break;
    case Fuel.Lpg:
      string = 'LPG';
      break;
  }

  if (fuelType.hybrid) {
    string += ' 하이브리드';
  }

  return string;
};
