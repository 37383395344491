import { LogoutOutlined } from '@ant-design/icons';
import { Button, Popover, Space, Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

import app from '../../../package.json';

import Profile from './Profile';
import SettingDrawer from './SettingDrawer';

import { useAuth, useMe } from '@hooks';

const Sider: React.FC = () => {
  const { logout } = useAuth();
  const { clearMe, readOnly, viewOnly } = useMe();

  const handleLogout = () => {
    logout();
    clearMe();
  };

  return (
    <SContainer>
      <SHeader>
        {readOnly ? (
          <Tag color="magenta">VISITOR ACCESS</Tag>
        ) : (
          viewOnly && <Tag color="purple">ADMIN ACCESS</Tag>
        )}
        <Profile />
        <SButtons>
          <SettingDrawer />
          <LogoutButton onLogout={handleLogout} />
        </SButtons>
        <SVersion>Version {app.version}</SVersion>
      </SHeader>
    </SContainer>
  );
};

interface LogoutButtonProps {
  onLogout: () => void;
}
const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (visible: boolean) => setVisible(visible);

  const close = () => setVisible(false);

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger="click"
      placement="rightBottom"
      title="정말 로그아웃 하시겠습니까?"
      content={
        <Space>
          <Button type="default" onClick={close}>
            취소
          </Button>
          <Button type="primary" danger onClick={onLogout}>
            로그아웃
          </Button>
        </Space>
      }
    >
      <Button type="text" icon={<LogoutOutlined />} />
    </Popover>
  );
};

export default Sider;

const SContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  display: flex;
`;
const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
`;
const SVersion = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;
const SButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
`;
