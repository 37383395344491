import FinishedTable, {
  TFinishedTableRow as tableType,
  FINISHED_COUNSEL_CONNECTION as _FINISHED_COUNSEL_CONNECTION,
  FINISHED_COUNSELS_RESULT as _FINISHED_COUNSELS_RESULT,
} from './FinishedTable';

export type TFinishedTableRow = tableType;
export const FINISHED_COUNSEL_CONNECTION = _FINISHED_COUNSEL_CONNECTION;
export const FINISHED_COUNSELS_RESULT = _FINISHED_COUNSELS_RESULT;
export default FinishedTable;
