import { Modal, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

type ShippingConfirmModalProps = {
  visible: boolean;
  handleClose: () => void;
  cancelPayment: (orderId: string, paymentId: string, reason: string) => void;
  orderId: string;
  paymentId: string;
};

const CancelPaymentModal: React.FC<ShippingConfirmModalProps> = ({
  cancelPayment,
  visible,
  orderId,
  handleClose,
  paymentId,
}) => {
  const [reason, setReason] = React.useState('');
  const [cancelLoading, setCancelLoading] = React.useState(false);

  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleClickCancelButton = async () => {
    if (reason === '') {
      Modal.error({
        title: '취소사유를 입력해주세요.',
      });
      return;
    }
    try {
      setCancelLoading(true);
      await cancelPayment(orderId, paymentId, reason);
      handleClose();
    } catch (err) {
      console.log(err);
      Modal.error({
        title: '알수없는 오류가 발생했습니다.',
        content: '개발팀에 문의해주세요.',
      });
    } finally {
      setCancelLoading(false);
    }
  };

  return (
    <Modal
      title={`주문번호 ${orderId} 결제취소`}
      closable
      visible={visible}
      okText={'결제 취소'}
      onOk={handleClickCancelButton}
      onCancel={handleClose}
      cancelText={'닫기'}
      confirmLoading={cancelLoading}
    >
      <SBody>
        <SRowBox>
          <SLabel>취소 사유 :</SLabel>
          <Input
            onChange={handleChangeReason}
            value={reason}
            placeholder={'내용을 작성하세요'}
          />
        </SRowBox>
      </SBody>
    </Modal>
  );
};

export default CancelPaymentModal;

const SBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const SRowBox = styled.div`
  width: 100%;
  flex: 0 0 32px;
  display: flex;
  margin-bottom: 15px;
`;

const SLabel = styled.div`
  flex: 0 0 96px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 24px;
`;
