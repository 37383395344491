import React from 'react';
import styled from 'styled-components';

import CounselTableTabs from '@components/CounselTableTabs';
import Sider from '@components/Sider';
import { requestPermission } from '@utils/notification';

const HomePresenter: React.FC = () => {
  React.useEffect(() => {
    requestPermission();
  }, []);
  return (
    <HomeContainer>
      <SCounselTableTabs />
      <Sider />
    </HomeContainer>
  );
};

export default HomePresenter;

const HomeContainer = styled.div`
  position: relative;
`;

const SCounselTableTabs = styled(CounselTableTabs)`
  width: 100%;
  height: 100vh;
`;
