import {
  ApolloClient,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import axios from 'axios';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import env from '@utils/env';

const httpLink = createHttpLink({
  uri: env.API_URL,
  credentials: 'include',
});

const wsClient = new SubscriptionClient(env.WS_URL, {
  reconnect: true,
  connectionParams: () => {
    const token = localStorage.getItem('token');
    return {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  },
});

wsClient.onDisconnected(() => {
  console.log('disconnected');
});

wsClient.onReconnecting(() => {
  console.log('reconnecting..');
});

wsClient.onReconnected(() => {
  console.log('reconnected');
});

const wsLink = new WebSocketLink(wsClient);

const combinedLink = new RetryLink().split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext((_, context) => {
  const token = localStorage.getItem('token');
  return {
    ...context,
    headers: {
      ...context.headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case 'EXPIRED_ACCESS_TOKEN': {
            let forward$;
            // eslint-disable-next-line prefer-const
            forward$ = fromPromise(refreshAccessToken()).filter(value =>
              Boolean(value),
            );
            return forward$.flatMap(() => forward(operation));
          }
          case 'INVALID_ACCESS_TOKEN':
            localStorage.removeItem('token');
            logout().finally(() => {
              location.reload();
            });

            break;
          case 'INVALID_REFRESH_TOKEN':
            localStorage.removeItem('token');
            logout().finally(() => {
              location.reload();
            });

            break;
          default:
            console.error(err);
            break;
        }
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${JSON.stringify(
            err.locations,
          )}, Path: ${err.path}`,
        );
      }
    }

    if (networkError) {
      console.log(networkError);
    }
  },
);

export const refreshAccessToken = async () => {
  try {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      return;
    }
    const { data } = await axios.post(
      env.API_URL,
      {
        query: `mutation refreshAccessToken {
        refreshAccessToken {
          accessToken
        }
      }`,
      },
      {
        withCredentials: true,
      },
    );

    const graphqlData = data.data;
    if (graphqlData?.refreshAccessToken?.accessToken) {
      localStorage.setItem('token', graphqlData.refreshAccessToken.accessToken);
    }

    return data?.refreshAccessToken?.accessToken;
  } catch (err) {
    console.error(err);
    return;
  }
};

const logout = async () => {
  await axios.post(env.API_URL, {
    query: `mutation logout {
      logout
    }`,
  });
};

const initClient = (cache: InMemoryCache, withToken = true) => {
  let links = [errorLink, authLink, combinedLink];

  if (!withToken) {
    links = [errorLink, combinedLink];
  }
  const client = new ApolloClient({
    link: from(links),
    cache,
    // typeDefs,
    connectToDevTools: true,
  });

  return client;
};

export default initClient;
