import { Modal, Radio, Select, Input, RadioChangeEvent } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Company } from './PaymentTable';

type ShippingConfirmModalProps = {
  visible: boolean;
  sendPayment: (shop?: Company) => void;
  handleClose: () => void;
  companies: Company[];
};

const ShippingConfirmModal: React.FC<ShippingConfirmModalProps> = ({
  handleClose,
  sendPayment,
  visible,
  companies,
}) => {
  const [shippingTypeSelect, setShippingTypeSelect] = React.useState<
    'recommend' | 'hope'
  >('recommend');
  const [companyId, setCompanyId] = React.useState('');
  const [contact, setContact] = React.useState('');

  const handleChangeShippingType = (e: RadioChangeEvent) => {
    const value = e.target.value as 'recommend' | 'hope';
    setShippingTypeSelect(value);
  };

  const currentSelected = React.useMemo(() => {
    const company = companies.find(val => val.id === companyId);
    if (!company) {
      return '추천한 업체가 없습니다.';
    }
    return `[${company.name}] ${company.address}`;
  }, [companyId, companies]);

  const handleClickOk = () => {
    if (companyId === '' && shippingTypeSelect === 'recommend') {
      Modal.error({
        title: '결제를 요청할 수 없습니다.',
        content: '정비소 정보가 존재하지 않습니다.',
      });
      return;
    }

    if (contact === '' && shippingTypeSelect === 'recommend') {
      Modal.error({
        title: '결제를 요청할 수 없습니다.',
        content: '정비소 연락처 정보를 입력해주세요.',
      });
    }

    const company = companies.find(company => company.id === companyId);
    if (shippingTypeSelect === 'recommend' && company) {
      sendPayment({
        ...company,
        contact,
      });
    } else {
      sendPayment();
    }
  };

  const handleChangeSelect = (companyId: string) => {
    setCompanyId(companyId);
    const targetCompany = companies.find(company => company.id === companyId);
    if (targetCompany) {
      setContact(targetCompany.contact);
    }
  };

  return (
    <Modal
      title={'배송 정보 확인'}
      closable
      visible={visible}
      okText={'이 정보로 결제 요청하기'}
      onOk={handleClickOk}
      okButtonProps={{
        disabled: companies.length === 0 && shippingTypeSelect === 'recommend',
      }}
      onCancel={handleClose}
      cancelText={'닫기'}
    >
      <Body>
        <SelectorBox>
          <RadioTitle>배송지</RadioTitle>
          <Radio.Group
            name="shippingType"
            onChange={handleChangeShippingType}
            value={shippingTypeSelect}
          >
            <Radio value={'recommend'}>닥터차추천</Radio>
            <Radio value={'hope'}>고객 희망지역</Radio>
          </Radio.Group>
        </SelectorBox>
        <ContentBox>
          {shippingTypeSelect === 'recommend' ? (
            <>
              <Select
                style={{ width: '258px' }}
                value={currentSelected}
                onChange={handleChangeSelect}
              >
                {companies.map((company: any) => (
                  <Select.Option
                    key={`selectCompany-${company.id}`}
                    value={company.id}
                  >{`[${company.name}] ${company.address}`}</Select.Option>
                ))}
              </Select>
              <Input
                style={{ width: '258px', marginTop: '10px' }}
                value={contact}
                onChange={e => setContact(e.target.value)}
                placeholder="연락처 (-제외)"
                required
              />
            </>
          ) : (
            <RadioTitle>고객이 결제시 직접 배송지를 입력합니다.</RadioTitle>
          )}
        </ContentBox>
      </Body>
    </Modal>
  );
};

export default ShippingConfirmModal;

const Body = styled.div`
  padding-left: 85px;
`;

const SelectorBox = styled.div``;

const ContentBox = styled.div`
  padding-left: 66px;
  margin-top: 16px;
`;

const RadioTitle = styled.span`
  margin-right: 26px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;
