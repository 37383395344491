import { CreditCardOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

import { dateDotFormatter } from '@utils/formatters';

interface PaymentHeaderProps {
  nickname?: string;
  updatedAt: Date;
}

const PaymentHeader: React.FC<PaymentHeaderProps> = ({
  nickname,
  updatedAt,
}) => {
  return (
    <SContainer>
      <SInfoBox>
        <SIconWrapper>
          <CreditCardOutlined />
        </SIconWrapper>
        <STitle>{nickname ? nickname : '(알수없음)'}님의 견적 / 결제</STitle>
        <SBookingDate>마지막 작성 {dateDotFormatter(updatedAt)}</SBookingDate>
      </SInfoBox>
    </SContainer>
  );
};

export default PaymentHeader;

const SContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 24px;
`;

const SInfoBox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const SIconWrapper = styled.div`
  flex: 0 0 32px;
  height: 32px;
  background-color: #bfbfbf;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const STitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.85);
`;

const SBookingDate = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 12px;
`;
