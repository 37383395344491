import { StarOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { useTemplateShortcutsQuery } from '@utils/client';

gql`
  query templateShortcuts($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      type {
        id
        name
      }
      vehicle {
        id
        brand {
          id
        }
        model {
          id
        }
        submodel {
          id
        }
        grade {
          id
        }
        trim {
          id
        }
        fullModelName
      }
    }
  }
`;

interface TemplateShortcutsProps {
  className?: string;
  counselId: string;
  onChangeDirectory: (directoryId: string) => void;
}
const TemplateShortcuts: React.FC<TemplateShortcutsProps> = ({
  className,
  counselId,
  onChangeDirectory,
}) => {
  const { data } = useTemplateShortcutsQuery({
    variables: { counselId },
  });

  const modelId = React.useMemo(() => {
    const vehicle = data?.counsel?.vehicle;

    if (!vehicle) return null;
    if (vehicle.grade?.id) return `GRADE:${vehicle.grade.id}`;
    if (vehicle.submodel?.id) return `SUBMODEL:${vehicle.submodel.id}`;
    if (vehicle?.model.id) return `MODEL:${vehicle.model.id}`;
    return null;
  }, [data?.counsel?.vehicle]);

  return data ? (
    <STemplateShortcuts className={className}>
      {modelId && (
        <Button
          size="small"
          icon={<StarOutlined />}
          onClick={() => onChangeDirectory(modelId)}
        >
          {data.counsel.vehicle?.fullModelName}
        </Button>
      )}
      <Button
        size="small"
        icon={<StarOutlined />}
        onClick={() => {
          if (data.counsel.type?.id) {
            onChangeDirectory(`INQUIRY_TYPE:${data.counsel.type.id}`);
          }
        }}
      >
        {data?.counsel?.type?.name || '이름 없음'}
      </Button>
    </STemplateShortcuts>
  ) : null;
};

export default TemplateShortcuts;

const STemplateShortcuts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px 16px;
  gap: 8px;

  background-color: #fafafa;
`;
