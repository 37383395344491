import _AppReviewMessage from './AppReviewMessage';
import _AreaInputMessage from './AreaInputMessage';
import _CompanyMessage from './CompanyMessage';
import _DeletedMessage from './DeletedMessage';
import _EndMessage from './EndMessage';
import _EnterMessage from './EnterMessage';
import _ErrorMessage from './ErrorMessage';
import _EstimateMessage from './EstimateMessage';
import _FileMessage from './FileMessage';
import _InvoiceMessage from './InvoiceMessage';
import _LeaveMessage from './LeaveMessage';
import _QueueMessage from './QueueMessage';
import _RestartMessage from './RestartMessage';
import _SuspendedMessage from './SuspendedMessage';
import _TextMessage from './TextMessage';
import _VinInputMessage from './VinInputMessage';

export const TextMessage = _TextMessage;
export const EnterMessage = _EnterMessage;
export const LeaveMessage = _LeaveMessage;
export const FileMessage = _FileMessage;
export const CompanyMessage = _CompanyMessage;
export const AreaInputMessage = _AreaInputMessage;
export const QueueMessage = _QueueMessage;
export const SuspendedMessage = _SuspendedMessage;
export const EstimateMessage = _EstimateMessage;
export const ErrorMessage = _ErrorMessage;
export const InvoiceMessage = _InvoiceMessage;
export const EndMessage = _EndMessage;
export const RestartMessage = _RestartMessage;
export const VinInputMessage = _VinInputMessage;
export const AppReviewMessage = _AppReviewMessage;
export const DeletedMessage = _DeletedMessage;
