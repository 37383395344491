import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** YYYY-MM-DD 형태의 날짜 문자열. */
  Date: any;
  /** UNIX epoch으로부터의 밀리초 형태의 timestamp */
  DateTime: number;
  /** HTML 문자열. */
  Html: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** YYYY-MM-DD 형태의 날짜 */
  LegacyDate: any;
  /** UNIX epoch으로부터의 밀리초 형태의 timestamp */
  LegacyDateTime: any;
};


/** 예약 수락 에러 */
export type AcceptAppointmentError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 예약 수락 실패 응답 */
export type AcceptAppointmentFail = {
  __typename?: 'AcceptAppointmentFail';
  error: AcceptAppointmentError;
};

/** 예약 수락 응답 */
export type AcceptAppointmentResponse = AcceptAppointmentFail | AcceptAppointmentSuccess;

/** 예약 수락 성공 응답 */
export type AcceptAppointmentSuccess = {
  __typename?: 'AcceptAppointmentSuccess';
  /** 수락된 예약 */
  appointment: Appointment;
};

/** 수락 상태의 예약. */
export type AcceptedAppointment = Appointment & {
  __typename?: 'AcceptedAppointment';
  /** 고객. */
  client: AppointmentClient;
  /** 예약번호(유일하지 않음). */
  code: Scalars['String'];
  /** 업체. */
  company: AppointmentCompany;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 상세. */
  detail: AppointmentDetail;
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  estimateRequest?: Maybe<EstimateRequest>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사 및 업체 구성원 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 수정 시점. */
  updatedAt: Scalars['DateTime'];
  /** 차량. */
  vehicle: AppointmentVehicle;
  /** 차량 도착 예정 시점. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

/** 계정. */
export type Account = {
  /**
   * 가능한 인증 방식 목록.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  authenticationMethods: Array<AuthenticationMethodEnum>;
  /**
   * 이메일 주소.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  email?: Maybe<Scalars['String']>;
  /**
   * 계정의 견적 문의 connection.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryConnection: EstimateInquiryConnection;
  /**
   * 계정의 견적 문의 개수.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryCount: Scalars['Int'];
  /** ID. */
  id: Scalars['ID'];
  /** 계정의 마지막 커뮤니티 활동(게시물/댓글/답글 작성) 시점. */
  lastActiveAtCommunity?: Maybe<Scalars['DateTime']>;
  /** 계정에 수신된 알림 connection. */
  notificationConnection: NotificationConnection;
  /**
   * 계정에 수신된 알림 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 및 계정 소유자만 접근 가능.
   */
  notificationCount: Scalars['Int'];
  /**
   * 알림 설정.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  notificationSetting: NotificationSetting;
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  phone?: Maybe<Scalars['String']>;
  /** 프로필. */
  profile: AccountProfile;
};


/** 계정. */
export type AccountEstimateInquiryConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 계정. */
export type AccountNotificationConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 계정. */
export type AccountNotificationCountArgs = {
  filter: GetNotificationCountOfAccountFilterRequestDto;
};

/** 가입하려는 방식으로 이미 가입된 계정이 존재함. */
export type AccountAlreadyExistsError = CreateQaAccountsError & LoginError & {
  __typename?: 'AccountAlreadyExistsError';
  /** 이미 존재하는 계정. */
  account: Account;
  message: Scalars['String'];
};

/** 계정 프로필. */
export type AccountProfile = {
  __typename?: 'AccountProfile';
  /** 아바타 이미지. */
  avatarImage?: Maybe<Image>;
  /** 자기소개. */
  bio?: Maybe<Scalars['String']>;
  /** 닉네임. */
  nickname: Scalars['String'];
};

/** 이메일의 계정이 존재하지 않음. */
export type AccountWithEmailDoesNotExistsError = ResetPasswordError & {
  __typename?: 'AccountWithEmailDoesNotExistsError';
  /** 이메일 주소. */
  email: Scalars['String'];
  message: Scalars['String'];
};

/** OR 조건의 계정 필터. */
export type AccountsFilter = {
  /**
   * 이메일 주소에 포함 되어야 할 검색어.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  email?: Maybe<Scalars['String']>;
  /**
   * 닉네임에 포함 되어야 할 검색어.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  nickname?: Maybe<Scalars['String']>;
  /**
   * 휴대전화번호에 포함 되어야 할 검색어.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  phone?: Maybe<Scalars['String']>;
};

export type AddMemberToCompanyError = {
  message: Scalars['String'];
};

/** 업체 구성원 추가 실패. */
export type AddMemberToCompanyFail = {
  __typename?: 'AddMemberToCompanyFail';
  error: AddMemberToCompanyError;
};

export type AddMemberToCompanyResponse = AddMemberToCompanyFail | AddMemberToCompanySuccess;

/** 업체 구성원 추가 성공. */
export type AddMemberToCompanySuccess = {
  __typename?: 'AddMemberToCompanySuccess';
  /** 추가된 구성원. */
  member: CompanyMember;
};

/** 퀵상담 사용자 추가 성공 response */
export type AddUserToCounselSuccess = {
  __typename?: 'AddUserToCounselSuccess';
  /** 사용자가 추가된 퀵상담 */
  counsel: Counsel;
  /** 추가된 사용자 */
  user: User;
};

/** 작업의 수리 추가 데이터. */
export type AddWorkRepairData = {
  /** 설명. */
  description?: Maybe<Scalars['String']>;
  /**
   * 첨부 파일 목록.
   *
   * ### Constraints
   *
   * 1. 최소1개, 최대 10개의 수리 과정 사진 혹은 영상.
   */
  files: Array<FileInput>;
  /** 수리 유형. */
  type: RepairTypeEnum;
  /** 수리를 추가할 작업 ID. */
  workId: Scalars['ID'];
};

export type AddWorkRepairError = {
  message: Scalars['String'];
};

/** 작업의 수리 추가 실패. */
export type AddWorkRepairFail = {
  __typename?: 'AddWorkRepairFail';
  error: AddWorkRepairError;
};

export type AddWorkRepairResponse = AddWorkRepairFail | AddWorkRepairSuccess;

/** 작업의 수리 추가 성공. */
export type AddWorkRepairSuccess = {
  __typename?: 'AddWorkRepairSuccess';
  /** 작업. */
  work: Work;
};

/** 주소 */
export type Address = {
  __typename?: 'Address';
  /** 좌표 */
  coordinates: Coordinates;
  /** 상세 주소 */
  detail?: Maybe<Scalars['String']>;
  /** 건물명 */
  name?: Maybe<Scalars['String']>;
  /** 지번 주소 */
  region?: Maybe<Scalars['String']>;
  /** 도로명 주소 */
  road?: Maybe<Scalars['String']>;
  /** 우편번호 (국가 기초구역번호) */
  zip?: Maybe<Scalars['String']>;
};

/** 어드민 계정. */
export type AdminAccount = Account & {
  __typename?: 'AdminAccount';
  /**
   * 가능한 인증 방식 목록.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  authenticationMethods: Array<AuthenticationMethodEnum>;
  /**
   * 이메일 주소.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  email?: Maybe<Scalars['String']>;
  /**
   * 계정의 견적 문의 connection.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryConnection: EstimateInquiryConnection;
  /**
   * 계정의 견적 문의 개수.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryCount: Scalars['Int'];
  /** ID. */
  id: Scalars['ID'];
  /** 계정의 마지막 커뮤니티 활동(게시물/댓글/답글 작성) 시점. */
  lastActiveAtCommunity?: Maybe<Scalars['DateTime']>;
  /** 계정에 수신된 알림 connection. */
  notificationConnection: NotificationConnection;
  /**
   * 계정에 수신된 알림 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 및 계정 소유자만 접근 가능.
   */
  notificationCount: Scalars['Int'];
  /**
   * 알림 설정.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  notificationSetting: NotificationSetting;
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  phone?: Maybe<Scalars['String']>;
  /** 프로필. */
  profile: AccountProfile;
};


/** 어드민 계정. */
export type AdminAccountEstimateInquiryConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 어드민 계정. */
export type AdminAccountNotificationConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 어드민 계정. */
export type AdminAccountNotificationCountArgs = {
  filter: GetNotificationCountOfAccountFilterRequestDto;
};

/** 쿠폰 사용 실패 response */
export type AlreadyUsedCouponError = RedeemCouponError & {
  __typename?: 'AlreadyUsedCouponError';
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 스토어 리뷰 요청 메세지 */
export type AppReviewMessage = Message & {
  __typename?: 'AppReviewMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 예약 */
export type Appointment = {
  /** 고객. */
  client: AppointmentClient;
  /** 예약번호(유일하지 않음). */
  code: Scalars['String'];
  /** 업체. */
  company: AppointmentCompany;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 상세. */
  detail: AppointmentDetail;
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  estimateRequest?: Maybe<EstimateRequest>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사 및 업체 구성원 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 수정 시점. */
  updatedAt: Scalars['DateTime'];
  /** 차량. */
  vehicle: AppointmentVehicle;
  /** 차량 도착 예정 시점. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

/** 예약 수락 알림. */
export type AppointmentAcceptedNotification = Notification & {
  __typename?: 'AppointmentAcceptedNotification';
  /** 수락된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 예약 취소 알림. */
export type AppointmentCancelledNotification = Notification & {
  __typename?: 'AppointmentCancelledNotification';
  /** 취소된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 고객 정보 */
export type AppointmentClient = {
  __typename?: 'AppointmentClient';
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  /** 휴대전화번호 */
  phone: Scalars['String'];
};

/** 예약 업체. */
export type AppointmentCompany = {
  __typename?: 'AppointmentCompany';
  /** 주소. */
  address: Scalars['String'];
  /** 연락처. */
  contact: Scalars['String'];
  /** ID. */
  id: Scalars['ID'];
  /** 이름. */
  name: Scalars['String'];
};

/** 예약 상세. */
export type AppointmentDetail = {
  __typename?: 'AppointmentDetail';
  /** 내용. */
  body?: Maybe<Scalars['String']>;
  /** 첨부 파일 목록. */
  files: Array<File>;
  /** 보험 처리 방식. */
  insurance?: Maybe<InsuranceEnum>;
  /** 제목. */
  title?: Maybe<Scalars['String']>;
};

/** 예약 완료 알림. */
export type AppointmentFinishedNotification = Notification & {
  __typename?: 'AppointmentFinishedNotification';
  /** 완료된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 예약 요청 알림. */
export type AppointmentRequestedNotification = Notification & {
  __typename?: 'AppointmentRequestedNotification';
  /** 요청된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 예약 목록 정렬 조건. */
export enum AppointmentSortOrderEnum {
  /** 생성 시점 오름차순 */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 생성 시점 내림차순 */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** 수정 시점 오름차순 */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** 수정 시점 내림차순 */
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** 차량 도착 시점 오름차순. */
  WillArriveAtAsc = 'WILL_ARRIVE_AT_ASC',
  /** 차량 도착 시점 내림차순. */
  WillArriveAtDesc = 'WILL_ARRIVE_AT_DESC'
}

/** 예약 상태 */
export enum AppointmentStatusEnum {
  /** 수락됨 */
  Accepted = 'ACCEPTED',
  /** No-Show */
  Broken = 'BROKEN',
  /** 취소됨 */
  Cancelled = 'CANCELLED',
  /** 완료됨 */
  Finished = 'FINISHED',
  /** 요청됨 */
  Requested = 'REQUESTED'
}

/** 예약 차량 */
export type AppointmentVehicle = {
  __typename?: 'AppointmentVehicle';
  /** 제조사. */
  brand?: Maybe<VehicleBrand>;
  /** 연료 유형 */
  fuelType?: Maybe<FuelType>;
  /** 모델명 */
  modelName?: Maybe<Scalars['String']>;
  /** 차량번호 */
  plateNumber?: Maybe<Scalars['String']>;
  /** 등록연도(연식) */
  registeredYear?: Maybe<Scalars['Int']>;
};

/** 예약 차량 방문 시점 변경 알림. */
export type AppointmentWillArriveAtChangedNotification = Notification & {
  __typename?: 'AppointmentWillArriveAtChangedNotification';
  /** 차량 방문 시점이 변경된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 변경 전 차량 방문 시점. */
  oldWillArriveAt?: Maybe<Scalars['DateTime']>;
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 예약 목록에 대한 AND 조건 목록. */
export type AppointmentsFilter = {
  /** OR 조건 목록. */
  or?: Maybe<AppointmentsOrFilter>;
  /** 예약 상태 목록. */
  statuses?: Maybe<Array<AppointmentStatusEnum>>;
  /** 차량 도착 예정 시점 범위. */
  willArriveAtRange?: Maybe<ClosedDateTimeRangeInput>;
};

export type AppointmentsOrFilter = {
  /**
   * 예약 고객 실명에 포함 되어야 할 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  clientName?: Maybe<Scalars['String']>;
  /**
   * 예약 고객 휴대전화번호에 포함 되어야 할 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  clientPhone?: Maybe<Scalars['String']>;
  /**
   * 예약번호에 포함 되어야 할 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  code?: Maybe<Scalars['String']>;
  /**
   * 예약 업체명에 포함 되어야 할 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  companyName?: Maybe<Scalars['String']>;
  /**
   * 예약 차량 차량번호에 포함 되어야 할 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  vehiclePlateNumber?: Maybe<Scalars['String']>;
};

/** 주문 승인 response */
export type ApproveOrderResponse = ApproveOrderSuccess;

/** 주문 승인 성공 response */
export type ApproveOrderSuccess = {
  __typename?: 'ApproveOrderSuccess';
  /** 승인된 주문 */
  order: OrderBase;
};

/** 영역 */
export type Area = {
  __typename?: 'Area';
  /** 중심 좌표 */
  coordinates: Coordinates;
  /** 중심 장소명 */
  name?: Maybe<Scalars['String']>;
  /** 반지름 (m) */
  radius: Scalars['Int'];
  /** 행정구역 */
  zone?: Maybe<Zone>;
};

/** 영역 */
export type AreaInput = {
  /** 중심 좌표 */
  coordinates: CoordinatesInput;
  /**
   * 중심 장소명
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 32자
   */
  name?: Maybe<Scalars['String']>;
  /**
   * 반지름 (m)
   * 최소 100m, 최대 50km
   */
  radius: Scalars['Int'];
};

/** 희망 정비 지역 입력 메세지 */
export type AreaInputMessage = Message & {
  __typename?: 'AreaInputMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 인증 방식 */
export enum AuthenticationMethodEnum {
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Kakao = 'KAKAO',
  Local = 'LOCAL'
}

/** 자동차관리법시행규칙 제12조에 명시된 자동차정비업의 등급. */
export enum AutomobileRepairBusinessLevel {
  /** 자동차종합정비업(1급) */
  ComprehensiveAutomobileRepairBusiness = 'COMPREHENSIVE_AUTOMOBILE_REPAIR_BUSINESS',
  /** 원동기전문정비업 */
  EngineSpecializedRepairBusiness = 'ENGINE_SPECIALIZED_REPAIR_BUSINESS',
  /** 소형자동차종합정비업(2급) */
  SmallSizeComprehensiveAutomobileRepairBusiness = 'SMALL_SIZE_COMPREHENSIVE_AUTOMOBILE_REPAIR_BUSINESS',
  /** 자동차전문정비업(3급) */
  SpecializedAutomobileRepairBusiness = 'SPECIALIZED_AUTOMOBILE_REPAIR_BUSINESS'
}

/** 비즈 계정. */
export type BizAccount = Account & {
  __typename?: 'BizAccount';
  /**
   * 가능한 인증 방식 목록.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  authenticationMethods: Array<AuthenticationMethodEnum>;
  /** 계정이 연결된 업체 구성원 목록. */
  companyMembers: Array<CompanyMember>;
  /**
   * 이메일 주소.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  email?: Maybe<Scalars['String']>;
  /**
   * 계정의 견적 문의 connection.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryConnection: EstimateInquiryConnection;
  /**
   * 계정의 견적 문의 개수.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryCount: Scalars['Int'];
  /** ID. */
  id: Scalars['ID'];
  /** 계정의 마지막 커뮤니티 활동(게시물/댓글/답글 작성) 시점. */
  lastActiveAtCommunity?: Maybe<Scalars['DateTime']>;
  /** 계정에 수신된 알림 connection. */
  notificationConnection: NotificationConnection;
  /**
   * 계정에 수신된 알림 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 및 계정 소유자만 접근 가능.
   */
  notificationCount: Scalars['Int'];
  /**
   * 알림 설정.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  notificationSetting: NotificationSetting;
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  phone?: Maybe<Scalars['String']>;
  /** 프로필. */
  profile: AccountProfile;
};


/** 비즈 계정. */
export type BizAccountEstimateInquiryConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 비즈 계정. */
export type BizAccountNotificationConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 비즈 계정. */
export type BizAccountNotificationCountArgs = {
  filter: GetNotificationCountOfAccountFilterRequestDto;
};

/** 사용자 차단 성공 response */
export type BlockUserSuccess = {
  __typename?: 'BlockUserSuccess';
  /** 사용자 차단 */
  block: UserBlock;
};

/** 블로그 게시물 */
export type BlogPost = {
  /** ID */
  id: Scalars['ID'];
  /** 부제목 */
  subtitle: Scalars['String'];
  thumbnail: Image;
  /** 제목 */
  title: Scalars['String'];
};

/** 블로그 카테고리 */
export enum BlogPostCategory {
  /** 자동차지식 */
  CarKnowledge = 'CAR_KNOWLEDGE',
  /** 차랄라 */
  Chalala = 'CHALALA',
  /** 고객 인터뷰 */
  Interview = 'INTERVIEW',
  /** 정비지식 */
  RepairKnowledge = 'REPAIR_KNOWLEDGE'
}

/** 블로그 게시물 connection */
export type BlogPostConnection = {
  __typename?: 'BlogPostConnection';
  edges: Array<BlogPostEdge>;
  pageInfo: PageInfo;
};

export type BlogPostEdge = {
  __typename?: 'BlogPostEdge';
  cursor: Scalars['String'];
  node: BlogPost;
};

/** 게시물 이슈 북마크 response */
export type BookmarkPostIssueResponse = BookmarkPostIssueSuccess;

/** 게시물 이슈 북마크 성공 response */
export type BookmarkPostIssueSuccess = {
  __typename?: 'BookmarkPostIssueSuccess';
  /** 북마크된 게시물 이슈 */
  postIssue: PostIssue;
};

/** 게시물 북마크 response */
export type BookmarkPostResponse = BookmarkPostSuccess;

/** 게시물 북마크 성공 response */
export type BookmarkPostSuccess = {
  __typename?: 'BookmarkPostSuccess';
  /** 북마크된 게시물 */
  post: Post;
};

/** 예약 노쇼처리 에러 */
export type BreakAppointmentError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 예약 노쇼처리 실패 응답 */
export type BreakAppointmentFail = {
  __typename?: 'BreakAppointmentFail';
  error: BreakAppointmentError;
};

/** 예약 노쇼처리 응답 */
export type BreakAppointmentResponse = BreakAppointmentFail | BreakAppointmentSuccess;

/** 예약 노쇼처리 성공 응답 */
export type BreakAppointmentSuccess = {
  __typename?: 'BreakAppointmentSuccess';
  /** 노쇼처리된 예약 */
  appointment: Appointment;
};

/** 파기 상태의 예약. */
export type BrokenAppointment = Appointment & {
  __typename?: 'BrokenAppointment';
  /** 고객. */
  client: AppointmentClient;
  /** 예약번호(유일하지 않음). */
  code: Scalars['String'];
  /** 업체. */
  company: AppointmentCompany;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 상세. */
  detail: AppointmentDetail;
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  estimateRequest?: Maybe<EstimateRequest>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사 및 업체 구성원 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 수정 시점. */
  updatedAt: Scalars['DateTime'];
  /** 차량. */
  vehicle: AppointmentVehicle;
  /** 차량 도착 예정 시점. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

/** 영업시간 */
export type BusinessHours = {
  __typename?: 'BusinessHours';
  /** 추가 설명 */
  description?: Maybe<Scalars['String']>;
  /** 금요일 영업시간, null일 경우 휴무 */
  friday?: Maybe<TimeRange>;
  /** 공휴일 영업시간, null일 경우 휴무 */
  holiday?: Maybe<TimeRange>;
  /** 월요일 영업시간, null일 경우 휴무 */
  monday?: Maybe<TimeRange>;
  /** 토요일 영업시간, null일 경우 휴무 */
  saturday?: Maybe<TimeRange>;
  /** 일요일 영업시간, null일 경우 휴무 */
  sunday?: Maybe<TimeRange>;
  /** 목요일 영업시간, null일 경우 휴무 */
  thursday?: Maybe<TimeRange>;
  /** 화요일 영업시간, null일 경우 휴무 */
  tuesday?: Maybe<TimeRange>;
  /** 수요일 영업시간, null일 경우 휴무 */
  wednesday?: Maybe<TimeRange>;
};

/** 버튼 스타일 */
export type ButtonStyle = {
  __typename?: 'ButtonStyle';
  /** 배경 색상 */
  backgroundColor?: Maybe<Scalars['String']>;
  /** 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 텍스트 색상 */
  textColor?: Maybe<Scalars['String']>;
};

/** 결제자 */
export type Buyer = {
  __typename?: 'Buyer';
  /** 연락처 */
  contact?: Maybe<Scalars['String']>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
};

/** 예약 취소 에러 */
export type CancelAppointmentError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 예약 취소 실패 응답 */
export type CancelAppointmentFail = {
  __typename?: 'CancelAppointmentFail';
  error: CancelAppointmentError;
};

/** 예약 취소 응답 */
export type CancelAppointmentResponse = CancelAppointmentFail | CancelAppointmentSuccess;

/** 예약 취소 성공 응답 */
export type CancelAppointmentSuccess = {
  __typename?: 'CancelAppointmentSuccess';
  /** 취소된 예약 */
  appointment: Appointment;
};

/** 환불 성공 response */
export type CancelPaymentSuccess = {
  __typename?: 'CancelPaymentSuccess';
  /** 환불된 결제 */
  payment: Payment;
};

/** 취소 상태의 예약. */
export type CancelledAppointment = Appointment & {
  __typename?: 'CancelledAppointment';
  /** 취소 시점. */
  cancelledAt: Scalars['DateTime'];
  /** 취소 사유. */
  cancelledReason: Scalars['String'];
  /** 고객. */
  client: AppointmentClient;
  /** 예약번호(유일하지 않음). */
  code: Scalars['String'];
  /** 업체. */
  company: AppointmentCompany;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 상세. */
  detail: AppointmentDetail;
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  estimateRequest?: Maybe<EstimateRequest>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사 및 업체 구성원 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 수정 시점. */
  updatedAt: Scalars['DateTime'];
  /** 차량. */
  vehicle: AppointmentVehicle;
  /** 차량 도착 예정 시점. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

/** 카드 */
export type Card = {
  __typename?: 'Card';
  /** 카드사 */
  company: Scalars['String'];
  /** 카드 번호 */
  number: Scalars['String'];
};

/** 택배사 */
export enum Carrier {
  /** 천일택배 */
  Chunilps = 'CHUNILPS',
  /** CJ 대한통운 */
  Cjlogistics = 'CJLOGISTICS',
  /** CU 편의점 택배 */
  Cupost = 'CUPOST',
  /** GS Postbox 택배 */
  Cvsnet = 'CVSNET',
  /** CWAY (우리 택배) */
  Cway = 'CWAY',
  /** 대신택배 */
  Daesin = 'DAESIN',
  /** DHL */
  Dhl = 'DHL',
  /** EMS */
  Ems = 'EMS',
  /** 우체국 택배 */
  Epost = 'EPOST',
  /** Fedex */
  Fedex = 'FEDEX',
  /** 한의사랑택배 */
  Hanips = 'HANIPS',
  /** 한진택배 */
  Hanjin = 'HANJIN',
  /** 합동택배 */
  Hdexp = 'HDEXP',
  /** 홈픽 */
  Homepick = 'HOMEPICK',
  /** 한서호남택배 */
  Honamlogis = 'HONAMLOGIS',
  /** 일양로지스 */
  Ilyanglogis = 'ILYANGLOGIS',
  /** 경동택배 */
  Kdexp = 'KDEXP',
  /** 건영택배 */
  Kunyoung = 'KUNYOUNG',
  /** 로젠택배 */
  Logen = 'LOGEN',
  /** 롯데택배 */
  Lotte = 'LOTTE',
  /** Sagawa */
  Sagawa = 'SAGAWA',
  /** SLX */
  Slx = 'SLX',
  /** 성원글로벌카고 */
  Swgexp = 'SWGEXP',
  /** TNT */
  Tnt = 'TNT',
  /** UPS */
  Ups = 'UPS',
  /** USPS */
  Usps = 'USPS',
  /** Kuroneko Yamato */
  Yamato = 'YAMATO',
  /** Japan Post */
  Yuubin = 'YUUBIN'
}

export type ChangeAccountNotificationSettingData = {
  /** 예약 알림 수신 여부. */
  appointment?: Maybe<Scalars['Boolean']>;
  /** 커뮤니티 알림 수신 여부. */
  community?: Maybe<Scalars['Boolean']>;
  /** 상담 알림 수신 여부. */
  counsel?: Maybe<Scalars['Boolean']>;
  /** 마케팅 알림 수신 여부. */
  marketing?: Maybe<Scalars['Boolean']>;
};

export type ChangeAccountProfileData = {
  /** 아바타 이미지 URL. */
  avatarImageUrl?: Maybe<Scalars['String']>;
  /**
   * 자기소개.
   *
   * ### Constraints
   *
   * 1. 1자 이상, 1000자 이하.
   */
  bio?: Maybe<Scalars['String']>;
  /**
   * 닉네임
   *
   * ### Constraints
   *
   * 1. 2자 이상, 10자 이하의 한글, 알파벳, 숫자로 구성된 문자열.
   */
  nickname?: Maybe<Scalars['String']>;
};

export type ChangeCompanyMemberRoleError = {
  message: Scalars['String'];
};

/** 업체 구성원 역할 변경 실패. */
export type ChangeCompanyMemberRoleFail = {
  __typename?: 'ChangeCompanyMemberRoleFail';
  error: ChangeCompanyMemberRoleError;
};

export type ChangeCompanyMemberRoleResponse = ChangeCompanyMemberRoleFail | ChangeCompanyMemberRoleSuccess;

/** 업체 구성원 역할 변경 성공. */
export type ChangeCompanyMemberRoleSuccess = {
  __typename?: 'ChangeCompanyMemberRoleSuccess';
  /** 역할이 변경된 구성원. */
  member: CompanyMember;
};

export type ChangeMyNotificationSettingError = {
  message: Scalars['String'];
};

/** 알림 설정 변경 실패. */
export type ChangeMyNotificationSettingFail = {
  __typename?: 'ChangeMyNotificationSettingFail';
  error: ChangeMyNotificationSettingError;
};

export type ChangeMyNotificationSettingResponse = ChangeMyNotificationSettingFail | ChangeMyNotificationSettingSuccess;

/** 알림 설정 변경 성공. */
export type ChangeMyNotificationSettingSuccess = {
  __typename?: 'ChangeMyNotificationSettingSuccess';
  /** 알림 설정이 변경된 계정. */
  account: Account;
};

export type ChangeMyPhoneError = {
  message: Scalars['String'];
};

/** 휴대전화번호 변경 실패. */
export type ChangeMyPhoneFail = {
  __typename?: 'ChangeMyPhoneFail';
  error: ChangeMyPhoneError;
};

export type ChangeMyPhoneResponse = ChangeMyPhoneFail | ChangeMyPhoneSuccess;

/** 휴대전화번호 변경 성공. */
export type ChangeMyPhoneSuccess = {
  __typename?: 'ChangeMyPhoneSuccess';
  /** 변경된 휴대전화번호. */
  phone: Scalars['String'];
};

export type ChangeMyProfileError = {
  message: Scalars['String'];
};

/** 프로필 변경 실패. */
export type ChangeMyProfileFail = {
  __typename?: 'ChangeMyProfileFail';
  error: ChangeMyProfileError;
};

export type ChangeMyProfileResponse = ChangeMyProfileFail | ChangeMyProfileSuccess;

/** 프로필 변경 성공. */
export type ChangeMyProfileSuccess = {
  __typename?: 'ChangeMyProfileSuccess';
  /** 프로필이 변경된 계정. */
  account: Account;
};

/** 커뮤니티 게시물 이슈 상태 완료 변경 에러 */
export type ChangePostIssueToDoneError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 커뮤니티 게시물 이슈 상태 완료 변경 실패 response */
export type ChangePostIssueToDoneFail = {
  __typename?: 'ChangePostIssueToDoneFail';
  /** 커뮤니티 게시물 이슈 상태 완료 변경 에러 */
  error: ChangePostIssueToDoneError;
};

/** 커뮤니티 게시물 이슈 상태 완료 변경 response */
export type ChangePostIssueToDoneResponse = ChangePostIssueToDoneFail | ChangePostIssueToDoneSuccess;

/** 커뮤니티 게시물 이슈 상태 완료 변경 성공 response */
export type ChangePostIssueToDoneSuccess = {
  __typename?: 'ChangePostIssueToDoneSuccess';
  /** 상태가 완료로 변경된 커뮤니티 게시물 이슈 */
  postIssue: PostIssue;
};

/** 작업의 예상 출고일 수정 데이터. */
export type ChangeWorkExpectedDateOfReleaseData = {
  /**
   * 예상 출고일.
   *
   * ### Constraints
   *
   * 1. YYYY-MM-DD 형식
   */
  expectedDateOfRelease: Scalars['Date'];
  /** 예상 출고일을 수정할 작업 ID. */
  workId: Scalars['ID'];
};

export type ChangeWorkExpectedDateOfReleaseError = {
  message: Scalars['String'];
};

/** 작업 예상 출고일 수정 실패. */
export type ChangeWorkExpectedDateOfReleaseFail = {
  __typename?: 'ChangeWorkExpectedDateOfReleaseFail';
  error: ChangeWorkExpectedDateOfReleaseError;
};

export type ChangeWorkExpectedDateOfReleaseResponse = ChangeWorkExpectedDateOfReleaseFail | ChangeWorkExpectedDateOfReleaseSuccess;

/** 작업 예상 출고일 수정 성공. */
export type ChangeWorkExpectedDateOfReleaseSuccess = {
  __typename?: 'ChangeWorkExpectedDateOfReleaseSuccess';
  /** 작업. */
  work: Work;
};

/** 작업의 수리 수정 데이터. */
export type ChangeWorkRepairData = {
  /**
   * 설명.
   *
   * ### Constraints
   *
   * 1. 최대 100자.
   */
  description?: Maybe<Scalars['ID']>;
  /**
   * 첨부 파일 목록.
   *
   * ### Constraints
   *
   * 1. 최소1개, 최대 10개의 수리 과정 사진 혹은 영상.
   */
  files?: Maybe<Array<FileInput>>;
  /** 수정할 수리 ID. */
  repairId: Scalars['ID'];
  /** 수리 유형. */
  type?: Maybe<RepairTypeEnum>;
  /** 수리를 수정할 작업 ID. */
  workId: Scalars['ID'];
};

export type ChangeWorkRepairError = {
  message: Scalars['String'];
};

/** 작업의 수리 추가 실패. */
export type ChangeWorkRepairFail = {
  __typename?: 'ChangeWorkRepairFail';
  error: ChangeWorkRepairError;
};

export type ChangeWorkRepairResponse = ChangeWorkRepairFail | ChangeWorkRepairSuccess;

/** 작업의 수리 추가 성공. */
export type ChangeWorkRepairSuccess = {
  __typename?: 'ChangeWorkRepairSuccess';
  /** 작업. */
  work: Work;
};

/** 채팅의 링크가 있는 댓글 */
export type ChatOpenedComment = CommentBase & {
  __typename?: 'ChatOpenedComment';
  author?: Maybe<User>;
  /** 본문 */
  body: Scalars['String'];
  /** 댓글에 연결된 채팅링크 */
  counsel?: Maybe<Counsel>;
  /** 생성 timestamp */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  parent?: Maybe<CommentBase>;
  post?: Maybe<Post>;
  replyConnection?: Maybe<CommentConnection>;
  replyCount?: Maybe<Scalars['Int']>;
  /** 수정 timestamp */
  updatedAt: Scalars['LegacyDateTime'];
  viewed?: Maybe<Scalars['Boolean']>;
};


/** 채팅의 링크가 있는 댓글 */
export type ChatOpenedCommentReplyConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** 닫힌 시점 범위. */
export type ClosedDateTimeRangeInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

/** 게시물 댓글 */
export type Comment = CommentBase & {
  __typename?: 'Comment';
  author?: Maybe<User>;
  /** 본문 */
  body: Scalars['String'];
  companies: Array<Company>;
  /** 생성 timestamp */
  createdAt: Scalars['LegacyDateTime'];
  files: Array<File>;
  /** ID */
  id: Scalars['ID'];
  parent?: Maybe<CommentBase>;
  post?: Maybe<Post>;
  products: Array<Product>;
  reacted?: Maybe<ReactionType>;
  reactionCount: Scalars['Int'];
  reactionTypes: Array<ReactionType>;
  replyConnection?: Maybe<CommentConnection>;
  replyCount?: Maybe<Scalars['Int']>;
  /** 수정 timestamp */
  updatedAt: Scalars['LegacyDateTime'];
  viewed?: Maybe<Scalars['Boolean']>;
  votes: Array<Vote>;
};


/** 게시물 댓글 */
export type CommentReactionCountArgs = {
  filter: ReactionCountFilter;
};


/** 게시물 댓글 */
export type CommentReplyConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/**
 * 게시물 댓글
 * * counselId가 있는 경우는 ChatOpenedComment
 * * deletedAt이 있는 경우는 DeletedComment
 * * 그 외 기본타입은 Comment
 */
export type CommentBase = {
  author?: Maybe<User>;
  /** 생성 timestamp */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  parent?: Maybe<CommentBase>;
  post?: Maybe<Post>;
  replyConnection?: Maybe<CommentConnection>;
  replyCount?: Maybe<Scalars['Int']>;
  /** 수정 timestamp */
  updatedAt: Scalars['LegacyDateTime'];
  viewed?: Maybe<Scalars['Boolean']>;
};


/**
 * 게시물 댓글
 * * counselId가 있는 경우는 ChatOpenedComment
 * * deletedAt이 있는 경우는 DeletedComment
 * * 그 외 기본타입은 Comment
 */
export type CommentBaseReplyConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CommentBaseEdge = {
  __typename?: 'CommentBaseEdge';
  cursor: Scalars['String'];
  node: CommentBase;
};

/** 댓글 connection */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges: Array<CommentBaseEdge>;
  pageInfo: PageInfo;
};

/** 게시물 작성자의 답변 만족도 */
export type CommentSatisfaction = {
  __typename?: 'CommentSatisfaction';
  /** 글쓴이가 가장 도움이 되었다고 선택한 댓글 작성자 */
  bestCommenter?: Maybe<User>;
  /** 게시물 작성자가 직접 입력한 답변에 대한 만족도 상세내용 */
  body?: Maybe<Scalars['String']>;
};

/** 유저가 댓글에 누른 투표 */
export type CommentVote = Vote & {
  __typename?: 'CommentVote';
  comment: Comment;
  /** 투표 유형 */
  type: VoteType;
  voter: User;
};

/** 커뮤니티 댓글 신고. */
export type CommunityCommentReport = CommunityReport & {
  __typename?: 'CommunityCommentReport';
  /** 신고가 접수된 댓글. */
  comment?: Maybe<Comment>;
  /** ID. */
  id: Scalars['ID'];
  /** 신고 사유. */
  reason: Scalars['String'];
  /** 접수 시각. */
  submittedAt: Scalars['DateTime'];
};

export type CommunityPostConnectionFilter = {
  /** 차량 모델 필터 목록. */
  vehicleModels?: Maybe<Array<VehicleModelFilter>>;
  /** 댓글 포함 여부. */
  withComment?: Maybe<Scalars['Boolean']>;
  /** 닥터차 답변 포함 여부 */
  withCounselorComment?: Maybe<Scalars['Boolean']>;
};

/** 커뮤니티 게시물 신고. */
export type CommunityPostReport = CommunityReport & {
  __typename?: 'CommunityPostReport';
  /** ID. */
  id: Scalars['ID'];
  /** 신고가 접수된 게시물. */
  post?: Maybe<Post>;
  /** 신고 사유. */
  reason: Scalars['String'];
  /** 접수 시각. */
  submittedAt: Scalars['DateTime'];
};

/** 커뮤니티 답글 신고. */
export type CommunityReplyReport = CommunityReport & {
  __typename?: 'CommunityReplyReport';
  /** ID. */
  id: Scalars['ID'];
  /** 신고 사유. */
  reason: Scalars['String'];
  /** 신고가 접수된 답글. */
  reply?: Maybe<Comment>;
  /** 접수 시각. */
  submittedAt: Scalars['DateTime'];
};

/** 커뮤니티 신고 */
export type CommunityReport = {
  /** ID. */
  id: Scalars['ID'];
  /** 신고 사유. */
  reason: Scalars['String'];
  /** 접수 시각. */
  submittedAt: Scalars['DateTime'];
};

/** 커뮤니티 사용자 신고. */
export type CommunityUserReport = CommunityReport & {
  __typename?: 'CommunityUserReport';
  /** ID. */
  id: Scalars['ID'];
  /** 신고 사유. */
  reason: Scalars['String'];
  /** 접수 시각. */
  submittedAt: Scalars['DateTime'];
  /** 신고가 접수된 사용자. */
  user?: Maybe<User>;
};

export type CompaniesFilter = {
  /**
   * 주소
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  address?: Maybe<Scalars['String']>;
  /**
   * 영역
   *
   * ### Constraints
   *
   * 1. 정렬 방식이 `DISTNANCE_ASC` 또는 `DISTANCE_DESC`인 경우 필수.
   */
  area?: Maybe<AreaInput>;
  /** 인증 업체 여부 */
  certificated?: Maybe<Scalars['Boolean']>;
  /** 파트너 업체 여부 */
  isPartner?: Maybe<Scalars['Boolean']>;
  /** 업체 전문분야 ID 목록 */
  specialtyIds?: Maybe<Array<Scalars['ID']>>;
  /**
   * 검색어 (업체명, 연락처, 메모)
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자
   */
  term?: Maybe<Scalars['String']>;
  /** 업체 종류 */
  type?: Maybe<CompanyType>;
};

/** 업체 */
export type Company = {
  __typename?: 'Company';
  /** 업체 주소 */
  address: Address;
  /** 예약 개수. */
  appointmentCount: Scalars['Int'];
  /** 예약 목록. */
  appointments: Array<Appointment>;
  /** 업체 소개 */
  bio?: Maybe<Scalars['String']>;
  /** 사업자등록번호 */
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  /** 대표 이름 */
  businessRepresentativeName?: Maybe<Scalars['String']>;
  /** 인증 업체 여부 */
  certificated: Scalars['Boolean'];
  /** 업체 연락처 */
  contact: Scalars['String'];
  /** 생성 TIMESTAMP */
  createdAt: Scalars['DateTime'];
  /**
   * 업체에 요청된 견적 요청 connection.
   *
   * ### Constraints
   *
   * 1. 관리자, 업체 구성원 비즈 계정만 접근 가능.
   */
  estimateRequestConnection: EstimateRequestConnection;
  /** 업체에 요청된 견적 요청 갯수. */
  estimateRequestCount: Scalars['Int'];
  /** 업체에게 요청된 견적 요청 목록. */
  estimateRequests: Array<EstimateRequest>;
  /** 업체 보조 연락처 */
  extraContact?: Maybe<Scalars['String']>;
  /** 업체 시설 */
  facilities: Array<CompanyFacility>;
  /** 취급 차량 국적 */
  handleNationality: HandleNationality;
  /** 업체 ID */
  id: Scalars['ID'];
  /** 업체 이미지 목록 */
  images: Array<Image>;
  /** 파트너 업체 여부 */
  isPartner: Scalars['Boolean'];
  /** 마지막으로 편집한 시간 */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /** 마지막 편집자 */
  lastEditor?: Maybe<Account>;
  /** 구성원 목록. */
  members: Array<CompanyMember>;
  /** 메카닉 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 상담 및 커뮤니티에서의 언급 횟수. */
  mentionCount: Scalars['Int'];
  /** 업체 이름 */
  name: Scalars['String'];
  /** 업체 운영 시간 */
  operatingTime?: Maybe<OperatingTime>;
  /** 업체 추천 이유 */
  recommendations: Array<CompanyRecommendation>;
  /** 업체 전문 분야 목록 */
  specialties: Array<CompanySpecialty>;
  /** 업체 유형 */
  type: CompanyType;
  /** 수정 TIMESTAMP */
  updatedAt: Scalars['DateTime'];
  /** 작업 개수. */
  workCount: Scalars['Int'];
  /** 작업 목록. */
  works: Array<Work>;
  /** 업체의 행정구역 */
  zone?: Maybe<Zone>;
};


/** 업체 */
export type CompanyAppointmentCountArgs = {
  filter?: Maybe<GetAppointmentsOfCompanyRequestFilterDto>;
};


/** 업체 */
export type CompanyAppointmentsArgs = {
  filter?: Maybe<GetAppointmentsOfCompanyRequestFilterDto>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: AppointmentSortOrderEnum;
};


/** 업체 */
export type CompanyEstimateRequestConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CompanyEstimateRequestFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 업체 */
export type CompanyEstimateRequestCountArgs = {
  filter?: Maybe<CompanyEstimateRequestFilter>;
};


/** 업체 */
export type CompanyEstimateRequestsArgs = {
  filter?: Maybe<CompanyEstimateRequestFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: EstimateRequestSortOrderEnum;
};


/** 업체 */
export type CompanyWorkCountArgs = {
  filter?: Maybe<WorksOfCompanyFilter>;
};


/** 업체 */
export type CompanyWorksArgs = {
  filter: WorksOfCompanyFilter;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: WorkSortOrderEnum;
};

export type CompanyEstimateRequestFilter = {
  /** 견적 요청 응답 여부. */
  responsed?: Maybe<Scalars['Boolean']>;
  /**
   * 견적 문의의 고객명, 고객 휴대전화번호, 차량 모델명, 차량번호에 대한 검색어.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  term?: Maybe<Scalars['String']>;
};

/** 업체 추천 이유 */
export type CompanyFacility = {
  __typename?: 'CompanyFacility';
  /** 시설 아이콘 url */
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

/** 업체 구성원. */
export type CompanyMember = {
  __typename?: 'CompanyMember';
  /** 구성원에 연결된 계정. */
  account?: Maybe<Account>;
  /** 구성원이 속한 업체. */
  company: Company;
  /** ID. */
  id: Scalars['ID'];
  /** 역할. */
  role: CompanyMemberRoleEnum;
};

/** 업체 구성원 역할. */
export enum CompanyMemberRoleEnum {
  /** 관리자. */
  Manager = 'MANAGER',
  /** 스태프. */
  Staff = 'STAFF'
}

/** 업체 메세지 */
export type CompanyMessage = Message & {
  __typename?: 'CompanyMessage';
  author?: Maybe<User>;
  company?: Maybe<Company>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 업체 추천 이유 */
export type CompanyRecommendation = {
  __typename?: 'CompanyRecommendation';
  /** 부연설명 */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** 제목 */
  title: Scalars['String'];
};

/** 업체 검색 프리셋. */
export enum CompanySearchPreset {
  /** 정밀 장비를 보유한 */
  Equipment = 'EQUIPMENT',
  /** 공임비만 받는 */
  Gongim = 'GONGIM',
  /** 업력이 오래된 */
  Old = 'OLD',
  /** 품질을 인증받은 */
  Quality = 'QUALITY',
  /** 전문가가 추천하는 */
  Recommend = 'RECOMMEND',
  /** 기술력을 인정받은 */
  TechnicalSkill = 'TECHNICAL_SKILL'
}

/** 업체 목록 정렬 조건. */
export enum CompanySortOrderEnum {
  /** 거리 오름차순 */
  DistnaceAsc = 'DISTNACE_ASC',
  /** 거리 내림차순 */
  DistnaceDesc = 'DISTNACE_DESC',
  /** 언급 횟수 오름차순 */
  MentionCountAsc = 'MENTION_COUNT_ASC',
  /** 언급 횟수 내림차순 */
  MentionCountDesc = 'MENTION_COUNT_DESC',
  /** 이름 오름차순 */
  NameAsc = 'NAME_ASC',
  /** 이름 내림차순 */
  NameDesc = 'NAME_DESC'
}

/** 업체 전문 분야 */
export type CompanySpecialty = {
  __typename?: 'CompanySpecialty';
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

/** 업체 유형 */
export enum CompanyType {
  /** 정비소 */
  CarCenter = 'CAR_CENTER',
  /** 기타업체 */
  Etc = 'ETC',
  /** 부품 대리점 */
  PartsDistributor = 'PARTS_DISTRIBUTOR',
  /** 정비공장 */
  RepairFactory = 'REPAIR_FACTORY',
  /** 정비공장/일반정비 */
  RepairFactoryGeneral = 'REPAIR_FACTORY_GENERAL',
  /** 서비스센터 */
  ServiceCenter = 'SERVICE_CENTER'
}

export type CompanyWithMembersMustHaveAtLeastOneManagerError = AddMemberToCompanyError & ChangeCompanyMemberRoleError & {
  __typename?: 'CompanyWithMembersMustHaveAtLeastOneManagerError';
  message: Scalars['String'];
};

export type CompanyWithTheSameNameAndContactExistsError = CreateCompanyError & UpdateCompanyError & {
  __typename?: 'CompanyWithTheSameNameAndContactExistsError';
  company: Company;
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 고객 문의 */
export type Complaint = {
  __typename?: 'Complaint';
  author?: Maybe<User>;
  /** 내용 */
  body: Scalars['String'];
  counsel?: Maybe<Counsel>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 첨부 파일 목록 */
  files: Array<File>;
  /** ID */
  id: Scalars['ID'];
  order?: Maybe<OrderBase>;
  response?: Maybe<ComplaintResponse>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
};

/** 고객 문의 connection */
export type ComplaintConnection = {
  __typename?: 'ComplaintConnection';
  edges: Array<ComplaintEdge>;
  pageInfo: PageInfo;
};

export type ComplaintConnectionFilter = {
  /** 답변 조회 여부 */
  viewedResponse?: Maybe<Scalars['Boolean']>;
};

export type ComplaintEdge = {
  __typename?: 'ComplaintEdge';
  cursor: Scalars['String'];
  node: Complaint;
};

/** 고객 문의 답변 */
export type ComplaintResponse = {
  __typename?: 'ComplaintResponse';
  author?: Maybe<User>;
  /** 내용 */
  body: Scalars['String'];
  complaint: Complaint;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 첨부 파일 목록 */
  files: Array<File>;
  /** ID */
  id: Scalars['ID'];
  viewed: Scalars['Boolean'];
};

export type ComplaintsFilter = {
  /** 응답/미응답 여부 */
  responsed?: Maybe<Scalars['Boolean']>;
  /**
   * 제목과 본문에 대한 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  term?: Maybe<Scalars['String']>;
};

/** 고객문의 목록 정렬 조건 */
export enum ComplaintsOrder {
  /** 고객문의글 생성 시각 기준 오름차순 정렬 */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 고객문의글 생성 시각 기준 내림차순 정렬 */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** 결제 완료 요청 response */
export type CompletePaymentResponse = PaymentCompleted | PaymentUncompleted;

/** 배송 완료 성공 response */
export type CompleteShippingSuccess = {
  __typename?: 'CompleteShippingSuccess';
  /** 완료된 배송 */
  shipping: Shipping;
};

/** 작업 완료 입력 데이터. */
export type CompleteWorkData = {
  /**
   * 첨부 이미지 목록.
   *
   * ### Constraints
   *
   * 1. 최대 10개의 수리내역서 사진.
   */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /**
   * 공임 비용.
   *
   * ### Constraints
   *
   * 1. 최소 0, 최대 4,294,967,295.
   */
  laborCost?: Maybe<Scalars['Float']>;
  /**
   * 부품 비용.
   *
   * ### Constraints
   *
   * 1. 최소 0, 최대 4,294,967,295.
   */
  partsCost?: Maybe<Scalars['Float']>;
  /** 완료 상태로 변경할 작업 ID. */
  workId: Scalars['ID'];
};

export type CompleteWorkError = {
  message: Scalars['String'];
};

/** 작업 완료 처리 실패. */
export type CompleteWorkFail = {
  __typename?: 'CompleteWorkFail';
  error: CompleteWorkError;
};

export type CompleteWorkResponse = CompleteWorkFail | CompleteWorkSuccess;

/** 작업 완료 처리 성공. */
export type CompleteWorkSuccess = {
  __typename?: 'CompleteWorkSuccess';
  /** 작업. */
  work: Work;
};

export type ConnectionPageInfo = {
  __typename?: 'ConnectionPageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

/** 좌표 */
export type Coordinates = {
  __typename?: 'Coordinates';
  /** 위도 */
  latitude: Scalars['Float'];
  /** 경도 */
  longitude: Scalars['Float'];
};

/** 좌표 정보 */
export type CoordinatesInput = {
  /** 위도 */
  latitude: Scalars['Float'];
  /** 경도 */
  longitude: Scalars['Float'];
};

/** 퀵상담 */
export type Counsel = Inquiry & {
  __typename?: 'Counsel';
  /** 정비 희망 지역 목록 */
  areas: Array<Area>;
  /** 내용 */
  body: Scalars['String'];
  bookmarkedUntil?: Maybe<Scalars['LegacyDateTime']>;
  counselee?: Maybe<User>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 상담을 생성한 사용자 */
  creator?: Maybe<User>;
  /**
   * 주문 목록
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  cursors: Array<Cursor>;
  files: Array<File>;
  /** 종료 TIMESTAMP */
  finishedAt?: Maybe<Scalars['LegacyDateTime']>;
  /** ID */
  id: Scalars['ID'];
  /** 상담 번호 */
  index: Scalars['Int'];
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  /**
   * 참여자 목록
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  members: Array<User>;
  /**
   * 메모
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  memo?: Maybe<Scalars['String']>;
  /**
   * 메세지 connection
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  messageConnection: MessageConnection;
  mileage?: Maybe<Scalars['Int']>;
  /**
   * 주문 목록
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  orders: Array<OrderBase>;
  /** 상담 연관관계 */
  post?: Maybe<Post>;
  /** 대기 사유 */
  queueReason?: Maybe<QueueReason>;
  /** 대기 시작 시점 TIMESTAMP */
  queuedFrom?: Maybe<Scalars['LegacyDateTime']>;
  /**
   * 요청자의 리뷰
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  review?: Maybe<CounselReview>;
  /** 채팅 메시지로 보내진 닥터차 추천 배송지 업체 목록 */
  sentCompanies: Array<Company>;
  /** 상담 상태 */
  status: CounselStatus;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 문의 유형 */
  type?: Maybe<InquiryType>;
  /**
   * 요청자의 읽지 않은 메세지 수
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  unreadCount: Scalars['Int'];
  /** 문의 차량. */
  vehicle?: Maybe<Vehicle>;
};


/** 퀵상담 */
export type CounselMessageConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** 퀵상담 connection */
export type CounselConnection = {
  __typename?: 'CounselConnection';
  edges: Array<CounselEdge>;
  pageInfo: PageInfo;
};

export type CounselEdge = {
  __typename?: 'CounselEdge';
  cursor: Scalars['String'];
  node: Counsel;
};

export type CounselMeta = {
  __typename?: 'CounselMeta';
  total: Scalars['Int'];
  unreads: Scalars['Int'];
};

/** 상담 리뷰 */
export type CounselReview = {
  __typename?: 'CounselReview';
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 추가 설명 */
  description?: Maybe<Scalars['String']>;
  /** 만족도 */
  satisfaction: CounselSatisfaction;
  /** 문제 해결 여부  */
  solved: Scalars['Boolean'];
  /** 수정 TIMESTAMP */
  updatedAt: Scalars['LegacyDateTime'];
};

/** 상담에 대한 리뷰 요청 알림. */
export type CounselReviewNotification = Notification & {
  __typename?: 'CounselReviewNotification';
  /** 리뷰가 요청된 상담. */
  counsel: Counsel;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 상담 만족도 */
export enum CounselSatisfaction {
  /** 나쁨 */
  Bad = 'BAD',
  /** 좋음 */
  Good = 'GOOD',
  /** 중립 */
  Neutral = 'NEUTRAL'
}

/** 상담 상태 */
export enum CounselStatus {
  /** 견적 완료 */
  Estimated = 'ESTIMATED',
  /** 견적 준비 중 */
  Estimating = 'ESTIMATING',
  /** 완료 */
  Finished = 'FINISHED',
  /** 상담 진행 중 */
  Ongoing = 'ONGOING',
  /** 접수 */
  Pending = 'PENDING',
  /** 상담 대기 중 */
  Queued = 'QUEUED'
}

/** 상담 상태 변경 */
export type CounselStatusChange = {
  __typename?: 'CounselStatusChange';
  /** 상태가 변경된 상담 */
  counsel: Counsel;
  /** 변경 전 상담 상태 */
  from: CounselStatus;
  /** 변경 후 상담 상태 */
  to: CounselStatus;
};

/** 상담사 계정. */
export type CounselorAccount = Account & {
  __typename?: 'CounselorAccount';
  /**
   * 가능한 인증 방식 목록.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  authenticationMethods: Array<AuthenticationMethodEnum>;
  /**
   * 이메일 주소.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  email?: Maybe<Scalars['String']>;
  /**
   * 계정의 견적 문의 connection.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryConnection: EstimateInquiryConnection;
  /**
   * 계정의 견적 문의 개수.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryCount: Scalars['Int'];
  /** ID. */
  id: Scalars['ID'];
  /** 계정의 마지막 커뮤니티 활동(게시물/댓글/답글 작성) 시점. */
  lastActiveAtCommunity?: Maybe<Scalars['DateTime']>;
  /** 계정에 수신된 알림 connection. */
  notificationConnection: NotificationConnection;
  /**
   * 계정에 수신된 알림 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 및 계정 소유자만 접근 가능.
   */
  notificationCount: Scalars['Int'];
  /**
   * 알림 설정.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  notificationSetting: NotificationSetting;
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  phone?: Maybe<Scalars['String']>;
  /** 프로필. */
  profile: AccountProfile;
};


/** 상담사 계정. */
export type CounselorAccountEstimateInquiryConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 상담사 계정. */
export type CounselorAccountNotificationConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 상담사 계정. */
export type CounselorAccountNotificationCountArgs = {
  filter: GetNotificationCountOfAccountFilterRequestDto;
};

/** 쿠폰 베이스 */
export type CouponBase = {
  /** 혜택 */
  benefit?: Maybe<CouponBenefit>;
  /** 쿠폰명과 관련된 용어의 추가설명 */
  explanation?: Maybe<CouponExplanation>;
  /** ID */
  id: Scalars['ID'];
  /** 발행 timestamp */
  issuedAt: Scalars['LegacyDateTime'];
  /** 발행처 */
  issuer: CouponIssuer;
  /** 쿠폰명 */
  name: Scalars['String'];
  /**
   * 유의사항들
   * @deprecated 유의사항들로 변경되었습니다. 유의사항 목록 문구의 첫번째 텍스트 문자열을 반환합니다.
   */
  precautionStatement?: Maybe<Scalars['String']>;
  /** 유의사항들 */
  precautions?: Maybe<Array<Scalars['String']>>;
  /** 사용 timestamp */
  redeemedAt?: Maybe<Scalars['LegacyDateTime']>;
  /** 사용처 */
  redeemer: CouponRedeemer;
  /** 사용 유효기간 기간 */
  validityPeriod: LegacyDateTimeRange;
};

export type CouponBaseEdge = {
  __typename?: 'CouponBaseEdge';
  cursor: Scalars['String'];
  node: CouponBase;
};

/** 쿠폰 혜택 */
export type CouponBenefit = DiscountAmountBenefit;

/** 쿠폰 connection */
export type CouponConnection = {
  __typename?: 'CouponConnection';
  edges: Array<CouponBaseEdge>;
  pageInfo: PageInfo;
};

export type CouponConnectionFilter = {
  /** 쿠폰 사용 여부 */
  used?: Maybe<Scalars['Boolean']>;
};

/** 쿠폰명과 관련된 용어의 추가설명 */
export type CouponExplanation = {
  __typename?: 'CouponExplanation';
  /** 본문 */
  body: Array<Scalars['String']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
};

/** 쿠폰 발행처 */
export type CouponIssuer = {
  __typename?: 'CouponIssuer';
  /** 발행처명 */
  name: Scalars['String'];
};

/** 쿠폰 사용처 */
export type CouponRedeemer = {
  __typename?: 'CouponRedeemer';
  /** 사용처명 */
  name: Scalars['String'];
};

/** 댓글 생성 데이터 */
export type CreateCommentData = {
  /**
   * 본문
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  body: Scalars['String'];
  /** 추천 정비소 ID 목록 */
  companyIds?: Maybe<Array<Scalars['String']>>;
  /** 게시물에서 생성된 상담의 아이디 */
  counselId?: Maybe<Scalars['String']>;
  /**
   * 첨부 파일 목록
   *
   * ### Constraints
   *
   * 1. 최대 10개의 이미지 또는 비디오
   */
  files?: Maybe<Array<FileInput>>;
  /** 추천 상품 ID 목록 */
  productIds?: Maybe<Array<Scalars['String']>>;
};

/** 댓글 생성 에러 */
export type CreateCommentError = DeletedPostError;

/** 댓글 생성 실패 response */
export type CreateCommentFail = {
  __typename?: 'CreateCommentFail';
  /**
   * ### Errors
   * - DeletedPostError (게시물이 삭제된 경우)
   */
  error: Array<CreateCommentError>;
};

/** 댓글 생성 response */
export type CreateCommentResponse = CreateCommentFail | CreateCommentSuccess;

/** 댓글 생성 성공 response */
export type CreateCommentSuccess = {
  __typename?: 'CreateCommentSuccess';
  /** 생성된 댓글 */
  comment: CommentBase;
};

/** 업체 생성 에러 */
export type CreateCompanyError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 업체 생성 실패 응답 */
export type CreateCompanyFail = {
  __typename?: 'CreateCompanyFail';
  error: CreateCompanyError;
};

export type CreateCompanyInput = {
  /**
   * 주소.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  address: Scalars['String'];
  /** 업체 소개 */
  bio?: Maybe<Scalars['String']>;
  /** 파트너 업체 여부 */
  certificated: Scalars['Boolean'];
  /** 전화번호 */
  contact: Scalars['String'];
  /**
   * 상세 주소.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  detailAddress?: Maybe<Scalars['String']>;
  /** 추가 전화번호 */
  extraContact?: Maybe<Scalars['String']>;
  /** 시설 ID 목록 */
  facilityIds?: Maybe<Array<Scalars['ID']>>;
  /** 취급 차량 국적 */
  handleNationality: HandleNationality;
  /** 이미지 Url 목록 */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 파트너 업체 여부 */
  isPartner: Scalars['Boolean'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /**
   * 업체명.
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 32자
   */
  name: Scalars['String'];
  /** 업체 운영 시간 */
  operatingTime?: Maybe<OperatingTimeInput>;
  /** 추천 이유 ID 목록 */
  recommendationIds?: Maybe<Array<Scalars['ID']>>;
  /** 사업자 등록번호 */
  registrationNumber?: Maybe<Scalars['String']>;
  /** 대표자 명 */
  representativeName?: Maybe<Scalars['String']>;
  /** 업체전문분야 ID목록 */
  specialtyIds?: Maybe<Array<Scalars['ID']>>;
  /** 업체 유형 */
  type: CompanyType;
};

/** 업체 생성 응답 */
export type CreateCompanyResponse = CreateCompanyFail | CreateCompanySuccess;

/** 업체 생성 성공 응답 */
export type CreateCompanySuccess = {
  __typename?: 'CreateCompanySuccess';
  /** 생성된 업체 */
  company: Company;
};

/** 고객 문의 생성 데이터 */
export type CreateComplaintData = {
  /**
   * 내용
   *
   * ### Constraints
   *
   * 1. 최대 500자
   */
  body: Scalars['String'];
  /** 관련 상담 ID */
  counselId?: Maybe<Scalars['ID']>;
  /**
   * 첨부 파일 목록
   *
   * ### Constraints
   *
   * 1. 최대 10개의 이미지 또는 비디오
   */
  files?: Maybe<Array<FileInput>>;
  /** 관련 주문 ID */
  orderId?: Maybe<Scalars['ID']>;
  /**
   * 제목
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 50자
   */
  title?: Maybe<Scalars['String']>;
};

/** 고객 문의 답변 생성 데이터 */
export type CreateComplaintResponseData = {
  /** 내용 */
  body: Scalars['String'];
  /** 고객 문의 ID */
  complaintId: Scalars['ID'];
  /**
   * 첨부 파일 목록
   *
   * ### Constraints
   *
   * 1. 최대 10개의 이미지 또는 비디오
   */
  files?: Maybe<Array<FileInput>>;
};

/** 상담 생성 정보 */
export type CreateCounselData = {
  /** 정비 희망 지역 목록 */
  areas?: Maybe<Array<AreaInput>>;
  /**
   * 메모
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  memo?: Maybe<Scalars['String']>;
  /**
   * 주행 거리 (km)
   *
   * ### Constraints
   *
   * 1. 최소 0km, 최대 999999km
   */
  mileage?: Maybe<Scalars['Int']>;
  /** 상담이 연결될 게시물 */
  postId: Scalars['ID'];
  /**
   * 제목
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 50자
   */
  title?: Maybe<Scalars['String']>;
  /** 문의 차량 ID */
  vehicleId: Scalars['ID'];
};

/** 상담 생성 에러 */
export type CreateCounselError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 상담 생성 실패 응답 */
export type CreateCounselFail = {
  __typename?: 'CreateCounselFail';
  error: CreateCounselError;
};

/** 상담 생성 응답 결과 */
export type CreateCounselResponse = CreateCounselFail | CreateCounselSuccess;

/** 상담 리뷰 생성 데이터 */
export type CreateCounselReviewData = {
  /**
   * 추가 설명
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  description?: Maybe<Scalars['String']>;
  /** 만족도 */
  satisfaction: CounselSatisfaction;
  /** 문제 해결 여부 */
  solved: Scalars['Boolean'];
};

/** 상담 리뷰 생성 성공 response */
export type CreateCounselReviewSuccess = {
  __typename?: 'CreateCounselReviewSuccess';
  /** 생성된 상담 리뷰 */
  review: CounselReview;
};

/** 상담 생성 성공 response */
export type CreateCounselSuccess = {
  __typename?: 'CreateCounselSuccess';
  /** 생성된 상담 */
  counsel: Counsel;
};

/** 해시태그 생성 데이터 */
export type CreateHashtagData = {
  /**
   * 이름
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 100자
   *
   * 2. 한글, 영문자, 숫자, -, _ 만 됨
   */
  name: Scalars['String'];
};

/** 해시태그 생성 response */
export type CreateHashtagResponse = CreateHashtagSuccess;

/** 해시태그 생성 성공 response */
export type CreateHashtagSuccess = {
  __typename?: 'CreateHashtagSuccess';
  /** 생성된 해시태그 */
  hashtag: PostHashtag;
};

/** 상품 생성 데이터 */
export type CreateItemData = {
  /**
   * 상품 ID
   *
   * ### Constraints
   *
   * 1. 최소 3자, 최대 32자
   *
   * 2. 알파벳, 숫자, 공백 및 -_ 허용
   */
  id: Scalars['ID'];
  /**
   * 상품명
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 128자
   */
  name: Scalars['String'];
};

/** 상품 생성 실패 response */
export type CreateItemFail = {
  __typename?: 'CreateItemFail';
  /**
   * ### Errors
   * - ItemExistsError (해당 ID의 상품이 이미 존재하는 경우)
   */
  errors: Array<Error>;
};

/** 상품 생성 response */
export type CreateItemResponse = CreateItemFail | CreateItemSuccess;

/** 상품 생성 성공 response */
export type CreateItemSuccess = {
  __typename?: 'CreateItemSuccess';
  /** 생성된 상품 */
  item: Item;
};

/** 공지사항 생성 데이터 */
export type CreateNoticeData = {
  /**
   * 본문
   *
   * ### Constraints
   *
   * 1. 최소 10자, 최대 5000자
   */
  body: Scalars['String'];
  /**
   * 첨부 파일 목록
   *
   * ### Constraints
   *
   * 1. 최대 10개의 이미지 또는 비디오
   */
  files?: Maybe<Array<FileInput>>;
  /**
   * 제목
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 128자
   */
  title: Scalars['String'];
};

/** 공지사항 생성 성공 response */
export type CreateNoticeSuccess = {
  __typename?: 'CreateNoticeSuccess';
  /** 생성된 공지사항 */
  notice: Notice;
};

/** 주문 생성 데이터 */
export type CreateOrderData = {
  /**
   * 주문 상품 목록
   *
   * ### Constraints
   *
   * 1. 최대 1000개
   */
  items: Array<OrderItemInput>;
  /** 주문명 */
  name: Scalars['String'];
  /**
   * 추천 이유
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  recommendedReason?: Maybe<Scalars['String']>;
  /**
   * 운임비 (KRW)
   *
   * ### Constraints
   *
   * 1. 최소 0원, 최대 100만원
   */
  shippingCharge: Scalars['Int'];
};

/** 주문 생성 성공 response */
export type CreateOrderSuccess = {
  __typename?: 'CreateOrderSuccess';
  /** 생성된 주문 */
  order: OrderBase;
};

/** 게시물 생성 데이터 */
export type CreatePostData = {
  /** 정비 희망 지역 목록 */
  areas?: Maybe<Array<AreaInput>>;
  /**
   * 내용
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 5000자
   */
  body: Scalars['String'];
  /** 주제 ID */
  categoryId: Scalars['ID'];
  /** 첨부 파일 목록 */
  files?: Maybe<Array<FileInput>>;
  /** 해시태그 목록 */
  hashtags?: Maybe<Array<PostHashtagInput>>;
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  /**
   * 주행 거리 (km)
   *
   * ### Constraints
   *
   * 1. 최소 0km, 최대 999999km
   */
  mileage?: Maybe<Scalars['Int']>;
  /**
   * 제목
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 50자
   */
  title?: Maybe<Scalars['String']>;
  /** 문의 유형 ID */
  typeId?: Maybe<Scalars['ID']>;
  /** 문의 차량 ID */
  vehicleId: Scalars['ID'];
};

/** 게시물 생성 response */
export type CreatePostResponse = CreatePostSuccess;

/** 게시물 생성 성공 response */
export type CreatePostSuccess = {
  __typename?: 'CreatePostSuccess';
  /** 생성된 게시물 */
  post: Post;
};

export type CreateQaAccountData = {
  /**
   * 이름. 길동관리자QA, 길동사용자QA 등으로 닉네임이 설정됨.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 4자.
   */
  name: Scalars['String'];
  /**
   * 비밀번호. 모든 계정에 대해 동일하게 설정됨.
   *
   * ### Constraints
   *
   * 1. 최소 8자에서 최대 32자.
   *
   * 2. 알파벳, 숫자 및 ~!@#$%^&*()+|= 허용.
   *
   * 3. 알파벳과 숫자 포함 필수, 특수문자는 누락 가능.
   */
  password: Scalars['String'];
  /**
   * ID 및 이메일 주소 앞에 붙는 접두사.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 4자.
   *
   * 2. 알파벳만 허용. 대소문자 구분 없음.
   */
  prefix: Scalars['String'];
};

export type CreateQaAccountsError = {
  message: Scalars['String'];
};

/** QA 계정 생성 실패. */
export type CreateQaAccountsFail = {
  __typename?: 'CreateQaAccountsFail';
  error: CreateQaAccountsError;
};

export type CreateQaAccountsResponse = CreateQaAccountsFail | CreateQaAccountsSuccess;

/** QA 계정 생성 성공. */
export type CreateQaAccountsSuccess = {
  __typename?: 'CreateQaAccountsSuccess';
  /** 생성된 QA 계정 목록. */
  accounts: Array<Account>;
};

/** 답글 생성 response */
export type CreateReplyResponse = CreateReplySuccess;

/** 답글 생성 성공 response */
export type CreateReplySuccess = {
  __typename?: 'CreateReplySuccess';
  /** 생성된 답글 */
  reply: CommentBase;
};

export type CreateSearchableCompanyReviewKeywordsData = {
  /**
   * 선택한 키워드 목록.
   *
   * ### Constraints
   *
   * 1. 최대 5개의 키워드.
   */
  keywords: Array<Scalars['String']>;
  /** 리뷰가 작성된 검색 가능 업체 ID. */
  searchableCompanyId: Scalars['ID'];
};

export type CreateSearchableCompanyReviewKeywordsError = {
  message: Scalars['String'];
};

/** 검색 가능 업체 리뷰 키워드들을 생성하는데 실패했을 때 반환되는 응답. */
export type CreateSearchableCompanyReviewKeywordsFail = {
  __typename?: 'CreateSearchableCompanyReviewKeywordsFail';
  error: CreateSearchableCompanyReviewKeywordsError;
};

export type CreateSearchableCompanyReviewKeywordsResponse = CreateSearchableCompanyReviewKeywordsFail | CreateSearchableCompanyReviewKeywordsSuccess;

/** 검색 가능 업체 리뷰 키워드들을 생성하는데 성공했을 때 반환되는 응답. */
export type CreateSearchableCompanyReviewKeywordsSuccess = {
  __typename?: 'CreateSearchableCompanyReviewKeywordsSuccess';
  /** 생성된 검색 가능 업체 리뷰 키워드들. */
  searchableCompanyReviewKeywords: Array<SearchableCompanyReviewKeyword>;
};

/** 배송 생성 데이터 */
export type CreateShippingData = {
  /** 수령인 휴대전화번호 또는 유선전화번호 */
  contact: Scalars['String'];
  /**
   * 배송지 주소
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  destination: Scalars['String'];
  /**
   * 수령인
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 32자
   */
  recipient: Scalars['String'];
  /**
   * 배송 요청 사항
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 20자
   */
  request?: Maybe<Scalars['String']>;
};

/** 배송 생성 성공 response */
export type CreateShippingSuccess = {
  __typename?: 'CreateShippingSuccess';
  /** 생성된 배송 */
  shipping: Shipping;
};

export type CreateTemplateData = {
  /** 메세지 목록 */
  messages: Array<TemplateMessageInput>;
  /**
   * 템플릿명
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 100자
   */
  name: Scalars['String'];
};

/** 템플릿 생성 성공 response */
export type CreateTemplateSuccess = {
  __typename?: 'CreateTemplateSuccess';
  /** 생성된 템플릿 */
  template: Template;
};

export type CreateVehicleData = {
  /** 차량의 제조사 ID */
  brandId: Scalars['ID'];
  /** 차량 특성 ID 목록 */
  characteristicIds?: Maybe<Array<Scalars['ID']>>;
  /** 차량의 유종. */
  fuelType: FuelTypeInput;
  /** 차량의 등급 ID */
  gradeId?: Maybe<Scalars['ID']>;
  /**
   * 차량의 주행거리 (km)
   *
   * ### Constraints
   *
   * 1. 최소 0km, 최대 999999km
   */
  mileage: Scalars['Int'];
  /** 차량의 모델 ID */
  modelId: Scalars['ID'];
  /**
   * 별칭
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 10자
   *
   * 2. 한글, 알파벳, 숫자 허용
   */
  nickname?: Maybe<Scalars['String']>;
  /** 소유주명(이름 또는 법인명). */
  ownerName?: Maybe<Scalars['String']>;
  /**
   * 차량 번호
   *
   * ### Constraints
   *
   * 1. 공백 제외
   */
  plateNumber: Scalars['String'];
  /**
   * 차량의 연식(등록연도)
   *
   * ### Constraints
   *
   * 1. 최소 1901년, 최대 2155년
   */
  registeredYear?: Maybe<Scalars['Int']>;
  /** 차량의 세부 모델 ID */
  submodelId?: Maybe<Scalars['ID']>;
  /** 차량 타이어 규격. */
  tireSize?: Maybe<VehicleTireSizeInput>;
  /** 차량의 트림 ID */
  trimId?: Maybe<Scalars['ID']>;
  /**
   * 차대번호
   *
   * ### Constraints
   *
   * 1. 영문 대문자 및 숫자 17자리
   */
  vin?: Maybe<Scalars['String']>;
};

export type CreateVehicleError = {
  message: Scalars['String'];
};

export type CreateVehicleFail = {
  __typename?: 'CreateVehicleFail';
  error: CreateVehicleError;
};

export type CreateVehicleResponse = CreateVehicleFail | CreateVehicleSuccess;

export type CreateVehicleSuccess = {
  __typename?: 'CreateVehicleSuccess';
  /** 생성된 차량. */
  vehicle: Vehicle;
};

/** 작업 고객 정보. */
export type CreateWorkClientData = {
  /** 고객 아이디 */
  accountId?: Maybe<Scalars['String']>;
  /**
   * 이름.
   *
   * ### Constraints
   *
   * 1. 한글 최소 2자, 최대 10자.
   */
  name: Scalars['String'];
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. - 제외.
   *
   * 2. 국가번호 82없이 최소 10자, 최대 11자.
   */
  phone: Scalars['String'];
};

/** 작업 생성 입력 데이터. */
export type CreateWorkData = {
  /** 작업을 생성하는 예약 ID. */
  appointmentId?: Maybe<Scalars['ID']>;
  /** 고객 정보. */
  client: CreateWorkClientData;
  /** 작업을 생성하는 업체 ID. */
  companyId: Scalars['ID'];
  /**
   * 입고일.
   *
   * ### Constraints
   *
   * 1. YYYY-MM-DD 형식
   */
  dateOfEnter: Scalars['Date'];
  /**
   * 예상 출고일.
   *
   * ### Constraints
   *
   * 1. YYYY-MM-DD 형식
   */
  expectedDateOfRelease: Scalars['Date'];
  /** 보험처리 방식. */
  insurance: InsuranceEnum;
  /** 차량 정보. */
  vehicle: CreateWorkVehicleData;
};

export type CreateWorkError = {
  message: Scalars['String'];
};

/** 작업 생성 실패. */
export type CreateWorkFail = {
  __typename?: 'CreateWorkFail';
  error: CreateWorkError;
};

export type CreateWorkResponse = CreateWorkFail | CreateWorkSuccess;

/** 작업 생성 성공. */
export type CreateWorkSuccess = {
  __typename?: 'CreateWorkSuccess';
  /** 작업. */
  work: Work;
};

/** 작업 차량 정보. */
export type CreateWorkVehicleData = {
  /** 제조사 ID. */
  brandId?: Maybe<Scalars['ID']>;
  /** 연료 유형 */
  fuelType?: Maybe<FuelTypeInput>;
  /**
   * 모델명.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  modelName: Scalars['String'];
  /**
   * 차량번호
   *
   * ### Constraints
   *
   * 1. 차량번호 형식
   */
  plateNumber: Scalars['String'];
  /**
   * 연식
   *
   * ### Constraints
   *
   * 1. 최소 1, 최대 9999
   */
  registeredYear?: Maybe<Scalars['Int']>;
};

/** 통화 */
export enum Currency {
  /** 원화 */
  Krw = 'KRW'
}

/** 퀵상담 메세지 커서 */
export type Cursor = {
  __typename?: 'Cursor';
  counsel: Counsel;
  id: Scalars['ID'];
  message?: Maybe<Message>;
  user: User;
};


/** 날짜 범위. */
export type DateRangeInput = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


/** 예약 차량 방문 시점 하루 전 알림. */
export type DayBeforeAppointmentNotification = Notification & {
  __typename?: 'DayBeforeAppointmentNotification';
  /** 다음날 차량 방문이 예정된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 댓글 삭제 response */
export type DeleteCommentResponse = DeleteCommentSuccess;

/** 댓글 삭제 성공 response */
export type DeleteCommentSuccess = {
  __typename?: 'DeleteCommentSuccess';
  /** 삭제된 댓글 */
  comment: CommentBase;
};

export type DeleteCompanyError = {
  message: Scalars['String'];
};

export type DeleteCompanyFail = {
  __typename?: 'DeleteCompanyFail';
  error: DeleteCompanyError;
};

export type DeleteCompanyResponse = DeleteCompanyFail | DeleteCompanySuccess;

export type DeleteCompanySuccess = {
  __typename?: 'DeleteCompanySuccess';
  /** 삭제된 업체. */
  company: Company;
};

/** 고객 문의 삭제 성공 response */
export type DeleteComplaintSuccess = {
  __typename?: 'DeleteComplaintSuccess';
  /** 삭제된 고객 문의 */
  complaint: Complaint;
};

/** 상담 삭제 response */
export type DeleteCounselResponse = DeleteCounselSuccess;

/** 상담 삭제 성공 response */
export type DeleteCounselSuccess = {
  __typename?: 'DeleteCounselSuccess';
  /** 삭제된 상담 */
  counsel: Counsel;
};

/** 메세지 삭제 성공 response */
export type DeleteMessageSuccess = {
  __typename?: 'DeleteMessageSuccess';
  /** 삭제된 메세지 */
  message: DeletedMessage;
};

/** 주문 삭제 성공 response */
export type DeleteOrderSuccess = {
  __typename?: 'DeleteOrderSuccess';
  /** 삭제된 주문 */
  order: OrderBase;
};

/** 게시물 삭제 response */
export type DeletePostResponse = DeletePostSuccess;

/** 게시물 삭제 성공 response */
export type DeletePostSuccess = {
  __typename?: 'DeletePostSuccess';
  /** 삭제된 게시물 */
  post: Post;
};

export type DeleteSearchableCompanyReviewData = {
  /** 리뷰가 작성된 검색 가능 업체 리뷰 ID. */
  searchableCompanyReviewId: Scalars['ID'];
};

export type DeleteSearchableCompanyReviewError = {
  message: Scalars['String'];
};

/** 검색 가능 업체 리뷰를 삭제하는데 실패했을 때 반환되는 응답. */
export type DeleteSearchableCompanyReviewFail = {
  __typename?: 'DeleteSearchableCompanyReviewFail';
  error: DeleteSearchableCompanyReviewError;
};

export type DeleteSearchableCompanyReviewResponse = DeleteSearchableCompanyReviewFail | DeleteSearchableCompanyReviewSuccess;

/** 검색 가능 업체 리뷰를 삭제하는데 성공했을 때 반환되는 응답. */
export type DeleteSearchableCompanyReviewSuccess = {
  __typename?: 'DeleteSearchableCompanyReviewSuccess';
  /** 삭제된 검색 가능 업체 리뷰. */
  searchableCompanyReview: SearchableCompanyReview;
};

/** 템플릿 삭제 성공 response */
export type DeleteTemplateSuccess = {
  __typename?: 'DeleteTemplateSuccess';
  /** 삭제된 템플릿 */
  template: Template;
};

export type DeleteVehicleError = {
  message: Scalars['String'];
};

export type DeleteVehicleFail = {
  __typename?: 'DeleteVehicleFail';
  error: DeleteVehicleError;
};

export type DeleteVehicleResponse = DeleteVehicleFail | DeleteVehicleSuccess;

export type DeleteVehicleSuccess = {
  __typename?: 'DeleteVehicleSuccess';
  /** 생성된 차량. */
  vehicle: Vehicle;
};

/** 삭제된 게시물 댓글 */
export type DeletedComment = CommentBase & {
  __typename?: 'DeletedComment';
  author?: Maybe<User>;
  /** 생성 timestamp */
  createdAt: Scalars['LegacyDateTime'];
  /** 삭제 timestamp */
  deletedAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  parent?: Maybe<CommentBase>;
  post?: Maybe<Post>;
  replyConnection?: Maybe<CommentConnection>;
  replyCount?: Maybe<Scalars['Int']>;
  /** 수정 timestamp */
  updatedAt: Scalars['LegacyDateTime'];
  viewed?: Maybe<Scalars['Boolean']>;
};


/** 삭제된 게시물 댓글 */
export type DeletedCommentReplyConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** 삭제된 메세지 */
export type DeletedMessage = Message & {
  __typename?: 'DeletedMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** 삭제 시점 TIMESTAMP */
  deletedAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 게시물 삭제됨 */
export type DeletedPostError = Error & {
  __typename?: 'DeletedPostError';
  message: Scalars['String'];
};

/** 삭제된 차량 소유자 에러 */
export type DeletedVehicleOwnerError = CreateCounselError & {
  __typename?: 'DeletedVehicleOwnerError';
  message: Scalars['String'];
};

/** 기기 */
export type Device = {
  __typename?: 'Device';
  /** 닥터차 앱 버전 */
  appVersion?: Maybe<Scalars['String']>;
  /** 제조사 */
  brand?: Maybe<Scalars['String']>;
  /** 모델명 */
  model?: Maybe<Scalars['String']>;
  /** OS */
  os?: Maybe<Scalars['String']>;
  /** OS 버전 */
  osVersion?: Maybe<Scalars['String']>;
};

/** 할인 금액 혜택 */
export type DiscountAmountBenefit = {
  __typename?: 'DiscountAmountBenefit';
  /** 할인 금액 */
  discountAmount: Scalars['Int'];
};

export type DuplicatedAppointmentExistError = RequestAppointmentError & {
  __typename?: 'DuplicatedAppointmentExistError';
  appointment: Appointment;
  /** 에러 메세지 */
  message: Scalars['String'];
};

export type DuplicatedWorkExistError = CreateWorkError & {
  __typename?: 'DuplicatedWorkExistError';
  message: Scalars['String'];
  work: Work;
};

export type EditEstimateData = {
  /**
   * 설명.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * 예상 수리 기간(일).
   *
   * ### Constraints
   *
   * 1. 최소 0일, 최대 65535일.
   */
  estimatedRepairDays?: Maybe<Scalars['Int']>;
  /**
   * 첨부 파일 목록.
   *
   * ### Constraints
   *
   * 1. 최대 10개의 사진 및 영상.
   */
  files?: Maybe<Array<FileInput>>;
  /**
   * 가격(KRW).
   *
   * ### Constraints
   *
   * 1. 최대 4294967295원
   */
  price?: Maybe<Scalars['Int']>;
};

export type EditEstimateError = {
  message: Scalars['String'];
};

/** 견적 수정 실패. */
export type EditEstimateFail = {
  __typename?: 'EditEstimateFail';
  error: EditEstimateError;
};

export type EditEstimateRequestMemoError = {
  message: Scalars['String'];
};

/** 견적 요청 메모 수정 실패. */
export type EditEstimateRequestMemoFail = {
  __typename?: 'EditEstimateRequestMemoFail';
  error: EditEstimateRequestMemoError;
};

export type EditEstimateRequestMemoResponse = EditEstimateRequestMemoFail | EditEstimateRequestMemoSuccess;

/** 견적 요청 메모 수정 성공. */
export type EditEstimateRequestMemoSuccess = {
  __typename?: 'EditEstimateRequestMemoSuccess';
  /** 메모가 수정된 견적 요청. */
  request: EstimateRequest;
};

export type EditEstimateResponse = EditEstimateFail | EditEstimateSuccess;

/** 견적 수정 성공. */
export type EditEstimateSuccess = {
  __typename?: 'EditEstimateSuccess';
  /** 수정된 견적. */
  estimate: Estimatation;
};

export type EditSearchableCompanyReviewData = {
  /**
   * 내용.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 400자.
   *
   * 2. imageUrls가 없을 경우에는 필수값임
   */
  content?: Maybe<Scalars['String']>;
  /**
   * 첨부 이미지 목록.
   *
   * ### Constraints
   *
   * 1. 최대 5개의 사진.
   *
   * 2. content가 없을 경우에는 필수값임
   */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 리뷰가 작성된 검색 가능 업체 리뷰 ID. */
  searchableCompanyReviewId: Scalars['ID'];
};

export type EditSearchableCompanyReviewError = {
  message: Scalars['String'];
};

/** 검색 가능 업체 리뷰 수정하는데 실패했을 때 반환되는 응답. */
export type EditSearchableCompanyReviewFail = {
  __typename?: 'EditSearchableCompanyReviewFail';
  error: EditSearchableCompanyReviewError;
};

export type EditSearchableCompanyReviewResponse = EditSearchableCompanyReviewFail | EditSearchableCompanyReviewSuccess;

/** 검색 가능 업체 리뷰 수정하는데 성공했을 때 반환되는 응답. */
export type EditSearchableCompanyReviewSuccess = {
  __typename?: 'EditSearchableCompanyReviewSuccess';
  /** 수정된 검색 가능 업체 리뷰. */
  searchableCompanyReview: SearchableCompanyReview;
};

/** 이메일이 인증 되지 않음. */
export type EmailHasNotVerifiedError = LoginError & ResetPasswordError & {
  __typename?: 'EmailHasNotVerifiedError';
  /** 인증이 필요한 이메일 주소. */
  email: Scalars['String'];
  message: Scalars['String'];
};

/** 상담 종료 안내 메세지 */
export type EndMessage = Message & {
  __typename?: 'EndMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  endedBy?: Maybe<User>;
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 사용자 입장 메세지 */
export type EnterMessage = Message & {
  __typename?: 'EnterMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  /** 채팅의 초대받은 사람 */
  invitee?: Maybe<User>;
  /** 채팅의 초대자 */
  inviter?: Maybe<User>;
  status: MessageStatus;
};

export type Error = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 견적. */
export type Estimatation = {
  __typename?: 'Estimatation';
  /** 작성자 계정. */
  author?: Maybe<Account>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 설명. */
  description?: Maybe<Scalars['String']>;
  /** 예상 수리 기간(일). */
  estimatedRepairDays?: Maybe<Scalars['Int']>;
  /** 첨부 파일 목록. */
  files?: Maybe<Array<File>>;
  /** 발행 여부. */
  hasPublished: Scalars['Boolean'];
  /** ID. */
  id: Scalars['ID'];
  /** 가격 */
  price?: Maybe<Scalars['Int']>;
  /** 발행 시점. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** 발행자 계정. */
  publisher?: Maybe<Account>;
  /** 견적에 대한 견적 요청. */
  request?: Maybe<EstimateRequest>;
};

/** 견적 */
export type Estimate = {
  __typename?: 'Estimate';
  /** 결제 금액 (KRW) */
  amount: Scalars['Int'];
  items: Array<OrderItem>;
  latest: Scalars['Boolean'];
  /** 주문명 */
  name: Scalars['String'];
  order?: Maybe<OrderBase>;
  /** 운임비 (KRW) */
  shippingCharge: Scalars['Int'];
  vehicle?: Maybe<Vehicle>;
};

export type EstimateFilter = {
  /** 작성한 업체 ID. */
  companyId?: Maybe<Scalars['ID']>;
  /** 발행 여부. */
  hasPublished?: Maybe<Scalars['Boolean']>;
};

/** 견적 문의. */
export type EstimateInquiry = {
  __typename?: 'EstimateInquiry';
  /** 고객. */
  client: EstimateInquiryClient;
  /** 요청 시점. */
  createdAt: Scalars['DateTime'];
  /** 내용. */
  detail: EstimateInquiryDetail;
  /** 견적 요청 목록. */
  estimateRequests: Array<EstimateRequest>;
  /** ID */
  id: Scalars['ID'];
  /** 차량. */
  vehicle: EstimateInquiryVehicle;
};

/** 견적 문의 고객. */
export type EstimateInquiryClient = {
  __typename?: 'EstimateInquiryClient';
  /** 계정. */
  account?: Maybe<Account>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  /** 휴대전화번호 */
  phone: Scalars['String'];
};

export type EstimateInquiryConnection = {
  __typename?: 'EstimateInquiryConnection';
  edges: Array<EstimateInquiryConnectionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type EstimateInquiryConnectionEdge = {
  __typename?: 'EstimateInquiryConnectionEdge';
  cursor: Scalars['String'];
  node: EstimateInquiry;
};

/** 견적 문의 내용. */
export type EstimateInquiryDetail = {
  __typename?: 'EstimateInquiryDetail';
  /** 상품 조회 영역. */
  area?: Maybe<Area>;
  /** 상품 조회 영역 목록. */
  areas: Array<Area>;
  /** 본문. */
  body?: Maybe<Scalars['String']>;
  /** 첨부 파일 목록. */
  files?: Maybe<Array<File>>;
  /** 보험 처리 방식. */
  insurance?: Maybe<InsuranceEnum>;
};

export type EstimateInquiryFilter = {
  /** 생성시점 범위. */
  createdAt?: Maybe<OpenedDateTimeRangeInput>;
  /** 발행 대기 중인 견적 포함 여부. */
  hasUnpublishedEstimate?: Maybe<Scalars['Boolean']>;
};

/** 견적 문의 정렬 방식. */
export enum EstimateInquirySortOrderEnum {
  /** 문의 시점 오름차순. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 문의 시점 내림차순. */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** 견적 문의 차량. */
export type EstimateInquiryVehicle = {
  __typename?: 'EstimateInquiryVehicle';
  /** 모델명. */
  modelName?: Maybe<Scalars['String']>;
  /** 연식. */
  modelYear?: Maybe<Scalars['Int']>;
  /** 차량번호. */
  plateNumber?: Maybe<Scalars['String']>;
  /** 차대번호. */
  vin?: Maybe<Scalars['String']>;
};

/** 견적 메세지 */
export type EstimateMessage = Message & {
  __typename?: 'EstimateMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  estimate: Estimate;
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 견적 요청. */
export type EstimateRequest = {
  __typename?: 'EstimateRequest';
  /** 예약. */
  appointment?: Maybe<Appointment>;
  /** 견적이 요청된 업체. */
  company?: Maybe<Company>;
  /** 요청 시점. */
  createdAt: Scalars['DateTime'];
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  /** 견적. */
  estimate?: Maybe<Estimatation>;
  /** 업체의 응답 여부. */
  hasResponsed: Scalars['Boolean'];
  /** ID. */
  id: Scalars['ID'];
  /** 견적이 요청된 견적 문의. */
  inquiry?: Maybe<EstimateInquiry>;
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 견적 요청의 상품. */
  product?: Maybe<Product>;
  /** 업체의 응답 시점. */
  responsedAt?: Maybe<Scalars['DateTime']>;
};

export type EstimateRequestConnection = {
  __typename?: 'EstimateRequestConnection';
  edges: Array<EstimateRequestConnectionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type EstimateRequestConnectionEdge = {
  __typename?: 'EstimateRequestConnectionEdge';
  cursor: Scalars['String'];
  node: EstimateRequest;
};

export type EstimateRequestFilter = {
  /** 업체 ID. */
  companyId?: Maybe<Scalars['ID']>;
  /** 메모 포함 여부. */
  hasMemo?: Maybe<Scalars['Boolean']>;
  /** 응답 여부. */
  hasResponsed?: Maybe<Scalars['Boolean']>;
  /** 미발행 견적서 포함 여부. */
  hasUnpublishedEstimate?: Maybe<Scalars['Boolean']>;
  /**
   * 메모 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  memo?: Maybe<Scalars['String']>;
};

/** 견적 요청 정렬 방식. */
export enum EstimateRequestSortOrderEnum {
  /** 생성시점 오름차순. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 생성시점 내림차순. */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** 견적 요청 알림. */
export type EstimateRequestedNotification = Notification & {
  __typename?: 'EstimateRequestedNotification';
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 요청된 견적 요청. */
  request?: Maybe<EstimateRequest>;
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 견적 정렬 방식. */
export enum EstimateSortOrderEnum {
  /** 생성 시점 오름차순. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 생성 시점 내림차순. */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** 이벤트 */
export type Event = {
  __typename?: 'Event';
  banner: EventBanner;
  /** 버튼 스타일 */
  buttonStyles?: Maybe<EventButtonStyles>;
  /** ID */
  id: Scalars['ID'];
  leaflet?: Maybe<Image>;
  /** 이벤트명 */
  name: Scalars['String'];
  period: LegacyDateTimeRange;
  /** 이벤트 페이지 URL */
  url: Scalars['String'];
};

/** 이벤트 배너 */
export type EventBanner = {
  __typename?: 'EventBanner';
  /** 모바일용 가로 배너 이미지 */
  horizontal: Image;
  /** 웹용 세로 배너 이미지 */
  vertical?: Maybe<Image>;
};

export type EventButtonStyles = {
  __typename?: 'EventButtonStyles';
  primary?: Maybe<ButtonStyle>;
  secondary?: Maybe<ButtonStyle>;
  text?: Maybe<ButtonStyle>;
};

/** 이벤트 connection */
export type EventConnection = {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
};

/** 이벤트 connection 필터 */
export type EventConnectionFilter = {
  /** 이벤트 진행중 여부 */
  ongoing: Scalars['Boolean'];
  /** 이벤트의 리플렛 이미지 포함 여부 */
  withLeaflet?: Maybe<Scalars['Boolean']>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

/** 쿠폰 사용 실패 response */
export type ExpiredCouponError = RedeemCouponError & {
  __typename?: 'ExpiredCouponError';
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 외부 블로그 게시물 */
export type ExternalBlogPost = BlogPost & {
  __typename?: 'ExternalBlogPost';
  /** 카테고리 */
  category?: Maybe<BlogPostCategory>;
  /** ID */
  id: Scalars['ID'];
  /** 부제목 */
  subtitle: Scalars['String'];
  thumbnail: Image;
  /** 제목 */
  title: Scalars['String'];
  url: Scalars['String'];
};

/** 파일 */
export type File = {
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** URL */
  url: Scalars['String'];
};

/** 파일 */
export type FileInput = {
  /**
   * 설명
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  description?: Maybe<Scalars['String']>;
  /** 유형 */
  type: FileType;
  /**
   * URL
   *
   * ### Constraints
   *
   * 1. IMAGE 유형의 경우 jpg, jpeg, png, gif 허용
   *
   * 2. VIDEO 유형의 경우 avi, mp4 허용
   */
  url: Scalars['String'];
};

/** 파일 메세지 */
export type FileMessage = Message & {
  __typename?: 'FileMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  file: File;
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

export type FileMessageContent = {
  __typename?: 'FileMessageContent';
  /** 파일 */
  file: File;
};

/** 파일 유형. */
export enum FileType {
  /** 이미지 */
  Image = 'IMAGE',
  /** 비디오 */
  Video = 'VIDEO'
}

/** 파일 업로드 데이터 */
export type FileUploadInput = {
  /** 파일명 (확장자 포함) */
  filename: Scalars['String'];
  /**
   * 메타데이터
   *
   * ### Constraints
   *
   * 1. image/* mimetype의 경우 width(px), height(px)를 포함하는 object
   *
   * 2. video/* mimetype의 경우 width(px), height(px), duration(ms)을 포함하는 object
   */
  metadata: Scalars['JSON'];
  /**
   * mimetype
   *
   * ### Constraints
   *
   * 1. image/jpeg, image/png, image/gif, video/mp4, video/x-msvideo 허용.
   */
  mimetype: Scalars['String'];
};

/** 예약 완료처리 에러 */
export type FinishAppointmentError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 예약 완료처리 실패 응답 */
export type FinishAppointmentFail = {
  __typename?: 'FinishAppointmentFail';
  error: FinishAppointmentError;
};

/** 예약 완료처리 응답 */
export type FinishAppointmentResponse = FinishAppointmentFail | FinishAppointmentSuccess;

/** 예약 완료처리 성공 응답 */
export type FinishAppointmentSuccess = {
  __typename?: 'FinishAppointmentSuccess';
  /** 완료된 예약 */
  appointment: Appointment;
};

/** 퀵상담 종료 성공 response */
export type FinishCounselSuccess = {
  __typename?: 'FinishCounselSuccess';
  /** 종료된 퀵상담 */
  counsel: Counsel;
};

/** 완료 상태의 예약. */
export type FinishedAppointment = Appointment & {
  __typename?: 'FinishedAppointment';
  /** 고객. */
  client: AppointmentClient;
  /** 예약번호(유일하지 않음). */
  code: Scalars['String'];
  /** 업체. */
  company: AppointmentCompany;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 상세. */
  detail: AppointmentDetail;
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  estimateRequest?: Maybe<EstimateRequest>;
  /** 완료 시점. */
  finishedAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사 및 업체 구성원 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 수정 시점. */
  updatedAt: Scalars['DateTime'];
  /** 차량. */
  vehicle: AppointmentVehicle;
  /** 차량 도착 예정 시점. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

export type FinishedCounselConnectionFilter = {
  /** 문의 차량의 국산 여부 */
  domestic?: Maybe<Scalars['Boolean']>;
  /** 상담 번호 */
  index?: Maybe<Scalars['Int']>;
  /** 문의 유형 ID 목록 */
  inquiryTypeIds?: Maybe<Array<Scalars['ID']>>;
  /** 닉네임 검색 여부 */
  nickname?: Maybe<Scalars['Boolean']>;
  /** 시작 시점 범위 */
  range?: Maybe<LegacyDateTimeRangeInput>;
  /**
   * 검색어 (고객 닉네임, 고객 전화번호, 메모, 차종, 차량번호)
   *
   * ### Constraints
   *
   * 1. 최소 1자
   */
  term?: Maybe<Scalars['String']>;
};

/** 연료. */
export enum Fuel {
  /** 디젤 */
  Diesel = 'DIESEL',
  /** 전기 */
  Electricity = 'ELECTRICITY',
  /** 기타 */
  Extra = 'EXTRA',
  /** 휘발유 */
  Gasoline = 'GASOLINE',
  /** 수소 */
  Hydrogen = 'HYDROGEN',
  /** LPG */
  Lpg = 'LPG'
}

/** 유종. */
export type FuelType = {
  __typename?: 'FuelType';
  /** 기본 연료. */
  base: Fuel;
  /** 하이브리드 여부. */
  hybrid: Scalars['Boolean'];
};

/** 유종 입력값. */
export type FuelTypeInput = {
  /** 기본 연료. */
  base: Fuel;
  /** 하이브리드 여부. */
  hybrid: Scalars['Boolean'];
};

/** 사용자 성별 enum */
export enum Gender {
  /** 여성 */
  Female = 'FEMALE',
  /** 남성 */
  Male = 'MALE'
}

export type GetAppointmentsOfCompanyRequestFilterDto = {
  /** 예약 상태 목록(OR) */
  statuses?: Maybe<Array<AppointmentStatusEnum>>;
};

export type GetAppointmentsOfUserAccountFilterRequestDto = {
  /** 예약 상태 목록(OR) */
  statuses?: Maybe<Array<AppointmentStatusEnum>>;
};

export type GetNotificationCountOfAccountFilterRequestDto = {
  /** 알림의 조회 여부. */
  viewed?: Maybe<Scalars['Boolean']>;
};

/** 취급 차량 국적 */
export enum HandleNationality {
  /** 국산/수입 */
  All = 'ALL',
  /** 국산 */
  OnlyDomestic = 'ONLY_DOMESTIC',
  /** 수입 */
  OnlyForeign = 'ONLY_FOREIGN'
}

/** 해시태그 종류 */
export enum HashtagVariety {
  /** 사전정의된 문항을 선택한 해시태그 */
  Predefined = 'PREDEFINED',
  /** 프리셋을 포함한 사용자가 입력한 해시태그 */
  Userdefined = 'USERDEFINED'
}

/** 공휴일 */
export type Holiday = {
  __typename?: 'Holiday';
  /** 날짜. */
  date: Scalars['Date'];
  /** 이름. */
  name: Scalars['String'];
  /** 대체공휴일 여부. */
  substituded: Scalars['Boolean'];
};

/** 요일별 운영시간 목록 */
export type HoursByDay = {
  __typename?: 'HoursByDay';
  FRI?: Maybe<TimeRange>;
  MON?: Maybe<TimeRange>;
  SAT?: Maybe<TimeRange>;
  SUN?: Maybe<TimeRange>;
  TUE?: Maybe<TimeRange>;
  TUR?: Maybe<TimeRange>;
  WED?: Maybe<TimeRange>;
};

/** 요일별 업무시간 */
export type HoursByDayInput = {
  FRI?: Maybe<TimeRangeInput>;
  MON?: Maybe<TimeRangeInput>;
  SAT?: Maybe<TimeRangeInput>;
  SUN?: Maybe<TimeRangeInput>;
  TUE?: Maybe<TimeRangeInput>;
  TUR?: Maybe<TimeRangeInput>;
  WED?: Maybe<TimeRangeInput>;
};


/** 아이콘 */
export enum Icon {
  /** 체크 아이콘 */
  Check = 'CHECK',
  /** X 아이콘 */
  Cross = 'CROSS'
}

/** 위택스 본인인증이 되지 않은 경우. */
export type IdentityNotVerifiedForWetax = {
  __typename?: 'IdentityNotVerifiedForWetax';
  message: Scalars['String'];
};

/** 위택스 본인인증이 요청되지 않거나 본인인증이 만료된 경우. */
export type IdentityVerficationForWetaxNotRequested = {
  __typename?: 'IdentityVerficationForWetaxNotRequested';
  message: Scalars['String'];
};

/** 이미지 파일 */
export type Image = File & {
  __typename?: 'Image';
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 메타데이터 */
  metadata?: Maybe<ImageMetadata>;
  /** URL */
  url: Scalars['String'];
};

/** 이미지 파일 메타데이터 */
export type ImageMetadata = {
  __typename?: 'ImageMetadata';
  /** 이미지 높이 (px) */
  height: Scalars['Int'];
  /** 이미지 넓이 (px) */
  width: Scalars['Int'];
};

/** 견적 문의 고객 정보. */
export type InquireEstimateClientData = {
  /**
   * 이름.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. - 제외.
   */
  phone: Scalars['String'];
};

/** 견적 문의 정보. */
export type InquireEstimateData = {
  /** 고객 정보. */
  client: InquireEstimateClientData;
  /** 내용 정보. */
  detail: InquireEstimateDetailData;
  /** 차량 정보. */
  vehicle: InquireEstimateVehicleData;
};

/** 견적 문의 내용 정보. */
export type InquireEstimateDetailData = {
  /** 상품 조회 영역. */
  area?: Maybe<AreaInput>;
  /**
   * 상품 조회 영역 목록.
   *
   * ### Constraints
   *
   * 1. 최대 10개.
   */
  areas?: Maybe<Array<AreaInput>>;
  /**
   * 본문.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자.
   */
  body?: Maybe<Scalars['String']>;
  /**
   * 첨부 파일 목록.
   *
   * ### Constraints
   *
   * 1. 최대 10개의 사진 또는 영상.
   */
  files?: Maybe<Array<FileInput>>;
  /** 보험 처리 방식. */
  insurance?: Maybe<InsuranceEnum>;
};

export type InquireEstimateError = {
  message: Scalars['String'];
};

/** 견적 문의 남기기 실패. */
export type InquireEstimateFail = {
  __typename?: 'InquireEstimateFail';
  error: InquireEstimateError;
};

export type InquireEstimateResponse = InquireEstimateFail | InquireEstimateSuccess;

/** 견적 문의 남기기 성공. */
export type InquireEstimateSuccess = {
  __typename?: 'InquireEstimateSuccess';
  /** 남겨진 견적 문의. */
  inquiry: EstimateInquiry;
};

/** 견적 문의 차량 정보. */
export type InquireEstimateVehicleData = {
  /** 차량 ID. */
  id?: Maybe<Scalars['String']>;
  /**
   * 모델명.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  modelName?: Maybe<Scalars['String']>;
  /**
   * 연식
   *
   * ### Constraints
   *
   * 1. 최소 1, 최대 9999
   */
  modelYear?: Maybe<Scalars['Int']>;
  /**
   * 차량 번호.
   *
   * ### Constraints
   *
   * 1. 공백 제외의 차량 번호 형식.
   */
  plateNumber?: Maybe<Scalars['String']>;
  /**
   * 차대번호.
   *
   * ### Constraints
   *
   * 1. 17자리의 알파벳 대문자 및 숫자.
   */
  vin?: Maybe<Scalars['String']>;
};

/** 문의 */
export type Inquiry = {
  /** 정비 희망 지역 목록 */
  areas: Array<Area>;
  /** 내용 */
  body: Scalars['String'];
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  files: Array<File>;
  /** ID */
  id: Scalars['ID'];
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 문의 유형 */
  type?: Maybe<InquiryType>;
  /** 문의 차량. */
  vehicle?: Maybe<Vehicle>;
};

/** 문의 분야 추가 요구 field */
export enum InquiryExtraField {
  /** 보험 방식 */
  InsuranceType = 'INSURANCE_TYPE',
  /** 타이어 스펙 */
  TireSpec = 'TIRE_SPEC'
}

/** 문의 작성 가이드 */
export type InquiryGuide = Node & {
  __typename?: 'InquiryGuide';
  /** 설명 */
  description: Scalars['String'];
  examples: Array<Image>;
  /** 아이콘 */
  icon: Icon;
  /** 가이드 ID */
  id: Scalars['ID'];
  /** 제목 */
  title: Scalars['String'];
};

/** 문의 분야 */
export type InquiryType = Node & {
  __typename?: 'InquiryType';
  /** 상담 가능 여부 */
  counselable: Scalars['Boolean'];
  /** 설명 */
  description: Scalars['String'];
  extraFields: Array<InquiryExtraField>;
  guides: Array<InquiryGuide>;
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

/** 보험 처리 방식 */
export enum Insurance {
  /** 자차 보험 (Collision Damage Waiver) */
  Cdw = 'CDW',
  /** 대물 보험 (Property Damage Liability) */
  Lp = 'LP',
  /** 자비 처리 */
  None = 'NONE'
}

/** 보험 처리 */
export enum InsuranceEnum {
  /** 자차보험 */
  Cdw = 'CDW',
  /** 대물보험 */
  Lp = 'LP',
  /** 자비처리 */
  None = 'NONE'
}

/** 보험처리 주문 */
export type InsurancedOrder = OrderBase & {
  __typename?: 'InsurancedOrder';
  /** @deprecated Use totalAmount instead. */
  amount: Scalars['Int'];
  /** 캐시백 예상금액 */
  cashbackAmount: Scalars['Int'];
  /** 캐시백 적용율 (소수점 넷째자리까지 지원) */
  cashbackRate: Scalars['Float'];
  counsel?: Maybe<Counsel>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 추가할인 (KRW) */
  discountAmount: Scalars['Int'];
  /** 만료 TIMESTAMP */
  expiresAt?: Maybe<Scalars['LegacyDateTime']>;
  /** ID */
  id: Scalars['ID'];
  items: Array<OrderItem>;
  /** 주문명 */
  name: Scalars['String'];
  payment?: Maybe<Payment>;
  /**
   * ### Order Type
   * 총 결제 금액
   *
   * ### InsurancedOrder Type
   * 총 청구 금액
   *
   * ### NoneInsurancedOrder Type
   * 총 결제 금액
   */
  paymentAmount: Scalars['Int'];
  /** 추천 이유 */
  recommendedReason?: Maybe<Scalars['String']>;
  /** 거부 사유 */
  rejectedReason?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  /** 운임비 (KRW) */
  shippingCharge: Scalars['Int'];
  /** 총액 */
  totalAmount: Scalars['Int'];
  /** 상품 총 금액 */
  totalItemAmount: Scalars['Int'];
  /** 수정 TIMESTAMP */
  updatedAt: Scalars['LegacyDateTime'];
  vehicle?: Maybe<Vehicle>;
};


/** 보험처리 주문 */
export type InsurancedOrderCashbackAmountArgs = {
  in?: Maybe<Currency>;
};


/** 보험처리 주문 */
export type InsurancedOrderDiscountAmountArgs = {
  in?: Maybe<Currency>;
};


/** 보험처리 주문 */
export type InsurancedOrderPaymentAmountArgs = {
  in?: Maybe<Currency>;
};


/** 보험처리 주문 */
export type InsurancedOrderTotalAmountArgs = {
  in?: Maybe<Currency>;
};


/** 보험처리 주문 */
export type InsurancedOrderTotalItemAmountArgs = {
  in?: Maybe<Currency>;
};

/** 올바르지 않은 이메일 인증 코드. */
export type InvalidEmailVerificationCodeError = VerifyEmailError & {
  __typename?: 'InvalidEmailVerificationCodeError';
  message: Scalars['String'];
};

/** 결제 요청 메세지 */
export type InvoiceMessage = Message & {
  __typename?: 'InvoiceMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  order?: Maybe<OrderBase>;
  status: MessageStatus;
};

/** 상품 */
export type Item = Node & {
  __typename?: 'Item';
  /** ID */
  id: Scalars['ID'];
  /** 상품명 */
  name: Scalars['String'];
};

/** 상품이 이미 존재함 */
export type ItemExistsError = Error & {
  __typename?: 'ItemExistsError';
  /** 존재하는 상품 */
  item: Item;
  message: Scalars['String'];
};


/** 고객 문의 답변 성공 response */
export type LeaveComplaintResponseSuccess = {
  __typename?: 'LeaveComplaintResponseSuccess';
  /** 생성된 고객 문의 답변 */
  response: ComplaintResponse;
};

/** 고객 문의 생성 성공 response */
export type LeaveComplaintSuccess = {
  __typename?: 'LeaveComplaintSuccess';
  /** 생성된 고객 문의 */
  complaint: Complaint;
};

/** 사용자 퇴장 메세지 */
export type LeaveMessage = Message & {
  __typename?: 'LeaveMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
  user?: Maybe<User>;
};

export type LeaveSearchableCompanyReviewData = {
  /**
   * 내용.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 400자.
   *
   * 2. imageUrls가 없을 경우에는 필수값임
   */
  content?: Maybe<Scalars['String']>;
  /**
   * 첨부 이미지 목록.
   *
   * ### Constraints
   *
   * 1. 최대 5개의 사진.
   *
   * 2. content가 없을 경우에는 필수값임
   */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 리뷰가 작성된 검색 가능 업체 ID. */
  searchableCompanyId: Scalars['ID'];
  /** 리뷰 유형. */
  type: SearchableCompanyReviewTypeEnum;
};

export type LeaveSearchableCompanyReviewError = {
  message: Scalars['String'];
};

/** 검색 가능 업체 리뷰를 삭제하는데 실패했을 때 반환되는 응답. */
export type LeaveSearchableCompanyReviewFail = {
  __typename?: 'LeaveSearchableCompanyReviewFail';
  error: LeaveSearchableCompanyReviewError;
};

export type LeaveSearchableCompanyReviewResponse = LeaveSearchableCompanyReviewFail | LeaveSearchableCompanyReviewSuccess;

/** 검색 가능 업체 리뷰를 삭제하는데 성공했을 때 반환되는 응답. */
export type LeaveSearchableCompanyReviewSuccess = {
  __typename?: 'LeaveSearchableCompanyReviewSuccess';
  /** 생성된 검색 가능 업체 리뷰. */
  searchableCompanyReview: SearchableCompanyReview;
};



/** 시점 범위 */
export type LegacyDateTimeRange = {
  __typename?: 'LegacyDateTimeRange';
  /** 시작 시점 */
  from?: Maybe<Scalars['LegacyDateTime']>;
  /** 종료 시점 */
  to?: Maybe<Scalars['LegacyDateTime']>;
};

/** 시점 범위 */
export type LegacyDateTimeRangeInput = {
  /** 시작 시점 */
  from?: Maybe<Scalars['LegacyDateTime']>;
  /** 종료 시점 */
  to?: Maybe<Scalars['LegacyDateTime']>;
};

/** 사용자 알림 수신 설정 */
export type LegacyNotificationSetting = {
  __typename?: 'LegacyNotificationSetting';
  /** 예약 알림 수신 여부 */
  appointment: Scalars['Boolean'];
  /** 상담 알림 수신 여부 */
  counsel: Scalars['Boolean'];
  /** 마케팅 알림 수신 여부 */
  marketing?: Maybe<Scalars['Boolean']>;
  /** 게시물 알림 수신 여부 */
  post: Scalars['Boolean'];
};

/** 지자체별 자동차세 담당 부서. */
export type LocalAutomobileTaxDepartment = {
  __typename?: 'LocalAutomobileTaxDepartment';
  /** 연락처. 다른 연락처 필드들과 달리 "-"가 포함되어 반환. "063-290-2322~2328" 형태의 연락처 범위도 포함. */
  contact: Scalars['String'];
  /** 부서명. */
  departmentName?: Maybe<Scalars['String']>;
  /** 지자체명. */
  localName: Scalars['String'];
};

export type LocalAutomobileTaxDepartmentFilter = {
  /**
   * 검색어.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  term?: Maybe<Scalars['String']>;
};

export type LoginError = {
  message: Scalars['String'];
};

/** 로그인 실패 */
export type LoginFail = {
  __typename?: 'LoginFail';
  error: LoginError;
};

export type LoginResponse = LoginFail | LoginSuccess;

/** 로그인 성공 */
export type LoginSuccess = {
  __typename?: 'LoginSuccess';
  /** Access token */
  accessToken: Scalars['String'];
  /** Access token이 만료될 시각 */
  expiresAt: Scalars['DateTime'];
};

export type MakeAdminAccountError = {
  message: Scalars['String'];
};

/** 관리자 계정 전환 실패. */
export type MakeAdminAccountFail = {
  __typename?: 'MakeAdminAccountFail';
  error: MakeAdminAccountError;
};

export type MakeAdminAccountResponse = MakeAdminAccountFail | MakeAdminAccountSuccess;

/** 관리자 계정 전환 성공. */
export type MakeAdminAccountSuccess = {
  __typename?: 'MakeAdminAccountSuccess';
  /** 관리자 계정으로 전환된 계정. */
  account: AdminAccount;
};

export type MakeBizAccountError = {
  message: Scalars['String'];
};

/** 비즈 계정 전환 실패. */
export type MakeBizAccountFail = {
  __typename?: 'MakeBizAccountFail';
  error: MakeBizAccountError;
};

export type MakeBizAccountResponse = MakeBizAccountFail | MakeBizAccountSuccess;

/** 비즈 계정 전환 성공. */
export type MakeBizAccountSuccess = {
  __typename?: 'MakeBizAccountSuccess';
  /** 비즈 계정으로 전환된 계정. */
  account: BizAccount;
};

export type MakeCounselorAccountError = {
  message: Scalars['String'];
};

/** 상담사 계정 전환 실패. */
export type MakeCounselorAccountFail = {
  __typename?: 'MakeCounselorAccountFail';
  error: MakeCounselorAccountError;
};

export type MakeCounselorAccountResponse = MakeCounselorAccountFail | MakeCounselorAccountSuccess;

/** 상담사 계정 전환 성공. */
export type MakeCounselorAccountSuccess = {
  __typename?: 'MakeCounselorAccountSuccess';
  /** 상담사 계정으로 전환된 계정. */
  account: CounselorAccount;
};

export type MakeEstimateData = {
  /**
   * 설명.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * 예상 수리 기간(일).
   *
   * ### Constraints
   *
   * 1. 최소 0일, 최대 65535일.
   */
  estimatedRepairDays?: Maybe<Scalars['Int']>;
  /**
   * 첨부 파일 목록.
   *
   * ### Constraints
   *
   * 1. 최대 10개의 사진 및 영상.
   */
  files?: Maybe<Array<FileInput>>;
  /**
   * 가격(KRW).
   *
   * ### Constraints
   *
   * 1. 최소 0원, 최대 4294967295원
   */
  price?: Maybe<Scalars['Int']>;
};

export type MakeUserAccountError = {
  message: Scalars['String'];
};

/** 사용자 계정 전환 실패. */
export type MakeUserAccountFail = {
  __typename?: 'MakeUserAccountFail';
  error: MakeUserAccountError;
};

export type MakeUserAccountResponse = MakeUserAccountFail | MakeUserAccountSuccess;

/** 사용자 계정 전환 성공. */
export type MakeUserAccountSuccess = {
  __typename?: 'MakeUserAccountSuccess';
  /** 사용자 계정으로 전환된 계정. */
  account: UserAccount;
};

/** 업체 구성원 수 제한 초과. */
export type MemberLimitExceededError = AddMemberToCompanyError & {
  __typename?: 'MemberLimitExceededError';
  /** 업체의 구성원 수 제한. */
  limit: Scalars['Int'];
  message: Scalars['String'];
};

/** 이미 존재하는 구성원. */
export type MemberWithIdAlreadyExistsError = AddMemberToCompanyError & {
  __typename?: 'MemberWithIdAlreadyExistsError';
  /** 이미 존재하는 구성원. */
  member: CompanyMember;
  message: Scalars['String'];
};

/** 퀵상담 메세지 */
export type Message = {
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

/** 퀵상담 메세지 connection */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node: Message;
};

/** 메세지 전송 상태 */
export enum MessageStatus {
  /** 전송 실패 */
  Failed = 'FAILED',
  /** 전송 대기 중 */
  Pending = 'PENDING',
  /** 전송 완료 */
  Sent = 'SENT'
}

/** 메세지 종류 */
export enum MessageType {
  /** 스토어 리뷰 요청 메세지 */
  AppReview = 'APP_REVIEW',
  /** 희망 정비 지역 입력 메세지 */
  AreaInput = 'AREA_INPUT',
  /** 업체 메세지 */
  Company = 'COMPANY',
  /** 상담 종료 안내 메세지 */
  End = 'END',
  /** 사용자 입장 메세지 */
  Enter = 'ENTER',
  /** 견적서 메세지 */
  Estimate = 'ESTIMATE',
  /** 파일 메세지 */
  File = 'FILE',
  /** 결제 요청 메세지 */
  Invoice = 'INVOICE',
  /** 사용자 퇴장 메세지 */
  Leave = 'LEAVE',
  /** 차량 번호 입력 메세지 */
  PlateNumberInput = 'PLATE_NUMBER_INPUT',
  /** 대기 상태 전환 메세지 */
  Queue = 'QUEUE',
  /** 상담 자동 종료 경고 메세지 */
  Suspended = 'SUSPENDED',
  /** 텍스트 메세지 */
  Text = 'TEXT',
  /** 차대번호 입력 메세지 */
  VinInput = 'VIN_INPUT'
}

/** 주행거리 기록. */
export type MileageRecord = {
  __typename?: 'MileageRecord';
  /** 생성 시점. */
  createdAt: Scalars['LegacyDate'];
  /** 주행거리(km). */
  mileage: Scalars['Int'];
};

/** 휴대전화 통신사 */
export enum MobileCarrier {
  /** KT */
  Kt = 'KT',
  /** LG U+ */
  Lgu = 'LGU',
  /** SKT */
  Skt = 'SKT'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 검색 가능 업체 리뷰 키워드를 생성합니다.
   *
   * ### Constraints
   *
   * 1. 사용자는 업체당 1개의 키워드 묶음만 생성 가능
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  CreateSearchableCompanyReviewKeywords: CreateSearchableCompanyReviewKeywordsResponse;
  /**
   * 예약을 수락합니다.
   *
   * ### Constraints
   *
   * 1. 관리자와 예약 업체 구성원만 호출 가능합니다.
   */
  acceptAppointment: AcceptAppointmentResponse;
  /**
   * 업체에 구성원을 추가합니다.
   *
   * ### Constraints
   *
   * 1. `ADMIN`과 `COUNSELOR`만 호출 가능.
   */
  addMemberToCompany: AddMemberToCompanyResponse;
  /**
   * 상담에 사용자를 추가합니다.
   *
   * ### Permissions
   *
   * *1. 상담사만 접근 가능
   */
  addUserToCounsel: AddUserToCounselSuccess;
  /**
   * 작업의 수리를 추가합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저, 업체 스태프만 가능
   */
  addWorkRepair: AddWorkRepairResponse;
  /** 애플 로그인. */
  appleLogin?: Maybe<LoginSuccess>;
  /** 애플 가입. */
  appleSignup: LoginResponse;
  /** 주문을 승인합니다. */
  approveOrder: ApproveOrderResponse;
  /** 사용자를 차단합니다. */
  blockUser: BlockUserSuccess;
  /** 게시물을 북마크합니다. */
  bookmarkPost: BookmarkPostResponse;
  /** 게시물 이슈를 북마크합니다. */
  bookmarkPostIssue: BookmarkPostIssueResponse;
  /**
   * 예약을 파기(노쇼)합니다.
   *
   * ### Constraints
   *
   * 1. 관리자와 예약 업체 구성원만 호출 가능.
   */
  breakAppointment: BreakAppointmentResponse;
  /**
   * 예약을 취소합니다.
   *
   * ### Constraints
   *
   * 1. 관리자, 예약 업체 구성원, 예약 고객만 호출 가능.
   */
  cancelAppointment: CancelAppointmentResponse;
  /** 결제를 취소합니다. */
  cancelPayment: CancelPaymentSuccess;
  /**
   * 업체 구성원의 역할을 변경합니다.
   *
   * ### Constraints
   *
   * 1. 어드민 계정, 또는 해당 업체에 매니저 권한을 가진 비즈 계정만 호출 가능.
   */
  changeCompanyMemberRole: ChangeCompanyMemberRoleResponse;
  /** 계정의 알림 설정을 변경합니다. */
  changeMyNotificationSetting: ChangeMyNotificationSettingResponse;
  /** 현재 계정의 비밀번호를 변경합니다. */
  changeMyPassword: Scalars['Boolean'];
  /** 현재 계정의 인증된 휴대전화번호를 변경합니다. */
  changeMyPhone: ChangeMyPhoneResponse;
  /**
   * 로그인한 계정의 프로필을 업데이트 합니다.
   *
   * ### Constraints
   *
   * 1. 인증 필요.
   */
  changeMyProfile: ChangeMyProfileResponse;
  /** 미답변 상태의 커뮤니티 게시물 이슈의 상태를 완료로 변경합니다. */
  changePostIssueToDone: ChangePostIssueToDoneResponse;
  /**
   * 작업의 예상 출고일을 수정합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저만 가능
   */
  changeWorkExpectedDateOfRelease: ChangeWorkExpectedDateOfReleaseResponse;
  /**
   * 작업의 수리를 수정합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저, 업체 스태프만 가능
   */
  changeWorkRepair: ChangeWorkRepairResponse;
  completePayment: CompletePaymentResponse;
  /** 배송을 완료합니다. */
  completeShipping: CompleteShippingSuccess;
  /**
   * 작업을 완료합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저만 가능
   */
  completeWork: CompleteWorkResponse;
  /** 새로운 댓글을 생성합니다 */
  createComment: CreateCommentResponse;
  createCompany: CreateCompanyResponse;
  /**
   * 상담을 생성합니다.
   *
   * ### Permissions
   *
   * *1. 상담사만 접근 가능
   */
  createCounsel: CreateCounselResponse;
  /** 상담 리뷰를 생성합니다. */
  createCounselReview: CreateCounselReviewSuccess;
  /** 해시태그를 생성합니다. */
  createHashtag: CreateHashtagResponse;
  /** 상품을 생성합니다. */
  createItem: CreateItemResponse;
  /** 공지사항을 생성합니다. */
  createNotice: CreateNoticeSuccess;
  /** 주문을 생성합니다. */
  createOrder: CreateOrderSuccess;
  /** 요청자가 작성한 게시물을 생성합니다. */
  createPost: CreatePostResponse;
  /** 관리자가 작성한 게시물을 생성합니다. */
  createPostAdmin: CreatePostResponse;
  /**
   * QA 계정들을 생성합니다.
   *
   * ### Constraints
   *
   * 1. 운영 환경에서는 호출 불가.
   */
  createQaAccounts: CreateQaAccountsResponse;
  /** 새로운 답글을 생성합니다. */
  createReply: CreateReplyResponse;
  /** 배송을 생성합니다. */
  createShipping: CreateShippingSuccess;
  /** 템플릿을 생성합니다. */
  createTemplate: CreateTemplateSuccess;
  /** 차량을 생성합니다. */
  createVehicle: CreateVehicleResponse;
  /**
   * 작업을 생성합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저만 가능
   */
  createWork: CreateWorkResponse;
  /** 댓글을 삭제합니다. */
  deleteComment: DeleteCommentResponse;
  /**
   * 업체를 닥터차가 관리하던 업체 데이터에서 완전히 삭제합니다.
   *
   * ### Constraints
   *
   * 1. `ADMIN`과 `COUNSELOR`만 호출 가능.
   */
  deleteCompany: DeleteCompanyResponse;
  /** 고객 문의를 삭제합니다. */
  deleteComplaint: DeleteComplaintSuccess;
  /**
   * 상담을 삭제합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  deleteCounsel: DeleteCounselResponse;
  /** 메세지를 삭제합니다. */
  deleteMessage: DeleteMessageSuccess;
  /** 주문을 삭제합니다. */
  deleteOrder: DeleteOrderSuccess;
  /** 게시물을 삭제합니다. */
  deletePost: DeletePostResponse;
  /**
   * 검색 가능 업체 리뷰를 삭제합니다.
   *
   * ### Permissions
   *
   * *1. 어드민과 작성자만 접근 가능
   */
  deleteSearchableCompanyReview: DeleteSearchableCompanyReviewResponse;
  /** 템플릿을 삭제합니다. */
  deleteTemplate: DeleteTemplateSuccess;
  /** 차량을 삭제합니다. 요청자가 소유한 차량만 삭제 가능합니다. */
  deleteVehicle: DeleteVehicleResponse;
  /**
   * 견적을 수정합니다.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   *
   * 2. 발행된 견적은 수정할 수 없음.
   */
  editEstimate: EditEstimateResponse;
  /**
   * 견적 요청의 메모를 수정합니다.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  editEstimateRequestMemo: EditEstimateRequestMemoResponse;
  /**
   * 검색 가능 업체 리뷰를 수정합니다.
   *
   * ### Permissions
   *
   * *1. 어드민과 작성자만 접근 가능
   */
  editSearchableCompanyReview: EditSearchableCompanyReviewResponse;
  /**
   * 예약을 완료합니다.
   *
   * ### Constraints
   *
   * 1. 관리자와 업체 구성원만 호출 가능.
   */
  finishAppointment: FinishAppointmentResponse;
  /**
   * 상담을 종료합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  finishCounsel: FinishCounselSuccess;
  /** 아임포트를 거치지 않고 결제를 강제로 완료합니다. */
  forceCompletePayment: CompletePaymentResponse;
  /** 구글 로그인. */
  googleLogin?: Maybe<LoginSuccess>;
  /** 구글 가입. */
  googleSignup: LoginResponse;
  /** 견적을 남깁니다. */
  inquireEstimate: InquireEstimateResponse;
  /** 커뮤니티 게시물 검색용 파일 업로드를 위한 업로드 URL(유효기간 10분)과 파일 URL(유효기간 1일)을 발급합니다. */
  issueCommunityPostSearchFileUpload: Upload;
  /** 사용자 파일 업로드를 위한 업로드 URL(유효기간 10분)과 파일 URL을 발급합니다. */
  issueUserContentFileUpload: Upload;
  /** 차대번호 이미지 업로드를 위한 업로드 URL(유효기간 10분)과 파일 URL을 발급합니다. */
  issueVinImageUpload: Upload;
  /** 카카오 로그인. */
  kakaoLogin?: Maybe<LoginSuccess>;
  /** 카카오 가입. */
  kakaoSignup: LoginResponse;
  /** 고객 문의를 남깁니다. */
  leaveComplaint: LeaveComplaintSuccess;
  /** 고객 문의에 답변을 남깁니다. */
  leaveComplaintResponse: LeaveComplaintResponseSuccess;
  /**
   * 검색 가능 업체 리뷰를 남깁니다.
   *
   * ### Constraints
   *
   * 1. 사용자는 업체당 1개의 리뷰만 생성 가능
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  leaveSearchableCompanyReview: LeaveSearchableCompanyReviewResponse;
  /** 로컬 로그인. */
  localLogin?: Maybe<LoginSuccess>;
  /** 로컬 가입. */
  localSignup: LoginResponse;
  /** Refresh token cookie를 삭제해 로그아웃 합니다. */
  logout: Scalars['Boolean'];
  /**
   * 계정을 관리자 계정으로 전환합니다.
   *
   * ### Constraints
   *
   * 1. 어드민 계정만 호출 가능.
   *
   * 2. 하나 이상의 업체에 구성원으로 소속된 비즈 계정은 다른 역할로 전환 불가능.
   */
  makeAdminAccount: MakeAdminAccountResponse;
  /**
   * 계정을 비즈 계정으로 전환합니다.
   *
   * ### Constraints
   *
   * 1. 어드민 계정만 호출 가능.
   */
  makeBizAccount: MakeBizAccountResponse;
  /**
   * 계정을 상담사 계정으로 전환합니다.
   *
   * ### Constraints
   *
   * 1. 어드민 계정만 호출 가능.
   *
   * 2. 하나 이상의 업체에 구성원으로 소속된 비즈 계정은 다른 역할로 전환 불가능.
   */
  makeCounselorAccount: MakeCounselorAccountResponse;
  /**
   * 계정을 사용자 계정으로 전환합니다.
   *
   * ### Constraints
   *
   * 1. 어드민 계정만 호출 가능.
   *
   * 2. 하나 이상의 업체에 구성원으로 소속된 비즈 계정은 다른 역할로 전환 불가능.
   */
  makeUserAccount: MakeUserAccountResponse;
  /**
   * 견적을 발행합니다.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  publishEstimate: PublishEstimateResponse;
  /**
   * 상담을 대기 상태로 전환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  queueCounsel: QueueCounselSuccess;
  /** 댓글에 공감합니다. */
  reactToComment: ReactToCommentResponse;
  /** 게시물에 공감을 표시합니다. */
  reactToPost: ReactToPostResponse;
  /** 쿠폰을 사용처리합니다. */
  redeemCoupon: RedeemCouponResponse;
  /** Cookie에 담긴 refresh token을 통해 새로운 access token을 발급합니다. */
  refreshAccessToken: LoginSuccess;
  /** 주문을 거부합니다. */
  rejectOrder: RejectOrderResponse;
  /**
   * 업체에서 구성원을 제거합니다.
   *
   * ### Constraints
   *
   * 1. `ADMIN`, `COUNSELOR`, 또는 해당 업체에 매니저 권한을 가진 비즈 계정만 호출 가능.
   */
  removeMemberFromCompany: RemoveMemberFromCompanyResponse;
  /**
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  removeUserFromCounsel: RemoveUserFromCounselSuccess;
  /**
   * 작업의 수리를 삭제합니다.
   *
   * ### Permissions
   *
   * *1. 관리자
   *
   * *2. 업체 매니저
   *
   * *3. 업체 스태프
   */
  removeWorkRepair: RemoveWorkRepairResponse;
  /** 커뮤니티 댓글에 대한 신고를 접수합니다. */
  reportCommunityComment: ReportCommunityCommentResponse;
  /** 커뮤니티 게시물에 대한 신고를 접수합니다. */
  reportCommunityPost: ReportCommunityPostResponse;
  /** 커뮤니티 답글에 대한 신고를 접수합니다. */
  reportCommunityReply: ReportCommunityReplyResponse;
  /** 커뮤니티 사용자에 대한 신고를 접수합니다. */
  reportCommunityUser: ReportCommunityUserResponse;
  /**
   * 검색 가능 업체 리뷰를 신고합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  reportSearchableCompanyReview: ReportSearchableCompanyReviewResponse;
  /** 새로운 예약을 요청합니다. */
  requestAppointment: RequestAppointmentResponse;
  /** 요청자에게 상담 리뷰를 요청합니다. */
  requestCounselReview: Scalars['Boolean'];
  /** 인증 코드를 통한 이메일 인증을 요청합니다. */
  requestEmailVerification: Scalars['Boolean'];
  /** 견적을 요청합니다. */
  requestEstimate: RequestEstimateResponse;
  /**
   * 위택스 본인 인증 요청.
   *
   * ### Constraints
   *
   * 1. 인증 필요.
   */
  requestIdentityVerificationForWetax: RequestIdentityVerificationForWetaxResponse;
  /** 인증 코드를 통한 휴대전화번호 인증을 요청합니다. */
  requestPhoneVerification: Scalars['Boolean'];
  /** 커뮤니티 게시물 작성자에게 커뮤니티 게시물에 대한 스토어 리뷰 알림을 전송합니다. */
  requestStoreReviewForPost: Scalars['Boolean'];
  /** 계정의 비밀번호를 잊었을 경우 인증된 이메일을 통해 계정의 비밀번호를 재설정 합니다. */
  resetPassword: ResetPasswordResponse;
  /**
   * 견적 요청에 응답합니다.
   *
   * ### Constraints
   *
   * 1. 관리자 및 견적이 요청된 업체 구성원 비즈 계정만 호출 가능.
   *
   * 2. 한 견적 요청에 대해 한 번만 응답 가능.
   */
  responseEstimateRequest: ResponseEstimateRequestResponse;
  /**
   * 종료된 상담을 재시작합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  restartCounsel: RestartCounselSuccess;
  /**
   * 게시글의 작성자가 가장 도움된 답변 작성자를 선택합니다.
   *
   * ### Permissions
   *
   * *1. 소유자만 조회 가능
   */
  selectBestCommenter: SelectBestCommenterResponse;
  /** 스토어 리뷰 요청 메세지를 전송합니다. */
  sendAppReviewMessage: SendAppReviewMessageSuccess;
  /** 정비 희망 지역 입력 메세지를 전송합니다. */
  sendAreaInputMessage: SendAreaInputMessageSuccess;
  /** 업체 메세지를 전송합니다. */
  sendCompanyMessage: SendCompanyMessageSuccess;
  /** 견적 메세지를 전송합니다. */
  sendEstimateMessage: SendEstimateMessageSuccess;
  sendFileMessage: SendFileMessageSuccess;
  /** 결제 요청 메세지를 전송합니다. */
  sendInvoiceMessage: SendInvoiceMessageSuccess;
  /** 차량 번호 입력 메세지를 전송합니다. */
  sendPlateNumberInputMessage: SendPlateNumberInputMessageSuccess;
  /** 퀵상담에 텍스트 메세지를 전송합니다. */
  sendTextMessage: SendTextMessageSuccess;
  /** 차대번호 입력 메세지를 전송합니다. */
  sendVinInputMessage: SendVinInputMessageSuccess;
  /** FCM token을 설정합니다. */
  setFcmToken?: Maybe<Scalars['Boolean']>;
  /**
   * 작업의 예상 출고일을 수정합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저만 가능
   */
  shareWork: ShareWorkResponse;
  /** 배송을 시작합니다. */
  startShipping: StartShippingSuccess;
  /**
   * 선견적을 중단하고 상담을 시작합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  stopEstimationAndStartCounsel: StopEstimationAndStartCounselResponse;
  /**
   * 작업을 중단합니다.
   *
   * ### Permissions
   *
   * *1. 관리자와 업체 매니저만 가능
   */
  stopWork: StopWorkResponse;
  /** 검색 가능 업체 정보 수정 요청. */
  suggestSearchableCompanyEdition: SuggestSearchableCompanyEditionResponse;
  /** 사용자를 차단 해제합니다. */
  unblockUser: UnblockUserSuccess;
  /** 게시물에 대한 북마크를 취소합니다. */
  unbookmarkPost: UnbookmarkPostResponse;
  /** 게시물 이슈를 북마크 해제합니다. */
  unbookmarkPostIssue: UnbookmarkPostIssueResponse;
  /**
   * 상담을 대기 상태에서 해제합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  unqueueCounsel: UnqueueCounselSuccess;
  /** 댓글에 대한 공감을 취소합니다. */
  unreactToComment: UnreactToCommentResponse;
  /** 게시물에 대한 공감을 취소합니다. */
  unreactToPost: UnreactToPostResponse;
  /**
   * 댓글의 도윰여부 투표를 취소합니다.
   *
   * ### Permissions
   *
   * *1. 소유자만 조회 가능
   */
  unvoteToComment: VoteToCommentResponse;
  /**
   * 게시물의 도윰여부 투표를 취소합니다.
   *
   * ### Permissions
   *
   * *1. 소유자만 조회 가능
   */
  unvoteToPost: VoteToPostResponse;
  /**
   * 예약 정보를 수정합니다.
   *
   * ### Constraints
   *
   * 1. 관리자, 예약 업체 구성원, 예약 고객만 호출 가능.
   *
   * 2. 예약 고객의 경우 요청 상태의 예약만 수정 가능.
   *
   * 3. 업체 구성원의 경우 요청 및 수락 상태의 예약만 수정 가능.
   */
  updateAppointment: UpdateAppointmentResponse;
  /**
   * 댓글을 수정합니다.
   * @deprecated 사용 안 함
   */
  updateComment: UpdateCommentResponse;
  updateCompany: UpdateCompanyResponse;
  /**
   * 상담을 수정합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  updateCounsel: UpdateCounselSuccess;
  /** 상담 리뷰를 수정합니다. */
  updateCounselReview: UpdateCounselReviewSuccess;
  updateCursor: UpdateCursorSuccess;
  updateInquiry: UpdateInquirySuccess;
  /**
   * 사용자 자신의 정보를 업데이트하는 mutation
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  updateMe: UpdateUserSuccess;
  /** 공지사항을 수정합니다. */
  updateNotice: UpdateNoticeResponse;
  /** 주문을 수정합니다. */
  updateOrder: UpdateOrderSuccess;
  /**
   * 게시물을 수정합니다.
   * @deprecated v2.7.0 이상에서 더 이상사용하지 않는 기능입니다.
   */
  updatePost: UpdatePostResponse;
  /** 게시물 이슈를 수정합니다. */
  updatePostIssue: UpdatePostIssueResponse;
  /** 배송을 수정합니다. */
  updateShipping: UpdateShippingSuccess;
  /** 템플릿을 수정합니다. */
  updateTemplate: UpdateTemplateSuccess;
  /** 차량을 수정합니다. */
  updateVehicle: UpdateVehicleResponse;
  /** 상품 목록을 upsert합니다. */
  upsertItems: UpsertItemsSuccess;
  /** 인증 코드로 이메일을 인증하고 인증 결과를 반환합니다. */
  verifyEmail: VerifyEmailResponse;
  /** 인증 코드로 휴대전화번호를 인증하고 인증 결과를 반환합니다. */
  verifyPhone: Scalars['Boolean'];
  /**
   * 현재 계정의 모든 알림을 조회합니다.
   *
   * ### Constraints
   *
   * 1. 인증 필요.
   */
  viewAllMyNotifications: ViewAllMyNotificationsResponse;
  /** 댓글 및 답글을 조회합니다. */
  viewComment: ViewCommentResponse;
  /** 고객 문의 답변을 조회합니다. */
  viewComplaintResponse: ViewComplaintResponseSuccess;
  /** 알림을 조회합니다. */
  viewNotification: ViewNotificationResponse;
  /** 게시물을 조회합니다. */
  viewPost: ViewPostResponse;
  /**
   * 댓글 도움여부를 투표합니다.
   *
   * ### Permissions
   *
   * *1. 소유자만 조회 가능
   */
  voteToComment: VoteToCommentResponse;
  /**
   * 게시물의 도움여부를 투표합니다.
   *
   * ### Permissions
   *
   * *1. 소유자만 조회 가능
   */
  voteToPost: VoteToPostResponse;
  /** 현재 로그인한 계정을 탈퇴합니다. */
  withdrawMe: WithdrawMeResponse;
};


export type MutationCreateSearchableCompanyReviewKeywordsArgs = {
  data: CreateSearchableCompanyReviewKeywordsData;
};


export type MutationAcceptAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type MutationAddMemberToCompanyArgs = {
  accountId: Scalars['ID'];
  companyId: Scalars['ID'];
  role: CompanyMemberRoleEnum;
};


export type MutationAddUserToCounselArgs = {
  counselId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddWorkRepairArgs = {
  data: AddWorkRepairData;
};


export type MutationAppleLoginArgs = {
  idToken: Scalars['String'];
  nonce: Scalars['String'];
};


export type MutationAppleSignupArgs = {
  email?: Maybe<Scalars['String']>;
  idToken: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  nonce: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationApproveOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationBlockUserArgs = {
  userId: Scalars['ID'];
};


export type MutationBookmarkPostArgs = {
  postId: Scalars['ID'];
};


export type MutationBookmarkPostIssueArgs = {
  postIssueId: Scalars['ID'];
};


export type MutationBreakAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type MutationCancelAppointmentArgs = {
  appointmentId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationCancelPaymentArgs = {
  paymentId: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationChangeCompanyMemberRoleArgs = {
  memberId: Scalars['ID'];
  role: CompanyMemberRoleEnum;
};


export type MutationChangeMyNotificationSettingArgs = {
  data: ChangeAccountNotificationSettingData;
};


export type MutationChangeMyPasswordArgs = {
  password: Scalars['String'];
};


export type MutationChangeMyPhoneArgs = {
  phone: Scalars['String'];
};


export type MutationChangeMyProfileArgs = {
  data: ChangeAccountProfileData;
};


export type MutationChangePostIssueToDoneArgs = {
  postIssueId: Scalars['ID'];
};


export type MutationChangeWorkExpectedDateOfReleaseArgs = {
  data: ChangeWorkExpectedDateOfReleaseData;
};


export type MutationChangeWorkRepairArgs = {
  data: ChangeWorkRepairData;
};


export type MutationCompletePaymentArgs = {
  impId: Scalars['String'];
  orderId: Scalars['String'];
  paymentId: Scalars['String'];
};


export type MutationCompleteShippingArgs = {
  shippingId: Scalars['ID'];
};


export type MutationCompleteWorkArgs = {
  data: CompleteWorkData;
};


export type MutationCreateCommentArgs = {
  data: CreateCommentData;
  postId: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput;
};


export type MutationCreateCounselArgs = {
  data: CreateCounselData;
};


export type MutationCreateCounselReviewArgs = {
  counselId: Scalars['ID'];
  data: CreateCounselReviewData;
};


export type MutationCreateHashtagArgs = {
  data: CreateHashtagData;
};


export type MutationCreateItemArgs = {
  data: CreateItemData;
};


export type MutationCreateNoticeArgs = {
  data: CreateNoticeData;
};


export type MutationCreateOrderArgs = {
  counselId: Scalars['ID'];
  data: CreateOrderData;
  userId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};


export type MutationCreatePostArgs = {
  data: CreatePostData;
};


export type MutationCreatePostAdminArgs = {
  authorId: Scalars['String'];
  data: CreatePostData;
};


export type MutationCreateQaAccountsArgs = {
  data: CreateQaAccountData;
};


export type MutationCreateReplyArgs = {
  commentId: Scalars['ID'];
  data: CreateCommentData;
};


export type MutationCreateShippingArgs = {
  data: CreateShippingData;
  orderId: Scalars['ID'];
};


export type MutationCreateTemplateArgs = {
  data: CreateTemplateData;
  directoryId: Scalars['ID'];
};


export type MutationCreateVehicleArgs = {
  data: CreateVehicleData;
};


export type MutationCreateWorkArgs = {
  data: CreateWorkData;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  companyId: Scalars['ID'];
};


export type MutationDeleteComplaintArgs = {
  complaintId: Scalars['ID'];
};


export type MutationDeleteCounselArgs = {
  counselId: Scalars['ID'];
};


export type MutationDeleteMessageArgs = {
  messageId: Scalars['ID'];
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationDeletePostArgs = {
  postId: Scalars['ID'];
};


export type MutationDeleteSearchableCompanyReviewArgs = {
  data: DeleteSearchableCompanyReviewData;
};


export type MutationDeleteTemplateArgs = {
  templateId: Scalars['ID'];
};


export type MutationDeleteVehicleArgs = {
  vehicleId: Scalars['ID'];
};


export type MutationEditEstimateArgs = {
  data: EditEstimateData;
  estimateId: Scalars['ID'];
};


export type MutationEditEstimateRequestMemoArgs = {
  memo: Scalars['String'];
  requestId: Scalars['ID'];
};


export type MutationEditSearchableCompanyReviewArgs = {
  data: EditSearchableCompanyReviewData;
};


export type MutationFinishAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type MutationFinishCounselArgs = {
  counselId: Scalars['ID'];
};


export type MutationForceCompletePaymentArgs = {
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
};


export type MutationGoogleLoginArgs = {
  idToken: Scalars['String'];
};


export type MutationGoogleSignupArgs = {
  email?: Maybe<Scalars['String']>;
  idToken: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};


export type MutationInquireEstimateArgs = {
  data: InquireEstimateData;
};


export type MutationIssueCommunityPostSearchFileUploadArgs = {
  file: FileUploadInput;
};


export type MutationIssueUserContentFileUploadArgs = {
  file: FileUploadInput;
};


export type MutationIssueVinImageUploadArgs = {
  image: FileUploadInput;
};


export type MutationKakaoLoginArgs = {
  id: Scalars['String'];
};


export type MutationKakaoSignupArgs = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


export type MutationLeaveComplaintArgs = {
  data: CreateComplaintData;
};


export type MutationLeaveComplaintResponseArgs = {
  data: CreateComplaintResponseData;
};


export type MutationLeaveSearchableCompanyReviewArgs = {
  data: LeaveSearchableCompanyReviewData;
};


export type MutationLocalLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLocalSignupArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationMakeAdminAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationMakeBizAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationMakeCounselorAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationMakeUserAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationPublishEstimateArgs = {
  estimateId: Scalars['ID'];
};


export type MutationQueueCounselArgs = {
  counselId: Scalars['ID'];
  reason?: Maybe<QueueReason>;
};


export type MutationReactToCommentArgs = {
  commentId: Scalars['ID'];
  type: ReactionType;
};


export type MutationReactToPostArgs = {
  postId: Scalars['ID'];
  type: ReactionType;
};


export type MutationRedeemCouponArgs = {
  couponId: Scalars['ID'];
  redeemedBy: Scalars['String'];
};


export type MutationRejectOrderArgs = {
  data: RejectOrderData;
  orderId: Scalars['ID'];
};


export type MutationRemoveMemberFromCompanyArgs = {
  memberId: Scalars['ID'];
};


export type MutationRemoveUserFromCounselArgs = {
  counselId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveWorkRepairArgs = {
  data: RemoveWorkRepairData;
};


export type MutationReportCommunityCommentArgs = {
  commentId: Scalars['ID'];
  data: ReportCommunityCommentData;
};


export type MutationReportCommunityPostArgs = {
  data: ReportCommunityPostData;
  postId: Scalars['ID'];
};


export type MutationReportCommunityReplyArgs = {
  data: ReportCommunityReplyData;
  replyId: Scalars['ID'];
};


export type MutationReportCommunityUserArgs = {
  data: ReportCommunityUserData;
  userId: Scalars['ID'];
};


export type MutationReportSearchableCompanyReviewArgs = {
  data: ReportSearchableCompanyReviewData;
};


export type MutationRequestAppointmentArgs = {
  data: RequestAppointmentData;
};


export type MutationRequestCounselReviewArgs = {
  counselId: Scalars['ID'];
};


export type MutationRequestEmailVerificationArgs = {
  email: Scalars['String'];
};


export type MutationRequestEstimateArgs = {
  data: RequestEstimateData;
};


export type MutationRequestIdentityVerificationForWetaxArgs = {
  loginOrganizationCode: WetaxLoginOrganizationCode;
  mobileCarrier?: Maybe<MobileCarrier>;
  mobileNumber: Scalars['String'];
  name: Scalars['String'];
  ssn: Scalars['String'];
};


export type MutationRequestPhoneVerificationArgs = {
  phone: Scalars['String'];
};


export type MutationRequestStoreReviewForPostArgs = {
  postId: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResponseEstimateRequestArgs = {
  estimate?: Maybe<MakeEstimateData>;
  requestId: Scalars['ID'];
};


export type MutationRestartCounselArgs = {
  counselId: Scalars['ID'];
};


export type MutationSelectBestCommenterArgs = {
  body?: Maybe<Scalars['String']>;
  commenterId?: Maybe<Scalars['String']>;
  postId: Scalars['String'];
};


export type MutationSendAppReviewMessageArgs = {
  counselId: Scalars['ID'];
};


export type MutationSendAreaInputMessageArgs = {
  counselId: Scalars['ID'];
};


export type MutationSendCompanyMessageArgs = {
  companyId: Scalars['ID'];
  counselId: Scalars['ID'];
};


export type MutationSendEstimateMessageArgs = {
  counselId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationSendFileMessageArgs = {
  counselId: Scalars['ID'];
  file: FileInput;
};


export type MutationSendInvoiceMessageArgs = {
  counselId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationSendPlateNumberInputMessageArgs = {
  counselId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};


export type MutationSendTextMessageArgs = {
  body: Scalars['String'];
  counselId: Scalars['ID'];
};


export type MutationSendVinInputMessageArgs = {
  counselId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};


export type MutationSetFcmTokenArgs = {
  token: Scalars['String'];
};


export type MutationShareWorkArgs = {
  data: ShareWorkData;
};


export type MutationStartShippingArgs = {
  data: StartShippingData;
  shippingId: Scalars['ID'];
};


export type MutationStopEstimationAndStartCounselArgs = {
  counselId: Scalars['ID'];
};


export type MutationStopWorkArgs = {
  data: StopWorkData;
};


export type MutationSuggestSearchableCompanyEditionArgs = {
  reason: Scalars['String'];
  searchableCompanyId: Scalars['ID'];
};


export type MutationUnblockUserArgs = {
  userId: Scalars['ID'];
};


export type MutationUnbookmarkPostArgs = {
  postId: Scalars['ID'];
};


export type MutationUnbookmarkPostIssueArgs = {
  postIssueId: Scalars['ID'];
};


export type MutationUnqueueCounselArgs = {
  counselId: Scalars['ID'];
};


export type MutationUnreactToCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationUnreactToPostArgs = {
  postId: Scalars['ID'];
};


export type MutationUnvoteToCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationUnvoteToPostArgs = {
  postId: Scalars['ID'];
};


export type MutationUpdateAppointmentArgs = {
  appointmentId: Scalars['ID'];
  data: UpdateAppointmentInput;
};


export type MutationUpdateCommentArgs = {
  commentId: Scalars['ID'];
  data: UpdateCommentData;
};


export type MutationUpdateCompanyArgs = {
  companyId?: Maybe<Scalars['ID']>;
  data: UpdateCompanyInput;
};


export type MutationUpdateCounselArgs = {
  counselId: Scalars['ID'];
  data: UpdateCounselData;
};


export type MutationUpdateCounselReviewArgs = {
  counselId: Scalars['ID'];
  data: UpdateCounselReviewData;
};


export type MutationUpdateCursorArgs = {
  counselId: Scalars['ID'];
  messageId: Scalars['ID'];
};


export type MutationUpdateInquiryArgs = {
  data: UpdateInquiryData;
  inquiryId: Scalars['ID'];
};


export type MutationUpdateMeArgs = {
  data: UpdateUserData;
};


export type MutationUpdateNoticeArgs = {
  data: UpdateNoticeData;
  noticeId: Scalars['ID'];
};


export type MutationUpdateOrderArgs = {
  data: UpdateOrderData;
  orderId: Scalars['ID'];
};


export type MutationUpdatePostArgs = {
  data: UpdatePostData;
  postId: Scalars['ID'];
};


export type MutationUpdatePostIssueArgs = {
  data: UpdatePostIssueData;
  postIssueId: Scalars['ID'];
};


export type MutationUpdateShippingArgs = {
  data: UpdateShippingData;
  shippingId: Scalars['ID'];
};


export type MutationUpdateTemplateArgs = {
  data: UpdateTemplateData;
  templateId: Scalars['ID'];
};


export type MutationUpdateVehicleArgs = {
  data: UpdateVehicleData;
  vehicleId?: Maybe<Scalars['ID']>;
};


export type MutationUpsertItemsArgs = {
  items: Array<CreateItemData>;
};


export type MutationVerifyEmailArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type MutationVerifyPhoneArgs = {
  code: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationViewCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationViewComplaintResponseArgs = {
  responseId: Scalars['ID'];
};


export type MutationViewNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationViewPostArgs = {
  postId: Scalars['ID'];
};


export type MutationVoteToCommentArgs = {
  commentId: Scalars['ID'];
  type: VoteType;
};


export type MutationVoteToPostArgs = {
  postId: Scalars['ID'];
  type: VoteType;
};

/** 새로운 커뮤니티 댓글 알림. */
export type NewCommentNotification = Notification & {
  __typename?: 'NewCommentNotification';
  comment: CommentBase;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  cursor?: Maybe<Scalars['String']>;
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 새로운 고객 문의 답변 알림. */
export type NewComplaintResponseNotification = Notification & {
  __typename?: 'NewComplaintResponseNotification';
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  response?: Maybe<ComplaintResponse>;
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 새로운 커뮤니티 답글 알림. */
export type NewReplyNotification = Notification & {
  __typename?: 'NewReplyNotification';
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  cursor?: Maybe<Scalars['String']>;
  /** ID. */
  id: Scalars['ID'];
  reply: CommentBase;
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

export type NoEstimationToStopError = StopEstimationAndStartCounselError & {
  __typename?: 'NoEstimationToStopError';
  /** 에러 메세지 */
  message: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

/** 자비처리 주문 */
export type NoneInsurancedOrder = OrderBase & {
  __typename?: 'NoneInsurancedOrder';
  /** @deprecated Use totalAmount instead. */
  amount: Scalars['Int'];
  counsel?: Maybe<Counsel>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 추가할인 (KRW) */
  discountAmount: Scalars['Int'];
  /** 만료 TIMESTAMP */
  expiresAt?: Maybe<Scalars['LegacyDateTime']>;
  /** ID */
  id: Scalars['ID'];
  items: Array<OrderItem>;
  /** 주문명 */
  name: Scalars['String'];
  payment?: Maybe<Payment>;
  /**
   * ### Order Type
   * 총 결제 금액
   *
   * ### InsurancedOrder Type
   * 총 청구 금액
   *
   * ### NoneInsurancedOrder Type
   * 총 결제 금액
   */
  paymentAmount: Scalars['Int'];
  /** 추천 이유 */
  recommendedReason?: Maybe<Scalars['String']>;
  /** 거부 사유 */
  rejectedReason?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  /** 운임비 (KRW) */
  shippingCharge: Scalars['Int'];
  /** 총액 */
  totalAmount: Scalars['Int'];
  /** 상품 총 금액 */
  totalItemAmount: Scalars['Int'];
  /** 수정 TIMESTAMP */
  updatedAt: Scalars['LegacyDateTime'];
  vehicle?: Maybe<Vehicle>;
};


/** 자비처리 주문 */
export type NoneInsurancedOrderDiscountAmountArgs = {
  in?: Maybe<Currency>;
};


/** 자비처리 주문 */
export type NoneInsurancedOrderPaymentAmountArgs = {
  in?: Maybe<Currency>;
};


/** 자비처리 주문 */
export type NoneInsurancedOrderTotalAmountArgs = {
  in?: Maybe<Currency>;
};


/** 자비처리 주문 */
export type NoneInsurancedOrderTotalItemAmountArgs = {
  in?: Maybe<Currency>;
};

/** 공지사항 */
export type Notice = {
  __typename?: 'Notice';
  /** 본문 */
  body: Scalars['String'];
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  files: Array<File>;
  /** ID */
  id: Scalars['ID'];
  /** 제목 */
  title: Scalars['String'];
};

/** 공지사항 connection */
export type NoticeConnection = {
  __typename?: 'NoticeConnection';
  edges: Array<NoticeEdge>;
  pageInfo: PageInfo;
};

export type NoticeEdge = {
  __typename?: 'NoticeEdge';
  cursor: Scalars['String'];
  node: Notice;
};

/** 인 앱 알림. */
export type Notification = {
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationConnectionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type NotificationConnectionEdge = {
  __typename?: 'NotificationConnectionEdge';
  cursor: Scalars['String'];
  node: Notification;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  /** 예약 알림 수신 여부. */
  appointment: Scalars['Boolean'];
  /** 커뮤니티 알림 수신 여부. */
  community: Scalars['Boolean'];
  /** 상담 알림 수신 여부. */
  counsel: Scalars['Boolean'];
  /** 마케팅 알림 수신 여부. */
  marketing?: Maybe<Scalars['Boolean']>;
};

/** 사용자 알림 수신 설정 */
export type NotificationSettingInput = {
  /** 예약 알림 수신 여부 */
  appointment?: Maybe<Scalars['Boolean']>;
  /** 상담 알림 수신 여부 */
  counsel?: Maybe<Scalars['Boolean']>;
  /** 마케팅 알림 수신 여부 */
  marketing?: Maybe<Scalars['Boolean']>;
  /** 게시물 알림 수신 여부 */
  post?: Maybe<Scalars['Boolean']>;
};

/** 현장 사용 쿠폰 */
export type OnSiteCoupon = CouponBase & {
  __typename?: 'OnSiteCoupon';
  /** 혜택 */
  benefit?: Maybe<CouponBenefit>;
  /** 쿠폰명과 관련된 용어의 추가설명 */
  explanation?: Maybe<CouponExplanation>;
  /** ID */
  id: Scalars['ID'];
  /** 발행 timestamp */
  issuedAt: Scalars['LegacyDateTime'];
  /** 발행처 */
  issuer: CouponIssuer;
  /** 쿠폰명 */
  name: Scalars['String'];
  /**
   * 유의사항들
   * @deprecated 유의사항들로 변경되었습니다. 유의사항 목록 문구의 첫번째 텍스트 문자열을 반환합니다.
   */
  precautionStatement?: Maybe<Scalars['String']>;
  /** 유의사항들 */
  precautions?: Maybe<Array<Scalars['String']>>;
  /** 현장 사용확인용 URL */
  redeemUrl: Scalars['String'];
  /** 사용 timestamp */
  redeemedAt?: Maybe<Scalars['LegacyDateTime']>;
  /** 사용처 */
  redeemer: CouponRedeemer;
  /** 사용 유효기간 기간 */
  validityPeriod: LegacyDateTimeRange;
};

/** 열린 시점 범위. */
export type OpenedDateTimeRangeInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

/** 업체 운영 시간 */
export type OperatingTime = {
  __typename?: 'OperatingTime';
  /** 공휴일 운영 여부 */
  closesOnHoliday: Scalars['Boolean'];
  /** 요일별 운영 시간 */
  hoursByDay: HoursByDay;
  /** 비고 */
  remark?: Maybe<Scalars['String']>;
};

/** 운영 시간 */
export type OperatingTimeInput = {
  /** 공휴일 운영 여부 */
  closesOnHoliday: Scalars['Boolean'];
  /** 요일별 운영 시간 */
  hoursByDay: HoursByDayInput;
  /** 비고 */
  remark?: Maybe<Scalars['String']>;
};

/** 비사고 주문 */
export type Order = OrderBase & {
  __typename?: 'Order';
  /** @deprecated Use totalAmount instead. */
  amount: Scalars['Int'];
  counsel?: Maybe<Counsel>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 추가할인 (KRW) */
  discountAmount: Scalars['Int'];
  /** 만료 TIMESTAMP */
  expiresAt?: Maybe<Scalars['LegacyDateTime']>;
  /** ID */
  id: Scalars['ID'];
  items: Array<OrderItem>;
  /** 주문명 */
  name: Scalars['String'];
  payment?: Maybe<Payment>;
  /**
   * ### Order Type
   * 총 결제 금액
   *
   * ### InsurancedOrder Type
   * 총 청구 금액
   *
   * ### NoneInsurancedOrder Type
   * 총 결제 금액
   */
  paymentAmount: Scalars['Int'];
  /** 추천 이유 */
  recommendedReason?: Maybe<Scalars['String']>;
  /** 거부 사유 */
  rejectedReason?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  /** 운임비 (KRW) */
  shippingCharge: Scalars['Int'];
  /** 총액 */
  totalAmount: Scalars['Int'];
  /** 상품 총 금액 */
  totalItemAmount: Scalars['Int'];
  /** 수정 TIMESTAMP */
  updatedAt: Scalars['LegacyDateTime'];
  vehicle?: Maybe<Vehicle>;
};


/** 비사고 주문 */
export type OrderDiscountAmountArgs = {
  in?: Maybe<Currency>;
};


/** 비사고 주문 */
export type OrderPaymentAmountArgs = {
  in?: Maybe<Currency>;
};


/** 비사고 주문 */
export type OrderTotalAmountArgs = {
  in?: Maybe<Currency>;
};


/** 비사고 주문 */
export type OrderTotalItemAmountArgs = {
  in?: Maybe<Currency>;
};

/** 주문 */
export type OrderBase = {
  /** @deprecated Use totalAmount instead. */
  amount: Scalars['Int'];
  counsel?: Maybe<Counsel>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 추가할인 (KRW) */
  discountAmount: Scalars['Int'];
  /** 만료 TIMESTAMP */
  expiresAt?: Maybe<Scalars['LegacyDateTime']>;
  /** ID */
  id: Scalars['ID'];
  items: Array<OrderItem>;
  /** 주문명 */
  name: Scalars['String'];
  payment?: Maybe<Payment>;
  /**
   * ### Order Type
   * 총 결제 금액
   *
   * ### InsurancedOrder Type
   * 총 청구 금액
   *
   * ### NoneInsurancedOrder Type
   * 총 결제 금액
   */
  paymentAmount: Scalars['Int'];
  /** 추천 이유 */
  recommendedReason?: Maybe<Scalars['String']>;
  /** 거부 사유 */
  rejectedReason?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  /** 운임비 (KRW) */
  shippingCharge: Scalars['Int'];
  /** 총액 */
  totalAmount: Scalars['Int'];
  /** 상품 총 금액 */
  totalItemAmount: Scalars['Int'];
  /** 수정 TIMESTAMP */
  updatedAt: Scalars['LegacyDateTime'];
  vehicle?: Maybe<Vehicle>;
};


/** 주문 */
export type OrderBaseDiscountAmountArgs = {
  in?: Maybe<Currency>;
};


/** 주문 */
export type OrderBasePaymentAmountArgs = {
  in?: Maybe<Currency>;
};


/** 주문 */
export type OrderBaseTotalAmountArgs = {
  in?: Maybe<Currency>;
};


/** 주문 */
export type OrderBaseTotalItemAmountArgs = {
  in?: Maybe<Currency>;
};

export type OrderBaseEdge = {
  __typename?: 'OrderBaseEdge';
  cursor: Scalars['String'];
  node: OrderBase;
};

/** 주문 connection */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<OrderBaseEdge>;
  pageInfo: PageInfo;
};

/** 주문 상품 */
export type OrderItem = {
  __typename?: 'OrderItem';
  /** 상품 코드 */
  code: Scalars['String'];
  /** 할인율 (소수점 넷째자리까지 지원) */
  discountRate: Scalars['Float'];
  /** 상품명 */
  name: Scalars['String'];
  /**
   * ### Order Type
   * 결제 금액
   *
   * ### InsurancedOrder Type
   * 청구 금액
   *
   * ### NoneInsurancedOrder Type
   * 결제 금액
   */
  paymentAmount: Scalars['Int'];
  /** 개당 가격 */
  price: Scalars['Int'];
  /** 수량 */
  quantity: Scalars['Int'];
  /** 총액 */
  totalAmount: Scalars['Int'];
};


/** 주문 상품 */
export type OrderItemPaymentAmountArgs = {
  in?: Maybe<Currency>;
};


/** 주문 상품 */
export type OrderItemPriceArgs = {
  in?: Maybe<Currency>;
};


/** 주문 상품 */
export type OrderItemTotalAmountArgs = {
  in?: Maybe<Currency>;
};

/** 주문 상품 정보 */
export type OrderItemInput = {
  /**
   * 할인율 (소수점 넷째자리까지 지원)
   *
   * ### Constraints
   *
   * 1. 최소 0.0000 (할인 없음), 최대 1.0000 (전액 할인)
   */
  discountRate?: Maybe<Scalars['Float']>;
  /** 상품 ID */
  itemId: Scalars['ID'];
  /**
   * 개당 가격 (KRW)
   *
   * ### Constraints
   *
   * 1. 최소 301원, 최대 1억원
   */
  price: Scalars['Int'];
  /**
   * 수량
   *
   * ### Constraints
   *
   * 1. 최소 1개, 최대 1만개
   */
  quantity: Scalars['Int'];
};

/** 페이지 정보 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** 마지막 edge의 cursor */
  endCursor: Scalars['String'];
  /** 다음 페이지 존재 여부 */
  hasNextPage: Scalars['Boolean'];
  /** 이전 페이지 존재 여부 */
  hasPreviousPage: Scalars['Boolean'];
  /** 첫번째 edge의 cursor */
  startCursor: Scalars['String'];
};

/** 결제 */
export type Payment = {
  __typename?: 'Payment';
  /** 결제/취소 금액 (KRW) */
  amount: Scalars['Int'];
  buyer?: Maybe<Buyer>;
  cancelledAt?: Maybe<Scalars['LegacyDateTime']>;
  card?: Maybe<Card>;
  cashReceipt?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['LegacyDateTime']>;
  /** ID */
  id: Scalars['ID'];
  /** 결제 방식 */
  method?: Maybe<PaymentMethod>;
  order: OrderBase;
  /** 결제 상태 */
  status: PaymentStatus;
};

/** 결제 완료 response */
export type PaymentCompleted = {
  __typename?: 'PaymentCompleted';
  /** 결제 정보 */
  payment: Payment;
};

/** 결제 방식 */
export enum PaymentMethod {
  /** 카드 결제 */
  Card = 'CARD',
  /** 카카오페이 */
  KakaoPay = 'KAKAO_PAY',
  /** 수동 결제 */
  Manual = 'MANUAL',
  /** 실시간 계좌이체 */
  Transfer = 'TRANSFER'
}

/** 결제 상태 */
export enum PaymentStatus {
  /** 환불 완료 */
  Cancelled = 'CANCELLED',
  /** 결제 실패 */
  Failed = 'FAILED',
  /** 결제 완료 */
  Paid = 'PAID',
  /** 미결제/가상 계좌 발급 완료 */
  Ready = 'READY'
}

/** 결제 미완료 response */
export type PaymentUncompleted = {
  __typename?: 'PaymentUncompleted';
  /** 결제 정보 */
  payment: Payment;
};

export type PendingCounselConnectionFilter = {
  /** 문의 차량의 국산 여부 */
  domestic?: Maybe<Scalars['Boolean']>;
  /** 상담 번호 */
  index?: Maybe<Scalars['Int']>;
  /** 문의 유형 ID 목록 */
  inquiryTypeIds?: Maybe<Array<Scalars['ID']>>;
  /** 시작 시점 범위 */
  range?: Maybe<LegacyDateTimeRangeInput>;
  /**
   * 검색어 (고객 닉네임, 고객 전화번호, 메모, 차종, 차량번호)
   *
   * ### Constraints
   *
   * 1. 최소 1자
   */
  term?: Maybe<Scalars['String']>;
};

/** 휴대전화번호가 인증 되지 않음. */
export type PhoneHasNotVerifiedError = ChangeMyPhoneError & LoginError & {
  __typename?: 'PhoneHasNotVerifiedError';
  message: Scalars['String'];
  /** 인증이 필요한 휴대전화번호. */
  phone: Scalars['String'];
};

/** 장소 */
export type Place = {
  __typename?: 'Place';
  /** 연락처 */
  contact?: Maybe<Scalars['String']>;
  /** 좌표 */
  coordinates: Coordinates;
  /**
   * 장소 ID
   * @deprecated naver api로 교체 후 사용하지 않음. 호환성을 위해 임시로 랜덤값을 반환함. 2023-09-11
   */
  id?: Maybe<Scalars['ID']>;
  /** 장소명 */
  name: Scalars['String'];
  /** 지번 주소 */
  regionAddress: Scalars['String'];
  /** 도로명 주소 */
  roadAddress: Scalars['String'];
  /** 행정구역 */
  zone?: Maybe<Zone>;
};

/** 차량 번호 입력 메세지 */
export type PlateNumberInputMessage = Message & {
  __typename?: 'PlateNumberInputMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
  vehicle?: Maybe<Vehicle>;
};

/** 커뮤니티 게시물 */
export type Post = Inquiry & {
  __typename?: 'Post';
  /** 정비 희망 지역 목록 */
  areas: Array<Area>;
  author?: Maybe<User>;
  /** 내용 */
  body: Scalars['String'];
  bookmarkCount: Scalars['Int'];
  bookmarked?: Maybe<Scalars['Boolean']>;
  /** @deprecated hashtags로 대체됨. v2.7.0부터  사용하지 않음 */
  category: PostCategory;
  commentConnection: CommentConnection;
  commentCount: Scalars['Int'];
  /** 글쓴이의 답변 만족도 */
  commentSatisfaction?: Maybe<CommentSatisfaction>;
  /** 게시물을 통해 생성된 상담 */
  counsel?: Maybe<Counsel>;
  /** @deprecated counsel로 대체됨. 현재 비즈니스로직 상으로는 하나의 상담이 생성된 게시물은 다른 상담을 생성할 수 없기 때문에 1:1관계로 변경. 2022-02-13 v3.4.1 */
  counsels?: Maybe<Array<Counsel>>;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
  /** 글 삭제 TIMESTAMP */
  deletedAt?: Maybe<Scalars['LegacyDateTime']>;
  files: Array<File>;
  hashtags: Array<PostHashtag>;
  /** ID */
  id: Scalars['ID'];
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  issue: PostIssue;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  reacted?: Maybe<ReactionType>;
  reactionCount: Scalars['Int'];
  reactionTypes: Array<ReactionType>;
  /** 게시물과 연관성이 있는 게시물들을 반환합니다 */
  relatedPosts: Array<Post>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 문의 유형 */
  type?: Maybe<InquiryType>;
  /** 문의 차량. */
  vehicle?: Maybe<Vehicle>;
  viewCount: Scalars['Int'];
  viewed?: Maybe<Scalars['Boolean']>;
  votes: Array<Vote>;
  withCounselorComment: Scalars['Boolean'];
};


/** 커뮤니티 게시물 */
export type PostCommentConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 커뮤니티 게시물 */
export type PostReactionCountArgs = {
  filter: ReactionCountFilter;
};

/** 차수다 게시물 주제 */
export type PostCategory = Node & {
  __typename?: 'PostCategory';
  /** 설명 */
  description: Scalars['String'];
  extraFields: Array<PostExtraField>;
  /** ID */
  id: Scalars['ID'];
  /** 주제명 */
  name: Scalars['String'];
};

/** 게시물 connection */
export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<PostEdge>;
  pageInfo: PageInfo;
};

export type PostEdge = {
  __typename?: 'PostEdge';
  cursor: Scalars['String'];
  node: Post;
};

/** 차수다 주제 추가 요구 field */
export enum PostExtraField {
  /** 문의 분야 */
  InquiryType = 'INQUIRY_TYPE'
}

/** 게시물 해시태그 */
export type PostHashtag = {
  __typename?: 'PostHashtag';
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

/** 게시물 해시태그 생성 */
export type PostHashtagInput = {
  /**
   * 이름
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 100자
   *
   * 2. 한글, 영문자, 숫자, -, _ 만 됨
   */
  name: Scalars['String'];
  /** 해시태그 종류 */
  variety?: Maybe<HashtagVariety>;
};

/** 게시물 이슈 */
export type PostIssue = {
  __typename?: 'PostIssue';
  bookmarked: Scalars['Boolean'];
  id: Scalars['ID'];
  lastComment?: Maybe<Comment>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 답변이 필요하거나 답변을 단 게시물 */
  post: Post;
  status: PostIssueStatus;
};

/** 게시물 이슈 상태 */
export enum PostIssueStatus {
  /** 답변 완료 */
  Done = 'DONE',
  /** 답변 필요 */
  InProgress = 'IN_PROGRESS',
  /** 미답변 */
  ToDo = 'TO_DO'
}

export type PostIssuesFilter = {
  /** 요청자의 게시물 북마크 여부 */
  bookmarked?: Maybe<Scalars['Boolean']>;
  /** 게시물 카테고리 ID 목록 */
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  /** 채팅이 시작된 게시물만 검색 여부 */
  chatOpened?: Maybe<Scalars['Boolean']>;
  /** 게시물 생성 시점 범위 */
  createdAtRange?: Maybe<LegacyDateTimeRangeInput>;
  /** 닉네임 검색 여부 */
  nickname?: Maybe<Scalars['Boolean']>;
  /** 자신이 답변한 게시물만 검색 여부 */
  onlyIReplied?: Maybe<Scalars['Boolean']>;
  /** 게시물 이슈 상태 */
  status?: Maybe<PostIssueStatus>;
  /**
   * 차종과 차량번호에 대한 검색어
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  term?: Maybe<Scalars['String']>;
};

/** 게시물 이슈 목록 정렬 조건 */
export enum PostIssuesOrder {
  /** 게시물 생성 시각 기준 오름차순 정렬 */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 게시물 생성 시각 기준 내림차순 정렬 */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** 유저가 게시물에 누른 투표 */
export type PostVote = Vote & {
  __typename?: 'PostVote';
  post: Post;
  /** 투표 유형 */
  type: VoteType;
  voter: User;
};

export type PressedMessageColumn = {
  __typename?: 'PressedMessageColumn';
  id: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  sendedAt?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  /** 상품을 제공하는 업체. */
  company?: Maybe<Company>;
  /** 수입차 전용 상품 여부. */
  foreignVehicleOnly: Scalars['Boolean'];
  /** ID. */
  id: Scalars['ID'];
  /** 소개 문구. */
  intro?: Maybe<Scalars['String']>;
  /** 품질 등급. */
  qualityLevel?: Maybe<ProductQualityLevel>;
  /** 추천 이유 HTML 문자열 목록. */
  recommendations: Array<Scalars['Html']>;
  /** 썸네일 이미지. */
  thumbnailImage?: Maybe<Image>;
};

/** 상품 품질 등급. */
export enum ProductQualityLevel {
  /** 최고 품질 */
  Best = 'BEST',
  /** 실속 */
  Practical = 'PRACTICAL'
}

/** AND 조건의 상품 필터. */
export type ProductsFilter = {
  /** 업체가 포함 되어야 할 영역. */
  companyArea?: Maybe<AreaInput>;
  /**
   * 업체명에 포함 되어야 할 검색어.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  companyName?: Maybe<Scalars['String']>;
  /** 수입 차량 전용 상품 여부. */
  foreignVehicleOnly?: Maybe<Scalars['Boolean']>;
};

export type PublishEstimateError = {
  message: Scalars['String'];
};

/** 견적 발행 실패. */
export type PublishEstimateFail = {
  __typename?: 'PublishEstimateFail';
  error: PublishEstimateError;
};

export type PublishEstimateResponse = PublishEstimateFail | PublishEstimateSuccess;

/** 견적 발행 성공. */
export type PublishEstimateSuccess = {
  __typename?: 'PublishEstimateSuccess';
  /** 발행된 견적. */
  estimate: Estimatation;
};

export type Query = {
  __typename?: 'Query';
  /** 해당 ID의 계정. */
  account?: Maybe<Account>;
  /**
   * 최신 순으로 정렬된 사용자 목록.
   *
   * ### Constraints
   *
   * 1. 어드민 계정만 호출 가능.
   */
  accounts: Array<Account>;
  /** 검색어로 검색된 주소 목록 */
  addresses: Array<Address>;
  /** 영업시간내 after milliseconds가 지난 시점을 반환합니다. */
  afterInCounselableHours: Scalars['LegacyDateTime'];
  /** 해당 ID의 예약 */
  appointment?: Maybe<Appointment>;
  /**
   * 예약 목록.
   *
   * ### Constraints
   *
   * 1. 관리자만 접근 가능.
   */
  appointments: Array<Appointment>;
  /** 차수다 게시물의 댓글을 반환합니다. */
  comment?: Maybe<CommentBase>;
  /** 커뮤니티의 게시물과 댓글의 합산 갯수  */
  communityPostAndCommentCount: Scalars['Int'];
  /** 커뮤니티 게시물 connection. */
  communityPostConnection: PostConnection;
  /** 조건에 맞는 업체 목록을 조회 */
  companies: Array<Company>;
  /** 조건에 맞는 업체 개수를 반환 */
  companiesCount: Scalars['Int'];
  /** 해당 ID의 업체 */
  company?: Maybe<Company>;
  /** 모든 업체 시설 목록 */
  companyFacilities: Array<CompanyFacility>;
  /** 모든 업체 추천 이유 목록 */
  companyRecommendations: Array<CompanyRecommendation>;
  /** 모든 업체 전문 분야 목록 */
  companySpecialties: Array<Specialty>;
  /** 모든 업체 목록 유형 */
  companyTypes: Array<CompanyType>;
  complaint: Complaint;
  /**
   * 고객문의 목록을 반환합니다.
   * 오프셋 기반의 페이지네이션입니다
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 조회 가능
   */
  complaints: Array<Complaint>;
  /**
   * 해당 ID의 상담을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  counsel: Counsel;
  counselMeta: CounselMeta;
  /** 해당 시점의 상담 가능 여부를 반환합니다. */
  counselableAt: Scalars['Boolean'];
  /** 상담 제공 시간을 반환합니다. */
  counselableHours: BusinessHours;
  /**
   * 상담사 목록
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  counselors: Array<User>;
  /** 해당 ID의 쿠폰을 반환합니다. */
  coupon: CouponBase;
  /** 해당 ID의 견적. */
  estimate?: Maybe<Estimatation>;
  /**
   * 견적 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  estimateCount: Scalars['Int'];
  /**
   * 견적 문의 목록.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  estimateInquiries: Array<EstimateInquiry>;
  /** 해당 ID의 견적 문의. */
  estimateInquiry?: Maybe<EstimateInquiry>;
  /** 조건에 맞는 견적 요청 개수. */
  estimateInquiryCount: Scalars['Int'];
  /** 해당 ID의 견적 요청. */
  estimateRequest?: Maybe<EstimateRequest>;
  /**
   * 견적 요청 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  estimateRequestCount: Scalars['Int'];
  /**
   * 견적 요청 목록.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  estimateRequests: Array<EstimateRequest>;
  /**
   * 견적 목록.
   *
   * ### Constraints
   *
   * 1. 관리자 계정만 접근 가능.
   */
  estimates: Array<Estimatation>;
  /** 요청자가 속해 있는 견적 준비 중 또는 견적 완료 상태의 상담 목록을 반환합니다. */
  estimatingCounsels: Array<Counsel>;
  /** 해당 ID의 이벤트를 반환합니다. */
  event: Event;
  /** 이벤트 connection을 반환합니다. */
  eventConnection: EventConnection;
  /** 제목과 본문을 가지고 해시태그프리셋의 각 해시태그의 이름과 정확히 일치하는 해시태그목록을 반환합니다 */
  extractHashtags: Array<PostHashtag>;
  /**
   * 종료된 상담 connection을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  finishedCounselConnection: CounselConnection;
  /** 해당 날짜의 공휴일 여부. */
  holiday: Scalars['Boolean'];
  /** 해당 날짜 범위 내의 공휴일 목록. */
  holidays: Array<Holiday>;
  /** 모든 문의 분야 목록 */
  inquiryTypes: Array<InquiryType>;
  /** 해당 날짜의 공휴일 여부. */
  isHoliday: Scalars['Boolean'];
  /** 상품을 반환합니다. 해당 ID의 상품이 없을 경우 null을 반환합니다. */
  item?: Maybe<Item>;
  /** 지자체별 자동차세 담당 부서 목록. */
  localAutomobileTaxDepartments: Array<LocalAutomobileTaxDepartment>;
  loggedIn: Scalars['Boolean'];
  /**
   * ### Permissions
   *
   * *1. 로그인한 사용자만 조회 가능
   */
  me: User;
  /**
   * 현재 로그인 한 계정.
   *
   * ### Constraints
   *
   * 1. 인증 필요.
   */
  myAccount: Account;
  /** 해당 ID의 공지사항을 반환합니다. */
  notice: Notice;
  /** 최신순으로 정렬된 모든 공지사항 connection을 반환합니다. */
  noticeConnection: NoticeConnection;
  /** 진행 중인 모든 상담 개수를 반환합니다. */
  ongoingCounselCount: Scalars['Int'];
  /**
   * 요청자가 속해 있는 진행 상태의 상담 목록을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  ongoingCounsels: Array<Counsel>;
  /** 해당 ID의 주문을 반환합니다. */
  order: OrderBase;
  /**
   * 접수 상태의 상담 connection을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  pendingCounselConnection: CounselConnection;
  /** 검색어로 검색된 장소 목록 */
  places: Array<Place>;
  /** 차수다 게시물을 반환합니다. */
  post?: Maybe<Post>;
  /** 모든 차수다 주제 목록 */
  postCategories: Array<PostCategory>;
  /** 해당 ID의 게시물 이슈를 반환합니다. */
  postIssue: PostIssue;
  /** 조건에 맞는 게시물 이슈 개수를 반환합니다. */
  postIssueCount: Scalars['Int'];
  /** 조건에 맞는 게시물 이슈 목록을 반환합니다. */
  postIssues: Array<PostIssue>;
  /** 해당 ID의 상품. */
  product?: Maybe<Product>;
  /** 조건에 해당하는 상품 목록. */
  products: Array<Product>;
  /**
   * 요청자가 속해 있는 대기 상태의 상담 목록을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  queuedCounsels: Array<Counsel>;
  recommendedBlogPostConnection: BlogPostConnection;
  /** 검색 가능 업체. */
  searchableCompany?: Maybe<SearchableCompany>;
  /** 검색 가능 업체 connection. */
  searchableCompanyConnection: SearchableCompanyConnection;
  /** 검색 가능 업체 장비 목록. */
  searchableCompanyEquipments: Array<SearchableCompanyEquipment>;
  /** 해당 ID의 검색 가능 업체 리뷰. */
  searchableCompanyReview?: Maybe<SearchableCompanyReview>;
  /** 조건에 맞는 검색가능한 업체 리뷰 개수. */
  searchableCompanyReviewCount: Scalars['Int'];
  /**
   * 검색 가능 업체 리뷰 목록.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  searchableCompanyReviews: Array<SearchableCompanyReview>;
  searchedCounsels: Array<Counsel>;
  /** 해당 ID의 배송을 반환합니다. */
  shipping: Shipping;
  /** 배송 connection */
  shippingConnection: ShippingConnection;
  /** 배송 개수 */
  shippingCount: Scalars['Int'];
  /** 입력한 검색어와 접두어가 동일한 추천 해시태그 목록을 반환합니다. */
  suggestedHashtags: Array<PostHashtag>;
  /** 추천 검색어 목록. */
  suggestedTerms: Array<Scalars['String']>;
  /**
   * 자동차세 환급 가능 차량 목록.
   *
   * ### Constraints
   *
   * 1. 인증 필요.
   *
   * 2. 위택스 본인 인증만료 이전에만 호출 가능.
   */
  taxRefundableAutomobiles: TaxRefundableAutomobilesResponse;
  template: Template;
  templateDirectory: TemplateDirectory;
  /**
   * 미납 자동차세 목록.
   *
   * ### Constraints
   *
   * 1. 인증 필요.
   *
   * 2. 위택스 본인인증 만료 이전에만 호출 가능.
   */
  unpaidAutomobileTaxes: UnpaidAutomobileTaxesResponse;
  /** 사용자 */
  user: User;
  /** 모든 사용자 특성 목록 */
  userCharacteristics: Array<UserCharacteristic>;
  /**
   * 사용자 파일 업로드를 위한 업로드 URL과 파일 URL을 발급합니다.
   * @deprecated issueUserContentFileUpload mutation으로 대체됨. 2022-12-29 v3.3.2.
   */
  userContentFileUpload: Upload;
  /** 해당 ID의 차량. */
  vehicle?: Maybe<Vehicle>;
  /** 차량 제조사. */
  vehicleBrand?: Maybe<VehicleBrand>;
  /** 차량 제조사 목록. */
  vehicleBrands: Array<VehicleBrand>;
  /** 모든 차량 특성 목록. */
  vehicleCharacteristics: Array<VehicleCharacteristic>;
  /** 차량 등급. */
  vehicleGrade?: Maybe<VehicleGrade>;
  /** 소유주명과 차량번호에 일치하는 차량의 원부 정보. */
  vehicleLedger?: Maybe<SearchVehicleLedgerResponse>;
  /** 차량 모델. */
  vehicleModel?: Maybe<VehicleModel>;
  /** 차량 세부 모델. */
  vehicleSubmodel?: Maybe<VehicleSubmodel>;
  /** 차량 트림. */
  vehicleTrim?: Maybe<VehicleTrim>;
  /** 해당 ID의 작업. */
  work?: Maybe<Work>;
  /**
   * 작업 목록.
   *
   * ### Permissions
   *
   * *1. 관리자
   */
  works: Array<Work>;
  /** 좌표의 행정구역 */
  zone?: Maybe<Zone>;
};


export type QueryAccountArgs = {
  accountId: Scalars['ID'];
};


export type QueryAccountsArgs = {
  filter?: Maybe<AccountsFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryAddressesArgs = {
  first: Scalars['Int'];
  term: Scalars['String'];
};


export type QueryAfterInCounselableHoursArgs = {
  after: Scalars['Int'];
  from: Scalars['LegacyDateTime'];
};


export type QueryAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryAppointmentsArgs = {
  filter: AppointmentsFilter;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: AppointmentSortOrderEnum;
};


export type QueryCommentArgs = {
  commentId: Scalars['ID'];
};


export type QueryCommunityPostConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CommunityPostConnectionFilter>;
  first?: Maybe<Scalars['Int']>;
  hashtags?: Maybe<Array<Scalars['String']>>;
  imageUrl?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};


export type QueryCompaniesArgs = {
  filter: CompaniesFilter;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: CompanySortOrderEnum;
};


export type QueryCompaniesCountArgs = {
  filter: CompaniesFilter;
};


export type QueryCompanyArgs = {
  companyId: Scalars['ID'];
};


export type QueryComplaintArgs = {
  complaintId: Scalars['ID'];
};


export type QueryComplaintsArgs = {
  filter: ComplaintsFilter;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: ComplaintsOrder;
};


export type QueryCounselArgs = {
  counselId: Scalars['ID'];
};


export type QueryCounselMetaArgs = {
  status: CounselStatus;
};


export type QueryCounselableAtArgs = {
  datetime: Scalars['LegacyDateTime'];
};


export type QueryCouponArgs = {
  couponId: Scalars['ID'];
};


export type QueryEstimateArgs = {
  estimateId: Scalars['ID'];
};


export type QueryEstimateCountArgs = {
  filter?: Maybe<EstimateFilter>;
};


export type QueryEstimateInquiriesArgs = {
  filter?: Maybe<EstimateInquiryFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: EstimateInquirySortOrderEnum;
};


export type QueryEstimateInquiryArgs = {
  inquiryId: Scalars['ID'];
};


export type QueryEstimateInquiryCountArgs = {
  filter: EstimateInquiryFilter;
};


export type QueryEstimateRequestArgs = {
  requestId: Scalars['ID'];
};


export type QueryEstimateRequestCountArgs = {
  filter?: Maybe<EstimateRequestFilter>;
};


export type QueryEstimateRequestsArgs = {
  filter?: Maybe<EstimateRequestFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: EstimateRequestSortOrderEnum;
};


export type QueryEstimatesArgs = {
  filter?: Maybe<EstimateFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: EstimateSortOrderEnum;
};


export type QueryEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryEventConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: EventConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryExtractHashtagsArgs = {
  text: Scalars['String'];
};


export type QueryFinishedCounselConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: FinishedCounselConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryHolidayArgs = {
  date: Scalars['Date'];
};


export type QueryHolidaysArgs = {
  range: DateRangeInput;
};


export type QueryIsHolidayArgs = {
  date: Scalars['Date'];
};


export type QueryItemArgs = {
  itemId: Scalars['ID'];
};


export type QueryLocalAutomobileTaxDepartmentsArgs = {
  filter?: Maybe<LocalAutomobileTaxDepartmentFilter>;
};


export type QueryNoticeArgs = {
  noticeId: Scalars['ID'];
};


export type QueryNoticeConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryPendingCounselConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PendingCounselConnectionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPlacesArgs = {
  first: Scalars['Int'];
  term: Scalars['String'];
};


export type QueryPostArgs = {
  postId: Scalars['ID'];
};


export type QueryPostIssueArgs = {
  postIssueId: Scalars['ID'];
};


export type QueryPostIssueCountArgs = {
  filter: PostIssuesFilter;
};


export type QueryPostIssuesArgs = {
  filter: PostIssuesFilter;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: PostIssuesOrder;
};


export type QueryProductArgs = {
  productId: Scalars['ID'];
};


export type QueryProductsArgs = {
  filter?: Maybe<ProductsFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryRecommendedBlogPostConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RecommendedBlogPostConnectionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySearchableCompanyArgs = {
  searchableCompanyId: Scalars['ID'];
};


export type QuerySearchableCompanyConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SearchableCompanyConnectionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchableCompanyEquipmentsArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchableCompanyReviewArgs = {
  searchableCompanyReviewId: Scalars['ID'];
};


export type QuerySearchableCompanyReviewCountArgs = {
  filter: SearchableCompanyReviewFilter;
};


export type QuerySearchableCompanyReviewsArgs = {
  filter?: Maybe<SearchableCompanyReviewFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: Maybe<SearchableCompanyReviewSortOrderEnum>;
};


export type QuerySearchedCounselsArgs = {
  status: CounselStatus;
};


export type QueryShippingArgs = {
  shippingId: Scalars['ID'];
};


export type QueryShippingConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: ShippingConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShippingCountArgs = {
  filter: ShippingConnectionFilter;
};


export type QuerySuggestedHashtagsArgs = {
  term: Scalars['String'];
};


export type QuerySuggestedTermsArgs = {
  term: Scalars['String'];
};


export type QueryTemplateArgs = {
  templateId: Scalars['ID'];
};


export type QueryTemplateDirectoryArgs = {
  directoryId: Scalars['ID'];
};


export type QueryUnpaidAutomobileTaxesArgs = {
  range: DateRangeInput;
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryUserContentFileUploadArgs = {
  file: FileUploadInput;
};


export type QueryVehicleArgs = {
  vehicleId: Scalars['ID'];
};


export type QueryVehicleBrandArgs = {
  brandId: Scalars['ID'];
};


export type QueryVehicleBrandsArgs = {
  filter?: Maybe<VehicleBrandsFilter>;
};


export type QueryVehicleGradeArgs = {
  gradeId: Scalars['ID'];
};


export type QueryVehicleLedgerArgs = {
  ownerName: Scalars['String'];
  plateNumber: Scalars['String'];
};


export type QueryVehicleModelArgs = {
  modelId: Scalars['ID'];
};


export type QueryVehicleSubmodelArgs = {
  submodelId: Scalars['ID'];
};


export type QueryVehicleTrimArgs = {
  trimId: Scalars['ID'];
};


export type QueryWorkArgs = {
  workId: Scalars['ID'];
};


export type QueryWorksArgs = {
  filter: WorksFilter;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: WorkSortOrderEnum;
};


export type QueryZoneArgs = {
  coordinates: CoordinatesInput;
};

/** 상담 대기 성공 response */
export type QueueCounselSuccess = {
  __typename?: 'QueueCounselSuccess';
  /** 대기 상태로 전환된 퀵상담 */
  counsel: Counsel;
};

/** 퀵상담 대기 상태 전환 메세지 */
export type QueueMessage = Message & {
  __typename?: 'QueueMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  reason: QueueReason;
  status: MessageStatus;
};

/** 대기 사유 */
export enum QueueReason {
  /** 캐시백 안내 */
  CashbackCheck = 'CASHBACK_CHECK',
  /** 견적 조회 */
  PriceCheck = 'PRICE_CHECK',
  /** 배송 상황 조사 */
  ShippingCheck = 'SHIPPING_CHECK',
  /** 추천 업체 조사 */
  ShopCheck = 'SHOP_CHECK'
}

/** 댓글 공감 response */
export type ReactToCommentResponse = ReactToCommentSuccess;

/** 댓글 공감 성공 response */
export type ReactToCommentSuccess = {
  __typename?: 'ReactToCommentSuccess';
  /** 공감한 댓글 */
  comment: CommentBase;
};

/** 게시물 공감 response */
export type ReactToPostResponse = ReactToPostSuccess;

/** 게시물 공감 성공 response */
export type ReactToPostSuccess = {
  __typename?: 'ReactToPostSuccess';
  /** 공감한 게시물 */
  post: Post;
};

export type ReactionCountFilter = {
  /** 공감 유형 */
  type?: Maybe<ReactionType>;
};

/** 공감 유형 */
export enum ReactionType {
  /** 무서워요 */
  Afraid = 'AFRAID',
  /** 힘내요 */
  CheerUp = 'CHEER_UP',
  /** 좋아요 */
  Like = 'LIKE',
  /** 슬퍼요 */
  Sad = 'SAD'
}

export type RecommendedBlogPostConnectionFilter = {
  /** 블로그 카테고리 */
  category?: Maybe<BlogPostCategory>;
};

/** 쿠폰 에러 */
export type RedeemCouponError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 쿠폰 사용 실패 response */
export type RedeemCouponFail = {
  __typename?: 'RedeemCouponFail';
  /** 쿠폰 사용 에러 */
  error: RedeemCouponError;
};

/** 쿠폰 수정 response */
export type RedeemCouponResponse = RedeemCouponFail | RedeemCouponSuccess;

/** 쿠폰 사용 성공 response */
export type RedeemCouponSuccess = {
  __typename?: 'RedeemCouponSuccess';
  /** 수정된 쿠폰 */
  coupon: CouponBase;
};

/** 주문 거부 데이터 */
export type RejectOrderData = {
  /**
   * 주문 거부 사유
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자
   */
  reason: Scalars['String'];
};

/** 주문 거부 response */
export type RejectOrderResponse = RejectOrderSuccess;

/** 주문 거부 성공 response */
export type RejectOrderSuccess = {
  __typename?: 'RejectOrderSuccess';
  /** 거부된 주문 */
  order: OrderBase;
};

export type RemoveMemberFromCompanyError = {
  message: Scalars['String'];
};

/** 업체 구성원 제거 실패. */
export type RemoveMemberFromCompanyFail = {
  __typename?: 'RemoveMemberFromCompanyFail';
  error: RemoveMemberFromCompanyError;
};

export type RemoveMemberFromCompanyResponse = RemoveMemberFromCompanyFail | RemoveMemberFromCompanySuccess;

/** 업체 구성원 제거 성공. */
export type RemoveMemberFromCompanySuccess = {
  __typename?: 'RemoveMemberFromCompanySuccess';
  /** 제거된 구성원. */
  member: CompanyMember;
};

/** 퀵상담 사용자 제거 성공 response */
export type RemoveUserFromCounselSuccess = {
  __typename?: 'RemoveUserFromCounselSuccess';
  /** 사용자가 제거된 퀵상담 */
  counsel: Counsel;
  /** 제거된 사용자 */
  user: User;
};

/** 작업의 수리 삭제 데이터. */
export type RemoveWorkRepairData = {
  /** 삭제할 수리 ID. */
  repairId: Scalars['ID'];
  /** 수리를 삭제할 작업 ID. */
  workId: Scalars['ID'];
};

export type RemoveWorkRepairError = {
  message: Scalars['String'];
};

/** 작업의 수리 삭제 실패. */
export type RemoveWorkRepairFail = {
  __typename?: 'RemoveWorkRepairFail';
  error: RemoveWorkRepairError;
};

export type RemoveWorkRepairResponse = RemoveWorkRepairFail | RemoveWorkRepairSuccess;

/** 작업의 수리 삭제 성공. */
export type RemoveWorkRepairSuccess = {
  __typename?: 'RemoveWorkRepairSuccess';
  /** 작업. */
  work: Work;
};

/** 수리. */
export type Repair = {
  __typename?: 'Repair';
  /** 추가 시점 */
  createdAt: Scalars['DateTime'];
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 첨부 파일 목록. */
  files: Array<File>;
  /** ID */
  id: Scalars['ID'];
  /** 수리 유형 */
  type: RepairTypeEnum;
};

/** 수리내역서. */
export type RepairReport = {
  __typename?: 'RepairReport';
  /** 첨부 이미지 목록. */
  imageUrls?: Maybe<Array<Image>>;
  /** 공임비용 */
  laborCost?: Maybe<Scalars['Int']>;
  /** 부품비용 */
  partsCost?: Maybe<Scalars['Int']>;
  /** 총 수리비용 */
  totalRepairCost?: Maybe<Scalars['Int']>;
};

/** 수리 유형. */
export enum RepairTypeEnum {
  /** 덴트. */
  Dent = 'DENT',
  /** 점검. */
  Inspection = 'INSPECTION',
  /** 도장 */
  Painting = 'PAINTING',
  /** 재생 */
  Regenerate = 'REGENERATE',
  /** 부품 교체 */
  Replacement = 'REPLACEMENT',
  /** 판금 */
  Sheet = 'SHEET',
  /** 세차/광택 */
  WashingPolishing = 'WASHING_POLISHING'
}

/** 신고 내역. */
export type Report = {
  __typename?: 'Report';
  /** 신고 사유. */
  reason: Scalars['String'];
  /** 신고 시점. */
  reportedAt: Scalars['DateTime'];
  /** 신고자 ID. */
  reporterId: Scalars['ID'];
};

export type ReportCommunityCommentData = {
  /**
   * 신고 사유.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  reason: Scalars['String'];
};

export type ReportCommunityCommentError = {
  message: Scalars['String'];
};

export type ReportCommunityCommentFail = {
  __typename?: 'ReportCommunityCommentFail';
  error: ReportCommunityCommentError;
};

export type ReportCommunityCommentResponse = ReportCommunityCommentFail | ReportCommunityCommentSuccess;

export type ReportCommunityCommentSuccess = {
  __typename?: 'ReportCommunityCommentSuccess';
  /** 접수된 커뮤니티 댓글 신고. */
  report: CommunityCommentReport;
};

export type ReportCommunityPostData = {
  /**
   * 신고 사유.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  reason: Scalars['String'];
};

export type ReportCommunityPostError = {
  message: Scalars['String'];
};

export type ReportCommunityPostFail = {
  __typename?: 'ReportCommunityPostFail';
  error: ReportCommunityPostError;
};

export type ReportCommunityPostResponse = ReportCommunityPostFail | ReportCommunityPostSuccess;

export type ReportCommunityPostSuccess = {
  __typename?: 'ReportCommunityPostSuccess';
  /** 접수된 커뮤니티 게시물 신고. */
  report: CommunityPostReport;
};

export type ReportCommunityReplyData = {
  /**
   * 신고 사유.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  reason: Scalars['String'];
};

export type ReportCommunityReplyError = {
  message: Scalars['String'];
};

export type ReportCommunityReplyFail = {
  __typename?: 'ReportCommunityReplyFail';
  error: ReportCommunityReplyError;
};

export type ReportCommunityReplyResponse = ReportCommunityReplyFail | ReportCommunityReplySuccess;

export type ReportCommunityReplySuccess = {
  __typename?: 'ReportCommunityReplySuccess';
  /** 접수된 커뮤니티 답글 신고. */
  report: CommunityReplyReport;
};

export type ReportCommunityUserData = {
  /**
   * 신고 사유.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 500자.
   */
  reason: Scalars['String'];
};

export type ReportCommunityUserError = {
  message: Scalars['String'];
};

export type ReportCommunityUserFail = {
  __typename?: 'ReportCommunityUserFail';
  error: ReportCommunityUserError;
};

export type ReportCommunityUserResponse = ReportCommunityUserFail | ReportCommunityUserSuccess;

export type ReportCommunityUserSuccess = {
  __typename?: 'ReportCommunityUserSuccess';
  /** 접수된 커뮤니티 사용자 신고. */
  report: CommunityUserReport;
};

export type ReportSearchableCompanyReviewData = {
  /**
   * 내용.
   *
   * ### Constraints
   *
   * 1. 최소 10자, 최대 200자.
   */
  reportReason: Scalars['String'];
  /** 리뷰가 작성된 검색 가능 업체 리뷰 ID. */
  searchableCompanyReviewId: Scalars['ID'];
};

export type ReportSearchableCompanyReviewError = {
  message: Scalars['String'];
};

/** 검색 가능 업체 리뷰 신고하는데 실패했을 때 반환되는 응답. */
export type ReportSearchableCompanyReviewFail = {
  __typename?: 'ReportSearchableCompanyReviewFail';
  error: ReportSearchableCompanyReviewError;
};

export type ReportSearchableCompanyReviewResponse = ReportSearchableCompanyReviewFail | ReportSearchableCompanyReviewSuccess;

/** 검색 가능 업체 리뷰 신고하는데 성공했을 때 반환되는 응답. */
export type ReportSearchableCompanyReviewSuccess = {
  __typename?: 'ReportSearchableCompanyReviewSuccess';
  /** 신고된 검색 가능 업체 리뷰. */
  searchableCompanyReview: SearchableCompanyReview;
};

export type RequestAppointmentClientData = {
  /**
   * 이름
   *
   * ### Constraints
   *
   * 1. 최소 1글자, 최대 100글자
   */
  name?: Maybe<Scalars['String']>;
  /**
   * 휴대전화번호
   *
   * ### Constraints
   *
   * 1. 휴대전화번호형식, - (하이픈) 제외
   */
  phone: Scalars['String'];
};

export type RequestAppointmentData = {
  /** 고객 정보. */
  client: RequestAppointmentClientData;
  /**
   * 로그인한 계정 외의 고객 계정 ID.
   *
   * ### Constraints
   *
   * 1. 어드민, 상담사 계정만 설정 가능.
   */
  clientAccountId?: Maybe<Scalars['ID']>;
  /** 업체 ID */
  companyId: Scalars['ID'];
  /** 상세 정보. */
  detail: RequestAppointmentDetailData;
  /** 견적 요청으로부터 예약이 요청되는 경우 견적 문의 ID. */
  estimateInquiryId?: Maybe<Scalars['ID']>;
  /** 메모. */
  memo?: Maybe<Scalars['String']>;
  /** 차량 정보. */
  vehicle: RequestAppointmentVehicleData;
  /** 차량의 업체 도착 예상 시각. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

export type RequestAppointmentDetailData = {
  /**
   * 내용
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  body?: Maybe<Scalars['String']>;
  /** 첨부 사진 및 동영상 목록. */
  files?: Maybe<Array<FileInput>>;
  /** 보험처리 방식. */
  insurance?: Maybe<InsuranceEnum>;
  /**
   * 제목
   *
   * ### Constraints
   *
   * 1. 최소 5자, 최대 256자
   */
  title?: Maybe<Scalars['String']>;
};

/** 예약 요청 에러 */
export type RequestAppointmentError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 예약 요청 실패 응답 */
export type RequestAppointmentFail = {
  __typename?: 'RequestAppointmentFail';
  error: RequestAppointmentError;
};

/** 예약 요청 응답 */
export type RequestAppointmentResponse = RequestAppointmentFail | RequestAppointmentSuccess;

/** 예약 요청 성공 응답 */
export type RequestAppointmentSuccess = {
  __typename?: 'RequestAppointmentSuccess';
  /** 요청된 예약 */
  appointment: Appointment;
};

export type RequestAppointmentVehicleData = {
  /** 제조사 ID. */
  brandId?: Maybe<Scalars['ID']>;
  /** 연료 유형 */
  fuel?: Maybe<FuelTypeInput>;
  /**
   * 모델명
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   */
  modelName?: Maybe<Scalars['String']>;
  /**
   * 차량번호
   *
   * ### Constraints
   *
   * 1. 차량번호 형식
   */
  plateNumber?: Maybe<Scalars['String']>;
  /**
   * 연식
   *
   * ### Constraints
   *
   * 1. 연도 형식(숫자 4자리)
   */
  registeredYear?: Maybe<Scalars['Int']>;
};

/** 견적 요청 정보. */
export type RequestEstimateData = {
  /** 견적을 요청할 문의 ID. */
  inquiryId: Scalars['ID'];
  /** 견적 요청 정보 목록. */
  requests: Array<RequestEstimateRequestData>;
};

export type RequestEstimateError = {
  message: Scalars['String'];
};

/** 견적 요청 실패. */
export type RequestEstimateFail = {
  __typename?: 'RequestEstimateFail';
  error: RequestEstimateError;
};

export type RequestEstimateRequestData = {
  /**
   * 견적을 요청할 업체 ID.
   *
   * ### Constraints
   *
   * 1. 파트너 업체에만 견적 요청 가능.
   */
  companyId: Scalars['ID'];
  /**
   * 업체가 제공하는 상품을 통해 견적을 요청하는 경우, 상품 ID.
   *
   * ### Constraints
   *
   * 1. 업체가 제공하는 상품의 ID만 가능.
   */
  productId?: Maybe<Scalars['ID']>;
};

export type RequestEstimateResponse = RequestEstimateFail | RequestEstimateSuccess;

/** 견적 요청 성공. */
export type RequestEstimateSuccess = {
  __typename?: 'RequestEstimateSuccess';
  /** 견적이 요청된 견적 문의. */
  inquiry: EstimateInquiry;
  /** 생성된 견적 요청 목록. */
  requests: Array<EstimateRequest>;
};

export type RequestIdentityVerificationForWetaxError = {
  message: Scalars['String'];
};

/** 위택스 본인 인증 요청 실패. */
export type RequestIdentityVerificationForWetaxFail = {
  __typename?: 'RequestIdentityVerificationForWetaxFail';
  error: RequestIdentityVerificationForWetaxError;
};

export type RequestIdentityVerificationForWetaxResponse = RequestIdentityVerificationForWetaxFail | RequestIdentityVerificationForWetaxSuccess;

/** 위택스 본인 인증 요청 성공. */
export type RequestIdentityVerificationForWetaxSuccess = {
  __typename?: 'RequestIdentityVerificationForWetaxSuccess';
  /** 인증 만료 예정 시각. */
  expiresAt: Scalars['DateTime'];
};

/** 요청 상태의 예약. */
export type RequestedAppointment = Appointment & {
  __typename?: 'RequestedAppointment';
  /** 고객. */
  client: AppointmentClient;
  /** 예약번호(유일하지 않음). */
  code: Scalars['String'];
  /** 업체. */
  company: AppointmentCompany;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 상세. */
  detail: AppointmentDetail;
  /** 담당자 */
  dispatcher?: Maybe<BizAccount>;
  estimateRequest?: Maybe<EstimateRequest>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사 및 업체 구성원 계정만 접근 가능.
   */
  memo?: Maybe<Scalars['String']>;
  /** 수정 시점. */
  updatedAt: Scalars['DateTime'];
  /** 차량. */
  vehicle: AppointmentVehicle;
  /** 차량 도착 예정 시점. */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

export type ResetPasswordError = {
  message: Scalars['String'];
};

/** 비밀번호 재설정 실패. */
export type ResetPasswordFail = {
  __typename?: 'ResetPasswordFail';
  error: ResetPasswordError;
};

export type ResetPasswordResponse = ResetPasswordFail | ResetPasswordSuccess;

/** 비밀번호 재설정 성공. */
export type ResetPasswordSuccess = {
  __typename?: 'ResetPasswordSuccess';
  /** 비밀번호가 재설정된 계정의 이메일 주소. */
  email: Scalars['String'];
};

export type ResponseEstimateRequestError = {
  message: Scalars['String'];
};

/** 견적 요청 응답 실패. */
export type ResponseEstimateRequestFail = {
  __typename?: 'ResponseEstimateRequestFail';
  error: ResponseEstimateRequestError;
};

export type ResponseEstimateRequestResponse = ResponseEstimateRequestFail | ResponseEstimateRequestSuccess;

/** 견적 요청 응답 성공. */
export type ResponseEstimateRequestSuccess = {
  __typename?: 'ResponseEstimateRequestSuccess';
  /** 응답된 견적 요청. */
  request: EstimateRequest;
};

/** 상담 재시작 성공 response */
export type RestartCounselSuccess = {
  __typename?: 'RestartCounselSuccess';
  /** 재시작된 상담 */
  counsel: Counsel;
};

/** 자동 종료 상담 재시작 메세지 */
export type RestartMessage = Message & {
  __typename?: 'RestartMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  /** 대화가 없었던 시간 (min) */
  idle: Scalars['Int'];
  status: MessageStatus;
};

/** 계정의 역할. */
export enum RoleEnum {
  /** 관리자. */
  Admin = 'ADMIN',
  /** 비즈 사용자. */
  Biz = 'BIZ',
  /** 상담사. */
  Counselor = 'COUNSELOR',
  /** 사용자. */
  User = 'USER'
}

/** 판매 기간. */
export type SalesRange = {
  __typename?: 'SalesRange';
  /** 출시연월. */
  from: YearMonth;
  /** 단종연월. */
  to?: Maybe<YearMonth>;
};

export type SearchVehicleLedgerResponse = SearchedVehicleLedger | VehicleLedgerServerDown;

/** 검색 가능 업체. */
export type SearchableCompany = {
  __typename?: 'SearchableCompany';
  /** 주소. */
  address: Address;
  /** 제조사 직영점인 경우, 제조사. */
  brand?: Maybe<VehicleBrand>;
  /** 영업시간. */
  businessHours?: Maybe<SearchableCompanyBusinessHours>;
  /** 연락처. */
  contact: Scalars['String'];
  /** 개업일자. */
  dateOfEstablishment?: Maybe<Scalars['Date']>;
  /** 설명. */
  description?: Maybe<Scalars['String']>;
  /** 보유 장비 목록. */
  equipments: Array<SearchableCompanyEquipment>;
  /** 홈페이지 URL. */
  homepage?: Maybe<Scalars['String']>;
  /** ID. */
  id: Scalars['String'];
  /** 이미지 목록. */
  images: Array<Image>;
  /** 키워드 목록. */
  keywords: Array<Scalars['String']>;
  /** 검색 가능 업체에 리뷰 키워드를 남겼는지 여부. */
  leavedKeyword: Scalars['Boolean'];
  /** 검색 가능 업체에 리뷰를 남겼는지 여부. */
  leavedReview: Scalars['Boolean'];
  /** 등급. */
  level?: Maybe<AutomobileRepairBusinessLevel>;
  /** 내가 작성한 검색 가능 업체 리뷰. */
  mySearchableCompanyReview?: Maybe<SearchableCompanyReview>;
  /** 이름. */
  name: Scalars['String'];
  /** 추천 사유. */
  recommendation?: Maybe<Scalars['String']>;
  /** 추천 횟수. */
  recommendationCount: Scalars['Int'];
  /** 검색 가능 업체에 작성된 리뷰 connection. */
  searchableCompanyReviewConnection: SearchableCompanyReviewConnection;
  /** 검색 가능 업체에 작성된 리뷰 키워드들 개수. */
  searchableCompanyReviewKeywordsCount?: Maybe<Array<SearchableCompanyReviewKeywordCount>>;
  /** 유형. */
  type?: Maybe<SearchableCompanyType>;
};


/** 검색 가능 업체. */
export type SearchableCompanySearchableCompanyReviewConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SearchableCompanyBusinessHours = {
  __typename?: 'SearchableCompanyBusinessHours';
  /** 금요일 영업시간. */
  fri?: Maybe<TimeRange>;
  /** 공휴일 영업시간. */
  holiday?: Maybe<TimeRange>;
  /** 월요일 영업시간. */
  mon?: Maybe<TimeRange>;
  /** 주의사항. */
  remark?: Maybe<Scalars['String']>;
  /** 토요일 영업시간. */
  sat?: Maybe<TimeRange>;
  /** 일요일 영업시간. */
  sun?: Maybe<TimeRange>;
  /** 화요일 영업시간. */
  tue?: Maybe<TimeRange>;
  /** 목요일 영업시간. */
  tur?: Maybe<TimeRange>;
  /** 수요일 영업시간. */
  wed?: Maybe<TimeRange>;
};

export type SearchableCompanyConnection = {
  __typename?: 'SearchableCompanyConnection';
  edges: Array<SearchableCompanyConnectionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type SearchableCompanyConnectionEdge = {
  __typename?: 'SearchableCompanyConnectionEdge';
  cursor: Scalars['String'];
  node: SearchableCompany;
};

export type SearchableCompanyConnectionFilter = {
  /** 영역. */
  area?: Maybe<AreaInput>;
  /** 검색 프리셋 목록. */
  presets?: Maybe<Array<CompanySearchPreset>>;
  /** 유형. */
  type?: Maybe<SearchableCompanyType>;
};

/** 검색 가능 업체 장비. */
export type SearchableCompanyEquipment = {
  __typename?: 'SearchableCompanyEquipment';
  /** 설명. */
  description: Scalars['String'];
  /** ID. */
  id: Scalars['ID'];
  /** 이름. */
  name: Scalars['String'];
};

/** 검색 가능 업체 리뷰. */
export type SearchableCompanyReview = {
  __typename?: 'SearchableCompanyReview';
  /** 작성자 계정. */
  author?: Maybe<Account>;
  /** 자동 생성 여부. */
  autogenerated?: Maybe<Scalars['Boolean']>;
  /** 내용. */
  content?: Maybe<Scalars['String']>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** 숨김처리여부. */
  hide: Scalars['Boolean'];
  /** ID. */
  id: Scalars['ID'];
  /** 이미지 목록. */
  imageUrls?: Maybe<Array<Image>>;
  /** 신고 목록. */
  reports?: Maybe<Array<Report>>;
  /** 검색 가능 업체. */
  searchableCompany?: Maybe<SearchableCompany>;
  /** 리뷰 유형. */
  type: SearchableCompanyReviewTypeEnum;
};

export type SearchableCompanyReviewConnection = {
  __typename?: 'SearchableCompanyReviewConnection';
  edges: Array<SearchableCompanyReviewConnectionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type SearchableCompanyReviewConnectionEdge = {
  __typename?: 'SearchableCompanyReviewConnectionEdge';
  cursor: Scalars['String'];
  node: SearchableCompanyReview;
};

export type SearchableCompanyReviewFilter = {
  /** 자동 생성 여부. */
  autogenerated?: Maybe<Scalars['Boolean']>;
  /** 생성시점 범위. */
  createdAt?: Maybe<OpenedDateTimeRangeInput>;
  /** 숨김처리여부. */
  hasHidden?: Maybe<Scalars['Boolean']>;
  /** 작성된 검색 가능 업체 ID. */
  searchableCompanyId?: Maybe<Scalars['ID']>;
  /** 작성한 사용자 ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** 검색 가능 업체 리뷰 키워드. */
export type SearchableCompanyReviewKeyword = {
  __typename?: 'SearchableCompanyReviewKeyword';
  /** 키워드 이름. */
  name: Scalars['String'];
};

/** 검색 가능 업체 리뷰 키워드 개수. */
export type SearchableCompanyReviewKeywordCount = {
  __typename?: 'SearchableCompanyReviewKeywordCount';
  /** 키워드 개수. */
  count: Scalars['Float'];
  /** 키워드. */
  keyword: Scalars['String'];
};

/** 리뷰 목록 정렬 조건. */
export enum SearchableCompanyReviewSortOrderEnum {
  /** 생성 시점 오름차순 */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** 생성 시점 내림차순 */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** 리뷰 유형. */
export enum SearchableCompanyReviewTypeEnum {
  /** 별로에요. */
  Dislike = 'DISLIKE',
  /** 좋아요. */
  Like = 'LIKE'
}

/** 검색 가능 업체 유형. */
export enum SearchableCompanyType {
  /** 카센터 */
  CarCenter = 'CAR_CENTER',
  /** 기타 */
  Etc = 'ETC',
  /** 제조사 직영점 */
  ManufacturerOwnedStore = 'MANUFACTURER_OWNED_STORE',
  /** 정비공장 */
  RepairFactory = 'REPAIR_FACTORY'
}

/** 차량 원부 조회 결과. */
export type SearchedVehicleLedger = {
  __typename?: 'SearchedVehicleLedger';
  /** 제조사. */
  brand: VehicleBrand;
  /** 등급. */
  grade: VehicleGrade;
  /** 모델. */
  model: VehicleModel;
  /** 소유주명. */
  ownerName: Scalars['String'];
  /** 차량번호. */
  plateNumber: Scalars['String'];
  /** 등록연도(연식). */
  registeredYear: Scalars['Int'];
  /** 세부 모델. */
  submodel: VehicleSubmodel;
  /** 트림. */
  trim?: Maybe<VehicleTrim>;
  /** 차대번호. */
  vin: Scalars['String'];
};

/** 게시물의 가장 도움된 답변 작성자 선택 response */
export type SelectBestCommenterResponse = SelectBestCommenterSuccess;

/** 게시물의 가장 도움된 답변 작성자 선택 성공 response */
export type SelectBestCommenterSuccess = {
  __typename?: 'SelectBestCommenterSuccess';
  /** 공감한 게시물 */
  post: Post;
};

/** 스토어 리뷰 요청 메세지 전송 성공 response */
export type SendAppReviewMessageSuccess = {
  __typename?: 'SendAppReviewMessageSuccess';
  /** 전송된 스토어 리뷰 요청 메세지 */
  message: AppReviewMessage;
};

/** 정비 희망 지역 입력 메세지 전송 성공 response */
export type SendAreaInputMessageSuccess = {
  __typename?: 'SendAreaInputMessageSuccess';
  /** 생성된 정비 희망 지역 입력 메세지 */
  message: AreaInputMessage;
};

/** 업체 메세지 전송 성공 response */
export type SendCompanyMessageSuccess = {
  __typename?: 'SendCompanyMessageSuccess';
  /** 전송된 업체 메세지 */
  message: CompanyMessage;
};

/** 견적 메세지 전송 성공 response */
export type SendEstimateMessageSuccess = {
  __typename?: 'SendEstimateMessageSuccess';
  /** 전송된 견적 메세지 */
  message: EstimateMessage;
};

/** 파일 메세지 전송 성공 response */
export type SendFileMessageSuccess = {
  __typename?: 'SendFileMessageSuccess';
  /** 전송된 파일 메세지 */
  message: FileMessage;
};

/** 견적 메세지 전송 성공 response */
export type SendInvoiceMessageSuccess = {
  __typename?: 'SendInvoiceMessageSuccess';
  /** 전송된 결제 요청 메세지 */
  message: InvoiceMessage;
};

/** 차량 번호 입력 메세지 전송 성공 response */
export type SendPlateNumberInputMessageSuccess = {
  __typename?: 'SendPlateNumberInputMessageSuccess';
  /** 생성된 차량 번호 입력 메세지 */
  message: PlateNumberInputMessage;
};

/** 텍스트 메세지 전송 성공 response */
export type SendTextMessageSuccess = {
  __typename?: 'SendTextMessageSuccess';
  /** 전송된 텍스트 메세지 */
  message: TextMessage;
};

/** 차대번호 입력 메세지 전송 성공 response */
export type SendVinInputMessageSuccess = {
  __typename?: 'SendVinInputMessageSuccess';
  /** 생성된 차대번호 입력 메세지 */
  message: VinInputMessage;
};

/** 작업의 예상 출고일 수정 데이터. */
export type ShareWorkData = {
  /** 예상 출고일을 수정할 작업 ID. */
  workId: Scalars['ID'];
};

export type ShareWorkError = {
  message: Scalars['String'];
};

/** 작업 예상 출고일 수정 실패. */
export type ShareWorkFail = {
  __typename?: 'ShareWorkFail';
  error: ShareWorkError;
};

export type ShareWorkResponse = ShareWorkFail | ShareWorkSuccess;

/** 작업 예상 출고일 수정 성공. */
export type ShareWorkSuccess = {
  __typename?: 'ShareWorkSuccess';
  /** 작업. */
  work: Work;
};

/** 배송 */
export type Shipping = {
  __typename?: 'Shipping';
  /** 택배사 */
  carrier?: Maybe<Carrier>;
  /** 수령인 연락처 */
  contact: Scalars['String'];
  /** 배송 완료 TIMESATMP */
  deliveredAt?: Maybe<Scalars['LegacyDateTime']>;
  /** 배송지 주소 */
  destination: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 배송 방식 */
  method?: Maybe<ShippingMethod>;
  order: OrderBase;
  progresses: ShippingProgress;
  /** 수령인 */
  recipient: Scalars['String'];
  /** 배송 요청 사항 */
  request?: Maybe<Scalars['String']>;
  status?: Maybe<ShippingStatus>;
  /** 운송장번호 */
  trackingId?: Maybe<Scalars['String']>;
};

export type ShippingConnection = {
  __typename?: 'ShippingConnection';
  edges: Array<ShippingEdge>;
  pageInfo: PageInfo;
};

/** 배송 connection filter */
export type ShippingConnectionFilter = {
  /** 배송 상태 */
  status: ShippingStatus;
};

export type ShippingEdge = {
  __typename?: 'ShippingEdge';
  cursor: Scalars['String'];
  node: Shipping;
};

/** 배송 방식 */
export enum ShippingMethod {
  /** 내부 직원이 직접 배송 */
  Autopedia = 'AUTOPEDIA',
  /** 택배사를 통한 배송 */
  Delivery = 'DELIVERY',
  /** 부품 도매점에서 택배사를 통해 배송 */
  Wholesale = 'WHOLESALE'
}

/** 배송 과정 */
export type ShippingProgress = {
  __typename?: 'ShippingProgress';
  contact?: Maybe<Scalars['String']>;
  /** 설명 */
  description: Scalars['String'];
  location: Scalars['String'];
  status: ShippingStatus;
};

/** 배송 상태 */
export enum ShippingStatus {
  /** 배송 완료 */
  Delivered = 'DELIVERED',
  /** 배송 중 */
  InTransit = 'IN_TRANSIT',
  /** 배송 준비 중 */
  Preparing = 'PREPARING'
}

/** 전문 분야 */
export type Specialty = {
  __typename?: 'Specialty';
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

/** 배송 시작 데이터 */
export type StartShippingData = {
  /**
   * 택배사
   *
   * ### Constraints
   *
   * 1. method가 DELIVERY일 경우 필수
   */
  carrier?: Maybe<Carrier>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 배송 방식 */
  method: ShippingMethod;
  /**
   * 운송장번호
   *
   * ### Constraints
   *
   * 1. method가 DELIVERY일 경우 필수
   */
  trackingId?: Maybe<Scalars['ID']>;
};

/** 배송 시작 성공 response */
export type StartShippingSuccess = {
  __typename?: 'StartShippingSuccess';
  /** 시작된 배송 */
  shipping: Shipping;
};

/** 선견적 중단 및 상담 시작 에러 */
export type StopEstimationAndStartCounselError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 선견적 중단 및 상담 시작 실패 response */
export type StopEstimationAndStartCounselFail = {
  __typename?: 'StopEstimationAndStartCounselFail';
  /** 선견적 중단 및 상담 시작 에러 */
  error: StopEstimationAndStartCounselError;
};

/** 선견적 중단 및 상담 시작 response */
export type StopEstimationAndStartCounselResponse = StopEstimationAndStartCounselFail | StopEstimationAndStartCounselSuccess;

/** 선견적 중단 및 상담 시작 성공 response */
export type StopEstimationAndStartCounselSuccess = {
  __typename?: 'StopEstimationAndStartCounselSuccess';
  /** 선견적 중단 및 시작된 상담 */
  counsel: Counsel;
};

/** 작업 중단 입력 데이터. */
export type StopWorkData = {
  /**
   * 중단 사유.
   *
   * ### Constraints
   *
   * 1. 최대 100자.
   */
  stoppedReason?: Maybe<Scalars['String']>;
  /** 완료 상태로 변경할 작업 ID. */
  workId: Scalars['ID'];
};

export type StopWorkError = {
  message: Scalars['String'];
};

/** 작업 중단 처리 실패. */
export type StopWorkFail = {
  __typename?: 'StopWorkFail';
  error: StopWorkError;
};

export type StopWorkResponse = StopWorkFail | StopWorkSuccess;

/** 작업 중단 처리 성공. */
export type StopWorkSuccess = {
  __typename?: 'StopWorkSuccess';
  /** 작업. */
  work: Work;
};

/** 커뮤니티 게시물에 대한 스토어 리뷰 요청 알림. */
export type StoreReviewForPostNotification = Notification & {
  __typename?: 'StoreReviewForPostNotification';
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 리뷰가 요청된 커뮤니티 게시물. */
  post?: Maybe<Post>;
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /**
   * 해당 상담에 초대된 사용자를 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  addedUserOnCounsel: User;
  /** 취소된 결제를 반환합니다. */
  cancelledPayment: Payment;
  /** 결제 완료 또는 실패한 결제를 반환합니다. */
  completedPayment: Payment;
  /** 퀵상담에서 삭제된 메세지를 반환합니다. */
  deletedMessageOnCounsel: DeletedMessage;
  /**
   * 요청자가 초대된 상담을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 사용자 접근 가능
   */
  invitedCounsel: Counsel;
  /**
   * 요청자가 퇴장한 상담을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사만 접근 가능
   */
  leavedCounsel: Counsel;
  /**
   * 새로운 상담을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  newCounsel: Counsel;
  /** 상담 가능한 모든 퀵상담에 전송된 새로운 메세지를 반환합니다. */
  newMessage: Message;
  /** 퀵상담에 전송된 새로운 메세지를 반환합니다. */
  newMessageOnCounsel: Message;
  /** 요청자에게 전송된 새로운 알림. */
  newNotification: Notification;
  /**
   * 해당 상담에서 퇴장한 사용자를 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  removedUserOnCounsel: User;
  /**
   * 상태가 변경된 상담을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 로그인한 사용자만 접근 가능
   */
  statusChangedCounsel: CounselStatusChange;
  /**
   * 수정된 상담을 반환합니다.
   *
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  updatedCounsel: Counsel;
  updatedCursorOnCounsel: Cursor;
  /** 해당 배송의 수정 사항을 반환합니다. */
  updatedShipping: Shipping;
};


export type SubscriptionAddedUserOnCounselArgs = {
  counselId: Scalars['ID'];
};


export type SubscriptionCancelledPaymentArgs = {
  paymentId: Scalars['ID'];
};


export type SubscriptionCompletedPaymentArgs = {
  paymentId: Scalars['ID'];
};


export type SubscriptionDeletedMessageOnCounselArgs = {
  counselId: Scalars['ID'];
};


export type SubscriptionNewMessageOnCounselArgs = {
  counselId: Scalars['ID'];
};


export type SubscriptionRemovedUserOnCounselArgs = {
  counselId: Scalars['ID'];
};


export type SubscriptionStatusChangedCounselArgs = {
  counselId?: Maybe<Scalars['ID']>;
};


export type SubscriptionUpdatedCounselArgs = {
  counselId: Scalars['ID'];
};


export type SubscriptionUpdatedCursorOnCounselArgs = {
  counselId: Scalars['ID'];
};


export type SubscriptionUpdatedShippingArgs = {
  shippingId: Scalars['ID'];
};

export type SuggestSearchableCompanyEditionError = {
  message: Scalars['String'];
};

/** 검색 가능 업체 정보 수정 요청 실패. */
export type SuggestSearchableCompanyEditionFail = {
  __typename?: 'SuggestSearchableCompanyEditionFail';
  error: SuggestSearchableCompanyEditionError;
};

export type SuggestSearchableCompanyEditionResponse = SuggestSearchableCompanyEditionFail | SuggestSearchableCompanyEditionSuccess;

/** 검색 가능 업체 정보 수정 요청 성공. */
export type SuggestSearchableCompanyEditionSuccess = {
  __typename?: 'SuggestSearchableCompanyEditionSuccess';
  /** 수정이 요청된 검색 가능 업체 */
  company: SearchableCompany;
};

/** 상담 자동 종료 경고 메세지 */
export type SuspendedMessage = Message & {
  __typename?: 'SuspendedMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  idle: Scalars['Int'];
  status: MessageStatus;
  willBeFinishedAt?: Maybe<Scalars['LegacyDateTime']>;
};

/** 자동차세 환급 가능 차량. */
export type TaxRefundableAutomobile = {
  __typename?: 'TaxRefundableAutomobile';
  /** 최대 환급액 (KRW). */
  maximumRefundableAmount: Scalars['Int'];
  /** 차량번호. */
  plateNumber: Scalars['String'];
};

export type TaxRefundableAutomobiles = {
  __typename?: 'TaxRefundableAutomobiles';
  list: Array<TaxRefundableAutomobile>;
};

export type TaxRefundableAutomobilesResponse = IdentityNotVerifiedForWetax | IdentityVerficationForWetaxNotRequested | TaxRefundableAutomobiles;

/** 템플릿 */
export type Template = {
  __typename?: 'Template';
  directory: TemplateDirectory;
  /** ID */
  id: Scalars['ID'];
  /** 메세지 목록 */
  messages: Array<TemplateMessage>;
  /** 제목 */
  name: Scalars['String'];
  path: Array<TemplateDirectory>;
};

/** 템플릿 디렉토리 */
export type TemplateDirectory = {
  __typename?: 'TemplateDirectory';
  /** ID */
  id: Scalars['ID'];
  /** 디렉토리명 */
  name: Scalars['String'];
  parent?: Maybe<TemplateDirectory>;
  path: Array<TemplateDirectory>;
  subdirectories: Array<TemplateDirectory>;
  templates: Array<Template>;
};

export type TemplateMessage = FileMessageContent | TextMessageContent;

export type TemplateMessageInput = {
  /**
   * 내용
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   *
   * 2. type: TEXT일 경우 필수
   */
  body?: Maybe<Scalars['String']>;
  /**
   * 파일
   *
   * ### Constraints
   *
   * 1. type: FILE일 경우 필수
   */
  file?: Maybe<FileInput>;
  /**
   * 메세지 종류
   *
   * ### Constraints
   *
   * 1. TEXT or FILE
   */
  type: MessageType;
};

/** 텍스트 메세지 */
export type TextMessage = Message & {
  __typename?: 'TextMessage';
  author?: Maybe<User>;
  body: Scalars['String'];
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
};

export type TextMessageContent = {
  __typename?: 'TextMessageContent';
  /** 내용 */
  body: Scalars['String'];
};

/** 시각 */
export type TimeInput = {
  /** 시 */
  hour: Scalars['Int'];
  /** 분 */
  minute: Scalars['Int'];
  /** 초 */
  second: Scalars['Int'];
};

/** 시각 범위 */
export type TimeRange = {
  __typename?: 'TimeRange';
  from: TimeResponseDto;
  to: TimeResponseDto;
};

/** 시각 범위 */
export type TimeRangeInput = {
  from: TimeInput;
  to: TimeInput;
};

/** 시각 */
export type TimeResponseDto = {
  __typename?: 'TimeResponseDto';
  /** 시 */
  hour: Scalars['Int'];
  /** 분 */
  minute: Scalars['Int'];
  /** 초 */
  second: Scalars['Int'];
};

/** 타이어 규격. */
export type TireSize = {
  __typename?: 'TireSize';
  /** 편평비 (%). */
  aspectRatio: Scalars['Int'];
  /** 휠 직경 (in). */
  wheelDiameter: Scalars['Int'];
  /** 폭 (mm). */
  width: Scalars['Int'];
};

/** 타이어 규격 입력값. */
export type TireSizeInput = {
  /**
   * 편평비 (%).
   *
   * ### Constraints
   *
   * 1. 0 또는 5로 끝나는 두자리의 숫자.
   */
  aspectRatio: Scalars['Int'];
  /**
   * 휠 직경 (in).
   *
   * ### Constraints
   *
   * 1. 두자리의 숫자.
   */
  wheelDiameter: Scalars['Int'];
  /**
   * 폭 (mm).
   *
   * ### Constraints
   *
   * 1. 5로 끝나는 세자리의 숫자.
   */
  width: Scalars['Int'];
};

/** 변속기 유형 */
export enum Transmission {
  /** 오토 */
  Auto = 'AUTO',
  /** CVT */
  Cvt = 'CVT',
  /** 수동 */
  Manual = 'MANUAL',
  /** 세미오토 */
  SemiAuto = 'SEMI_AUTO'
}

/** 예약 차량 방문 시점 두시간 전 알림. */
export type TwoHoursBeforeAppointmentNotification = Notification & {
  __typename?: 'TwoHoursBeforeAppointmentNotification';
  /** 두시간 뒤 차량 방문이 예정된 예약. */
  appointment?: Maybe<Appointment>;
  /** 생성 시점. */
  createdAt: Scalars['DateTime'];
  /** ID. */
  id: Scalars['ID'];
  /** 수신자의 조회 여부. */
  viewed: Scalars['Boolean'];
};

/** 사용자 차단 해제 성공 response */
export type UnblockUserSuccess = {
  __typename?: 'UnblockUserSuccess';
  /** 해제된 사용자 차단 */
  block: UserBlock;
};

/** 게시물 이슈 북마크 해제 response */
export type UnbookmarkPostIssueResponse = UnbookmarkPostIssueSuccess;

/** 게시물 이슈 북마크 해제 성공 response */
export type UnbookmarkPostIssueSuccess = {
  __typename?: 'UnbookmarkPostIssueSuccess';
  /** 북마크 해제된 게시물 이슈 */
  postIssue: PostIssue;
};

/** 게시물 북마크 취소 response */
export type UnbookmarkPostResponse = UnbookmarkPostSuccess;

/** 게시물 북마크 취소 성공 response */
export type UnbookmarkPostSuccess = {
  __typename?: 'UnbookmarkPostSuccess';
  /** 북마크 취소된 게시물 */
  post: Post;
};

export type UnpaidAutomobileTax = {
  __typename?: 'UnpaidAutomobileTax';
  /** 과세액(KRW). */
  amount?: Maybe<Scalars['Int']>;
  /** 과세년월. */
  chargedAt?: Maybe<YearMonth>;
  /** 과세 대상. */
  object?: Maybe<Scalars['String']>;
  /** 전자납부번호. */
  payId: Scalars['String'];
};

export type UnpaidAutomobileTaxes = {
  __typename?: 'UnpaidAutomobileTaxes';
  list: Array<UnpaidAutomobileTax>;
};

export type UnpaidAutomobileTaxesResponse = IdentityNotVerifiedForWetax | IdentityVerficationForWetaxNotRequested | UnpaidAutomobileTaxes;

/** 상담 대기 해제 성공 response */
export type UnqueueCounselSuccess = {
  __typename?: 'UnqueueCounselSuccess';
  /** 대기 해제된 퀵상담 */
  counsel: Counsel;
};

/** 댓글 공감 취소 response */
export type UnreactToCommentResponse = UnreactToCommentSuccess;

/** 댓글 공감 취소 성공 response */
export type UnreactToCommentSuccess = {
  __typename?: 'UnreactToCommentSuccess';
  /** 공감 취소된 댓글 */
  comment: CommentBase;
};

/** 게시물 공감 취소 response */
export type UnreactToPostResponse = UnreactToPostSuccess;

/** 게시물 공감 취소 성공 response */
export type UnreactToPostSuccess = {
  __typename?: 'UnreactToPostSuccess';
  /** 공감 취소된 게시물 */
  post: Post;
};

/** 고객 정보 */
export type UpdateAppointmentClientInput = {
  /** 고객 이름 */
  name?: Maybe<Scalars['String']>;
  /** 고객 휴대폰 번호 */
  phone?: Maybe<Scalars['String']>;
};

/** 예약 상세 */
export type UpdateAppointmentDetailInput = {
  /**
   * 예약 상세 내용
   *
   * ### Constraints
   *
   * 1. 10자 이상, 5000자 이하
   */
  body?: Maybe<Scalars['String']>;
  /** 예약 첨부 파일 목록 */
  files?: Maybe<Array<FileInput>>;
  /** 보험 처리 방식 */
  insurance?: Maybe<InsuranceEnum>;
  /**
   * 예약 상세 제목
   *
   * ### Constraints
   *
   * 1. 5자 이상, 256자 이하
   */
  title?: Maybe<Scalars['String']>;
};

/** 예약 수정 에러 */
export type UpdateAppointmentError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 예약 수정 실패 응답 */
export type UpdateAppointmentFail = {
  __typename?: 'UpdateAppointmentFail';
  error: UpdateAppointmentError;
};

export type UpdateAppointmentInput = {
  /** 예약 고객 */
  client?: Maybe<UpdateAppointmentClientInput>;
  /** 예약 상세 정보 */
  detail?: Maybe<UpdateAppointmentDetailInput>;
  /** 업체에서 예약에 작성할 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 예약 차량 */
  vehicle?: Maybe<UpdateAppointmentVehicleInput>;
  /**
   * 차량이 업체에 도착할 예상 시각
   *
   * ### Constraints
   *
   * 1. 현재 시각보다 이전일 수 없음.
   */
  willArriveAt?: Maybe<Scalars['DateTime']>;
};

/** 예약 수정 응답 */
export type UpdateAppointmentResponse = UpdateAppointmentFail | UpdateAppointmentSuccess;

/** 예약 수정 성공 응답 */
export type UpdateAppointmentSuccess = {
  __typename?: 'UpdateAppointmentSuccess';
  /** 수정된 예약 */
  appointment: Appointment;
};

/** 차량 정보 */
export type UpdateAppointmentVehicleInput = {
  /** 제조사 ID. */
  brandId?: Maybe<Scalars['ID']>;
  /** 연료 유형 */
  fuelType?: Maybe<Fuel>;
  /** 하이브리드 여부 */
  isHybrid?: Maybe<Scalars['Boolean']>;
  /**
   * 모델명
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 50자.
   */
  modelName?: Maybe<Scalars['String']>;
  /**
   * 차량번호
   *
   * ### Constraints
   *
   * 1. 공백제외, 차량번호 포맷
   */
  plateNumber?: Maybe<Scalars['String']>;
  /**
   * 연식
   *
   * ### Constraints
   *
   * 1. 숫자 4자리
   *
   * 2. 최소 1901, 최대 2155
   */
  registeredYear?: Maybe<Scalars['Int']>;
};

/** 댓글 수정 데이터 */
export type UpdateCommentData = {
  /**
   * 본문
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   *
   * 2. null 비허용
   */
  body: Scalars['String'];
  /**
   * 첨부 파일 목록
   *
   * ### Constraints
   *
   * 1. 최대 10개의 이미지 또는 비디오
   */
  files?: Maybe<Array<FileInput>>;
};

/** 댓글 수정 response */
export type UpdateCommentResponse = UpdateCommentSuccess;

/** 댓글 수정 성공 response */
export type UpdateCommentSuccess = {
  __typename?: 'UpdateCommentSuccess';
  /** 수정된 댓글 */
  comment: CommentBase;
};

/** 업체 수정 에러 */
export type UpdateCompanyError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 업체 수정 실패 응답 */
export type UpdateCompanyFail = {
  __typename?: 'UpdateCompanyFail';
  error: UpdateCompanyError;
};

export type UpdateCompanyInput = {
  /**
   * 도로명 주소.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  address?: Maybe<Scalars['String']>;
  /** 업체 소개 */
  bio?: Maybe<Scalars['String']>;
  /** 파트너 업체 여부 */
  certificated?: Maybe<Scalars['Boolean']>;
  /** 전화번호 */
  contact?: Maybe<Scalars['String']>;
  /**
   * 상세 주소.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  detailAddress?: Maybe<Scalars['String']>;
  /** 추가 전화번호 */
  extraContact?: Maybe<Scalars['String']>;
  /** 시설 ID 목록 */
  facilityIds?: Maybe<Array<Scalars['ID']>>;
  /** 취급 차량 국적 */
  handleNationality?: Maybe<HandleNationality>;
  /** 이미지 Url 목록 */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 파트너 업체 여부 */
  isPartner?: Maybe<Scalars['Boolean']>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /**
   * 업체명.
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 32자
   */
  name?: Maybe<Scalars['String']>;
  /** 업체 운영 시간 */
  operatingTime?: Maybe<OperatingTimeInput>;
  /** 추천 이유 ID 목록 */
  recommendationIds?: Maybe<Array<Scalars['ID']>>;
  /** 사업자 등록번호 */
  registrationNumber?: Maybe<Scalars['String']>;
  /** 대표자 명 */
  representativeName?: Maybe<Scalars['String']>;
  /** 업체전문분야 ID목록 */
  specialtyIds?: Maybe<Array<Scalars['ID']>>;
  /** 업체 유형 */
  type?: Maybe<CompanyType>;
};

/** 업체 수정 응답 */
export type UpdateCompanyResponse = UpdateCompanyFail | UpdateCompanySuccess;

/** 업체 수정 성공 응답 */
export type UpdateCompanySuccess = {
  __typename?: 'UpdateCompanySuccess';
  /** 수정된 업체 */
  company: Company;
};

/** 퀵상담 수정 데이터 */
export type UpdateCounselData = {
  /** 정비 희망 지역 목록 */
  areas?: Maybe<Array<AreaInput>>;
  /**
   * 내용
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 5000자
   */
  body?: Maybe<Scalars['String']>;
  /** 북마크 기한 */
  bookmarkedUntil?: Maybe<Scalars['LegacyDateTime']>;
  /** 첨부 파일 목록 */
  files?: Maybe<Array<FileInput>>;
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  /**
   * 메모
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  memo?: Maybe<Scalars['String']>;
  /**
   * 주행 거리 (km)
   *
   * ### Constraints
   *
   * 1. 최소 0km, 최대 999999km
   */
  mileage?: Maybe<Scalars['Int']>;
};

/** 상담 리뷰  수정 데이터 */
export type UpdateCounselReviewData = {
  /**
   * 추가 설명
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  description?: Maybe<Scalars['String']>;
  /** 만족도 */
  satisfaction?: Maybe<CounselSatisfaction>;
  /** 문제 해결 여부 */
  solved?: Maybe<Scalars['Boolean']>;
};

/** 상담 리뷰 수정 성공 response */
export type UpdateCounselReviewSuccess = {
  __typename?: 'UpdateCounselReviewSuccess';
  /** 수정된 상담 리뷰 */
  review: CounselReview;
};

/** 퀵상담 수정 성공 response */
export type UpdateCounselSuccess = {
  __typename?: 'UpdateCounselSuccess';
  /** 수정된 퀵상담 */
  counsel: Counsel;
};

/** 메세지 커서 수정 성공 response */
export type UpdateCursorSuccess = {
  __typename?: 'UpdateCursorSuccess';
  /** 수정된 메세지 커서 */
  cursor: Cursor;
};

/** 문의 수정 데이터 */
export type UpdateInquiryData = {
  /** 정비 희망 지역 목록 */
  areas?: Maybe<Array<AreaInput>>;
  /**
   * 내용
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 5000자
   */
  body: Scalars['String'];
  /** 첨부 파일 목록 */
  files?: Maybe<Array<FileInput>>;
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  /**
   * 주행 거리 (km)
   *
   * ### Constraints
   *
   * 1. 최소 0km, 최대 999999km
   */
  mileage?: Maybe<Scalars['Int']>;
};

/** 문의 수정 성공 response */
export type UpdateInquirySuccess = {
  __typename?: 'UpdateInquirySuccess';
  /** 수정된 문의 */
  inquiry: Inquiry;
};

/** 공지사항 수정 데이터 */
export type UpdateNoticeData = {
  /**
   * 본문.
   *
   * ### Constraints
   *
   * 1. 최소 10자, 최대 5000자.
   */
  body?: Maybe<Scalars['String']>;
  /**
   * 첨부 파일 목록.
   *
   * ### Constraints
   *
   * 1. 최대 10개의 이미지 또는 비디오.
   */
  files?: Maybe<Array<FileInput>>;
  /**
   * 제목.
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 128자.
   */
  title?: Maybe<Scalars['String']>;
};

/** 공지사항 수정 에러 */
export type UpdateNoticeError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 공지사항 수정 실패 응답 */
export type UpdateNoticeFail = {
  __typename?: 'UpdateNoticeFail';
  error: UpdateNoticeError;
};

/** 공지사항 수정 응답 */
export type UpdateNoticeResponse = UpdateNoticeFail | UpdateNoticeSuccess;

/** 공지사항 수정 성공 응답 */
export type UpdateNoticeSuccess = {
  __typename?: 'UpdateNoticeSuccess';
  /** 수정된 공지사항 */
  notice: Notice;
};

/** 주문 수정 데이터 */
export type UpdateOrderData = {
  /**
   * 캐시백 적용율 (소수점 넷째자리까지 지원)
   *
   * ### Constraints
   *
   * 1. 최소 0.0000 (캐시백 없음), 최대 0.15 (벤츠)
   */
  cashbackRate?: Maybe<Scalars['Float']>;
  /**
   * 추가할인 (KRW)
   *
   * ### Constraints
   *
   * 1. 최소 0, 최대 1억
   */
  discountAmount?: Maybe<Scalars['Int']>;
  /** 보험처리 선택 */
  insurance?: Maybe<Scalars['Boolean']>;
  /**
   * 주문 상품 목록
   *
   * ### Constraints
   *
   * 1. 최소 1개, 최대 1000개
   */
  items?: Maybe<Array<OrderItemInput>>;
  /** 주문명 */
  name?: Maybe<Scalars['String']>;
  /**
   * 추천 이유
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1000자
   */
  recommendedReason?: Maybe<Scalars['String']>;
  /**
   * 운임비 (KRW)
   *
   * ### Constraints
   *
   * 1. 최소 0원, 최대 100만원
   */
  shippingCharge?: Maybe<Scalars['Int']>;
};

/** 주문 수정 response */
export type UpdateOrderSuccess = {
  __typename?: 'UpdateOrderSuccess';
  /** 수정된 주문 */
  order: OrderBase;
};

/** 게시물 수정 데이터 */
export type UpdatePostData = {
  /** 정비 희망 지역 목록 */
  areas?: Maybe<Array<AreaInput>>;
  /** 본문 */
  body?: Maybe<Scalars['String']>;
  /** 주제 ID */
  categoryId?: Maybe<Scalars['ID']>;
  /** 첨부 파일 목록 */
  files?: Maybe<Array<FileInput>>;
  /** 해시태그 목록 */
  hashtags?: Maybe<Array<PostHashtagInput>>;
  /** 보험 처리 방식 */
  insurance?: Maybe<Insurance>;
  /**
   * 주행 거리 (km)
   *
   * ### Constraints
   *
   * 1. 최소 0km, 최대 999999km
   */
  mileage?: Maybe<Scalars['Int']>;
  /**
   * 제목
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 50자
   */
  title?: Maybe<Scalars['String']>;
  /** 문의 유형 ID */
  typeId?: Maybe<Scalars['ID']>;
};

export type UpdatePostIssueData = {
  /**
   * 메모.
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 1024자.
   */
  memo?: Maybe<Scalars['String']>;
};

export type UpdatePostIssueError = {
  message: Scalars['String'];
};

/** 게시물 이슈 수정 실패. */
export type UpdatePostIssueFail = {
  __typename?: 'UpdatePostIssueFail';
  error: UpdatePostIssueError;
};

export type UpdatePostIssueResponse = UpdatePostIssueFail | UpdatePostIssueSuccess;

/** 게시물 이슈 수정 성공. */
export type UpdatePostIssueSuccess = {
  __typename?: 'UpdatePostIssueSuccess';
  /** 수정된 게시물 이슈. */
  postIssue: PostIssue;
};

/** 게시물 수정 response */
export type UpdatePostResponse = UpdatePostSuccess;

/** 게시물 수정 성공 response */
export type UpdatePostSuccess = {
  __typename?: 'UpdatePostSuccess';
  /** 수정된 게시물 */
  post: Post;
};

/** 배송 수정 데이터 */
export type UpdateShippingData = {
  /** 수령인 휴대전화번호 또는 유선전화번호 */
  contact?: Maybe<Scalars['String']>;
  /**
   * 배송지 주소
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 128자
   */
  destination?: Maybe<Scalars['String']>;
  /**
   * 수령인
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 32자
   */
  recipient?: Maybe<Scalars['String']>;
  /**
   * 배송 요청 사항
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 20자
   */
  request?: Maybe<Scalars['String']>;
};

/** 배송 수정 성공 response */
export type UpdateShippingSuccess = {
  __typename?: 'UpdateShippingSuccess';
  /** 수정된 배송 */
  shipping: Shipping;
};

export type UpdateTemplateData = {
  /** 메세지 목록 */
  messages: Array<TemplateMessageInput>;
  /**
   * 템플릿명
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 100자
   */
  name: Scalars['String'];
};

/** 템플릿 수정 성공 response */
export type UpdateTemplateSuccess = {
  __typename?: 'UpdateTemplateSuccess';
  /** 수정된 템플릿 */
  template: Template;
};

/** 사용자 정보 업데이트를 위한 데이터 */
export type UpdateUserData = {
  /**
   * 나이
   *
   * ### Constraints
   *
   * 1. 최소 17세, 최대 150세
   */
  age?: Maybe<Scalars['Int']>;
  /** 정비 희망 지역 목록 */
  areas?: Maybe<Array<AreaInput>>;
  /**
   * 프로필 이미지
   *
   * ### Constraints
   *
   * 1. 이미지만 허용
   */
  avatar?: Maybe<FileInput>;
  /** 사용자 특성 ID 목록 */
  characteristicIds?: Maybe<Array<Scalars['ID']>>;
  /** 성별 */
  gender?: Maybe<Gender>;
  /**
   * 실명
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 10자
   * - 한글, 알파벳, 숫자 허용
   */
  name?: Maybe<Scalars['String']>;
  /**
   * 닉네임
   *
   * ### Constraints
   *
   * 1. 최소 2자, 최대 10자
   * - 한글, 알파벳, 숫자 허용
   */
  nickname?: Maybe<Scalars['String']>;
  /** 알림 수신 설정 */
  notification?: Maybe<NotificationSettingInput>;
};

/** 사용자 정보 업데이트 성공 시 response */
export type UpdateUserSuccess = {
  __typename?: 'UpdateUserSuccess';
  /** 업데이트 된 사용자 */
  user: User;
};

export type UpdateVehicleData = {
  /** 프로필 이미지 */
  avatar?: Maybe<FileInput>;
  /** 차량 특성 ID 목록 */
  characteristicIds?: Maybe<Array<Scalars['ID']>>;
  /**
   * 차량의 유종.
   *
   * ### Constraints
   *
   * 1. 이미 등급이 등록된 경우 수정할 수 없음.
   */
  fuelType?: Maybe<FuelTypeInput>;
  /** 차량의 등급 ID. */
  gradeId?: Maybe<Scalars['ID']>;
  /**
   * 차량의 주행거리(km).
   *
   * ### Constraints
   *
   * 1. 최소 이전 주행 거리 기록 이상 또는 0km, 최대 999999km.
   */
  mileage?: Maybe<Scalars['Int']>;
  /** 별칭 */
  nickname?: Maybe<Scalars['String']>;
  /**
   * 소유주명(이름 또는 법인명).
   *
   * ### Constraints
   *
   * 1. 최소 1자, 최대 100자.
   *
   * 2. 이미 등록된 값을 수정할 수 없음.
   */
  ownerName?: Maybe<Scalars['String']>;
  /**
   * 차량 번호.
   *
   * ### Constraints
   *
   * 1. 이미 등록된 값을 수정할 수 없음.
   */
  plateNumber?: Maybe<Scalars['String']>;
  /**
   * 차량의 연식(등록연도).
   *
   * ### Constraints
   *
   * 1. 최소 1901년, 최대 2155년.
   */
  registeredYear?: Maybe<Scalars['Int']>;
  /** 차량의 세부 모델 ID. */
  submodelId?: Maybe<Scalars['ID']>;
  /** 차량 타이어 규격. */
  tireSize?: Maybe<VehicleTireSizeInput>;
  /** 차량의 트림 ID. */
  trimId?: Maybe<Scalars['ID']>;
  /**
   * 차대번호
   *
   * ### Constraints
   *
   * 1. 영문 대문자 및 숫자 17자리.
   */
  vin?: Maybe<Scalars['String']>;
};

export type UpdateVehicleError = {
  message: Scalars['String'];
};

export type UpdateVehicleFail = {
  __typename?: 'UpdateVehicleFail';
  error: UpdateVehicleError;
};

export type UpdateVehicleResponse = UpdateVehicleFail | UpdateVehicleSuccess;

export type UpdateVehicleSuccess = {
  __typename?: 'UpdateVehicleSuccess';
  /** 수정된 차량. */
  vehicle: Vehicle;
};

/** 파일 업로드 */
export type Upload = {
  __typename?: 'Upload';
  /** 업로드 이후 파일 URL */
  fileUrl: Scalars['String'];
  /** 업로드 용 URL */
  uploadUrl: Scalars['String'];
};

/** 상품 목록 upsert 성공 response */
export type UpsertItemsSuccess = {
  __typename?: 'UpsertItemsSuccess';
  /** 상품 목록 */
  items: Array<Item>;
};

/** 사용자 */
export type User = Node & {
  __typename?: 'User';
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  activeCounsels: Array<Counsel>;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  age?: Maybe<Scalars['Int']>;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  areas: Array<Area>;
  /**
   * 사용자의 가능한 인증 방법 목록.
   *
   * ### Constraints
   *
   * 1. 소유자, 상담사, 어드민만 조회 가능.
   */
  authenticationMethods: Array<AuthenticationMethodEnum>;
  avatar?: Maybe<Image>;
  /** 자기소개. */
  bio?: Maybe<Scalars['String']>;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  blockConnection: UserBlockConnection;
  /**
   * ### Permissions
   *
   * *1. 로그인한 사용자만 조회 가능
   */
  blocked: Scalars['Boolean'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  bookmarkedPostConnection: PostConnection;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  bookmarkedPostCount: Scalars['Int'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  characteristics: Array<UserCharacteristic>;
  commentConnection: CommentConnection;
  commentCount: Scalars['Int'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  complaintConnection: ComplaintConnection;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  complaintCount: Scalars['Int'];
  /**
   * 종료되었고, 오늘보다 미래의 예약이 존재하지 않는 상담 개수
   *
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  counselCount: Scalars['Int'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  couponConnection: CouponConnection;
  /**
   * 사용자의 보유한 쿠폰 개수
   *
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  couponCount: Scalars['Int'];
  /**
   * 사용자의 보유한 만료될 쿠폰 개수
   *
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  couponCountExpiresWithin: Scalars['Int'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  email?: Maybe<Scalars['String']>;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  gender?: Maybe<Gender>;
  /** 사용자 ID */
  id: Scalars['ID'];
  /**
   * 종료되었고, 오늘보다 미래의 예약이 존재하지 않는 상담 connection (최신 상담 종료일 순 정렬)
   *
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  inactiveCounselConnection: CounselConnection;
  /**
   * ### Permissions
   *
   * *1. 상담사, 어드민만 접근 가능
   */
  lastDevice?: Maybe<Device>;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  name?: Maybe<Scalars['String']>;
  /** 닉네임 */
  nickname: Scalars['String'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  notification: LegacyNotificationSetting;
  /**
   * ### Permissions
   *
   * *1. 소유자, 어드민만 접근 가능
   */
  notificationConnection: NotificationConnection;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  orderConnection: OrderConnection;
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  orderCount: Scalars['Int'];
  /**
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  phone?: Maybe<Scalars['String']>;
  postConnection: PostConnection;
  postCount: Scalars['Int'];
  role: RoleEnum;
  /** 검색 가능 업체 리뷰 개수. */
  searchableCompanyReviewCount: Scalars['Int'];
  /** 검색 가능 업체 리뷰 목록. */
  searchableCompanyReviews: Array<SearchableCompanyReview>;
  /**
   * ### Permissions
   *
   * *1. 소유자, 어드민만 접근 가능
   */
  unreadNotificationCount?: Maybe<Scalars['Int']>;
  /** 사용자 소유의 차량 목록. */
  vehicles: Array<Vehicle>;
};


/** 사용자 */
export type UserBlockConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserBookmarkedPostConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserCommentConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserComplaintConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: ComplaintConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserComplaintCountArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: ComplaintConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserCounselCountArgs = {
  filter: UserInactiveCounselConnectionFilter;
};


/** 사용자 */
export type UserCouponConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CouponConnectionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserCouponCountArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CouponConnectionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserCouponCountExpiresWithinArgs = {
  day?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserInactiveCounselConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: UserInactiveCounselConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserNotificationConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserOrderConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 */
export type UserPostConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** 사용자 계정. */
export type UserAccount = Account & {
  __typename?: 'UserAccount';
  /** 예약 개수. */
  appointmentCount: Scalars['Int'];
  /** 예약 목록. */
  appointments: Array<Appointment>;
  /**
   * 가능한 인증 방식 목록.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  authenticationMethods: Array<AuthenticationMethodEnum>;
  /**
   * 이메일 주소.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  email?: Maybe<Scalars['String']>;
  /**
   * 계정의 견적 문의 connection.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryConnection: EstimateInquiryConnection;
  /**
   * 계정의 견적 문의 개수.
   *
   * ### Constraints
   *
   * 1. 관리자, 상담사, 계정 소유주만 접근 가능.
   */
  estimateInquiryCount: Scalars['Int'];
  /** ID. */
  id: Scalars['ID'];
  /** 계정의 마지막 커뮤니티 활동(게시물/댓글/답글 작성) 시점. */
  lastActiveAtCommunity?: Maybe<Scalars['DateTime']>;
  /** 계정에 수신된 알림 connection. */
  notificationConnection: NotificationConnection;
  /**
   * 계정에 수신된 알림 개수.
   *
   * ### Constraints
   *
   * 1. 관리자 및 계정 소유자만 접근 가능.
   */
  notificationCount: Scalars['Int'];
  /**
   * 알림 설정.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  notificationSetting: NotificationSetting;
  /**
   * 휴대전화번호.
   *
   * ### Constraints
   *
   * 1. 계정 소유자, 관리자 및 상담사 계정만 접근 가능.
   */
  phone?: Maybe<Scalars['String']>;
  /** 프로필. */
  profile: AccountProfile;
};


/** 사용자 계정. */
export type UserAccountAppointmentCountArgs = {
  filter?: Maybe<GetAppointmentsOfUserAccountFilterRequestDto>;
};


/** 사용자 계정. */
export type UserAccountAppointmentsArgs = {
  filter?: Maybe<GetAppointmentsOfUserAccountFilterRequestDto>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder?: AppointmentSortOrderEnum;
};


/** 사용자 계정. */
export type UserAccountEstimateInquiryConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 계정. */
export type UserAccountNotificationConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** 사용자 계정. */
export type UserAccountNotificationCountArgs = {
  filter: GetNotificationCountOfAccountFilterRequestDto;
};

/** 사용자 차단 */
export type UserBlock = {
  __typename?: 'UserBlock';
  blocked: User;
  blocker: User;
  /** 생성 TIMESTAMP */
  createdAt: Scalars['LegacyDateTime'];
};

/** 사용자 차단 connection */
export type UserBlockConnection = {
  __typename?: 'UserBlockConnection';
  edges: Array<UserBlockEdge>;
  pageInfo: PageInfo;
};

export type UserBlockEdge = {
  __typename?: 'UserBlockEdge';
  cursor: Scalars['String'];
  node: UserBlock;
};

/** 사용자 특성 */
export type UserCharacteristic = Node & {
  __typename?: 'UserCharacteristic';
  /** 사용자 특성 ID */
  id: Scalars['ID'];
  /** 특성 */
  name: Scalars['String'];
};

export type UserInactiveCounselConnectionFilter = {
  /** 문의 유형 ID 목록 */
  inquiryTypeIds?: Maybe<Array<Scalars['ID']>>;
  /** 요청자에게로의 리뷰 요청 여부 */
  reviewRequested?: Maybe<Scalars['Boolean']>;
  /** 요청자의 리뷰 작성 여부 */
  reviewed?: Maybe<Scalars['Boolean']>;
  /** 상담 차량 ID 목록 */
  vehicleIds?: Maybe<Array<Scalars['ID']>>;
};

/** 차량 */
export type Vehicle = {
  __typename?: 'Vehicle';
  /**
   * 종료되지 않았거나, 오늘보다 미래의 예약이 존재하는 상담 목록
   *
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  activeCounsels?: Maybe<Array<Counsel>>;
  /** 프로필 이미지 */
  avatar?: Maybe<Image>;
  /** 제조사. */
  brand: VehicleBrand;
  /** 차량 특성 목록. */
  characteristics: Array<VehicleCharacteristic>;
  /**
   * 완료된 퀵상담 connection
   *
   * ### Permissions
   *
   * *1. 소유자, 상담사, 어드민만 조회 가능
   */
  finishedCounselConnection: CounselConnection;
  /** 연료 유형 */
  fuelType?: Maybe<FuelType>;
  /** 전체 모델명(제조사명 + 모델명/세부모델명 + 등급명/트림명). */
  fullModelName: Scalars['String'];
  /** 등급. */
  grade?: Maybe<VehicleGrade>;
  /** 차량 ID */
  id: Scalars['ID'];
  /** 최신 주행거리 */
  mileage?: Maybe<Scalars['Int']>;
  /** 주행 거리 기록 목록 */
  mileageRecords: Array<MileageRecord>;
  /** 모델. */
  model: VehicleModel;
  /** 별칭 */
  nickname: Scalars['String'];
  /**
   * 소유주명(실명 또는 법인명)
   *
   * ### Constraints
   *
   * 1. `USER`, `BIZ` role의 요청자의 경우 차량 소유주만 접근 가능.
   */
  ownerName?: Maybe<Scalars['String']>;
  /**
   * 차량 번호.
   *
   * ### Constraints
   *
   * 1. `USER`, `BIZ` role의 요청자의 경우 차량 소유주만 접근 가능.
   */
  plateNumber?: Maybe<Scalars['String']>;
  /** 등록연도(연식) */
  registeredYear?: Maybe<Scalars['Int']>;
  /** 축약 모델명(제조사명 + 모델명/세부모델명). */
  shortModelName: Scalars['String'];
  /** 차량의 등록연도에 기반한 제원. */
  spec?: Maybe<VehicleSpec>;
  /** 세부 모델. */
  submodel?: Maybe<VehicleSubmodel>;
  /** 타이어 규격. */
  tireSize?: Maybe<VehicleTireSize>;
  /** 트림. */
  trim?: Maybe<VehicleTrim>;
  /**
   * 차대번호.
   *
   * ### Constraints
   *
   * 1. `USER`, `BIZ` role의 요청자의 경우 차량 소유주만 접근 가능.
   */
  vin?: Maybe<Scalars['String']>;
};


/** 차량 */
export type VehicleFinishedCounselConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: FinishedCounselConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** 차량 제조사. */
export type VehicleBrand = {
  __typename?: 'VehicleBrand';
  /** 활성화 여부. 비활성화된 제조사는 검색되지 않음. */
  activated: Scalars['Boolean'];
  /** 국산 제조사 여부. */
  domestic: Scalars['Boolean'];
  /** ID. */
  id: Scalars['ID'];
  /** 로고 이미지. */
  logo?: Maybe<Image>;
  /** 하위 차량 모델 목록. */
  models: Array<VehicleModel>;
  /** 이름. */
  name: Scalars['String'];
  /** 정렬 우선순위. */
  priority: Scalars['Int'];
};


/** 차량 제조사. */
export type VehicleBrandModelsArgs = {
  filter?: Maybe<VehicleBrandVehicleModelsFilter>;
};

export type VehicleBrandVehicleModelsFilter = {
  /** 활성화 여부. */
  activated?: Maybe<Scalars['Boolean']>;
};

export type VehicleBrandsFilter = {
  /** 활성화 여부. */
  activated?: Maybe<Scalars['Boolean']>;
};

/** 차량 특성. */
export type VehicleCharacteristic = {
  __typename?: 'VehicleCharacteristic';
  /** ID. */
  id: Scalars['ID'];
  /** 이름. */
  name: Scalars['String'];
};

/** 차량 등급. */
export type VehicleGrade = {
  __typename?: 'VehicleGrade';
  /** 활성화 여부. 비활성화된 등급은 검색되지 않음. */
  activated: Scalars['Boolean'];
  /** 배기량 (cc). */
  displacement?: Maybe<Scalars['Int']>;
  /** 연료 유형. */
  fuelType: FuelType;
  /** ID. */
  id: Scalars['ID'];
  /** 이름. */
  name: Scalars['String'];
  /** 승차 정원. */
  seatingCapacity?: Maybe<Scalars['Int']>;
  /** 차급. */
  segment: VehicleSegment;
  /** 상위 차량 세부 모델. */
  submodel: VehicleSubmodel;
  /** 변속기 유형. */
  transmission?: Maybe<Transmission>;
  /** 하위 차량 트림 목록. */
  trims: Array<VehicleTrim>;
};


/** 차량 등급. */
export type VehicleGradeTrimsArgs = {
  filter?: Maybe<VehicleGradeVehicleTrimsFilter>;
};

export type VehicleGradeVehicleTrimsFilter = {
  /** 활성화 여부. */
  activated?: Maybe<Scalars['Boolean']>;
};

/** NICE D&R 측 오류로 인한 응답 실패. */
export type VehicleLedgerServerDown = {
  __typename?: 'VehicleLedgerServerDown';
  healthCheck: Scalars['Boolean'];
};

/** 모델. */
export type VehicleModel = {
  __typename?: 'VehicleModel';
  /** 활성화 여부. 비활성화된 차량 모델은 검색되지 않음. */
  activated: Scalars['Boolean'];
  /** 상위 차량 제조사. */
  brand: VehicleBrand;
  /** ID. */
  id: Scalars['ID'];
  /** 이름. */
  name: Scalars['String'];
  /** 정렬 우선순위. */
  priority: Scalars['Int'];
  /** 하위 차량 세부 모델 목록. */
  submodels: Array<VehicleSubmodel>;
};


/** 모델. */
export type VehicleModelSubmodelsArgs = {
  filter?: Maybe<VehicleModelVehicleSubmodelsFilter>;
};

/**
 * 차량 모델 필터.
 *
 * ### Constraints
 *
 * 1. 제조사, 모델, 세부모델, 등급, 트림 ID 중 하나만 주어져야 함.
 */
export type VehicleModelFilter = {
  /** 제조사 ID. */
  brandId?: Maybe<Scalars['ID']>;
  /** 등급 ID. */
  gradeId?: Maybe<Scalars['ID']>;
  /** 모델 ID. */
  modelId?: Maybe<Scalars['ID']>;
  /** 세부모델 ID. */
  submodelId?: Maybe<Scalars['ID']>;
  /** 트림 ID. */
  trimId?: Maybe<Scalars['ID']>;
};

export type VehicleModelVehicleSubmodelsFilter = {
  /** 활성화 여부. */
  activated?: Maybe<Scalars['Boolean']>;
};

/** 차량 소유자 불일치 에러 */
export type VehicleOwnerMismatchError = CreateCounselError & {
  __typename?: 'VehicleOwnerMismatchError';
  message: Scalars['String'];
};

/** 차급. */
export enum VehicleSegment {
  /** 경형 */
  A = 'A',
  /** 소형 */
  B = 'B',
  /** 버스 */
  Bus = 'BUS',
  /** 준중형 */
  C = 'C',
  /** 중형 */
  D = 'D',
  /** 준대형 */
  E = 'E',
  /** 대형 */
  F = 'F',
  /** RV */
  Rv = 'RV',
  /** 특수목적 */
  Special = 'SPECIAL',
  /** 스포츠 */
  Sports = 'SPORTS',
  /** SUV */
  Suv = 'SUV',
  /** 트럭 */
  Truck = 'TRUCK',
  /** 밴 */
  Van = 'VAN'
}

/** 차량 제원. */
export type VehicleSpec = {
  __typename?: 'VehicleSpec';
  /** 기준 연월. */
  appliedYearMonth: YearMonth;
  /** 표준 차량 타이어 규격. */
  baseVehicleTireSize?: Maybe<VehicleTireSize>;
  /** ID. */
  id: Scalars['ID'];
};

/** 세부 모델. */
export type VehicleSubmodel = {
  __typename?: 'VehicleSubmodel';
  /** 활성화 여부. 비활성화된 세부 모델은 검색되지 않음. */
  activated: Scalars['Boolean'];
  /** 하위 차량 등급 목록. */
  grades: Array<VehicleGrade>;
  /** ID. */
  id: Scalars['ID'];
  /** 이미지 URL. */
  imageUrl?: Maybe<Scalars['String']>;
  /** 상위 차량 모델. */
  model: VehicleModel;
  /** 이름. */
  name: Scalars['String'];
  /** 판매 기간. */
  salesRange: SalesRange;
};


/** 세부 모델. */
export type VehicleSubmodelGradesArgs = {
  filter?: Maybe<VehicleSubmodelVehicleGradesFilter>;
};

export type VehicleSubmodelVehicleGradesFilter = {
  /** 활성화 여부. */
  activated?: Maybe<Scalars['Boolean']>;
};

/** 차량 타이어 규격. */
export type VehicleTireSize = {
  __typename?: 'VehicleTireSize';
  /** 전륜 규격. */
  front: TireSize;
  /** 후륜 규격. */
  rear: TireSize;
};

/** 차량 타이어 규격 입력값. */
export type VehicleTireSizeInput = {
  /** 전륜 타이어 규격. */
  front: TireSizeInput;
  /** 후륜 타이어 규격. */
  rear: TireSizeInput;
};

/** 차량 트림. */
export type VehicleTrim = {
  __typename?: 'VehicleTrim';
  /** 활성화 여부. 비활성화된 트림은 검색되지 않음. */
  activated: Scalars['Boolean'];
  /** 상위 차량 등급. */
  grade: VehicleGrade;
  /** ID. */
  id: Scalars['ID'];
  /** 이름. */
  name: Scalars['String'];
  /** 판매 기간. */
  salesRange: SalesRange;
  /** 하위 차량 제원 목록. */
  specs: Array<VehicleSpec>;
};

export type VerifyEmailError = {
  message: Scalars['String'];
};

/** 이메일 인증 실패. */
export type VerifyEmailFail = {
  __typename?: 'VerifyEmailFail';
  error: VerifyEmailError;
};

export type VerifyEmailResponse = VerifyEmailFail | VerifyEmailSuccess;

/** 이메일 인증 성공. */
export type VerifyEmailSuccess = {
  __typename?: 'VerifyEmailSuccess';
  /** 인증한 이메일의 계정. */
  account?: Maybe<User>;
};

/** 비디오 파일 */
export type Video = File & {
  __typename?: 'Video';
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 메타데이터 */
  metadata?: Maybe<VideoMetadata>;
  /** URL */
  url: Scalars['String'];
};

/** 비디오 파일 메타데이터 */
export type VideoMetadata = {
  __typename?: 'VideoMetadata';
  /** 비디오 길이 (ms) */
  duration: Scalars['Int'];
  /** 비디오 높이 (px) */
  height: Scalars['Int'];
  /** 비디오 넓이 (px) */
  width: Scalars['Int'];
};

export type ViewAllMyNotificationsError = {
  message: Scalars['String'];
};

/** 현재 계정의 모든 알림 조회 실패. */
export type ViewAllMyNotificationsFail = {
  __typename?: 'ViewAllMyNotificationsFail';
  error: ViewAllMyNotificationsError;
};

export type ViewAllMyNotificationsResponse = ViewAllMyNotificationsFail | ViewAllMyNotificationsSuccess;

/** 현재 계정의 모든 알림 조회 성공. */
export type ViewAllMyNotificationsSuccess = {
  __typename?: 'ViewAllMyNotificationsSuccess';
  /** 모든 알림이 조회된 현재 계정. */
  account: Account;
  /** 조회된 알림 목록. */
  viewedNotifications: Array<Notification>;
};

/** 댓글 조회 response */
export type ViewCommentResponse = ViewCommentSuccess;

/** 댓글 조회 성공 response */
export type ViewCommentSuccess = {
  __typename?: 'ViewCommentSuccess';
  /** 조회한 댓글 */
  comment: CommentBase;
  /** 조회된 알림 */
  notification?: Maybe<Notification>;
};

/** 고객 문의 답변 조회 성공 response */
export type ViewComplaintResponseSuccess = {
  __typename?: 'ViewComplaintResponseSuccess';
  /** 조회된 고객 문의 답변 알림 */
  notification?: Maybe<NewComplaintResponseNotification>;
  /** 조회된 고객 문의 답변 */
  response: ComplaintResponse;
};

export type ViewNotificationError = {
  message: Scalars['String'];
};

/** 알림 조회 실패. */
export type ViewNotificationFail = {
  __typename?: 'ViewNotificationFail';
  error: ViewNotificationError;
};

export type ViewNotificationResponse = ViewNotificationFail | ViewNotificationSuccess;

/** 알림 조회 성공. */
export type ViewNotificationSuccess = {
  __typename?: 'ViewNotificationSuccess';
  /** 조회된 알림. */
  notification: Notification;
};

/** 게시물 조회 response */
export type ViewPostResponse = ViewPostSuccess;

/** 게시물 조회 성공 response */
export type ViewPostSuccess = {
  __typename?: 'ViewPostSuccess';
  /** 조회된 게시물 */
  post: Post;
};

/** 차대번호 입력 메세지 */
export type VinInputMessage = Message & {
  __typename?: 'VinInputMessage';
  author?: Maybe<User>;
  counsel: Counsel;
  /** 생성 시점 */
  createdAt: Scalars['LegacyDateTime'];
  /** ID */
  id: Scalars['ID'];
  status: MessageStatus;
  vehicle?: Maybe<Vehicle>;
};

/** 유저가 게시물 또는 댓글에 누른 투표 */
export type Vote = {
  /** 투표 유형 */
  type: VoteType;
  voter: User;
};

/** 댓글 도움 여부 투표 response */
export type VoteToCommentResponse = VoteToCommentSuccess;

/** 댓글 도움 여부 투표 response */
export type VoteToCommentSuccess = {
  __typename?: 'VoteToCommentSuccess';
  /** 투표한 댓글 */
  comment: Comment;
};

/** 게시물 도움 여부 투표 response */
export type VoteToPostResponse = VoteToPostSuccess;

/** 게시물 도움 여부 투표 response */
export type VoteToPostSuccess = {
  __typename?: 'VoteToPostSuccess';
  /** 투표한 게시물 */
  post: Post;
};

/** 투표 유형 */
export enum VoteType {
  /** DownVote */
  Down = 'DOWN',
  /** UpVote */
  Up = 'UP'
}

/** 간편인증 요청 실패. */
export type WetaxIdentityVerificationRequestFailError = RequestIdentityVerificationForWetaxError & {
  __typename?: 'WetaxIdentityVerificationRequestFailError';
  /** 간편인증 요청에 실패한 간편인증 기관. */
  loginOrganizationCode: WetaxLoginOrganizationCode;
  message: Scalars['String'];
};

/** 위택스 간편인증 기관 코드. */
export enum WetaxLoginOrganizationCode {
  /** 카카오 */
  Kakao = 'kakao',
  /** KB스타뱅킹 */
  Kb = 'kb',
  /** 삼성패스 */
  Kica = 'kica',
  /** PASS(통신사) */
  Pass = 'pass',
  /** 페이코 */
  Payco = 'payco'
}

export type WithdrawMeError = {
  message: Scalars['String'];
};

/** 계정 탈퇴 실패. */
export type WithdrawMeFail = {
  __typename?: 'WithdrawMeFail';
  error: WithdrawMeError;
};

export type WithdrawMeResponse = WithdrawMeFail | WithdrawMeSuccess;

/** 계정 탈퇴 성공. */
export type WithdrawMeSuccess = {
  __typename?: 'WithdrawMeSuccess';
  /** 탈퇴된 계정 ID. */
  accountId: Scalars['ID'];
};

/** 작업. */
export type Work = {
  __typename?: 'Work';
  /** 고객. */
  client: WorkClient;
  /** 작업을 제공하는 업체. */
  company?: Maybe<Company>;
  /** 차량 입고일. */
  dateOfEnter: Scalars['Date'];
  /** 예상 출고일. */
  expectedDateOfRelease: Scalars['Date'];
  /** 종료일. */
  finishedAt?: Maybe<Scalars['DateTime']>;
  /** ID */
  id: Scalars['ID'];
  /** 보험 처리 방식. */
  insurance: InsuranceEnum;
  /** 최신 작업의 공유가 이루어졌는지 여부. */
  isSharedByLatest: Scalars['Boolean'];
  /** 차량. */
  repairReport?: Maybe<RepairReport>;
  /** 수리 목록. */
  repairs: Array<Repair>;
  /** 작업 상태. */
  status: WorkStatusEnum;
  /** 중단 사유. */
  stoppedReason?: Maybe<Scalars['String']>;
  /** 차량. */
  vehicle: WorkVehicle;
};

/** 작업 고객. */
export type WorkClient = {
  __typename?: 'WorkClient';
  /** 이름 */
  name: Scalars['String'];
  /** 휴대전화번호 */
  phone: Scalars['String'];
};

/** 작업 목록 정렬 조건. */
export enum WorkSortOrderEnum {
  /** 생성 시점 내림차순 */
  CreatedAtDesc = 'CREATED_AT_DESC',
  FinishedAtDesc = 'FINISHED_AT_DESC'
}

/** 작업 상태. */
export enum WorkStatusEnum {
  /** 완료. */
  Completed = 'COMPLETED',
  /** 진행. */
  InProgress = 'IN_PROGRESS',
  /** 중단 */
  Stopped = 'STOPPED'
}

/** 작업 차량 */
export type WorkVehicle = {
  __typename?: 'WorkVehicle';
  /** 제조사. */
  brand?: Maybe<VehicleBrand>;
  /** 연료 유형 */
  fuelType?: Maybe<FuelType>;
  /** 모델명 */
  modelName: Scalars['String'];
  /** 차량번호 */
  plateNumber: Scalars['String'];
  /** 등록연도(연식) */
  registeredYear?: Maybe<Scalars['Int']>;
};

/** 예약 목록에 대한 AND 조건 목록. */
export type WorksFilter = {
  /** 업체 아이디. */
  companyId?: Maybe<Scalars['String']>;
  /** 예약 상태 목록. */
  statuses?: Maybe<Array<WorkStatusEnum>>;
};

/** 작업 목록에 대한 AND 조건 목록. */
export type WorksOfCompanyFilter = {
  /** 작업 상태 목록. */
  statuses?: Maybe<Array<WorkStatusEnum>>;
};

/** 연월. */
export type YearMonth = {
  __typename?: 'YearMonth';
  /** 월. */
  month: Scalars['Int'];
  /** 연도. */
  year: Scalars['Int'];
};

/** 행정구역 */
export type Zone = {
  __typename?: 'Zone';
  /** 구 단위 */
  district?: Maybe<Scalars['String']>;
  /** 동 단위 */
  neighborhood?: Maybe<Scalars['String']>;
  /** 시/도 단위 */
  province: Scalars['String'];
};

export type CounselTableRowUserFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'nickname'>
  & { avatar?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  )> }
);

type CounselTableRowMessage_AppReviewMessage_Fragment = (
  { __typename: 'AppReviewMessage' }
  & Pick<AppReviewMessage, 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_AreaInputMessage_Fragment = (
  { __typename: 'AreaInputMessage' }
  & Pick<AreaInputMessage, 'id' | 'createdAt'>
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
  ), author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_CompanyMessage_Fragment = (
  { __typename: 'CompanyMessage' }
  & Pick<CompanyMessage, 'id' | 'createdAt'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_DeletedMessage_Fragment = (
  { __typename: 'DeletedMessage' }
  & Pick<DeletedMessage, 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_EndMessage_Fragment = (
  { __typename: 'EndMessage' }
  & Pick<EndMessage, 'id' | 'createdAt'>
  & { endedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'role' | 'nickname'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_EnterMessage_Fragment = (
  { __typename: 'EnterMessage' }
  & Pick<EnterMessage, 'id' | 'createdAt'>
  & { inviter?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )>, invitee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_EstimateMessage_Fragment = (
  { __typename: 'EstimateMessage' }
  & Pick<EstimateMessage, 'id' | 'createdAt'>
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ), author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_FileMessage_Fragment = (
  { __typename: 'FileMessage' }
  & Pick<FileMessage, 'id' | 'createdAt'>
  & { file: (
    { __typename: 'Image' }
    & Pick<Image, 'description' | 'url'>
    & { metadata?: Maybe<(
      { __typename?: 'ImageMetadata' }
      & Pick<ImageMetadata, 'width' | 'height'>
    )> }
  ) | (
    { __typename: 'Video' }
    & Pick<Video, 'description' | 'url'>
    & { metadata?: Maybe<(
      { __typename?: 'VideoMetadata' }
      & Pick<VideoMetadata, 'width' | 'height' | 'duration'>
    )> }
  ), author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_InvoiceMessage_Fragment = (
  { __typename: 'InvoiceMessage' }
  & Pick<InvoiceMessage, 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_LeaveMessage_Fragment = (
  { __typename: 'LeaveMessage' }
  & Pick<LeaveMessage, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_PlateNumberInputMessage_Fragment = (
  { __typename: 'PlateNumberInputMessage' }
  & Pick<PlateNumberInputMessage, 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_QueueMessage_Fragment = (
  { __typename: 'QueueMessage' }
  & Pick<QueueMessage, 'reason' | 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_RestartMessage_Fragment = (
  { __typename: 'RestartMessage' }
  & Pick<RestartMessage, 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_SuspendedMessage_Fragment = (
  { __typename: 'SuspendedMessage' }
  & Pick<SuspendedMessage, 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_TextMessage_Fragment = (
  { __typename: 'TextMessage' }
  & Pick<TextMessage, 'body' | 'id' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselTableRowMessage_VinInputMessage_Fragment = (
  { __typename: 'VinInputMessage' }
  & Pick<VinInputMessage, 'id' | 'createdAt'>
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type CounselTableRowMessageFragment = CounselTableRowMessage_AppReviewMessage_Fragment | CounselTableRowMessage_AreaInputMessage_Fragment | CounselTableRowMessage_CompanyMessage_Fragment | CounselTableRowMessage_DeletedMessage_Fragment | CounselTableRowMessage_EndMessage_Fragment | CounselTableRowMessage_EnterMessage_Fragment | CounselTableRowMessage_EstimateMessage_Fragment | CounselTableRowMessage_FileMessage_Fragment | CounselTableRowMessage_InvoiceMessage_Fragment | CounselTableRowMessage_LeaveMessage_Fragment | CounselTableRowMessage_PlateNumberInputMessage_Fragment | CounselTableRowMessage_QueueMessage_Fragment | CounselTableRowMessage_RestartMessage_Fragment | CounselTableRowMessage_SuspendedMessage_Fragment | CounselTableRowMessage_TextMessage_Fragment | CounselTableRowMessage_VinInputMessage_Fragment;

export type CounselTableRowMessageConnectionFragment = (
  { __typename: 'MessageConnection' }
  & { edges: Array<(
    { __typename?: 'MessageEdge' }
    & { node: (
      { __typename?: 'AppReviewMessage' }
      & CounselTableRowMessage_AppReviewMessage_Fragment
    ) | (
      { __typename?: 'AreaInputMessage' }
      & CounselTableRowMessage_AreaInputMessage_Fragment
    ) | (
      { __typename?: 'CompanyMessage' }
      & CounselTableRowMessage_CompanyMessage_Fragment
    ) | (
      { __typename?: 'DeletedMessage' }
      & CounselTableRowMessage_DeletedMessage_Fragment
    ) | (
      { __typename?: 'EndMessage' }
      & CounselTableRowMessage_EndMessage_Fragment
    ) | (
      { __typename?: 'EnterMessage' }
      & CounselTableRowMessage_EnterMessage_Fragment
    ) | (
      { __typename?: 'EstimateMessage' }
      & CounselTableRowMessage_EstimateMessage_Fragment
    ) | (
      { __typename?: 'FileMessage' }
      & CounselTableRowMessage_FileMessage_Fragment
    ) | (
      { __typename?: 'InvoiceMessage' }
      & CounselTableRowMessage_InvoiceMessage_Fragment
    ) | (
      { __typename?: 'LeaveMessage' }
      & CounselTableRowMessage_LeaveMessage_Fragment
    ) | (
      { __typename?: 'PlateNumberInputMessage' }
      & CounselTableRowMessage_PlateNumberInputMessage_Fragment
    ) | (
      { __typename?: 'QueueMessage' }
      & CounselTableRowMessage_QueueMessage_Fragment
    ) | (
      { __typename?: 'RestartMessage' }
      & CounselTableRowMessage_RestartMessage_Fragment
    ) | (
      { __typename?: 'SuspendedMessage' }
      & CounselTableRowMessage_SuspendedMessage_Fragment
    ) | (
      { __typename?: 'TextMessage' }
      & CounselTableRowMessage_TextMessage_Fragment
    ) | (
      { __typename?: 'VinInputMessage' }
      & CounselTableRowMessage_VinInputMessage_Fragment
    ) }
  )> }
);

export type CounselTableRowFragment = (
  { __typename: 'Counsel' }
  & Pick<Counsel, 'id' | 'index' | 'memo' | 'queueReason' | 'createdAt' | 'body' | 'status' | 'unreadCount'>
  & { orders: Array<(
    { __typename?: 'InsurancedOrder' }
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status'>
    )> }
  ) | (
    { __typename?: 'NoneInsurancedOrder' }
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status'>
    )> }
  ) | (
    { __typename?: 'Order' }
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status'>
    )> }
  )>, counselee?: Maybe<(
    { __typename?: 'User' }
    & CounselTableRowUserFragment
  )>, areas: Array<(
    { __typename?: 'Area' }
    & { zone?: Maybe<(
      { __typename?: 'Zone' }
      & Pick<Zone, 'province' | 'district' | 'neighborhood'>
    )> }
  )>, type?: Maybe<(
    { __typename?: 'InquiryType' }
    & Pick<InquiryType, 'id' | 'name'>
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'plateNumber' | 'fullModelName'>
    & { brand: (
      { __typename?: 'VehicleBrand' }
      & Pick<VehicleBrand, 'id'>
      & { logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    ) }
  )>, files: Array<(
    { __typename: 'Image' }
    & Pick<Image, 'url'>
  ) | (
    { __typename: 'Video' }
    & Pick<Video, 'url'>
  )>, post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
    & { hashtags: Array<(
      { __typename?: 'PostHashtag' }
      & Pick<PostHashtag, 'id' | 'name'>
    )> }
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, members: Array<(
    { __typename?: 'User' }
    & CounselTableRowUserFragment
  )>, messageConnection: (
    { __typename: 'MessageConnection' }
    & { edges: Array<(
      { __typename?: 'MessageEdge' }
      & { node: (
        { __typename: 'AppReviewMessage' }
        & Pick<AppReviewMessage, 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'AreaInputMessage' }
        & Pick<AreaInputMessage, 'id' | 'createdAt'>
        & { counsel: (
          { __typename?: 'Counsel' }
          & Pick<Counsel, 'id'>
        ), author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'CompanyMessage' }
        & Pick<CompanyMessage, 'id' | 'createdAt'>
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'DeletedMessage' }
        & Pick<DeletedMessage, 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'EndMessage' }
        & Pick<EndMessage, 'id' | 'createdAt'>
        & { endedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'role' | 'nickname'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'EnterMessage' }
        & Pick<EnterMessage, 'id' | 'createdAt'>
        & { inviter?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        )>, invitee?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'EstimateMessage' }
        & Pick<EstimateMessage, 'id' | 'createdAt'>
        & { counsel: (
          { __typename?: 'Counsel' }
          & Pick<Counsel, 'id'>
          & { counselee?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'nickname'>
          )> }
        ), author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'FileMessage' }
        & Pick<FileMessage, 'id' | 'createdAt'>
        & { file: (
          { __typename: 'Image' }
          & Pick<Image, 'description' | 'url'>
          & { metadata?: Maybe<(
            { __typename?: 'ImageMetadata' }
            & Pick<ImageMetadata, 'width' | 'height'>
          )> }
        ) | (
          { __typename: 'Video' }
          & Pick<Video, 'description' | 'url'>
          & { metadata?: Maybe<(
            { __typename?: 'VideoMetadata' }
            & Pick<VideoMetadata, 'width' | 'height' | 'duration'>
          )> }
        ), author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'InvoiceMessage' }
        & Pick<InvoiceMessage, 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'LeaveMessage' }
        & Pick<LeaveMessage, 'id' | 'createdAt'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'PlateNumberInputMessage' }
        & Pick<PlateNumberInputMessage, 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'QueueMessage' }
        & Pick<QueueMessage, 'reason' | 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'RestartMessage' }
        & Pick<RestartMessage, 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'SuspendedMessage' }
        & Pick<SuspendedMessage, 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'TextMessage' }
        & Pick<TextMessage, 'body' | 'id' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) | (
        { __typename: 'VinInputMessage' }
        & Pick<VinInputMessage, 'id' | 'createdAt'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

type CounselRoomMessage_AppReviewMessage_Fragment = (
  { __typename: 'AppReviewMessage' }
  & Pick<AppReviewMessage, 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_AreaInputMessage_Fragment = (
  { __typename: 'AreaInputMessage' }
  & Pick<AreaInputMessage, 'id' | 'createdAt' | 'status'>
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ), author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselRoomMessage_CompanyMessage_Fragment = (
  { __typename: 'CompanyMessage' }
  & Pick<CompanyMessage, 'id' | 'createdAt' | 'status'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_DeletedMessage_Fragment = (
  { __typename: 'DeletedMessage' }
  & Pick<DeletedMessage, 'id' | 'deletedAt' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_EndMessage_Fragment = (
  { __typename: 'EndMessage' }
  & Pick<EndMessage, 'id' | 'createdAt' | 'status'>
  & { endedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'role' | 'nickname'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_EnterMessage_Fragment = (
  { __typename: 'EnterMessage' }
  & Pick<EnterMessage, 'id' | 'createdAt' | 'status'>
  & { inviter?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )>, invitee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_EstimateMessage_Fragment = (
  { __typename: 'EstimateMessage' }
  & Pick<EstimateMessage, 'id' | 'createdAt' | 'status'>
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'plateNumber' | 'registeredYear' | 'shortModelName'>
    )>, counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ), estimate: (
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'name' | 'amount' | 'latest' | 'shippingCharge'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'name' | 'price' | 'quantity' | 'discountRate' | 'totalAmount' | 'paymentAmount'>
    )>, order?: Maybe<(
      { __typename?: 'InsurancedOrder' }
      & Pick<InsurancedOrder, 'name' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'paymentAmount'>
    ) | (
      { __typename?: 'NoneInsurancedOrder' }
      & Pick<NoneInsurancedOrder, 'name' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'paymentAmount'>
    ) | (
      { __typename?: 'Order' }
      & Pick<Order, 'name' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'paymentAmount'>
    )> }
  ), author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselRoomMessage_FileMessage_Fragment = (
  { __typename: 'FileMessage' }
  & Pick<FileMessage, 'id' | 'createdAt' | 'status'>
  & { file: (
    { __typename: 'Image' }
    & Pick<Image, 'description' | 'url'>
    & { metadata?: Maybe<(
      { __typename?: 'ImageMetadata' }
      & Pick<ImageMetadata, 'width' | 'height'>
    )> }
  ) | (
    { __typename: 'Video' }
    & Pick<Video, 'description' | 'url'>
    & { metadata?: Maybe<(
      { __typename?: 'VideoMetadata' }
      & Pick<VideoMetadata, 'width' | 'height' | 'duration'>
    )> }
  ), author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_InvoiceMessage_Fragment = (
  { __typename: 'InvoiceMessage' }
  & Pick<InvoiceMessage, 'id' | 'createdAt' | 'status'>
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ), order?: Maybe<(
    { __typename?: 'InsurancedOrder' }
    & Pick<InsurancedOrder, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'name' | 'shippingCharge'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'code' | 'name' | 'price' | 'quantity'>
    )>, payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'status'>
    )>, shipping?: Maybe<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
    )> }
  ) | (
    { __typename?: 'NoneInsurancedOrder' }
    & Pick<NoneInsurancedOrder, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'name' | 'shippingCharge'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'code' | 'name' | 'price' | 'quantity'>
    )>, payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'status'>
    )>, shipping?: Maybe<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
    )> }
  ) | (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'name' | 'shippingCharge'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'code' | 'name' | 'price' | 'quantity'>
    )>, payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'status'>
    )>, shipping?: Maybe<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
    )> }
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

type CounselRoomMessage_LeaveMessage_Fragment = (
  { __typename: 'LeaveMessage' }
  & Pick<LeaveMessage, 'id' | 'createdAt' | 'status'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_PlateNumberInputMessage_Fragment = (
  { __typename: 'PlateNumberInputMessage' }
  & Pick<PlateNumberInputMessage, 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_QueueMessage_Fragment = (
  { __typename: 'QueueMessage' }
  & Pick<QueueMessage, 'reason' | 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_RestartMessage_Fragment = (
  { __typename: 'RestartMessage' }
  & Pick<RestartMessage, 'idle' | 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_SuspendedMessage_Fragment = (
  { __typename: 'SuspendedMessage' }
  & Pick<SuspendedMessage, 'idle' | 'willBeFinishedAt' | 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_TextMessage_Fragment = (
  { __typename: 'TextMessage' }
  & Pick<TextMessage, 'body' | 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

type CounselRoomMessage_VinInputMessage_Fragment = (
  { __typename: 'VinInputMessage' }
  & Pick<VinInputMessage, 'id' | 'createdAt' | 'status'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, counsel: (
    { __typename?: 'Counsel' }
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )> }
  ) }
);

export type CounselRoomMessageFragment = CounselRoomMessage_AppReviewMessage_Fragment | CounselRoomMessage_AreaInputMessage_Fragment | CounselRoomMessage_CompanyMessage_Fragment | CounselRoomMessage_DeletedMessage_Fragment | CounselRoomMessage_EndMessage_Fragment | CounselRoomMessage_EnterMessage_Fragment | CounselRoomMessage_EstimateMessage_Fragment | CounselRoomMessage_FileMessage_Fragment | CounselRoomMessage_InvoiceMessage_Fragment | CounselRoomMessage_LeaveMessage_Fragment | CounselRoomMessage_PlateNumberInputMessage_Fragment | CounselRoomMessage_QueueMessage_Fragment | CounselRoomMessage_RestartMessage_Fragment | CounselRoomMessage_SuspendedMessage_Fragment | CounselRoomMessage_TextMessage_Fragment | CounselRoomMessage_VinInputMessage_Fragment;

export type CounselWindowTitleDataFragment = (
  { __typename?: 'Counsel' }
  & Pick<Counsel, 'id' | 'index' | 'status' | 'unreadCount'>
  & { counselee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'shortModelName'>
  )> }
);

export type CounselWindowUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'role' | 'nickname'>
  & { avatar?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  )> }
);

type TaskpadOrder_InsurancedOrder_Fragment = (
  { __typename: 'InsurancedOrder' }
  & Pick<InsurancedOrder, 'cashbackAmount' | 'cashbackRate' | 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
    & { brand: (
      { __typename?: 'VehicleBrand' }
      & Pick<VehicleBrand, 'id' | 'name'>
    ) }
  )>, items: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
  )>, payment?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
  )>, shipping?: Maybe<(
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
  )> }
);

type TaskpadOrder_NoneInsurancedOrder_Fragment = (
  { __typename: 'NoneInsurancedOrder' }
  & Pick<NoneInsurancedOrder, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
    & { brand: (
      { __typename?: 'VehicleBrand' }
      & Pick<VehicleBrand, 'id' | 'name'>
    ) }
  )>, items: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
  )>, payment?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
  )>, shipping?: Maybe<(
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
  )> }
);

type TaskpadOrder_Order_Fragment = (
  { __typename: 'Order' }
  & Pick<Order, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
    & { brand: (
      { __typename?: 'VehicleBrand' }
      & Pick<VehicleBrand, 'id' | 'name'>
    ) }
  )>, items: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
  )>, payment?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
  )>, shipping?: Maybe<(
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
  )> }
);

export type TaskpadOrderFragment = TaskpadOrder_InsurancedOrder_Fragment | TaskpadOrder_NoneInsurancedOrder_Fragment | TaskpadOrder_Order_Fragment;

export type CounselDetailInfoFragment = (
  { __typename?: 'Counsel' }
  & Pick<Counsel, 'id' | 'createdAt' | 'status' | 'memo' | 'body' | 'insurance'>
  & { counselee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { inactiveCounselConnection: (
      { __typename?: 'CounselConnection' }
      & { edges: Array<(
        { __typename?: 'CounselEdge' }
        & { node: (
          { __typename?: 'Counsel' }
          & Pick<Counsel, 'id' | 'index' | 'memo' | 'createdAt'>
        ) }
      )> }
    ) }
  )>, type?: Maybe<(
    { __typename?: 'InquiryType' }
    & Pick<InquiryType, 'id' | 'name'>
  )>, areas: Array<(
    { __typename?: 'Area' }
    & { zone?: Maybe<(
      { __typename?: 'Zone' }
      & Pick<Zone, 'province' | 'district' | 'neighborhood'>
    )> }
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'shortModelName' | 'plateNumber' | 'registeredYear'>
  )>, files: Array<(
    { __typename?: 'Image' }
    & Pick<Image, 'url' | 'description'>
  ) | (
    { __typename?: 'Video' }
    & Pick<Video, 'url' | 'description'>
  )> }
);

export type CounselRoomFragment = (
  { __typename?: 'Counsel' }
  & Pick<Counsel, 'id' | 'status' | 'queueReason' | 'queuedFrom' | 'bookmarkedUntil'>
  & { cursors: Array<(
    { __typename?: 'Cursor' }
    & Pick<Cursor, 'id'>
    & { message?: Maybe<(
      { __typename?: 'AppReviewMessage' }
      & Pick<AppReviewMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'AreaInputMessage' }
      & Pick<AreaInputMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'CompanyMessage' }
      & Pick<CompanyMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'DeletedMessage' }
      & Pick<DeletedMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'EndMessage' }
      & Pick<EndMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'EnterMessage' }
      & Pick<EnterMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'EstimateMessage' }
      & Pick<EstimateMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'FileMessage' }
      & Pick<FileMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'InvoiceMessage' }
      & Pick<InvoiceMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'LeaveMessage' }
      & Pick<LeaveMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'PlateNumberInputMessage' }
      & Pick<PlateNumberInputMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'QueueMessage' }
      & Pick<QueueMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'RestartMessage' }
      & Pick<RestartMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'SuspendedMessage' }
      & Pick<SuspendedMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'TextMessage' }
      & Pick<TextMessage, 'id' | 'createdAt'>
    ) | (
      { __typename?: 'VinInputMessage' }
      & Pick<VinInputMessage, 'id' | 'createdAt'>
    )>, user: (
      { __typename?: 'User' }
      & CounselWindowUserFragment
    ) }
  )>, members: Array<(
    { __typename?: 'User' }
    & CounselWindowUserFragment
  )>, messageConnection: (
    { __typename?: 'MessageConnection' }
    & { edges: Array<(
      { __typename?: 'MessageEdge' }
      & Pick<MessageEdge, 'cursor'>
      & { node: (
        { __typename?: 'AppReviewMessage' }
        & CounselRoomMessage_AppReviewMessage_Fragment
      ) | (
        { __typename?: 'AreaInputMessage' }
        & CounselRoomMessage_AreaInputMessage_Fragment
      ) | (
        { __typename?: 'CompanyMessage' }
        & CounselRoomMessage_CompanyMessage_Fragment
      ) | (
        { __typename?: 'DeletedMessage' }
        & CounselRoomMessage_DeletedMessage_Fragment
      ) | (
        { __typename?: 'EndMessage' }
        & CounselRoomMessage_EndMessage_Fragment
      ) | (
        { __typename?: 'EnterMessage' }
        & CounselRoomMessage_EnterMessage_Fragment
      ) | (
        { __typename?: 'EstimateMessage' }
        & CounselRoomMessage_EstimateMessage_Fragment
      ) | (
        { __typename?: 'FileMessage' }
        & CounselRoomMessage_FileMessage_Fragment
      ) | (
        { __typename?: 'InvoiceMessage' }
        & CounselRoomMessage_InvoiceMessage_Fragment
      ) | (
        { __typename?: 'LeaveMessage' }
        & CounselRoomMessage_LeaveMessage_Fragment
      ) | (
        { __typename?: 'PlateNumberInputMessage' }
        & CounselRoomMessage_PlateNumberInputMessage_Fragment
      ) | (
        { __typename?: 'QueueMessage' }
        & CounselRoomMessage_QueueMessage_Fragment
      ) | (
        { __typename?: 'RestartMessage' }
        & CounselRoomMessage_RestartMessage_Fragment
      ) | (
        { __typename?: 'SuspendedMessage' }
        & CounselRoomMessage_SuspendedMessage_Fragment
      ) | (
        { __typename?: 'TextMessage' }
        & CounselRoomMessage_TextMessage_Fragment
      ) | (
        { __typename?: 'VinInputMessage' }
        & CounselRoomMessage_VinInputMessage_Fragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  ), counselee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )> }
);

export type LoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loggedIn'>
);

export type AddUserToCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type AddUserToCounselMutation = (
  { __typename?: 'Mutation' }
  & { addUserToCounsel: (
    { __typename?: 'AddUserToCounselSuccess' }
    & { user: (
      { __typename?: 'User' }
      & CounselWindowUserFragment
    ) }
  ) }
);

export type RemoveUserFromCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveUserFromCounselMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromCounsel: (
    { __typename?: 'RemoveUserFromCounselSuccess' }
    & { user: (
      { __typename?: 'User' }
      & CounselWindowUserFragment
    ) }
  ) }
);

export type CounselorsQueryVariables = Exact<{ [key: string]: never; }>;


export type CounselorsQuery = (
  { __typename?: 'Query' }
  & { counselors: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type CounselInfoQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounselInfoQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'index' | 'status' | 'bookmarkedUntil' | 'body'>
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'age' | 'gender' | 'nickname' | 'phone'>
      & { avatar?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )>, lastDevice?: Maybe<(
        { __typename?: 'Device' }
        & Pick<Device, 'brand' | 'model' | 'os' | 'osVersion' | 'appVersion'>
      )> }
    )>, members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
      & { avatar?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    )>, files: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'url' | 'description'>
    ) | (
      { __typename?: 'Video' }
      & Pick<Video, 'url' | 'description'>
    )>, type?: Maybe<(
      { __typename?: 'InquiryType' }
      & Pick<InquiryType, 'id' | 'name'>
    )>, areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'radius'>
      & { zone?: Maybe<(
        { __typename?: 'Zone' }
        & Pick<Zone, 'province' | 'district' | 'neighborhood'>
      )> }
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'registeredYear' | 'mileage' | 'plateNumber' | 'fullModelName' | 'vin'>
      & { avatar?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )>, fuelType?: Maybe<(
        { __typename?: 'FuelType' }
        & Pick<FuelType, 'base' | 'hybrid'>
      )>, grade?: Maybe<(
        { __typename?: 'VehicleGrade' }
        & Pick<VehicleGrade, 'displacement'>
      )>, tireSize?: Maybe<(
        { __typename?: 'VehicleTireSize' }
        & { front: (
          { __typename?: 'TireSize' }
          & Pick<TireSize, 'aspectRatio' | 'wheelDiameter' | 'width'>
        ), rear: (
          { __typename?: 'TireSize' }
          & Pick<TireSize, 'aspectRatio' | 'wheelDiameter' | 'width'>
        ) }
      )> }
    )> }
  ) }
);

export type CounselMessagesQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounselMessagesQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { messageConnection: (
      { __typename?: 'MessageConnection' }
      & { edges: Array<(
        { __typename?: 'MessageEdge' }
        & { node: (
          { __typename?: 'AppReviewMessage' }
          & CounselRoomMessage_AppReviewMessage_Fragment
        ) | (
          { __typename?: 'AreaInputMessage' }
          & CounselRoomMessage_AreaInputMessage_Fragment
        ) | (
          { __typename?: 'CompanyMessage' }
          & CounselRoomMessage_CompanyMessage_Fragment
        ) | (
          { __typename?: 'DeletedMessage' }
          & CounselRoomMessage_DeletedMessage_Fragment
        ) | (
          { __typename?: 'EndMessage' }
          & CounselRoomMessage_EndMessage_Fragment
        ) | (
          { __typename?: 'EnterMessage' }
          & CounselRoomMessage_EnterMessage_Fragment
        ) | (
          { __typename?: 'EstimateMessage' }
          & CounselRoomMessage_EstimateMessage_Fragment
        ) | (
          { __typename?: 'FileMessage' }
          & CounselRoomMessage_FileMessage_Fragment
        ) | (
          { __typename?: 'InvoiceMessage' }
          & CounselRoomMessage_InvoiceMessage_Fragment
        ) | (
          { __typename?: 'LeaveMessage' }
          & CounselRoomMessage_LeaveMessage_Fragment
        ) | (
          { __typename?: 'PlateNumberInputMessage' }
          & CounselRoomMessage_PlateNumberInputMessage_Fragment
        ) | (
          { __typename?: 'QueueMessage' }
          & CounselRoomMessage_QueueMessage_Fragment
        ) | (
          { __typename?: 'RestartMessage' }
          & CounselRoomMessage_RestartMessage_Fragment
        ) | (
          { __typename?: 'SuspendedMessage' }
          & CounselRoomMessage_SuspendedMessage_Fragment
        ) | (
          { __typename?: 'TextMessage' }
          & CounselRoomMessage_TextMessage_Fragment
        ) | (
          { __typename?: 'VinInputMessage' }
          & CounselRoomMessage_VinInputMessage_Fragment
        ) }
      )> }
    ) }
  ) }
);

export type CounselRoomQueryVariables = Exact<{
  counselId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type CounselRoomQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & CounselRoomFragment
  ) }
);

export type CompanyMessageQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyMessageQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'contact' | 'extraContact'>
    & { specialties: Array<(
      { __typename?: 'CompanySpecialty' }
      & Pick<CompanySpecialty, 'id' | 'name'>
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type QueueCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
  reason?: Maybe<QueueReason>;
}>;


export type QueueCounselMutation = (
  { __typename?: 'Mutation' }
  & { queueCounsel: (
    { __typename?: 'QueueCounselSuccess' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status' | 'queueReason' | 'queuedFrom' | 'bookmarkedUntil'>
    ) }
  ) }
);

export type UnqueueCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type UnqueueCounselMutation = (
  { __typename?: 'Mutation' }
  & { unqueueCounsel: (
    { __typename?: 'UnqueueCounselSuccess' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status' | 'queueReason' | 'queuedFrom' | 'bookmarkedUntil'>
    ) }
  ) }
);

export type FinishCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type FinishCounselMutation = (
  { __typename?: 'Mutation' }
  & { finishCounsel: (
    { __typename?: 'FinishCounselSuccess' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id'>
    ) }
  ) }
);

export type SendAppReviewMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type SendAppReviewMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendAppReviewMessage: (
    { __typename?: 'SendAppReviewMessageSuccess' }
    & { message: (
      { __typename?: 'AppReviewMessage' }
      & Pick<AppReviewMessage, 'id'>
    ) }
  ) }
);

export type RestartCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type RestartCounselMutation = (
  { __typename?: 'Mutation' }
  & { restartCounsel: (
    { __typename?: 'RestartCounselSuccess' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
  ) }
);

export type StopEstimationAndStartCounselMutationVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type StopEstimationAndStartCounselMutation = (
  { __typename?: 'Mutation' }
  & { stopEstimationAndStartCounsel: { __typename?: 'StopEstimationAndStartCounselFail' } | (
    { __typename?: 'StopEstimationAndStartCounselSuccess' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
  ) }
);

export type MessageInputQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type MessageInputQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'status' | 'queueReason' | 'queuedFrom' | 'bookmarkedUntil'>
    & { orders: Array<(
      { __typename?: 'InsurancedOrder' }
      & Pick<InsurancedOrder, 'id' | 'expiresAt'>
    ) | (
      { __typename?: 'NoneInsurancedOrder' }
      & Pick<NoneInsurancedOrder, 'id' | 'expiresAt'>
    ) | (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'expiresAt'>
    )> }
  ) }
);

export type InquiryTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type InquiryTypesQuery = (
  { __typename?: 'Query' }
  & { inquiryTypes: Array<(
    { __typename?: 'InquiryType' }
    & Pick<InquiryType, 'id' | 'name'>
  )> }
);

export type FinishedCounselsResultFragment = (
  { __typename?: 'Counsel' }
  & Pick<Counsel, 'id' | 'index' | 'status' | 'finishedAt' | 'createdAt' | 'insurance' | 'memo'>
  & { orders: Array<(
    { __typename?: 'InsurancedOrder' }
    & Pick<InsurancedOrder, 'paymentAmount'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'status' | 'amount'>
    )>, shipping?: Maybe<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'destination'>
    )> }
  ) | (
    { __typename?: 'NoneInsurancedOrder' }
    & Pick<NoneInsurancedOrder, 'paymentAmount'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'status' | 'amount'>
    )>, shipping?: Maybe<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'destination'>
    )> }
  ) | (
    { __typename?: 'Order' }
    & Pick<Order, 'paymentAmount'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'status' | 'amount'>
    )>, shipping?: Maybe<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'destination'>
    )> }
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'plateNumber' | 'fullModelName'>
    & { brand: (
      { __typename?: 'VehicleBrand' }
      & Pick<VehicleBrand, 'id'>
      & { logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    ), fuelType?: Maybe<(
      { __typename?: 'FuelType' }
      & Pick<FuelType, 'base' | 'hybrid'>
    )> }
  )>, post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
    & { hashtags: Array<(
      { __typename?: 'PostHashtag' }
      & Pick<PostHashtag, 'id' | 'name'>
    )> }
  )>, type?: Maybe<(
    { __typename?: 'InquiryType' }
    & Pick<InquiryType, 'id' | 'name'>
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, members: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type FinishedCounselConnectionQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter: FinishedCounselConnectionFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type FinishedCounselConnectionQuery = (
  { __typename?: 'Query' }
  & { finishedCounselConnection: (
    { __typename?: 'CounselConnection' }
    & { edges: Array<(
      { __typename?: 'CounselEdge' }
      & Pick<CounselEdge, 'cursor'>
      & { node: (
        { __typename?: 'Counsel' }
        & FinishedCounselsResultFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type OngoingCounselsResultFragment = (
  { __typename?: 'Counsel' }
  & Pick<Counsel, 'id' | 'index' | 'status' | 'createdAt' | 'memo' | 'unreadCount' | 'insurance'>
  & { areas: Array<(
    { __typename?: 'Area' }
    & { zone?: Maybe<(
      { __typename?: 'Zone' }
      & Pick<Zone, 'province' | 'district' | 'neighborhood'>
    )> }
  )>, type?: Maybe<(
    { __typename?: 'InquiryType' }
    & Pick<InquiryType, 'id' | 'name'>
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'plateNumber' | 'fullModelName'>
    & { brand: (
      { __typename?: 'VehicleBrand' }
      & Pick<VehicleBrand, 'id'>
      & { logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    ), fuelType?: Maybe<(
      { __typename?: 'FuelType' }
      & Pick<FuelType, 'base' | 'hybrid'>
    )> }
  )>, messageConnection: (
    { __typename: 'MessageConnection' }
    & { edges: Array<(
      { __typename?: 'MessageEdge' }
      & { node: (
        { __typename?: 'AppReviewMessage' }
        & CounselTableRowMessage_AppReviewMessage_Fragment
      ) | (
        { __typename?: 'AreaInputMessage' }
        & CounselTableRowMessage_AreaInputMessage_Fragment
      ) | (
        { __typename?: 'CompanyMessage' }
        & CounselTableRowMessage_CompanyMessage_Fragment
      ) | (
        { __typename?: 'DeletedMessage' }
        & CounselTableRowMessage_DeletedMessage_Fragment
      ) | (
        { __typename?: 'EndMessage' }
        & CounselTableRowMessage_EndMessage_Fragment
      ) | (
        { __typename?: 'EnterMessage' }
        & CounselTableRowMessage_EnterMessage_Fragment
      ) | (
        { __typename?: 'EstimateMessage' }
        & CounselTableRowMessage_EstimateMessage_Fragment
      ) | (
        { __typename?: 'FileMessage' }
        & CounselTableRowMessage_FileMessage_Fragment
      ) | (
        { __typename?: 'InvoiceMessage' }
        & CounselTableRowMessage_InvoiceMessage_Fragment
      ) | (
        { __typename?: 'LeaveMessage' }
        & CounselTableRowMessage_LeaveMessage_Fragment
      ) | (
        { __typename?: 'PlateNumberInputMessage' }
        & CounselTableRowMessage_PlateNumberInputMessage_Fragment
      ) | (
        { __typename?: 'QueueMessage' }
        & CounselTableRowMessage_QueueMessage_Fragment
      ) | (
        { __typename?: 'RestartMessage' }
        & CounselTableRowMessage_RestartMessage_Fragment
      ) | (
        { __typename?: 'SuspendedMessage' }
        & CounselTableRowMessage_SuspendedMessage_Fragment
      ) | (
        { __typename?: 'TextMessage' }
        & CounselTableRowMessage_TextMessage_Fragment
      ) | (
        { __typename?: 'VinInputMessage' }
        & CounselTableRowMessage_VinInputMessage_Fragment
      ) }
    )> }
  ), post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
    & { hashtags: Array<(
      { __typename?: 'PostHashtag' }
      & Pick<PostHashtag, 'id' | 'name'>
    )> }
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, members: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type OngoingCounselsQueryVariables = Exact<{ [key: string]: never; }>;


export type OngoingCounselsQuery = (
  { __typename?: 'Query' }
  & { ongoingCounsels: Array<(
    { __typename?: 'Counsel' }
    & OngoingCounselsResultFragment
  )> }
);

export type QueuedCounselsResultFragment = (
  { __typename?: 'Counsel' }
  & Pick<Counsel, 'id' | 'index' | 'status' | 'unreadCount' | 'createdAt' | 'memo' | 'queueReason'>
  & { areas: Array<(
    { __typename?: 'Area' }
    & { zone?: Maybe<(
      { __typename?: 'Zone' }
      & Pick<Zone, 'province' | 'district' | 'neighborhood'>
    )> }
  )>, type?: Maybe<(
    { __typename?: 'InquiryType' }
    & Pick<InquiryType, 'id' | 'name'>
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'plateNumber' | 'fullModelName'>
    & { fuelType?: Maybe<(
      { __typename?: 'FuelType' }
      & Pick<FuelType, 'base' | 'hybrid'>
    )> }
  )>, messageConnection: (
    { __typename: 'MessageConnection' }
    & { edges: Array<(
      { __typename?: 'MessageEdge' }
      & { node: (
        { __typename?: 'AppReviewMessage' }
        & CounselTableRowMessage_AppReviewMessage_Fragment
      ) | (
        { __typename?: 'AreaInputMessage' }
        & CounselTableRowMessage_AreaInputMessage_Fragment
      ) | (
        { __typename?: 'CompanyMessage' }
        & CounselTableRowMessage_CompanyMessage_Fragment
      ) | (
        { __typename?: 'DeletedMessage' }
        & CounselTableRowMessage_DeletedMessage_Fragment
      ) | (
        { __typename?: 'EndMessage' }
        & CounselTableRowMessage_EndMessage_Fragment
      ) | (
        { __typename?: 'EnterMessage' }
        & CounselTableRowMessage_EnterMessage_Fragment
      ) | (
        { __typename?: 'EstimateMessage' }
        & CounselTableRowMessage_EstimateMessage_Fragment
      ) | (
        { __typename?: 'FileMessage' }
        & CounselTableRowMessage_FileMessage_Fragment
      ) | (
        { __typename?: 'InvoiceMessage' }
        & CounselTableRowMessage_InvoiceMessage_Fragment
      ) | (
        { __typename?: 'LeaveMessage' }
        & CounselTableRowMessage_LeaveMessage_Fragment
      ) | (
        { __typename?: 'PlateNumberInputMessage' }
        & CounselTableRowMessage_PlateNumberInputMessage_Fragment
      ) | (
        { __typename?: 'QueueMessage' }
        & CounselTableRowMessage_QueueMessage_Fragment
      ) | (
        { __typename?: 'RestartMessage' }
        & CounselTableRowMessage_RestartMessage_Fragment
      ) | (
        { __typename?: 'SuspendedMessage' }
        & CounselTableRowMessage_SuspendedMessage_Fragment
      ) | (
        { __typename?: 'TextMessage' }
        & CounselTableRowMessage_TextMessage_Fragment
      ) | (
        { __typename?: 'VinInputMessage' }
        & CounselTableRowMessage_VinInputMessage_Fragment
      ) }
    )> }
  ), creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )>, members: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type QueuedCounselsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueuedCounselsQuery = (
  { __typename?: 'Query' }
  & { queuedCounsels: Array<(
    { __typename?: 'Counsel' }
    & QueuedCounselsResultFragment
  )> }
);

export type UpdatedCounselSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type UpdatedCounselSubscription = (
  { __typename?: 'Subscription' }
  & { updatedCounsel: (
    { __typename?: 'Counsel' }
    & CounselTableRowFragment
  ) }
);

export type LocalCounselQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type LocalCounselQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'status'>
  ) }
);

export type NewCounselSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewCounselSubscription = (
  { __typename?: 'Subscription' }
  & { newCounsel: (
    { __typename?: 'Counsel' }
    & CounselTableRowFragment
  ) }
);

export type InvitedCounselSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InvitedCounselSubscription = (
  { __typename?: 'Subscription' }
  & { invitedCounsel: (
    { __typename?: 'Counsel' }
    & CounselTableRowFragment
  ) }
);

export type LeavedCounselSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LeavedCounselSubscription = (
  { __typename?: 'Subscription' }
  & { leavedCounsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'status'>
  ) }
);

export type NewMessageSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewMessageSubscription = (
  { __typename?: 'Subscription' }
  & { newMessage: (
    { __typename?: 'AppReviewMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_AppReviewMessage_Fragment
  ) | (
    { __typename?: 'AreaInputMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_AreaInputMessage_Fragment
  ) | (
    { __typename?: 'CompanyMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_CompanyMessage_Fragment
  ) | (
    { __typename?: 'DeletedMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_DeletedMessage_Fragment
  ) | (
    { __typename?: 'EndMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_EndMessage_Fragment
  ) | (
    { __typename?: 'EnterMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_EnterMessage_Fragment
  ) | (
    { __typename?: 'EstimateMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_EstimateMessage_Fragment
  ) | (
    { __typename?: 'FileMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_FileMessage_Fragment
  ) | (
    { __typename?: 'InvoiceMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_InvoiceMessage_Fragment
  ) | (
    { __typename?: 'LeaveMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_LeaveMessage_Fragment
  ) | (
    { __typename?: 'PlateNumberInputMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_PlateNumberInputMessage_Fragment
  ) | (
    { __typename?: 'QueueMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_QueueMessage_Fragment
  ) | (
    { __typename?: 'RestartMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_RestartMessage_Fragment
  ) | (
    { __typename?: 'SuspendedMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_SuspendedMessage_Fragment
  ) | (
    { __typename?: 'TextMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_TextMessage_Fragment
  ) | (
    { __typename?: 'VinInputMessage' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'status'>
    ) }
    & CounselTableRowMessage_VinInputMessage_Fragment
  ) }
);

export type StatusChangedCounselSubscriptionVariables = Exact<{
  counselId?: Maybe<Scalars['ID']>;
}>;


export type StatusChangedCounselSubscription = (
  { __typename?: 'Subscription' }
  & { statusChangedCounsel: (
    { __typename?: 'CounselStatusChange' }
    & Pick<CounselStatusChange, 'from' | 'to'>
    & { counsel: (
      { __typename?: 'Counsel' }
      & CounselTableRowFragment
    ) }
  ) }
);

export type HashtagsQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;


export type HashtagsQuery = (
  { __typename?: 'Query' }
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
    & { hashtags: Array<(
      { __typename?: 'PostHashtag' }
      & Pick<PostHashtag, 'id' | 'name'>
    )> }
  )> }
);

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  data: UpdateUserData;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateMe: (
    { __typename?: 'UpdateUserSuccess' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
      & { avatar?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    ) }
  ) }
);

export type SettingDrawerQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingDrawerQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  ) }
);

export type UpdateCounselMemoMutationVariables = Exact<{
  counselId: Scalars['ID'];
  data: UpdateCounselData;
}>;


export type UpdateCounselMemoMutation = (
  { __typename?: 'Mutation' }
  & { updateCounsel: (
    { __typename?: 'UpdateCounselSuccess' }
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id' | 'memo'>
    ) }
  ) }
);

export type UpdatedCounselDetailSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type UpdatedCounselDetailSubscription = (
  { __typename?: 'Subscription' }
  & { updatedCounsel: (
    { __typename?: 'Counsel' }
    & CounselDetailInfoFragment
  ) }
);

export type CounselDetailQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounselDetailQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'createdAt' | 'insurance' | 'memo' | 'body' | 'status'>
    & { type?: Maybe<(
      { __typename?: 'InquiryType' }
      & Pick<InquiryType, 'id' | 'name'>
    )>, files: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'url' | 'description'>
    ) | (
      { __typename?: 'Video' }
      & Pick<Video, 'url' | 'description'>
    )>, areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'radius'>
      & { zone?: Maybe<(
        { __typename?: 'Zone' }
        & Pick<Zone, 'province' | 'district' | 'neighborhood'>
      )>, coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'latitude' | 'longitude'>
      ) }
    )>, post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'title' | 'body'>
      & { hashtags: Array<(
        { __typename?: 'PostHashtag' }
        & Pick<PostHashtag, 'id' | 'name'>
      )>, files: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'url' | 'description'>
      ) | (
        { __typename?: 'Video' }
        & Pick<Video, 'url' | 'description'>
      )>, areas: Array<(
        { __typename?: 'Area' }
        & Pick<Area, 'radius'>
        & { zone?: Maybe<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'province' | 'district' | 'neighborhood'>
        )>, coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<Coordinates, 'latitude' | 'longitude'>
        ) }
      )> }
    )>, counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
      & { inactiveCounselConnection: (
        { __typename?: 'CounselConnection' }
        & { edges: Array<(
          { __typename?: 'CounselEdge' }
          & { node: (
            { __typename?: 'Counsel' }
            & Pick<Counsel, 'id' | 'index' | 'memo' | 'createdAt'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type CreateEmptyOrderMutationVariables = Exact<{
  userId: Scalars['ID'];
  name: Scalars['String'];
  counselId: Scalars['ID'];
  vehicleId: Scalars['ID'];
}>;


export type CreateEmptyOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'CreateOrderSuccess' }
    & { order: (
      { __typename?: 'InsurancedOrder' }
      & TaskpadOrder_InsurancedOrder_Fragment
    ) | (
      { __typename?: 'NoneInsurancedOrder' }
      & TaskpadOrder_NoneInsurancedOrder_Fragment
    ) | (
      { __typename?: 'Order' }
      & TaskpadOrder_Order_Fragment
    ) }
  ) }
);

export type DeleteOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder: (
    { __typename?: 'DeleteOrderSuccess' }
    & { order: (
      { __typename?: 'InsurancedOrder' }
      & Pick<InsurancedOrder, 'id'>
    ) | (
      { __typename?: 'NoneInsurancedOrder' }
      & Pick<NoneInsurancedOrder, 'id'>
    ) | (
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    ) }
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  data: UpdateOrderData;
  orderId: Scalars['ID'];
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'UpdateOrderSuccess' }
    & { order: (
      { __typename: 'InsurancedOrder' }
      & Pick<InsurancedOrder, 'cashbackAmount' | 'cashbackRate' | 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
        & { brand: (
          { __typename?: 'VehicleBrand' }
          & Pick<VehicleBrand, 'id' | 'name'>
        ) }
      )>, items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
      )>, shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
      )> }
    ) | (
      { __typename: 'NoneInsurancedOrder' }
      & Pick<NoneInsurancedOrder, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
        & { brand: (
          { __typename?: 'VehicleBrand' }
          & Pick<VehicleBrand, 'id' | 'name'>
        ) }
      )>, items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
      )>, shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
      )> }
    ) | (
      { __typename: 'Order' }
      & Pick<Order, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
        & { brand: (
          { __typename?: 'VehicleBrand' }
          & Pick<VehicleBrand, 'id' | 'name'>
        ) }
      )>, items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
      )>, shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
      )> }
    ) }
  ) }
);

export type SendEstimateMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  orderId: Scalars['ID'];
}>;


export type SendEstimateMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendEstimateMessage: (
    { __typename?: 'SendEstimateMessageSuccess' }
    & { message: (
      { __typename?: 'EstimateMessage' }
      & CounselRoomMessage_EstimateMessage_Fragment
    ) }
  ) }
);

export type CreateShippingMutationVariables = Exact<{
  data: CreateShippingData;
  orderId: Scalars['ID'];
}>;


export type CreateShippingMutation = (
  { __typename?: 'Mutation' }
  & { createShipping: (
    { __typename?: 'CreateShippingSuccess' }
    & { shipping: (
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id'>
    ) }
  ) }
);

export type SendInvoiceMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  orderId: Scalars['ID'];
}>;


export type SendInvoiceMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendInvoiceMessage: (
    { __typename?: 'SendInvoiceMessageSuccess' }
    & { message: (
      { __typename?: 'InvoiceMessage' }
      & CounselRoomMessage_InvoiceMessage_Fragment
    ) }
  ) }
);

export type CancelPaymentMutationVariables = Exact<{
  paymentId: Scalars['String'];
  reason: Scalars['String'];
}>;


export type CancelPaymentMutation = (
  { __typename?: 'Mutation' }
  & { cancelPayment: (
    { __typename?: 'CancelPaymentSuccess' }
    & { payment: (
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'amount' | 'cancelledAt'>
    ) }
  ) }
);

export type UpdateShippingMutationVariables = Exact<{
  data: UpdateShippingData;
  shippingId: Scalars['ID'];
}>;


export type UpdateShippingMutation = (
  { __typename?: 'Mutation' }
  & { updateShipping: (
    { __typename?: 'UpdateShippingSuccess' }
    & { shipping: (
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier'>
    ) }
  ) }
);

export type ForceCompletePaymentMutationVariables = Exact<{
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
}>;


export type ForceCompletePaymentMutation = (
  { __typename?: 'Mutation' }
  & { forceCompletePayment: (
    { __typename?: 'PaymentCompleted' }
    & { payment: (
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'status'>
    ) }
  ) | (
    { __typename?: 'PaymentUncompleted' }
    & { payment: (
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'status' | 'amount' | 'completedAt'>
    ) }
  ) }
);

export type UpsertItemsMutationVariables = Exact<{
  items: Array<CreateItemData> | CreateItemData;
}>;


export type UpsertItemsMutation = (
  { __typename?: 'Mutation' }
  & { upsertItems: (
    { __typename?: 'UpsertItemsSuccess' }
    & { items: Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'name'>
    )> }
  ) }
);

export type CounselOrderQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounselOrderQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & { sentCompanies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'contact'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'road' | 'region'>
      ) }
    )>, orders: Array<(
      { __typename: 'InsurancedOrder' }
      & Pick<InsurancedOrder, 'cashbackAmount' | 'cashbackRate' | 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
        & { brand: (
          { __typename?: 'VehicleBrand' }
          & Pick<VehicleBrand, 'id' | 'name'>
        ) }
      )>, items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
      )>, shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
      )> }
    ) | (
      { __typename: 'NoneInsurancedOrder' }
      & Pick<NoneInsurancedOrder, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
        & { brand: (
          { __typename?: 'VehicleBrand' }
          & Pick<VehicleBrand, 'id' | 'name'>
        ) }
      )>, items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
      )>, shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
      )> }
    ) | (
      { __typename: 'Order' }
      & Pick<Order, 'id' | 'amount' | 'createdAt' | 'expiresAt' | 'discountAmount' | 'totalItemAmount' | 'totalAmount' | 'paymentAmount' | 'name' | 'shippingCharge' | 'recommendedReason' | 'rejectedReason'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
        & { brand: (
          { __typename?: 'VehicleBrand' }
          & Pick<VehicleBrand, 'id' | 'name'>
        ) }
      )>, items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'code' | 'name' | 'discountRate' | 'price' | 'quantity' | 'totalAmount' | 'paymentAmount'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
      )>, shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id' | 'status' | 'trackingId' | 'contact' | 'destination' | 'method' | 'recipient' | 'carrier' | 'deliveredAt'>
      )> }
    )> }
    & CounselDetailInfoFragment
  ) }
);

export type CompletedPaymentSubscriptionVariables = Exact<{
  paymentId: Scalars['ID'];
}>;


export type CompletedPaymentSubscription = (
  { __typename?: 'Subscription' }
  & { completedPayment: (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'completedAt' | 'cancelledAt' | 'status'>
    & { order: (
      { __typename?: 'InsurancedOrder' }
      & Pick<InsurancedOrder, 'id'>
      & { shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id'>
      )> }
    ) | (
      { __typename?: 'NoneInsurancedOrder' }
      & Pick<NoneInsurancedOrder, 'id'>
      & { shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id'>
      )> }
    ) | (
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
      & { shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'id'>
      )> }
    ) }
  ) }
);

export type CompaniesSearchQueryVariables = Exact<{
  filter: CompaniesFilter;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type CompaniesSearchQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'companiesCount'>
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'contact'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'road' | 'region'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'latitude' | 'longitude'>
      ) }
    ), specialties: Array<(
      { __typename?: 'CompanySpecialty' }
      & Pick<CompanySpecialty, 'id' | 'name'>
    )> }
  )> }
);

export type CounseleeLastCounselsQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounseleeLastCounselsQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'status'>
    & { areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'radius'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'latitude' | 'longitude'>
      ), zone?: Maybe<(
        { __typename?: 'Zone' }
        & Pick<Zone, 'district' | 'neighborhood' | 'province'>
      )> }
    )>, counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { inactiveCounselConnection: (
        { __typename?: 'CounselConnection' }
        & { edges: Array<(
          { __typename?: 'CounselEdge' }
          & { node: (
            { __typename?: 'Counsel' }
            & Pick<Counsel, 'id' | 'index' | 'memo' | 'createdAt'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type CompanySpecialtiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanySpecialtiesQuery = (
  { __typename?: 'Query' }
  & { companySpecialties: Array<(
    { __typename?: 'Specialty' }
    & Pick<Specialty, 'id' | 'name'>
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  data: CreateTemplateData;
  directoryId: Scalars['ID'];
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate: (
    { __typename?: 'CreateTemplateSuccess' }
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'id'>
    ) }
  ) }
);

export type TemplatePathQueryVariables = Exact<{
  directoryId: Scalars['ID'];
}>;


export type TemplatePathQuery = (
  { __typename?: 'Query' }
  & { templateDirectory: (
    { __typename?: 'TemplateDirectory' }
    & Pick<TemplateDirectory, 'id'>
    & { path: Array<(
      { __typename?: 'TemplateDirectory' }
      & Pick<TemplateDirectory, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateTemplateMutationVariables = Exact<{
  data: UpdateTemplateData;
  templateId: Scalars['ID'];
}>;


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate: (
    { __typename?: 'UpdateTemplateSuccess' }
    & { template: (
      { __typename?: 'Template' }
      & TemplateDetailFragment
    ) }
  ) }
);

export type DeleteTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemplate: (
    { __typename?: 'DeleteTemplateSuccess' }
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'id'>
    ) }
  ) }
);

export type CounselStatusQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounselStatusQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id' | 'status'>
  ) }
);

export type TemplateDirectoryQueryVariables = Exact<{
  directoryId: Scalars['ID'];
}>;


export type TemplateDirectoryQuery = (
  { __typename?: 'Query' }
  & { templateDirectory: (
    { __typename?: 'TemplateDirectory' }
    & Pick<TemplateDirectory, 'id' | 'name'>
    & { path: Array<(
      { __typename?: 'TemplateDirectory' }
      & Pick<TemplateDirectory, 'id' | 'name'>
    )>, parent?: Maybe<(
      { __typename?: 'TemplateDirectory' }
      & Pick<TemplateDirectory, 'id'>
    )>, templates: Array<(
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name'>
    )>, subdirectories: Array<(
      { __typename?: 'TemplateDirectory' }
      & Pick<TemplateDirectory, 'id' | 'name'>
      & { subdirectories: Array<(
        { __typename?: 'TemplateDirectory' }
        & Pick<TemplateDirectory, 'id'>
      )>, templates: Array<(
        { __typename?: 'Template' }
        & Pick<Template, 'id'>
      )> }
    )> }
  ) }
);

export type TemplateShortcutsQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type TemplateShortcutsQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { type?: Maybe<(
      { __typename?: 'InquiryType' }
      & Pick<InquiryType, 'id' | 'name'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'fullModelName'>
      & { brand: (
        { __typename?: 'VehicleBrand' }
        & Pick<VehicleBrand, 'id'>
      ), model: (
        { __typename?: 'VehicleModel' }
        & Pick<VehicleModel, 'id'>
      ), submodel?: Maybe<(
        { __typename?: 'VehicleSubmodel' }
        & Pick<VehicleSubmodel, 'id'>
      )>, grade?: Maybe<(
        { __typename?: 'VehicleGrade' }
        & Pick<VehicleGrade, 'id'>
      )>, trim?: Maybe<(
        { __typename?: 'VehicleTrim' }
        & Pick<VehicleTrim, 'id'>
      )> }
    )> }
  ) }
);

export type TemplateDetailFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'name'>
  & { path: Array<(
    { __typename?: 'TemplateDirectory' }
    & Pick<TemplateDirectory, 'id' | 'name'>
  )>, messages: Array<(
    { __typename?: 'FileMessageContent' }
    & { file: (
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
      & { metadata?: Maybe<(
        { __typename?: 'ImageMetadata' }
        & Pick<ImageMetadata, 'width' | 'height'>
      )> }
    ) | (
      { __typename?: 'Video' }
      & Pick<Video, 'url'>
      & { metadata?: Maybe<(
        { __typename?: 'VideoMetadata' }
        & Pick<VideoMetadata, 'width' | 'height' | 'duration'>
      )> }
    ) }
  ) | (
    { __typename?: 'TextMessageContent' }
    & Pick<TextMessageContent, 'body'>
  )> }
);

export type TemplateQueryVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type TemplateQuery = (
  { __typename?: 'Query' }
  & { template: (
    { __typename?: 'Template' }
    & TemplateDetailFragment
  ) }
);

export type TemplateFileUploadQueryVariables = Exact<{
  file: FileUploadInput;
}>;


export type TemplateFileUploadQuery = (
  { __typename?: 'Query' }
  & { userContentFileUpload: (
    { __typename?: 'Upload' }
    & Pick<Upload, 'uploadUrl' | 'fileUrl'>
  ) }
);

export type VehicleCounselQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type VehicleCounselQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { counselee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'fullModelName' | 'shortModelName' | 'plateNumber' | 'vin' | 'mileage'>
      & { model: (
        { __typename?: 'VehicleModel' }
        & Pick<VehicleModel, 'id'>
      ), grade?: Maybe<(
        { __typename?: 'VehicleGrade' }
        & Pick<VehicleGrade, 'segment'>
      )>, fuelType?: Maybe<(
        { __typename?: 'FuelType' }
        & Pick<FuelType, 'base' | 'hybrid'>
      )>, tireSize?: Maybe<(
        { __typename?: 'VehicleTireSize' }
        & { front: (
          { __typename?: 'TireSize' }
          & Pick<TireSize, 'width' | 'aspectRatio' | 'wheelDiameter'>
        ), rear: (
          { __typename?: 'TireSize' }
          & Pick<TireSize, 'width' | 'aspectRatio' | 'wheelDiameter'>
        ) }
      )> }
    )> }
  ) }
);

export type UpdateVehicleMutationVariables = Exact<{
  vehicleId: Scalars['ID'];
  data: UpdateVehicleData;
}>;


export type UpdateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicle: (
    { __typename?: 'UpdateVehicleFail' }
    & { error: never }
  ) | (
    { __typename?: 'UpdateVehicleSuccess' }
    & { vehicle: (
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'fullModelName' | 'shortModelName' | 'plateNumber' | 'vin' | 'mileage'>
      & { model: (
        { __typename?: 'VehicleModel' }
        & Pick<VehicleModel, 'id'>
      ), grade?: Maybe<(
        { __typename?: 'VehicleGrade' }
        & Pick<VehicleGrade, 'segment'>
      )>, tireSize?: Maybe<(
        { __typename?: 'VehicleTireSize' }
        & { front: (
          { __typename?: 'TireSize' }
          & Pick<TireSize, 'width' | 'aspectRatio' | 'wheelDiameter'>
        ), rear: (
          { __typename?: 'TireSize' }
          & Pick<TireSize, 'width' | 'aspectRatio' | 'wheelDiameter'>
        ) }
      )> }
    ) }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { localLogin?: Maybe<(
    { __typename?: 'LoginSuccess' }
    & Pick<LoginSuccess, 'accessToken' | 'expiresAt'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type CachedOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type CachedOrderQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'InsurancedOrder' }
    & TaskpadOrder_InsurancedOrder_Fragment
  ) | (
    { __typename?: 'NoneInsurancedOrder' }
    & TaskpadOrder_NoneInsurancedOrder_Fragment
  ) | (
    { __typename?: 'Order' }
    & TaskpadOrder_Order_Fragment
  ) }
);

export type CachedCounselDetailQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CachedCounselDetailQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & Pick<Counsel, 'id'>
    & { orders: Array<(
      { __typename?: 'InsurancedOrder' }
      & TaskpadOrder_InsurancedOrder_Fragment
    ) | (
      { __typename?: 'NoneInsurancedOrder' }
      & TaskpadOrder_NoneInsurancedOrder_Fragment
    ) | (
      { __typename?: 'Order' }
      & TaskpadOrder_Order_Fragment
    )> }
    & CounselDetailInfoFragment
  ) }
);

export type FileUploadUrlQueryVariables = Exact<{
  file: FileUploadInput;
}>;


export type FileUploadUrlQuery = (
  { __typename?: 'Query' }
  & { userContentFileUpload: (
    { __typename?: 'Upload' }
    & Pick<Upload, 'uploadUrl' | 'fileUrl'>
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'role' | 'nickname'>
    & { avatar?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  ) }
);

export type SendTextMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  body: Scalars['String'];
}>;


export type SendTextMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendTextMessage: (
    { __typename?: 'SendTextMessageSuccess' }
    & { message: (
      { __typename?: 'TextMessage' }
      & CounselRoomMessage_TextMessage_Fragment
    ) }
  ) }
);

export type SendFileMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  file: FileInput;
}>;


export type SendFileMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendFileMessage: (
    { __typename?: 'SendFileMessageSuccess' }
    & { message: (
      { __typename?: 'FileMessage' }
      & CounselRoomMessage_FileMessage_Fragment
    ) }
  ) }
);

export type SendCompanyMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  companyId: Scalars['ID'];
}>;


export type SendCompanyMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendCompanyMessage: (
    { __typename?: 'SendCompanyMessageSuccess' }
    & { message: (
      { __typename?: 'CompanyMessage' }
      & CounselRoomMessage_CompanyMessage_Fragment
    ) }
  ) }
);

export type SendAreaInputMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type SendAreaInputMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendAreaInputMessage: (
    { __typename?: 'SendAreaInputMessageSuccess' }
    & { message: (
      { __typename?: 'AreaInputMessage' }
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, counsel: (
        { __typename?: 'Counsel' }
        & Pick<Counsel, 'id'>
      ) }
    ) }
  ) }
);

export type SendVinInputMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  vehicleId: Scalars['ID'];
}>;


export type SendVinInputMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendVinInputMessage: (
    { __typename?: 'SendVinInputMessageSuccess' }
    & { message: (
      { __typename?: 'VinInputMessage' }
      & Pick<VinInputMessage, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
      )> }
    ) }
  ) }
);

export type SendPlateNumberInputMessageMutationVariables = Exact<{
  counselId: Scalars['ID'];
  vehicleId: Scalars['ID'];
}>;


export type SendPlateNumberInputMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendPlateNumberInputMessage: (
    { __typename?: 'SendPlateNumberInputMessageSuccess' }
    & { message: (
      { __typename?: 'PlateNumberInputMessage' }
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id'>
      )> }
    ) }
  ) }
);

export type UpdateCursorMutationVariables = Exact<{
  counselId: Scalars['ID'];
  messageId: Scalars['ID'];
}>;


export type UpdateCursorMutation = (
  { __typename?: 'Mutation' }
  & { updateCursor: (
    { __typename?: 'UpdateCursorSuccess' }
    & { cursor: (
      { __typename?: 'Cursor' }
      & Pick<Cursor, 'id'>
      & { counsel: (
        { __typename?: 'Counsel' }
        & Pick<Counsel, 'id'>
      ), message?: Maybe<(
        { __typename?: 'AppReviewMessage' }
        & Pick<AppReviewMessage, 'id'>
      ) | (
        { __typename?: 'AreaInputMessage' }
        & Pick<AreaInputMessage, 'id'>
      ) | (
        { __typename?: 'CompanyMessage' }
        & Pick<CompanyMessage, 'id'>
      ) | (
        { __typename?: 'DeletedMessage' }
        & Pick<DeletedMessage, 'id'>
      ) | (
        { __typename?: 'EndMessage' }
        & Pick<EndMessage, 'id'>
      ) | (
        { __typename?: 'EnterMessage' }
        & Pick<EnterMessage, 'id'>
      ) | (
        { __typename?: 'EstimateMessage' }
        & Pick<EstimateMessage, 'id'>
      ) | (
        { __typename?: 'FileMessage' }
        & Pick<FileMessage, 'id'>
      ) | (
        { __typename?: 'InvoiceMessage' }
        & Pick<InvoiceMessage, 'id'>
      ) | (
        { __typename?: 'LeaveMessage' }
        & Pick<LeaveMessage, 'id'>
      ) | (
        { __typename?: 'PlateNumberInputMessage' }
        & Pick<PlateNumberInputMessage, 'id'>
      ) | (
        { __typename?: 'QueueMessage' }
        & Pick<QueueMessage, 'id'>
      ) | (
        { __typename?: 'RestartMessage' }
        & Pick<RestartMessage, 'id'>
      ) | (
        { __typename?: 'SuspendedMessage' }
        & Pick<SuspendedMessage, 'id'>
      ) | (
        { __typename?: 'TextMessage' }
        & Pick<TextMessage, 'id'>
      ) | (
        { __typename?: 'VinInputMessage' }
        & Pick<VinInputMessage, 'id'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    ) }
  ) }
);

export type DeleteMessageMutationVariables = Exact<{
  messageId: Scalars['ID'];
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage: (
    { __typename?: 'DeleteMessageSuccess' }
    & { message: (
      { __typename?: 'DeletedMessage' }
      & Pick<DeletedMessage, 'id' | 'deletedAt' | 'status'>
    ) }
  ) }
);

export type UpdateCounselMessageConnectionFragment = (
  { __typename?: 'Counsel' }
  & { messageConnection: (
    { __typename: 'MessageConnection' }
    & { edges: Array<(
      { __typename: 'MessageEdge' }
      & Pick<MessageEdge, 'cursor'>
      & { node: (
        { __typename?: 'AppReviewMessage' }
        & Pick<AppReviewMessage, 'id'>
      ) | (
        { __typename?: 'AreaInputMessage' }
        & Pick<AreaInputMessage, 'id'>
      ) | (
        { __typename?: 'CompanyMessage' }
        & Pick<CompanyMessage, 'id'>
      ) | (
        { __typename?: 'DeletedMessage' }
        & Pick<DeletedMessage, 'id'>
      ) | (
        { __typename?: 'EndMessage' }
        & Pick<EndMessage, 'id'>
      ) | (
        { __typename?: 'EnterMessage' }
        & Pick<EnterMessage, 'id'>
      ) | (
        { __typename?: 'EstimateMessage' }
        & Pick<EstimateMessage, 'id'>
      ) | (
        { __typename?: 'FileMessage' }
        & Pick<FileMessage, 'id'>
      ) | (
        { __typename?: 'InvoiceMessage' }
        & Pick<InvoiceMessage, 'id'>
      ) | (
        { __typename?: 'LeaveMessage' }
        & Pick<LeaveMessage, 'id'>
      ) | (
        { __typename?: 'PlateNumberInputMessage' }
        & Pick<PlateNumberInputMessage, 'id'>
      ) | (
        { __typename?: 'QueueMessage' }
        & Pick<QueueMessage, 'id'>
      ) | (
        { __typename?: 'RestartMessage' }
        & Pick<RestartMessage, 'id'>
      ) | (
        { __typename?: 'SuspendedMessage' }
        & Pick<SuspendedMessage, 'id'>
      ) | (
        { __typename?: 'TextMessage' }
        & Pick<TextMessage, 'id'>
      ) | (
        { __typename?: 'VinInputMessage' }
        & Pick<VinInputMessage, 'id'>
      ) }
    )> }
  ) }
);

export type UpdateCounselMembersFragment = (
  { __typename?: 'Counsel' }
  & { members: Array<(
    { __typename?: 'User' }
    & CounselWindowUserFragment
  )> }
);

export type CounselWindowTitleQueryVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type CounselWindowTitleQuery = (
  { __typename?: 'Query' }
  & { counsel: (
    { __typename?: 'Counsel' }
    & CounselWindowTitleDataFragment
  ) }
);

export type UpdatedCounselWindowSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type UpdatedCounselWindowSubscription = (
  { __typename?: 'Subscription' }
  & { updatedCounsel: (
    { __typename?: 'Counsel' }
    & CounselWindowTitleDataFragment
  ) }
);

export type UpdatedCursorOnCounselSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type UpdatedCursorOnCounselSubscription = (
  { __typename?: 'Subscription' }
  & { updatedCursorOnCounsel: (
    { __typename?: 'Cursor' }
    & Pick<Cursor, 'id'>
    & { counsel: (
      { __typename?: 'Counsel' }
      & Pick<Counsel, 'id'>
      & { cursors: Array<(
        { __typename?: 'Cursor' }
        & Pick<Cursor, 'id'>
      )> }
    ), user: (
      { __typename?: 'User' }
      & CounselWindowUserFragment
    ), message?: Maybe<(
      { __typename?: 'AppReviewMessage' }
      & CounselRoomMessage_AppReviewMessage_Fragment
    ) | (
      { __typename?: 'AreaInputMessage' }
      & CounselRoomMessage_AreaInputMessage_Fragment
    ) | (
      { __typename?: 'CompanyMessage' }
      & CounselRoomMessage_CompanyMessage_Fragment
    ) | (
      { __typename?: 'DeletedMessage' }
      & CounselRoomMessage_DeletedMessage_Fragment
    ) | (
      { __typename?: 'EndMessage' }
      & CounselRoomMessage_EndMessage_Fragment
    ) | (
      { __typename?: 'EnterMessage' }
      & CounselRoomMessage_EnterMessage_Fragment
    ) | (
      { __typename?: 'EstimateMessage' }
      & CounselRoomMessage_EstimateMessage_Fragment
    ) | (
      { __typename?: 'FileMessage' }
      & CounselRoomMessage_FileMessage_Fragment
    ) | (
      { __typename?: 'InvoiceMessage' }
      & CounselRoomMessage_InvoiceMessage_Fragment
    ) | (
      { __typename?: 'LeaveMessage' }
      & CounselRoomMessage_LeaveMessage_Fragment
    ) | (
      { __typename?: 'PlateNumberInputMessage' }
      & CounselRoomMessage_PlateNumberInputMessage_Fragment
    ) | (
      { __typename?: 'QueueMessage' }
      & CounselRoomMessage_QueueMessage_Fragment
    ) | (
      { __typename?: 'RestartMessage' }
      & CounselRoomMessage_RestartMessage_Fragment
    ) | (
      { __typename?: 'SuspendedMessage' }
      & CounselRoomMessage_SuspendedMessage_Fragment
    ) | (
      { __typename?: 'TextMessage' }
      & CounselRoomMessage_TextMessage_Fragment
    ) | (
      { __typename?: 'VinInputMessage' }
      & CounselRoomMessage_VinInputMessage_Fragment
    )> }
  ) }
);

export type NewMessageOnCounselSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type NewMessageOnCounselSubscription = (
  { __typename?: 'Subscription' }
  & { newMessageOnCounsel: (
    { __typename?: 'AppReviewMessage' }
    & CounselRoomMessage_AppReviewMessage_Fragment
  ) | (
    { __typename?: 'AreaInputMessage' }
    & CounselRoomMessage_AreaInputMessage_Fragment
  ) | (
    { __typename?: 'CompanyMessage' }
    & CounselRoomMessage_CompanyMessage_Fragment
  ) | (
    { __typename?: 'DeletedMessage' }
    & CounselRoomMessage_DeletedMessage_Fragment
  ) | (
    { __typename?: 'EndMessage' }
    & CounselRoomMessage_EndMessage_Fragment
  ) | (
    { __typename?: 'EnterMessage' }
    & CounselRoomMessage_EnterMessage_Fragment
  ) | (
    { __typename?: 'EstimateMessage' }
    & CounselRoomMessage_EstimateMessage_Fragment
  ) | (
    { __typename?: 'FileMessage' }
    & CounselRoomMessage_FileMessage_Fragment
  ) | (
    { __typename?: 'InvoiceMessage' }
    & CounselRoomMessage_InvoiceMessage_Fragment
  ) | (
    { __typename?: 'LeaveMessage' }
    & CounselRoomMessage_LeaveMessage_Fragment
  ) | (
    { __typename?: 'PlateNumberInputMessage' }
    & CounselRoomMessage_PlateNumberInputMessage_Fragment
  ) | (
    { __typename?: 'QueueMessage' }
    & CounselRoomMessage_QueueMessage_Fragment
  ) | (
    { __typename?: 'RestartMessage' }
    & CounselRoomMessage_RestartMessage_Fragment
  ) | (
    { __typename?: 'SuspendedMessage' }
    & CounselRoomMessage_SuspendedMessage_Fragment
  ) | (
    { __typename?: 'TextMessage' }
    & CounselRoomMessage_TextMessage_Fragment
  ) | (
    { __typename?: 'VinInputMessage' }
    & CounselRoomMessage_VinInputMessage_Fragment
  ) }
);

export type AddedUserOnCounselWindowSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type AddedUserOnCounselWindowSubscription = (
  { __typename?: 'Subscription' }
  & { addedUserOnCounsel: (
    { __typename?: 'User' }
    & CounselWindowUserFragment
  ) }
);

export type RemovedUserOnCounselWindowSubscriptionVariables = Exact<{
  counselId: Scalars['ID'];
}>;


export type RemovedUserOnCounselWindowSubscription = (
  { __typename?: 'Subscription' }
  & { removedUserOnCounsel: (
    { __typename?: 'User' }
    & CounselWindowUserFragment
  ) }
);

export type RefreshAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshAccessToken: (
    { __typename?: 'LoginSuccess' }
    & Pick<LoginSuccess, 'accessToken' | 'expiresAt'>
  ) }
);

export const CounselTableRowMessageFragmentDoc = gql`
    fragment CounselTableRowMessage on Message {
  __typename
  id
  author {
    avatar {
      url
    }
  }
  createdAt
  ... on AreaInputMessage {
    counsel {
      id
    }
  }
  ... on EndMessage {
    endedBy {
      role
      nickname
    }
  }
  ... on SuspendedMessage {
    id
  }
  ... on EnterMessage {
    inviter {
      id
      nickname
    }
    invitee {
      id
      nickname
    }
  }
  ... on EstimateMessage {
    counsel {
      id
      counselee {
        id
        nickname
      }
    }
  }
  ... on FileMessage {
    file {
      __typename
      description
      url
      ... on Image {
        metadata {
          width
          height
        }
      }
      ... on Video {
        metadata {
          width
          height
          duration
        }
      }
    }
  }
  ... on InvoiceMessage {
    id
  }
  ... on LeaveMessage {
    user {
      id
      nickname
    }
  }
  ... on RestartMessage {
    id
  }
  ... on TextMessage {
    body
  }
  ... on QueueMessage {
    reason
  }
  ... on CompanyMessage {
    company {
      id
      name
    }
  }
  ... on VinInputMessage {
    vehicle {
      id
    }
  }
  ... on AppReviewMessage {
    id
  }
}
    `;
export const CounselTableRowMessageConnectionFragmentDoc = gql`
    fragment CounselTableRowMessageConnection on MessageConnection {
  __typename
  edges {
    node {
      ...CounselTableRowMessage
    }
  }
}
    ${CounselTableRowMessageFragmentDoc}`;
export const CounselTableRowUserFragmentDoc = gql`
    fragment CounselTableRowUser on User {
  __typename
  id
  avatar {
    url
  }
  nickname
}
    `;
export const CounselTableRowFragmentDoc = gql`
    fragment CounselTableRow on Counsel {
  __typename
  id
  orders {
    payment {
      status
    }
  }
  counselee {
    ...CounselTableRowUser
  }
  index
  memo
  queueReason
  createdAt
  areas {
    zone {
      province
      district
      neighborhood
    }
  }
  body
  type {
    id
    name
  }
  vehicle {
    id
    brand {
      id
      logo {
        url
      }
    }
    plateNumber
    fullModelName
  }
  files {
    __typename
    ... on Image {
      url
    }
    ... on Video {
      url
    }
  }
  post {
    id
    hashtags {
      id
      name
    }
  }
  creator {
    id
    nickname
    avatar {
      url
    }
  }
  members {
    ...CounselTableRowUser
  }
  messageConnection(last: 1) {
    __typename
    edges {
      node {
        __typename
        id
        author {
          id
          avatar {
            url
          }
        }
        createdAt
        ... on AreaInputMessage {
          counsel {
            id
          }
        }
        ... on EndMessage {
          endedBy {
            role
            nickname
          }
        }
        ... on SuspendedMessage {
          id
        }
        ... on EnterMessage {
          inviter {
            id
            nickname
          }
          invitee {
            id
            nickname
          }
        }
        ... on EstimateMessage {
          counsel {
            id
            counselee {
              nickname
            }
          }
        }
        ... on FileMessage {
          file {
            __typename
            description
            url
            ... on Image {
              metadata {
                width
                height
              }
            }
            ... on Video {
              metadata {
                width
                height
                duration
              }
            }
          }
        }
        ... on InvoiceMessage {
          id
        }
        ... on LeaveMessage {
          user {
            id
            nickname
          }
        }
        ... on RestartMessage {
          id
        }
        ... on TextMessage {
          body
        }
        ... on QueueMessage {
          reason
        }
        ... on CompanyMessage {
          company {
            id
            name
          }
        }
        ... on VinInputMessage {
          vehicle {
            id
          }
        }
        ... on AppReviewMessage {
          id
        }
      }
    }
  }
  status
  unreadCount
  createdAt
}
    ${CounselTableRowUserFragmentDoc}`;
export const CounselWindowTitleDataFragmentDoc = gql`
    fragment counselWindowTitleData on Counsel {
  id
  index
  status
  unreadCount
  counselee {
    id
    nickname
  }
  vehicle {
    id
    shortModelName
  }
}
    `;
export const TaskpadOrderFragmentDoc = gql`
    fragment taskpadOrder on OrderBase {
  id
  __typename
  amount
  createdAt
  expiresAt
  discountAmount
  totalItemAmount
  totalAmount
  paymentAmount
  vehicle {
    id
    brand {
      id
      name
    }
  }
  items {
    code
    name
    discountRate
    price
    quantity
    totalAmount
    paymentAmount
  }
  name
  payment {
    id
    amount
    completedAt
    cancelledAt
    status
  }
  shippingCharge
  recommendedReason
  rejectedReason
  shipping {
    id
    status
    trackingId
    contact
    destination
    method
    recipient
    carrier
    deliveredAt
  }
  ... on InsurancedOrder {
    cashbackAmount
    cashbackRate
  }
}
    `;
export const CounselDetailInfoFragmentDoc = gql`
    fragment counselDetailInfo on Counsel {
  id
  createdAt
  status
  counselee {
    id
    nickname
    inactiveCounselConnection(filter: {}, first: 3) {
      edges {
        node {
          id
          index
          memo
          createdAt
        }
      }
    }
  }
  memo
  body
  type {
    id
    name
  }
  areas {
    zone {
      province
      district
      neighborhood
    }
  }
  insurance
  vehicle {
    id
    shortModelName
    plateNumber
    registeredYear
  }
  files {
    url
    description
  }
}
    `;
export const CounselWindowUserFragmentDoc = gql`
    fragment counselWindowUser on User {
  id
  role
  nickname
  avatar {
    url
  }
}
    `;
export const CounselRoomMessageFragmentDoc = gql`
    fragment counselRoomMessage on Message {
  __typename
  id
  author {
    id
    role
    nickname
    avatar {
      url
    }
  }
  counsel {
    counselee {
      id
      nickname
    }
  }
  createdAt
  status
  ... on AppReviewMessage {
    id
  }
  ... on AreaInputMessage {
    counsel {
      id
      counselee {
        id
        nickname
      }
    }
  }
  ... on DeletedMessage {
    id
    deletedAt
  }
  ... on EndMessage {
    endedBy {
      role
      nickname
    }
  }
  ... on SuspendedMessage {
    idle
    willBeFinishedAt
  }
  ... on EnterMessage {
    inviter {
      id
      nickname
    }
    invitee {
      id
      nickname
    }
  }
  ... on EstimateMessage {
    counsel {
      id
      vehicle {
        id
        plateNumber
        registeredYear
        shortModelName
      }
      counselee {
        id
        nickname
      }
    }
    estimate {
      name
      amount
      latest
      items {
        name
        price
        quantity
        discountRate
        totalAmount
        paymentAmount
      }
      order {
        name
        expiresAt
        discountAmount
        totalItemAmount
        paymentAmount
      }
      shippingCharge
    }
  }
  ... on FileMessage {
    file {
      __typename
      description
      url
      ... on Image {
        metadata {
          width
          height
        }
      }
      ... on Video {
        metadata {
          width
          height
          duration
        }
      }
    }
  }
  ... on InvoiceMessage {
    counsel {
      id
      counselee {
        id
        nickname
      }
    }
    order {
      id
      amount
      createdAt
      expiresAt
      items {
        code
        name
        price
        quantity
      }
      name
      payment {
        id
        amount
        completedAt
        status
      }
      shippingCharge
      shipping {
        id
        status
        trackingId
        contact
        destination
        method
        recipient
        carrier
        deliveredAt
      }
    }
  }
  ... on LeaveMessage {
    user {
      id
      role
      nickname
      avatar {
        url
      }
    }
  }
  ... on PlateNumberInputMessage {
    id
  }
  ... on RestartMessage {
    idle
  }
  ... on TextMessage {
    body
  }
  ... on QueueMessage {
    reason
  }
  ... on CompanyMessage {
    company {
      id
    }
  }
  ... on VinInputMessage {
    id
  }
}
    `;
export const CounselRoomFragmentDoc = gql`
    fragment counselRoom on Counsel {
  id
  cursors {
    id
    message {
      id
      createdAt
    }
    user {
      ...counselWindowUser
    }
  }
  members {
    ...counselWindowUser
  }
  messageConnection(before: $before, last: $last) {
    edges {
      cursor
      node {
        ...counselRoomMessage
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
  counselee {
    id
    nickname
  }
  status
  queueReason
  queuedFrom
  bookmarkedUntil
}
    ${CounselWindowUserFragmentDoc}
${CounselRoomMessageFragmentDoc}`;
export const FinishedCounselsResultFragmentDoc = gql`
    fragment finishedCounselsResult on Counsel {
  id
  index
  status
  orders {
    payment {
      id
      status
      amount
    }
    shipping {
      id
      destination
    }
    paymentAmount
  }
  finishedAt
  createdAt
  vehicle {
    id
    plateNumber
    brand {
      id
      logo {
        url
      }
    }
    fullModelName
    fuelType {
      base
      hybrid
    }
  }
  post {
    id
    hashtags {
      id
      name
    }
  }
  type {
    id
    name
  }
  insurance
  memo
  creator {
    id
    nickname
    avatar {
      url
    }
  }
  members {
    id
    nickname
    avatar {
      url
    }
  }
}
    `;
export const OngoingCounselsResultFragmentDoc = gql`
    fragment ongoingCounselsResult on Counsel {
  id
  index
  status
  areas {
    zone {
      province
      district
      neighborhood
    }
  }
  type {
    id
    name
  }
  createdAt
  vehicle {
    id
    brand {
      id
      logo {
        url
      }
    }
    plateNumber
    fullModelName
    fuelType {
      base
      hybrid
    }
  }
  memo
  unreadCount
  messageConnection(last: 1) {
    __typename
    edges {
      node {
        ...CounselTableRowMessage
      }
    }
  }
  post {
    id
    hashtags {
      id
      name
    }
  }
  creator {
    id
    nickname
    avatar {
      url
    }
  }
  members {
    id
    nickname
    avatar {
      url
    }
  }
  insurance
}
    ${CounselTableRowMessageFragmentDoc}`;
export const QueuedCounselsResultFragmentDoc = gql`
    fragment queuedCounselsResult on Counsel {
  id
  index
  status
  unreadCount
  areas {
    zone {
      province
      district
      neighborhood
    }
  }
  type {
    id
    name
  }
  createdAt
  vehicle {
    id
    plateNumber
    fullModelName
    fuelType {
      base
      hybrid
    }
  }
  messageConnection(last: 1) {
    __typename
    edges {
      node {
        ...CounselTableRowMessage
      }
    }
  }
  memo
  queueReason
  creator {
    id
    nickname
    avatar {
      url
    }
  }
  members {
    id
    nickname
    avatar {
      url
    }
  }
}
    ${CounselTableRowMessageFragmentDoc}`;
export const TemplateDetailFragmentDoc = gql`
    fragment TemplateDetail on Template {
  id
  name
  path {
    id
    name
  }
  messages {
    ... on TextMessageContent {
      body
    }
    ... on FileMessageContent {
      file {
        url
        ... on Image {
          metadata {
            width
            height
          }
        }
        ... on Video {
          metadata {
            width
            height
            duration
          }
        }
      }
    }
  }
}
    `;
export const UpdateCounselMessageConnectionFragmentDoc = gql`
    fragment updateCounselMessageConnection on Counsel {
  messageConnection {
    __typename
    edges {
      __typename
      cursor
      node {
        id
      }
    }
  }
}
    `;
export const UpdateCounselMembersFragmentDoc = gql`
    fragment updateCounselMembers on Counsel {
  members {
    ...counselWindowUser
  }
}
    ${CounselWindowUserFragmentDoc}`;
export const LoggedInDocument = gql`
    query loggedIn {
  loggedIn @client
}
    `;

/**
 * __useLoggedInQuery__
 *
 * To run a query within a React component, call `useLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<LoggedInQuery, LoggedInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedInQuery, LoggedInQueryVariables>(LoggedInDocument, options);
      }
export function useLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedInQuery, LoggedInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedInQuery, LoggedInQueryVariables>(LoggedInDocument, options);
        }
export type LoggedInQueryHookResult = ReturnType<typeof useLoggedInQuery>;
export type LoggedInLazyQueryHookResult = ReturnType<typeof useLoggedInLazyQuery>;
export type LoggedInQueryResult = Apollo.QueryResult<LoggedInQuery, LoggedInQueryVariables>;
export const AddUserToCounselDocument = gql`
    mutation addUserToCounsel($counselId: ID!, $userId: ID!) {
  addUserToCounsel(counselId: $counselId, userId: $userId) {
    ... on AddUserToCounselSuccess {
      user {
        ...counselWindowUser
      }
    }
  }
}
    ${CounselWindowUserFragmentDoc}`;
export type AddUserToCounselMutationFn = Apollo.MutationFunction<AddUserToCounselMutation, AddUserToCounselMutationVariables>;

/**
 * __useAddUserToCounselMutation__
 *
 * To run a mutation, you first call `useAddUserToCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToCounselMutation, { data, loading, error }] = useAddUserToCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserToCounselMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToCounselMutation, AddUserToCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToCounselMutation, AddUserToCounselMutationVariables>(AddUserToCounselDocument, options);
      }
export type AddUserToCounselMutationHookResult = ReturnType<typeof useAddUserToCounselMutation>;
export type AddUserToCounselMutationResult = Apollo.MutationResult<AddUserToCounselMutation>;
export type AddUserToCounselMutationOptions = Apollo.BaseMutationOptions<AddUserToCounselMutation, AddUserToCounselMutationVariables>;
export const RemoveUserFromCounselDocument = gql`
    mutation removeUserFromCounsel($counselId: ID!, $userId: ID!) {
  removeUserFromCounsel(counselId: $counselId, userId: $userId) {
    ... on RemoveUserFromCounselSuccess {
      user {
        ...counselWindowUser
      }
    }
  }
}
    ${CounselWindowUserFragmentDoc}`;
export type RemoveUserFromCounselMutationFn = Apollo.MutationFunction<RemoveUserFromCounselMutation, RemoveUserFromCounselMutationVariables>;

/**
 * __useRemoveUserFromCounselMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromCounselMutation, { data, loading, error }] = useRemoveUserFromCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromCounselMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromCounselMutation, RemoveUserFromCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromCounselMutation, RemoveUserFromCounselMutationVariables>(RemoveUserFromCounselDocument, options);
      }
export type RemoveUserFromCounselMutationHookResult = ReturnType<typeof useRemoveUserFromCounselMutation>;
export type RemoveUserFromCounselMutationResult = Apollo.MutationResult<RemoveUserFromCounselMutation>;
export type RemoveUserFromCounselMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromCounselMutation, RemoveUserFromCounselMutationVariables>;
export const CounselorsDocument = gql`
    query counselors {
  counselors {
    id
    nickname
    name
    avatar {
      url
    }
  }
}
    `;

/**
 * __useCounselorsQuery__
 *
 * To run a query within a React component, call `useCounselorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCounselorsQuery(baseOptions?: Apollo.QueryHookOptions<CounselorsQuery, CounselorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselorsQuery, CounselorsQueryVariables>(CounselorsDocument, options);
      }
export function useCounselorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselorsQuery, CounselorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselorsQuery, CounselorsQueryVariables>(CounselorsDocument, options);
        }
export type CounselorsQueryHookResult = ReturnType<typeof useCounselorsQuery>;
export type CounselorsLazyQueryHookResult = ReturnType<typeof useCounselorsLazyQuery>;
export type CounselorsQueryResult = Apollo.QueryResult<CounselorsQuery, CounselorsQueryVariables>;
export const CounselInfoDocument = gql`
    query counselInfo($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    index
    status
    bookmarkedUntil
    counselee {
      id
      avatar {
        url
      }
      age
      gender
      nickname
      phone
      lastDevice {
        brand
        model
        os
        osVersion
        appVersion
      }
    }
    members {
      id
      nickname
      avatar {
        url
      }
    }
    body
    files {
      url
      description
    }
    type {
      id
      name
    }
    areas {
      radius
      zone {
        province
        district
        neighborhood
      }
    }
    vehicle {
      id
      avatar {
        url
      }
      registeredYear
      mileage
      plateNumber
      fuelType {
        base
        hybrid
      }
      fullModelName
      grade {
        displacement
      }
      tireSize {
        front {
          aspectRatio
          wheelDiameter
          width
        }
        rear {
          aspectRatio
          wheelDiameter
          width
        }
      }
      vin
    }
  }
}
    `;

/**
 * __useCounselInfoQuery__
 *
 * To run a query within a React component, call `useCounselInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselInfoQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounselInfoQuery(baseOptions: Apollo.QueryHookOptions<CounselInfoQuery, CounselInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselInfoQuery, CounselInfoQueryVariables>(CounselInfoDocument, options);
      }
export function useCounselInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselInfoQuery, CounselInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselInfoQuery, CounselInfoQueryVariables>(CounselInfoDocument, options);
        }
export type CounselInfoQueryHookResult = ReturnType<typeof useCounselInfoQuery>;
export type CounselInfoLazyQueryHookResult = ReturnType<typeof useCounselInfoLazyQuery>;
export type CounselInfoQueryResult = Apollo.QueryResult<CounselInfoQuery, CounselInfoQueryVariables>;
export const CounselMessagesDocument = gql`
    query counselMessages($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    messageConnection {
      edges {
        node {
          ...counselRoomMessage
        }
      }
    }
  }
}
    ${CounselRoomMessageFragmentDoc}`;

/**
 * __useCounselMessagesQuery__
 *
 * To run a query within a React component, call `useCounselMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselMessagesQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounselMessagesQuery(baseOptions: Apollo.QueryHookOptions<CounselMessagesQuery, CounselMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselMessagesQuery, CounselMessagesQueryVariables>(CounselMessagesDocument, options);
      }
export function useCounselMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselMessagesQuery, CounselMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselMessagesQuery, CounselMessagesQueryVariables>(CounselMessagesDocument, options);
        }
export type CounselMessagesQueryHookResult = ReturnType<typeof useCounselMessagesQuery>;
export type CounselMessagesLazyQueryHookResult = ReturnType<typeof useCounselMessagesLazyQuery>;
export type CounselMessagesQueryResult = Apollo.QueryResult<CounselMessagesQuery, CounselMessagesQueryVariables>;
export const CounselRoomDocument = gql`
    query counselRoom($counselId: ID!, $before: String, $last: Int = 20) {
  counsel(counselId: $counselId) {
    ...counselRoom
  }
}
    ${CounselRoomFragmentDoc}`;

/**
 * __useCounselRoomQuery__
 *
 * To run a query within a React component, call `useCounselRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselRoomQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useCounselRoomQuery(baseOptions: Apollo.QueryHookOptions<CounselRoomQuery, CounselRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselRoomQuery, CounselRoomQueryVariables>(CounselRoomDocument, options);
      }
export function useCounselRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselRoomQuery, CounselRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselRoomQuery, CounselRoomQueryVariables>(CounselRoomDocument, options);
        }
export type CounselRoomQueryHookResult = ReturnType<typeof useCounselRoomQuery>;
export type CounselRoomLazyQueryHookResult = ReturnType<typeof useCounselRoomLazyQuery>;
export type CounselRoomQueryResult = Apollo.QueryResult<CounselRoomQuery, CounselRoomQueryVariables>;
export const CompanyMessageDocument = gql`
    query CompanyMessage($companyId: ID!) {
  company(companyId: $companyId) {
    id
    name
    specialties {
      id
      name
    }
    images {
      url
    }
    contact
    extraContact
  }
}
    `;

/**
 * __useCompanyMessageQuery__
 *
 * To run a query within a React component, call `useCompanyMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMessageQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyMessageQuery(baseOptions: Apollo.QueryHookOptions<CompanyMessageQuery, CompanyMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMessageQuery, CompanyMessageQueryVariables>(CompanyMessageDocument, options);
      }
export function useCompanyMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMessageQuery, CompanyMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMessageQuery, CompanyMessageQueryVariables>(CompanyMessageDocument, options);
        }
export type CompanyMessageQueryHookResult = ReturnType<typeof useCompanyMessageQuery>;
export type CompanyMessageLazyQueryHookResult = ReturnType<typeof useCompanyMessageLazyQuery>;
export type CompanyMessageQueryResult = Apollo.QueryResult<CompanyMessageQuery, CompanyMessageQueryVariables>;
export const QueueCounselDocument = gql`
    mutation queueCounsel($counselId: ID!, $reason: QueueReason) {
  queueCounsel(counselId: $counselId, reason: $reason) {
    counsel {
      id
      status
      queueReason
      queuedFrom
      bookmarkedUntil
    }
  }
}
    `;
export type QueueCounselMutationFn = Apollo.MutationFunction<QueueCounselMutation, QueueCounselMutationVariables>;

/**
 * __useQueueCounselMutation__
 *
 * To run a mutation, you first call `useQueueCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueueCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queueCounselMutation, { data, loading, error }] = useQueueCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useQueueCounselMutation(baseOptions?: Apollo.MutationHookOptions<QueueCounselMutation, QueueCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QueueCounselMutation, QueueCounselMutationVariables>(QueueCounselDocument, options);
      }
export type QueueCounselMutationHookResult = ReturnType<typeof useQueueCounselMutation>;
export type QueueCounselMutationResult = Apollo.MutationResult<QueueCounselMutation>;
export type QueueCounselMutationOptions = Apollo.BaseMutationOptions<QueueCounselMutation, QueueCounselMutationVariables>;
export const UnqueueCounselDocument = gql`
    mutation unqueueCounsel($counselId: ID!) {
  unqueueCounsel(counselId: $counselId) {
    counsel {
      id
      status
      queueReason
      queuedFrom
      bookmarkedUntil
    }
  }
}
    `;
export type UnqueueCounselMutationFn = Apollo.MutationFunction<UnqueueCounselMutation, UnqueueCounselMutationVariables>;

/**
 * __useUnqueueCounselMutation__
 *
 * To run a mutation, you first call `useUnqueueCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnqueueCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unqueueCounselMutation, { data, loading, error }] = useUnqueueCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useUnqueueCounselMutation(baseOptions?: Apollo.MutationHookOptions<UnqueueCounselMutation, UnqueueCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnqueueCounselMutation, UnqueueCounselMutationVariables>(UnqueueCounselDocument, options);
      }
export type UnqueueCounselMutationHookResult = ReturnType<typeof useUnqueueCounselMutation>;
export type UnqueueCounselMutationResult = Apollo.MutationResult<UnqueueCounselMutation>;
export type UnqueueCounselMutationOptions = Apollo.BaseMutationOptions<UnqueueCounselMutation, UnqueueCounselMutationVariables>;
export const FinishCounselDocument = gql`
    mutation finishCounsel($counselId: ID!) {
  finishCounsel(counselId: $counselId) {
    counsel {
      id
    }
  }
}
    `;
export type FinishCounselMutationFn = Apollo.MutationFunction<FinishCounselMutation, FinishCounselMutationVariables>;

/**
 * __useFinishCounselMutation__
 *
 * To run a mutation, you first call `useFinishCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishCounselMutation, { data, loading, error }] = useFinishCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useFinishCounselMutation(baseOptions?: Apollo.MutationHookOptions<FinishCounselMutation, FinishCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishCounselMutation, FinishCounselMutationVariables>(FinishCounselDocument, options);
      }
export type FinishCounselMutationHookResult = ReturnType<typeof useFinishCounselMutation>;
export type FinishCounselMutationResult = Apollo.MutationResult<FinishCounselMutation>;
export type FinishCounselMutationOptions = Apollo.BaseMutationOptions<FinishCounselMutation, FinishCounselMutationVariables>;
export const SendAppReviewMessageDocument = gql`
    mutation sendAppReviewMessage($counselId: ID!) {
  sendAppReviewMessage(counselId: $counselId) {
    message {
      id
    }
  }
}
    `;
export type SendAppReviewMessageMutationFn = Apollo.MutationFunction<SendAppReviewMessageMutation, SendAppReviewMessageMutationVariables>;

/**
 * __useSendAppReviewMessageMutation__
 *
 * To run a mutation, you first call `useSendAppReviewMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAppReviewMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAppReviewMessageMutation, { data, loading, error }] = useSendAppReviewMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useSendAppReviewMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendAppReviewMessageMutation, SendAppReviewMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAppReviewMessageMutation, SendAppReviewMessageMutationVariables>(SendAppReviewMessageDocument, options);
      }
export type SendAppReviewMessageMutationHookResult = ReturnType<typeof useSendAppReviewMessageMutation>;
export type SendAppReviewMessageMutationResult = Apollo.MutationResult<SendAppReviewMessageMutation>;
export type SendAppReviewMessageMutationOptions = Apollo.BaseMutationOptions<SendAppReviewMessageMutation, SendAppReviewMessageMutationVariables>;
export const RestartCounselDocument = gql`
    mutation restartCounsel($counselId: ID!) {
  restartCounsel(counselId: $counselId) {
    counsel {
      id
      status
    }
  }
}
    `;
export type RestartCounselMutationFn = Apollo.MutationFunction<RestartCounselMutation, RestartCounselMutationVariables>;

/**
 * __useRestartCounselMutation__
 *
 * To run a mutation, you first call `useRestartCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartCounselMutation, { data, loading, error }] = useRestartCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useRestartCounselMutation(baseOptions?: Apollo.MutationHookOptions<RestartCounselMutation, RestartCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartCounselMutation, RestartCounselMutationVariables>(RestartCounselDocument, options);
      }
export type RestartCounselMutationHookResult = ReturnType<typeof useRestartCounselMutation>;
export type RestartCounselMutationResult = Apollo.MutationResult<RestartCounselMutation>;
export type RestartCounselMutationOptions = Apollo.BaseMutationOptions<RestartCounselMutation, RestartCounselMutationVariables>;
export const StopEstimationAndStartCounselDocument = gql`
    mutation stopEstimationAndStartCounsel($counselId: ID!) {
  stopEstimationAndStartCounsel(counselId: $counselId) {
    ... on StopEstimationAndStartCounselSuccess {
      counsel {
        id
        status
      }
    }
  }
}
    `;
export type StopEstimationAndStartCounselMutationFn = Apollo.MutationFunction<StopEstimationAndStartCounselMutation, StopEstimationAndStartCounselMutationVariables>;

/**
 * __useStopEstimationAndStartCounselMutation__
 *
 * To run a mutation, you first call `useStopEstimationAndStartCounselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopEstimationAndStartCounselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopEstimationAndStartCounselMutation, { data, loading, error }] = useStopEstimationAndStartCounselMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useStopEstimationAndStartCounselMutation(baseOptions?: Apollo.MutationHookOptions<StopEstimationAndStartCounselMutation, StopEstimationAndStartCounselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopEstimationAndStartCounselMutation, StopEstimationAndStartCounselMutationVariables>(StopEstimationAndStartCounselDocument, options);
      }
export type StopEstimationAndStartCounselMutationHookResult = ReturnType<typeof useStopEstimationAndStartCounselMutation>;
export type StopEstimationAndStartCounselMutationResult = Apollo.MutationResult<StopEstimationAndStartCounselMutation>;
export type StopEstimationAndStartCounselMutationOptions = Apollo.BaseMutationOptions<StopEstimationAndStartCounselMutation, StopEstimationAndStartCounselMutationVariables>;
export const MessageInputDocument = gql`
    query messageInput($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    status
    queueReason
    queuedFrom
    bookmarkedUntil
    orders {
      id
      expiresAt
    }
  }
}
    `;

/**
 * __useMessageInputQuery__
 *
 * To run a query within a React component, call `useMessageInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageInputQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useMessageInputQuery(baseOptions: Apollo.QueryHookOptions<MessageInputQuery, MessageInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageInputQuery, MessageInputQueryVariables>(MessageInputDocument, options);
      }
export function useMessageInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageInputQuery, MessageInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageInputQuery, MessageInputQueryVariables>(MessageInputDocument, options);
        }
export type MessageInputQueryHookResult = ReturnType<typeof useMessageInputQuery>;
export type MessageInputLazyQueryHookResult = ReturnType<typeof useMessageInputLazyQuery>;
export type MessageInputQueryResult = Apollo.QueryResult<MessageInputQuery, MessageInputQueryVariables>;
export const InquiryTypesDocument = gql`
    query inquiryTypes {
  inquiryTypes {
    id
    name
  }
}
    `;

/**
 * __useInquiryTypesQuery__
 *
 * To run a query within a React component, call `useInquiryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiryTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInquiryTypesQuery(baseOptions?: Apollo.QueryHookOptions<InquiryTypesQuery, InquiryTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InquiryTypesQuery, InquiryTypesQueryVariables>(InquiryTypesDocument, options);
      }
export function useInquiryTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InquiryTypesQuery, InquiryTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InquiryTypesQuery, InquiryTypesQueryVariables>(InquiryTypesDocument, options);
        }
export type InquiryTypesQueryHookResult = ReturnType<typeof useInquiryTypesQuery>;
export type InquiryTypesLazyQueryHookResult = ReturnType<typeof useInquiryTypesLazyQuery>;
export type InquiryTypesQueryResult = Apollo.QueryResult<InquiryTypesQuery, InquiryTypesQueryVariables>;
export const FinishedCounselConnectionDocument = gql`
    query finishedCounselConnection($after: String, $before: String, $filter: FinishedCounselConnectionFilter!, $first: Int, $last: Int) {
  finishedCounselConnection(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        ...finishedCounselsResult
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${FinishedCounselsResultFragmentDoc}`;

/**
 * __useFinishedCounselConnectionQuery__
 *
 * To run a query within a React component, call `useFinishedCounselConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinishedCounselConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishedCounselConnectionQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFinishedCounselConnectionQuery(baseOptions: Apollo.QueryHookOptions<FinishedCounselConnectionQuery, FinishedCounselConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinishedCounselConnectionQuery, FinishedCounselConnectionQueryVariables>(FinishedCounselConnectionDocument, options);
      }
export function useFinishedCounselConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinishedCounselConnectionQuery, FinishedCounselConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinishedCounselConnectionQuery, FinishedCounselConnectionQueryVariables>(FinishedCounselConnectionDocument, options);
        }
export type FinishedCounselConnectionQueryHookResult = ReturnType<typeof useFinishedCounselConnectionQuery>;
export type FinishedCounselConnectionLazyQueryHookResult = ReturnType<typeof useFinishedCounselConnectionLazyQuery>;
export type FinishedCounselConnectionQueryResult = Apollo.QueryResult<FinishedCounselConnectionQuery, FinishedCounselConnectionQueryVariables>;
export const OngoingCounselsDocument = gql`
    query ongoingCounsels {
  ongoingCounsels {
    ...ongoingCounselsResult
  }
}
    ${OngoingCounselsResultFragmentDoc}`;

/**
 * __useOngoingCounselsQuery__
 *
 * To run a query within a React component, call `useOngoingCounselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingCounselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingCounselsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOngoingCounselsQuery(baseOptions?: Apollo.QueryHookOptions<OngoingCounselsQuery, OngoingCounselsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OngoingCounselsQuery, OngoingCounselsQueryVariables>(OngoingCounselsDocument, options);
      }
export function useOngoingCounselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OngoingCounselsQuery, OngoingCounselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OngoingCounselsQuery, OngoingCounselsQueryVariables>(OngoingCounselsDocument, options);
        }
export type OngoingCounselsQueryHookResult = ReturnType<typeof useOngoingCounselsQuery>;
export type OngoingCounselsLazyQueryHookResult = ReturnType<typeof useOngoingCounselsLazyQuery>;
export type OngoingCounselsQueryResult = Apollo.QueryResult<OngoingCounselsQuery, OngoingCounselsQueryVariables>;
export const QueuedCounselsDocument = gql`
    query queuedCounsels {
  queuedCounsels {
    ...queuedCounselsResult
  }
}
    ${QueuedCounselsResultFragmentDoc}`;

/**
 * __useQueuedCounselsQuery__
 *
 * To run a query within a React component, call `useQueuedCounselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueuedCounselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueuedCounselsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueuedCounselsQuery(baseOptions?: Apollo.QueryHookOptions<QueuedCounselsQuery, QueuedCounselsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueuedCounselsQuery, QueuedCounselsQueryVariables>(QueuedCounselsDocument, options);
      }
export function useQueuedCounselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueuedCounselsQuery, QueuedCounselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueuedCounselsQuery, QueuedCounselsQueryVariables>(QueuedCounselsDocument, options);
        }
export type QueuedCounselsQueryHookResult = ReturnType<typeof useQueuedCounselsQuery>;
export type QueuedCounselsLazyQueryHookResult = ReturnType<typeof useQueuedCounselsLazyQuery>;
export type QueuedCounselsQueryResult = Apollo.QueryResult<QueuedCounselsQuery, QueuedCounselsQueryVariables>;
export const UpdatedCounselDocument = gql`
    subscription updatedCounsel($counselId: ID!) {
  updatedCounsel(counselId: $counselId) {
    ...CounselTableRow
  }
}
    ${CounselTableRowFragmentDoc}`;

/**
 * __useUpdatedCounselSubscription__
 *
 * To run a query within a React component, call `useUpdatedCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedCounselSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useUpdatedCounselSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdatedCounselSubscription, UpdatedCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedCounselSubscription, UpdatedCounselSubscriptionVariables>(UpdatedCounselDocument, options);
      }
export type UpdatedCounselSubscriptionHookResult = ReturnType<typeof useUpdatedCounselSubscription>;
export type UpdatedCounselSubscriptionResult = Apollo.SubscriptionResult<UpdatedCounselSubscription>;
export const LocalCounselDocument = gql`
    query localCounsel($counselId: ID!) {
  counsel(counselId: $counselId) @client {
    id @client
    status @client
  }
}
    `;

/**
 * __useLocalCounselQuery__
 *
 * To run a query within a React component, call `useLocalCounselQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalCounselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalCounselQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useLocalCounselQuery(baseOptions: Apollo.QueryHookOptions<LocalCounselQuery, LocalCounselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocalCounselQuery, LocalCounselQueryVariables>(LocalCounselDocument, options);
      }
export function useLocalCounselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocalCounselQuery, LocalCounselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocalCounselQuery, LocalCounselQueryVariables>(LocalCounselDocument, options);
        }
export type LocalCounselQueryHookResult = ReturnType<typeof useLocalCounselQuery>;
export type LocalCounselLazyQueryHookResult = ReturnType<typeof useLocalCounselLazyQuery>;
export type LocalCounselQueryResult = Apollo.QueryResult<LocalCounselQuery, LocalCounselQueryVariables>;
export const NewCounselDocument = gql`
    subscription newCounsel {
  newCounsel {
    ...CounselTableRow
  }
}
    ${CounselTableRowFragmentDoc}`;

/**
 * __useNewCounselSubscription__
 *
 * To run a query within a React component, call `useNewCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCounselSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewCounselSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewCounselSubscription, NewCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewCounselSubscription, NewCounselSubscriptionVariables>(NewCounselDocument, options);
      }
export type NewCounselSubscriptionHookResult = ReturnType<typeof useNewCounselSubscription>;
export type NewCounselSubscriptionResult = Apollo.SubscriptionResult<NewCounselSubscription>;
export const InvitedCounselDocument = gql`
    subscription invitedCounsel {
  invitedCounsel {
    ...CounselTableRow
  }
}
    ${CounselTableRowFragmentDoc}`;

/**
 * __useInvitedCounselSubscription__
 *
 * To run a query within a React component, call `useInvitedCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvitedCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitedCounselSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvitedCounselSubscription(baseOptions?: Apollo.SubscriptionHookOptions<InvitedCounselSubscription, InvitedCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InvitedCounselSubscription, InvitedCounselSubscriptionVariables>(InvitedCounselDocument, options);
      }
export type InvitedCounselSubscriptionHookResult = ReturnType<typeof useInvitedCounselSubscription>;
export type InvitedCounselSubscriptionResult = Apollo.SubscriptionResult<InvitedCounselSubscription>;
export const LeavedCounselDocument = gql`
    subscription leavedCounsel {
  leavedCounsel {
    id
    status
  }
}
    `;

/**
 * __useLeavedCounselSubscription__
 *
 * To run a query within a React component, call `useLeavedCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLeavedCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeavedCounselSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLeavedCounselSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LeavedCounselSubscription, LeavedCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LeavedCounselSubscription, LeavedCounselSubscriptionVariables>(LeavedCounselDocument, options);
      }
export type LeavedCounselSubscriptionHookResult = ReturnType<typeof useLeavedCounselSubscription>;
export type LeavedCounselSubscriptionResult = Apollo.SubscriptionResult<LeavedCounselSubscription>;
export const NewMessageDocument = gql`
    subscription newMessage {
  newMessage {
    counsel {
      id
      status
    }
    ...CounselTableRowMessage
  }
}
    ${CounselTableRowMessageFragmentDoc}`;

/**
 * __useNewMessageSubscription__
 *
 * To run a query within a React component, call `useNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewMessageSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewMessageSubscription, NewMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewMessageSubscription, NewMessageSubscriptionVariables>(NewMessageDocument, options);
      }
export type NewMessageSubscriptionHookResult = ReturnType<typeof useNewMessageSubscription>;
export type NewMessageSubscriptionResult = Apollo.SubscriptionResult<NewMessageSubscription>;
export const StatusChangedCounselDocument = gql`
    subscription statusChangedCounsel($counselId: ID) {
  statusChangedCounsel(counselId: $counselId) {
    counsel {
      ...CounselTableRow
    }
    from
    to
  }
}
    ${CounselTableRowFragmentDoc}`;

/**
 * __useStatusChangedCounselSubscription__
 *
 * To run a query within a React component, call `useStatusChangedCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStatusChangedCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusChangedCounselSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useStatusChangedCounselSubscription(baseOptions?: Apollo.SubscriptionHookOptions<StatusChangedCounselSubscription, StatusChangedCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StatusChangedCounselSubscription, StatusChangedCounselSubscriptionVariables>(StatusChangedCounselDocument, options);
      }
export type StatusChangedCounselSubscriptionHookResult = ReturnType<typeof useStatusChangedCounselSubscription>;
export type StatusChangedCounselSubscriptionResult = Apollo.SubscriptionResult<StatusChangedCounselSubscription>;
export const HashtagsDocument = gql`
    query Hashtags($postId: ID!) {
  post(postId: $postId) {
    id
    hashtags {
      id
      name
    }
  }
}
    `;

/**
 * __useHashtagsQuery__
 *
 * To run a query within a React component, call `useHashtagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHashtagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHashtagsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useHashtagsQuery(baseOptions: Apollo.QueryHookOptions<HashtagsQuery, HashtagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HashtagsQuery, HashtagsQueryVariables>(HashtagsDocument, options);
      }
export function useHashtagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HashtagsQuery, HashtagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HashtagsQuery, HashtagsQueryVariables>(HashtagsDocument, options);
        }
export type HashtagsQueryHookResult = ReturnType<typeof useHashtagsQuery>;
export type HashtagsLazyQueryHookResult = ReturnType<typeof useHashtagsLazyQuery>;
export type HashtagsQueryResult = Apollo.QueryResult<HashtagsQuery, HashtagsQueryVariables>;
export const ProfileDocument = gql`
    query profile {
  me {
    id
    avatar {
      url
    }
    nickname
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($data: UpdateUserData!) {
  updateMe(data: $data) {
    user {
      id
      avatar {
        url
      }
      nickname
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const SettingDrawerDocument = gql`
    query settingDrawer {
  me {
    id
    avatar {
      url
    }
    nickname
  }
}
    `;

/**
 * __useSettingDrawerQuery__
 *
 * To run a query within a React component, call `useSettingDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingDrawerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingDrawerQuery(baseOptions?: Apollo.QueryHookOptions<SettingDrawerQuery, SettingDrawerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingDrawerQuery, SettingDrawerQueryVariables>(SettingDrawerDocument, options);
      }
export function useSettingDrawerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingDrawerQuery, SettingDrawerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingDrawerQuery, SettingDrawerQueryVariables>(SettingDrawerDocument, options);
        }
export type SettingDrawerQueryHookResult = ReturnType<typeof useSettingDrawerQuery>;
export type SettingDrawerLazyQueryHookResult = ReturnType<typeof useSettingDrawerLazyQuery>;
export type SettingDrawerQueryResult = Apollo.QueryResult<SettingDrawerQuery, SettingDrawerQueryVariables>;
export const UpdateCounselMemoDocument = gql`
    mutation updateCounselMemo($counselId: ID!, $data: UpdateCounselData!) {
  updateCounsel(counselId: $counselId, data: $data) {
    ... on UpdateCounselSuccess {
      counsel {
        id
        memo
      }
    }
  }
}
    `;
export type UpdateCounselMemoMutationFn = Apollo.MutationFunction<UpdateCounselMemoMutation, UpdateCounselMemoMutationVariables>;

/**
 * __useUpdateCounselMemoMutation__
 *
 * To run a mutation, you first call `useUpdateCounselMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCounselMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCounselMemoMutation, { data, loading, error }] = useUpdateCounselMemoMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCounselMemoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCounselMemoMutation, UpdateCounselMemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCounselMemoMutation, UpdateCounselMemoMutationVariables>(UpdateCounselMemoDocument, options);
      }
export type UpdateCounselMemoMutationHookResult = ReturnType<typeof useUpdateCounselMemoMutation>;
export type UpdateCounselMemoMutationResult = Apollo.MutationResult<UpdateCounselMemoMutation>;
export type UpdateCounselMemoMutationOptions = Apollo.BaseMutationOptions<UpdateCounselMemoMutation, UpdateCounselMemoMutationVariables>;
export const UpdatedCounselDetailDocument = gql`
    subscription updatedCounselDetail($counselId: ID!) {
  updatedCounsel(counselId: $counselId) {
    ...counselDetailInfo
  }
}
    ${CounselDetailInfoFragmentDoc}`;

/**
 * __useUpdatedCounselDetailSubscription__
 *
 * To run a query within a React component, call `useUpdatedCounselDetailSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedCounselDetailSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedCounselDetailSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useUpdatedCounselDetailSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdatedCounselDetailSubscription, UpdatedCounselDetailSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedCounselDetailSubscription, UpdatedCounselDetailSubscriptionVariables>(UpdatedCounselDetailDocument, options);
      }
export type UpdatedCounselDetailSubscriptionHookResult = ReturnType<typeof useUpdatedCounselDetailSubscription>;
export type UpdatedCounselDetailSubscriptionResult = Apollo.SubscriptionResult<UpdatedCounselDetailSubscription>;
export const CounselDetailDocument = gql`
    query CounselDetail($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    createdAt
    insurance
    type {
      id
      name
    }
    memo
    body
    files {
      url
      description
    }
    areas {
      zone {
        province
        district
        neighborhood
      }
      coordinates {
        latitude
        longitude
      }
      radius
    }
    post {
      id
      title
      body
      hashtags {
        id
        name
      }
      files {
        url
        description
      }
      areas {
        zone {
          province
          district
          neighborhood
        }
        coordinates {
          latitude
          longitude
        }
        radius
      }
    }
    status
    counselee {
      id
      nickname
      inactiveCounselConnection(filter: {}, first: 5) {
        edges {
          node {
            id
            index
            memo
            createdAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCounselDetailQuery__
 *
 * To run a query within a React component, call `useCounselDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselDetailQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounselDetailQuery(baseOptions: Apollo.QueryHookOptions<CounselDetailQuery, CounselDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselDetailQuery, CounselDetailQueryVariables>(CounselDetailDocument, options);
      }
export function useCounselDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselDetailQuery, CounselDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselDetailQuery, CounselDetailQueryVariables>(CounselDetailDocument, options);
        }
export type CounselDetailQueryHookResult = ReturnType<typeof useCounselDetailQuery>;
export type CounselDetailLazyQueryHookResult = ReturnType<typeof useCounselDetailLazyQuery>;
export type CounselDetailQueryResult = Apollo.QueryResult<CounselDetailQuery, CounselDetailQueryVariables>;
export const CreateEmptyOrderDocument = gql`
    mutation createEmptyOrder($userId: ID!, $name: String!, $counselId: ID!, $vehicleId: ID!) {
  createOrder(
    userId: $userId
    counselId: $counselId
    vehicleId: $vehicleId
    data: {items: [], name: $name, shippingCharge: 7000}
  ) {
    order {
      ...taskpadOrder
    }
  }
}
    ${TaskpadOrderFragmentDoc}`;
export type CreateEmptyOrderMutationFn = Apollo.MutationFunction<CreateEmptyOrderMutation, CreateEmptyOrderMutationVariables>;

/**
 * __useCreateEmptyOrderMutation__
 *
 * To run a mutation, you first call `useCreateEmptyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyOrderMutation, { data, loading, error }] = useCreateEmptyOrderMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *      counselId: // value for 'counselId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCreateEmptyOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmptyOrderMutation, CreateEmptyOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmptyOrderMutation, CreateEmptyOrderMutationVariables>(CreateEmptyOrderDocument, options);
      }
export type CreateEmptyOrderMutationHookResult = ReturnType<typeof useCreateEmptyOrderMutation>;
export type CreateEmptyOrderMutationResult = Apollo.MutationResult<CreateEmptyOrderMutation>;
export type CreateEmptyOrderMutationOptions = Apollo.BaseMutationOptions<CreateEmptyOrderMutation, CreateEmptyOrderMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($orderId: ID!) {
  deleteOrder(orderId: $orderId) {
    order {
      id
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($data: UpdateOrderData!, $orderId: ID!) {
  updateOrder(data: $data, orderId: $orderId) {
    order {
      id
      __typename
      amount
      createdAt
      expiresAt
      discountAmount
      totalItemAmount
      totalAmount
      paymentAmount
      vehicle {
        id
        brand {
          id
          name
        }
      }
      items {
        code
        name
        discountRate
        price
        quantity
        totalAmount
        paymentAmount
      }
      name
      payment {
        id
        amount
        completedAt
        cancelledAt
        status
      }
      shippingCharge
      recommendedReason
      rejectedReason
      shipping {
        id
        status
        trackingId
        contact
        destination
        method
        recipient
        carrier
        deliveredAt
      }
      ... on InsurancedOrder {
        cashbackAmount
        cashbackRate
      }
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const SendEstimateMessageDocument = gql`
    mutation sendEstimateMessage($counselId: ID!, $orderId: ID!) {
  sendEstimateMessage(counselId: $counselId, orderId: $orderId) {
    message {
      ...counselRoomMessage
    }
  }
}
    ${CounselRoomMessageFragmentDoc}`;
export type SendEstimateMessageMutationFn = Apollo.MutationFunction<SendEstimateMessageMutation, SendEstimateMessageMutationVariables>;

/**
 * __useSendEstimateMessageMutation__
 *
 * To run a mutation, you first call `useSendEstimateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEstimateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEstimateMessageMutation, { data, loading, error }] = useSendEstimateMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSendEstimateMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendEstimateMessageMutation, SendEstimateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEstimateMessageMutation, SendEstimateMessageMutationVariables>(SendEstimateMessageDocument, options);
      }
export type SendEstimateMessageMutationHookResult = ReturnType<typeof useSendEstimateMessageMutation>;
export type SendEstimateMessageMutationResult = Apollo.MutationResult<SendEstimateMessageMutation>;
export type SendEstimateMessageMutationOptions = Apollo.BaseMutationOptions<SendEstimateMessageMutation, SendEstimateMessageMutationVariables>;
export const CreateShippingDocument = gql`
    mutation createShipping($data: CreateShippingData!, $orderId: ID!) {
  createShipping(data: $data, orderId: $orderId) {
    shipping {
      id
    }
  }
}
    `;
export type CreateShippingMutationFn = Apollo.MutationFunction<CreateShippingMutation, CreateShippingMutationVariables>;

/**
 * __useCreateShippingMutation__
 *
 * To run a mutation, you first call `useCreateShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShippingMutation, { data, loading, error }] = useCreateShippingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCreateShippingMutation(baseOptions?: Apollo.MutationHookOptions<CreateShippingMutation, CreateShippingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShippingMutation, CreateShippingMutationVariables>(CreateShippingDocument, options);
      }
export type CreateShippingMutationHookResult = ReturnType<typeof useCreateShippingMutation>;
export type CreateShippingMutationResult = Apollo.MutationResult<CreateShippingMutation>;
export type CreateShippingMutationOptions = Apollo.BaseMutationOptions<CreateShippingMutation, CreateShippingMutationVariables>;
export const SendInvoiceMessageDocument = gql`
    mutation sendInvoiceMessage($counselId: ID!, $orderId: ID!) {
  sendInvoiceMessage(counselId: $counselId, orderId: $orderId) {
    message {
      ...counselRoomMessage
    }
  }
}
    ${CounselRoomMessageFragmentDoc}`;
export type SendInvoiceMessageMutationFn = Apollo.MutationFunction<SendInvoiceMessageMutation, SendInvoiceMessageMutationVariables>;

/**
 * __useSendInvoiceMessageMutation__
 *
 * To run a mutation, you first call `useSendInvoiceMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceMessageMutation, { data, loading, error }] = useSendInvoiceMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSendInvoiceMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceMessageMutation, SendInvoiceMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceMessageMutation, SendInvoiceMessageMutationVariables>(SendInvoiceMessageDocument, options);
      }
export type SendInvoiceMessageMutationHookResult = ReturnType<typeof useSendInvoiceMessageMutation>;
export type SendInvoiceMessageMutationResult = Apollo.MutationResult<SendInvoiceMessageMutation>;
export type SendInvoiceMessageMutationOptions = Apollo.BaseMutationOptions<SendInvoiceMessageMutation, SendInvoiceMessageMutationVariables>;
export const CancelPaymentDocument = gql`
    mutation cancelPayment($paymentId: String!, $reason: String!) {
  cancelPayment(paymentId: $paymentId, reason: $reason) {
    payment {
      id
      amount
      cancelledAt
    }
  }
}
    `;
export type CancelPaymentMutationFn = Apollo.MutationFunction<CancelPaymentMutation, CancelPaymentMutationVariables>;

/**
 * __useCancelPaymentMutation__
 *
 * To run a mutation, you first call `useCancelPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentMutation, { data, loading, error }] = useCancelPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelPaymentMutation, CancelPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPaymentMutation, CancelPaymentMutationVariables>(CancelPaymentDocument, options);
      }
export type CancelPaymentMutationHookResult = ReturnType<typeof useCancelPaymentMutation>;
export type CancelPaymentMutationResult = Apollo.MutationResult<CancelPaymentMutation>;
export type CancelPaymentMutationOptions = Apollo.BaseMutationOptions<CancelPaymentMutation, CancelPaymentMutationVariables>;
export const UpdateShippingDocument = gql`
    mutation updateShipping($data: UpdateShippingData!, $shippingId: ID!) {
  updateShipping(data: $data, shippingId: $shippingId) {
    shipping {
      id
      status
      trackingId
      contact
      destination
      method
      recipient
      carrier
    }
  }
}
    `;
export type UpdateShippingMutationFn = Apollo.MutationFunction<UpdateShippingMutation, UpdateShippingMutationVariables>;

/**
 * __useUpdateShippingMutation__
 *
 * To run a mutation, you first call `useUpdateShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingMutation, { data, loading, error }] = useUpdateShippingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      shippingId: // value for 'shippingId'
 *   },
 * });
 */
export function useUpdateShippingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShippingMutation, UpdateShippingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShippingMutation, UpdateShippingMutationVariables>(UpdateShippingDocument, options);
      }
export type UpdateShippingMutationHookResult = ReturnType<typeof useUpdateShippingMutation>;
export type UpdateShippingMutationResult = Apollo.MutationResult<UpdateShippingMutation>;
export type UpdateShippingMutationOptions = Apollo.BaseMutationOptions<UpdateShippingMutation, UpdateShippingMutationVariables>;
export const ForceCompletePaymentDocument = gql`
    mutation forceCompletePayment($orderId: ID!, $paymentId: ID!) {
  forceCompletePayment(orderId: $orderId, paymentId: $paymentId) {
    ... on PaymentCompleted {
      payment {
        id
        amount
        completedAt
        status
      }
    }
    ... on PaymentUncompleted {
      payment {
        id
        status
        id
        amount
        completedAt
        status
      }
    }
  }
}
    `;
export type ForceCompletePaymentMutationFn = Apollo.MutationFunction<ForceCompletePaymentMutation, ForceCompletePaymentMutationVariables>;

/**
 * __useForceCompletePaymentMutation__
 *
 * To run a mutation, you first call `useForceCompletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceCompletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceCompletePaymentMutation, { data, loading, error }] = useForceCompletePaymentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useForceCompletePaymentMutation(baseOptions?: Apollo.MutationHookOptions<ForceCompletePaymentMutation, ForceCompletePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceCompletePaymentMutation, ForceCompletePaymentMutationVariables>(ForceCompletePaymentDocument, options);
      }
export type ForceCompletePaymentMutationHookResult = ReturnType<typeof useForceCompletePaymentMutation>;
export type ForceCompletePaymentMutationResult = Apollo.MutationResult<ForceCompletePaymentMutation>;
export type ForceCompletePaymentMutationOptions = Apollo.BaseMutationOptions<ForceCompletePaymentMutation, ForceCompletePaymentMutationVariables>;
export const UpsertItemsDocument = gql`
    mutation upsertItems($items: [CreateItemData!]!) {
  upsertItems(items: $items) {
    items {
      name
    }
  }
}
    `;
export type UpsertItemsMutationFn = Apollo.MutationFunction<UpsertItemsMutation, UpsertItemsMutationVariables>;

/**
 * __useUpsertItemsMutation__
 *
 * To run a mutation, you first call `useUpsertItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertItemsMutation, { data, loading, error }] = useUpsertItemsMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpsertItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertItemsMutation, UpsertItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertItemsMutation, UpsertItemsMutationVariables>(UpsertItemsDocument, options);
      }
export type UpsertItemsMutationHookResult = ReturnType<typeof useUpsertItemsMutation>;
export type UpsertItemsMutationResult = Apollo.MutationResult<UpsertItemsMutation>;
export type UpsertItemsMutationOptions = Apollo.BaseMutationOptions<UpsertItemsMutation, UpsertItemsMutationVariables>;
export const CounselOrderDocument = gql`
    query CounselOrder($counselId: ID!) {
  counsel(counselId: $counselId) {
    ...counselDetailInfo
    sentCompanies {
      id
      name
      contact
      address {
        name
        road
        region
      }
    }
    orders {
      id
      __typename
      amount
      createdAt
      expiresAt
      discountAmount
      totalItemAmount
      totalAmount
      paymentAmount
      vehicle {
        id
        brand {
          id
          name
        }
      }
      items {
        code
        name
        discountRate
        price
        quantity
        totalAmount
        paymentAmount
      }
      name
      payment {
        id
        amount
        completedAt
        cancelledAt
        status
      }
      shippingCharge
      recommendedReason
      rejectedReason
      shipping {
        id
        status
        trackingId
        contact
        destination
        method
        recipient
        carrier
        deliveredAt
      }
      ... on InsurancedOrder {
        cashbackAmount
        cashbackRate
      }
    }
  }
}
    ${CounselDetailInfoFragmentDoc}`;

/**
 * __useCounselOrderQuery__
 *
 * To run a query within a React component, call `useCounselOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselOrderQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounselOrderQuery(baseOptions: Apollo.QueryHookOptions<CounselOrderQuery, CounselOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselOrderQuery, CounselOrderQueryVariables>(CounselOrderDocument, options);
      }
export function useCounselOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselOrderQuery, CounselOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselOrderQuery, CounselOrderQueryVariables>(CounselOrderDocument, options);
        }
export type CounselOrderQueryHookResult = ReturnType<typeof useCounselOrderQuery>;
export type CounselOrderLazyQueryHookResult = ReturnType<typeof useCounselOrderLazyQuery>;
export type CounselOrderQueryResult = Apollo.QueryResult<CounselOrderQuery, CounselOrderQueryVariables>;
export const CompletedPaymentDocument = gql`
    subscription completedPayment($paymentId: ID!) {
  completedPayment(paymentId: $paymentId) {
    id
    amount
    completedAt
    cancelledAt
    status
    order {
      id
      shipping {
        id
      }
    }
  }
}
    `;

/**
 * __useCompletedPaymentSubscription__
 *
 * To run a query within a React component, call `useCompletedPaymentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCompletedPaymentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedPaymentSubscription({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useCompletedPaymentSubscription(baseOptions: Apollo.SubscriptionHookOptions<CompletedPaymentSubscription, CompletedPaymentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CompletedPaymentSubscription, CompletedPaymentSubscriptionVariables>(CompletedPaymentDocument, options);
      }
export type CompletedPaymentSubscriptionHookResult = ReturnType<typeof useCompletedPaymentSubscription>;
export type CompletedPaymentSubscriptionResult = Apollo.SubscriptionResult<CompletedPaymentSubscription>;
export const CompaniesSearchDocument = gql`
    query companiesSearch($filter: CompaniesFilter!, $offset: Int!, $limit: Int!) {
  companies(filter: $filter, offset: $offset, limit: $limit) {
    id
    name
    address {
      road
      region
      coordinates {
        latitude
        longitude
      }
    }
    contact
    specialties {
      id
      name
    }
  }
  companiesCount(filter: $filter)
}
    `;

/**
 * __useCompaniesSearchQuery__
 *
 * To run a query within a React component, call `useCompaniesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompaniesSearchQuery(baseOptions: Apollo.QueryHookOptions<CompaniesSearchQuery, CompaniesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesSearchQuery, CompaniesSearchQueryVariables>(CompaniesSearchDocument, options);
      }
export function useCompaniesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesSearchQuery, CompaniesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesSearchQuery, CompaniesSearchQueryVariables>(CompaniesSearchDocument, options);
        }
export type CompaniesSearchQueryHookResult = ReturnType<typeof useCompaniesSearchQuery>;
export type CompaniesSearchLazyQueryHookResult = ReturnType<typeof useCompaniesSearchLazyQuery>;
export type CompaniesSearchQueryResult = Apollo.QueryResult<CompaniesSearchQuery, CompaniesSearchQueryVariables>;
export const CounseleeLastCounselsDocument = gql`
    query counseleeLastCounsels($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    status
    areas {
      coordinates {
        latitude
        longitude
      }
      radius
      zone {
        district
        neighborhood
        province
      }
    }
    counselee {
      id
      inactiveCounselConnection(filter: {}, first: 3) {
        edges {
          node {
            id
            index
            memo
            createdAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCounseleeLastCounselsQuery__
 *
 * To run a query within a React component, call `useCounseleeLastCounselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounseleeLastCounselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounseleeLastCounselsQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounseleeLastCounselsQuery(baseOptions: Apollo.QueryHookOptions<CounseleeLastCounselsQuery, CounseleeLastCounselsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounseleeLastCounselsQuery, CounseleeLastCounselsQueryVariables>(CounseleeLastCounselsDocument, options);
      }
export function useCounseleeLastCounselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounseleeLastCounselsQuery, CounseleeLastCounselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounseleeLastCounselsQuery, CounseleeLastCounselsQueryVariables>(CounseleeLastCounselsDocument, options);
        }
export type CounseleeLastCounselsQueryHookResult = ReturnType<typeof useCounseleeLastCounselsQuery>;
export type CounseleeLastCounselsLazyQueryHookResult = ReturnType<typeof useCounseleeLastCounselsLazyQuery>;
export type CounseleeLastCounselsQueryResult = Apollo.QueryResult<CounseleeLastCounselsQuery, CounseleeLastCounselsQueryVariables>;
export const CompanySpecialtiesDocument = gql`
    query companySpecialties {
  companySpecialties {
    id
    name
  }
}
    `;

/**
 * __useCompanySpecialtiesQuery__
 *
 * To run a query within a React component, call `useCompanySpecialtiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySpecialtiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySpecialtiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySpecialtiesQuery(baseOptions?: Apollo.QueryHookOptions<CompanySpecialtiesQuery, CompanySpecialtiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySpecialtiesQuery, CompanySpecialtiesQueryVariables>(CompanySpecialtiesDocument, options);
      }
export function useCompanySpecialtiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySpecialtiesQuery, CompanySpecialtiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySpecialtiesQuery, CompanySpecialtiesQueryVariables>(CompanySpecialtiesDocument, options);
        }
export type CompanySpecialtiesQueryHookResult = ReturnType<typeof useCompanySpecialtiesQuery>;
export type CompanySpecialtiesLazyQueryHookResult = ReturnType<typeof useCompanySpecialtiesLazyQuery>;
export type CompanySpecialtiesQueryResult = Apollo.QueryResult<CompanySpecialtiesQuery, CompanySpecialtiesQueryVariables>;
export const CreateTemplateDocument = gql`
    mutation createTemplate($data: CreateTemplateData!, $directoryId: ID!) {
  createTemplate(data: $data, directoryId: $directoryId) {
    template {
      id
    }
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      directoryId: // value for 'directoryId'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const TemplatePathDocument = gql`
    query templatePath($directoryId: ID!) {
  templateDirectory(directoryId: $directoryId) {
    id
    path {
      id
      name
    }
  }
}
    `;

/**
 * __useTemplatePathQuery__
 *
 * To run a query within a React component, call `useTemplatePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatePathQuery({
 *   variables: {
 *      directoryId: // value for 'directoryId'
 *   },
 * });
 */
export function useTemplatePathQuery(baseOptions: Apollo.QueryHookOptions<TemplatePathQuery, TemplatePathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatePathQuery, TemplatePathQueryVariables>(TemplatePathDocument, options);
      }
export function useTemplatePathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatePathQuery, TemplatePathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatePathQuery, TemplatePathQueryVariables>(TemplatePathDocument, options);
        }
export type TemplatePathQueryHookResult = ReturnType<typeof useTemplatePathQuery>;
export type TemplatePathLazyQueryHookResult = ReturnType<typeof useTemplatePathLazyQuery>;
export type TemplatePathQueryResult = Apollo.QueryResult<TemplatePathQuery, TemplatePathQueryVariables>;
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($data: UpdateTemplateData!, $templateId: ID!) {
  updateTemplate(data: $data, templateId: $templateId) {
    template {
      ...TemplateDetail
    }
  }
}
    ${TemplateDetailFragmentDoc}`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation deleteTemplate($templateId: ID!) {
  deleteTemplate(templateId: $templateId) {
    template {
      id
    }
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const CounselStatusDocument = gql`
    query counselStatus($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    status
  }
}
    `;

/**
 * __useCounselStatusQuery__
 *
 * To run a query within a React component, call `useCounselStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselStatusQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounselStatusQuery(baseOptions: Apollo.QueryHookOptions<CounselStatusQuery, CounselStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselStatusQuery, CounselStatusQueryVariables>(CounselStatusDocument, options);
      }
export function useCounselStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselStatusQuery, CounselStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselStatusQuery, CounselStatusQueryVariables>(CounselStatusDocument, options);
        }
export type CounselStatusQueryHookResult = ReturnType<typeof useCounselStatusQuery>;
export type CounselStatusLazyQueryHookResult = ReturnType<typeof useCounselStatusLazyQuery>;
export type CounselStatusQueryResult = Apollo.QueryResult<CounselStatusQuery, CounselStatusQueryVariables>;
export const TemplateDirectoryDocument = gql`
    query templateDirectory($directoryId: ID!) {
  templateDirectory(directoryId: $directoryId) {
    id
    name
    path {
      id
      name
    }
    parent {
      id
    }
    templates {
      id
      name
    }
    subdirectories {
      id
      name
      subdirectories {
        id
      }
      templates {
        id
      }
    }
  }
}
    `;

/**
 * __useTemplateDirectoryQuery__
 *
 * To run a query within a React component, call `useTemplateDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateDirectoryQuery({
 *   variables: {
 *      directoryId: // value for 'directoryId'
 *   },
 * });
 */
export function useTemplateDirectoryQuery(baseOptions: Apollo.QueryHookOptions<TemplateDirectoryQuery, TemplateDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateDirectoryQuery, TemplateDirectoryQueryVariables>(TemplateDirectoryDocument, options);
      }
export function useTemplateDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateDirectoryQuery, TemplateDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateDirectoryQuery, TemplateDirectoryQueryVariables>(TemplateDirectoryDocument, options);
        }
export type TemplateDirectoryQueryHookResult = ReturnType<typeof useTemplateDirectoryQuery>;
export type TemplateDirectoryLazyQueryHookResult = ReturnType<typeof useTemplateDirectoryLazyQuery>;
export type TemplateDirectoryQueryResult = Apollo.QueryResult<TemplateDirectoryQuery, TemplateDirectoryQueryVariables>;
export const TemplateShortcutsDocument = gql`
    query templateShortcuts($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    type {
      id
      name
    }
    vehicle {
      id
      brand {
        id
      }
      model {
        id
      }
      submodel {
        id
      }
      grade {
        id
      }
      trim {
        id
      }
      fullModelName
    }
  }
}
    `;

/**
 * __useTemplateShortcutsQuery__
 *
 * To run a query within a React component, call `useTemplateShortcutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateShortcutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateShortcutsQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useTemplateShortcutsQuery(baseOptions: Apollo.QueryHookOptions<TemplateShortcutsQuery, TemplateShortcutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateShortcutsQuery, TemplateShortcutsQueryVariables>(TemplateShortcutsDocument, options);
      }
export function useTemplateShortcutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateShortcutsQuery, TemplateShortcutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateShortcutsQuery, TemplateShortcutsQueryVariables>(TemplateShortcutsDocument, options);
        }
export type TemplateShortcutsQueryHookResult = ReturnType<typeof useTemplateShortcutsQuery>;
export type TemplateShortcutsLazyQueryHookResult = ReturnType<typeof useTemplateShortcutsLazyQuery>;
export type TemplateShortcutsQueryResult = Apollo.QueryResult<TemplateShortcutsQuery, TemplateShortcutsQueryVariables>;
export const TemplateDocument = gql`
    query template($templateId: ID!) {
  template(templateId: $templateId) {
    ...TemplateDetail
  }
}
    ${TemplateDetailFragmentDoc}`;

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, options);
      }
export function useTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, options);
        }
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export type TemplateQueryResult = Apollo.QueryResult<TemplateQuery, TemplateQueryVariables>;
export const TemplateFileUploadDocument = gql`
    query templateFileUpload($file: FileUploadInput!) {
  userContentFileUpload(file: $file) {
    uploadUrl
    fileUrl
  }
}
    `;

/**
 * __useTemplateFileUploadQuery__
 *
 * To run a query within a React component, call `useTemplateFileUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateFileUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateFileUploadQuery({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useTemplateFileUploadQuery(baseOptions: Apollo.QueryHookOptions<TemplateFileUploadQuery, TemplateFileUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateFileUploadQuery, TemplateFileUploadQueryVariables>(TemplateFileUploadDocument, options);
      }
export function useTemplateFileUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateFileUploadQuery, TemplateFileUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateFileUploadQuery, TemplateFileUploadQueryVariables>(TemplateFileUploadDocument, options);
        }
export type TemplateFileUploadQueryHookResult = ReturnType<typeof useTemplateFileUploadQuery>;
export type TemplateFileUploadLazyQueryHookResult = ReturnType<typeof useTemplateFileUploadLazyQuery>;
export type TemplateFileUploadQueryResult = Apollo.QueryResult<TemplateFileUploadQuery, TemplateFileUploadQueryVariables>;
export const VehicleCounselDocument = gql`
    query vehicleCounsel($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    counselee {
      id
      nickname
    }
    vehicle {
      id
      model {
        id
      }
      grade {
        segment
      }
      fullModelName
      shortModelName
      fuelType {
        base
        hybrid
      }
      plateNumber
      vin
      mileage
      tireSize {
        front {
          width
          aspectRatio
          wheelDiameter
        }
        rear {
          width
          aspectRatio
          wheelDiameter
        }
      }
    }
  }
}
    `;

/**
 * __useVehicleCounselQuery__
 *
 * To run a query within a React component, call `useVehicleCounselQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleCounselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleCounselQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useVehicleCounselQuery(baseOptions: Apollo.QueryHookOptions<VehicleCounselQuery, VehicleCounselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleCounselQuery, VehicleCounselQueryVariables>(VehicleCounselDocument, options);
      }
export function useVehicleCounselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleCounselQuery, VehicleCounselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleCounselQuery, VehicleCounselQueryVariables>(VehicleCounselDocument, options);
        }
export type VehicleCounselQueryHookResult = ReturnType<typeof useVehicleCounselQuery>;
export type VehicleCounselLazyQueryHookResult = ReturnType<typeof useVehicleCounselLazyQuery>;
export type VehicleCounselQueryResult = Apollo.QueryResult<VehicleCounselQuery, VehicleCounselQueryVariables>;
export const UpdateVehicleDocument = gql`
    mutation updateVehicle($vehicleId: ID!, $data: UpdateVehicleData!) {
  updateVehicle(vehicleId: $vehicleId, data: $data) {
    ... on UpdateVehicleSuccess {
      vehicle {
        id
        model {
          id
        }
        grade {
          segment
        }
        fullModelName
        shortModelName
        plateNumber
        vin
        mileage
        tireSize {
          front {
            width
            aspectRatio
            wheelDiameter
          }
          rear {
            width
            aspectRatio
            wheelDiameter
          }
        }
      }
    }
    ... on UpdateVehicleFail {
      error {
        message
      }
    }
  }
}
    `;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  localLogin(email: $email, password: $password) {
    accessToken
    expiresAt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CachedOrderDocument = gql`
    query CachedOrder($orderId: ID!) {
  order(orderId: $orderId) {
    ...taskpadOrder
  }
}
    ${TaskpadOrderFragmentDoc}`;

/**
 * __useCachedOrderQuery__
 *
 * To run a query within a React component, call `useCachedOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCachedOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCachedOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCachedOrderQuery(baseOptions: Apollo.QueryHookOptions<CachedOrderQuery, CachedOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CachedOrderQuery, CachedOrderQueryVariables>(CachedOrderDocument, options);
      }
export function useCachedOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CachedOrderQuery, CachedOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CachedOrderQuery, CachedOrderQueryVariables>(CachedOrderDocument, options);
        }
export type CachedOrderQueryHookResult = ReturnType<typeof useCachedOrderQuery>;
export type CachedOrderLazyQueryHookResult = ReturnType<typeof useCachedOrderLazyQuery>;
export type CachedOrderQueryResult = Apollo.QueryResult<CachedOrderQuery, CachedOrderQueryVariables>;
export const CachedCounselDetailDocument = gql`
    query CachedCounselDetail($counselId: ID!) {
  counsel(counselId: $counselId) {
    id
    ...counselDetailInfo
    orders {
      ...taskpadOrder
    }
  }
}
    ${CounselDetailInfoFragmentDoc}
${TaskpadOrderFragmentDoc}`;

/**
 * __useCachedCounselDetailQuery__
 *
 * To run a query within a React component, call `useCachedCounselDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCachedCounselDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCachedCounselDetailQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCachedCounselDetailQuery(baseOptions: Apollo.QueryHookOptions<CachedCounselDetailQuery, CachedCounselDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CachedCounselDetailQuery, CachedCounselDetailQueryVariables>(CachedCounselDetailDocument, options);
      }
export function useCachedCounselDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CachedCounselDetailQuery, CachedCounselDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CachedCounselDetailQuery, CachedCounselDetailQueryVariables>(CachedCounselDetailDocument, options);
        }
export type CachedCounselDetailQueryHookResult = ReturnType<typeof useCachedCounselDetailQuery>;
export type CachedCounselDetailLazyQueryHookResult = ReturnType<typeof useCachedCounselDetailLazyQuery>;
export type CachedCounselDetailQueryResult = Apollo.QueryResult<CachedCounselDetailQuery, CachedCounselDetailQueryVariables>;
export const FileUploadUrlDocument = gql`
    query fileUploadUrl($file: FileUploadInput!) {
  userContentFileUpload(file: $file) {
    uploadUrl
    fileUrl
  }
}
    `;

/**
 * __useFileUploadUrlQuery__
 *
 * To run a query within a React component, call `useFileUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileUploadUrlQuery({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useFileUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<FileUploadUrlQuery, FileUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileUploadUrlQuery, FileUploadUrlQueryVariables>(FileUploadUrlDocument, options);
      }
export function useFileUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileUploadUrlQuery, FileUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileUploadUrlQuery, FileUploadUrlQueryVariables>(FileUploadUrlDocument, options);
        }
export type FileUploadUrlQueryHookResult = ReturnType<typeof useFileUploadUrlQuery>;
export type FileUploadUrlLazyQueryHookResult = ReturnType<typeof useFileUploadUrlLazyQuery>;
export type FileUploadUrlQueryResult = Apollo.QueryResult<FileUploadUrlQuery, FileUploadUrlQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    avatar {
      url
    }
    email
    role
    nickname
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SendTextMessageDocument = gql`
    mutation sendTextMessage($counselId: ID!, $body: String!) {
  sendTextMessage(counselId: $counselId, body: $body) {
    ... on SendTextMessageSuccess {
      message {
        ...counselRoomMessage
      }
    }
  }
}
    ${CounselRoomMessageFragmentDoc}`;
export type SendTextMessageMutationFn = Apollo.MutationFunction<SendTextMessageMutation, SendTextMessageMutationVariables>;

/**
 * __useSendTextMessageMutation__
 *
 * To run a mutation, you first call `useSendTextMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTextMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTextMessageMutation, { data, loading, error }] = useSendTextMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendTextMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendTextMessageMutation, SendTextMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTextMessageMutation, SendTextMessageMutationVariables>(SendTextMessageDocument, options);
      }
export type SendTextMessageMutationHookResult = ReturnType<typeof useSendTextMessageMutation>;
export type SendTextMessageMutationResult = Apollo.MutationResult<SendTextMessageMutation>;
export type SendTextMessageMutationOptions = Apollo.BaseMutationOptions<SendTextMessageMutation, SendTextMessageMutationVariables>;
export const SendFileMessageDocument = gql`
    mutation sendFileMessage($counselId: ID!, $file: FileInput!) {
  sendFileMessage(counselId: $counselId, file: $file) {
    ... on SendFileMessageSuccess {
      message {
        ...counselRoomMessage
      }
    }
  }
}
    ${CounselRoomMessageFragmentDoc}`;
export type SendFileMessageMutationFn = Apollo.MutationFunction<SendFileMessageMutation, SendFileMessageMutationVariables>;

/**
 * __useSendFileMessageMutation__
 *
 * To run a mutation, you first call `useSendFileMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFileMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFileMessageMutation, { data, loading, error }] = useSendFileMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSendFileMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendFileMessageMutation, SendFileMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFileMessageMutation, SendFileMessageMutationVariables>(SendFileMessageDocument, options);
      }
export type SendFileMessageMutationHookResult = ReturnType<typeof useSendFileMessageMutation>;
export type SendFileMessageMutationResult = Apollo.MutationResult<SendFileMessageMutation>;
export type SendFileMessageMutationOptions = Apollo.BaseMutationOptions<SendFileMessageMutation, SendFileMessageMutationVariables>;
export const SendCompanyMessageDocument = gql`
    mutation sendCompanyMessage($counselId: ID!, $companyId: ID!) {
  sendCompanyMessage(counselId: $counselId, companyId: $companyId) {
    ... on SendCompanyMessageSuccess {
      message {
        ...counselRoomMessage
      }
    }
  }
}
    ${CounselRoomMessageFragmentDoc}`;
export type SendCompanyMessageMutationFn = Apollo.MutationFunction<SendCompanyMessageMutation, SendCompanyMessageMutationVariables>;

/**
 * __useSendCompanyMessageMutation__
 *
 * To run a mutation, you first call `useSendCompanyMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompanyMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompanyMessageMutation, { data, loading, error }] = useSendCompanyMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSendCompanyMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendCompanyMessageMutation, SendCompanyMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompanyMessageMutation, SendCompanyMessageMutationVariables>(SendCompanyMessageDocument, options);
      }
export type SendCompanyMessageMutationHookResult = ReturnType<typeof useSendCompanyMessageMutation>;
export type SendCompanyMessageMutationResult = Apollo.MutationResult<SendCompanyMessageMutation>;
export type SendCompanyMessageMutationOptions = Apollo.BaseMutationOptions<SendCompanyMessageMutation, SendCompanyMessageMutationVariables>;
export const SendAreaInputMessageDocument = gql`
    mutation sendAreaInputMessage($counselId: ID!) {
  sendAreaInputMessage(counselId: $counselId) {
    ... on SendAreaInputMessageSuccess {
      message {
        author {
          id
        }
        counsel {
          id
        }
      }
    }
  }
}
    `;
export type SendAreaInputMessageMutationFn = Apollo.MutationFunction<SendAreaInputMessageMutation, SendAreaInputMessageMutationVariables>;

/**
 * __useSendAreaInputMessageMutation__
 *
 * To run a mutation, you first call `useSendAreaInputMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAreaInputMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAreaInputMessageMutation, { data, loading, error }] = useSendAreaInputMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useSendAreaInputMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendAreaInputMessageMutation, SendAreaInputMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAreaInputMessageMutation, SendAreaInputMessageMutationVariables>(SendAreaInputMessageDocument, options);
      }
export type SendAreaInputMessageMutationHookResult = ReturnType<typeof useSendAreaInputMessageMutation>;
export type SendAreaInputMessageMutationResult = Apollo.MutationResult<SendAreaInputMessageMutation>;
export type SendAreaInputMessageMutationOptions = Apollo.BaseMutationOptions<SendAreaInputMessageMutation, SendAreaInputMessageMutationVariables>;
export const SendVinInputMessageDocument = gql`
    mutation sendVinInputMessage($counselId: ID!, $vehicleId: ID!) {
  sendVinInputMessage(counselId: $counselId, vehicleId: $vehicleId) {
    ... on SendVinInputMessageSuccess {
      message {
        id
        author {
          id
        }
        vehicle {
          id
        }
      }
    }
  }
}
    `;
export type SendVinInputMessageMutationFn = Apollo.MutationFunction<SendVinInputMessageMutation, SendVinInputMessageMutationVariables>;

/**
 * __useSendVinInputMessageMutation__
 *
 * To run a mutation, you first call `useSendVinInputMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVinInputMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVinInputMessageMutation, { data, loading, error }] = useSendVinInputMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useSendVinInputMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendVinInputMessageMutation, SendVinInputMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVinInputMessageMutation, SendVinInputMessageMutationVariables>(SendVinInputMessageDocument, options);
      }
export type SendVinInputMessageMutationHookResult = ReturnType<typeof useSendVinInputMessageMutation>;
export type SendVinInputMessageMutationResult = Apollo.MutationResult<SendVinInputMessageMutation>;
export type SendVinInputMessageMutationOptions = Apollo.BaseMutationOptions<SendVinInputMessageMutation, SendVinInputMessageMutationVariables>;
export const SendPlateNumberInputMessageDocument = gql`
    mutation sendPlateNumberInputMessage($counselId: ID!, $vehicleId: ID!) {
  sendPlateNumberInputMessage(counselId: $counselId, vehicleId: $vehicleId) {
    ... on SendPlateNumberInputMessageSuccess {
      message {
        vehicle {
          id
        }
      }
    }
  }
}
    `;
export type SendPlateNumberInputMessageMutationFn = Apollo.MutationFunction<SendPlateNumberInputMessageMutation, SendPlateNumberInputMessageMutationVariables>;

/**
 * __useSendPlateNumberInputMessageMutation__
 *
 * To run a mutation, you first call `useSendPlateNumberInputMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPlateNumberInputMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPlateNumberInputMessageMutation, { data, loading, error }] = useSendPlateNumberInputMessageMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useSendPlateNumberInputMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendPlateNumberInputMessageMutation, SendPlateNumberInputMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPlateNumberInputMessageMutation, SendPlateNumberInputMessageMutationVariables>(SendPlateNumberInputMessageDocument, options);
      }
export type SendPlateNumberInputMessageMutationHookResult = ReturnType<typeof useSendPlateNumberInputMessageMutation>;
export type SendPlateNumberInputMessageMutationResult = Apollo.MutationResult<SendPlateNumberInputMessageMutation>;
export type SendPlateNumberInputMessageMutationOptions = Apollo.BaseMutationOptions<SendPlateNumberInputMessageMutation, SendPlateNumberInputMessageMutationVariables>;
export const UpdateCursorDocument = gql`
    mutation updateCursor($counselId: ID!, $messageId: ID!) {
  updateCursor(counselId: $counselId, messageId: $messageId) {
    ... on UpdateCursorSuccess {
      cursor {
        id
        counsel {
          id
        }
        message {
          id
        }
        user {
          id
        }
      }
    }
  }
}
    `;
export type UpdateCursorMutationFn = Apollo.MutationFunction<UpdateCursorMutation, UpdateCursorMutationVariables>;

/**
 * __useUpdateCursorMutation__
 *
 * To run a mutation, you first call `useUpdateCursorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCursorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCursorMutation, { data, loading, error }] = useUpdateCursorMutation({
 *   variables: {
 *      counselId: // value for 'counselId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useUpdateCursorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCursorMutation, UpdateCursorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCursorMutation, UpdateCursorMutationVariables>(UpdateCursorDocument, options);
      }
export type UpdateCursorMutationHookResult = ReturnType<typeof useUpdateCursorMutation>;
export type UpdateCursorMutationResult = Apollo.MutationResult<UpdateCursorMutation>;
export type UpdateCursorMutationOptions = Apollo.BaseMutationOptions<UpdateCursorMutation, UpdateCursorMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation deleteMessage($messageId: ID!) {
  deleteMessage(messageId: $messageId) {
    message {
      id
      deletedAt
      status
    }
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const CounselWindowTitleDocument = gql`
    query counselWindowTitle($counselId: ID!) {
  counsel(counselId: $counselId) {
    ...counselWindowTitleData
  }
}
    ${CounselWindowTitleDataFragmentDoc}`;

/**
 * __useCounselWindowTitleQuery__
 *
 * To run a query within a React component, call `useCounselWindowTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselWindowTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselWindowTitleQuery({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useCounselWindowTitleQuery(baseOptions: Apollo.QueryHookOptions<CounselWindowTitleQuery, CounselWindowTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounselWindowTitleQuery, CounselWindowTitleQueryVariables>(CounselWindowTitleDocument, options);
      }
export function useCounselWindowTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounselWindowTitleQuery, CounselWindowTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounselWindowTitleQuery, CounselWindowTitleQueryVariables>(CounselWindowTitleDocument, options);
        }
export type CounselWindowTitleQueryHookResult = ReturnType<typeof useCounselWindowTitleQuery>;
export type CounselWindowTitleLazyQueryHookResult = ReturnType<typeof useCounselWindowTitleLazyQuery>;
export type CounselWindowTitleQueryResult = Apollo.QueryResult<CounselWindowTitleQuery, CounselWindowTitleQueryVariables>;
export const UpdatedCounselWindowDocument = gql`
    subscription updatedCounselWindow($counselId: ID!) {
  updatedCounsel(counselId: $counselId) {
    ...counselWindowTitleData
  }
}
    ${CounselWindowTitleDataFragmentDoc}`;

/**
 * __useUpdatedCounselWindowSubscription__
 *
 * To run a query within a React component, call `useUpdatedCounselWindowSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedCounselWindowSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedCounselWindowSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useUpdatedCounselWindowSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdatedCounselWindowSubscription, UpdatedCounselWindowSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedCounselWindowSubscription, UpdatedCounselWindowSubscriptionVariables>(UpdatedCounselWindowDocument, options);
      }
export type UpdatedCounselWindowSubscriptionHookResult = ReturnType<typeof useUpdatedCounselWindowSubscription>;
export type UpdatedCounselWindowSubscriptionResult = Apollo.SubscriptionResult<UpdatedCounselWindowSubscription>;
export const UpdatedCursorOnCounselDocument = gql`
    subscription updatedCursorOnCounsel($counselId: ID!) {
  updatedCursorOnCounsel(counselId: $counselId) {
    id
    counsel {
      id
      cursors {
        id
      }
    }
    user {
      ...counselWindowUser
    }
    message {
      ...counselRoomMessage
    }
  }
}
    ${CounselWindowUserFragmentDoc}
${CounselRoomMessageFragmentDoc}`;

/**
 * __useUpdatedCursorOnCounselSubscription__
 *
 * To run a query within a React component, call `useUpdatedCursorOnCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedCursorOnCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedCursorOnCounselSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useUpdatedCursorOnCounselSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdatedCursorOnCounselSubscription, UpdatedCursorOnCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdatedCursorOnCounselSubscription, UpdatedCursorOnCounselSubscriptionVariables>(UpdatedCursorOnCounselDocument, options);
      }
export type UpdatedCursorOnCounselSubscriptionHookResult = ReturnType<typeof useUpdatedCursorOnCounselSubscription>;
export type UpdatedCursorOnCounselSubscriptionResult = Apollo.SubscriptionResult<UpdatedCursorOnCounselSubscription>;
export const NewMessageOnCounselDocument = gql`
    subscription newMessageOnCounsel($counselId: ID!) {
  newMessageOnCounsel(counselId: $counselId) {
    ...counselRoomMessage
  }
}
    ${CounselRoomMessageFragmentDoc}`;

/**
 * __useNewMessageOnCounselSubscription__
 *
 * To run a query within a React component, call `useNewMessageOnCounselSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageOnCounselSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageOnCounselSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useNewMessageOnCounselSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewMessageOnCounselSubscription, NewMessageOnCounselSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewMessageOnCounselSubscription, NewMessageOnCounselSubscriptionVariables>(NewMessageOnCounselDocument, options);
      }
export type NewMessageOnCounselSubscriptionHookResult = ReturnType<typeof useNewMessageOnCounselSubscription>;
export type NewMessageOnCounselSubscriptionResult = Apollo.SubscriptionResult<NewMessageOnCounselSubscription>;
export const AddedUserOnCounselWindowDocument = gql`
    subscription addedUserOnCounselWindow($counselId: ID!) {
  addedUserOnCounsel(counselId: $counselId) {
    ...counselWindowUser
  }
}
    ${CounselWindowUserFragmentDoc}`;

/**
 * __useAddedUserOnCounselWindowSubscription__
 *
 * To run a query within a React component, call `useAddedUserOnCounselWindowSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAddedUserOnCounselWindowSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddedUserOnCounselWindowSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useAddedUserOnCounselWindowSubscription(baseOptions: Apollo.SubscriptionHookOptions<AddedUserOnCounselWindowSubscription, AddedUserOnCounselWindowSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AddedUserOnCounselWindowSubscription, AddedUserOnCounselWindowSubscriptionVariables>(AddedUserOnCounselWindowDocument, options);
      }
export type AddedUserOnCounselWindowSubscriptionHookResult = ReturnType<typeof useAddedUserOnCounselWindowSubscription>;
export type AddedUserOnCounselWindowSubscriptionResult = Apollo.SubscriptionResult<AddedUserOnCounselWindowSubscription>;
export const RemovedUserOnCounselWindowDocument = gql`
    subscription removedUserOnCounselWindow($counselId: ID!) {
  removedUserOnCounsel(counselId: $counselId) {
    ...counselWindowUser
  }
}
    ${CounselWindowUserFragmentDoc}`;

/**
 * __useRemovedUserOnCounselWindowSubscription__
 *
 * To run a query within a React component, call `useRemovedUserOnCounselWindowSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRemovedUserOnCounselWindowSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemovedUserOnCounselWindowSubscription({
 *   variables: {
 *      counselId: // value for 'counselId'
 *   },
 * });
 */
export function useRemovedUserOnCounselWindowSubscription(baseOptions: Apollo.SubscriptionHookOptions<RemovedUserOnCounselWindowSubscription, RemovedUserOnCounselWindowSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RemovedUserOnCounselWindowSubscription, RemovedUserOnCounselWindowSubscriptionVariables>(RemovedUserOnCounselWindowDocument, options);
      }
export type RemovedUserOnCounselWindowSubscriptionHookResult = ReturnType<typeof useRemovedUserOnCounselWindowSubscription>;
export type RemovedUserOnCounselWindowSubscriptionResult = Apollo.SubscriptionResult<RemovedUserOnCounselWindowSubscription>;
export const RefreshAccessTokenDocument = gql`
    mutation refreshAccessToken {
  refreshAccessToken {
    accessToken
    expiresAt
  }
}
    `;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, options);
      }
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;