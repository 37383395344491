import {
  FileImageOutlined,
  IdcardOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Avatar, Button, Drawer, Form, Input, Spin, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { useFileUpload } from '@hooks';
import {
  useUpdateProfileMutation,
  useSettingDrawerQuery,
  FileType,
} from '@utils/client';
import { selectFile } from '@utils/file';

gql`
  mutation updateProfile($data: UpdateUserData!) {
    updateMe(data: $data) {
      user {
        id
        avatar {
          url
        }
        nickname
      }
    }
  }
`;

gql`
  query settingDrawer {
    me {
      id
      avatar {
        url
      }
      nickname
    }
  }
`;

interface SettingDrawerProps {
  className?: string;
}
const SettingDrawer: React.FC<SettingDrawerProps> = ({ className }) => {
  const [updateProfileMutation] = useUpdateProfileMutation();
  const { upload } = useFileUpload();

  const uploadAvatarFile = async (file: File): Promise<string | undefined> => {
    const fileData = await upload(file);

    return fileData.url;
  };

  const { data } = useSettingDrawerQuery();

  const [form] = Form.useForm<{ nickname: string; name: string }>();

  const [visible, setVisible] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [avatar, setAvatar] = React.useState(data?.me.avatar);

  const save = async () => {
    const fields = await form.validateFields();

    setSaving(true);
    await updateProfileMutation({
      variables: {
        data: {
          avatar: avatar?.url
            ? {
                url: avatar.url,
                type: FileType.Image,
              }
            : undefined,
          nickname: fields.nickname,
          name: fields.name,
        },
      },
      onCompleted: () => {
        notification.success({
          message: '프로필이 수정되었습니다.',
        });
        setVisible(false);
      },
      onError: error => {
        notification.error({
          message: '프로필 수정에 실패했습니다.',
          description: error.message,
        });
      },
    });
    setSaving(false);
  };

  const close = () => {
    setVisible(false);
    setAvatar(data?.me.avatar);
    form.setFieldsValue({ nickname: data?.me.nickname || '' });
  };

  const changeAvatar = async () => {
    setUploading(true);

    try {
      const file = await selectFile(['image/*']);

      try {
        const url = await uploadAvatarFile(file);
        if (url) {
          setAvatar({ url });
        }
      } catch (error: any) {
        notification.error({
          message: '프로필 사진 파일을 업로드하지 못했습니다',
          description: error?.message,
        });
      }
    } catch (error: any) {
      notification.error({
        message: '프로필 사진 파일을 불러오지 못했습니다',
        description: error?.message,
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Button
        type="text"
        icon={<SettingOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        className={className}
        title="내 프로필"
        placement="right"
        visible={visible}
        onClose={close}
        footer={
          <>
            <Button onClick={close}>취소</Button>
            <Button
              type="primary"
              disabled={uploading || saving}
              onClick={save}
            >
              {saving ? '저장중...' : '저장'}
            </Button>
          </>
        }
        contentWrapperStyle={{
          width: '315px',
        }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        footerStyle={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 10,
        }}
      >
        {data && (
          <>
            <SAvatarWrapper>
              <Avatar
                size={160}
                src={avatar?.url || undefined}
                icon={<UserOutlined />}
              />
              {uploading && <Spin style={{ position: 'absolute' }} />}
            </SAvatarWrapper>
            <SChangeAvatarButton onClick={changeAvatar}>
              <FileImageOutlined /> 사진 변경
            </SChangeAvatarButton>
            <SForm form={form} layout="vertical" initialValues={data?.me}>
              <Form.Item
                name="nickname"
                label="닉네임"
                rules={[
                  { required: true, message: '닉네임을 입력해주세요' },
                  { min: 2, message: '2자 이상의 닉네임을 입력해주세요' },
                  { max: 10, message: '10자 이내의 닉네임을 입력해주세요' },
                ]}
              >
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                name="name"
                label="본명"
                rules={[
                  { min: 2, message: '2자 이상의 닉네임을 입력해주세요' },
                  { max: 10, message: '10자 이내의 닉네임을 입력해주세요' },
                ]}
              >
                <Input prefix={<IdcardOutlined />} />
              </Form.Item>
            </SForm>
          </>
        )}
      </Drawer>
    </>
  );
};

const SAvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
`;
const SChangeAvatarButton = styled(Button)`
  width: 160px;
  margin-top: 8px;
`;
const SForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 50px;
`;

export default SettingDrawer;
