import {
  DeleteOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Input, notification, Popover, Typography, Upload } from 'antd';
import cuid from 'cuid';
import _ from 'lodash';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

import Cell from './Cell';

import { useFileUpload } from '@hooks';

type TextContent = {
  type: 'text';
  body: string;
};
type FileContent = {
  type: 'image' | 'video';
  url: string;
  width: number;
  height: number;
  duration?: number;
};

export type TemplateContentProp = TextContent | FileContent;
export type TemplateContent = TemplateContentProp & { id: string };

export interface TemplateEditorRef {
  initialize: () => void;
  getContents: () => TemplateContent[];
}
interface TemplateEditorProps {
  className?: string;
  defaultContents?: TemplateContentProp[];
  onChange?: (change: { edited: boolean; contents: TemplateContent[] }) => void;
}
const TemplateEditor = React.forwardRef<TemplateEditorRef, TemplateEditorProps>(
  ({ className, defaultContents: defaultContentsProp, onChange }, ref) => {
    const { upload } = useFileUpload();
    const defaultContents = React.useMemo<TemplateContent[]>(
      () =>
        defaultContentsProp?.map(content => ({ ...content, id: cuid() })) || [],
      [defaultContentsProp],
    );

    const [selectedContentId, setSelectedContentId] = React.useState<
      string | null
    >(null);
    const [contents, setContents] =
      React.useState<TemplateContent[]>(defaultContents);
    const [imageUrl, setImageUrl] = React.useState('');
    const [showUploadModal, setShowUploadModal] = React.useState(false);

    const initialize = React.useCallback(() => {
      setSelectedContentId(null);
      setContents(defaultContents);
    }, [defaultContents]);

    const getContents = React.useCallback(() => contents, [contents]);

    React.useEffect(() => {
      if (ref) {
        const refObject = { initialize, getContents };
        if (typeof ref === 'function') ref(refObject);
        else ref.current = refObject;
      }
    }, [ref, initialize, getContents]);

    React.useEffect(() => {
      if (onChange)
        onChange({ edited: !_.isEqual(contents, defaultContents), contents });
    }, [contents, defaultContents, onChange]);

    const insertCell = (content: TemplateContentProp) => {
      const id = cuid();
      setContents(contents => [...contents, { ...content, id }]);
      setSelectedContentId(id);
    };

    const insertTextCell = () => {
      const newContent: TextContent = { type: 'text', body: '' };

      insertCell(newContent);
    };

    const insertMediaCell = React.useCallback(
      async (file: File) => {
        try {
          const newContent: FileContent = await upload(file);
          insertCell(newContent);
        } catch (error: any) {
          notification.error({
            message: '미디어 불러오기 실패',
            description: error.message,
          });
        }
      },
      [upload],
    );

    const deleteCell = () => {
      if (!selectedContentId) return;

      setContents(contents => {
        let selectedId: string | null = null;

        if (contents.length > 1) {
          const selectedIndex = contents.findIndex(
            content => content.id === selectedContentId,
          );

          if (selectedIndex === contents.length - 1)
            selectedId = contents[selectedIndex - 1].id;
          else selectedId = contents[selectedIndex + 1].id;
        }

        setSelectedContentId(selectedId);
        return contents.filter(({ id }) => id !== selectedContentId);
      });
    };

    const onContentChange = (newContent: TemplateContent) => {
      setContents(contents =>
        contents.map(content =>
          content.id === newContent.id ? newContent : content,
        ),
      );
    };

    const onDragEnd = (result: DropResult) => {
      setContents(contents => {
        if (!result.destination) return contents;

        const sortedContents = [...contents];
        const [removed] = sortedContents.splice(result.source.index, 1);
        sortedContents.splice(result.destination.index, 0, removed);

        return sortedContents;
      });
    };

    const uploadLinkImage = React.useCallback(() => {
      if (imageUrl) {
        const image = new Image();
        image.src = imageUrl;

        setContents(contents => [
          ...contents,
          {
            id: cuid(),
            type: 'image',
            url: imageUrl,
            width: image.width,
            height: image.height,
          },
        ]);
        setImageUrl('');
        setShowUploadModal(false);
      }
    }, [imageUrl]);

    const renderContent = React.useCallback(
      () => (
        <div>
          <SUploadForm>
            <SUpload
              accept="image/*, video/*"
              beforeUpload={() => false}
              fileList={[]}
              onChange={e => {
                const file = e.file as unknown as File;
                insertMediaCell(file);
                setShowUploadModal(false);
              }}
            >
              <Button icon={<UploadOutlined />}>사진 선택 및 업로드</Button>
            </SUpload>
            <SText>또는</SText>
            <SButtonBox>
              <SInput
                placeholder="복사한 이미지 URL 입력"
                onChange={e => {
                  setImageUrl(e.target.value);
                }}
                value={imageUrl}
              />
              <SButton type="primary" onClick={uploadLinkImage}>
                입력
              </SButton>
            </SButtonBox>
          </SUploadForm>
        </div>
      ),
      [imageUrl, insertMediaCell, uploadLinkImage],
    );

    return (
      <STemplateEditor className={className}>
        <SToolBar>
          <Button
            size="small"
            icon={<FontSizeOutlined />}
            onClick={insertTextCell}
          >
            +
          </Button>
          <Popover
            trigger="click"
            placement="bottomLeft"
            visible={showUploadModal}
            content={renderContent}
          >
            <Button
              size="small"
              icon={<FileImageOutlined />}
              onClick={() => {
                setShowUploadModal(prev => !prev);
              }}
            >
              +
            </Button>
          </Popover>
          <Button
            danger
            size="small"
            disabled={!selectedContentId}
            icon={<DeleteOutlined />}
            onClick={deleteCell}
          />
        </SToolBar>

        <div style={{ height: 'calc(100vh - 185px)' }}>
          <DragDropContext
            onDragStart={initial => {
              setSelectedContentId(initial.draggableId);
            }}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="teamplte-cells">
              {(provided, snapshot) => (
                <SCells
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  dragging={!!snapshot.draggingFromThisWith}
                  draggingOver={snapshot.isDraggingOver}
                >
                  {contents.map((content, index) => (
                    <Draggable
                      key={content.id}
                      index={index}
                      draggableId={content.id}
                    >
                      {(provided, snapshot) => (
                        <SCell
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          dragging={snapshot.isDragging}
                          index={index}
                          selected={content.id === selectedContentId}
                          content={content}
                          onContentChange={newContent =>
                            onContentChange({ ...newContent, id: content.id })
                          }
                          onClick={() => setSelectedContentId(content.id)}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </SCells>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </STemplateEditor>
    );
  },
);

TemplateEditor.displayName = 'TemplateEditor';

export default React.memo(TemplateEditor);

const STemplateEditor = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;
const SToolBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
const SCells = styled.ul<{ dragging?: boolean; draggingOver?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 8px;
  max-height: 100%;
  overflow-y: auto;

  ${props => {
    if (props.draggingOver)
      return css`
        background: ${props.theme.colors.BACKGROUND_DARK};
      `;
    if (props.dragging)
      return css`
        background: #fff1f0;
      `;
    return null;
  }};
`;
const SCell = styled(Cell)<{ dragging?: boolean; selected?: boolean }>`
  ${props =>
    (props.dragging || props.selected) &&
    css`
      background: ${props.theme.colors.PRIMARY_LIGHT};
    `}
`;

const SUploadForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SButton = styled(Button)`
  margin-left: 4px;
`;

const SText = styled(Typography)`
  margin: 0 8px;
`;

const SInput = styled(Input)`
  flex: 1;
`;

const SUpload = styled(Upload)`
  flex: 1;
`;
