import { gql } from '@apollo/client';
import React from 'react';

import CreateTemplatePresenter from './CreateTemplatePresenter';

import { CreateTemplateData, useCreateTemplateMutation } from '@utils/client';

gql`
  mutation createTemplate($data: CreateTemplateData!, $directoryId: ID!) {
    createTemplate(data: $data, directoryId: $directoryId) {
      template {
        id
      }
    }
  }
`;

export interface CreateTemplateContainerProps {
  className?: string;
  directoryId: string;
  onClose: (templateId?: string) => void;
  onChangeDirectory: (directoryId: string) => void;
}
const CreateTemplateContainer: React.FC<
  CreateTemplateContainerProps
> = props => {
  const [createTemplateMutation] = useCreateTemplateMutation({
    fetchPolicy: 'no-cache',
  });

  const createTemplate = async (data: CreateTemplateData) => {
    const result = await createTemplateMutation({
      variables: { data, directoryId: props.directoryId },
    });
    return result.data?.createTemplate.template.id;
  };

  return <CreateTemplatePresenter {...props} createTemplate={createTemplate} />;
};

export default CreateTemplateContainer;
