import { gql } from '@apollo/client';
import React from 'react';

import MessageInputPresenter from './MessageInputPresenter';

import {
  QueueReason,
  useQueueCounselMutation,
  useUnqueueCounselMutation,
  useFinishCounselMutation,
  useSendAppReviewMessageMutation,
  useRestartCounselMutation,
  useStopEstimationAndStartCounselMutation,
} from '@utils/client';

type MessageInputContainerProps = {
  className?: string;
  counselId: string;
};

gql`
  mutation queueCounsel($counselId: ID!, $reason: QueueReason) {
    queueCounsel(counselId: $counselId, reason: $reason) {
      counsel {
        id
        status
        queueReason
        queuedFrom
        bookmarkedUntil
      }
    }
  }
`;

gql`
  mutation unqueueCounsel($counselId: ID!) {
    unqueueCounsel(counselId: $counselId) {
      counsel {
        id
        status
        queueReason
        queuedFrom
        bookmarkedUntil
      }
    }
  }
`;

gql`
  mutation finishCounsel($counselId: ID!) {
    finishCounsel(counselId: $counselId) {
      counsel {
        id
      }
    }
  }
`;

gql`
  mutation sendAppReviewMessage($counselId: ID!) {
    sendAppReviewMessage(counselId: $counselId) {
      message {
        id
      }
    }
  }
`;

gql`
  mutation restartCounsel($counselId: ID!) {
    restartCounsel(counselId: $counselId) {
      counsel {
        id
        status
      }
    }
  }
`;

gql`
  mutation stopEstimationAndStartCounsel($counselId: ID!) {
    stopEstimationAndStartCounsel(counselId: $counselId) {
      ... on StopEstimationAndStartCounselSuccess {
        counsel {
          id
          status
        }
      }
    }
  }
`;

const MessageInputContainer: React.FC<MessageInputContainerProps> = props => {
  const [queueCounselMutation] = useQueueCounselMutation();
  const [unqueueCounselMutation] = useUnqueueCounselMutation();
  const [finishCounselMutation] = useFinishCounselMutation();
  const [sendAppReviewMessageMutation] = useSendAppReviewMessageMutation();
  const [restartCounselMutation] = useRestartCounselMutation();
  const [stopEstimateAndStartCounselMutation] =
    useStopEstimationAndStartCounselMutation();

  const queueCounsel = async (counselId: string, reason?: QueueReason) => {
    const { data } = await queueCounselMutation({
      variables: {
        counselId,
        reason: reason,
      },
    });

    if (!data) {
      return null;
    }

    return data.queueCounsel.counsel;
  };

  const unqueueCounsel = async (counselId: string) => {
    const { data } = await unqueueCounselMutation({
      variables: {
        counselId,
      },
    });

    if (!data) {
      return null;
    }

    return data.unqueueCounsel.counsel;
  };

  const finishCounsel = async (counselId: string) => {
    const { data } = await finishCounselMutation({
      variables: {
        counselId,
      },
    });

    if (!data) {
      return null;
    }

    return data.finishCounsel.counsel;
  };

  const sendAppReviewMessage = async (counselId: string) => {
    const { data } = await sendAppReviewMessageMutation({
      variables: {
        counselId,
      },
    });

    if (!data) {
      return null;
    }

    return data.sendAppReviewMessage.message;
  };

  const restartCounsel = async (counselId: string) => {
    await restartCounselMutation({
      variables: {
        counselId,
      },
    });
  };

  const stopEstimateAndStartCounsel = async (counselId: string) => {
    await stopEstimateAndStartCounselMutation({
      variables: {
        counselId,
      },
    });
  };

  return (
    <MessageInputPresenter
      queueCounsel={queueCounsel}
      unqueueCounsel={unqueueCounsel}
      finishCounsel={finishCounsel}
      sendAppReviewMessage={sendAppReviewMessage}
      restartCounsel={restartCounsel}
      stopEstimateAndStartCounsel={stopEstimateAndStartCounsel}
      {...props}
    />
  );
};

export default MessageInputContainer;
