import React from 'react';
import styled from 'styled-components';

import { MessageProps } from '../';

import { isContainUrl } from '@utils/validators';

type TextMessageProps = {
  text: string;
  counselee: boolean;
  id: string;
} & MessageProps;

const TextMessage: React.FC<TextMessageProps> = ({
  mine,
  counselee,
  text,
  id,
}) => {
  const messageBubble = React.useMemo(() => {
    const { isContain, splited } = isContainUrl(text);
    const messages = [];
    if (!isContain) {
      messages.push(
        <SText key={`${id}-1`} isLink={false} isMine={mine}>
          {splited.map(part => part.text).join(' ')}
        </SText>,
      );
    } else {
      for (let i = 0; i < splited.length; i++) {
        const part = splited[i];
        if (part.isUrl) {
          messages.push(
            <SLink
              href={part.text}
              key={`${id}-${i}`}
              isMine={mine}
              target="_blank"
            >{`${part.text} `}</SLink>,
          );
        } else {
          messages.push(
            <SText
              key={`${id}-${i}`}
              isLink={part.isUrl}
              isMine={mine}
            >{`${part.text} `}</SText>,
          );
        }
        if (part.newLined && i < splited.length - 1) {
          messages.push(<SLineBreaker key={`breaker-${i}`} />);
        }
      }
    }

    return (
      <STextBubble counselee={counselee} isMine={mine}>
        {messages.map(text => text)}
      </STextBubble>
    );
  }, [text, counselee, mine, id]);

  return <>{messageBubble}</>;
};

export default TextMessage;

const STextBubble = styled.div<{ isMine: boolean; counselee: boolean }>`
  width: fit-content;
  height: fit-content;
  padding: 5px 9px;
  ${props => (props.isMine ? 'background-color: #1890ff;' : '')}
  ${props => (props.counselee ? 'background-color: #FCF4A3;' : '')}
  ${props =>
    !props.isMine && !props.counselee
      ? 'background-color: #ffffff; border: 1px solid #f0f0f0;'
      : ''}
  border-radius: 14px;
  ${props =>
    props.isMine
      ? 'border-top-right-radius: 0px;'
      : 'border-top-left-radius: 0px;'}
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;

const SText = styled.span<{ isMine: boolean; isLink: boolean }>`
  color: ${props =>
    props.isMine ? '#ffffff' : props.theme.colors.TEXT_DEFAULT};
  font-size: 14px;
  min-width: 0;
  ::selection {
    ${props =>
      props.isMine
        ? 'background-color:rgba(245, 229, 27, 1); color: #000000'
        : ''}
  }
`;

const SLink = styled.a<{ isMine: boolean }>`
  color: ${props =>
    props.isMine ? '#ffffff' : props.theme.colors.TEXT_DEFAULT};
  font-size: 14px;
  min-width: 0;
  display: block;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #e6f7ff;
  }
`;

const SLineBreaker = styled.div`
  width: 100%;
  white-space: pre;
`;
