import { UserOutlined, ReadOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Skeleton, Space, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import styled from 'styled-components';

import { useMe } from '@hooks';

const { Text } = Typography;

gql`
  query profile {
    me {
      id
      avatar {
        url
      }
      nickname
    }
  }
`;

const Profile: React.FC = () => {
  const { id, avatar, nickname, loading, fetchMe } = useMe();

  if (loading) {
    return (
      <>
        <Skeleton
          active
          avatar={{ shape: 'circle' }}
          title={{ width: 100 }}
          paragraph={false}
        />
      </>
    );
  }

  if (!id) {
    return <Button type="text" icon={<ReadOutlined />} onClick={fetchMe} />;
  }
  return (
    <SProfile size={12}>
      <>
        <Avatar
          src={avatar?.url || undefined}
          alt="상담사 프로필 이미지"
          icon={<UserOutlined />}
        />
        <Text>{nickname}</Text>
      </>
    </SProfile>
  );
};

export default Profile;

const SProfile = styled(Space)`
  display: flex;
  align-items: center;
`;
