import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

import { dateFormatter } from '@utils/formatters';

type SuspendedMessageProps = {
  finishedTime?: number;
  nickname: string;
};

const SuspendedMessage: React.FC<SuspendedMessageProps> = ({
  nickname,
  finishedTime,
  ...props
}) => {
  return (
    <InlineBubble {...props} icon={<></>}>
      {`${nickname}님, 답변이 없으시네요. 아직 도움이 필요한 상황이라면, 상담창은 ${
        finishedTime ? dateFormatter(finishedTime) : '영업일 기준 8시간 뒤'
      }까지 열려있으니 꼭 대화를 진행해주세요!  닥터차는 ${nickname}님께 꼭 도움이 되고 싶습니다:활짝_웃는: `}
    </InlineBubble>
  );
};

export default SuspendedMessage;
