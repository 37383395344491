import { WarningOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

import { RoleEnum } from '@utils/client';

type EndMessageProps = {
  nickname: string;
  role: RoleEnum;
};

const EndMessage: React.FC<EndMessageProps> = ({ nickname, role }) => {
  return (
    <InlineBubble icon={<WarningOutlined />}>
      {`${
        role === RoleEnum.Counselor ? '메카닉 ' : ''
      }${nickname}님이 상담을 종료했습니다.`}
    </InlineBubble>
  );
};

export default EndMessage;
