import { green } from '@ant-design/colors';
import { CloseOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Spin, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

import ExitConfirmModal from '../ExitConfirmModal';
import TemplateBreadcrumb from '../TemplateBreadcrumb';
import TemplateEditor, {
  TemplateContent,
  TemplateEditorRef,
} from '../TemplateEditor';

import { EditTemplateContainerProps } from './EditTemplateContainer';

import { ErrorTemplate } from '@components';
import {
  FileType,
  MessageType,
  TemplateMessageInput,
  UpdateTemplateData,
  useTemplateQuery,
} from '@utils/client';

interface EditTemplatePresenterProps extends EditTemplateContainerProps {
  updateTemplate: (template: UpdateTemplateData) => Promise<void>;
}
const EditTemplatePresenter: React.FC<EditTemplatePresenterProps> = ({
  className,
  templateId,
  updateTemplate,
  onChangeDirectory,
  onClose,
}) => {
  const { loading, data, error } = useTemplateQuery({
    fetchPolicy: 'no-cache',
    variables: { templateId: templateId },
  });

  const [exitConfirm, setExitConfirm] = React.useState<{
    visible: boolean;
    onConfirm?: () => void;
  }>({ visible: false });
  const [saving, setSaving] = React.useState(false);
  const [contentsEdited, setContentsEdited] = React.useState(false);
  const [name, setName] = React.useState<string>('');

  const editor = React.useRef<TemplateEditorRef | null>(null);

  const defaultContents: TemplateContent[] = React.useMemo(() => {
    if (!data?.template) return [];
    const contents: TemplateContent[] = [];

    for (const content of data.template.messages) {
      let templateContent: TemplateContent;
      switch (content.__typename) {
        case 'TextMessageContent':
          templateContent = { ...content, type: 'text' };
          break;
        case 'FileMessageContent':
          if (content.file.__typename === 'Video')
            templateContent = {
              url: content.file.url,
              width: content.file.metadata?.width || 0,
              height: content.file.metadata?.height || 0,
              duration: content.file.metadata?.duration || 0,
              type: 'video',
            };
          else
            templateContent = {
              url: content.file.url,
              width: content.file.metadata?.width || 0,
              height: content.file.metadata?.height || 0,
              type: 'image',
            };

          break;
        default:
          continue;
      }

      contents.push(templateContent);
    }

    return contents;
  }, [data?.template]);

  const edited = React.useMemo(
    () => contentsEdited || name !== data?.template.name,
    [contentsEdited, name, data?.template.name],
  );

  const breadcrumb = React.useMemo(
    () =>
      data?.template.path ? (
        <TemplateBreadcrumb
          directories={data.template.path}
          onClickDirectory={directoryId => {
            if (edited)
              setExitConfirm({
                visible: true,
                onConfirm: () => onChangeDirectory(directoryId),
              });
            else onChangeDirectory(directoryId);
          }}
        />
      ) : undefined,
    [data?.template.path, edited, onChangeDirectory],
  );

  const initialize = () => {
    setName(data?.template.name || '');
    editor.current?.initialize();
  };

  React.useEffect(initialize, [data?.template.name, defaultContents]);

  const onBack = React.useCallback(() => {
    if (edited) setExitConfirm({ visible: true, onConfirm: onClose });
    else onClose();
  }, [edited, onClose]);

  const onChange = React.useCallback((change: { edited: boolean }) => {
    setContentsEdited(change.edited);
  }, []);

  const save = React.useCallback(async () => {
    const contents = editor.current?.getContents();

    if (!name) {
      notification.error({
        message: '템플릿 변경 사항 저장 실패',
        description: '템플릿명을 입력해주세요',
      });
      return;
    }
    if (!contents?.length) {
      notification.error({
        message: '템플릿 변경 사항 저장 실패',
        description: '템플릿 내용을 입력해주세요',
      });
      return;
    }

    setSaving(true);
    const formattedContents: TemplateMessageInput[] = contents.map(content => {
      if (content.type !== 'text') {
        return {
          file: {
            url: content.url,
            type: content.type.toUpperCase() as FileType,
          },
          type: MessageType.File,
        };
      }
      return {
        type: MessageType.Text,
        body: content.body,
      };
    });
    try {
      await updateTemplate({
        name,
        messages: formattedContents,
      });
      onClose();
    } catch (error: any) {
      notification.error({
        message: '템플릿 저장 실패',
        description: error.message,
      });
    } finally {
      setSaving(false);
    }
  }, [name, onClose, updateTemplate]);

  if (loading) {
    return (
      <SCenterContainer className={className}>
        <Spin />
      </SCenterContainer>
    );
  }

  return (
    <>
      <ExitConfirmModal
        {...exitConfirm}
        onCancel={() => setExitConfirm({ visible: false })}
        title="저장하지 않고 나가시겠습니까?"
        body="모든 변경사항들이 취소됩니다."
      />
      <SEditTemplatePresenter
        className={className}
        title={
          <SNameInput
            value={name}
            onChange={event => {
              setName(event.target.value);
            }}
          />
        }
        backIcon={<CloseOutlined />}
        onBack={onBack}
        breadcrumbRender={() => breadcrumb}
        extra={[
          <Button
            key="reset-template"
            icon={<ReloadOutlined />}
            onClick={initialize}
          />,
          <Button
            key="save-template"
            type="primary"
            disabled={saving || !edited}
            icon={<SaveOutlined />}
            onClick={save}
          >
            {saving ? '저장중...' : '저장'}
          </Button>,
        ]}
      >
        {error ? (
          <ErrorTemplate message="템플릿을 불러오지 못했습니다" error={error} />
        ) : (
          <STemplateEditor
            ref={editor}
            defaultContents={defaultContents}
            onChange={onChange}
          />
        )}
      </SEditTemplatePresenter>
    </>
  );
};

export default EditTemplatePresenter;

const SEditTemplatePresenter = styled(PageHeader)`
  background-color: ${green[1]};
`;
const SNameInput = styled(Input)`
  width: 300px;
`;
const STemplateEditor = styled(TemplateEditor)`
  height: 100%;
`;
const SCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
