/* eslint-disable react/prop-types */
import {
  CommentOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  PageHeader,
  Table,
  Card,
  Result,
  Space,
  Form,
  Input,
  Button,
  Modal,
  notification,
  InputNumber,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import Loading from '@components/Loading';
import {
  TireSize,
  UpdateVehicleData,
  useUpdateVehicleMutation,
  useVehicleCounselQuery,
} from '@utils/client';
import { fuelTypeStringifer } from '@utils/formatters';

gql`
  query vehicleCounsel($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      counselee {
        id
        nickname
      }
      vehicle {
        id
        model {
          id
        }
        grade {
          segment
        }
        fullModelName
        shortModelName
        fuelType {
          base
          hybrid
        }
        plateNumber
        vin
        mileage
        tireSize {
          front {
            width
            aspectRatio
            wheelDiameter
          }
          rear {
            width
            aspectRatio
            wheelDiameter
          }
        }
      }
    }
  }
`;

gql`
  mutation updateVehicle($vehicleId: ID!, $data: UpdateVehicleData!) {
    updateVehicle(vehicleId: $vehicleId, data: $data) {
      ... on UpdateVehicleSuccess {
        vehicle {
          id
          model {
            id
          }
          grade {
            segment
          }
          fullModelName
          shortModelName
          plateNumber
          vin
          mileage
          tireSize {
            front {
              width
              aspectRatio
              wheelDiameter
            }
            rear {
              width
              aspectRatio
              wheelDiameter
            }
          }
        }
      }
      ... on UpdateVehicleFail {
        error {
          message
        }
      }
    }
  }
`;

type VehicleProps = {
  counselId: string;
};

type VehicleFormFields = {
  model: string;
  vehicleId: string;
  plateNumber: string;
  vin: string;
  mileage: number;
  front: TireSize;
  rear: TireSize;
};

const validateTireSize = (size?: TireSize) => {
  if (!size) {
    return '';
  }

  if (size.width % 10 !== 5) {
    return '단면폭은 5로 끝나야 합니다.';
  } else if (size.aspectRatio % 5) {
    return '편평비는 0 또는 5로 끝나야 합니다.';
  } else {
    return '';
  }
};

const Vehicle: React.FC<VehicleProps> = ({ counselId }) => {
  const [updateVehicleMutation] = useUpdateVehicleMutation();

  const { data, loading, error } = useVehicleCounselQuery({
    variables: {
      counselId,
    },
  });

  const [editting, setEdit] = React.useState(false);
  const [form] = Form.useForm<VehicleFormFields>();

  const displayData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    const vehicle = data.counsel.vehicle;

    if (!vehicle) {
      return [];
    }

    let modelName = vehicle.fullModelName;
    if (vehicle.fuelType) {
      modelName += ` (${fuelTypeStringifer(vehicle.fuelType)})`;
    }
    return [
      {
        model: modelName,
        plateNumber: vehicle.plateNumber ? vehicle.plateNumber : '정보 없음',
        vin: vehicle.vin ? vehicle.vin : '정보 없음',
        mileage: vehicle.mileage
          ? `${vehicle.mileage.toLocaleString()} (km)`
          : '정보 없음',
        tireSize: vehicle.tireSize ? vehicle.tireSize : '정보 없음',
      },
    ];
  }, [data]);

  const formValue = React.useMemo(() => {
    if (!data) {
      return;
    }

    const vehicle = data.counsel.vehicle;

    if (!vehicle) {
      return;
    }

    const front = vehicle.tireSize?.front;
    const rear = vehicle.tireSize?.rear;

    return {
      model: vehicle.fullModelName,
      vehicleId: vehicle.id,
      plateNumber: vehicle.plateNumber,
      vin: vehicle.vin,
      mileage: vehicle.mileage,
      front: front,
      rear: rear,
    };
  }, [data]);

  const handleClickSaveVehicle = React.useCallback(async () => {
    if (!data) {
      return;
    }

    if (!data.counsel.vehicle || !data.counsel.vehicle.id) {
      Modal.error({
        title: '차량정보가 존재하지 않습니다.',
      });
      return;
    }

    const vehicle = data.counsel.vehicle;
    const formData = form.getFieldsValue();
    const front = formData.front;
    const rear = formData.rear;

    const updateVehicleData: UpdateVehicleData = {
      vin: formData.vin,
      mileage: formData.mileage ? formData.mileage : undefined,
      plateNumber: data.counsel.vehicle.plateNumber
        ? undefined
        : formData.plateNumber,
    };

    if (
      front.aspectRatio &&
      front.width &&
      front.wheelDiameter &&
      rear.aspectRatio &&
      rear.width &&
      rear.wheelDiameter
    ) {
      if (!validateTireSize(front) && !validateTireSize(rear)) {
        updateVehicleData.tireSize = {
          front: {
            aspectRatio: Number(front.aspectRatio),
            width: Number(front.width),
            wheelDiameter: Number(front.wheelDiameter),
          },
          rear: {
            aspectRatio: Number(rear.aspectRatio),
            width: Number(rear.width),
            wheelDiameter: Number(rear.wheelDiameter),
          },
        };
      } else {
        notification.error({
          message: '타이어 사이즈가 올바르지 않습니다.',
          description: validateTireSize(front) + validateTireSize(rear),
        });
        return;
      }
    } else if (
      front.aspectRatio ||
      front.width ||
      front.wheelDiameter ||
      rear.aspectRatio ||
      rear.width ||
      rear.wheelDiameter
    ) {
      notification.error({
        message: '타이어 사이즈가 올바르지 않습니다.',
        description: '타이어 사이즈를 모두 입력해주세요.',
      });
      return;
    }

    if (updateVehicleData.vin?.length !== 17) {
      notification.error({ message: '차대번호는 17자리여야 합니다.' });
      return;
    }

    if (
      formData.mileage &&
      (formData.mileage < 0 || formData.mileage > 999999)
    ) {
      notification.error({ message: '주행거리는 0 ~ 999999 까지입니다.' });
      return;
    }

    await updateVehicleMutation({
      variables: {
        vehicleId: vehicle.id,
        data: updateVehicleData,
      },
      onError: error => {
        notification.error({
          message: '차량 정보 수정 실패',
          description: error.message,
        });
        console.error(error.message);
      },
      onCompleted: data => {
        if (data.updateVehicle.__typename === 'UpdateVehicleFail') {
          notification.error({
            message: '차량 정보 수정 실패',
            description: data.updateVehicle.error,
          });
        } else if (data.updateVehicle.__typename === 'UpdateVehicleSuccess') {
          notification.success({ message: '차량 정보 수정 성공' });
          setEdit(false);
        }
      },
    });
  }, [data, form, updateVehicleMutation]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Result
        status="error"
        title="차량 정보 로드중에 문제가 발생했습니다."
        extra={error.message}
      />
    );
  }

  if (!data) {
    return (
      <Result status="error" title="차량 정보 로드중에 문제가 발생했습니다." />
    );
  }

  return (
    <SContainer>
      {editting ? (
        <>
          <PageHeader
            title={<Space>차량 정보 편집</Space>}
            backIcon={<ArrowLeftOutlined />}
            onBack={() => setEdit(false)}
            extra={[
              <Button
                key="save-vehicle"
                disabled={false}
                icon={<SaveOutlined />}
                onClick={handleClickSaveVehicle}
              >
                저장
              </Button>,
            ]}
          />
          <SFormBox>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 19 }}
              initialValues={formValue}
            >
              <Form.Item name="vehicleId" label="ID">
                <Input disabled style={{ width: '422px' }} />
              </Form.Item>
              <Form.Item name="model" label="차량 모델">
                <Input disabled style={{ width: '422px' }} />
              </Form.Item>
              <Form.Item
                name="plateNumber"
                label="차량 번호"
                rules={[{ required: false }]}
              >
                <Input
                  disabled={!!formValue?.plateNumber}
                  style={{ width: '422px' }}
                />
              </Form.Item>
              <Form.Item
                name="vin"
                label="차대번호"
                rules={[{ required: false }]}
              >
                <Input style={{ width: '422px' }} />
              </Form.Item>
              <Form.Item
                name="mileage"
                label="주행거리"
                rules={[{ required: false }]}
              >
                <InputNumber style={{ width: '422px' }} />
              </Form.Item>
              <Form.Item
                label="타이어 치수 (앞)"
                rules={[{ required: false }]}
                style={{ height: '32px' }}
              >
                <Input.Group compact>
                  <Form.Item
                    name={['front', 'width']}
                    style={{ width: '130px' }}
                  >
                    <Input placeholder="단면폭(mm)" />
                  </Form.Item>
                  <Form.Item
                    name={['front', 'aspectRatio']}
                    style={{ width: '162px' }}
                  >
                    <Input
                      placeholder="편평비(%)"
                      addonAfter={<span>R</span>}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['front', 'wheelDiameter']}
                    style={{ width: '130px' }}
                  >
                    <Input placeholder="휠 직경(in)" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="타이어 치수 (뒤)"
                rules={[{ required: false }]}
                style={{ height: '32px' }}
              >
                <Input.Group compact>
                  <Form.Item
                    name={['rear', 'width']}
                    style={{ width: '130px' }}
                  >
                    <Input placeholder="단면폭(mm)" />
                  </Form.Item>
                  <Form.Item
                    name={['rear', 'aspectRatio']}
                    style={{ width: '162px' }}
                  >
                    <Input
                      placeholder="편평비(%)"
                      addonAfter={<span>R</span>}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['rear', 'wheelDiameter']}
                    style={{ width: '130px' }}
                  >
                    <Input placeholder="휠 직경(in)" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Form>
          </SFormBox>
        </>
      ) : (
        <>
          <PageHeader
            title={
              <Space>
                <CommentOutlined />
                {`${
                  data.counsel.counselee
                    ? data.counsel.counselee.nickname
                    : '(알수없음)'
                }님 차량정보`}
              </Space>
            }
          />
          <SBodyContainer>
            {displayData.length > 0 ? (
              <Card title="상담 차량">
                <Space direction="vertical" style={{ width: '100%' }}>
                  <STableBox>
                    <Table
                      dataSource={displayData}
                      pagination={false}
                      style={{ width: '100%' }}
                    >
                      <Table.Column
                        title="차량 모델"
                        key="model"
                        dataIndex="model"
                        width={140}
                      />
                      <Table.Column
                        title="차량 번호"
                        key="plateNumber"
                        dataIndex="plateNumber"
                        width={90}
                      />
                      <Table.Column
                        title="차대번호"
                        key="vin"
                        dataIndex="vin"
                        width={20}
                      />
                      <Table.Column
                        title="주행거리"
                        key="mileage"
                        dataIndex="mileage"
                        width={84}
                      />
                      <Table.Column
                        title="타이어 치수"
                        key="tireSize"
                        dataIndex="tireSize"
                        width={120}
                        render={props => {
                          const front = props.front as TireSize;
                          const rear = props.rear as TireSize;
                          if (!front || !rear) {
                            return <TireCell>정보없음</TireCell>;
                          }
                          return (
                            <TireCell>
                              <span>{`${front.width} ${front.aspectRatio}R ${front.wheelDiameter} (앞)`}</span>
                              <span>{`${rear.width} ${rear.aspectRatio}R ${rear.wheelDiameter} (뒤)`}</span>
                            </TireCell>
                          );
                        }}
                      />
                      <Table.Column
                        title=""
                        key="edit"
                        width={26}
                        render={() => (
                          <EditText onClick={() => setEdit(true)}>
                            편집
                          </EditText>
                        )}
                      />
                    </Table>
                  </STableBox>
                </Space>
              </Card>
            ) : (
              <div>차량정보 추가하기</div>
            )}
          </SBodyContainer>
        </>
      )}
    </SContainer>
  );
};

export default Vehicle;

const SContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SFormBox = styled.div`
  padding-left: 24px;
`;

const SBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 16px;
  flex: 1;
  overflow-y: auto;
`;

const STableBox = styled.div`
  width: 100%;
`;

const TireCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditText = styled.div`
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
  width: 26px;
`;
