import React from 'react';
import { TableCellProps } from 'react-virtualized';
import styled from 'styled-components';

import { TMergedTableRow } from './';

interface InquiryCellProps extends TableCellProps {
  disabled?: boolean;
}
const InquiryCell: React.FC<InquiryCellProps> = props => {
  const inquiryDate = props.rowData[
    'inquiryDate'
  ] as TMergedTableRow['inquiryDate'];

  return <SInquiryDateCell>{inquiryDate}</SInquiryDateCell>;
};

export default React.memo(
  InquiryCell,
  (prevProps, nextProps) =>
    prevProps.rowData.id === nextProps.rowData.id &&
    prevProps.cellData === nextProps.cellData,
);

const SInquiryDateCell = styled.pre`
  text-align: center;
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
