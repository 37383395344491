import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Loading: React.FC = () => {
  return (
    <SLoadingOverlay>
      <Spin />
    </SLoadingOverlay>
  );
};

const SLoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;

  background-color: rgba(0, 0, 0, 0.05);
`;

export default Loading;
