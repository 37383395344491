import { AppstoreOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

const AppReviewMessage: React.FC = props => {
  return (
    <InlineBubble {...props} icon={<AppstoreOutlined />}>
      앱 리뷰등록을 요청하였습니다.
    </InlineBubble>
  );
};

export default AppReviewMessage;
