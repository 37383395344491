import { Dropdown, Menu } from 'antd';
import React from 'react';

import { useMessage } from '@hooks';

export type MyMessageDropdownProps = {
  counselId: string;
  messageId: string;
  mine: boolean;
  children?: React.ReactNode;
};
export const MyMessageDropdown = ({
  children,
  counselId,
  messageId,
  mine,
}: MyMessageDropdownProps) => {
  const { deleteMessage } = useMessage({ counselId });

  if (!mine) {
    return <>{children}</>;
  }
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={() => deleteMessage(messageId)}>삭제</Menu.Item>
        </Menu>
      }
      trigger={['contextMenu']}
    >
      {children}
    </Dropdown>
  );
};
