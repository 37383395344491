import {
  AimOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Select, Input, Table, Tooltip, Tag, notification } from 'antd';
import React, { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { useMessage } from '@hooks';
import { useHorizontalScroll } from '@hooks/useHorizontalScroll';
import {
  Area,
  CompaniesFilter,
  Coordinates,
  Specialty,
  useCompaniesSearchLazyQuery,
  useCompanySpecialtiesQuery,
  useCounseleeLastCounselsQuery,
} from '@utils/client';
import { contactFormatter } from '@utils/formatters';
import { getDistance } from '@utils/location';

gql`
  query companiesSearch(
    $filter: CompaniesFilter!
    $offset: Int!
    $limit: Int!
  ) {
    companies(filter: $filter, offset: $offset, limit: $limit) {
      id
      name
      address {
        road
        region
        coordinates {
          latitude
          longitude
        }
      }
      contact
      specialties {
        id
        name
      }
    }
    companiesCount(filter: $filter)
  }
`;

gql`
  query counseleeLastCounsels($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      status
      areas {
        coordinates {
          latitude
          longitude
        }
        radius
        zone {
          district
          neighborhood
          province
        }
      }
      counselee {
        id
        inactiveCounselConnection(filter: {}, first: 3) {
          edges {
            node {
              id
              index
              memo
              createdAt
            }
          }
        }
      }
    }
  }
`;

gql`
  query companySpecialties {
    companySpecialties {
      id
      name
    }
  }
`;

interface ShopProps {
  className?: string;
  counselId: string;
}

export type RegionState = Omit<Area, '__typename' | 'zone'> & {
  selectIndex: number;
};

const Company: React.FC<ShopProps> = ({ className, counselId }) => {
  const { sendCompanyMessage, sendAreaInputMessage } = useMessage({
    counselId,
  });
  const { data: companySpecialtiesData } = useCompanySpecialtiesQuery();
  const [companiesSearchQuery, { data: companiesSearchData }] =
    useCompaniesSearchLazyQuery({
      fetchPolicy: 'network-only',
    });
  const { data, refetch } = useCounseleeLastCounselsQuery({
    variables: {
      counselId,
    },
  });

  const [selectedArea, setSelectedArea] = React.useState<{
    index: number;
    coordinates: Coordinates;
    radius: number;
  } | null>(null);
  const scrollRef = useHorizontalScroll();
  const [specialtyIds, setSpecialtyIds] = React.useState<string[]>([]);
  const [term, setTerm] = React.useState<string>('');
  const [pagination, setPagination] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 10 });

  const search = React.useCallback(
    (offset: number, limit: number) => {
      const filter: CompaniesFilter = {
        area: selectedArea
          ? {
              coordinates: selectedArea.coordinates,
              radius: selectedArea.radius,
            }
          : undefined,
        specialtyIds: specialtyIds.length > 0 ? specialtyIds : undefined,
        term: term || undefined,
      };

      companiesSearchQuery({
        variables: {
          filter,
          offset,
          limit,
        },
      });
    },
    [selectedArea, specialtyIds, term, companiesSearchQuery],
  );

  const onPageChange = (page: number, pageSize?: number) => {
    setPagination(pagination => {
      const newPage = page - 1;
      const newPageSize = pageSize || pagination.pageSize;

      search(newPage * newPageSize, newPageSize);
      return { page: newPage, pageSize: newPageSize };
    });
  };

  const { companies, count } = React.useMemo(() => {
    if (!companiesSearchData) {
      return {
        companies: [],
        count: 0,
      };
    }

    return {
      companies: companiesSearchData.companies,
      count: companiesSearchData.companiesCount,
    };
  }, [companiesSearchData]);

  const companiesDataSource = React.useMemo(() => {
    const coordinates = selectedArea?.coordinates;
    let companiesData = companies.map(company => ({
      id: company.id,
      name: company.name,
      distance: coordinates
        ? getDistance({
            current: coordinates,
            target: company.address.coordinates,
          })
        : 0,
      address: company.address.road
        ? company.address.road
        : company.address.region
        ? company.address.region
        : '정보 없음',
      specialties: company.specialties,
      contact: company.contact,
    }));

    if (coordinates) {
      companiesData = companiesData.sort((a, b) => a.distance - b.distance);
    }

    return companiesData;
  }, [companies, selectedArea]);

  const sendCompany = useCallback(
    async (companyId: string) => {
      await sendCompanyMessage(companyId);
    },
    [sendCompanyMessage],
  );

  const columns = React.useMemo(() => {
    if (selectedArea) {
      return [
        {
          key: 'name',
          dataIndex: 'name',
          title: '업체명',
          render: (name: string) => `${name}`,
        },
        {
          key: 'distance',
          dataIndex: 'distance',
          title: '거리',
          width: 72,
          render: (distance: number) => (
            <Tag color="blue">{`${distance}km`}</Tag>
          ),
        },
        {
          key: 'address',
          dataIndex: 'address',
          title: '주소',
          ellipsis: true,
          render: (address: string) => (
            <Tooltip placement="top" title={address}>
              {address}
            </Tooltip>
          ),
        },
        {
          key: 'specialties',
          dataIndex: 'specialties',
          title: '전문분야',
          width: 300,
          render: (specialties: Specialty[]) =>
            specialties.map((specialty: Specialty) => (
              <Tag key={specialty.id}>{specialty.name}</Tag>
            )),
        },
        {
          key: 'actions',
          title: '액션',
          width: 220,
          render: (_: unknown, company: any) => (
            <>
              <Button
                size="small"
                type="link"
                onClick={() => sendCompany(company.id)}
              >
                전송
              </Button>
              <CopyToClipboard
                text={`업체명 : ${company.name}\n주소 : ${
                  company.address
                }\n연락처 : ${contactFormatter(company.contact)}`}
                onCopy={() => {
                  notification.success({
                    message: '연락처가 복사되었습니다.',
                  });
                }}
              >
                <Button size="small" type="link">
                  연락처 복사
                </Button>
              </CopyToClipboard>
            </>
          ),
        },
      ];
    } else {
      return [
        {
          key: 'name',
          dataIndex: 'name',
          title: '업체명',
          render: (name: string) => `${name}`,
        },
        {
          key: 'address',
          dataIndex: 'address',
          title: '주소',
          ellipsis: true,
          render: (address: string) => (
            <Tooltip placement="top" title={address}>
              {address}
            </Tooltip>
          ),
        },
        {
          key: 'specialties',
          dataIndex: 'specialties',
          title: '전문분야',
          width: 300,
          render: (specialties: Specialty[]) =>
            specialties.map((specialty: Specialty) => (
              <Tag key={specialty.id}>{specialty.name}</Tag>
            )),
        },
        {
          key: 'actions',
          title: '액션',
          width: 220,
          render: (_: unknown, company: any) => (
            <>
              <Button
                size="small"
                type="link"
                onClick={() => sendCompany(company.id)}
              >
                전송
              </Button>
              <CopyToClipboard
                text={`업체명 : ${company.name}\n주소 : ${
                  company.address
                }\n연락처 : ${contactFormatter(company.contact)}`}
                onCopy={() => {
                  notification.success({
                    message: '연락처가 복사되었습니다.',
                  });
                }}
              >
                <Button size="small" type="link">
                  연락처 복사
                </Button>
              </CopyToClipboard>
            </>
          ),
        },
      ];
    }
  }, [selectedArea, sendCompany]);

  return (
    <>
      <SShop className={className}>
        <SSearchHeader>
          <STitle>정비소 검색</STitle>
          <SSearchButtonBox>
            <SRegionsBox ref={scrollRef}>
              {data?.counsel.areas.map((area, index) => {
                return (
                  <Button
                    size="small"
                    type={selectedArea?.index === index ? 'primary' : 'default'}
                    ghost={selectedArea?.index === index}
                    key={`area_${index}`}
                    onClick={() => {
                      if (selectedArea) {
                        setSelectedArea(null);
                      } else {
                        setSelectedArea({
                          coordinates: {
                            latitude: area.coordinates.latitude,
                            longitude: area.coordinates.longitude,
                          },
                          radius: area.radius,
                          index: index,
                        });
                      }
                    }}
                  >
                    <AimOutlined />
                    {`${area.zone?.district} ${area.zone?.neighborhood} - ${
                      Math.round((area.radius / 1000) * 100) / 100
                    }km`}
                  </Button>
                );
              })}
            </SRegionsBox>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                if (data?.counsel) sendAreaInputMessage();
              }}
            >
              희망 정비 지역 등록 요청
            </Button>
            <Button
              size="small"
              onClick={() => {
                refetch?.({ counselId });
              }}
              icon={<ReloadOutlined />}
            >
              새로고침
            </Button>
          </SSearchButtonBox>
        </SSearchHeader>
        <FilterBox>
          <Select
            mode="multiple"
            allowClear
            placeholder="전문분야"
            value={specialtyIds}
            onChange={setSpecialtyIds}
            style={{ flex: '0 0 220px' }}
          >
            {companySpecialtiesData &&
              companySpecialtiesData.companySpecialties.map(specialty => (
                <Select.Option key={specialty.id} value={specialty.id}>
                  {specialty.name}
                </Select.Option>
              ))}
          </Select>
          <Input.Search
            allowClear
            placeholder="업체명, 메모, 주소로 검색"
            value={term}
            onChange={event => setTerm(event.target.value)}
            onSearch={() => search(0, pagination.pageSize)}
            style={{ flex: '1' }}
          />
        </FilterBox>
        <Table
          columns={columns}
          dataSource={companiesDataSource}
          pagination={{
            total: count,
            current: pagination.page + 1,
            pageSize: pagination.pageSize,
            onChange: onPageChange,
          }}
          loading={false}
          size="small"
          rowKey={company => company.id}
        />
      </SShop>
    </>
  );
};

export default Company;

const SShop = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SSearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SSearchButtonBox = styled.div`
  display: flex;
  width: 100%;
  margin-left: 8px;
  gap: 8px;
`;

const STitle = styled.h5`
  font-size: 16px;
  flex: 0 0 78px;
`;

const SRegionsBox = styled.div`
  width: calc(100% - 457px);
  height: 24px;
  display: flex;
  height: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 4px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FilterBox = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  gap: 8px;
`;
