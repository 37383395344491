import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

type EstimateTimerProps = {
  expiredAt?: number;
  label?: boolean;
};

const EstimateTimer: React.FC<EstimateTimerProps> = ({
  expiredAt,
  label = true,
}) => {
  const [now, setNow] = React.useState<dayjs.Dayjs | null>(null);

  React.useEffect(() => {
    if (now === null) {
      setInterval(() => {
        setNow(dayjs());
      }, 1000);
    }
  }, [now]);

  const validateTime = React.useMemo(() => {
    if (!now) {
      return '';
    }
    const expiredAtFromDay = dayjs(expiredAt);
    const diff = expiredAtFromDay.diff(now, 'seconds');

    if (diff < 0) {
      return '';
    }

    const hour = Math.floor(diff / 3600);
    const min = Math.floor((diff - hour * 3600) / 60);
    const sec = diff - hour * 3600 - min * 60;

    const formattedHour = hour < 10 ? `0${hour}` : hour.toString();
    const formattedMin = min < 10 ? `0${min}` : min.toString();
    const formattedSec = sec < 10 ? `0${sec}` : sec.toString();
    return `${formattedHour}:${formattedMin}:${formattedSec}`;
  }, [now, expiredAt]);

  if (!expiredAt) {
    return null;
  }

  return (
    <Container>
      <TimeText label={label}>{`${
        label ? `⏳ 유효시간: ` : ''
      }${validateTime}`}</TimeText>
    </Container>
  );
};

export default EstimateTimer;

const Container = styled.div`
  display: flex;
  height: 22px;
  gap: 4px;
  align-items: center;
`;

const TimeText = styled.span<{ label: boolean }>`
  font-weight: normal;
  font-size: 14px;
  color: #ff4d4f;
  width: ${props => (props.label ? '135px' : '100px')};
`;
