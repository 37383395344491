import OngoingTable, {
  TOngoingTableRow as tableType,
  ONGOING_COUNSELS as _ONGOING_COUNSELS,
  ONGOING_COUNSELS_RESULT as _ONGOING_COUNSELS_RESULT,
} from './OngoingTable';

export type TOngoingTableRow = tableType;
export const ONGOING_COUNSELS = _ONGOING_COUNSELS;
export const ONGOING_COUNSELS_RESULT = _ONGOING_COUNSELS_RESULT;
export default OngoingTable;
