import { gql } from '@apollo/client';
import { notification } from 'antd';
import React from 'react';

import useCounselCache from '@hooks/useCounselCache';
import { useMe } from '@hooks/useMe';
import { openCounselWindow } from '@routes/CounselWindow';
import {
  useNewCounselSubscription,
  useInvitedCounselSubscription,
  useLeavedCounselSubscription,
  useNewMessageSubscription,
  useStatusChangedCounselSubscription,
  CounselStatus,
} from '@utils/client';
import { COUNSEL_TABLE_ROW, COUNSEL_TABLE_ROW_MESSAGE } from '@utils/fragments';
import { notify } from '@utils/notification';

interface CounselsSubscriberProps {
  newMessage?: boolean;
  newCounsel?: boolean;
  invitedCounsel?: boolean;
  leavedCounsel?: boolean;
  statusChangeCounsel?: boolean;
}

gql`
  ${COUNSEL_TABLE_ROW}
  subscription newCounsel {
    newCounsel {
      ...CounselTableRow
    }
  }
`;

gql`
  ${COUNSEL_TABLE_ROW}
  subscription invitedCounsel {
    invitedCounsel {
      ...CounselTableRow
    }
  }
`;

gql`
  subscription leavedCounsel {
    leavedCounsel {
      id
      status
    }
  }
`;

gql`
  ${COUNSEL_TABLE_ROW_MESSAGE}
  subscription newMessage {
    newMessage {
      counsel {
        id
        status
      }
      ...CounselTableRowMessage
    }
  }
`;

gql`
  ${COUNSEL_TABLE_ROW}
  subscription statusChangedCounsel($counselId: ID) {
    statusChangedCounsel(counselId: $counselId) {
      counsel {
        ...CounselTableRow
      }
      from
      to
    }
  }
`;

// Counsels 리스트 전반에 대한 내용을 구독
// Default는 다 구독하는걸로 하고, 원하는 경우 어떤 쿼리는 끌 수 있도록.
const CounselsSubscriber: React.FC<CounselsSubscriberProps> = ({
  newCounsel = true,
  newMessage = true,
  invitedCounsel = true,
  leavedCounsel = true,
  statusChangeCounsel = true,
}) => {
  const {
    appendCounsel,
    appendMessageToCounsel,
    findCounselById,
    removeCounsel,
    updateCounselStatus,
  } = useCounselCache();
  const { id } = useMe();

  useNewCounselSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      console.log('newCounsel', subscriptionData);
      const data = subscriptionData.data;
      if (!data) {
        return;
      }

      const newCounsel = data.newCounsel;
      appendCounsel(newCounsel);
      notification.info({
        message: `${newCounsel.counselee?.nickname}님의 새로운 상담이 접수되었습니다`,
      });
      notify({
        title: '새로운 상담',
        body: `${newCounsel.counselee?.nickname}님의 새로운 상담이 접수되었습니다`,
        icon: newCounsel.counselee?.avatar?.url || undefined,
        onClick: () => {
          window.focus();
        },
      });
    },
    skip: !newCounsel,
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  useInvitedCounselSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data;

      if (!data) {
        return;
      }

      if (!id) {
        return;
      }

      const cachedCounsel = findCounselById(data.invitedCounsel.id);

      if (cachedCounsel) {
        return;
      }

      const invitedCounsel = data.invitedCounsel;
      appendCounsel(invitedCounsel);

      notify({
        title: '초대된 상담',
        body: `${invitedCounsel.counselee?.nickname}님의 상담에 초대되었습니다`,
        icon: invitedCounsel.counselee?.avatar?.url || undefined,
        onClick: () => openCounselWindow(invitedCounsel.id),
      });
    },
    skip: !invitedCounsel,
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
  });

  useLeavedCounselSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data;

      if (!data) {
        return;
      }

      const { id, status } = data.leavedCounsel;
      removeCounsel(id, status);
    },
    skip: !leavedCounsel,
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  useNewMessageSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data;
      if (!data) {
        return;
      }

      const newMessage = data.newMessage;
      if (newMessage.counsel.status !== CounselStatus.Finished) {
        appendMessageToCounsel(
          newMessage,
          newMessage.counsel.id,
          newMessage.counsel.status,
        );
      }
    },
    skip: !newMessage,
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
  });

  useStatusChangedCounselSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data;
      if (!data) {
        return;
      }

      const statusChangedCounsel = data.statusChangedCounsel;
      updateCounselStatus(
        statusChangedCounsel.from,
        statusChangedCounsel.to,
        statusChangedCounsel.counsel.id,
      );
    },
    skip: !statusChangeCounsel,
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
  });

  return null;
};
export default CounselsSubscriber;
