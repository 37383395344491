import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

import app from '../../../package.json';

import logo from '@assets/image/logo.png';
import { useAuth } from '@hooks';

interface AuthFormInputs {
  email: string;
  password: string;
}

const AuthHomePresenter = () => {
  const { login } = useAuth();

  const [form] = Form.useForm<AuthFormInputs>();

  const onLogin = async ({ email, password }: AuthFormInputs) => {
    try {
      await login({ email, password });
    } catch (error: any) {
      notification.error({
        message: '로그인 실패',
        description: error?.message,
      });
    }
  };

  return (
    <SContainer>
      <SLogin>
        <SBody>
          <SVersion>Ver {app.version}</SVersion>
          <SLogo src={logo} alt="닥터차 로고" />
          <Typography.Title style={{ marginBottom: 40 }}>
            닥터차
            <br />
            상담사 콘솔
          </Typography.Title>
          <Form form={form} onFinish={onLogin}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: '이메일 주소를 입력해주세요!' },
              ]}
            >
              <Input
                type="email"
                placeholder="이메일 주소"
                prefix={<UserOutlined style={{ color: '#1890FF' }} />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}
            >
              <Input
                type="password"
                placeholder="비밀번호"
                prefix={<LockOutlined style={{ color: '#1890FF' }} />}
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                로그인
              </Button>
            </Form.Item>
          </Form>
        </SBody>
        <SFooter>
          Copyright ©{new Date().getFullYear()} Produced by Autopedia
        </SFooter>
      </SLogin>
      <SGradient />
    </SContainer>
  );
};

const SContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
const SLogin = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 35px;
`;
const SGradient = styled.div`
  flex: 1;
  height: 100%;
  background: linear-gradient(
    86.98deg,
    #e7f3ff 2.36%,
    #bafbff 58.67%,
    #9bb7ff 98.04%
  );
`;
const SBody = styled.div`
  display: flex;
  flex-direction: column;
`;
const SVersion = styled.span`
  align-self: flex-end;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;
const SLogo = styled.img`
  width: 87px;
  height: 48px;
  margin-top: 55px;
  margin-bottom: 30px;
`;
const SFooter = styled.span`
  align-self: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 50px;
`;

export default AuthHomePresenter;
