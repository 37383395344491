import { CompassOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

// TODO
const RestartMessage: React.FC = props => {
  return (
    <InlineBubble {...props} icon={<CompassOutlined />}>
      자동 종료된 상담이 재시작됩니다.
    </InlineBubble>
  );
};

export default RestartMessage;
