import { Avatar } from 'antd';
import React from 'react';
import styled from 'styled-components';

type ProfileProps = {
  className?: string;
  avatar?: string;
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  children?: React.ReactNode;
};

const Profile = ({
  className,
  avatar,
  icon,
  title,
  description,
  children,
}: ProfileProps) => {
  return (
    <SProfile className={className}>
      <SProfileWrapper>
        <Avatar size="large" src={avatar} icon={icon} />
        <SColumn>
          <STitle>{title}</STitle>
          <SDescription>{description}</SDescription>
        </SColumn>
      </SProfileWrapper>
      {children}
    </SProfile>
  );
};

export default Profile;

const SProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const SProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const SColumn = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  word-break: break-all;
`;
const STitle = styled.p`
  width: 100%;
  display: block;
`;
const SDescription = styled.p`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.TEXT_MUTED};
  word-wrap: break-word;
`;
