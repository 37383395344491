import { WarningOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface ExitConfirmModalProps {
  visible: boolean;
  title: string;
  body: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
const ExitConfirmModal: React.FC<ExitConfirmModalProps> = ({
  visible,
  title,
  body,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      closable={false}
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      bodyStyle={{ display: 'flex', gap: 16 }}
    >
      <WarningOutlined style={{ fontSize: 22, color: '#FFC53D' }} />
      <div>
        <SExitConfirmTitle>{title}</SExitConfirmTitle>
        {body}
      </div>
    </Modal>
  );
};

export default ExitConfirmModal;

const SExitConfirmTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;
