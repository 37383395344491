import 'antd/dist/antd.css';
import 'react-virtualized/styles.css';
import 'dayjs/locale/ko';

import { gql } from '@apollo/client';
import { ConfigProvider } from 'antd';
import koKR from 'antd/lib/locale/ko_KR';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Tracker } from '../utils/tracker';

import { Loading } from '@components';
import { useMe, MeProvider } from '@hooks/useMe';
import { AuthHome, CounselWindow, Home } from '@routes';
import { theme } from '@styles';
import GlobalStyle from '@styles/globalStyle';
import { loggedInVar } from '@utils/cache';

gql`
  query loggedIn {
    loggedIn @client
  }
`;

const App: React.FC = () => {
  return (
    <ConfigProvider locale={koKR}>
      <ThemeProvider theme={theme}>
        <MeProvider>
          <GlobalStyle />
          <Router />
        </MeProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

const Router: React.FC = () => {
  const loggedIn = loggedInVar();
  const { id, loading } = useMe();

  React.useEffect(() => {
    id && Tracker.GAInitialize(id);
  }, [id]);

  if (!loggedIn) {
    return <AuthHome />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className="App"
      style={{ width: '100%', height: '100vh', overflow: 'hidden' }}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/chat/:counselId" component={CounselWindow} />
          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
