import { red } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { TableCellProps } from 'react-virtualized';

import { TMergedTableRow } from './';

type UsersCellProps = TableCellProps;
const UsersCell: React.FC<UsersCellProps> = ({ rowData }) => {
  const { members, creator } = rowData as TMergedTableRow;
  return (
    <Avatar.Group
      maxCount={3}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {members.map(user => (
        <Tooltip
          key={user.id}
          color={creator?.id === user.id ? red.primary : 'black'}
          title={
            creator?.id === user.id
              ? '👑 ' + creator.nickname
              : user.nickname || '(알 수 없음)'
          }
          placement="topRight"
        >
          <Avatar
            style={
              creator?.id === user.id
                ? { border: `2px solid ${red.primary}` }
                : {}
            }
            src={user.avatar}
            icon={<UserOutlined />}
          />
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

export default React.memo(
  UsersCell,
  (prevProps, nextProps) =>
    prevProps.rowData.id === nextProps.rowData.id &&
    _.isEqual(prevProps.rowData.users, nextProps.rowData.users),
);
