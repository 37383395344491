/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import { Input, Radio } from 'antd';
import React from 'react';
import {
  AutoSizer as _AutoSizer,
  Table as _Table,
  Column as _Column,
  TableProps,
  ColumnProps,
  AutoSizerProps,
} from 'react-virtualized';
import styled from 'styled-components';

import LastMessageCell from '../../cells/LastMessageCell';
import RegionCell from '../../cells/RegionCell';
import UsersCell from '../../cells/UsersCell';
import CounselSubscriber from '../../utils/CounselSubscriber';
import Loading from '../../utils/Loading';
import NoData from '../../utils/NoData';
import Placeholder from '../../utils/Placeholder';

import { MessageInterface } from '@components/CounselRoom/Message';
import MemoCell from '@components/CounselTableTabs/cells/memoCell';
import SimpleTextCell from '@components/CounselTableTabs/cells/SimpleTextCell';
import { TableFilter } from '@components/CounselTableTabs/CounselTableTabs';
import ErrorTemplate from '@components/ErrorTemplate';
import { useMe } from '@hooks';
import { openCounselWindow } from '@routes/CounselWindow';
import {
  CounselStatus,
  Area,
  useQueuedCounselsQuery,
  QueuedCounselsResultFragment,
  QueueReason,
} from '@utils/client';
import { fuelTypeStringifer, QueuedReasonMap } from '@utils/formatters';
import { COUNSEL_TABLE_ROW_MESSAGE } from '@utils/fragments';

const Table = _Table as unknown as React.FC<TableProps>;
const Column = _Column as unknown as React.FC<ColumnProps>;
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;

export const QUEUED_COUNSELS_RESULT = gql`
  ${COUNSEL_TABLE_ROW_MESSAGE}
  fragment queuedCounselsResult on Counsel {
    id
    index
    status
    unreadCount
    areas {
      zone {
        province
        district
        neighborhood
      }
    }
    type {
      id
      name
    }
    createdAt
    vehicle {
      id
      plateNumber
      fullModelName
      fuelType {
        base
        hybrid
      }
    }
    messageConnection(last: 1) {
      __typename
      edges {
        node {
          ...CounselTableRowMessage
        }
      }
    }
    memo
    queueReason
    creator {
      id
      nickname
      avatar {
        url
      }
    }
    members {
      id
      nickname
      avatar {
        url
      }
    }
  }
`;

export const QUEUED_COUNSELS = gql`
  ${QUEUED_COUNSELS_RESULT}
  query queuedCounsels {
    queuedCounsels {
      ...queuedCounselsResult
    }
  }
`;

type TTableRowUser = {
  id: string;
  nickname: string;
  avatar?: string;
};

export type TableColumnMessage = {
  id: string;
  avatar?: React.ReactNode;
  data: MessageInterface;
  createdAt: Date;
};

export type TQueuedTableRow = {
  id: string;
  reason: string;
  index: number;
  memo?: string | null;
  modelName?: string | null;
  plateNumber?: string | null;
  areas: string[];
  lastMessage?: TableColumnMessage | null;
  unreads?: number;
  members: TTableRowUser[];
  creator?: TTableRowUser;
};

const DEFAULT_ROW_HEIGHT = 60;
const INCREASED_HEIGHT_BY_REGION = 20;

const QueuedTable = () => {
  const { viewOnly } = useMe();
  const [filter, setFilter] = React.useState<TableFilter>({});
  const [reasonFilter, setReasonFilter] = React.useState<
    'all' | QueueReason.CashbackCheck
  >('all');
  const { data, loading, error } = useQueuedCounselsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const areasFormatHandler = (areas: Area[]): string[] =>
    areas
      .map(area => {
        let result = '';
        if (!area.zone) {
          return result;
        }

        if (area.zone.province) {
          result += `${area.zone.province} `;
        }

        if (area.zone.district) {
          result += `${area.zone.district} `;
        }

        if (area.zone.neighborhood) {
          result += `${area.zone.neighborhood} `;
        }

        return result.slice(0, -1);
      })
      .filter(area => area !== '');

  const filteredCounsels = React.useMemo<QueuedCounselsResultFragment[]>(() => {
    if (!data) {
      return [];
    }

    if (filter.term && filter.term.length < 2) {
      return data.queuedCounsels;
    }

    const filtered = data.queuedCounsels.filter(counsel => {
      if (!filter.term) {
        return true;
      }

      if (filter.term.startsWith('#')) {
        return `#${counsel.index}` === filter.term;
      }
      const vehicle = counsel.vehicle;
      return (
        vehicle?.plateNumber?.includes(filter.term) ||
        vehicle?.fullModelName?.includes(filter.term) ||
        counsel.memo?.includes(filter.term) ||
        counsel.index.toString().includes(filter.term)
      );
    });

    return filtered;
  }, [data, filter]);

  const formatCounselToRow = React.useCallback(
    (counsel: QueuedCounselsResultFragment): TQueuedTableRow => {
      // date formate handling
      const lastMessageObject = counsel.messageConnection.edges[0]
        ?.node as MessageInterface;
      let lastMessage = null;
      if (lastMessageObject) {
        lastMessage = {
          id: lastMessageObject.id,
          avatar: lastMessageObject.author?.avatar?.url,
          data: lastMessageObject,
          createdAt: new Date(lastMessageObject.createdAt),
        };
      }

      const members = counsel.members.map(user => ({
        id: user.id,
        nickname: user.nickname,
        avatar: user.avatar?.url,
      }));

      const creator = counsel.creator && {
        id: counsel.creator.id,
        nickname: counsel.creator.nickname,
        avatar: counsel.creator.avatar?.url,
      };

      let modelName = counsel.vehicle?.fullModelName;
      if (counsel.vehicle?.fuelType) {
        modelName += ` (${fuelTypeStringifer(counsel.vehicle.fuelType)})`;
      }

      return {
        id: counsel.id,
        index: counsel.index,
        memo: counsel.memo,
        unreads: counsel.unreadCount,
        lastMessage,
        reason: counsel.queueReason ? QueuedReasonMap[counsel.queueReason] : '',
        areas: areasFormatHandler(counsel.areas as Area[]),
        modelName,
        plateNumber: counsel.vehicle?.plateNumber,
        members,
        creator,
      };
    },
    [],
  );

  const formatedCounselsForRow: TQueuedTableRow[] = React.useMemo(
    () =>
      filteredCounsels
        .filter(data => {
          if (reasonFilter === 'all') {
            return data;
          } else {
            return data.queueReason === QueueReason.CashbackCheck;
          }
        })
        .map(formatCounselToRow),
    [filteredCounsels, formatCounselToRow, reasonFilter],
  );

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  return (
    <SSearchableChatTable>
      {data &&
        data.queuedCounsels.map(counsel => (
          <CounselSubscriber
            key={`queued-table-${counsel.id}`}
            counselId={counsel.id}
          />
        ))}
      <SCounsellingSearchContainer>
        <Radio.Group
          onChange={e => setReasonFilter(e.target.value)}
          value={reasonFilter}
        >
          <Radio value="all">전체</Radio>
          <Radio value={QueueReason.CashbackCheck}>캐시백 안내</Radio>
        </Radio.Group>
        <Input.Search
          allowClear
          placeholder="상담번호, 차량번호, 차종, 메카닉 메모로 검색..."
          value={filter.term}
          onChange={e => {
            setFilter({ ...filter, term: e.target.value });
          }}
          style={{ width: '400px' }}
        />
      </SCounsellingSearchContainer>
      <SChatTableContainer>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={40}
              rowHeight={({ index }) => {
                const row = formatedCounselsForRow[index];
                const regionCount = row.areas.length;
                return (
                  DEFAULT_ROW_HEIGHT + INCREASED_HEIGHT_BY_REGION * regionCount
                );
              }}
              rowCount={formatedCounselsForRow.length}
              rowGetter={({ index }) => formatedCounselsForRow[index]}
              noRowsRenderer={() =>
                error ? (
                  <SError error={error} message="상담을 불러오지 못했습니다" />
                ) : (
                  <Placeholder status={CounselStatus.Queued} />
                )
              }
              onRowDoubleClick={({ rowData }) => {
                const counsel = rowData as TQueuedTableRow;
                openCounselWindow(counsel.id);
              }}
            >
              <Column
                label="대기 이유"
                dataKey="bookmarkedUntil"
                width={120}
                minWidth={120}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <SimpleTextCell text={props.rowData['reason']} />
                )}
              />
              <Column
                label="번호"
                dataKey="index"
                width={72}
                minWidth={72}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <SimpleTextCell text={props.rowData['index']} />
                )}
              />
              <Column
                label="차량번호"
                dataKey="plateNumber"
                width={130}
                minWidth={130}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <SimpleTextCell text={props.rowData['plateNumber']} />
                )}
              />
              <Column
                label="차종"
                dataKey="modelName"
                width={240}
                minWidth={240}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <SimpleTextCell text={props.rowData['modelName']} />
                )}
              />
              <Column
                label="문의지역"
                dataKey="areas"
                width={297}
                minWidth={90}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <RegionCell disabled={viewOnly} {...props} />
                )}
              />
              <Column
                label="최근 메세지"
                dataKey="lastMessage"
                width={642}
                minWidth={240}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                flexGrow={2}
                cellRenderer={props => <LastMessageCell {...props} />}
              />
              <Column
                label="메카닉 메모"
                dataKey="memo"
                width={389}
                headerStyle={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <MemoCell {...props} disabled={viewOnly} />
                )}
              />
              <Column
                label="참여 현황"
                dataKey={['members', 'creator']}
                width={110}
                minWidth={110}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => <UsersCell {...props} />}
              />
            </Table>
          )}
        </AutoSizer>
      </SChatTableContainer>
    </SSearchableChatTable>
  );
};

export default QueuedTable;

const SSearchableChatTable = styled.div`
  flex: 1;
`;

const SCounsellingSearchContainer = styled.div`
  padding: 8px;
  padding-left: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
  display: flex;
  gap: 26px;
  align-items: center;
`;

const SChatTableContainer = styled.div`
  height: calc(100vh - 95px);
`;

const SError = styled(ErrorTemplate)`
  height: 100%;
  background-color: #fafafa;
`;
