import { gql } from '@apollo/client';
import React from 'react';

import { CounselDetailPresenter } from './CounselDetailPresenter';

import { UpdateCounselData, useUpdateCounselMemoMutation } from '@utils/client';

gql`
  mutation updateCounselMemo($counselId: ID!, $data: UpdateCounselData!) {
    updateCounsel(counselId: $counselId, data: $data) {
      ... on UpdateCounselSuccess {
        counsel {
          id
          memo
        }
      }
    }
  }
`;

type CounselDetailContainerProps = {
  className?: string;
  counselId: string;
};

export const CounselDetailContainer: React.FC<CounselDetailContainerProps> = ({
  counselId,
  className,
}) => {
  const [updateCounselMemoMutation] = useUpdateCounselMemoMutation();
  const updateCounselMemo = async (memo: string) => {
    const data: UpdateCounselData = { memo };
    try {
      await updateCounselMemoMutation({
        variables: { counselId, data },
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <CounselDetailPresenter
      className={className}
      counselId={counselId}
      updateCounselMemo={updateCounselMemo}
    />
  );
};
