import { Skeleton } from 'antd';
import React from 'react';

interface CounselInfoLoadingSkeletonProps {
  className?: string;
}
export const CounselInfoLoadingSkeleton: React.FC<
  CounselInfoLoadingSkeletonProps
> = ({ className }) => {
  return (
    <Skeleton className={className} active avatar paragraph={{ rows: 2 }} />
  );
};
