import { InboxOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

import { CounselStatus } from '@utils/client';
import { COUNSEL_STATE } from '@utils/constants';

interface PlaceholderProps {
  status: CounselStatus;
}

const Placeholder: React.FC<PlaceholderProps> = ({ status }) => {
  return (
    <SBackground>
      <InboxOutlined style={{ fontSize: 32 }} />
      {COUNSEL_STATE[status].title} 상태인 상담이 없습니다!
    </SBackground>
  );
};

export default Placeholder;

const SBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  height: 100%;
  padding: 150px 20px;
  background-color: #fafafa;
  color: ${props => props.theme.colors.TEXT_MUTED};
`;
