import {
  CommentOutlined,
  FolderOutlined,
  ShopOutlined,
  CreditCardOutlined,
  CarOutlined,
} from '@ant-design/icons';
import { Tabs, Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CounselDetail } from './CounselDetail';
import Payment from './Payment';
import Shop from './Shop';
import Template from './Template';
import Vehicle from './Vehicle';

import { useMe } from '@hooks';
import { CounselStatus, useCounselInfoQuery } from '@utils/client';

export type TaskPadProps = {
  className?: string;
  counselId: string;
};

const TaskPad = ({ className, counselId }: TaskPadProps) => {
  const { viewOnly, readOnly } = useMe();
  const { data } = useCounselInfoQuery({
    variables: {
      counselId,
    },
    fetchPolicy: 'cache-first',
  });

  if (!data) {
    return null;
  }

  const estimating =
    data.counsel.status === CounselStatus.Estimated ||
    data.counsel.status === CounselStatus.Estimating;

  return (
    <SContinaer className={className}>
      {!data ? (
        <SPlaceholder>상담정보를 불러오지 못했습니다.</SPlaceholder>
      ) : (
        <Tabs
          type="card"
          animated={false}
          style={{ height: '100%', overflowY: 'hidden' }}
          tabBarStyle={{ marginBottom: 0 }}
          tabBarExtraContent={
            readOnly ? (
              <Tag color="magenta">VISITOR ACCESS</Tag>
            ) : (
              viewOnly && <Tag color="purple">ADMIN ACCESS</Tag>
            )
          }
        >
          <STabPane
            key="counselDetailInfo"
            tab={
              <span>
                <CommentOutlined />
                상담정보
              </span>
            }
          >
            <CounselDetail counselId={counselId} />
          </STabPane>
          {!estimating && (
            <STabPane
              key="counselTemplate"
              tab={
                <span>
                  <FolderOutlined />
                  템플릿
                </span>
              }
            >
              <Template counselId={counselId} />
            </STabPane>
          )}

          {!viewOnly && (
            <STabPane
              key="counselPayment"
              tab={
                <span>
                  <CreditCardOutlined />
                  견적 / 결제
                </span>
              }
            >
              <Payment counselId={counselId} />
            </STabPane>
          )}
          {!viewOnly && !estimating && (
            <STabPane
              forceRender
              key="conselShop"
              tab={
                <span>
                  <ShopOutlined />
                  정비소
                </span>
              }
            >
              <Shop counselId={counselId} />
            </STabPane>
          )}
          {!viewOnly && (
            <STabPane
              key="counselVehicle"
              tab={
                <span>
                  <CarOutlined />
                  차량정보
                </span>
              }
            >
              <Vehicle counselId={counselId} />
            </STabPane>
          )}
        </Tabs>
      )}
    </SContinaer>
  );
};

export default React.memo(TaskPad);

const SContinaer = styled.div`
  flex: 1;
  overflow-y: hidden;
`;

const SPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
  color: ${props => props.theme.colors.TEXT_MUTED};
`;

const STabPane = styled(Tabs.TabPane)`
  height: 100%;
  border: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
  border-top-width: 0px;
`;
