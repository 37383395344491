import { WarningOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

type ErrorMessageProps = {
  children: React.ReactNode;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => {
  return <InlineBubble icon={<WarningOutlined />}>{children}</InlineBubble>;
};

export default ErrorMessage;
