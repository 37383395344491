import { FileUnknownOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

const NoData: React.FC = () => {
  return (
    <SBackground>
      <FileUnknownOutlined style={{ fontSize: 32 }} /> 상담을 찾을 수 없습니다.
    </SBackground>
  );
};

const SBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 150px 20px;
  background-color: #fafafa;
  color: ${props => props.theme.colors.TEXT_MUTED};
`;

export default NoData;
