import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Button,
  Checkbox,
  PageHeader,
  Popconfirm,
  Spin,
  notification,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import TemplateBreadcrumb from '../TemplateBreadcrumb';

import Cell from './Cell';
import { TemplateDetailContainerProps } from './TemplateDetailContainer';

import ErrorTemplate from '@components/ErrorTemplate';
import { useMe, useMessage } from '@hooks';
import { MessageContentInput } from '@hooks/useMessage';
import {
  useTemplateQuery,
  useCounselStatusQuery,
  CounselStatus,
} from '@utils/client';
import { Tracker } from '@utils/tracker';

gql`
  query counselStatus($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      status
    }
  }
`;

interface ICell {
  selected: boolean;
  content: MessageContentInput;
}
interface TemplateDetailPresenterProps extends TemplateDetailContainerProps {
  deleteTemplate: () => Promise<void>;
}
const TemplateDetailPresenter: React.FC<TemplateDetailPresenterProps> = ({
  className,
  counselId,
  templateId,
  onClose,
  onEdit,
  onChangeDirectory,
  deleteTemplate: deleteTemplateProp,
}) => {
  const { sendMessages } = useMessage({ counselId });
  const { loading, data, error } = useTemplateQuery({
    fetchPolicy: 'no-cache',
    variables: { templateId },
  });
  const { readOnly, viewOnly } = useMe();
  const counselStatusData = useCounselStatusQuery({
    variables: {
      counselId,
    },
    fetchPolicy: 'cache-only',
  });

  const [deleting, setDeleting] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [cells, setCells] = React.useState<ICell[]>([]);

  const breadcrumb = React.useMemo(
    () =>
      data?.template.path ? (
        <TemplateBreadcrumb
          directories={data.template.path}
          onClickDirectory={onChangeDirectory}
        />
      ) : undefined,
    [data?.template.path, onChangeDirectory],
  );

  const active = React.useMemo(() => {
    if (!counselStatusData.data) {
      return false;
    }

    const status = counselStatusData.data.counsel.status;
    return (
      status !== CounselStatus.Pending && status !== CounselStatus.Finished
    );
  }, [counselStatusData.data]);

  const initializeCells = () => {
    if (data?.template) {
      const cells: ICell[] = [];
      for (const content of data.template.messages) {
        let contentInput: MessageContentInput;
        switch (content.__typename) {
          case 'TextMessageContent':
            contentInput = { ...content, type: 'text' };
            break;
          case 'FileMessageContent':
            if (content.file.__typename === 'Video')
              contentInput = {
                url: content.file.url,
                type: 'video',
              };
            else
              contentInput = {
                url: content.file.url,
                type: 'image',
              };

            break;
          default:
            continue;
        }
        cells.push({ selected: false, content: contentInput });
      }
      setCells(cells);
    }
  };

  React.useEffect(initializeCells, [data?.template]);

  const deleteTemplate = async () => {
    setDeleting(true);

    try {
      await deleteTemplateProp();
      onClose?.();
    } catch (error: any) {
      notification.error({
        message: '템플릿 삭제 실패',
        description: error.message,
      });
    } finally {
      setDeleting(false);
    }
  };

  const sendTemplate = async () => {
    setSending(true);
    const messageIds = await sendMessages(
      cells.filter(cell => cell.selected).map(cell => cell.content),
      { interval: 1000, bot: false },
    );
    data &&
      Tracker.sendEvent('send_message_template', {
        title: data.template.name,
        counsel_id: counselId,
        template_id: data.template.id,
        message_ids: messageIds ? messageIds : [],
      });
    setSending(false);
  };

  const onToggleAll = (selected: boolean) => {
    setCells(cells => cells.map(cell => ({ ...cell, selected })));
  };

  const onToggle = (selected: boolean, index: number) => {
    setCells(cells =>
      cells.map((cell, i) => (index === i ? { ...cell, selected } : cell)),
    );
  };

  const onContentChange = (content: MessageContentInput, index: number) => {
    setCells(cells =>
      cells.map((cell, i) => (index === i ? { ...cell, content } : cell)),
    );
  };

  if (loading) {
    return (
      <SCenterContainer className={className}>
        <Spin />
      </SCenterContainer>
    );
  }

  if (error) {
    return (
      <SCenterContainer className={className}>
        <ErrorTemplate message="템플릿을 불러오지 못했습니다" error={error} />
      </SCenterContainer>
    );
  }

  return data ? (
    <STemplateDetailPresenter
      className={className}
      title={data.template.name}
      backIcon={<CloseOutlined />}
      onBack={onClose}
      breadcrumbRender={() => breadcrumb}
      extra={[
        <Popconfirm
          key="delete-template"
          title={`정말로 ${data.template.name} 템플릿을 삭제하시겠습니까?`}
          onCancel={deleteTemplate}
          okText="취소"
          cancelText="삭제"
          okButtonProps={{ type: 'default' }}
          cancelButtonProps={{ danger: true }}
        >
          <Button
            danger
            icon={deleting ? <LoadingOutlined spin /> : <DeleteOutlined />}
          />
        </Popconfirm>,
        <Button key="edit-template" icon={<EditOutlined />} onClick={onEdit} />,
        <Button
          key="reset-template"
          icon={<ReloadOutlined />}
          onClick={initializeCells}
        />,
        <Button
          key="send-template"
          type="primary"
          disabled={sending || !active || viewOnly || readOnly}
          icon={<SendOutlined />}
          onClick={sendTemplate}
        >
          {sending ? '전송중...' : '전송'}
        </Button>,
      ]}
    >
      <SCells>
        <SCell>
          <Checkbox
            onChange={event => onToggleAll(event.target.checked)}
            style={{ marginRight: 10 }}
          />
          전체 선택
        </SCell>
        {cells.map((cell, index) => (
          <Cell
            key={index}
            {...cell}
            onToggle={selected => onToggle(selected, index)}
            onContentChange={content => onContentChange(content, index)}
          />
        ))}
      </SCells>
    </STemplateDetailPresenter>
  ) : null;
};

export default TemplateDetailPresenter;

const STemplateDetailPresenter = styled(PageHeader)``;
const SCells = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 172px);
`;
const SCell = styled.li`
  display: flex;
  flex-direction: row;
`;
const SCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
