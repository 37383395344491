import { Divider } from 'antd';
import React from 'react';
import styled from 'styled-components';

type EstimateItem = {
  itemId: string;
  name: string;
  discountRate: number;
  price: number;
  quantity: number;
  totalAmount: number;
  paymentAmount: number;
};

type SendEstimateConfirmModalProps = {
  vehicleName: string;
  plateNumber: string;
  items: EstimateItem[];
  shippingPrice: number;
  recommendReason?: string;
  additionalDiscount?: number;
  totalItemAmount: number;
  paymentAmount: number;
  insurance: boolean;
  cashbackRate?: number;
  cashbackAmount?: number;
};

const SendEstimateConfirmModal: React.FC<SendEstimateConfirmModalProps> = ({
  vehicleName,
  plateNumber,
  items,
  shippingPrice,
  recommendReason,
  additionalDiscount = 0,
  totalItemAmount,
  paymentAmount,
  insurance,
  cashbackRate,
  cashbackAmount,
}) => {
  const itemsBasicPrice = items
    .map(item => item.price * item.quantity)
    .reduce((acc, cur) => acc + cur, 0);

  return (
    <SContainer>
      <SRowBox>
        <SLabel>차종/연식</SLabel>
        <SBasicValue>{vehicleName}</SBasicValue>
      </SRowBox>
      <SRowBox>
        <SLabel>차량번호</SLabel>
        <SBasicValue>{plateNumber}</SBasicValue>
      </SRowBox>
      <Divider />
      {items.map(item => {
        return (
          <>
            <SRowBox>
              <SLabel>상품코드</SLabel>
              <SBasicValue>{item.itemId}</SBasicValue>
            </SRowBox>
            <SRowBox>
              <SLabel>상품명</SLabel>
              <SBasicValue>{item.name}</SBasicValue>
            </SRowBox>
            <SRowBox>
              <SLabel>개당 금액(수량)</SLabel>
              <SPriceValue>{`${item.price.toLocaleString()}원 (${
                item.quantity
              }개)`}</SPriceValue>
            </SRowBox>
            {item.quantity >= 2 && (
              <SRowBox>
                <SLabel>합계</SLabel>
                <SPriceValue
                  discounted={item.discountRate > 0}
                >{`${item.totalAmount.toLocaleString()}원`}</SPriceValue>
              </SRowBox>
            )}
            <SRowBox style={{ marginBottom: '20px' }}>
              <SLabel>결제 금액</SLabel>
              <SBasicValue>
                {`${item.paymentAmount.toLocaleString()}원`}
                {item.discountRate > 0 && (
                  <SDiscounted style={{ marginLeft: '4px' }}>
                    -{(item.discountRate * 100).toLocaleString()}%
                  </SDiscounted>
                )}
              </SBasicValue>
            </SRowBox>
          </>
        );
      })}
      <Divider />
      <SRowBox>
        <SLabel style={{ fontWeight: 'bold', marginBottom: '20px' }}>
          상품 총 금액
        </SLabel>
        <SBasicValue
          style={{ fontWeight: 'bold' }}
        >{`${totalItemAmount.toLocaleString()}원`}</SBasicValue>
      </SRowBox>
      {additionalDiscount > 0 && (
        <SRowBox>
          <SLabel style={{ fontWeight: 'bold', marginBottom: '20px' }}>
            추가 할인 금액
          </SLabel>
          <SDiscounted
            style={{ fontWeight: 'bold' }}
          >{`-${additionalDiscount.toLocaleString()}원`}</SDiscounted>
        </SRowBox>
      )}

      <SRowBox>
        <SLabel style={{ fontWeight: 'bold', marginBottom: '20px' }}>
          운임비
        </SLabel>
        <SBasicValue
          style={{ fontWeight: 'bold' }}
        >{`${shippingPrice.toLocaleString()}원`}</SBasicValue>
      </SRowBox>

      <SRowBox>
        <SLabel style={{ fontWeight: 'bold', fontSize: '20px' }}>
          {insurance ? '보험사 청구 금액' : '총 결제 금액'}
        </SLabel>
        <SPriceValue
          style={{ fontWeight: 'bold', fontSize: '20px', color: '#1890FF' }}
        >{`${paymentAmount.toLocaleString()}원`}</SPriceValue>
      </SRowBox>
      <SThinBox>(세금포함가)</SThinBox>
      <SShippingIntroText>
        * 닥터차는 경동택배를 이용하고 있으며, 택배사 변동이 있을 시 운임비
        변동과 함께 추가 안내드리겠습니다.
      </SShippingIntroText>
      {recommendReason && (
        <SRecommendReasonBox>
          <SRecommendReasonTitle>
            고객님의 {vehicleName}을 위한{' '}
            <SRecommendReasonBlueTitle>
              부품 추천 이유
            </SRecommendReasonBlueTitle>
          </SRecommendReasonTitle>
          <SRecommendReasonText>{recommendReason}</SRecommendReasonText>
        </SRecommendReasonBox>
      )}
      {insurance && cashbackRate && cashbackAmount !== undefined ? (
        <PriceBenefitBox>
          <p>이 견적으로 닥터차에서 보험 처리시</p>
          <p style={{ color: '#1890FF' }}>{cashbackRate}% 캐시백 적용으로</p>
          <p style={{ fontSize: '18px', color: '#1890FF' }}>
            <span style={{ color: '#48CF7C' }}>
              {cashbackAmount.toLocaleString()}원
            </span>
            <span>을 받을 수 있습니다.</span>
          </p>
        </PriceBenefitBox>
      ) : (
        <PriceBenefitBox>
          <p>닥터차에서 이 견적으로 구매시 공식 센터가 보다</p>
          <p style={{ fontSize: '18px', color: '#1890FF' }}>{`${(
            itemsBasicPrice - totalItemAmount
          ).toLocaleString()}원 저렴하게 구매할 수 있습니다.`}</p>
        </PriceBenefitBox>
      )}
    </SContainer>
  );
};

export default SendEstimateConfirmModal;

const SContainer = styled.div`
  width: 100%;
  flex-direction: column;
  gap: 3px;
`;

const SRowBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  min-width: 68px;
`;

const SBasicValue = styled.span`
  font-size: 14px;
  color: #000000;
`;

const SDiscounted = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #4cdf87;
`;

const SShippingIntroText = styled.span`
  margin-top: 20px;
  color: #007aff;
  font-size: 12px;
  display: flex;
`;

const SPriceValue = styled.span<{ discounted?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: #a4aaae;
  ${props => (props.discounted ? 'text-decoration: line-through' : '')};
`;

const SThinBox = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  color: #5f656a;
`;

const SRecommendReasonBox = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const SRecommendReasonTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const SRecommendReasonBlueTitle = styled.span`
  color: #1890ff;
`;

const SRecommendReasonText = styled.span`
  font-size: 14px;
  color: #8c8c8c;
  margin-top: 12px;
`;

const PriceBenefitBox = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 16px;
  color: #212121;
`;
