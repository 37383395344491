import { Image as AntdImage } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { MessageProps } from '../';

import ImageFallback from '@assets/image/fallback-image.png';
import { FileMessage as _FileMessage, Image, Video } from '@utils/client';

type FileMessageProps = {
  type?: 'Image' | 'Video';
} & MessageProps;

const FileMessage: React.FC<FileMessageProps> = ({ message, type }) => {
  const fileMessage = message as _FileMessage;
  if (type === 'Image') {
    return <ImageMessage image={fileMessage.file} />;
  } else if (type === 'Video') {
    return <VideoMessage video={fileMessage.file} />;
  } else {
    return null;
  }
};

export default FileMessage;

type ImageMessageProps = {
  image?: Image;
};

const ImageMessage: React.FC<ImageMessageProps> = ({ image, ...props }) => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <>
      {!loaded && (
        <div style={{ width: '70%' }}>
          <SPlaceholder
            width={image?.metadata?.width || 640}
            height={image?.metadata?.height || 640}
          />
        </div>
      )}
      <SImageMessage
        {...props}
        width={loaded ? '70%' : 0}
        src={image?.url}
        fallback={ImageFallback}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

type VideoMessageProps = {
  video?: Video;
};

const VideoMessage: React.FC<VideoMessageProps> = ({ video, ...props }) => {
  return (
    <video
      {...props}
      controls
      width="70%"
      preload="metadata"
      style={{ borderRadius: 14 }}
    >
      <source src={video?.url} />
    </video>
  );
};

const SImageMessage = styled(AntdImage)`
  border-radius: 14px;
`;

const SPlaceholder = styled.div<{ width: number; height: number }>`
  border-radius: 14px;
  background-color: ${props => props.theme.colors.BACKGROUND_DARK};

  aspect-ratio: ${props => `${props.width} / ${props.height}`};
`;
