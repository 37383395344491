export const requestPermission = () => {
  if (!Notification) {
    alert(
      '해당 브라우저에서는 알림 기능이 지원되지 않습니다. 최신 버전의 Chrome을 이용해 주세요.',
    );
    return;
  }

  if (Notification.permission !== 'granted') Notification.requestPermission();
};

export const notify = async (data: {
  title: string;
  body?: string;
  icon?: string;
  onClick?: () => void;
}) => {
  if (Notification.permission !== 'granted') Notification.requestPermission();
  else {
    const audio = new Audio('/audio/notification.mp3');
    audio.play();

    const notification = new Notification(data.title, {
      body: data.body,
      icon: data.icon,
      image: 'https://image.doctor-cha.com/extra/sample.png',
      lang: 'ko-KR',
    });

    notification.onclick = () => {
      data.onClick?.();
      notification.close();
    };
  }
};
