import { Spin, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { useMe } from '@hooks';

const Loading: React.FC = () => {
  const { clearMe } = useMe();
  const handleLogout = () => {
    localStorage.removeItem('token');
    clearMe();
    window.location.reload();
  };

  return (
    <SContainer>
      <Spin size="large" style={{ margin: 40 }} />
      <SText>로딩중...</SText>
      <Button onClick={handleLogout} danger style={{ marginTop: '18px' }}>
        로그인 정보 초기화
      </Button>
    </SContainer>
  );
};

const SContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SText = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;

export default Loading;
