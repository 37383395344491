import { useApolloClient, gql } from '@apollo/client';

import {
  PaymentFragment,
  ShippingFragment,
} from '../components/TaskPad/Payment/PaymentPresenter';

import {
  TaskpadOrderFragment,
  CachedCounselDetailQuery,
  CachedOrderQuery,
  CachedOrderDocument,
  CachedCounselDetailDocument,
} from '@utils/client';
import { TASKPAD_ORDER } from '@utils/fragments';

type CounselWindowCache = {
  appendOrder: (order: TaskpadOrderFragment, counselId: string) => void;
  removeOrder: (orderId: string, counselId: string) => void;
  updateOrder: (
    orderId: string,
    updatedOrder: TaskpadOrderFragment,
    counselId: string,
  ) => void;
  findOrder: (
    orderId: string,
    counselId: string,
  ) => TaskpadOrderFragment | undefined;
  updateShipping: (
    orderId: string,
    counselId: string,
    shipping: ShippingFragment,
  ) => void;
  updatePayment: (
    counselId: string,
    orderId: string,
    payment: PaymentFragment,
  ) => void;
};

gql`
  ${TASKPAD_ORDER}
  query CachedOrder($orderId: ID!) {
    order(orderId: $orderId) {
      ...taskpadOrder
    }
  }
`;

gql`
  query CachedCounselDetail($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      ...counselDetailInfo
      orders {
        ...taskpadOrder
      }
    }
  }
`;

const useCounselWindowCache = (): CounselWindowCache => {
  const { cache } = useApolloClient();

  const findOrder = (orderId: string) => {
    const cachedOrder = cache.readQuery<CachedOrderQuery>({
      query: CachedOrderDocument,
      variables: { orderId },
    });

    return cachedOrder?.order;
  };

  const updateShipping = (
    orderId: string,
    counselId: string,
    shipping: ShippingFragment,
  ) => {
    const targetOrder = findOrder(orderId);

    if (!targetOrder) {
      return;
    }

    const updatedOrder = {
      ...targetOrder,
      shipping,
    };
    updateOrder(orderId, updatedOrder, counselId);
  };

  const appendOrder = (order: TaskpadOrderFragment, counselId: string) => {
    const cachedCounsel = cache.readQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
    });

    if (!cachedCounsel) {
      return;
    }

    const orders = cachedCounsel.counsel.orders;
    const appendedOrders = orders ? [...orders, order] : [order];
    const updatedCounsel = {
      ...cachedCounsel.counsel,
      orders: appendedOrders,
    };

    cache.writeQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
      data: {
        counsel: updatedCounsel,
      },
    });
  };

  const removeOrder = (orderId: string, counselId: string) => {
    const cachedCounsel = cache.readQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
    });

    if (!cachedCounsel) {
      return;
    }

    const orders = cachedCounsel.counsel.orders;

    const filteredOrder = orders.filter(order => order.id !== orderId);
    const updatedCounsel = {
      ...cachedCounsel.counsel,
      orders: filteredOrder,
    };

    cache.writeQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
      data: {
        counsel: updatedCounsel,
      },
    });
  };

  const updateOrder = (
    orderId: string,
    updatedOrder: TaskpadOrderFragment,
    counselId: string,
  ) => {
    if (orderId !== updatedOrder.id) {
      return;
    }

    const cachedCounsel = cache.readQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
    });

    if (!cachedCounsel) {
      return;
    }

    const orders = cachedCounsel.counsel.orders;
    const updatedOrders = orders.map(order => {
      if (order.id === orderId) {
        return updatedOrder;
      } else {
        return order;
      }
    });

    const updatedCounsel = {
      ...cachedCounsel.counsel,
      orders: updatedOrders,
    };

    cache.writeQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
      data: {
        counsel: updatedCounsel,
      },
    });
  };

  const updatePayment = (
    counselId: string,
    orderId: string,
    payment: PaymentFragment,
  ) => {
    const cachedCounsel = cache.readQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
    });

    if (!cachedCounsel) {
      return;
    }

    const order = cachedCounsel.counsel.orders.find(
      order => order.id === orderId,
    );
    if (!order) {
      return;
    }

    if (!order.payment) {
      return;
    }

    const updatedOrder = {
      ...order,
      payment,
    };

    const updatedCounsel = {
      ...cachedCounsel.counsel,
      order: updatedOrder,
    };

    cache.writeQuery<CachedCounselDetailQuery>({
      query: CachedCounselDetailDocument,
      variables: {
        counselId,
      },
      data: {
        counsel: updatedCounsel,
      },
    });
  };

  return {
    appendOrder,
    removeOrder,
    updateOrder,
    findOrder,
    updateShipping,
    updatePayment,
  };
};

export default useCounselWindowCache;
