import { gql } from '@apollo/client';
import React from 'react';

import useCounselCache from '@hooks/useCounselCache';
import { useUpdatedCounselSubscription } from '@utils/client';

type CounselSubscriberSwitch = {
  updatedCounsel?: boolean;
};

interface CounselSubscriberProps {
  counselId: string;
  subscribeSwitch?: CounselSubscriberSwitch;
}

gql`
  subscription updatedCounsel($counselId: ID!) {
    updatedCounsel(counselId: $counselId) {
      ...CounselTableRow
    }
  }
`;

gql`
  query localCounsel($counselId: ID!) {
    counsel(counselId: $counselId) @client {
      id @client
      status @client
    }
  }
`;

// Default는 다 구독하는걸로 하고, 원하는 경우 어떤 쿼리는 끌 수 있도록.
const CounselSubscriber: React.FC<CounselSubscriberProps> = ({
  subscribeSwitch,
  counselId,
}) => {
  const opendedSwitch: CounselSubscriberSwitch = {
    updatedCounsel: true,
    ...subscribeSwitch,
  };
  const { updateCounsel } = useCounselCache();
  useUpdatedCounselSubscription({
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) {
        return;
      }

      const updatedCounsel = data.updatedCounsel;

      updateCounsel(updatedCounsel);
    },
    variables: {
      counselId,
    },
    skip: !opendedSwitch.updatedCounsel,
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
  });

  return null;
};
export default CounselSubscriber;
