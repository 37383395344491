import { useApolloClient } from '@apollo/client';

import {
  FINISHED_COUNSEL_CONNECTION,
  FINISHED_COUNSELS_RESULT,
} from '@components/CounselTableTabs/tables/FinishedTable';
import {
  ONGOING_COUNSELS,
  ONGOING_COUNSELS_RESULT,
} from '@components/CounselTableTabs/tables/OngoingTable';
import {
  QUEUED_COUNSELS,
  QUEUED_COUNSELS_RESULT,
} from '@components/CounselTableTabs/tables/QueuedTable';
import { useMe } from '@hooks';
import {
  CounselStatus,
  OngoingCounselsQuery,
  CounselTableRowFragment,
  CounselTableRowMessageFragment,
  QueuedCounselsQuery,
  FinishedCounselConnectionQuery,
} from '@utils/client';

type FindCounselsResult = {
  counsels: CounselTableRowFragment[];
  status: CounselStatus;
};

type CounselCache = {
  findCounselById: (counselId: string) => FindCounselsResult | undefined;
  updateCounsel: (updatedCounsel: CounselTableRowFragment) => boolean;
  updateCounselStatus: (
    from: CounselStatus,
    to: CounselStatus,
    counselId: string,
  ) => void;
  appendCounsel: (newCounsel: CounselTableRowFragment) => void;
  removeCounsel: (counselId: string, status: CounselStatus) => void;
  appendMessageToCounsel: (
    newMessage: CounselTableRowMessageFragment,
    counselId: string,
    counselStatus: CounselStatus,
  ) => void;
};

const useCounselCache = (): CounselCache => {
  const { cache } = useApolloClient();
  const { id } = useMe();

  const findCounselById = (
    counselId: string,
  ): FindCounselsResult | undefined => {
    const ongoingCounsels = cache.readQuery<OngoingCounselsQuery>({
      query: ONGOING_COUNSELS,
    });

    if (
      ongoingCounsels?.ongoingCounsels.some(counsel => counsel.id === counselId)
    ) {
      return {
        counsels: ongoingCounsels.ongoingCounsels as CounselTableRowFragment[],
        status: CounselStatus.Ongoing,
      };
    }

    const queuedCounsels = cache.readQuery<QueuedCounselsQuery>({
      query: QUEUED_COUNSELS,
    });

    if (
      queuedCounsels?.queuedCounsels.some(counsel => counsel.id === counselId)
    ) {
      return {
        counsels: queuedCounsels.queuedCounsels as CounselTableRowFragment[],
        status: CounselStatus.Queued,
      };
    }

    const finishedCounselsConnection =
      cache.readQuery<FinishedCounselConnectionQuery>({
        query: FINISHED_COUNSEL_CONNECTION,
      });

    const finishedCounsels =
      finishedCounselsConnection?.finishedCounselConnection.edges.map(
        edge => edge.node,
      );

    if (
      finishedCounsels &&
      finishedCounsels.some(counsel => counsel.id === counselId)
    ) {
      return {
        counsels: finishedCounsels as any[],
        status: CounselStatus.Finished,
      };
    }
  };

  const counselsCache = (status: CounselStatus) => {
    switch (status) {
      case CounselStatus.Ongoing: {
        const ongoingCounsels = cache.readQuery<OngoingCounselsQuery>({
          query: ONGOING_COUNSELS,
        });
        return {
          query: ONGOING_COUNSELS,
          queryName: 'ongoingCounsels',
          resultFragment: ONGOING_COUNSELS_RESULT,
          data: ongoingCounsels?.ongoingCounsels as CounselTableRowFragment[],
        };
      }
      case CounselStatus.Queued: {
        const queuedCounsels = cache.readQuery<QueuedCounselsQuery>({
          query: QUEUED_COUNSELS,
        });
        return {
          query: QUEUED_COUNSELS,
          queryName: 'queuedCounsels',
          resultFragment: QUEUED_COUNSELS_RESULT,
          data: queuedCounsels?.queuedCounsels as CounselTableRowFragment[],
        };
      }
      case CounselStatus.Finished: {
        const finishedCounselsConnection =
          cache.readQuery<FinishedCounselConnectionQuery>({
            query: FINISHED_COUNSEL_CONNECTION,
          });

        const finishedCounsels =
          finishedCounselsConnection?.finishedCounselConnection.edges.map(
            edge => edge.node,
          );
        return {
          query: FINISHED_COUNSEL_CONNECTION,
          queryName: 'finishedCounselConnection',
          resultFragment: FINISHED_COUNSELS_RESULT,
          data: finishedCounsels as any[],
        };
      }
      default:
        return null;
    }
  };

  const exceptCounselByStatus = (
    cachedCounsels: CounselTableRowFragment[],
    updatedCounsel: CounselTableRowFragment,
  ) => {
    const cachedCounsel = cachedCounsels.find(
      counsel => counsel.id === updatedCounsel.id,
    );
    if (!cachedCounsel) {
      return;
    }

    const cachedCounselsMetadata = counselsCache(cachedCounsel.status);
    if (!cachedCounselsMetadata) {
      return;
    }

    // 테이블 쿼리가 커넥션이 아닌 경우에는 counsels 쿼리필드를 공유하므로 타입정의없이 캐시 write 가능
    if (
      cachedCounsel.status !== CounselStatus.Finished &&
      cachedCounsel.status !== CounselStatus.Pending
    ) {
      const filtered = cachedCounsels.filter(
        cachedCounsel => cachedCounsel.id !== updatedCounsel.id,
      );

      cache.writeQuery({
        query: cachedCounselsMetadata.query,
        data: {
          [cachedCounselsMetadata.queryName]: filtered,
        },
      });

      return;
    }

    // counselConnection 쿼리는 별도의 쿼리타입을 사용하므로, 타입분기 처리 해야한다.
    if (cachedCounsel.status === CounselStatus.Finished) {
      const finishedCounsels = cache.readQuery<FinishedCounselConnectionQuery>({
        query: cachedCounselsMetadata.query,
      });

      if (!finishedCounsels) {
        return;
      }

      const finishedFiltered =
        finishedCounsels.finishedCounselConnection.edges.filter(
          edge => edge.node.id !== updatedCounsel.id,
        );
      cache.writeQuery<FinishedCounselConnectionQuery>({
        query: FINISHED_COUNSEL_CONNECTION,
        data: {
          finishedCounselConnection: {
            ...finishedCounsels?.finishedCounselConnection,
            edges: finishedFiltered,
          },
        },
      });
    }
  };

  const includeCounselByStatus = (
    cachedCounselsData: FindCounselsResult,
    updatedCounsel: CounselTableRowFragment,
  ) => {
    const fromPending =
      cachedCounselsData.status === CounselStatus.Pending &&
      (updatedCounsel.status === CounselStatus.Ongoing ||
        updatedCounsel.status === CounselStatus.Estimated ||
        updatedCounsel.status === CounselStatus.Estimating);
    const isMember = updatedCounsel.members?.some(member => member.id === id);
    let isNeedIncludeCache = true;
    if (fromPending) {
      isNeedIncludeCache = isMember;
    }

    if (!isNeedIncludeCache) {
      return;
    }

    const updatedCounselsMetadata = counselsCache(updatedCounsel.status);

    if (!updatedCounselsMetadata) {
      return;
    }

    if (
      updatedCounsel.status !== CounselStatus.Finished &&
      updatedCounsel.status !== CounselStatus.Pending
    ) {
      const merged = updatedCounselsMetadata.data
        ? [...updatedCounselsMetadata.data, updatedCounsel]
        : [updatedCounsel];
      cache.writeQuery({
        query: updatedCounselsMetadata.query,
        data: {
          [updatedCounselsMetadata.queryName]: merged,
        },
      });
      return;
    }

    if (updatedCounsel.status === CounselStatus.Finished) {
      const finishedCounsels = cache.readQuery<FinishedCounselConnectionQuery>({
        query: FINISHED_COUNSEL_CONNECTION,
      });

      if (!finishedCounsels) {
        return;
      }

      const mergedEdge = [
        { node: { ...updatedCounsel }, cursor: 'new' },
        ...(finishedCounsels.finishedCounselConnection.edges as any[]),
      ];
      cache.writeQuery<FinishedCounselConnectionQuery>({
        query: FINISHED_COUNSEL_CONNECTION,
        data: {
          finishedCounselConnection: {
            ...finishedCounsels?.finishedCounselConnection,
            edges: mergedEdge,
          },
        },
      });
    }
  };

  const updateCounselStatus = (
    from: CounselStatus,
    to: CounselStatus,
    counselId: string,
  ) => {
    const cachedCounselsData = counselsCache(from);
    if (!cachedCounselsData) {
      return;
    }

    const cachedCounsel = cachedCounselsData.data?.find(
      counsel => counsel.id === counselId,
    );

    if (!cachedCounsel) {
      return false;
    }

    const updatedCounselsMetadata = counselsCache(to);

    if (!updatedCounselsMetadata) {
      return false;
    }

    exceptCounselByStatus(cachedCounselsData.data, cachedCounsel);
    includeCounselByStatus(
      { status: from, counsels: cachedCounselsData.data },
      { ...cachedCounsel, status: to },
    );
  };

  //Pending & Finished counsels는 업데이트 되지 않으므로 그냥 사용.
  const updateCounsel = (updatedCounsel: CounselTableRowFragment) => {
    if (!updatedCounsel) {
      return false;
    }

    const cachedCounselsData = findCounselById(updatedCounsel.id);

    if (!cachedCounselsData) {
      return false;
    }

    if (cachedCounselsData.status !== updatedCounsel.status) {
      return false;
    }

    const cachedCounsel = cachedCounselsData.counsels.find(
      counsel => counsel.id === updatedCounsel.id,
    );

    if (!cachedCounsel) {
      return false;
    }

    const updatedCounselsCacheData = counselsCache(updatedCounsel.status);

    if (!updatedCounselsCacheData) {
      return false;
    }

    const updatedCounsels = cachedCounselsData.counsels.map(counsel => {
      if (counsel.id === updatedCounsel.id) {
        return updatedCounsel;
      }

      return counsel;
    });
    cache.writeQuery({
      query: updatedCounselsCacheData.query,
      data: {
        [updatedCounselsCacheData.queryName]:
          updatedCounsels as CounselTableRowFragment[],
      },
      broadcast: true,
    });
    return true;
  };

  const appendCounsel = (newCounsel: CounselTableRowFragment) => {
    const cachedCounselData = counselsCache(newCounsel.status);

    if (!cachedCounselData) {
      return;
    }

    if (
      cachedCounselData?.data?.some(counsel => counsel.id === newCounsel.id)
    ) {
      return;
    }

    includeCounselByStatus(
      { counsels: cachedCounselData.data, status: newCounsel.status },
      newCounsel,
    );
  };

  const removeCounsel = (counselId: string, status: CounselStatus) => {
    const cachedCounselData = counselsCache(status);
    if (!cachedCounselData) {
      return;
    }
    const cachedCounsels = cache.readQuery({
      query: cachedCounselData.query,
    });

    if (!cachedCounsels) {
      return;
    }

    const targetCounsels = findCounselById(counselId);

    if (!targetCounsels) {
      return;
    }

    const targetCounsel = targetCounsels.counsels.find(
      counsel => counsel.id === counselId,
    );

    if (!targetCounsel) {
      return;
    }

    exceptCounselByStatus(cachedCounselData.data, targetCounsel);
  };

  // Connection을 사용하고 있는 Pending / Finished로는 메세지가 안가기때문에 그대로 사용
  const appendMessageToCounsel = (
    message: CounselTableRowMessageFragment,
    counselId: string,
    counselStatus: CounselStatus,
  ) => {
    const cachedCounselsData = counselsCache(counselStatus);
    if (!cachedCounselsData) {
      return;
    }
  };

  return {
    findCounselById,
    updateCounsel,
    updateCounselStatus,
    appendCounsel,
    removeCounsel,
    appendMessageToCounsel,
  };
};

export default useCounselCache;
