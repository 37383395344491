const colors = {
  // Baseline colors
  PRIMARY: '#1890FF',
  PRIMARY_LIGHT: '#E6F7FF',

  // UI colors
  BACKGROUND: '#FFFFFF',
  BACKGROUND_DARK: '#F0F2F5',
  BACKGROUND_LIGHT: '#FAFAFA',
  MUTED: 'rgba(0, 0, 0, 0.45)',

  // Action colors
  DANGER: '#FF4D4F',

  // Text colors
  TEXT_DEFAULT: 'rgba(0, 0, 0, 0.85)',
  TEXT_MUTED: 'rgba(0, 0, 0, 0.45)',

  // On colors
  ON_PRIMARY: '#FFFFFF',
  ON_PRIMARY_LIGHT: '#1890FF',
  ON_DANGER: '#FFFFFF',

  // Border colors
  BORDER_PRIMARY_LIGHT: '#BAE7FF',
  BORDER_BACKGROUND: '#F0F0F0',

  // Hover colors
  HOVER_BACKGROUND: '#F5F5F5',
};

export default colors;
