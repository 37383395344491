import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import React from 'react';

import HomePresenter from './HomePresenter';

import { Loading } from '@components';
import initClient from '@utils/apollo';
import { generateCache } from '@utils/cache';
import { ApolloClientProvider } from '@utils/providers';

const HomeContainer: React.FC = () => {
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject> | null>(null);

  React.useEffect(() => {
    const initialize = async () => {
      const cache = generateCache();

      const client = initClient(cache);
      setClient(client);
    };

    initialize().catch(console.error);
  }, []);

  if (!client) {
    return <Loading />;
  }

  return (
    <ApolloClientProvider client={client}>
      <HomePresenter />
    </ApolloClientProvider>
  );
};

export default HomeContainer;
