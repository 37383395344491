import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { theme } from '@styles';
import { CounselStatus, useOngoingCounselsQuery } from '@utils/client';
import { COUNSEL_STATE } from '@utils/constants';

const OngoingTableTab = () => {
  const { data, error } = useOngoingCounselsQuery({
    fetchPolicy: 'cache-only',
  });

  const { push } = useHistory();

  const totalUnreadCount = React.useMemo<number>(() => {
    if (!data) {
      return 0;
    }

    return data.ongoingCounsels

      .map(counsel => {
        return counsel.unreadCount;
      })
      .reduce((acc, cur) => acc + cur, 0);
  }, [data]);

  return (
    <STab
      onClick={() => push('/?tab=ongoing')}
      className="chat-list-tab-header__title"
    >
      {COUNSEL_STATE[CounselStatus.Ongoing].title}
      {totalUnreadCount > 0 ? (
        <STabBadge count={totalUnreadCount} overflowCount={999} />
      ) : (
        <STabBadge
          className="badge-count-muted"
          count={data?.ongoingCounsels.length}
          overflowCount={999}
        />
      )}
      {error && (
        <ExclamationCircleOutlined
          style={{ margin: 0, color: theme.colors.DANGER }}
        />
      )}
    </STab>
  );
};

export default OngoingTableTab;

const STab = styled.div`
  display: flex;
  align-items: center;
`;
const STabBadge = styled(Badge)`
  margin-left: 5px;
`;
