import { gql } from '@apollo/client';
import React from 'react';

import TemplateDetailPresenter from './TemplateDetailPresenter';

import { useDeleteTemplateMutation } from '@utils/client';

gql`
  mutation deleteTemplate($templateId: ID!) {
    deleteTemplate(templateId: $templateId) {
      template {
        id
      }
    }
  }
`;

export interface TemplateDetailContainerProps {
  className?: string;
  counselId: string;
  templateId: string;
  onClose: () => void;
  onEdit?: () => void;
  onChangeDirectory: (directoryId: string) => void;
}
const TemplateDetailContainer: React.FC<
  TemplateDetailContainerProps
> = props => {
  const [deleteTemplateMutation] = useDeleteTemplateMutation({
    fetchPolicy: 'no-cache',
  });

  const deleteTemplate = async () => {
    await deleteTemplateMutation({
      variables: { templateId: props.templateId },
    });
  };

  return <TemplateDetailPresenter {...props} deleteTemplate={deleteTemplate} />;
};

export default TemplateDetailContainer;
