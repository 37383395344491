let env: Env | undefined = undefined;
class Env {
  public API_URL = '';
  public WS_URL = '';
  public COMMUNITY_CONSOLE_URL = '';
  public WEB_URL = '';
  public MAP_CONSOLE_URL = '';
  private constructor() {
    const env = process.env;
    console.log(env.REACT_APP_WEB_URL);
    if (
      !env.REACT_APP_API_HTTP_ENDPOINT ||
      !env.REACT_APP_API_WS_ENDPOINT ||
      !env.REACT_APP_COMMUNITY_CONSOLE_URL ||
      !env.REACT_APP_WEB_URL ||
      !env.REACT_APP_MAP_CONSOLE_URL
    ) {
      throw new Error('Enviromnents is not setted!');
    }
    this.API_URL = env.REACT_APP_API_HTTP_ENDPOINT;
    this.WS_URL = env.REACT_APP_API_WS_ENDPOINT;
    this.COMMUNITY_CONSOLE_URL = env.REACT_APP_COMMUNITY_CONSOLE_URL;
    this.WEB_URL = env.REACT_APP_WEB_URL;
    this.MAP_CONSOLE_URL = env.REACT_APP_MAP_CONSOLE_URL;
  }

  public static getInstance() {
    if (env) {
      return env;
    }
    env = new Env();
    return env;
  }
}

export default Env.getInstance();
