import { gql } from '@apollo/client';
import React from 'react';

import CounselInfoPresenter from './CounselInfoPresenter';

import { useMe } from '@hooks';
import {
  useAddUserToCounselMutation,
  useRemoveUserFromCounselMutation,
} from '@utils/client';

gql`
  mutation addUserToCounsel($counselId: ID!, $userId: ID!) {
    addUserToCounsel(counselId: $counselId, userId: $userId) {
      ... on AddUserToCounselSuccess {
        user {
          ...counselWindowUser
        }
      }
    }
  }
`;

gql`
  mutation removeUserFromCounsel($counselId: ID!, $userId: ID!) {
    removeUserFromCounsel(counselId: $counselId, userId: $userId) {
      ... on RemoveUserFromCounselSuccess {
        user {
          ...counselWindowUser
        }
      }
    }
  }
`;

export interface CounselInfoContainerProps {
  className?: string;
  counselId: string;
  showTaskPad: boolean;
  toggleTaskPad: () => void;
}
const CounselInfoContainer: React.FC<CounselInfoContainerProps> = props => {
  const { id: userId } = useMe();

  const [addUserToCounselMutation] = useAddUserToCounselMutation();
  const [removeUserFromCounselMutation] = useRemoveUserFromCounselMutation();

  const invite = async (userId: string) => {
    await addUserToCounselMutation({
      variables: { counselId: props.counselId, userId },
      onError: err => console.error(err),
    });
  };

  const leave = async () => {
    if (userId) {
      await removeUserFromCounselMutation({
        variables: { counselId: props.counselId, userId },
        onError: err => console.error(err),
      });
    }
  };

  return <CounselInfoPresenter {...props} invite={invite} leave={leave} />;
};

export default CounselInfoContainer;
