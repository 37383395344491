import React from 'react';
import styled from 'styled-components';

interface InlineBubbleProps {
  children: string | React.ReactNode;
  icon: React.ReactElement;
}
const InlineBubble: React.FC<InlineBubbleProps> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <SOtherInlineBubble {...props}>
      {React.cloneElement(icon, {
        ...icon.props,
        style: { ...icon.props.style, marginRight: 8 },
      })}
      {children}
    </SOtherInlineBubble>
  );
};

export default InlineBubble;

const SOtherInlineBubble = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 14px;
  padding: 14px;
  font-size: 14px;
  white-space: pre-wrap;
`;
