import React from 'react';

import {
  AreaInputMessage,
  SuspendedMessage,
  EnterMessage,
  FileMessage,
  LeaveMessage,
  QueueMessage,
  CompanyMessage,
  TextMessage,
  EstimateMessage,
  ErrorMessage,
  InvoiceMessage,
  EndMessage,
  RestartMessage,
  VinInputMessage,
  AppReviewMessage,
  DeletedMessage,
} from './messageImplements';
import MessageRow from './messageParts/MessageRow';
import { MyMessageDropdown } from './messageParts/MyMessageDropdown';

import {
  Message as _Message,
  TextMessage as _TextMessage,
  FileMessage as _FileMessage,
  CompanyMessage as _CompanyMessage,
  EnterMessage as _EnterMessage,
  LeaveMessage as _LeaveMessage,
  AreaInputMessage as _AreaInputMessage,
  SuspendedMessage as _SuspendedMessage,
  EstimateMessage as _EstimateMessage,
  InvoiceMessage as _InvoiceMessage,
  PlateNumberInputMessage as _PlateNumberInputMessage,
  RestartMessage as _RestartMessage,
  QueueMessage as _QueueMessage,
  EndMessage as _EndMessage,
  VinInputMessage as _VinInputMessage,
  AppReviewMessage as _AppReviewMessage,
  DeletedMessage as _DeletedMessage,
  CounselWindowUserFragment,
  Image,
  Video,
  RoleEnum,
} from '@utils/client';

export type MessageInterface =
  | _TextMessage
  | _FileMessage
  | _CompanyMessage
  | _EnterMessage
  | _LeaveMessage
  | _AreaInputMessage
  | _SuspendedMessage
  | _EstimateMessage
  | _InvoiceMessage
  | _PlateNumberInputMessage
  | _RestartMessage
  | _QueueMessage
  | _EndMessage
  | _VinInputMessage
  | _AppReviewMessage
  | _DeletedMessage;

export type MessageProps = {
  mine: boolean;
  message: MessageInterface;
  showDate: boolean;
  showAuthor: boolean;
  showTimestamp: boolean;
  users: CounselWindowUserFragment[];
  counselId: string;
};

export const Message: React.FC<MessageProps> = props => {
  const messageType = props.message.__typename;
  const messageComponent = React.useMemo(() => {
    switch (messageType) {
      case 'TextMessage': {
        const textMessage = props.message as _TextMessage;
        const counseleeId = props.message.counsel.counselee?.id;
        let counselee = false;
        if (counseleeId && textMessage.author) {
          counselee = textMessage.author.id === counseleeId;
        }
        return (
          <TextMessage
            {...props}
            id={textMessage.id}
            text={textMessage.body}
            counselee={counselee}
          />
        );
      }
      case 'EnterMessage': {
        const enterMessage = props.message as _Message & _EnterMessage;
        return (
          <EnterMessage
            inviter={enterMessage.inviter?.nickname}
            invitee={enterMessage.invitee?.nickname}
          />
        );
      }
      case 'LeaveMessage': {
        const leaveMessage = props.message as _Message & _LeaveMessage;
        return (
          <LeaveMessage
            nickname={
              leaveMessage.user ? leaveMessage.user.nickname : '(알수없음)'
            }
          />
        );
      }
      case 'FileMessage': {
        const fileMessage = props.message as _Message & _FileMessage;
        const file = fileMessage.file as Image | Video;
        return <FileMessage {...props} type={file.__typename} />;
      }
      case 'CompanyMessage': {
        const companyMessage = props.message as _Message & _CompanyMessage;
        return <CompanyMessage companyId={companyMessage.company?.id} />;
      }
      case 'AreaInputMessage': {
        return <AreaInputMessage {...props} />;
      }
      case 'QueueMessage': {
        const queueMessage = props.message as _Message & _QueueMessage;

        return (
          <QueueMessage
            nickname={
              queueMessage.counsel.counselee
                ? queueMessage.counsel.counselee.nickname
                : '(알수없음)'
            }
            reason={queueMessage.reason}
            {...props}
          />
        );
      }
      case 'SuspendedMessage': {
        const suspendedMessage = props.message as _Message & _SuspendedMessage;
        return (
          <SuspendedMessage
            nickname={
              suspendedMessage.counsel.counselee
                ? suspendedMessage.counsel.counselee.nickname
                : '(알수없음)'
            }
          />
        );
      }
      case 'EstimateMessage': {
        const estimateMessage = props.message as _Message & _EstimateMessage;
        const estimate = estimateMessage.estimate;
        const vehicle = estimateMessage.counsel.vehicle;

        if (!vehicle) {
          return (
            <ErrorMessage>
              차량등록이 되지 않은 견적서입니다. 개발팀에 문의해주세요.
            </ErrorMessage>
          );
        }

        const items = estimate.items.map(item => ({
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          discountRate: item.discountRate,
          totalAmount: item.totalAmount,
          paymentAmount: item.paymentAmount,
        }));

        const estimateInfo = {
          name: estimate.name,
          modelInfo: `${vehicle.shortModelName} / ${
            vehicle.registeredYear ? vehicle.registeredYear : '정보없음'
          }`,
          plateNumber: vehicle.plateNumber ? vehicle.plateNumber : '(알수없음)',
          items,
          shippingCharge: estimate.shippingCharge,
          disable: !estimate.latest,
          order: estimate.order,
        };

        return <EstimateMessage estimate={estimateInfo} {...props} />;
      }
      case 'InvoiceMessage': {
        const message = props.message as _Message & _InvoiceMessage;
        const payment = message.order?.payment;
        if (!payment) {
          return (
            <ErrorMessage>
              결제 데이터에 오류가 있습니다. 개발팀에 문의해주세요.
            </ErrorMessage>
          );
        }
        return (
          <InvoiceMessage
            {...props}
            paymentStatus={payment.status}
            nickname={
              message.counsel.counselee
                ? message.counsel.counselee.nickname
                : '(알수없음)'
            }
          />
        );
      }
      case 'EndMessage': {
        const endMessage = props.message as _Message & _EndMessage;
        let nickname = '(알수없음)';
        let role = RoleEnum.User;
        if (endMessage.endedBy) {
          nickname = endMessage.endedBy.nickname;
          role = endMessage.endedBy.role;
        }
        return <EndMessage nickname={nickname} role={role} />;
      }
      case 'RestartMessage':
        return <RestartMessage />;
      case 'VinInputMessage':
        return <VinInputMessage />;
      case 'AppReviewMessage':
        return <AppReviewMessage />;
      case 'DeletedMessage':
        return <DeletedMessage />;
      default:
        return <>미구현 메시지</>;
    }
  }, [messageType, props]);

  const noWrap =
    messageType === 'EnterMessage' || messageType === 'LeaveMessage';

  const message = props.message as MessageInterface;

  return (
    <MyMessageDropdown
      messageId={message?.id}
      counselId={message.counsel?.id}
      mine={props.mine}
    >
      <MessageRow disable={noWrap} {...props}>
        {messageComponent}
      </MessageRow>
    </MyMessageDropdown>
  );
};
