import { throttle } from 'lodash';
import React from 'react';

/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export const useVisibilityOnScroll = (
  offset = 0,
  throttleMilliseconds = 100,
): [boolean, React.MutableRefObject<HTMLDivElement | null>] => {
  const [isVisible, setIsVisible] = React.useState(false);
  const currentElement = React.useRef<HTMLDivElement | null>(null);

  const handleScroll = React.useCallback(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
  }, [currentElement, offset]);

  const onScroll = throttle(handleScroll, throttleMilliseconds);

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });
  return [isVisible, currentElement];
};
