import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
  gql,
} from '@apollo/client';
import React from 'react';

import initClient from '@utils/apollo';
import { generateCache } from '@utils/cache';
import { useRefreshAccessTokenMutation } from '@utils/client';

type Props = {
  children: React.ReactNode;
  withToken?: boolean;
  client?: ApolloClient<NormalizedCacheObject>;
};

gql`
  mutation refreshAccessToken {
    refreshAccessToken {
      accessToken
      expiresAt
    }
  }
`;

export const ApolloClientProvider = ({
  withToken = true,
  children,
  client,
}: Props) => {
  let apolloClient = client;
  if (!apolloClient) {
    apolloClient = initClient(generateCache(), withToken);
  }
  return (
    <ApolloProvider client={apolloClient}>
      <TokenRefreshProvider />
      {children}
    </ApolloProvider>
  );
};

const TokenRefreshProvider = () => {
  const [refreshAccessTokenMutation] = useRefreshAccessTokenMutation();
  const [tokenExpiresAt, setTokenExpiresAt] = React.useState(0);

  const updateAccessToken = React.useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        return;
      }
      const { data } = await refreshAccessTokenMutation();
      if (!data) {
        return;
      }
      localStorage.setItem('token', data.refreshAccessToken.accessToken);
      setTokenExpiresAt(data.refreshAccessToken.expiresAt);
    } catch (err) {
      console.error(err);
    }
  }, [refreshAccessTokenMutation]);

  React.useEffect(() => {
    // 토큰 체커 등록
    const interval = setInterval(async () => {
      if (Date.now() < tokenExpiresAt) {
        await updateAccessToken();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [updateAccessToken, tokenExpiresAt]);

  React.useEffect(() => {
    // 최초 1회 토큰 리프레시
    updateAccessToken();

    const tab = window.top;

    if (!tab) {
      return;
    }
    // 관리자콘솔이 포커스 될때 토큰 리프레시 ()
    tab.onfocus = async () => {
      await updateAccessToken();
    };
  }, [updateAccessToken]);

  return <></>;
};
