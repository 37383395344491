import { FrownOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

interface ErrorProps {
  className?: string;
  message?: string;
  error: ApolloError;
}
const ErrorTemplate: React.FC<ErrorProps> = ({ className, message, error }) => {
  return (
    <SError className={className}>
      <SFrown />
      <p>
        {message}
        {error.graphQLErrors.map((graphQLError, index) => (
          <React.Fragment key={`GraphQLError-${index}`}>
            <br />[{graphQLError.extensions?.code}]: {error.message}
          </React.Fragment>
        ))}
        {(error.networkError as any)?.result?.errors.map(
          (networkError: any, index: number) => (
            <React.Fragment key={`NetworkError-${index}`}>
              <br />
              [NETWORK ERROR]: {networkError.message}
            </React.Fragment>
          ),
        )}
      </p>
    </SError>
  );
};

export default ErrorTemplate;

const SError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  text-align: center;

  color: ${props => props.theme.colors.TEXT_MUTED};
`;
const SFrown = styled(FrownOutlined)`
  font-size: 36px;
  margin-bottom: 10px;
`;
