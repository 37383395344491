import { gql } from '@apollo/client';
import { notification } from 'antd';
import { uniqueId } from 'lodash';
import React from 'react';

import { useMe } from '@hooks';
import {
  CounselRoomFragment,
  CounselRoomFragmentDoc,
  FileInput,
  FileType,
  useSendAreaInputMessageMutation,
  useSendFileMessageMutation,
  useCounselRoomQuery,
  useSendPlateNumberInputMessageMutation,
  useSendCompanyMessageMutation,
  useSendTextMessageMutation,
  useSendVinInputMessageMutation,
  useUpdateCursorMutation,
  MessageStatus,
  CounselRoomMessageFragment,
  useDeleteMessageMutation,
  RoleEnum,
} from '@utils/client';

gql`
  mutation sendTextMessage($counselId: ID!, $body: String!) {
    sendTextMessage(counselId: $counselId, body: $body) {
      ... on SendTextMessageSuccess {
        message {
          ...counselRoomMessage
        }
      }
    }
  }
`;

gql`
  mutation sendFileMessage($counselId: ID!, $file: FileInput!) {
    sendFileMessage(counselId: $counselId, file: $file) {
      ... on SendFileMessageSuccess {
        message {
          ...counselRoomMessage
        }
      }
    }
  }
`;

gql`
  mutation sendCompanyMessage($counselId: ID!, $companyId: ID!) {
    sendCompanyMessage(counselId: $counselId, companyId: $companyId) {
      ... on SendCompanyMessageSuccess {
        message {
          ...counselRoomMessage
        }
      }
    }
  }
`;

gql`
  mutation sendAreaInputMessage($counselId: ID!) {
    sendAreaInputMessage(counselId: $counselId) {
      ... on SendAreaInputMessageSuccess {
        message {
          author {
            id
          }
          counsel {
            id
          }
        }
      }
    }
  }
`;

gql`
  mutation sendVinInputMessage($counselId: ID!, $vehicleId: ID!) {
    sendVinInputMessage(counselId: $counselId, vehicleId: $vehicleId) {
      ... on SendVinInputMessageSuccess {
        message {
          id
          author {
            id
          }
          vehicle {
            id
          }
        }
      }
    }
  }
`;

gql`
  mutation sendPlateNumberInputMessage($counselId: ID!, $vehicleId: ID!) {
    sendPlateNumberInputMessage(counselId: $counselId, vehicleId: $vehicleId) {
      ... on SendPlateNumberInputMessageSuccess {
        message {
          vehicle {
            id
          }
        }
      }
    }
  }
`;

gql`
  mutation updateCursor($counselId: ID!, $messageId: ID!) {
    updateCursor(counselId: $counselId, messageId: $messageId) {
      ... on UpdateCursorSuccess {
        cursor {
          id
          counsel {
            id
          }
          message {
            id
          }
          user {
            id
          }
        }
      }
    }
  }
`;

gql`
  mutation deleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId) {
      message {
        id
        deletedAt
        status
      }
    }
  }
`;

interface UseMessageArgs {
  counselId: string;
}
function useMessage({ counselId }: UseMessageArgs) {
  const {
    data,
    client: { cache },
    refetch,
  } = useCounselRoomQuery({
    variables: {
      counselId,
    },
    fetchPolicy: 'cache-only',
  });

  const { id, avatar, nickname } = useMe();
  const [updateCursorMutation] = useUpdateCursorMutation({
    onError: err => {
      console.error(err);
    },
  });
  const [sendTextMessageMutation] = useSendTextMessageMutation();
  const [sendFileMessageMutation] = useSendFileMessageMutation();
  const [sendCompanyMessageMutation] = useSendCompanyMessageMutation();
  const [deleteMessageMutation] = useDeleteMessageMutation();
  const [sendAreaInputMessageMutation] = useSendAreaInputMessageMutation();
  const [sendPlateNumberInputMessageMutation] =
    useSendPlateNumberInputMessageMutation();
  const [sendVinInputMessageMutation] = useSendVinInputMessageMutation();

  const updateCursor = React.useCallback(
    async (messageId: string) => {
      if (!data) {
        return;
      }

      const counselCache = data.counsel;

      const targetMessageEdge = counselCache.messageConnection?.edges.find(
        edge => edge.node.id === messageId,
      );

      if (!targetMessageEdge) {
        return;
      }

      if (targetMessageEdge.node.status !== MessageStatus.Sent) {
        return;
      }

      await updateCursorMutation({
        variables: { counselId, messageId },
      });
    },
    [data, counselId, updateCursorMutation],
  );

  // cache 관련 utils
  const appendTempMessage = React.useCallback(
    (tempMessage: CounselRoomMessageFragment, counsel: CounselRoomFragment) => {
      const existingEdges = counsel.messageConnection.edges;
      cache.writeFragment<CounselRoomFragment>({
        id: `Counsel:${counsel.id}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
        data: {
          ...counsel,
          messageConnection: {
            edges: [
              ...existingEdges,
              {
                node: tempMessage,
                cursor: 'temp',
              },
            ],
            pageInfo: {
              ...counsel.messageConnection.pageInfo,
            },
          },
        },
      });
    },
    [cache],
  );

  const handleSuccessSendMessage = React.useCallback(
    async (newMessage: CounselRoomMessageFragment, tempId: string) => {
      if (!data) {
        return;
      }

      const counsel = data.counsel;
      const existingEdges = counsel.messageConnection.edges;
      const updatedEdges = [
        ...existingEdges.filter(edge => edge.node.id !== tempId),
        {
          node: {
            ...newMessage,
            status: MessageStatus.Sent,
          },
          cursor: 'new',
        },
      ];

      cache.writeFragment<CounselRoomFragment>({
        id: `Counsel:${counsel.id}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
        data: {
          ...counsel,
          messageConnection: {
            edges: updatedEdges,
            pageInfo: {
              ...counsel.messageConnection.pageInfo,
            },
          },
        },
      });

      await refetch({ counselId });
    },
    [cache, counselId, data, refetch],
  );

  const handleFailSendMessage = React.useCallback(
    (tempId: string) => {
      if (!data) {
        return;
      }

      const counselCache = data.counsel;

      const existingEdges = counselCache.messageConnection.edges;
      const updatedEdges = existingEdges.map(edge => {
        if (edge.node.id !== tempId) {
          return edge;
        }

        return {
          ...edge,
          node: {
            ...edge.node,
            status: MessageStatus.Failed,
          },
        };
      });

      cache.writeFragment<CounselRoomFragment>({
        id: `Counsel:${counselCache.id}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
        data: {
          ...counselCache,
          messageConnection: {
            edges: updatedEdges,
            pageInfo: {
              ...counselCache.messageConnection.pageInfo,
            },
          },
        },
      });
    },
    [data, cache],
  );

  const handleFailedToPendingMessage = React.useCallback(
    (failedmessageId: string): CounselRoomMessageFragment | undefined => {
      if (!data) {
        return;
      }

      const counselCache = data.counsel;

      let targetMessage: CounselRoomMessageFragment | undefined = undefined;

      const updatedEdges = counselCache.messageConnection.edges.map(edge => {
        if (edge.node.id !== failedmessageId) {
          return edge;
        }

        targetMessage = {
          ...edge.node,
          status: MessageStatus.Pending,
        };

        return {
          ...edge,
          node: targetMessage,
        };
      });

      cache.writeFragment<CounselRoomFragment>({
        id: `Counsel:${counselCache.id}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
        data: {
          ...counselCache,
          messageConnection: {
            edges: updatedEdges,
            pageInfo: {
              ...counselCache.messageConnection.pageInfo,
            },
          },
        },
      });

      return targetMessage;
    },
    [cache, data],
  );

  const removeFailedMessageOnCache = React.useCallback(
    (id: string) => {
      if (!data) {
        return;
      }

      const counselCache = data.counsel;

      const existingEdges = counselCache.messageConnection.edges;
      const updatedEdges = existingEdges.filter(edge => edge.node.id !== id);
      cache.writeFragment<CounselRoomFragment>({
        id: `Counsel:${counselCache.id}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
        data: {
          ...counselCache,
          messageConnection: {
            edges: updatedEdges,
            pageInfo: {
              ...counselCache.messageConnection.pageInfo,
            },
          },
        },
      });
    },
    [cache, data],
  );

  // mutation 포함 메시지 전송 함수
  // sendTextMessage
  const resendTextMessage = React.useCallback(
    async (failedmessageId: string) => {
      const targetMessage = handleFailedToPendingMessage(failedmessageId);

      if (!targetMessage || targetMessage.__typename !== 'TextMessage') {
        return;
      }

      if (!data) {
        return;
      }

      try {
        const sendTextMessageResult = await sendTextMessageMutation({
          variables: { counselId, body: targetMessage.body },
          update: (_, { data }) => {
            if (!data) {
              handleFailSendMessage(failedmessageId);
              return;
            }
            handleSuccessSendMessage(
              data.sendTextMessage.message,
              failedmessageId,
            );
          },
          onError: err => {
            handleFailSendMessage(failedmessageId);
            notification.error({
              message: '메시지 재전송 실패',
              description: err.message,
            });
            console.log(err);
          },
        });

        return sendTextMessageResult.data?.sendTextMessage;
      } catch (err) {
        handleFailSendMessage(failedmessageId);
        return undefined;
      }
    },
    [
      counselId,
      data,
      handleFailSendMessage,
      handleFailedToPendingMessage,
      handleSuccessSendMessage,
      sendTextMessageMutation,
    ],
  );

  const sendTextMessage = React.useCallback(
    async (body: string) => {
      if (!id || !nickname) {
        return;
      }

      if (!data) {
        return;
      }

      const counselCache = data.counsel;

      const tempId = uniqueId();
      const tempMessage: CounselRoomMessageFragment = {
        id: tempId,
        body,
        status: MessageStatus.Pending,
        author: {
          id: id,
          avatar: avatar,
          nickname: nickname,
          __typename: 'User',
          role: RoleEnum.Counselor,
        },
        counsel: counselCache,
        createdAt: new Date().getTime(),
        __typename: 'TextMessage',
      };

      appendTempMessage(tempMessage, counselCache);

      try {
        const sendTextMessageResult = await sendTextMessageMutation({
          variables: { counselId, body },
          update: (_, { data }) => {
            if (!data) {
              handleFailSendMessage(tempId);
              return;
            }
            handleSuccessSendMessage(data.sendTextMessage.message, tempId);
          },
          onError: err => {
            handleFailSendMessage(tempId);
            notification.error({
              message: '메시지 전송 실패',
              description: err.message,
            });
            console.log(err);
          },
        });

        return sendTextMessageResult.data?.sendTextMessage;
      } catch (err) {
        handleFailSendMessage(tempId);
        return undefined;
      }
    },
    [
      appendTempMessage,
      avatar,
      counselId,
      data,
      handleFailSendMessage,
      handleSuccessSendMessage,
      id,
      nickname,
      sendTextMessageMutation,
    ],
  );

  // sendFileMessage
  const sendFileMessage = React.useCallback(
    async (file: FileInput) => {
      const { data } = await sendFileMessageMutation({
        variables: { counselId, file },
        onError: err => {
          notification.error({
            message: '파일 메시지 전송 실패',
            description: err.message,
          });
          console.error(err);
        },
      });
      return data?.sendFileMessage;
    },
    [counselId, sendFileMessageMutation],
  );

  const sendCompanyMessage = React.useCallback(
    async (companyId: string) => {
      const { data } = await sendCompanyMessageMutation({
        variables: {
          counselId,
          companyId: companyId,
        },
        onCompleted: () => {
          notification.error({
            message: '업체 메시지가 전송되었습니다.',
          });
        },
        onError: err => {
          notification.error({
            message: '업체 메시지 전송 실패',
            description: err.message,
          });
          console.error(err);
        },
        refetchQueries: ['searchedShops', 'recentShops'],
      });
      return data?.sendCompanyMessage;
    },
    [counselId, sendCompanyMessageMutation],
  );

  // sendAreaInputMessage
  const sendAreaInputMessage = React.useCallback(async () => {
    const { data } = await sendAreaInputMessageMutation({
      variables: { counselId },
      onError: err => {
        notification.error({
          message: '지역입력 메시지 전송 실패',
          description: err.message,
        });
        console.error(err);
      },
    });
    return data?.sendAreaInputMessage;
  }, [counselId, sendAreaInputMessageMutation]);

  // sendPlayNumberInputMEssage
  const sendPlateNumberInputMessage = React.useCallback(
    async (vehicleId: string) => {
      const { data } = await sendPlateNumberInputMessageMutation({
        variables: { counselId, vehicleId },
        onError: err => {
          notification.error({
            message: '차량번호 입력 메시지 전송 실패',
            description: err.message,
          });
          console.error(err);
        },
      });
      return data?.sendPlateNumberInputMessage;
    },
    [counselId, sendPlateNumberInputMessageMutation],
  );

  // sendVinInputMessage
  const sendVinInputMessage = React.useCallback(
    async (counselId: string, vehicleId: string) => {
      const { data } = await sendVinInputMessageMutation({
        variables: {
          counselId,
          vehicleId,
        },
        onError: err => {
          notification.error({
            message: 'VIN 입력 메시지 전송 실패',
            description: err.message,
          });
          console.error(err);
        },
      });
      return data?.sendVinInputMessage;
    },
    [sendVinInputMessageMutation],
  );

  const sendMessages = React.useCallback(
    async (
      contents: MessageContentInput[],
      options?: { bot: boolean; interval: number },
    ) => {
      const messageIds: string[] = [];
      let result;
      try {
        for (const content of contents) {
          switch (content.type) {
            case 'text':
              result = await sendTextMessage(content.body);
              break;
            case 'image':
              result = await sendFileMessage({
                type: FileType.Image,
                url: content.url,
              });
              break;
            case 'video':
              result = await sendFileMessage({
                type: FileType.Video,
                url: content.url,
              });
              break;
            case 'shop':
              if (!options) {
                notification.error({
                  message: '정비소 연락처 포함여부를 입력해주세요.',
                });
                return;
              }
              result = await sendCompanyMessage(content.shopId);
              break;
            default:
              result = undefined;
              notification.warning({
                message: '지원하지 않는 메세지 타입 전송이 요청됨',
              });
              break;
          }
          if (result?.message.id) {
            messageIds.push(result.message.id);
          }
          await new Promise(resolve => setTimeout(resolve, options?.interval));
        }
        return messageIds;
      } catch (err: any) {
        notification.error({
          message: '템플릿 전송 실패',
          description: err.message,
        });
      }
    },
    [sendTextMessage, sendFileMessage, sendCompanyMessage],
  );

  const deleteMessage = React.useCallback(
    async (id: string) => {
      const { data } = await deleteMessageMutation({
        variables: {
          messageId: id,
        },
      });

      if (!data) {
        return;
      }

      const deletedMessage = data.deleteMessage.message;

      const counselCache = cache.readFragment<CounselRoomFragment>({
        id: `Counsel:${counselId}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
      });

      if (!counselCache) {
        return;
      }

      const updatedEdges = counselCache.messageConnection.edges.map(edge => {
        if (edge.node.id !== deletedMessage.id) {
          return edge;
        }

        return {
          ...edge,
          node: {
            ...edge.node,
            ...deletedMessage,
          },
        };
      });

      cache.writeFragment<CounselRoomFragment>({
        id: `Counsel:${counselCache.id}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
        data: {
          ...counselCache,
          messageConnection: {
            edges: updatedEdges,
            pageInfo: {
              ...counselCache.messageConnection.pageInfo,
            },
          },
        },
      });
    },
    [cache, counselId, deleteMessageMutation],
  );

  return {
    sendTextMessage,
    sendFileMessage,
    sendCompanyMessage,
    sendAreaInputMessage,
    sendPlateNumberInputMessage,
    sendMessages,
    deleteMessage,
    updateCursor,
    sendVinInputMessage,
    removeFailedMessageOnCache,
    resendTextMessage,
  };
}

export default useMessage;

export type SendTextMessage = ReturnType<typeof useMessage>['sendTextMessage'];
export type SendFileMessage = ReturnType<typeof useMessage>['sendFileMessage'];
export type SendCompanyMessage = ReturnType<
  typeof useMessage
>['sendCompanyMessage'];
export type SendMessages = ReturnType<typeof useMessage>['sendMessages'];
export type UpdateCursor = ReturnType<typeof useMessage>['updateCursor'];
export type DeleteMessage = ReturnType<typeof useMessage>['deleteMessage'];

export type TextContentInput = { type: 'text'; body: string };
export type ImageContentInput = {
  type: 'image';
  url: string;
};
export type VideoContentInput = {
  type: 'video';
  url: string;
};
export type ShopContentInput = {
  type: 'shop';
  shopId: string;
};
export type MessageContentInput =
  | TextContentInput
  | ImageContentInput
  | VideoContentInput
  | ShopContentInput;
