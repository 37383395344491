import { gql } from '@apollo/client';
import { Spin, Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { useHashtagsQuery } from '@utils/client';
import env from '@utils/env';

gql`
  query Hashtags($postId: ID!) {
    post(postId: $postId) {
      id
      hashtags {
        id
        name
      }
    }
  }
`;

type HashtagsProps = {
  style?: React.CSSProperties;
  postId: string;
};

const Hashtags = ({ postId, style }: HashtagsProps) => {
  const { data, loading } = useHashtagsQuery({
    variables: {
      postId,
    },
  });

  return (
    <Spin spinning={loading}>
      <HashtagsContainer style={style}>
        {data?.post?.hashtags.map(hashtag => (
          <Tag
            key={hashtag.id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open(
                `${env.WEB_URL}/?term=%23${hashtag.name}`,
                hashtag.name,
                'width=1676, height=1207',
              );
            }}
          >
            {hashtag.name}
          </Tag>
        ))}
      </HashtagsContainer>
    </Spin>
  );
};

const HashtagsContainer = styled.div`
  displasy: flex;
  flex-wrap: wrap;
`;

export default React.memo(Hashtags);
