import React from 'react';
import styled from 'styled-components';

import CreateTemplate from './CreateTemplate';
import EditTemplate from './EditTemplate';
import TemplateDetail from './TemplateDetail';
import TemplateDirectory from './TemplateDirectory';
import TemplateShortcuts from './TemplateShortcuts';

interface TemplateProps {
  className?: string;
  counselId: string;
}
interface TemplateState {
  mode: 'CREATE' | 'EDIT' | null;
  directoryId: string;
  templateId: string | null;
}
const Template: React.FC<TemplateProps> = ({ className, counselId }) => {
  const [{ mode, directoryId, templateId }, setState] =
    React.useState<TemplateState>({
      mode: null,
      directoryId: 'ROOT',
      templateId: null,
    });

  const onChangeDirectory = React.useCallback((directoryId: string) => {
    setState({ mode: null, directoryId, templateId: null });
  }, []);

  return (
    <STemplate className={className}>
      {mode ? (
        <>
          {mode === 'CREATE' && directoryId && (
            <SCreateTemplate
              directoryId={directoryId}
              onChangeDirectory={onChangeDirectory}
              onClose={templateId =>
                setState(state => ({
                  ...state,
                  mode: null,
                  templateId: templateId || null,
                }))
              }
            />
          )}
          {mode === 'EDIT' && templateId && (
            <SEditTemplate
              templateId={templateId}
              onChangeDirectory={onChangeDirectory}
              onClose={() =>
                setState(state => ({ ...state, mode: null, templateId }))
              }
            />
          )}
        </>
      ) : (
        <>
          {templateId ? (
            <STemplateDetail
              counselId={counselId}
              templateId={templateId}
              onClose={() =>
                setState(state => ({ ...state, templateId: null }))
              }
              onEdit={() => setState(state => ({ ...state, mode: 'EDIT' }))}
              onChangeDirectory={onChangeDirectory}
            />
          ) : (
            <>
              <STemplateShortcuts
                counselId={counselId}
                onChangeDirectory={onChangeDirectory}
              />
              <STemplateDirectory
                directoryId={directoryId}
                onChangeDirectory={onChangeDirectory}
                onAddTemplate={() =>
                  setState(state => ({ ...state, mode: 'CREATE' }))
                }
                onOpenTemplate={templateId =>
                  setState(state => ({ ...state, templateId }))
                }
              />
            </>
          )}
        </>
      )}
    </STemplate>
  );
};

export default Template;

const STemplate = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const STemplateDetail = styled(TemplateDetail)`
  flex: 1;
`;
const SCreateTemplate = styled(CreateTemplate)`
  flex: 1;
`;
const SEditTemplate = styled(EditTemplate)`
  flex: 1;
`;
const STemplateDirectory = styled(TemplateDirectory)`
  flex: 1;
`;
const STemplateShortcuts = styled(TemplateShortcuts)``;
