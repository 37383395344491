import { volcano } from '@ant-design/colors';
import { ArrowDownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { MessageInterface } from './Message';

import { messageIconifier, messageStringifier } from '@utils/formatters';

export type NewMessageProps = {
  onClick: () => void;
  message: MessageInterface;
};
export const NewMessage: React.FC<NewMessageProps> = ({ onClick, message }) => {
  return (
    <SNewMessage onClick={onClick}>
      <Avatar
        size="small"
        src={message.author?.avatar?.url}
        icon={<UserOutlined />}
      />
      <SNewMessageText>
        {message.author?.nickname}: {messageIconifier(message)}{' '}
        {messageStringifier(message)}
      </SNewMessageText>
      <SArrow />
    </SNewMessage>
  );
};

const SNewMessage = styled.div`
  position: absolute;
  align-items: center;
  left: 0px;
  right: 0px;
  bottom: 98px;
  padding: 8px 12px;
  margin: 0px 8px;
  border-radius: 4px;
  display: flex;
  align-self: center;
  background-color: ${volcano[4]};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: ${volcano[3]};
  }
`;
const SNewMessageText = styled.span`
  flex: 1;
  margin: 0px 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const SArrow = styled(ArrowDownOutlined)`
  color: ${props => props.theme.colors.TEXT_MUTED};
  font-size: 16px;
  margin-right: 6px;
`;
