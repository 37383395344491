import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { CounselStatus } from '@utils/client';
import { COUNSEL_STATE } from '@utils/constants';

const FinishedTableTab = () => {
  const { push } = useHistory();
  return (
    <STab
      onClick={() => push('/?tab=finished')}
      className="chat-list-tab-header__title"
    >
      {COUNSEL_STATE[CounselStatus.Finished].title}
    </STab>
  );
};

export default FinishedTableTab;

const STab = styled.div`
  display: flex;
  align-items: center;
`;
