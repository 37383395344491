import { CommentOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  PageHeader,
  Result,
  Space,
  Image,
  Typography,
  Card,
  Button,
  Input,
  Table,
  notification,
  Tooltip,
  Descriptions,
  Tag,
  Divider,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import Hashtags from '@components/Hashtags';
import Loading from '@components/Loading';
import { useMe } from '@hooks';
import { colors } from '@styles';
import {
  useCounselDetailQuery,
  useUpdatedCounselDetailSubscription,
  Area,
} from '@utils/client';
import env from '@utils/env';
import { dateFormatter, insuranceFormatter } from '@utils/formatters';
const { Text } = Typography;

gql`
  subscription updatedCounselDetail($counselId: ID!) {
    updatedCounsel(counselId: $counselId) {
      ...counselDetailInfo
    }
  }
`;

export const COUNSEL_DETAIL = gql`
  query CounselDetail($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      createdAt
      insurance
      type {
        id
        name
      }
      memo
      body
      files {
        url
        description
      }
      areas {
        zone {
          province
          district
          neighborhood
        }
        coordinates {
          latitude
          longitude
        }
        radius
      }
      post {
        id
        title
        body
        hashtags {
          id
          name
        }
        files {
          url
          description
        }
        areas {
          zone {
            province
            district
            neighborhood
          }
          coordinates {
            latitude
            longitude
          }
          radius
        }
      }
      status
      counselee {
        id
        nickname
        inactiveCounselConnection(filter: {}, first: 5) {
          edges {
            node {
              id
              index
              memo
              createdAt
            }
          }
        }
      }
    }
  }
`;

type CounselDetailPresenterProps = {
  counselId: string;
  updateCounselMemo: (memo: string) => void;
  className?: string;
};

export const CounselDetailPresenter: React.FC<CounselDetailPresenterProps> = ({
  counselId,
  updateCounselMemo,
  className,
}) => {
  const { data, loading, error } = useCounselDetailQuery({
    variables: { counselId },
  });

  useUpdatedCounselDetailSubscription({
    variables: { counselId },
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  const [memo, setMemo] = React.useState('');
  const [editing, setEditing] = React.useState(false);
  const host = `${window.location.protocol}//${window.location.host}`;

  const { viewOnly } = useMe();

  const areasFormatHandler = (area: Area): string => {
    let result = '';
    if (!area.zone) {
      return result;
    }

    if (area.zone.province) {
      result += `${area.zone.province} `;
    }

    if (area.zone.district) {
      result += `${area.zone.district} `;
    }

    if (area.zone.neighborhood) {
      result += `${area.zone.neighborhood} `;
    }

    if (area.radius) {
      result += `${area.radius / 1000}km `;
    }

    return result.slice(0, -1);
  };

  React.useEffect(() => {
    if (!editing) {
      setMemo(data?.counsel.memo || '');
    }
  }, [data?.counsel.memo, editing]);

  if (loading) {
    <Loading />;
  }

  if (error)
    return (
      <Result
        status="error"
        title="상담 상세 정보 로드중에 문제가 발생했습니다."
        extra={error.message}
      />
    );

  return data ? (
    <SContainer>
      <PageHeader
        className={className}
        title={
          <Space>
            <CommentOutlined />
            상담 정보
          </Space>
        }
        subTitle={
          '상담 생성일: ' +
          dateFormatter(data.counsel.createdAt, {
            withDay: true,
            withTime: true,
          })
        }
      />
      <SBodyContainer>
        <Card
          size="small"
          title="상담사 메모"
          extra={
            <Space>
              <Button
                disabled={viewOnly}
                onClick={() => {
                  setEditing(editing => !editing);
                  setMemo(data?.counsel.memo || '');
                }}
              >
                {editing ? '취소' : '편집'}
              </Button>
              <Button
                disabled={!editing || viewOnly}
                type="primary"
                onClick={async () => {
                  if (memo.length < 1 || memo.length > 1000) {
                    notification.error({
                      message: '메모는 1글자 이상 1000글자로 가능합니다.',
                    });
                    return;
                  }
                  await updateCounselMemo(memo);
                  setEditing(false);
                }}
              >
                저장
              </Button>
            </Space>
          }
        >
          <SMemoContainer>
            <SMemoRecent>
              {data.counsel.memo ? (
                <Text>{data.counsel.memo}</Text>
              ) : (
                <Text disabled>메모 없음</Text>
              )}
            </SMemoRecent>
            {editing && (
              <SMemoEdit
                autoSize={{ minRows: 3, maxRows: 12 }}
                value={memo}
                onChange={e => setMemo(e.target.value)}
                maxLength={1000}
                showCount
              />
            )}
          </SMemoContainer>
        </Card>
        {data.counsel.areas.length > 0 && (
          <Card size="small" title="상담 희망 정비 지역">
            <Space direction="horizontal">
              {data.counsel.areas.map((area, index) => (
                <Button
                  key={`detail-area-${index}`}
                  onClick={() => {
                    window.open(
                      `${env.MAP_CONSOLE_URL}/?lat=${
                        area.coordinates.latitude
                      }&lon=${area.coordinates.longitude}&distance=${Math.ceil(
                        area.radius / 1000,
                      )}`,
                      'doctor-cha-company-detail',
                      'width=1676, height=1207',
                    );
                  }}
                  icon={<EnvironmentOutlined />}
                >
                  {areasFormatHandler(area)}
                </Button>
              ))}
            </Space>
          </Card>
        )}
        <Card
          size="small"
          title={
            data.counsel.post ? '연결된 문의게시글' : '문의내용(기존 상담내용)'
          }
          extra={
            <Tooltip
              title={
                data.counsel.post
                  ? '닥터차 웹 게시물 페이지로 이동'
                  : '채팅과 연관된 게시물이 없습니다'
              }
              placement="left"
            >
              <Button
                type="link"
                disabled={!data.counsel.post}
                onClick={() => {
                  if (data.counsel.post) {
                    window.open(
                      `${env.WEB_URL}/post/${data.counsel.post.id}`,
                      data.counsel.post.id,
                      'width=1676, height=1207',
                    );
                  }
                }}
              >
                커뮤니티 글 가기
              </Button>
            </Tooltip>
          }
        >
          <Space direction="vertical">
            {data.counsel.post?.id ? (
              <>
                <Descriptions size="small" column={1} bordered>
                  <Descriptions.Item label="제목">
                    {data.counsel.post.title}
                  </Descriptions.Item>
                  <Descriptions.Item label="지역">
                    <Space direction="horizontal">
                      {data.counsel.post.areas.map((area, index) => (
                        <Button
                          key={`detail-area-${index}`}
                          onClick={() => {
                            window.open(
                              `${env.MAP_CONSOLE_URL}/?lat=${
                                area.coordinates.latitude
                              }&lon=${
                                area.coordinates.longitude
                              }&distance=${Math.ceil(area.radius / 1000)}`,
                              'doctor-cha-company-detail',
                              'width=1676, height=1207',
                            );
                          }}
                          icon={<EnvironmentOutlined />}
                        >
                          {areasFormatHandler(area)}
                        </Button>
                      ))}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="본문">
                    {data.counsel.post.body}
                  </Descriptions.Item>
                  <Descriptions.Item label="해시태그">
                    <Hashtags postId={data.counsel.post.id} />
                  </Descriptions.Item>
                  <Descriptions.Item label="사진/영상 자료">
                    <SMediaCol>
                      {data.counsel.post.files
                        .filter(file => file.__typename === 'Video')
                        .map(file => (
                          <SVideo
                            key={`video-${file.url}`}
                            controls
                            height={180}
                            preload="metadata"
                          >
                            <source src={file.url} />
                          </SVideo>
                        ))}
                      <Image.PreviewGroup>
                        {data.counsel.post.files
                          .filter(file => file.__typename === 'Image')
                          .map(file => (
                            <SImage key={`image-${file.url}`}>
                              <Image
                                height={180}
                                src={file.url}
                                alt={file.description || file.url}
                              />
                            </SImage>
                          ))}
                      </Image.PreviewGroup>
                    </SMediaCol>
                  </Descriptions.Item>
                </Descriptions>
              </>
            ) : (
              <>
                <SBodyCol>{data.counsel.body}</SBodyCol>
                <Space split={<Divider type="vertical" />}>
                  {data.counsel.insurance && (
                    <Typography.Text>
                      보험처리 방식:{' '}
                      <Tag>{insuranceFormatter(data.counsel.insurance)}</Tag>
                    </Typography.Text>
                  )}
                  {data.counsel.type && (
                    <Typography.Text>
                      문의항목(예전분류): <Tag>{data.counsel.type.name}</Tag>
                    </Typography.Text>
                  )}
                </Space>
                <SMediaCol>
                  {data.counsel.files
                    .filter(file => file.__typename === 'Video')
                    .map(file => (
                      <SVideo
                        key={`video-${file.url}`}
                        controls
                        height={180}
                        preload="metadata"
                      >
                        <source src={file.url} />
                      </SVideo>
                    ))}
                  <Image.PreviewGroup>
                    {data.counsel.files
                      .filter(file => file.__typename === 'Image')
                      .map(file => (
                        <SImage key={`image-${file.url}`}>
                          <Image
                            height={180}
                            src={file.url}
                            alt={file.description || file.url}
                          />
                        </SImage>
                      ))}
                  </Image.PreviewGroup>
                </SMediaCol>
              </>
            )}
          </Space>
        </Card>

        <Card
          size="small"
          title={data.counsel.counselee?.nickname + '님의 최근 상담 목록'}
        >
          <Table
            pagination={false}
            dataSource={data.counsel.counselee?.inactiveCounselConnection.edges.map(
              edge => ({
                key: `edge-node-${edge.node.id}`,
                ...edge.node,
                createdAt: dateFormatter(edge.node.createdAt),
              }),
            )}
          >
            <Table.Column
              title="상담ID"
              key="id"
              dataIndex="id"
              render={id => (
                <a href={`${host}/chat/${id}`} target="_blank" rel="noreferrer">
                  {id}
                </a>
              )}
            />
            <Table.Column title="상담번호" key="index" dataIndex="index" />
            <Table.Column
              title="상담일"
              key="createdAt"
              dataIndex="createdAt"
            />
            <Table.Column title="상담사 메모" key="memo" dataIndex="memo" />
          </Table>
        </Card>
      </SBodyContainer>
    </SContainer>
  ) : null;
};

const SContainer = styled.div`
  height: calc(100vh - 48px);
  overflow-y: scroll;
`;

const SBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 16px;
`;

const SMemoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const SMemoRecent = styled.div`
  flex: 1;
  background-color: ${colors.BACKGROUND_LIGHT};
  padding: 8px;
  white-space: pre-wrap;
`;
const SMemoEdit = styled(Input.TextArea)`
  flex: 1;
`;

const SBodyCol = styled.div`
  background-color: ${colors.BACKGROUND_LIGHT};
  padding: 8px 12px;
  border-radius: 2px;
  white-space: pre-wrap;
  margin-bottom: 8px;
`;

const AreaButton = styled.div`
  background-color: ${colors.BACKGROUND_LIGHT};
  padding: 8px 12px;
  border-radius: 2px;
  white-space: pre-wrap;
  margin-bottom: 8px;
  cursor: pointer;
`;

const SMediaCol = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

const SImage = styled.span`
  margin-right: 8px;
  flex: 0 0 auto;
`;

const SVideo = styled.video`
  margin-right: 8px;
  flex: 0 0 auto;
`;
