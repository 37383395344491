import { Button, Image, Input, Popconfirm, Space, message } from 'antd';
import React from 'react';

import ImageFallback from '@assets/image/fallback-image.png';
import { useFileUpload, useMessage } from '@hooks';
import { FileType } from '@utils/client';

interface TextInputProps {
  disabled?: boolean;
  counselId: string;
}
const TextInput: React.FC<TextInputProps> = ({ disabled, counselId }) => {
  const { sendTextMessage, sendFileMessage } = useMessage({ counselId });
  const { upload } = useFileUpload();

  const [text, setText] = React.useState<string>('');
  const [pastedFile, setPastedFile] = React.useState<File | null>(null);
  const [sendingPastedFile, setSendingPastedFile] = React.useState(false);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (pastedFile) sendPastedContent();
      else handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    setText(text => {
      if (text) {
        sendTextMessage(text);
        return '';
      }
      return text;
    });
  };

  const handlePaste: React.ClipboardEventHandler<
    HTMLTextAreaElement
  > = async event => {
    if (event.clipboardData.files.length) {
      const file = event.clipboardData.files[0];
      const type = file.type.split('/')[0];
      if (!['image', 'video'].includes(type)) {
        message.error(`'${file.type}'은 지원하지 않는 형식의 파일입니다.`);
        return;
      }

      setPastedFile(file);
    }
  };

  const cancelSendPastedContent = () => {
    setPastedFile(null);
  };

  const sendPastedContent = async () => {
    if (pastedFile) {
      setSendingPastedFile(true);
      const type = pastedFile.type.split('/')[0];

      try {
        switch (type) {
          case 'image': {
            const image = await upload(pastedFile);
            sendFileMessage({ type: FileType.Image, url: image.url });
            break;
          }
          case 'video': {
            const video = await upload(pastedFile);
            sendFileMessage({ type: FileType.Video, url: video.url });
            break;
          }
          default:
            alert(`'${type}'은 지원하지 않는 파일 형식입니다.`);
        }
      } catch {
        alert('파일 업로드에 실패했습니다.');
      } finally {
        setPastedFile(null);
        setSendingPastedFile(false);
      }
    }
  };

  return (
    <>
      <Popconfirm
        title={
          <Space direction="vertical">
            <div>이 사진/비디오를 전송하시겠습니까?</div>
            {pastedFile && (
              <Image
                width={200}
                height={200}
                src={URL.createObjectURL(pastedFile)}
                fallback={ImageFallback}
              />
            )}
          </Space>
        }
        visible={!!pastedFile}
        onConfirm={sendPastedContent}
        onCancel={cancelSendPastedContent}
        okButtonProps={{ loading: sendingPastedFile }}
        placement="topLeft"
        okText="전송"
        cancelText="취소"
      >
        <Input.TextArea
          disabled={disabled}
          value={text}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          autoSize={{ minRows: 1, maxRows: 3 }}
          style={{ flex: 1, marginRight: 8 }}
        />
      </Popconfirm>
      <Button
        type="primary"
        disabled={disabled || !text}
        onClick={handleSendMessage}
        style={{ alignSelf: 'center' }}
      >
        전송
      </Button>
    </>
  );
};

export default TextInput;
