import React from 'react';
import styled from 'styled-components';

import { dateFormatter } from '@utils/formatters';

type SimpleTextProps = {
  createdAt?: number;
  children: React.ReactNode;
};
const SimpleText: React.FC<SimpleTextProps> = ({ createdAt, children }) => {
  return (
    <>
      {createdAt && (
        <SSign>{dateFormatter(createdAt, { withDay: true })}</SSign>
      )}
      <SSign>{children}</SSign>
    </>
  );
};

export default SimpleText;

const SSign = styled.span`
  align-self: center;
  margin: 15px 0px;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 21px;

  border: 1px solid ${props => props.theme.colors.BORDER_PRIMARY_LIGHT};
  color: ${props => props.theme.colors.ON_PRIMARY_LIGHT};
`;
