import { CompassOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

const AreaInputMessage = (props: any) => {
  return (
    <InlineBubble {...props} icon={<CompassOutlined />}>
      희망 정비 지역 입력을 요청하였습니다
    </InlineBubble>
  );
};

export default AreaInputMessage;
