import QueuedTable, {
  TQueuedTableRow as tableType,
  QUEUED_COUNSELS as _QUEUED_COUNSELS,
  QUEUED_COUNSELS_RESULT as _QUEUED_COUNSELS_RESULT,
} from './QueuedTable';

export type TQueuedTableRow = tableType;
export const QUEUED_COUNSELS = _QUEUED_COUNSELS;
export const QUEUED_COUNSELS_RESULT = _QUEUED_COUNSELS_RESULT;
export default QueuedTable;
