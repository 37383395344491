import { CompassOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

const VinInputMessage: React.FC = props => {
  return (
    <InlineBubble {...props} icon={<CompassOutlined />}>
      차대번호 입력을 요청하였습니다
    </InlineBubble>
  );
};

export default VinInputMessage;
