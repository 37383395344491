import { Input } from 'antd';
import React from 'react';
import styled, { CSSProperties } from 'styled-components';

type EditableCellProps = {
  value: string | number;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  paste?: boolean;
  textStyle?: CSSProperties;
  onEnter?: () => void;
  onBlur?: () => void;
  textValue?: string;
};

const EditableCell: React.FC<EditableCellProps> = ({
  onChange,
  value,
  autoFocus = false,
  disabled = false,
  paste = true,
  textStyle,
  onEnter,
  textValue,
  onBlur,
}) => {
  const [openEdit, setOpenEdit] = React.useState(autoFocus);
  const handleChangeOpenEdit = () => {
    if (disabled) {
      return false;
    }
    if (!openEdit) {
      setOpenEdit(true);
    }
  };
  //value === '' ? '-' : value.toLocaleString()
  return (
    <SContainer onClick={handleChangeOpenEdit}>
      {openEdit ? (
        <Input
          autoFocus
          onBlur={() => {
            onBlur?.();
            setOpenEdit(false);
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              onEnter?.();
              setOpenEdit(false);
            }
          }}
          onChange={e => onChange && onChange(e.target.value)}
          value={value}
          onPaste={e => {
            if (!paste) {
              e.preventDefault();
            }
          }}
        />
      ) : (
        <SText style={textStyle}>{textValue ? textValue : value}</SText>
      )}
    </SContainer>
  );
};

export default EditableCell;

const SContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
`;

const SText = styled.div`
  font-size: 14px;
  color: #434343;
`;
