import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
  }

  p, span {
    /* color: rgba(0, 0, 0, 0.85); */
    margin: 0px;
    padding: 0px;
  }

  svg {
    vertical-align: baseline;
  }

  .ant-tabs-content {
    height: 100%;       
  }

  .ant-tree-node-content-wrapper {
    display: flex;
  }
  .ant-tree-title {
    flex: 1;
  }

  .ant-page-header {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .ant-page-header-content {
    overflow-y: hidden;
  }

  .badge-count-muted .ant-badge-count {
    color: #999;
    background-color: #fff;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
  }

  .badge-count-muted .ant-scroll-number-only-unit {
    color: #999;
  }

  
  .ant-tag-checkable-magenta:not(.ant-tag-checkable-magenta-checked):hover {
    color: #EB2F96;
  }
  .ant-tag-checkable-magenta-checked {
    background-color: #EB2F96;
  }
  .ant-tag-checkable-magenta:active {
    background-color: #EB2F96;
  }

  .ant-tag-checkable-volcano:not(.ant-tag-checkable-volcano-checked):hover {
    color: #FA541C;
  }
  .ant-tag-checkable-volcano-checked {
    background-color: #FA541C;
  }
  .ant-tag-checkable-volcano:active {
    background-color: #FA541C;
  }

  .ant-tag-checkable-gold:not(.ant-tag-checkable-gold-checked):hover {
    color: #FAAD14;
  }
  .ant-tag-checkable-gold-checked {
    background-color: #FAAD14;
  }
  .ant-tag-checkable-gold:active {
    background-color: #FAAD14;
  }

  .ant-tag-checkable-lime:not(.ant-tag-checkable-lime-checked):hover {
    color: #A0D911;
  }
  .ant-tag-checkable-lime-checked {
    background-color: #A0D911;
  }
  .ant-tag-checkable-lime:active {
    background-color: #A0D911;
  }

  .ant-tag-checkable-cyan:not(.ant-tag-checkable-cyan-checked):hover {
    color: #13C2C2;
  }
  .ant-tag-checkable-cyan-checked {
    background-color: #13C2C2;
  }
  .ant-tag-checkable-cyan:active {
    background-color: #13C2C2;
  }

  .ant-tag-checkable-geekblue:not(.ant-tag-checkable-geekblue-checked):hover {
    color: #2F54EB;
  }
  .ant-tag-checkable-geekblue-checked {
    background-color: #2F54EB;
  }
  .ant-tag-checkable-geekblue:active {
    background-color: #2F54EB;
  }

  .ant-tag-checkable-purple:not(.ant-tag-checkable-purple-checked):hover {
    color: #722ED1;
  }
  .ant-tag-checkable-purple-checked {
    background-color: #722ED1;
  }
  .ant-tag-checkable-purple:active {
    background-color: #722ED1;
  }

  .ReactVirtualized__Table__headerRow {
    background-color: #FAFAFA;
    font-weight: normal;
  }

  .ReactVirtualized__Table__headerColumn {
    border-right: 1px solid #F0F0F0;
  }

  .ReactVirtualized__Table__rowColumn {
    border-right: 1px solid #F0F0F0;
    height: 100%;
  }

  .ReactVirtualized__Table__row {
    cursor: pointer;
    border-bottom: 1px solid #F0F0F0;
    :hover {
      background-color: #FAFAFA;
      box-shadow: 0 0 0 2px #eee inset; 
    }
  }

  .ant-modal-confirm-title {
    white-space: pre;
  }

  ::-webkit-scrollbar {
    width: 6px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.15);
    border-radius: 3px;
    
    :hover {
      background: rgba(0,0,0,0.3);
    }
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .footer-bg-white .ant-table-footer{
    background-color: #ffffff;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .blue-title-modal .ant-modal-header {
    background-color: #0050B3;  
  }

  .blue-title-modal .ant-modal-title {
    color: #ffffff !important;
  }

  .blue-title-modal .anticon-close svg {
    color: #ffffff !important;
  }
`;

export default GlobalStyle;
