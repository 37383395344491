import { InMemoryCache, gql, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

import { TypedTypePolicies } from './apollo-helpers';
import generatedPossibleTypes from './fragment-matcher';

export const typeDefs = gql`
  extend type Query {
    loggedIn: Boolean!
    searchedCounsels(status: CounselStatus!): [Counsel!]!
    counselMeta(status: CounselStatus!): CounselMeta!
  }
  type CounselMeta {
    total: Int!
    unreads: Int!
  }

  type PressedMessageColumn {
    id: String!
    avatar: String
    text: String
    sendedAt: Int
  }
`;

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      loggedIn: {
        read() {
          return loggedInVar();
        },
      },
      ongoingCounsels: {
        read(counsels) {
          return counsels;
        },
        merge(_, incoming: any[]) {
          return incoming;
        },
      },
      queuedCounsels: {
        read(counsels) {
          return counsels;
        },
        merge(_, incoming: any[]) {
          return incoming;
        },
      },
      estimatingCounsels: {
        read(counsels) {
          return counsels;
        },
        merge(_, incoming: any[]) {
          return incoming;
        },
      },
      pendingCounselConnection: relayStylePagination(),
      finishedCounselConnection: relayStylePagination(),
    },
  },
  Counsel: {
    fields: {
      messageConnection: relayStylePagination(),
    },
  },
};

export const loggedInVar = makeVar<boolean>(!!localStorage.getItem('token'));

const cache = new InMemoryCache({
  possibleTypes: generatedPossibleTypes.possibleTypes,
  typePolicies,
});

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(localStorage),
  maxSize: 1024 * 1024 * 20, // 4MB
});

export default cache;
export type Cache = typeof cache;

export const generateCache = () => {
  return new InMemoryCache({
    possibleTypes: generatedPossibleTypes.possibleTypes,
    typePolicies,
  });
};

export const generatePersistedCache = () => {
  const cache = new InMemoryCache({
    possibleTypes: generatedPossibleTypes.possibleTypes,
    typePolicies,
  });

  const persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(localStorage),
    maxSize: 1024 * 1024 * 20, // 4MB
  });

  return { cache, persistor };
};
