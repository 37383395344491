import React from 'react';

import SimpleText from '../messageParts/SimpleText';

type EnterMessageProps = {
  inviter?: string;
  invitee?: string;
};

const EnterMessage: React.FC<EnterMessageProps> = ({
  invitee,
  inviter,
}: EnterMessageProps) => {
  if (inviter !== invitee) {
    return (
      <SimpleText>
        {inviter}님이 {invitee || '(알 수 없음)'}님을 초대했습니다.
      </SimpleText>
    );
  } else {
    return (
      <SimpleText>{invitee || '(알 수 없음)'}님이 입장했습니다.</SimpleText>
    );
  }
};

export default EnterMessage;
