import { Checkbox, Image, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

import FallbackImage from '@assets/image/fallback-image.png';
import { MessageContentInput } from '@hooks/useMessage';

interface CellProps {
  className?: string;
  selected: boolean;
  onToggle: (selected: boolean) => void;
  content: MessageContentInput;
  onContentChange: (content: MessageContentInput) => void;
}
const Cell: React.FC<CellProps> = ({
  className,
  selected,
  content,
  onToggle,
  onContentChange,
}) => {
  let cell: React.ReactNode = null;

  switch (content.type) {
    case 'text':
      cell = (
        <STextInput
          autoSize
          value={content.body}
          onChange={event =>
            onContentChange({ ...content, body: event.target.value })
          }
        />
      );
      break;
    case 'image':
      cell = <SImage width={300} src={content.url} fallback={FallbackImage} />;
      break;
    case 'video':
      cell = (
        <SVideo controls width={300} preload="metadata">
          <source src={content.url} />
        </SVideo>
      );
      break;
  }

  return (
    <SCell className={className}>
      <SCheckbox
        checked={selected}
        onChange={event => onToggle(event.target.checked)}
      />
      {cell}
    </SCell>
  );
};

export default React.memo(Cell);

const SCell = styled.li`
  display: flex;
  flex-direction: row;
`;
const SCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;
const STextInput = styled(Input.TextArea)`
  flex: 1;
`;
const SImage = styled(Image)`
  border-radius: 8px;
`;
const SVideo = styled.video`
  border-radius: 8px;
`;
