import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Button,
  Card,
  Input,
  Modal,
  Popover,
  Table,
  Tag,
  notification,
  Radio,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import AdditionalTableHeader from './AdditionalTableHeader';
import CancelPaymentModal from './CancelPaymentModal';
import EditableCell from './EditableCell';
import { EstimateItem } from './PaymentPresenter';
import ShippingConfirmModal from './ShippingConfirmModal';
import ShippingUpdateModal from './ShippingUpdateModal';

import SendEstimateConfirmModal from '@components/modals/SendEstimateConfirmModal';
import useCounselWindowCache from '@hooks/useCounselWindowCache';
import {
  CounselStatus,
  CreateShippingData,
  Maybe,
  PaymentStatus,
  TaskpadOrderFragment,
  UpdateShippingData,
  useCompletedPaymentSubscription,
  Vehicle,
  VehicleModel,
} from '@utils/client';
import { tableRowDateFormatter } from '@utils/formatters';

// import { RocketOutlined } from '@ant-design/icons'

// import env from '@utils/env';

export type TOrderItem = {
  code: string;
  name: string;
  price: number;
  quantity: number;
  discountRate: number;
  paymentAmount: number;
};

export type Company = {
  id: string;
  address: string;
  contact: string;
  name: string;
};

export type PaymentTableProps = {
  counselId: string;
  status: CounselStatus;
  items: TOrderItem[];
  updateEstimate: (estimateItems: EstimateItem[]) => void;
  sendEstimate: () => void;
  shippingCharge: number;
  order: TaskpadOrderFragment;
  updateShippingCharge: (
    order: TaskpadOrderFragment,
    updatedShippingCharge: number,
  ) => void;
  updateOrderDiscount: (
    order: TaskpadOrderFragment,
    updatedDiscount: number,
  ) => void;
  updateCashbackRate: (
    order: TaskpadOrderFragment,
    updatedCashbackRate: number,
  ) => void;
  handleSendPayment: (
    orderId: string,
    shippingData?: CreateShippingData,
  ) => void;
  handleCancelPayment: (
    orderId: string,
    paymentId: string,
    reason: string,
  ) => void;
  handleUpdateShipping: (
    orderId: string,
    shippingId: string,
    shippingData: UpdateShippingData,
  ) => void;
  handleForceComplementPayment: (orderId: string, paymentId: string) => void;
  updateRecommendedReason: (
    orderId: string,
    recommendedReason: string,
  ) => Promise<void>;
  onChangeOrderInsurance: (orderId: string, insurance: boolean) => void;
  companies: Company[];
  vehicle?: Maybe<
    { __typename?: 'Vehicle' } & Pick<
      Vehicle,
      'id' | 'plateNumber' | 'registeredYear' | 'shortModelName'
    >
  >;
};

const CLIPBOARD_ERROR = {
  title: '견적서의 정확한 부분을 복사해주세요!',
  content:
    '견적서의 부품표에서 No부터 합계까지, 총9개 필드를 추가하여 복사해주세요.\n또한, 표의 헤더영역을 포함해서 복사해주세요.',
};

const getDuplicatedError = (code: string) => {
  const title = '견적서에 중복된 부품 코드가 있습니다.';
  const duplicatedCode = `중복된 코드명: ${code}`;
  return {
    title,
    content: duplicatedCode,
  };
};

gql`
  subscription completedPayment($paymentId: ID!) {
    completedPayment(paymentId: $paymentId) {
      id
      amount
      completedAt
      cancelledAt
      status
      order {
        id
        shipping {
          id
        }
      }
    }
  }
`;

// TODO: 앞으로 Product가 늘어남에 따라, 서버에서 던지는 에러메시지 핸들링을 어떻게 처리할지 고려해보아야함.
const ESTIMATE_ID_MATCH_ERROR =
  'id must match /^[A-Za-z0-9-_\\s]{3,32}$/ regular expression';

const PaymentTable: React.FC<PaymentTableProps> = ({
  shippingCharge,
  items,
  updateEstimate,
  sendEstimate,
  order,
  updateShippingCharge,
  updateOrderDiscount,
  updateCashbackRate,
  handleSendPayment,
  handleCancelPayment,
  handleUpdateShipping,
  updateRecommendedReason,
  counselId,
  companies,
  status,
  vehicle,
  onChangeOrderInsurance,
}) => {
  const [updatingShippingCharge, setUpdateShippingCharge] =
    React.useState(false);
  const [updatingAdditionalDiscount, setUpdatingAdditionalDiscount] =
    React.useState(false);
  const [updatingCashbackRate, setUpdatingCashbackRate] = React.useState(false);
  const [updatedShippingCharge, setUpdatedShippingCharge] =
    React.useState(shippingCharge);
  const [updatedAdditionalDiscount, setUpdatedAdditionalDiscount] =
    React.useState(order.discountAmount);
  const [updatedCashbackRate, setUpdatedCashbackRate] = React.useState(
    order.__typename === 'InsurancedOrder' ? order.cashbackRate : 0,
  );
  const [openedShippingModal, setOpenShippingModal] = React.useState(false);
  const [openedCancelPaymentModal, setOpenCancelPaymentModal] =
    React.useState(false);
  const [openedShippingUpdateModal, setOpenShippingUpdateModal] =
    React.useState(false);
  const [recommend, setRecommend] = React.useState<string | undefined>(
    order.recommendedReason as string | undefined,
  );
  const [estimateConfirmModalVisible, setEstimateConfirmModalVisible] =
    React.useState(false);
  const [estimateItems, setEstimateItems] = React.useState<EstimateItem[]>([]);
  const [visibleDiscountPopover, setVisibleDiscountPopover] =
    React.useState(false);

  React.useEffect(() => {
    setEstimateItems(
      items.map(item => ({
        code: item.code,
        name: item.name,
        quantity: item.quantity,
        discountRate: Number((item.discountRate * 100).toFixed(2)),
        price: item.price,
        paymentAmount: item.paymentAmount,
      })),
    );
  }, [items]);

  const insuranced = React.useMemo(() => {
    return order.__typename === 'InsurancedOrder';
  }, [order]);

  const counselWindowCache = useCounselWindowCache();

  const paymentId = order.payment?.id as string;
  useCompletedPaymentSubscription({
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) {
        return;
      }

      const payment = data.completedPayment;
      counselWindowCache.updatePayment(counselId, order.id, payment);
    },
    variables: {
      paymentId,
    },
    skip: !paymentId,
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  const handleUpdateShippingFee = () => {
    if (!updatingShippingCharge && active) {
      setUpdateShippingCharge(true);
    }
  };

  const handleUpdateAdditionalDiscount = () => {
    if (!updatingAdditionalDiscount && active) {
      setUpdatingAdditionalDiscount(true);
    }
  };

  const handleUpdateCashbackRate = () => {
    if (order.__typename !== 'InsurancedOrder') {
      return;
    }

    if (!updatingCashbackRate && active) {
      setUpdatedCashbackRate(order.cashbackRate);
      setUpdatingCashbackRate(true);
    }
  };

  const loadClipboardData = async () => {
    const copied = await navigator.clipboard.readText();

    const onWindow = window.navigator.userAgent.includes('Window');

    let newLineCharacter = '\n';

    if (onWindow) {
      newLineCharacter = '\r\n';
    }

    const splitRow = copied.split(newLineCharacter);

    if (splitRow.length === 0) {
      Modal.error(CLIPBOARD_ERROR);
      return;
    }

    let error = false;
    const estimatedItems: EstimateItem[] = [];
    if (splitRow[0].startsWith('품목코드')) {
      //ECOUNT
      const headers = splitRow[0].split('\t');
      if (
        headers.length !== 6 ||
        headers[0] !== '품목코드' ||
        headers[5] !== '부가세'
      ) {
        Modal.error(CLIPBOARD_ERROR);
        return;
      }

      for (let i = 1; i < splitRow.length; i++) {
        const dataRow = splitRow[i].split('\t');
        const code = dataRow[0];
        const name = dataRow[1];
        const quantity = Number(dataRow[2]?.replace('EA', ''));
        const price = Number(dataRow[3]?.replaceAll(',', ''));
        const tax = Math.round(price * 0.1);
        const totalPrice = price + tax;

        if (estimatedItems.some(estimatedItem => estimatedItem.code === code)) {
          Modal.error(getDuplicatedError(code));
          error = true;
          break;
        }

        const handledCode = code.replaceAll('.', '').replaceAll('-', '');

        estimatedItems.push({
          code: handledCode,
          name,
          quantity: quantity,
          discountRate: 0,
          price: Number(totalPrice),
          paymentAmount: Number(totalPrice),
        });
      }
    } else if (splitRow[0].endsWith('납품단가')) {
      //EPMS
      const headers = splitRow[0].split('\t');

      if (
        headers.length !== 5 ||
        headers[0] !== 'No' ||
        headers[4] !== '납품단가'
      ) {
        Modal.error(CLIPBOARD_ERROR);
        return;
      }

      for (let i = 3; i < splitRow.length; i++) {
        const dataRow = splitRow[i].split('\t');
        const code = dataRow[1];
        const name = dataRow[2];
        const quantity = Number(dataRow[3]);
        const price = Number(dataRow[4]?.replaceAll(',', ''));
        const tax = Math.round(price * 0.1);
        const totalPrice = price + tax;

        if (estimatedItems.some(estimatedItem => estimatedItem.code === code)) {
          Modal.error(getDuplicatedError(code));
          error = true;
          break;
        }

        estimatedItems.push({
          code,
          name,
          quantity: quantity,
          discountRate: 0,
          price: Number(totalPrice),
          paymentAmount: Number(totalPrice),
        });
      }
    } else {
      //닥터파츠
      const headers = splitRow[0].split('\t');
      headers.splice(4, 1);

      if (
        headers.length !== 9 ||
        headers[0] !== 'No' ||
        headers[8] !== '합계'
      ) {
        Modal.error(CLIPBOARD_ERROR);
        return;
      }

      const datas = [];
      for (let i = 1; i < splitRow.length; i++) {
        const dataRow = splitRow[i].split('\t');
        dataRow.splice(4, 1);

        // 부품이름이 견적서에 없진 않을테니, 이게 없으면 빈 로우를 복사한것으로 본다.
        const partName = dataRow[3];

        if (dataRow.length !== 9) {
          Modal.error(CLIPBOARD_ERROR);
          return;
        }

        if (partName !== '') {
          datas.push(dataRow);
        }
      }

      for (const data of datas) {
        const code = data[2];
        if (code === '') {
          continue;
        }

        const name = data[3];
        // const unit = data[4];
        const quantity = data[5];
        const price = data[6].split(',').join('');
        const castedPrice = price ? Number(price.split(',').join('')) : 0;
        const tax = data[7];
        const castedTax = tax ? Number(tax.split(',').join('')) : 0;
        const castedQuantity = quantity ? Number(quantity) : 0;

        const totalPrice = castedPrice + castedTax;

        // 중복제거
        if (estimatedItems.some(estimatedItem => estimatedItem.code === code)) {
          Modal.error(getDuplicatedError(code));
          error = true;
          break;
        }

        estimatedItems.push({
          code,
          name,
          quantity: castedQuantity,
          discountRate: 0,
          price: Number(totalPrice),
          paymentAmount: Number(totalPrice),
        });
      }
    }

    if (error) {
      return;
    }

    try {
      if (estimatedItems.length === 0) {
        return;
      }

      const invalidItem = estimateItems.some(
        item =>
          !item.code.match(/^[A-Za-z0-9-_\s]{3,32}$/) ||
          item.name.length < 2 ||
          item.name.length > 128,
      );

      if (invalidItem) {
        notification.error({
          message: '부품코드 또는 부품이름이 형식에 맞지 않는 것이 있습니다.',
        });
        return;
      }

      await updateEstimate(estimatedItems);
    } catch (err) {
      let message = '개발팀에 문의바랍니다.';

      if (err instanceof Error && err.message === ESTIMATE_ID_MATCH_ERROR) {
        message = '부품코드 형식에 맞지 않는 것이 있습니다.';
      }
      Modal.error({
        title: '견적서 부품 저장에 실패했습니다.',
        content: message,
      });
    }
  };

  const handleChangeAdditionalDiscount = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = Number(e.target.value);
    if (isNaN(value)) {
      return;
    }
    setUpdatedAdditionalDiscount(value);
  };

  const handleChangeCashbackRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value)) {
      return;
    }
    setUpdatedCashbackRate(Number((value / 100).toFixed(2)));
  };

  const handleChangeShippingFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value)) {
      return;
    }
    setUpdatedShippingCharge(value);
  };

  const handleUpdateShippingCharge = () => {
    if (order.shippingCharge === updatedShippingCharge) {
      setUpdateShippingCharge(false);
      return;
    }

    if (order.payment && order.payment.status !== PaymentStatus.Ready) {
      Modal.error({
        title: '수정할 수 없는 견적서 입니다.',
        content: '결제가 진행된 견적서는 수정할 수 없습니다.',
      });
      setUpdateShippingCharge(false);
      setUpdatedShippingCharge(order.shippingCharge);
      return;
    }

    updateShippingCharge(order, updatedShippingCharge);
    setUpdateShippingCharge(false);
  };

  const handleBlurAdditionalDiscount = () => {
    if (order.discountAmount === updatedAdditionalDiscount) {
      setUpdatingAdditionalDiscount(false);
      return;
    }

    if (order.payment && order.payment.status !== PaymentStatus.Ready) {
      Modal.error({
        title: '수정할 수 없는 견적서 입니다.',
        content: '결제가 진행된 견적서는 수정할 수 없습니다.',
      });
      setUpdatingAdditionalDiscount(false);
      setUpdatedAdditionalDiscount(order.discountAmount);
      return;
    }

    updateOrderDiscount(order, updatedAdditionalDiscount);
    setUpdatingAdditionalDiscount(false);
  };

  const handleBlurCashbackRate = () => {
    if (order.__typename !== 'InsurancedOrder') {
      return;
    }

    if (order.cashbackRate === updatedCashbackRate) {
      setUpdatingCashbackRate(false);
      return;
    }

    if (order.payment && order.payment.status !== PaymentStatus.Ready) {
      Modal.error({
        title: '수정할 수 없는 견적서 입니다.',
        content: '결제가 진행된 견적서는 수정할 수 없습니다.',
      });
      setUpdatingCashbackRate(false);
      setUpdatedCashbackRate(order.cashbackRate);
      return;
    }

    updateCashbackRate(order, updatedCashbackRate);
    setUpdatingCashbackRate(false);
  };

  const notPaid = React.useMemo(() => {
    if (!order.payment) {
      return true;
    }
    return ![PaymentStatus.Paid, PaymentStatus.Cancelled].includes(
      order.payment.status,
    );
  }, [order]);

  const handleClickRequestPayment = () => {
    setOpenShippingModal(true);
  };

  const handleClickSendPayment = (company?: Company) => {
    if (order.items.length === 0) {
      return;
    }
    let shippingData: CreateShippingData | undefined = undefined;
    if (company) {
      shippingData = {
        contact: company.contact ? company.contact : '',
        destination: company.address,
        recipient: company.name,
      };
    }
    handleSendPayment(order.id, shippingData);
    setOpenShippingModal(false);
  };

  const handleBlurReason = async () => {
    if (!recommend) {
      return;
    }
    await updateRecommendedReason(order.id, recommend);
  };

  const active = React.useMemo(() => {
    return (
      status !== CounselStatus.Pending && status !== CounselStatus.Finished
    );
  }, [status]);

  const paymentActionButton = React.useMemo(() => {
    if (!order.payment || notPaid) {
      const empty = order.items.length === 0;
      return (
        <SSubmitBox>
          <Button
            disabled={empty || !active}
            type="primary"
            ghost
            onClick={() => setEstimateConfirmModalVisible(true)}
          >
            {status === CounselStatus.Estimated ||
            status === CounselStatus.Estimating
              ? '선견적서 보내기'
              : '이 견적서 보내기'}
          </Button>
          {(status === CounselStatus.Ongoing ||
            status === CounselStatus.Queued) && (
            <Button
              disabled={empty || !active}
              type="primary"
              style={{ marginLeft: '12px' }}
              onClick={handleClickRequestPayment}
            >
              결제 요청하기
            </Button>
          )}
        </SSubmitBox>
      );
    }

    if (order.payment.status === PaymentStatus.Paid) {
      return (
        <SPaidBottomBox>
          <Button onClick={() => setOpenCancelPaymentModal(true)}>
            결제 취소
          </Button>
        </SPaidBottomBox>
      );
    }
  }, [order, notPaid, active, status]);

  const updateShippingData = (data: UpdateShippingData) => {
    if (!order.shipping) {
      return;
    }

    handleUpdateShipping(order.id, order.shipping.id, data);
  };

  const onChangeDiscountRate = (value: string, code: string) => {
    if (value !== '' && isNaN(Number(value))) {
      return;
    }

    setEstimateItems(
      estimateItems.map(estimateItem => {
        if (estimateItem.code !== code) {
          return estimateItem;
        }

        return {
          ...estimateItem,
          discountRate: Number(value),
        };
      }),
    );
  };

  const updateDiscountRate = async () => {
    await updateEstimate(estimateItems);
  };

  const handleSaveDiscountRate = async (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
  ) => {
    const value = e.currentTarget.value;

    if (isNaN(Number(value))) {
      return;
    }

    await updateEstimate(
      estimateItems.map(item => ({
        ...item,
        discountRate: Number(value),
      })),
    );

    setVisibleDiscountPopover(false);
  };

  const tableTopContent = React.useMemo(() => {
    if (!order.payment && order.__typename !== 'Order') {
      return (
        <SShippingInfoBox style={{ paddingBottom: '28px' }}>
          <Radio.Group
            onChange={e => {
              onChangeOrderInsurance(order.id, e.target.value);
            }}
            value={insuranced}
          >
            <Radio value={false}>자비처리</Radio>
            <Radio value={true}>보험처리</Radio>
          </Radio.Group>
        </SShippingInfoBox>
      );
    }

    if (order.payment?.status === PaymentStatus.Cancelled) {
      return (
        <SShippingInfoBox>
          <p>{`주문번호 : ${order.id}`}</p>
          <p>{`취소완료 : ${tableRowDateFormatter(
            dayjs(order.payment?.cancelledAt).toDate(),
            false,
          )}`}</p>
        </SShippingInfoBox>
      );
    }

    if (order.shipping && order.shipping.status !== null) {
      return (
        <>
          <SShippingInfoBox>
            <p>{`주문번호 : ${order.id}`}</p>
            <p>
              {order.payment?.completedAt &&
                `결제시간 : ${tableRowDateFormatter(
                  dayjs(order.payment?.completedAt).toDate(),
                  false,
                )}`}
            </p>
          </SShippingInfoBox>
          <SShippingConsoleBox>
            <SShipingConsoleInfo>
              <SShippingConsoleText
                underline={false}
              >{`배송 정보 :`}</SShippingConsoleText>
              <SShippingConsoleText
                onClick={() => setOpenShippingUpdateModal(true)}
                style={{ marginLeft: '-7px' }}
                underline
              >{`${order.shipping?.recipient}`}</SShippingConsoleText>
              {/* <Tag style={{ height: '22px' }} color={badgeMeta?.color}>
                {badgeMeta?.text}
              </Tag> */}
            </SShipingConsoleInfo>
            {/* {order.shipping?.trackingId && (
              <SShipingConsoleInfo>
                <SShippingConsoleText
                  underline={false}
                >{`운송장 정보 :`}</SShippingConsoleText>
                <SShippingConsoleText
                  onClick={() => {
                    openShippingCarrierPage(
                      order.shipping?.carrier,
                      order.shipping?.trackingId,
                    );
                  }}
                  underline={false}
                  link
                >
                  {order.shipping.trackingId}
                </SShippingConsoleText>
              </SShipingConsoleInfo>
            )} */}
          </SShippingConsoleBox>
        </>
      );
    }
  }, [order, insuranced, onChangeOrderInsurance]);

  const canceldTableStyle: React.CSSProperties = {};

  if (order.payment?.status === PaymentStatus.Cancelled) {
    canceldTableStyle.opacity = '0.4';
  }

  return (
    <SContainer>
      {order.rejectedReason ? (
        <SRejectedReasonBox>
          <SRejectReasonTitle>
            <ExclamationCircleOutlined style={{ marginRight: '8px' }} />
            다른 견적이 필요해요.
          </SRejectReasonTitle>
          <SRejectReasonText>{order.rejectedReason}</SRejectReasonText>
        </SRejectedReasonBox>
      ) : null}
      <STopButtonsBox paid={order.payment !== null}>
        {tableTopContent}
      </STopButtonsBox>
      <STableBox>
        <Table
          dataSource={estimateItems.map((item, index) => {
            const totalPrice = item.quantity * item.price;
            return {
              ...item,
              key: index,
              totalPrice,
              orderPrice: item.paymentAmount,
            };
          })}
          scroll={{ x: true, y: '414px' }}
          pagination={false}
          style={canceldTableStyle}
        >
          <Table.Column title="상품코드" key="code" dataIndex="code" />
          <Table.Column title="상품명" key="name" dataIndex="name" />
          <Table.Column
            title="개당 가격(원)"
            key="price"
            dataIndex="price"
            render={(price: number) => `${price.toLocaleString()}원`}
          />
          <Table.Column
            title="수량(EA)"
            key="quantity"
            dataIndex="quantity"
            width={85}
          />
          {!insuranced && (
            <Table.Column
              title={
                <AdditionalTableHeader
                  title={'할인율 (-%)'}
                  additional={
                    <Popover
                      title="할인율 일괄적용"
                      trigger={'click'}
                      visible={visibleDiscountPopover}
                      onVisibleChange={visible => {
                        setVisibleDiscountPopover(visible);
                      }}
                      content={
                        <SPopoverContainer>
                          <SPopoverText>할인율 : - </SPopoverText>
                          <Input
                            addonAfter="%"
                            style={{ width: '84px', marginLeft: '4px' }}
                            onPressEnter={handleSaveDiscountRate}
                          />
                        </SPopoverContainer>
                      }
                    >
                      <Button type="link" icon={<EditOutlined />} />
                    </Popover>
                  }
                />
              }
              key="discountRate"
              dataIndex="discountRate"
              width={140}
              render={(discountRate, record: TOrderItem) => {
                return (
                  <EditableCell
                    textStyle={
                      discountRate > 0 ? { color: '#FF4D4F' } : undefined
                    }
                    value={discountRate > 0 ? discountRate : ''}
                    onChange={value => onChangeDiscountRate(value, record.code)}
                    textValue={discountRate > 0 ? `-${discountRate}%` : ''}
                    onEnter={updateDiscountRate}
                    onBlur={updateDiscountRate}
                  />
                );
              }}
            />
          )}
          <Table.Column
            title={!insuranced ? '결제 금액(원)' : '청구 금액(원)'}
            key="orderPrice"
            dataIndex="orderPrice"
            render={(orderPrice: number, record: any) => {
              if (insuranced) {
                return `${record.totalPrice.toLocaleString()}원`;
              }
              return `(${record.totalPrice.toLocaleString()}원)${orderPrice.toLocaleString()}원`;
            }}
          />
        </Table>
      </STableBox>
      <STableBottomBox>
        {notPaid && (
          <SImportExcelBox>
            <Button disabled={!active} onClick={loadClipboardData} type="text">
              <SImportExcelText>붙여넣기</SImportExcelText>
            </Button>
          </SImportExcelBox>
        )}
        <Card title="닥터차 부품 추천 이유">
          <Input.TextArea
            value={recommend}
            onChange={e => setRecommend(e.target.value)}
            onBlur={handleBlurReason}
          />
        </Card>
        <STotalContainer style={{ ...canceldTableStyle, marginTop: '40px' }}>
          <STotalBox>
            <STotalItemBox style={{ width: '540px' }}>
              <STotalHeader>상품 총 금액 (원)</STotalHeader>
              <STotalItem>{order.totalItemAmount.toLocaleString()}</STotalItem>
            </STotalItemBox>
          </STotalBox>
        </STotalContainer>
        <STotalContainer style={canceldTableStyle}>
          <STotalBox>
            <STotalItemBox>
              <STotalHeader>추가할인 (- 원)</STotalHeader>
              <STotalItem onClick={handleUpdateAdditionalDiscount}>
                {updatingAdditionalDiscount ? (
                  <Input
                    onChange={handleChangeAdditionalDiscount}
                    autoFocus
                    onBlur={handleBlurAdditionalDiscount}
                    onPressEnter={handleBlurAdditionalDiscount}
                    value={updatedAdditionalDiscount}
                  />
                ) : (
                  <span style={{ color: '#FF4D4F' }}>
                    {updatedAdditionalDiscount > 0 && (
                      <>- {updatedAdditionalDiscount.toLocaleString()}</>
                    )}
                  </span>
                )}
              </STotalItem>
            </STotalItemBox>
            <STotalItemBox>
              <STotalHeader>운임비 (원)</STotalHeader>
              <STotalItem onClick={handleUpdateShippingFee}>
                {updatingShippingCharge ? (
                  <Input
                    onChange={handleChangeShippingFee}
                    autoFocus
                    onBlur={handleUpdateShippingCharge}
                    onPressEnter={handleUpdateShippingCharge}
                    value={updatedShippingCharge}
                  />
                ) : (
                  updatedShippingCharge.toLocaleString()
                )}
              </STotalItem>
            </STotalItemBox>
            <STotalItemBox>
              <STotalHeader>
                {!insuranced ? '총 결제 금액(원)' : '총 청구 금액(원)'}
              </STotalHeader>
              <STotalItem>
                {order.paymentAmount.toLocaleString()}
                {order.payment?.status === PaymentStatus.Paid && (
                  <Tag style={{ marginLeft: '12px' }} color={'geekblue'}>
                    결제완료
                  </Tag>
                )}
              </STotalItem>
            </STotalItemBox>
          </STotalBox>
        </STotalContainer>
        {insuranced && order.vehicle && order.__typename === 'InsurancedOrder' && (
          <STotalContainer>
            <STotalBox>
              <STotalItemBox style={{ width: '270px' }}>
                <STotalHeader>캐시백 적용률</STotalHeader>
                <STotalItem
                  style={{ color: '#48CF7C' }}
                  onClick={handleUpdateCashbackRate}
                >
                  {updatingCashbackRate ? (
                    <Input
                      onChange={handleChangeCashbackRate}
                      autoFocus
                      onBlur={handleBlurCashbackRate}
                      onPressEnter={handleBlurCashbackRate}
                      value={Number((updatedCashbackRate * 100).toFixed(2))}
                    />
                  ) : (
                    <>
                      {`${order.vehicle.brand.name} ${Number(
                        (order.cashbackRate * 100).toFixed(2),
                      )}%`}
                    </>
                  )}
                </STotalItem>
              </STotalItemBox>
              <STotalItemBox style={{ width: '270px' }}>
                <STotalHeader>캐시백 예상금액(원)</STotalHeader>
                <STotalItem style={{ color: '#48CF7C' }}>
                  {`${order.cashbackAmount.toLocaleString()}원`}
                </STotalItem>
              </STotalItemBox>
            </STotalBox>
          </STotalContainer>
        )}
        {paymentActionButton}
      </STableBottomBox>
      <ShippingConfirmModal
        handleClose={() => {
          setOpenShippingModal(false);
        }}
        sendPayment={handleClickSendPayment}
        visible={openedShippingModal}
        companies={companies}
      />
      <Modal
        className="blue-title-modal"
        title={order.name}
        width={387}
        visible={estimateConfirmModalVisible}
        onCancel={() => setEstimateConfirmModalVisible(false)}
        onOk={async () => {
          await sendEstimate();
          setEstimateConfirmModalVisible(false);
          counselWindowCache.updateOrder(order.id, order, counselId);
        }}
        okText="이대로 견적서 보내기"
      >
        <SendEstimateConfirmModal
          vehicleName={
            vehicle
              ? `${vehicle.shortModelName}${
                  vehicle.registeredYear ? `/${vehicle.registeredYear}` : ''
                }`
              : '(알수없음)'
          }
          plateNumber={vehicle ? (vehicle.plateNumber as string) : '(알수없음)'}
          insurance={insuranced}
          items={order.items.map(item => ({
            itemId: item.code,
            name: item.name,
            discountRate: item.discountRate,
            price: item.price,
            quantity: item.quantity,
            totalAmount: item.totalAmount,
            paymentAmount: item.paymentAmount,
          }))}
          shippingPrice={order.shippingCharge}
          recommendReason={order.recommendedReason as string | undefined}
          additionalDiscount={updatedAdditionalDiscount}
          totalItemAmount={order.totalItemAmount}
          paymentAmount={order.paymentAmount}
          cashbackRate={
            order.__typename === 'InsurancedOrder'
              ? Number((order.cashbackRate * 100).toFixed(2))
              : undefined
          }
          cashbackAmount={
            order.__typename === 'InsurancedOrder'
              ? order.cashbackAmount
              : undefined
          }
        />
      </Modal>
      {order.payment && (
        <CancelPaymentModal
          handleClose={() => {
            setOpenCancelPaymentModal(false);
          }}
          cancelPayment={handleCancelPayment}
          orderId={order.id}
          paymentId={order.payment.id}
          visible={openedCancelPaymentModal}
        />
      )}
      {order.shipping && (
        <ShippingUpdateModal
          updatable={active}
          handleClose={() => setOpenShippingUpdateModal(false)}
          shipping={order.shipping}
          updateShipping={(data: UpdateShippingData) =>
            updateShippingData(data)
          }
          visible={openedShippingUpdateModal}
        />
      )}
    </SContainer>
  );
};

export default PaymentTable;

const TAB_PAN_TOP_GAP = 171;

const SContainer = styled.div`
  padding: 0 32px 24px 32px;
  height: calc(100vh - ${TAB_PAN_TOP_GAP}px);
  overflow-y: auto;
`;

const STopButtonsBox = styled.div<{ paid: boolean }>`
  width: 100%;
  padding-top: 28px;
  display: flex;
  justify-content: ${props => (props.paid ? 'space-between' : 'flex-start')};
  ${props => (props.paid ? 'margin-bottom: 25px;' : '')}
`;

const SShippingInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #434343;
`;

const SShippingConsoleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SShipingConsoleInfo = styled.div`
  display: flex;
`;

const SRejectedReasonBox = styled.div`
  width: 100%;
  background-color: #fff7e6;
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  gap: 6px;
  padding: 12px 22px 12px 0;
`;

const SRejectReasonTitle = styled.span`
  margin-left: 20px;
  font-size: 14px;
  color: #fa541c;
`;

const SRejectReasonText = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 44px;
`;

const SPopoverContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SPopoverText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const SShippingConsoleText = styled.span<{
  underline: boolean;
  link?: boolean;
}>`
  ${props =>
    props.underline ? 'text-decoration: underline; cursor: pointer;' : ''};
  ${props => (props.link ? 'color: #096DD9' : 'color: #434343')};
  font-size: 14px;
  margin-right: 12px;
`;

const STableBox = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const STableBottomBox = styled.div`
  width: 100%;
`;

const SImportExcelBox = styled.div`
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
`;

const SImportExcelText = styled.span`
  font-size: 14px;
  color: #1890ff;
`;

const STotalContainer = styled.div`
  height: 112px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const STotalBox = styled.div`
  height: 112px;
  display: flex;
`;

const STotalItemBox = styled.div`
  width: 180px;
  height: 112px;
`;

const STotalHeader = styled.div`
  height: 56px;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #f0f0f0;
`;

const STotalItem = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
`;

const SSubmitBox = styled.div`
  margin-top: 37px;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;
`;

const SPaidBottomBox = styled.div`
  margin-top: 37px;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
`;
