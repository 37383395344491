import { Input, InputRef, notification } from 'antd';
import React from 'react';
import { TableCellProps } from 'react-virtualized';
import styled, { css } from 'styled-components';

import { useUpdateCounselMemoMutation } from '@utils/client';

interface MemoCellProps extends TableCellProps {
  disabled?: boolean;
}
const MemoCell: React.FC<MemoCellProps> = ({ cellData, rowData, disabled }) => {
  const input = React.useRef<InputRef | null>(null);
  const [updateCounselMutation] = useUpdateCounselMemoMutation();
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState<string>(cellData || '');

  const updateCounselMemo = async (counselId: string, memo: string) => {
    if (memo.length < 1 || memo.length > 1000) {
      notification.error({
        message: '메모는 1-1000자 사이로 입력 가능합니다.',
      });
      return;
    }
    updateCounselMutation({
      variables: {
        counselId,
        data: {
          memo,
        },
      },
    });
  };

  React.useEffect(() => {
    setValue(cellData || '');
  }, [cellData]);

  React.useEffect(() => {
    if (editing) input.current?.focus();
  }, [editing]);

  const save = async () => {
    setEditing(false);

    try {
      const { id } = rowData;
      if (!id) {
        return;
      }

      await updateCounselMemo(id, value);
    } catch (error) {
      setValue(cellData);
    }
  };

  return !disabled && editing ? (
    <Input.TextArea
      ref={input}
      size="small"
      value={value}
      onChange={event => setValue(event.target.value)}
      onBlur={save}
      onPressEnter={save}
      onDoubleClick={event => event.stopPropagation()}
    />
  ) : (
    <SCell>
      <SCellText
        disabled={disabled}
        title={value}
        onClick={() => setEditing(true)}
      >
        {value || '-'}
      </SCellText>
    </SCell>
  );
};

export default MemoCell;

const SCell = styled.div`
  cursor: pointer;
`;
const SCellText = styled.p<{ disabled?: boolean }>`
  padding: 2px 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${props =>
    !props.disabled &&
    css`
      ${SCell}:hover & {
        cursor: text;
        padding: 1px 6px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: white;
      }
    `}
`;
