import { gql } from '@apollo/client';
import { Col, notification, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import CounselInfo from '@components/CounselInfo';
import CounselRoom from '@components/CounselRoom';
import { MessageInterface } from '@components/CounselRoom/Message';
import TaskPad from '@components/TaskPad';
import { useMe } from '@hooks';
import {
  CounselRoomFragment,
  CounselRoomFragmentDoc,
  UpdateCounselMembersFragmentDoc,
  UpdateCounselMessageConnectionFragmentDoc,
  useAddedUserOnCounselWindowSubscription,
  useCounselWindowTitleQuery,
  useNewMessageOnCounselSubscription,
  useRemovedUserOnCounselWindowSubscription,
  useUpdatedCounselWindowSubscription,
  useUpdatedCursorOnCounselSubscription,
} from '@utils/client';
import { COUNSEL_STATE } from '@utils/constants';
import { messageStringifier } from '@utils/formatters';
import { COUNSEL_WINDOW_USER } from '@utils/fragments';
import { notify } from '@utils/notification';

gql`
  fragment updateCounselMessageConnection on Counsel {
    messageConnection {
      __typename
      edges {
        __typename
        cursor
        node {
          id
        }
      }
    }
  }
`;

gql`
  ${COUNSEL_WINDOW_USER}
  fragment updateCounselMembers on Counsel {
    members {
      ...counselWindowUser
    }
  }
`;

gql`
  query counselWindowTitle($counselId: ID!) {
    counsel(counselId: $counselId) {
      ...counselWindowTitleData
    }
  }
`;

gql`
  subscription updatedCounselWindow($counselId: ID!) {
    updatedCounsel(counselId: $counselId) {
      ...counselWindowTitleData
    }
  }
`;

gql`
  subscription updatedCursorOnCounsel($counselId: ID!) {
    updatedCursorOnCounsel(counselId: $counselId) {
      id
      counsel {
        id
        cursors {
          id
        }
      }
      user {
        ...counselWindowUser
      }
      message {
        ...counselRoomMessage
      }
    }
  }
`;

gql`
  subscription newMessageOnCounsel($counselId: ID!) {
    newMessageOnCounsel(counselId: $counselId) {
      ...counselRoomMessage
    }
  }
`;

gql`
  subscription addedUserOnCounselWindow($counselId: ID!) {
    addedUserOnCounsel(counselId: $counselId) {
      ...counselWindowUser
    }
  }
`;

gql`
  subscription removedUserOnCounselWindow($counselId: ID!) {
    removedUserOnCounsel(counselId: $counselId) {
      ...counselWindowUser
    }
  }
`;

interface CounselWindowPresenterProps {
  className?: string;
  counselId: string;
}
const CounselWindowPresenter: React.FC<CounselWindowPresenterProps> = ({
  className,
  counselId,
}) => {
  const [title, setTitle] = React.useState<string>('닥터차 상담');
  const [showTaskPad, setShowTaskPad] = React.useState(true);
  const { data, loading, error } = useCounselWindowTitleQuery({
    variables: { counselId },
  });

  React.useEffect(() => {
    if (loading) setTitle('로딩중...');
    else if (error) setTitle('오류 발생');
    else if (data) {
      const index = data.counsel.index;
      const name = data.counsel.counselee?.nickname || '고객 정보 없음';
      const model = data.counsel?.vehicle?.shortModelName
        ? ` (${data.counsel.vehicle?.shortModelName})`
        : '';
      const type = COUNSEL_STATE[data.counsel.status].title;
      const unreads = data.counsel.unreadCount
        ? `(${data.counsel.unreadCount})`
        : '';

      setTitle(`${unreads} #${index} ${name} ${model} - ${type}`);
    }
  }, [data, loading, error]);

  const taskPadSpan = showTaskPad ? 16 : 0;
  const toggleTaskPad = React.useCallback(() => {
    setShowTaskPad(showTaskPad => {
      window.resizeTo(
        showTaskPad ? window.innerWidth / 3 : window.innerWidth * 3,
        window.outerHeight,
      );

      return !showTaskPad;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {data?.counsel && <ChatSubscriber counselId={counselId} />}
      <SCounselWindowPresenter className={className}>
        <SColumn
          span={24 - taskPadSpan}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <SCounselInfo
            counselId={counselId}
            showTaskPad={showTaskPad}
            toggleTaskPad={toggleTaskPad}
          />
          <SCounselRoom counselId={counselId} />
        </SColumn>
        <SColumn span={taskPadSpan}>
          <STaskPad counselId={counselId} />
        </SColumn>
      </SCounselWindowPresenter>
    </>
  );
};

interface ChatSubscriberProps {
  counselId: string;
}
const ChatSubscriber: React.FC<ChatSubscriberProps> = ({ counselId }) => {
  const { id } = useMe();

  // Subscription hook with custom cache update for new Message on CounselWindow
  useNewMessageOnCounselSubscription({
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    variables: { counselId },
    onSubscriptionData: ({ subscriptionData: { data }, client: { cache } }) => {
      if (!data) {
        return;
      }

      if (!data.newMessageOnCounsel.author) {
        return;
      }

      if (
        data.newMessageOnCounsel.author.id === id &&
        data.newMessageOnCounsel.__typename === 'TextMessage'
      ) {
        return;
      }

      const cachedCounsel = cache.readFragment<CounselRoomFragment>({
        id: `Counsel:${counselId}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
      });

      if (data?.newMessageOnCounsel && cachedCounsel) {
        const newMessage = data.newMessageOnCounsel;
        const updatedEdges = [
          ...cachedCounsel.messageConnection.edges,
          { node: newMessage, cursor: 'new', __typename: 'MessageEdge' },
        ];

        cache.writeFragment({
          id: `Counsel:${counselId}`,
          fragment: UpdateCounselMessageConnectionFragmentDoc,
          data: {
            id: counselId,
            messageConnection: {
              ...cachedCounsel.messageConnection,
              edges: updatedEdges,
            },
          },
          broadcast: true,
        });
        const isFocus = window.document.hasFocus();
        if (!isFocus) {
          if (newMessage.author?.id !== id) {
            notify({
              title: '새로운 메시지',
              body: messageStringifier(newMessage as MessageInterface),
              icon: newMessage.author?.avatar?.url || undefined,
              onClick: () => {
                window.focus();
                // updateCursor(newMessage.id);
              },
            });
          }
        }
      }
    },
  });

  // Subscription hook for update of Cursors
  useUpdatedCursorOnCounselSubscription({
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    variables: { counselId },
  });

  // Subscription hook for update of counsel informations, which makes change of title of tab/page
  useUpdatedCounselWindowSubscription({
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    variables: { counselId },
  });

  // Subscrption hook for Added User on Counsel
  useAddedUserOnCounselWindowSubscription({
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    variables: { counselId },
    // TODO : check adding user, removing user subscription on CounselWindow
    onSubscriptionData: ({ subscriptionData: { data }, client: { cache } }) => {
      const cachedCounsel = cache.readFragment<CounselRoomFragment>({
        id: `Counsel:${counselId}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
      });

      if (data?.addedUserOnCounsel && cachedCounsel) {
        const newMember = data.addedUserOnCounsel;
        cache.writeFragment({
          id: `Counsel:${counselId}`,
          fragment: UpdateCounselMembersFragmentDoc,
          data: {
            members: [...cachedCounsel.members, newMember],
          },
          fragmentName: 'updateCounselMembers',
        });
      }
    },
  });

  // Subscrption hook for Removed User on Counsel
  useRemovedUserOnCounselWindowSubscription({
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    variables: { counselId },
    onSubscriptionData: ({ subscriptionData: { data }, client: { cache } }) => {
      const cachedCounsel = cache.readFragment<CounselRoomFragment>({
        id: `Counsel:${counselId}`,
        fragment: CounselRoomFragmentDoc,
        fragmentName: 'counselRoom',
      });
      if (data?.removedUserOnCounsel && cachedCounsel) {
        const removedMember = data.removedUserOnCounsel;
        cache.writeFragment({
          id: `Counsel:${counselId}`,
          fragment: UpdateCounselMembersFragmentDoc,
          data: {
            members: cachedCounsel.members.filter(
              member => member.id !== removedMember.id,
            ),
          },
        });
        notification.info({
          message: removedMember.nickname + '님이 상담에서 나갔습니다',
        });
      }
    },
  });

  return null;
};

export default CounselWindowPresenter;

const SCounselWindowPresenter = styled(Row)`
  width: 100%;
  height: 100vh;
`;
const SColumn = styled(Col)`
  height: 100%;
`;
const SCounselInfo = styled(CounselInfo)`
  padding: 12px;
`;
const SCounselRoom = styled(CounselRoom)`
  flex: 1;
  overflow-y: hidden;
`;
const STaskPad = styled(TaskPad)`
  height: 100%;
`;
