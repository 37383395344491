import { PaperClipOutlined } from '@ant-design/icons';
import { gql, useApolloClient } from '@apollo/client';
import { Button, Space, Dropdown, Menu, Modal, Popconfirm } from 'antd';
import React from 'react';
import styled from 'styled-components';

import EstimateTimer from './EstimateTimer';
import QueuedButton from './QueuedButton';
import TextInput from './TextInput';

import { useFileUpload, useMe, useMessage } from '@hooks';
import { colors } from '@styles';
import {
  CounselStatus,
  FileType,
  QueueReason,
  useMessageInputQuery,
  Counsel,
  MessageInputQuery,
} from '@utils/client';
import { QueuedReasonMap } from '@utils/formatters';

const MESSAGE_INPUT = gql`
  query messageInput($counselId: ID!) {
    counsel(counselId: $counselId) {
      id
      status
      queueReason
      queuedFrom
      bookmarkedUntil
      orders {
        id
        expiresAt
      }
    }
  }
`;

type QueueCounselFragment = {
  __typename?: 'Counsel' | undefined;
} & Pick<
  Counsel,
  'id' | 'status' | 'queueReason' | 'queuedFrom' | 'bookmarkedUntil'
>;

type MessageInputPresenterProps = {
  className?: string;
  counselId: string;
  finishCounsel: (counselId: string) => Promise<{ id: string } | null>;
  queueCounsel: (
    counselId: string,
    reason?: QueueReason,
  ) => Promise<QueueCounselFragment | null>;
  unqueueCounsel: (counselId: string) => Promise<QueueCounselFragment | null>;
  sendAppReviewMessage: (counselId: string) => Promise<{ id: string } | null>;
  restartCounsel: (counselId: string) => Promise<void>;
  stopEstimateAndStartCounsel: (counselId: string) => Promise<void>;
};
const MessageInputPresenter: React.FC<MessageInputPresenterProps> = ({
  className,
  counselId,
  queueCounsel,
  unqueueCounsel,
  finishCounsel,
  sendAppReviewMessage,
  restartCounsel,
  stopEstimateAndStartCounsel,
}) => {
  const { viewOnly } = useMe();
  const { upload } = useFileUpload();
  const { sendFileMessage } = useMessage({ counselId });
  const { data, refetch } = useMessageInputQuery({ variables: { counselId } });
  const { cache } = useApolloClient();
  const fileInput = React.useRef<HTMLInputElement | null>(null);

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const valid = event.target.validity.valid;
    const file = event.target.files?.[0];
    if (valid && file) {
      const type = file.type.split('/')[0];

      try {
        if (type !== 'image' && type !== 'video') {
          alert(`'${type}'은 지원하지 않는 파일 형식입니다.`);
          return;
        }

        const uploadedFile = await upload(file);
        sendFileMessage({
          type: type === 'image' ? FileType.Image : FileType.Video,
          url: uploadedFile.url,
        });
      } catch {
        alert('파일 업로드에 실패했습니다.');
      } finally {
        if (fileInput.current) {
          fileInput.current.value = '';
        }
      }
    }
  };

  const updateCache = React.useCallback(
    (counsel: QueueCounselFragment) => {
      const cachedMessageInputCounsel = cache.readQuery<MessageInputQuery>({
        query: MESSAGE_INPUT,
        variables: {
          counselId,
        },
      });

      if (!cachedMessageInputCounsel) {
        return;
      }

      const updatedMessageInputCounsel = {
        ...cachedMessageInputCounsel.counsel,
        status: counsel.status,
        queueReason: counsel.queueReason,
        queuedFrom: counsel.queuedFrom,
        bookmarkedUntil: counsel.bookmarkedUntil,
      };

      cache.writeQuery<MessageInputQuery>({
        query: MESSAGE_INPUT,
        variables: {
          counselId,
        },
        data: {
          counsel: updatedMessageInputCounsel,
        },
      });
    },
    [cache, counselId],
  );

  const handleQueueCounsel = React.useCallback(
    async (reason?: QueueReason) => {
      const queuedCounsel = await queueCounsel(counselId, reason);

      if (!queuedCounsel) {
        return;
      }

      updateCache(queuedCounsel);
    },
    [counselId, queueCounsel, updateCache],
  );

  const handleUnqueueCounsel = React.useCallback(async () => {
    const unqueuedCounsel = await unqueueCounsel(counselId);
    if (!unqueuedCounsel) {
      return;
    }

    updateCache(unqueuedCounsel);
  }, [counselId, unqueueCounsel, updateCache]);

  const handleClickQueued = React.useCallback(
    (reason?: QueueReason) => {
      Modal.confirm({
        title: '정말 해당 상담을 대기모드로 전환하시겠어요?',
        content: reason ? `이유: ${QueuedReasonMap[reason]}` : '',
        onOk: () => handleQueueCounsel(reason),
      });
    },
    [handleQueueCounsel],
  );

  const handleClickUnqueued = React.useCallback(() => {
    Modal.confirm({
      title: '정말 해당 상담의 대기모드를 종료하시겠어요?',
      onOk: () => handleUnqueueCounsel(),
    });
  }, [handleUnqueueCounsel]);

  const handleClickRestart = React.useCallback(() => {
    Modal.confirm({
      title: '정말 상담을 재진행 하시겠어요?',
      onOk: async () => {
        await restartCounsel(counselId);
        await refetch({
          counselId,
        });
      },
    });
  }, [counselId, refetch, restartCounsel]);

  const dropdownMenu = React.useMemo(() => {
    if (!data) {
      return <></>;
    }

    if (data.counsel.status !== CounselStatus.Queued) {
      return (
        <Menu>
          {/* <Menu.Item
            onClick={() => {
              handleClickQueued(QueueReason.ShippingCheck);
            }}
          >
            배송 지연 확인
          </Menu.Item> */}
          <Menu.Item
            onClick={() => {
              handleClickQueued(QueueReason.ShopCheck);
            }}
          >
            추천 업체 서치
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              handleClickQueued(QueueReason.PriceCheck);
            }}
          >
            견적 조회
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              handleClickQueued(QueueReason.CashbackCheck);
            }}
          >
            캐시백 안내
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              handleClickQueued();
            }}
          >
            일시 정지
          </Menu.Item>
        </Menu>
      );
    } else {
      return (
        <Menu>
          <Menu.Item
            onClick={() => {
              handleClickUnqueued();
            }}
          >
            대기 끝내기
          </Menu.Item>
        </Menu>
      );
    }
  }, [data, handleClickQueued, handleClickUnqueued]);

  const handleFinishCounsel = async (counselId: string) => {
    const counsel = await finishCounsel(counselId);

    if (!counsel) {
      return;
    }

    window.top?.close();
    window.close();
  };

  const handleClickStoreReview = () => {
    Modal.confirm({
      title: '정말 스토어 리뷰를 요청하시겠습니까?',
      onOk: () => sendAppReviewMessage(counselId),
    });
  };

  if (data) {
    const {
      counsel: { status, orders },
    } = data;

    const disabled = viewOnly;
    const inputDisabled =
      disabled ||
      status === CounselStatus.Pending ||
      status === CounselStatus.Estimating ||
      status === CounselStatus.Estimated ||
      status === CounselStatus.Finished;

    const endDisabled =
      disabled ||
      status === CounselStatus.Pending ||
      status === CounselStatus.Finished;

    const estimating =
      status === CounselStatus.Estimating || status === CounselStatus.Estimated;

    return (
      <SMessageInput
        className={`message-input ${className}`}
        direction="vertical"
      >
        <SMessageInputRow>
          <input
            disabled={inputDisabled}
            ref={fileInput}
            type="file"
            name="file"
            accept="image/*, video/*"
            onChange={handleFile}
            style={{ display: 'none' }}
          />
          <PaperClipOutlined
            className="ant-input-group-addon"
            onClick={
              inputDisabled
                ? undefined
                : () => {
                    fileInput.current?.click();
                  }
            }
            style={{
              width: 'initial',
              cursor: inputDisabled ? 'inherit' : 'pointer',
              color: inputDisabled ? colors.TEXT_MUTED : 'inherit',
            }}
          />
          <TextInput counselId={counselId} disabled={inputDisabled} />
        </SMessageInputRow>
        <SMessageInputRow>
          <SMessageInputButtons>
            {status === CounselStatus.Finished ? (
              <Button type="primary" ghost onClick={handleClickRestart}>
                상담 재진행
              </Button>
            ) : estimating ? (
              <Popconfirm
                title="상담을 진행시키겠습니까?"
                okText="확인"
                cancelText="취소"
                onConfirm={() => {
                  stopEstimateAndStartCounsel(counselId);
                }}
              >
                <Button disabled={disabled} type="primary" ghost>
                  상담 진행
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="상담을 완료하시겠습니까?"
                okText="완료"
                cancelText="취소"
                okButtonProps={{ danger: true }}
                onConfirm={() => {
                  handleFinishCounsel(counselId);
                }}
                disabled={endDisabled}
              >
                <Button disabled={disabled} type="ghost" danger>
                  상담 완료
                </Button>
              </Popconfirm>
            )}

            {status !== CounselStatus.Finished && (
              <RightButtons>
                {estimating && orders.length > 0 ? (
                  <EstimateTimer
                    expiredAt={orders[0]?.expiresAt as number | undefined}
                  />
                ) : (
                  <>
                    <Dropdown
                      disabled={inputDisabled}
                      trigger={['click']}
                      overlay={dropdownMenu}
                    >
                      {data.counsel.status === CounselStatus.Queued &&
                      data.counsel.queuedFrom ? (
                        <QueuedButton
                          disabled={inputDisabled}
                          onClick={() => undefined}
                          queuedFrom={data.counsel.queuedFrom}
                          queueReason={data.counsel.queueReason}
                        />
                      ) : (
                        <Button
                          disabled={inputDisabled}
                          type="primary"
                          style={{
                            backgroundColor: inputDisabled ? '#eee' : '#52C41A',
                            border: 'none',
                          }}
                        >
                          대기걸기
                        </Button>
                      )}
                    </Dropdown>
                    <Button
                      disabled={inputDisabled}
                      onClick={handleClickStoreReview}
                    >
                      스토어 리뷰
                    </Button>
                  </>
                )}
              </RightButtons>
            )}
          </SMessageInputButtons>
        </SMessageInputRow>
      </SMessageInput>
    );
  }

  return null;
};

export default React.memo(MessageInputPresenter);

const SMessageInput = styled(Space)``;
const SMessageInputRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const SMessageInputButtons = styled(Space)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 7px;
`;

const RightButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
