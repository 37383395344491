import { Modal, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { ShippingFragment } from './PaymentPresenter';

import { ShippingStatus, UpdateShippingData } from '@utils/client';

type ShippingUpdateModalProps = {
  visible: boolean;
  updatable: boolean;
  handleClose: () => void;
  shipping: ShippingFragment;
  updateShipping: (data: UpdateShippingData) => void;
};

const ShippingUpdateModal: React.FC<ShippingUpdateModalProps> = ({
  handleClose,
  shipping,
  visible,
  updateShipping,
  updatable,
}) => {
  const [destination, setDestination] = React.useState<string>('');
  const [recipient, setRecipient] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => {
    if (!shipping) {
      return;
    }

    setDestination(shipping.destination);
    setRecipient(shipping.recipient);
    setPhone(shipping.contact);
  }, [shipping]);

  const handleClickSaveButton = async () => {
    if (!shipping) {
      handleClose();
      Modal.error({
        title: '해당 배송내역을 찾지 못했습니다.',
      });
      return;
    }

    if (shipping.status !== ShippingStatus.Preparing) {
      handleClose();
      return;
    }

    try {
      setUpdating(true);
      await updateShipping({
        contact: phone,
        recipient,
        destination,
      });
      handleClose();
    } catch (err) {
      console.log(err);
      Modal.error({
        title: '알수없는 오류가 발생했습니다.',
        content: '개발팀에 문의해주세요.',
      });
    } finally {
      setUpdating(false);
    }
  };

  const disabled = React.useMemo(() => {
    if (!shipping) {
      return true;
    }

    return shipping.status !== ShippingStatus.Preparing || !updatable;
  }, [shipping, updatable]);

  return (
    <Modal
      title={'배송지 정보'}
      closable
      visible={visible}
      okText={shipping?.status === ShippingStatus.Preparing ? '저장' : '확인'}
      onOk={handleClickSaveButton}
      onCancel={handleClose}
      confirmLoading={updating}
    >
      <SBody>
        <SRowBox>
          <SLabel>받는곳 :</SLabel>
          <Input
            disabled={disabled}
            onChange={e => setDestination(e.target.value)}
            value={destination}
          />
        </SRowBox>
        <SRowBox>
          <SLabel>받는 사람 :</SLabel>
          <Input
            disabled={disabled}
            onChange={e => setRecipient(e.target.value)}
            value={recipient}
          />
        </SRowBox>
        <SRowBox>
          <SLabel>받는 곳 연락처 :</SLabel>
          <Input
            disabled={disabled}
            onChange={e => setPhone(e.target.value)}
            value={phone}
          />
        </SRowBox>
      </SBody>
    </Modal>
  );
};

export default ShippingUpdateModal;

const SBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const SRowBox = styled.div`
  width: 100%;
  flex: 0 0 32px;
  display: flex;
  margin-bottom: 15px;
`;

const SLabel = styled.div`
  flex: 0 0 96px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 24px;
`;
