import { Input, Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { TaskpadOrderFragment, PaymentStatus } from '@utils/client';
import { paymentStatusFormatter } from '@utils/formatters';

type OrderTabProps = {
  orderName: string;
  status?: PaymentStatus /* | ShippingStatus */;
  opened: boolean;
  updateOrderName: (
    updatedOrderName: string,
  ) => Promise<TaskpadOrderFragment | null>;
};

const OrderTab: React.FC<OrderTabProps> = ({
  orderName,
  opened,
  updateOrderName,
  status,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [editTitle, setEditTitle] = React.useState(orderName);
  const handleClick = () => {
    if (status !== undefined) {
      return;
    }
    if (opened) {
      setEditing(true);
      setEditTitle(orderName);
    }
  };

  const handleChangeEditTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditTitle(e.target.value);
  };

  const save = async () => {
    await updateOrderName(editTitle);
    setEditing(false);
  };

  const badgeMeta = paymentStatusFormatter(status);
  return (
    <Container onClick={handleClick}>
      {editing ? (
        <Input
          autoFocus
          value={editTitle}
          onChange={handleChangeEditTitle}
          onBlur={() => save()}
          onPressEnter={() => save()}
        />
      ) : (
        <>
          {orderName}
          {badgeMeta && (
            <Tag color={badgeMeta.color} style={{ marginLeft: '10px' }}>
              {badgeMeta.text}
            </Tag>
          )}
        </>
      )}
    </Container>
  );
};

export default OrderTab;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
