import {
  AREA_CODES,
  MOBILE_CODES,
  NATIONAL_REPRESENTATIVE_CODES,
} from './constants';

export const isPlateNumber = (plateNumber: string): string | null => {
  const plateNumberRegex = /^[0-9]{2,3}[가-힣]{1}[0-9]{4}/;
  return plateNumberRegex.test(plateNumber) ? plateNumber : null;
};

export const isVin = (vin: string): string | null => {
  return vin.length === 17 ? vin : null;
};

export const isContact = (contact: string): boolean => {
  return (
    /^\d{8,11}$/.test(contact) &&
    (AREA_CODES.includes(contact.substring(0, 2)) ||
      AREA_CODES.includes(contact.substring(0, 3)) ||
      NATIONAL_REPRESENTATIVE_CODES.includes(contact.substring(0, 4)))
  );
};

export const isPhoneNumber = (phoneNumber: string): boolean => {
  return /^01[016789]{1}?([0-9]{3,4})?[0-9]{4}$/.test(phoneNumber);
};

export const validatePhoneNumber = (phoneNumber?: string | null) => {
  if (!phoneNumber) {
    return false;
  }
  return !!phoneNumber.match(
    /^((\+?82)[-]?)?0?1([0|1|6|7|8|9]{1})[-]?\d{3,4}[-]?\d{4}$/,
  );
};

export const istireSize = (
  spec: string,
): { aspectRatio: number; wheelDiameter: number; width: number } | null => {
  const tireSizeRegex = /^[0-9]{3}\/[0-9]{2}R\/[0-9]{2}$/;
  if (!tireSizeRegex.test(spec)) return null;

  const [width, , , aspectRatio, , , wheelDiamter] = spec.split(/(R\/)|(\/)/);

  return {
    aspectRatio: parseInt(aspectRatio),
    wheelDiameter: parseInt(wheelDiamter),
    width: parseInt(width),
  };
};

export const isContainUrl = (text: string) => {
  const splited = text.split(' ');
  const result = [];
  let isContain = false;
  const isWindow = window.navigator.userAgent.includes('Window');

  let newLineCharacter = '\n';

  if (isWindow) {
    newLineCharacter = '\r\n';
  }

  for (const part of splited) {
    const isNewlined = part.includes(newLineCharacter);
    const isUrl = part.includes('http://') || part.includes('https://');
    if (!isContain) {
      isContain = isUrl;
    }

    if (isNewlined && isUrl) {
      const newLineSplited = part.split(newLineCharacter);
      for (const newLined of newLineSplited) {
        result.push({
          isUrl,
          text: newLined,
          newLined: true,
        });
      }
    } else {
      result.push({
        isUrl,
        text: part,
        newLined: false,
      });
    }
  }

  return {
    isContain,
    splited: result,
  };
};
