import ReactGA from 'react-ga4';

import { GA_MEASUREMENT_ID } from './constants';

type Events = {
  send_message_template: {
    title: string;
    counsel_id: string;
    template_id: string;
    message_ids: Array<string>;
  };
};

export const GAInitialize = (userId: string) => {
  ReactGA.initialize(GA_MEASUREMENT_ID, {
    gtagOptions: { user_id: userId },
  });
  ReactGA.set({ crm_id: userId });
};

/**
 * 일반 이벤트 로그 함수
 * @param event 이벤트 이름(특수 이벤트일 경우에 조회안됨)
 * @param params 해당 이벤트에 대응되는 params object
 */
export const sendEvent = <T extends keyof Events>(
  event: T,
  params?: Events[T],
) => {
  ReactGA.event(event, { ...params });
};

export const Tracker = {
  GAInitialize,
  sendEvent,
};
