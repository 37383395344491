import React from 'react';
import styled from 'styled-components';

import { MessageProps } from '../';

import { Maybe, Order } from '@utils/client';

type ItemFragment = {
  name: string;
  price: number;
  quantity: number;
  discountRate: number;
  paymentAmount: number;
  totalAmount: number;
};

type EstimateFragment = {
  name: string;
  modelInfo: string;
  plateNumber: string;
  items: ItemFragment[];
  shippingCharge: number;
  disable: boolean;
  order: Maybe<Order> | undefined;
};

type EstimateMessageProps = {
  estimate: EstimateFragment;
} & MessageProps;

const EstimateMessage: React.FC<EstimateMessageProps> = ({ estimate }) => {
  if (!estimate.order) {
    return null;
  }

  return (
    <SContainer>
      {estimate.disable && (
        <DisableVail>
          <DisableTextBox>
            <DisableText>{`🚫`}</DisableText>
            <DisableText>{`유효하지 않은 견적서입니다`}</DisableText>
          </DisableTextBox>
        </DisableVail>
      )}
      <SHeader>{estimate.order ? estimate.order.name : estimate.name}</SHeader>
      <SBody>
        <SVehicleRow>
          <SLabel>차종/연식</SLabel>
          <SInfoText>{estimate.modelInfo}</SInfoText>
        </SVehicleRow>
        <SDivider marginTop={'9px'} marginBottom={'9px'} />
        <SVehicleRow>
          <SLabel>차량번호</SLabel>
          <SInfoText>{estimate.plateNumber}</SInfoText>
        </SVehicleRow>
        <SDivider marginTop={'9px'} />
        {estimate.items.map((item, index) => {
          return (
            <SItemContainer key={index}>
              <SItemRow>
                <SLabel>상품명</SLabel>
                <SInfoText>{item.name}</SInfoText>
              </SItemRow>
              <SItemRow>
                <SLabel>개당 가격(수량)</SLabel>
                <SInfoText>{`${item.price.toLocaleString()}원 (${
                  item.quantity
                }개)`}</SInfoText>
              </SItemRow>
              {item.discountRate > 0 && (
                <SItemRow>
                  <SLabel>합계</SLabel>
                  <SGrayText>{`${item.totalAmount.toLocaleString()}원`}</SGrayText>
                </SItemRow>
              )}

              <SItemRow>
                <SLabel>결제 금액</SLabel>
                <SInfoText style={{ fontWeight: 'bold' }}>
                  {`${item.paymentAmount.toLocaleString()}원`}
                  {item.discountRate > 0 && (
                    <span style={{ color: '#48CF7C', marginLeft: '4px' }}>
                      -{(item.discountRate * 100).toFixed()}%
                    </span>
                  )}
                </SInfoText>
              </SItemRow>
            </SItemContainer>
          );
        })}
        <SDivider marginTop={'10px'} marginBottom={'20px'} />
        <SItemRow>
          <SResultLabel>상품 총 금액</SResultLabel>
          <SInfoText>
            {estimate.order.totalItemAmount.toLocaleString()}원
          </SInfoText>
        </SItemRow>
        {estimate.order.discountAmount > 0 && (
          <SItemRow>
            <SResultLabel>추가 할인 금액</SResultLabel>
            <SInfoText style={{ color: '#48CF7C' }}>
              -{estimate.order.discountAmount.toLocaleString()}원
            </SInfoText>
          </SItemRow>
        )}

        <SItemRow>
          <SResultLabel>운임비</SResultLabel>
          <SInfoText>{estimate.shippingCharge.toLocaleString()}원</SInfoText>
        </SItemRow>

        <STotalRow style={{ marginTop: '22px' }}>
          <STotalLabel>총 결제금액</STotalLabel>
          <SBlueText style={{ fontWeight: 'bold' }}>
            {estimate.order.paymentAmount.toLocaleString()}원
          </SBlueText>
        </STotalRow>
        <SExtRow>*세금포함가</SExtRow>
      </SBody>
    </SContainer>
  );
};

export default EstimateMessage;

const SContainer = styled.div`
  width: 300px;
  height: 100%;
  height: fit-content;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
`;

const DisableVail = styled.div`
  width: 300px;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  position: absolute;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const DisableTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DisableText = styled.span`
  color: #ffffff;
  font-size: 16px;
`;

const SHeader = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  background-color: #0056b4;
  align-items: center;
  padding-left: 16px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 20px 20px 0 0;
`;

const SBody = styled.div`
  padding: 18px 20px 18px 20px;
  background-color: #ffffff;
  border-radius: 0 0 20px 20px;
  height: fit-content;
`;

const SVehicleRow = styled.div`
  display: flex;
  align-items: center;
`;

const SLabel = styled.span`
  color: #a4aaae;
  font-size: 12px;
  min-width: 80px;
  margin-right: 10px;
`;

const SInfoText = styled.span`
  color: #000000;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
`;

const SGrayText = styled.span`
  color: #a4aaae;
  text-decoration: line-through;
  font-size: 16px;
`;

const SBlueText = styled.span`
  color: #007aff;
  font-size: 16px;
`;

const SDivider = styled.div<{ marginTop?: string; marginBottom?: string }>`
  width: 260px;
  height: 1px;
  border-bottom: 1px solid #d9d9d9;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
`;

const SItemContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const SItemRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const STotalRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SResultLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  min-width: 80px;
  color: #000000;
`;

const STotalLabel = styled.span`
  color: #000000;
  font-weight: bold;
  font-size: 16px;
`;

const SExtRow = styled.div`
  display: flex;
  height: 23px;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  color: #0056b4;
`;
