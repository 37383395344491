import React from 'react';
import styled from 'styled-components';

type AdditionalTableHeaderProps = {
  title: string | React.ReactNode;
  additional?: React.ReactNode;
};

const AdditionalTableHeader: React.FC<AdditionalTableHeaderProps> = ({
  title,
  additional,
}) => {
  return (
    <TableColumnHeaderBox>
      <TableHeader>{title}</TableHeader>
      {additional}
    </TableColumnHeaderBox>
  );
};

export default AdditionalTableHeader;

const TableColumnHeaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; ;
`;

const TableHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;
