import { Button } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { QueueReason, Maybe } from '@utils/client';
import { QueuedReasonMap } from '@utils/formatters';

type QueuedButtonProps = {
  disabled?: boolean;
  queuedFrom: number;
  queueReason?: Maybe<QueueReason>;
  onClick: () => void;
};

const QueuedButton: React.FC<QueuedButtonProps> = ({
  disabled,
  queuedFrom,
  queueReason,
  onClick,
}) => {
  const [now, setNow] = React.useState<dayjs.Dayjs | null>(null);

  React.useEffect(() => {
    if (now === null) {
      setInterval(() => {
        setNow(dayjs());
      }, 1000);
    }
  }, [now]);

  const queuedTime = React.useMemo(() => {
    if (!now) {
      return '';
    }
    const queuedFromDay = dayjs(queuedFrom);
    const diff = now.diff(queuedFromDay, 'seconds');

    const hour = Math.floor(diff / 3600);
    const min = Math.floor((diff - hour * 3600) / 60);
    const sec = diff - hour * 3600 - min * 60;

    const formattedHour = hour < 10 ? `0${hour}` : hour.toString();
    const formattedMin = min < 10 ? `0${min}` : min.toString();
    const formattedSec = sec < 10 ? `0${sec}` : sec.toString();
    return `${formattedHour}:${formattedMin}:${formattedSec}`;
  }, [now, queuedFrom]);

  return (
    <Button disabled={disabled} onClick={onClick} type="primary" danger>
      ⏳ {queueReason ? `${QueuedReasonMap[queueReason]} 대기중` : '일시정지됨'}{' '}
      {queuedTime}
    </Button>
  );
};

export default QueuedButton;
