import { gql } from '@apollo/client';

export const COUNSEL_TABLE_ROW_USER = gql`
  fragment CounselTableRowUser on User {
    __typename
    id
    avatar {
      url
    }
    nickname
  }
`;

export const COUNSEL_TABLE_ROW_MESSAGE = gql`
  fragment CounselTableRowMessage on Message {
    __typename
    id
    author {
      avatar {
        url
      }
    }
    createdAt
    ... on AreaInputMessage {
      counsel {
        id
      }
    }
    ... on EndMessage {
      endedBy {
        role
        nickname
      }
    }
    ... on SuspendedMessage {
      id
    }
    ... on EnterMessage {
      inviter {
        id
        nickname
      }
      invitee {
        id
        nickname
      }
    }
    ... on EstimateMessage {
      counsel {
        id
        counselee {
          id
          nickname
        }
      }
    }
    ... on FileMessage {
      file {
        __typename
        description
        url
        ... on Image {
          metadata {
            width
            height
          }
        }
        ... on Video {
          metadata {
            width
            height
            duration
          }
        }
      }
    }
    ... on InvoiceMessage {
      id
    }
    ... on LeaveMessage {
      user {
        id
        nickname
      }
    }
    ... on RestartMessage {
      id
    }
    ... on TextMessage {
      body
    }

    ... on QueueMessage {
      reason
    }

    ... on CompanyMessage {
      company {
        id
        name
      }
    }

    ... on VinInputMessage {
      vehicle {
        id
      }
    }

    ... on AppReviewMessage {
      id
    }
  }
`;

export const COUNSEL_TABLE_ROW_MESSAGE_CONNECTION = gql`
  fragment CounselTableRowMessageConnection on MessageConnection {
    __typename
    edges {
      node {
        ...CounselTableRowMessage
      }
    }
  }
`;

export const COUNSEL_TABLE_ROW = gql`
  ${COUNSEL_TABLE_ROW_USER}
  fragment CounselTableRow on Counsel {
    __typename
    id
    orders {
      payment {
        status
      }
    }
    counselee {
      ...CounselTableRowUser
    }
    index
    memo
    queueReason
    createdAt
    areas {
      zone {
        province
        district
        neighborhood
      }
    }
    body
    type {
      id
      name
    }
    vehicle {
      id
      brand {
        id
        logo {
          url
        }
      }
      plateNumber
      fullModelName
    }
    files {
      __typename
      ... on Image {
        url
      }
      ... on Video {
        url
      }
    }
    post {
      id
      hashtags {
        id
        name
      }
    }
    creator {
      id
      nickname
      avatar {
        url
      }
    }
    members {
      ...CounselTableRowUser
    }
    messageConnection(last: 1) {
      __typename
      edges {
        node {
          __typename
          id
          author {
            id
            avatar {
              url
            }
          }
          createdAt
          ... on AreaInputMessage {
            counsel {
              id
            }
          }
          ... on EndMessage {
            endedBy {
              role
              nickname
            }
          }
          ... on SuspendedMessage {
            id
          }
          ... on EnterMessage {
            inviter {
              id
              nickname
            }
            invitee {
              id
              nickname
            }
          }
          ... on EstimateMessage {
            counsel {
              id
              counselee {
                nickname
              }
            }
          }
          ... on FileMessage {
            file {
              __typename
              description
              url
              ... on Image {
                metadata {
                  width
                  height
                }
              }
              ... on Video {
                metadata {
                  width
                  height
                  duration
                }
              }
            }
          }
          ... on InvoiceMessage {
            id
          }
          ... on LeaveMessage {
            user {
              id
              nickname
            }
          }
          ... on RestartMessage {
            id
          }
          ... on TextMessage {
            body
          }

          ... on QueueMessage {
            reason
          }

          ... on CompanyMessage {
            company {
              id
              name
            }
          }
          ... on VinInputMessage {
            vehicle {
              id
            }
          }
          ... on AppReviewMessage {
            id
          }
        }
      }
    }
    status
    unreadCount
    createdAt
  }
`;

export const COUNSEL_ROOM_MESSAGE = gql`
  fragment counselRoomMessage on Message {
    __typename
    id
    author {
      id
      role
      nickname
      avatar {
        url
      }
    }
    counsel {
      counselee {
        id
        nickname
      }
    }
    createdAt
    status
    ... on AppReviewMessage {
      id
    }
    ... on AreaInputMessage {
      counsel {
        id
      }
    }
    ... on DeletedMessage {
      id
      deletedAt
    }
    ... on EndMessage {
      endedBy {
        role
        nickname
      }
    }
    ... on SuspendedMessage {
      idle
      willBeFinishedAt
    }
    ... on EnterMessage {
      inviter {
        id
        nickname
      }
      invitee {
        id
        nickname
      }
    }
    ... on EstimateMessage {
      counsel {
        id
        vehicle {
          id
          plateNumber
          registeredYear
          shortModelName
        }
      }
      estimate {
        name
        amount
        latest
        items {
          name
          price
          quantity
          discountRate
          totalAmount
          paymentAmount
        }
        order {
          name
          expiresAt
          discountAmount
          totalItemAmount
          paymentAmount
        }
        shippingCharge
      }
    }
    ... on FileMessage {
      file {
        __typename
        description
        url
        ... on Image {
          metadata {
            width
            height
          }
        }
        ... on Video {
          metadata {
            width
            height
            duration
          }
        }
      }
    }

    ... on InvoiceMessage {
      counsel {
        id
      }
      order {
        id
        amount
        createdAt
        expiresAt
        items {
          code
          name
          price
          quantity
        }
        name
        payment {
          id
          amount
          completedAt
          status
        }
        shippingCharge
        shipping {
          id
          status
          trackingId
          contact
          destination
          method
          recipient
          carrier
          deliveredAt
        }
      }
    }
    ... on LeaveMessage {
      user {
        id
        role
        nickname
        avatar {
          url
        }
      }
    }
    ... on PlateNumberInputMessage {
      id
    }
    ... on RestartMessage {
      idle
    }
    ... on TextMessage {
      body
    }

    ... on QueueMessage {
      reason
    }

    ... on CompanyMessage {
      company {
        id
      }
    }

    ... on VinInputMessage {
      id
    }
  }
`;

export const COUNSEL_WINDOW_TITLE_DATA = gql`
  fragment counselWindowTitleData on Counsel {
    id
    index
    status
    unreadCount
    counselee {
      id
      nickname
    }
    vehicle {
      id
      shortModelName
    }
  }
`;

export const COUNSEL_WINDOW_USER = gql`
  fragment counselWindowUser on User {
    id
    role
    nickname
    avatar {
      url
    }
  }
`;

export const TASKPAD_ORDER = gql`
  fragment taskpadOrder on OrderBase {
    id
    __typename
    amount
    createdAt
    expiresAt
    discountAmount
    totalItemAmount
    totalAmount
    paymentAmount
    vehicle {
      id
      brand {
        id
        name
      }
    }
    items {
      code
      name
      discountRate
      price
      quantity
      totalAmount
      paymentAmount
    }
    name
    payment {
      id
      amount
      completedAt
      cancelledAt
      status
    }
    shippingCharge
    recommendedReason
    rejectedReason
    shipping {
      id
      status
      trackingId
      contact
      destination
      method
      recipient
      carrier
      deliveredAt
    }
    ... on InsurancedOrder {
      cashbackAmount
      cashbackRate
    }
  }
`;

export const COUNSEL_DETAIL_INFO = gql`
  fragment counselDetailInfo on Counsel {
    id
    createdAt
    status
    counselee {
      id
      nickname
      inactiveCounselConnection(filter: {}, first: 3) {
        edges {
          node {
            id
            index
            memo
            createdAt
          }
        }
      }
    }
    memo
    body
    type {
      id
      name
    }
    areas {
      zone {
        province
        district
        neighborhood
      }
    }
    insurance
    vehicle {
      id
      shortModelName
      plateNumber
      registeredYear
    }
    files {
      url
      description
    }
  }
`;

export const COUNSEL_ROOM = gql`
  ${COUNSEL_WINDOW_USER}
  ${COUNSEL_ROOM_MESSAGE}
  fragment counselRoom on Counsel {
    id
    cursors {
      id
      message {
        id
        createdAt
      }
      user {
        ...counselWindowUser
      }
    }
    members {
      ...counselWindowUser
    }
    messageConnection(before: $before, last: $last) {
      edges {
        cursor
        node {
          ...counselRoomMessage
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    counselee {
      id
      nickname
    }
    status
    queueReason
    queuedFrom
    bookmarkedUntil
  }
`;
