import React from 'react';

import AuthHomePresenter from './AuthHomePresenter';

import { ApolloClientProvider } from '@utils/providers';

const AuthHomeContainer: React.FC = () => {
  return (
    <ApolloClientProvider withToken={false}>
      <AuthHomePresenter />
    </ApolloClientProvider>
  );
};

export default AuthHomeContainer;
