import { FrownOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

interface CounselInfoErrorProps {
  className?: string;
  error: ApolloError;
}
export const CounselInfoError: React.FC<CounselInfoErrorProps> = ({
  className,
  error,
}) => {
  console.error(error);
  return (
    <SError className={className}>
      <FrownOutlined style={{ fontSize: 36 }} />
      <span>세부 채팅 정보 로드에 실패하였습니다.</span>
    </SError>
  );
};

const SError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${props => props.theme.colors.TEXT_MUTED};
  border: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
`;
