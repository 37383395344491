import React from 'react';

const useFocused = () => {
  const [focused, setFocused] = React.useState(document.hasFocus());
  React.useEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return { focused };
};

export default useFocused;
