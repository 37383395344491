import { Avatar, Space, Tag, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

type SimpeTextCellProps = {
  vehicleInfo: {
    brandLogo?: string;
    modelName?: string;
    plateNumber?: string;
  };
  style?: React.CSSProperties;
};

const { Text } = Typography;

const VehicleInfoCell: React.FC<SimpeTextCellProps> = ({
  vehicleInfo,
  style,
}) => {
  return (
    <SContainer style={style}>
      <Space size={4} direction="vertical">
        <Space>
          {vehicleInfo.brandLogo && (
            <Avatar
              style={{ backgroundColor: '#eeeeeeef', padding: 2 }}
              size={28}
              src={vehicleInfo.brandLogo}
            />
          )}
          {vehicleInfo.plateNumber && (
            <Tag color="blue">{vehicleInfo.plateNumber}</Tag>
          )}
        </Space>
        <Text>{vehicleInfo.modelName}</Text>
      </Space>
    </SContainer>
  );
};

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  white-space: pre-line;
  margin-right: 8px;
  overflow-y: auto;
`;

export default VehicleInfoCell;
