import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import styled, { css } from 'styled-components';
interface BlockBubbleProps {
  icon: React.ReactElement;
  title?: string;
  description?: string;
  onInfoClick: () => void;
}
const BlockBubble: React.FC<BlockBubbleProps> = ({
  icon,
  title,
  description,
  onInfoClick,
  ...props
}) => {
  return (
    <SOtherBlockBubble {...props}>
      <SBlockBubbleHeader>
        {React.cloneElement(icon, {
          ...icon.props,
          style: { ...icon.props.style, fontSize: 24 },
        })}
        <InfoCircleOutlined onClick={onInfoClick} style={{ fontSize: 16 }} />
      </SBlockBubbleHeader>
      {title && <SBlockBubbleTitle>{title}</SBlockBubbleTitle>}
      {description && (
        <SBlockBubbleDescription>{description}</SBlockBubbleDescription>
      )}
    </SOtherBlockBubble>
  );
};

export default BlockBubble;

const SOtherBlockBubble = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 14px;
  padding: 14px;
`;
const SBlockBubbleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;
const SBlockBubbleTitle = styled.h5`
  font-size: 16px;
  ${props => css`
    color: ${props.theme.colors.TEXT_DEFAULT};
  `}
`;
const SBlockBubbleDescription = styled.p`
  font-size: 12px;
  ${props => css`
    color: ${props.theme.colors.TEXT_MUTED};
  `}
`;
