import { RightOutlined, ShopOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Space, Spin, Tooltip, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import BlockBubble from '../messageParts/BlockBubble';

import { useCompanyMessageQuery } from '@utils/client';
import env from '@utils/env';
import { contactFormatter } from '@utils/formatters';

gql`
  query CompanyMessage($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      specialties {
        id
        name
      }
      images {
        url
      }
      contact
      extraContact
    }
  }
`;

type CompanyMessageProps = {
  companyId?: string;
};

const { Title, Text } = Typography;

const CompanyMessage: React.FC<CompanyMessageProps> = ({ companyId }) => {
  const { data, loading } = useCompanyMessageQuery({
    variables: {
      // @ts-ignore : type for skip option not implemented
      companyId: companyId,
    },
    skip: !companyId,
  });

  if (!companyId) {
    return (
      <BlockBubble
        icon={<ShopOutlined />}
        title="존재하지 않는 정비소입니다."
        onInfoClick={() => null}
      />
    );
  }

  const company = data?.company;
  const specialties = data?.company?.specialties
    .map(specialty => specialty.name)
    .join(', ');

  return (
    <Spin spinning={loading}>
      <SContainer>
        {company ? (
          <>
            <Space>
              {company.images.length ? (
                <img
                  style={{ borderRadius: 8, border: `1px solid #e0e0e0` }}
                  src={company.images[0].url}
                  width={50}
                  height={50}
                />
              ) : (
                <img
                  style={{ borderRadius: 8, border: `1px solid #e0e0e0` }}
                  src={require('@assets/image/company-placeholder.png')}
                  width={50}
                  height={50}
                />
              )}
              <Space size={2} direction="vertical">
                <Text style={{ fontSize: 16 }} strong>
                  {company.name}
                </Text>
                {specialties?.length ? <Text>{specialties}</Text> : null}
                {company?.extraContact && (
                  <Text>휴대폰: {contactFormatter(company?.extraContact)}</Text>
                )}
                {company?.contact && (
                  <Text>전화: {contactFormatter(company?.contact)}</Text>
                )}
              </Space>
            </Space>
            <Tooltip title="상세정보 확인/편집" placement="topRight">
              <RightOutlined
                size={32}
                onClick={() => {
                  window.open(
                    `${env.COMMUNITY_CONSOLE_URL}/company/list/detail/${company.id}`,
                  );
                }}
              />
            </Tooltip>
          </>
        ) : (
          <Title level={5}>
            {loading ? '정비소 정보 로드중' : '정비소 로드 실패'}
          </Title>
        )}
      </SContainer>
    </Spin>
  );
};

export default CompanyMessage;

const SContainer = styled.div`
  width: 320px;
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
