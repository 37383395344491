import React from 'react';
import styled from 'styled-components';

import { MessageProps } from '../';

import { PaymentStatus } from '@utils/client';

type InvoiceMessageProps = {
  nickname: string;
  paymentStatus: PaymentStatus;
} & MessageProps;

const InvoiceMessage: React.FC<InvoiceMessageProps> = ({
  nickname,
  paymentStatus,
}) => {
  const paymentStatusMessage = {
    [PaymentStatus.Ready]: '결제 대기중입니다...',
    [PaymentStatus.Cancelled]: '결제가 취소되었습니다.',
    [PaymentStatus.Failed]: '결제가 실패했습니다.',
    [PaymentStatus.Paid]: '결제가 완료되었습니다',
  };
  return (
    <Container>
      <Title>
        <TitleText fontWeight={'bold'}>{`${nickname}님`}</TitleText>
        <TitleText fontWeight={'normal'}>{`의 `}</TitleText>
        <TitleText fontWeight={'bold'}>견적</TitleText>
      </Title>
      <ButtonBox>{paymentStatusMessage[paymentStatus]}</ButtonBox>
    </Container>
  );
};

export default InvoiceMessage;

const Container = styled.div`
  width: 260px;
  height: 128px;
  border-radius: 20px;
  background-color: #0056b4;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px 0px 12px;
`;

const Title = styled.div``;

const TitleText = styled.span<{ fontWeight: string }>`
  font-weight: ${props => props.fontWeight};
  font-size: 16px;
  color: #ffffff;
`;

const ButtonBox = styled.div`
  width: 236px;
  height: 44px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c6cacf;
  font-size: 16px;
  margin-top: 24px;
`;
