import { gql } from '@apollo/client';
import React from 'react';

import { Query, Image, RoleEnum } from '@utils/client';
import initClient, { refreshAccessToken } from 'utils/apollo';
import { generateCache, loggedInVar } from 'utils/cache';

export type Me = {
  id: string;
  role: RoleEnum.Admin | RoleEnum.Counselor;
  avatar: Image;
  email?: string;
  nickname: string;
};

type ContextType = {
  me: Me | null;
  setMe: (me: Me | null) => void;
};

export const MeContext = React.createContext<ContextType>({
  me: null,
  setMe: () => undefined,
});

const ME_QUERY = gql`
  query me {
    me {
      id
      avatar {
        url
      }
      email
      role
      nickname
    }
  }
`;

const getUserId = async () => {
  const apolloClient = initClient(generateCache());
  const result = await apolloClient?.query<Query>({
    query: ME_QUERY,
  });
  return result?.data.me as Me;
};

export const useMe = () => {
  const { me, setMe } = React.useContext(MeContext);
  const [loading, setLoading] = React.useState(false);
  const fetchMe = async () => {
    setLoading(true);
    let result = false;
    if (!me) {
      const meResult = await getUserId();
      if (
        meResult &&
        (meResult.role === RoleEnum.Counselor ||
          meResult.role === RoleEnum.Admin)
      ) {
        setMe(meResult);
        result = true;
      }
    }
    setLoading(false);
    return result;
  };

  const clearMe = () => {
    setMe(null);
  };
  return {
    ...me,
    viewOnly: me?.email === 'read-only@autopediacar.com',
    readOnly: me?.email === 'read-only@autopediacar.com',
    fetchMe,
    clearMe,
    loading,
  };
};

type MeProviderProps = {
  children: React.ReactNode;
};

export const MeProvider = ({ children }: MeProviderProps) => {
  const [me, setMe] = React.useState<Me | null>(null);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (!me && token) {
      setUserInfo();
    }
  }, []);

  const setUserInfo = async () => {
    await refreshAccessToken();
    const result = await getUserId();
    if (result) {
      setMe(result);
      loggedInVar(true);
    }
  };

  return (
    <MeContext.Provider
      value={{
        me,
        setMe,
      }}
    >
      {children}
    </MeContext.Provider>
  );
};
