import { Tabs } from 'antd';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import FinishedTable from './tables/FinishedTable';
import OngoingTable from './tables/OngoingTable';
import QueuedTable from './tables/QueuedTable';
import FinishedTab from './tabs/FinishedTab';
import OngoingTab from './tabs/OngoingTab';
import QueuedTab from './tabs/QueuedTab';
import CounselsSubscriber from './utils/CounselsSubscriber';

import { useMe } from '@hooks';
import { CounselStatus } from '@utils/client';

interface CounselTableTabsProps {
  className?: string;
}

export type TableFilter = {
  term?: string;
  domestic?: boolean;
  inquiryTypeIds?: string[];
  index?: number;
};

const CounselTableTabs: React.FC<CounselTableTabsProps> = ({ className }) => {
  const { search } = useLocation();
  const { readOnly } = useMe();
  const selectedTab = React.useMemo(() => {
    const query = queryString.parse(search);
    switch (query.tab) {
      case 'ongoing':
        return CounselStatus.Ongoing;
      case 'queued':
        return CounselStatus.Queued;
      case 'finished':
        return CounselStatus.Finished;
      default:
        return readOnly ? CounselStatus.Finished : CounselStatus.Ongoing;
    }
  }, [search, readOnly]);

  return (
    <>
      <CounselsSubscriber />
      <SChatTableTabs
        className={className}
        animated={false}
        tabBarStyle={tabBarStyle}
        activeKey={selectedTab}
      >
        {!readOnly && (
          <>
            <STabPane
              key={CounselStatus.Ongoing}
              forceRender
              tab={<OngoingTab />}
            >
              <OngoingTable />
            </STabPane>
            <STabPane
              key={CounselStatus.Queued}
              forceRender
              tab={<QueuedTab />}
            >
              <QueuedTable />
            </STabPane>
          </>
        )}
        <STabPane
          key={CounselStatus.Finished}
          forceRender
          tab={<FinishedTab />}
        >
          <SSearchableFinishedCounselTable />
        </STabPane>
      </SChatTableTabs>
    </>
  );
};

export default CounselTableTabs;

const tabBarStyle: React.CSSProperties = {
  marginBottom: 0,
  marginLeft: '12px',
};
const SChatTableTabs = styled(Tabs)``;
const STabPane = styled(Tabs.TabPane)`
  ${props => css`
    background-color: ${props.theme.colors.BACKGROUND};
    border: 1px solid ${props.theme.colors.BORDER_BACKGROUND};
    border-top: none;
  `}
`;

const SSearchableFinishedCounselTable = styled(FinishedTable)`
  width: 100%;
  height: 100%;
`;
