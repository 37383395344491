import React from 'react';
import styled from 'styled-components';

type SimpeTextCellProps = {
  text: string;
  style?: React.CSSProperties;
};

const SimpleTextCell: React.FC<SimpeTextCellProps> = ({ text, style }) => {
  return <SContainer style={style}>{text}</SContainer>;
};

const SContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  white-space: pre-line;
  margin-right: 10px;
  overflow-y: auto;
`;

export default SimpleTextCell;
