import './styles.css';
import { gql } from '@apollo/client';
import { Result } from 'antd';
import { throttle } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

import { MessageInterface, Message } from './Message';
import MessageInput from './MessageInput';
import { NewMessage } from './NewMessage';

import Loading from '@components/Loading';
import { useFocused, useMe, useMessage, useVisibilityOnScroll } from '@hooks';
import { MessageStatus, useCounselRoomQuery } from '@utils/client';
import { COUNSEL_ROOM } from '@utils/fragments';

export const COUNSEL_ROOM_QUERY = gql`
  ${COUNSEL_ROOM}
  query counselRoom($counselId: ID!, $before: String, $last: Int = 20) {
    counsel(counselId: $counselId) {
      ...counselRoom
    }
  }
`;

interface CounselRoomProps {
  className?: string;
  counselId: string;
}
const CounselRoom: React.FC<CounselRoomProps> = ({ className, counselId }) => {
  const [initCursorUpdate, setCursorUpdate] = React.useState(false);
  const [background, setBackground] = React.useState<string>('transparent');
  const { data, loading, error, fetchMore } = useCounselRoomQuery({
    variables: { counselId },
  });

  const { id: userId } = useMe();
  const { focused } = useFocused();
  const { updateCursor } = useMessage({ counselId });

  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const PageInfo = React.useMemo(
    () => data && data.counsel?.messageConnection?.pageInfo,
    [data],
  );

  const myCursor = React.useMemo(
    () =>
      data && data.counsel?.cursors?.find(cursor => cursor.user?.id === userId),
    [data, userId],
  );

  const messageContents = React.useMemo(
    () =>
      data &&
      data.counsel?.messageConnection?.edges?.map((edge, index, edges) => {
        const prevEdge = edges[index - 1];
        const nextEdge = edges[index + 1];
        const message = edge.node as MessageInterface;
        const users = data?.counsel.cursors
          .filter(cursor => cursor?.message?.id === message.id)
          ?.map(cursor => cursor.user);

        if (!prevEdge)
          return {
            message,
            showDate: true,
            showAuthor: true,
            showTimestamp: false,
            users,
          };

        let showDate = false;
        let showAuthor = false;
        let showTimestamp = false;

        const sentAt = new Date(edge.node.createdAt);
        const prevSendedAt = new Date(prevEdge.node.createdAt);
        const nextSendedAt = nextEdge && new Date(nextEdge.node.createdAt);

        if (sentAt.getDate() !== prevSendedAt.getDate()) {
          showDate = true;
          showAuthor = true;
        }
        if (
          prevEdge.node.__typename === 'EnterMessage' ||
          prevEdge.node.__typename === 'LeaveMessage'
        ) {
          showAuthor = true;
          showTimestamp = true;
        }
        if (prevEdge.node.author?.id !== edge.node.author?.id)
          showAuthor = true;
        if (
          !nextSendedAt ||
          Math.floor(sentAt.getTime() / 60000) !==
            Math.floor(nextSendedAt.getTime() / 60000) ||
          edge.node.author?.id !== nextEdge.node.author?.id
        )
          showTimestamp = true;

        return { message, showDate, showAuthor, showTimestamp, users };
      }),
    [data],
  );

  const scrollable = scrollRef.current && scrollRef.current.scrollTop !== 0;

  const lastMessage =
    messageContents
      ?.filter(
        messageContent =>
          messageContent?.message?.__typename !== 'EnterMessage' &&
          messageContent?.message?.__typename !== 'LeaveMessage',
      )
      ?.reverse()[0]?.message || undefined;
  const viewport = React.useRef<HTMLDivElement | null>(null);
  const [bottomVisible, bottomRef] = useVisibilityOnScroll();

  const bottom = React.useMemo(() => {
    return bottomVisible || !scrollable;
  }, [bottomVisible, scrollable]);

  const handleFetchMore = React.useCallback(async () => {
    if (!PageInfo) {
      return;
    }
    await fetchMore({
      variables: { counselId, last: 20, before: PageInfo.startCursor },
    });
  }, [PageInfo, counselId, fetchMore]);

  const scrollListener = React.useCallback(
    async (e: React.WheelEvent<HTMLDivElement>) => {
      if (!scrollRef.current) {
        return;
      }

      if (!data) {
        return;
      }

      if (!PageInfo) {
        return;
      }

      const scrollHeight =
        scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
      let moreFetchHeight = scrollHeight * 0.5;

      if (data.counsel.messageConnection.edges.length <= 20) {
        moreFetchHeight = scrollHeight * 0.7;
      }

      if (
        scrollRef.current.scrollTop <= moreFetchHeight &&
        PageInfo.hasPreviousPage &&
        data.counsel.messageConnection.pageInfo.hasNextPage
      ) {
        scrollRef.current.scrollTop = moreFetchHeight;
      }

      if (scrollRef.current.scrollTop <= moreFetchHeight) {
        e.preventDefault();
        await handleFetchMore();
      }
    },
    [PageInfo, handleFetchMore, data],
  );

  const onScroll = throttle(scrollListener, 500);

  const scrollToBottom = React.useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop =
        scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
    }
  }, []);

  const hasNewMessage = React.useMemo(() => {
    if (!myCursor || !myCursor.message) {
      return false;
    }
    return !!(
      scrollable &&
      !bottom &&
      lastMessage &&
      myCursor.message.createdAt < lastMessage.createdAt &&
      lastMessage?.author?.id !== userId
    );
  }, [bottom, myCursor, lastMessage, userId, scrollable]);

  React.useEffect(() => {
    if (!scrollable && messageContents && !initCursorUpdate) {
      const lastMessageData = messageContents[messageContents.length - 1];
      if (lastMessageData) {
        updateCursor(lastMessageData.message.id);
        setCursorUpdate(true);
        scrollToBottom();
      }
    }
  }, [
    initCursorUpdate,
    scrollable,
    messageContents,
    scrollToBottom,
    updateCursor,
  ]);

  React.useEffect(() => {
    if (focused && bottom && messageContents) {
      const lastMessageData = messageContents[messageContents.length - 1];
      if (lastMessageData) {
        updateCursor(lastMessageData.message.id);
        scrollToBottom();
      }
    }
  }, [focused, bottom, updateCursor, messageContents, scrollToBottom]);

  React.useEffect(() => {
    if (!hasNewMessage) setBackground('transparent');
    if (!focused && hasNewMessage) {
      setBackground('#FFA39E');
      setTimeout(() => setBackground('#FFF1F0'), 500);
    }
  }, [hasNewMessage, focused, lastMessage?.id]);

  // 상담사가 새로운 메세지를 보내는 경우 최하단으로 스크롤
  React.useEffect(() => {
    if (lastMessage?.author?.id === userId) {
      bottomRef.current?.scrollIntoView();
    }
  }, [lastMessage?.id, lastMessage?.author, userId, bottomRef]);

  // 상담사가 화면을 주시하고 있고, 가장 아래를 보고있을때 신규 메세지가 온다면 자동으로 최하단으로 스크롤
  React.useEffect(() => {
    if (focused && bottom) {
      bottomRef.current?.scrollIntoView();
    }
  }, [lastMessage?.id, bottom, focused, bottomRef]);

  React.useEffect(() => {
    const handleIntersection: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        const id = entry.target.id;
        if (messageContents) {
          if (entry.isIntersecting) {
            const message = messageContents.find(
              messageContent => messageContent.message.id === id,
            )?.message;
            if (
              message &&
              (!myCursor?.message?.createdAt ||
                myCursor?.message?.createdAt < message.createdAt)
            )
              updateCursor(id);
          }
        }
      });
    };

    const messagesObserver = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px 0px -100px 0px',
    });
    messageContents &&
      messageContents.forEach(messageContent => {
        const status = messageContent.message.status;
        if (!status || status === MessageStatus.Sent) {
          const target = document.querySelector(
            `#${messageContent.message.id}`,
          );
          if (target) messagesObserver.observe(target);
        }
      });

    return () => messagesObserver && messagesObserver.disconnect();
  }, [messageContents, myCursor?.message?.createdAt, updateCursor]);

  if (loading) return <Loading />;
  if (error)
    return (
      <Result
        status="error"
        title="대화 기록 로드중에 문제가 발생했습니다."
        extra={error.message}
      />
    );

  return data ? (
    <SContainer className={className}>
      <SChatWrapper onWheel={onScroll} ref={scrollRef}>
        <SChat background={background} className={className} ref={viewport}>
          {messageContents?.map(messageContent => {
            return (
              <Message
                counselId={counselId}
                key={`message-${messageContent.message.id}`}
                {...messageContent}
                mine={messageContent.message.author?.id === userId}
              />
            );
          })}
          <div ref={bottomRef} />
        </SChat>
      </SChatWrapper>
      {hasNewMessage && lastMessage && (
        <NewMessage message={lastMessage} onClick={() => scrollToBottom()} />
      )}
      <SMessageInput counselId={counselId} />
    </SContainer>
  ) : null;
};

export default React.memo(CounselRoom);

const SChatWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  scroll-behavior: inherit;
  background-color: ${props => props.theme.colors.PRIMARY_LIGHT};
  border: 1px solid ${props => props.theme.colors.BORDER_PRIMARY_LIGHT};
`;

const SChat = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  padding: 4px;
  overflow: scroll;
  ${props =>
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.background === '#FFF1F0' &&
    css`
      transition: background-color 1000ms linear;
    `}
`;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SMessageInput = styled(MessageInput)`
  padding: 8px;
  background-color: ${props => props.theme.colors.BACKGROUND};
  border: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
  border-top-width: 0px;
`;
