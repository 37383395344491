import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Input, PageHeader, Spin, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

import ExitConfirmModal from '../ExitConfirmModal';
import TemplateBreadcrumb from '../TemplateBreadcrumb';
import TemplateEditor, { TemplateEditorRef } from '../TemplateEditor';

import { CreateTemplateContainerProps } from './CreateTemplateContainer';

import {
  CreateTemplateData,
  useTemplatePathQuery,
  FileType,
  MessageType,
  TemplateMessageInput,
} from '@utils/client';

gql`
  query templatePath($directoryId: ID!) {
    templateDirectory(directoryId: $directoryId) {
      id
      path {
        id
        name
      }
    }
  }
`;

interface CreateTemplatePresenterProps extends CreateTemplateContainerProps {
  createTemplate: (template: CreateTemplateData) => Promise<string | undefined>;
}
const CreateTemplatePresenter: React.FC<CreateTemplatePresenterProps> = ({
  className,
  directoryId,
  createTemplate,
  onClose,
  onChangeDirectory,
}) => {
  const { loading, data, error } = useTemplatePathQuery({
    variables: { directoryId },
  });

  const [exitConfirm, setExitConfirm] = React.useState<{
    visible: boolean;
    onConfirm?: () => void;
  }>({ visible: false });
  const [contentsEdited, setContentsEdited] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [name, setName] = React.useState<string>('');

  const editor = React.useRef<TemplateEditorRef | null>(null);

  const edited = React.useMemo(
    () => contentsEdited || !!name,
    [contentsEdited, name],
  );

  const breadcrumb = React.useMemo(
    () =>
      data?.templateDirectory.path ? (
        <TemplateBreadcrumb
          directories={data.templateDirectory.path}
          onClickDirectory={directoryId => {
            if (edited)
              setExitConfirm({
                visible: true,
                onConfirm: () => onChangeDirectory(directoryId),
              });
            else onChangeDirectory(directoryId);
          }}
        />
      ) : undefined,
    [data?.templateDirectory.path, edited, onChangeDirectory],
  );

  const onBack = React.useCallback(() => {
    if (edited) setExitConfirm({ visible: true, onConfirm: () => onClose() });
    else onClose();
  }, [edited, onClose]);

  const onChange = React.useCallback((change: { edited: boolean }) => {
    setContentsEdited(change.edited);
  }, []);

  const save = async () => {
    const contents = editor.current?.getContents();

    if (name.length > 100 || name.length < 2) {
      notification.error({
        message: '템플릿 생성 실패',
        description: '템플릿명은 2자 이상, 100자 이하만 허용됩니다.',
      });
      return;
    }

    if (!name) {
      notification.error({
        message: '템플릿 생성 실패',
        description: '템플릿명을 입력해주세요',
      });
      return;
    }
    if (!contents?.length) {
      notification.error({
        message: '템플릿 생성 실패',
        description: '템플릿 내용을 입력해주세요',
      });
      return;
    }

    setSaving(true);
    const formattedContents: TemplateMessageInput[] = contents.map(content => {
      if (content.type !== 'text') {
        return {
          file: {
            url: content.url,
            type: content.type.toUpperCase() as FileType,
          },
          type: MessageType.File,
        };
      }
      return {
        type: MessageType.Text,
        body: content.body,
      };
    });

    try {
      const templateId = await createTemplate({
        name,
        messages: formattedContents,
      });
      onClose(templateId);
    } catch (error: any) {
      notification.error({
        message: '템플릿 생성 실패',
        description: error.message,
      });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <SCenterContainer className={className}>
        <Spin />
      </SCenterContainer>
    );
  }

  if (error) return null;

  return data ? (
    <>
      <ExitConfirmModal
        {...exitConfirm}
        onCancel={() => setExitConfirm({ visible: false })}
        title="템플릿 생성을 취소하시겠습니까?"
        body="모든 작성 내용이 삭제됩니다."
      />
      <SCreateTemplatePresenter
        className={className}
        title={
          <SNameInput
            value={name}
            onChange={event => setName(event.target.value)}
          />
        }
        backIcon={<CloseOutlined />}
        onBack={onBack}
        breadcrumbRender={() => breadcrumb}
        extra={[
          <Button
            key="save-template"
            type="primary"
            disabled={saving || !edited}
            icon={<SaveOutlined />}
            onClick={save}
          >
            저장
          </Button>,
        ]}
      >
        <STemplateEditor
          ref={editor}
          defaultContents={[{ type: 'text', body: '' }]}
          onChange={onChange}
        />
      </SCreateTemplatePresenter>
    </>
  ) : null;
};

export default CreateTemplatePresenter;

const SCreateTemplatePresenter = styled(PageHeader)``;
const SNameInput = styled(Input)`
  width: 300px;
`;
const STemplateEditor = styled(TemplateEditor)`
  height: 100%;
`;
const SCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
