import { AimOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { TableCellProps } from 'react-virtualized';
import styled from 'styled-components';

import { TMergedTableRow } from './';

interface RegionCellProps extends TableCellProps {
  disabled?: boolean;
}
const RegionCell: React.FC<RegionCellProps> = props => {
  const counselId = props.rowData['id'];
  const areas: TMergedTableRow['areas'] = props.rowData['areas'];

  return (
    <SRegionCell>
      {areas.map((area, index) => (
        <STagWrapper key={`${counselId}-area-${index}`}>
          <Tag>
            <AimOutlined />
            <SRegionText>{area}</SRegionText>
          </Tag>
        </STagWrapper>
      ))}
    </SRegionCell>
  );
};

export default React.memo(
  RegionCell,
  (prevProps, nextProps) =>
    prevProps.rowData.id === nextProps.rowData.id &&
    prevProps.cellData === nextProps.cellData,
);

const SRegionCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 12px;
  height: 100%;
  margin-right: 10px;
`;

const STagWrapper = styled.div`
  margin-bottom: 12px;
`;

const SRegionText = styled.span`
  margin-left: 8px;
`;
