import { Coordinates } from './client';

export const getDistance = (coordinates: {
  current: Coordinates;
  target: Coordinates;
}): number => {
  const { latitude: currentLat, longitude: currentLng } = coordinates.current;
  const { latitude: targetLat, longitude: targetLng } = coordinates.target;
  const radCurrentLat = (Math.PI * currentLat) / 180;
  const radTargetLat = (Math.PI * targetLat) / 180;
  const theta = currentLng - targetLng;
  const radTheta = (Math.PI * theta) / 180;
  let distance =
    Math.sin(radCurrentLat) * Math.sin(radTargetLat) +
    Math.cos(radCurrentLat) * Math.cos(radTargetLat) * Math.cos(radTheta);

  if (distance > 1) {
    distance = 1;
  }
  distance = Math.acos(distance);
  distance *= 180 / Math.PI;
  distance *= 60 * 1.1515;

  return Math.round(distance * 16.09344) / 10;
};
