import { gql } from '@apollo/client';
import React from 'react';

import PaymentPresenter from './PaymentPresenter';

import {
  useCreateEmptyOrderMutation,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  UpdateOrderData,
  useSendEstimateMessageMutation,
  useCreateShippingMutation,
  useSendInvoiceMessageMutation,
  useCancelPaymentMutation,
  useUpdateShippingMutation,
  CreateShippingData,
  UpdateShippingData,
  useForceCompletePaymentMutation,
} from '@utils/client';

type PaymentContainerProps = {
  counselId: string;
};

gql`
  mutation createEmptyOrder(
    $userId: ID!
    $name: String!
    $counselId: ID!
    $vehicleId: ID!
  ) {
    createOrder(
      userId: $userId
      counselId: $counselId
      vehicleId: $vehicleId
      data: { items: [], name: $name, shippingCharge: 7000 }
    ) {
      order {
        ...taskpadOrder
      }
    }
  }
`;

gql`
  mutation deleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId) {
      order {
        id
      }
    }
  }
`;

gql`
  mutation updateOrder($data: UpdateOrderData!, $orderId: ID!) {
    updateOrder(data: $data, orderId: $orderId) {
      order {
        id
        __typename
        amount
        createdAt
        expiresAt
        discountAmount
        totalItemAmount
        totalAmount
        paymentAmount
        vehicle {
          id
          brand {
            id
            name
          }
        }
        items {
          code
          name
          discountRate
          price
          quantity
          totalAmount
          paymentAmount
        }
        name
        payment {
          id
          amount
          completedAt
          cancelledAt
          status
        }
        shippingCharge
        recommendedReason
        rejectedReason
        shipping {
          id
          status
          trackingId
          contact
          destination
          method
          recipient
          carrier
          deliveredAt
        }
        ... on InsurancedOrder {
          cashbackAmount
          cashbackRate
        }
      }
    }
  }
`;

gql`
  mutation sendEstimateMessage($counselId: ID!, $orderId: ID!) {
    sendEstimateMessage(counselId: $counselId, orderId: $orderId) {
      message {
        ...counselRoomMessage
      }
    }
  }
`;

gql`
  mutation createShipping($data: CreateShippingData!, $orderId: ID!) {
    createShipping(data: $data, orderId: $orderId) {
      shipping {
        id
      }
    }
  }
`;

gql`
  mutation sendInvoiceMessage($counselId: ID!, $orderId: ID!) {
    sendInvoiceMessage(counselId: $counselId, orderId: $orderId) {
      message {
        ...counselRoomMessage
      }
    }
  }
`;

gql`
  mutation cancelPayment($paymentId: String!, $reason: String!) {
    cancelPayment(paymentId: $paymentId, reason: $reason) {
      payment {
        id
        amount
        cancelledAt
      }
    }
  }
`;

gql`
  mutation updateShipping($data: UpdateShippingData!, $shippingId: ID!) {
    updateShipping(data: $data, shippingId: $shippingId) {
      shipping {
        id
        status
        trackingId
        contact
        destination
        method
        recipient
        carrier
      }
    }
  }
`;

gql`
  mutation forceCompletePayment($orderId: ID!, $paymentId: ID!) {
    forceCompletePayment(orderId: $orderId, paymentId: $paymentId) {
      ... on PaymentCompleted {
        payment {
          id
          amount
          completedAt
          status
        }
      }

      ... on PaymentUncompleted {
        payment {
          id
          status
          id
          amount
          completedAt
          status
        }
      }
    }
  }
`;

const PaymentContainer: React.FC<PaymentContainerProps> = ({ counselId }) => {
  const [createEmptyOrderMutation] = useCreateEmptyOrderMutation();
  const [deleteOrderMutation] = useDeleteOrderMutation();
  const [updateOrderMutation] = useUpdateOrderMutation();
  const [sendEsimateMutation] = useSendEstimateMessageMutation();
  const [createShippingMutation] = useCreateShippingMutation();
  const [sendInvoiceMessageMutation] = useSendInvoiceMessageMutation();
  const [cancelPaymentMutation] = useCancelPaymentMutation();
  const [updateShippingMutation] = useUpdateShippingMutation();
  const [forceCompletePaymentMutation] = useForceCompletePaymentMutation();

  const createEmptyOrder = async (
    ordername: string,
    counseleeId: string,
    vehicleId: string,
  ) => {
    const { data } = await createEmptyOrderMutation({
      variables: {
        userId: counseleeId,
        name: ordername,
        counselId,
        vehicleId,
      },
    });

    if (!data) {
      return null;
    }

    return data.createOrder.order;
  };

  const deleteOrder = async (orderId: string) => {
    deleteOrderMutation({
      variables: {
        orderId,
      },
    });
  };

  const updateOrder = async (
    orderId: string,
    updateOrderData: UpdateOrderData,
  ): Promise<any> => {
    const { data } = await updateOrderMutation({
      variables: {
        orderId,
        data: updateOrderData,
      },
    });

    if (!data) {
      return null;
    }

    return data.updateOrder.order;
  };

  const sendEstimate = async (counselId: string, orderId: string) => {
    const { data } = await sendEsimateMutation({
      variables: {
        orderId,
        counselId,
      },
    });

    if (!data) {
      return null;
    }

    return data.sendEstimateMessage.message;
  };

  const createShipping = async (
    orderId: string,
    createShippingData: CreateShippingData,
  ) => {
    const { data } = await createShippingMutation({
      variables: {
        data: createShippingData,
        orderId,
      },
    });

    if (!data) {
      return null;
    }

    return data.createShipping.shipping;
  };

  const sendInvoice = async (counselId: string, orderId: string) => {
    const { data } = await sendInvoiceMessageMutation({
      variables: {
        counselId,
        orderId,
      },
    });

    if (!data) {
      return null;
    }

    return data.sendInvoiceMessage.message;
  };

  const cancelPayment = async (paymentId: string, reason: string) => {
    const { data } = await cancelPaymentMutation({
      variables: {
        paymentId,
        reason,
      },
    });

    if (!data) {
      return;
    }

    return data.cancelPayment.payment.cancelledAt;
  };

  const updateShipping = async (
    shippingId: string,
    updateData: UpdateShippingData,
  ) => {
    const { data } = await updateShippingMutation({
      variables: {
        shippingId,
        data: updateData,
      },
    });

    if (!data) {
      return null;
    }

    return data.updateShipping.shipping;
  };

  const forceCompletePayment = async (orderId: string, paymentId: string) => {
    const { data } = await forceCompletePaymentMutation({
      variables: {
        orderId,
        paymentId,
      },
    });

    if (!data) {
      return null;
    }

    return data.forceCompletePayment.payment;
  };

  return (
    <PaymentPresenter
      counselId={counselId}
      createEmptyOrder={createEmptyOrder}
      deleteOrder={deleteOrder}
      updateOrder={updateOrder}
      sendEstimate={sendEstimate}
      createShipping={createShipping}
      sendInvoice={sendInvoice}
      cancelPayment={cancelPayment}
      updateShipping={updateShipping}
      forceCompletePayment={forceCompletePayment}
    />
  );
};

export default PaymentContainer;
