import React from 'react';

import SimpleText from '../messageParts/SimpleText';

type LeaveMessageProps = {
  nickname?: string;
};

const LeaveMessage: React.FC<LeaveMessageProps> = ({
  nickname,
}: LeaveMessageProps) => {
  return <SimpleText>{nickname || '(알 수 없음)'}님이 퇴장했습니다</SimpleText>;
};

export default LeaveMessage;
