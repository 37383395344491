import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface TemplateBreadcrumbProps {
  className?: string;
  directories: { id: string; name: string }[];
  onClickDirectory: (directoryId: string) => void;
}
const TemplateBreadcrumb: React.FC<TemplateBreadcrumbProps> = ({
  className,
  directories,
  onClickDirectory,
}) => {
  return (
    <STemplateBreadcrumb className={className}>
      {directories.map(directory => (
        <STemplateBreadcrumbItem
          key={directory.id}
          onClick={() => {
            onClickDirectory(directory.id);
          }}
        >
          {directory.id === 'ROOT' ? <HomeOutlined /> : directory.name}
        </STemplateBreadcrumbItem>
      ))}
    </STemplateBreadcrumb>
  );
};

export default TemplateBreadcrumb;

const STemplateBreadcrumb = styled(Breadcrumb)``;
const STemplateBreadcrumbItem = styled(Breadcrumb.Item)`
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.PRIMARY};
  }
`;
