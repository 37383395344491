import { CounselStatus } from './client';
import { provinceShortener } from './formatters';

import region from '@assets/json/region.json';

// TODO: 환경변수등 처리 필요
export const HOST = 'http://localhost:3000';

export const COUNSEL_STATE: Record<
  CounselStatus | 'Estimating',
  { title: string }
> = {
  [CounselStatus.Pending]: { title: '접수' },
  [CounselStatus.Ongoing]: { title: '진행' },
  [CounselStatus.Queued]: { title: '대기' },
  [CounselStatus.Finished]: { title: '완료(배송예약)' },
  [CounselStatus.Estimated]: { title: '견적 완료' },
  [CounselStatus.Estimating]: { title: '견적중' },
  Estimating: { title: '선견적서' },
};

export const CATEGORY: Record<
  string,
  { name: string; color: string; preset: string }
> = {
  ckh4mumcc0221anyzyhr63b3l: {
    name: '정비/부품',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckh4mvusv0238anyzxhs3rvld: {
    name: '타이어',
    color: '#FA541C',
    preset: 'volcano',
  },
  ckh4mw88z0248anyzjuf9vo9h: {
    name: '배터리',
    color: '#FAAD14',
    preset: 'gold',
  },
  ckh4mwvtd0260anyzcg06cfmi: {
    name: '사고수리',
    color: '#A0D911',
    preset: 'lime',
  },
  ckh4mz6r30287anyz3cpdvz5z: {
    name: '정비내역서 컨설팅',
    color: '#13C2C2',
    preset: 'cyan',
  },
  ckh4n1vbk0318anyz79r0wh1u: {
    name: '기타',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckisfpmyv000001mfbhgjg5qq: {
    name: '정비소',
    color: '#722ED1',
    preset: 'purple',
  },
  ckmlsvaby000801mh6bcv0wjw: {
    name: '오일 및 소모품',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckmlsvr19000901mhbd0326a2: {
    name: '엔진',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckmlsvxvk000a01mhbrj7d03f: {
    name: '브레이크',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckmlsyarc000f01mh4jy4cfsm: {
    name: '변속기',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckmlszmks000h01mhdn2ndxv3: {
    name: '하체',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckmlt0ll4000i01mh75yqdbbd: {
    name: '배기',
    color: '#EB2F96',
    preset: 'magenta',
  },
  ckmlt12j4000j01mhcaqb0vkx: {
    name: '타이어 교환 (국산)',
    color: '#FA541C',
    preset: 'volcano',
  },
  ckmlt1bg4000k01mhf22oe8uf: {
    name: '타이어 교환 (수입)',
    color: '#FA541C',
    preset: 'volcano',
  },
  ckmlt1t3r000l01mh2pukaark: {
    name: '휠 밸런스',
    color: '#FA541C',
    preset: 'volcano',
  },
  ckmlt2lzv000m01mh9o1pdark: {
    name: '휠 얼라인먼트',
    color: '#FA541C',
    preset: 'volcano',
  },
  ckmlt2xng000n01mh1m2dejo6: {
    name: '휠 복원',
    color: '#FA541C',
    preset: 'volcano',
  },
  ckmlsnhr9000001mh7k9gafrp: {
    name: '판금,도장',
    color: '#A0D911',
    preset: 'lime',
  },
  ckmlsp0g8000101mh64te15hx: {
    name: '범퍼 교환',
    color: '#A0D911',
    preset: 'lime',
  },
  ckmlsp9ki000201mh1h0fhj8o: {
    name: '도어 교환',
    color: '#A0D911',
    preset: 'lime',
  },
  ckmlsrngw000401mhgw1zbhx6: {
    name: '보닛 교환',
    color: '#A0D911',
    preset: 'lime',
  },
  ckmlstq7j000501mh4v2683z0: {
    name: '펜더 교환',
    color: '#A0D911',
    preset: 'lime',
  },
  ckmlsuc5s000601mh4yb422oe: {
    name: '무판금 덴트',
    color: '#A0D911',
    preset: 'lime',
  },
  ckmlsunja000701mhhpzb1gr8: { name: '기타', color: '#A0D911', preset: 'lime' },
  ckmlt3fxo000o01mh4rf757g7: {
    name: '수리 전',
    color: '#13C2C2',
    preset: 'cyan',
  },
  ckmlt461z000p01mhgwfx9odw: {
    name: '수리 후',
    color: '#13C2C2',
    preset: 'cyan',
  },
  ckmlt88z7000q01mhdr0jhyut: {
    name: '시트',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmlt8k6b000r01mh0n7p9r1r: {
    name: '오디오',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmlt8y67000s01mhaig4ahoy: {
    name: '네비게이션',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmlt91zq000t01mhcx1w0lkn: {
    name: '블랙박스',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmlt9fi2000u01mh2fhzafsu: {
    name: '후방카메라',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltbl05000w01mhe8ld0vv4: {
    name: '룸미러',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltbtli000x01mh5c3l9wbv: {
    name: '선팅',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltcmfy000y01mhcwcv6oyn: {
    name: '광택',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltcqn0000z01mh6dhidjvm: {
    name: '세차',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltd24n001001mh8xyv8tjr: {
    name: '유리',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltd68u001101mhf2w8361l: {
    name: '검사',
    color: '#2F54EB',
    preset: 'geekblue',
  },
  ckmltdgfv001201mh9o2084ae: {
    name: '폐차',
    color: '#2F54EB',
    preset: 'geekblue',
  },
};

export const regionOptions = Object.entries(region).map(
  ([province, districts]) => ({
    value: province,
    label: provinceShortener(province),
    children: [
      { value: undefined, label: '...' },
      ...(districts as string[]).sort().map(distirct => ({
        value: distirct,
        label: distirct,
      })),
    ],
  }),
);

export const AREA_CODES = [
  '02',
  '031',
  '032',
  '033',
  '041',
  '042',
  '043',
  '044',
  '051',
  '052',
  '053',
  '054',
  '055',
  '061',
  '062',
  '063',
  '064',
  '070',
];

export const NATIONAL_REPRESENTATIVE_CODES = [
  '1588',
  '1577',
  '1899',
  '1544',
  '1644',
  '1661',
  '1566',
  '1600',
  '1670',
  '1688',
  '1666',
  '1599',
  '1877',
  '1855',
  '1800',
];

export const MOBILE_CODES = ['010', '011', '016', '017', '018', '019'];

export const GA_MEASUREMENT_ID = 'G-0278TH8H6L';
