import { Image, Input } from 'antd';
import React from 'react';
import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd';
import styled from 'styled-components';

import { TemplateContent } from './TemplateEditor';

import FallbackImage from '@assets/image/fallback-image.png';

interface CellProps
  extends DraggableProvidedDraggableProps,
    Partial<DraggableProvidedDragHandleProps> {
  className?: string;
  index: number;
  content: TemplateContent;
  onContentChange?: (content: TemplateContent) => void;
  onClick?: () => void;
}
const Cell = React.forwardRef<HTMLLIElement, CellProps>(
  (
    { className, index, content, onClick, onContentChange, ...draggableProps },
    ref,
  ) => {
    let cell: React.ReactNode = null;

    switch (content.type) {
      case 'text':
        cell = (
          <STextInput
            autoSize
            value={content.body}
            onChange={event =>
              onContentChange?.({
                id: content.id,
                type: 'text',
                body: event.target.value,
              })
            }
          />
        );
        break;
      case 'image':
        cell = (
          <SImage width={300} src={content.url} fallback={FallbackImage} />
        );
        break;
      case 'video':
        cell = (
          <SVideo controls width={300} preload="metadata">
            <source src={content.url} />
          </SVideo>
        );
        break;
    }

    return (
      <SCell
        ref={ref}
        {...draggableProps}
        className={className}
        onClick={onClick}
      >
        <SIndex>{index + 1}</SIndex>
        {cell}
      </SCell>
    );
  },
);

Cell.displayName = 'Cell';

export default Cell;

const SCell = styled.li`
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 10px;
  border-radius: 8px;
`;
const SIndex = styled.span`
  width: 15px;
  text-align: right;
`;
const STextInput = styled(Input.TextArea)`
  flex: 1;
`;
const SImage = styled(Image)`
  border-radius: 8px;
`;
const SVideo = styled.video`
  border-radius: 8px;
`;
