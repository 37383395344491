import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';

import InlineBubble from '../messageParts/InlineBubble';

const DeletedMessage: React.FC = props => {
  return (
    <InlineBubble {...props} icon={<DeleteOutlined />}>
      삭제된 메시지입니다.
    </InlineBubble>
  );
};

export default DeletedMessage;
