import React from 'react';

import { MessageProps } from '../';
import InlineBubble from '../messageParts/InlineBubble';

import { QueueReason } from '@utils/client';

type QueueMessageProps = {
  reason: QueueReason;
  nickname: string;
} & MessageProps;

const QueueMessage: React.FC<QueueMessageProps> = ({
  reason,
  nickname,
  ...props
}) => {
  const ReasonMessage = {
    [QueueReason.ShippingCheck]: `주문하신 제품을 받지 못하셨나요? 닥터차 이용에 있어 불편을 드려 죄송하다는 말씀 먼저 드립니다. 배송 상황을 빠르게 알아보고 답변드리겠습니다!`,
    [QueueReason.ShopCheck]: `${nickname}님, 닥터차 메카닉이 요청하신 지역의 정비소를 찾고 있습니다. 👀 
  
닥터차는 고객분들이 안심할 수 있는 정비를 위해, 저희와 협력하고 있는 정비소와 전화 통화를 직접하여 고객분들께 안내드리는 방식으로 진행하고 있습니다. 
적합한 정비소를 찾기까지 길게는 하루가 걸리기도 해요. 
    
정성을 다하고 싶은 마음이니, 잠시 휴대폰을 내려 놓고 다른 일을 하셔도 좋아요. 준비되면 알람을 드릴게요!`,
    [QueueReason.PriceCheck]: `${nickname}님, 지금 닥터차 매카닉이 견적을 확인하는 중이에요. ${nickname}님에게 필요한 부품을 파악 중에 있어요.
 
꼼꼼하게 체크해서 ${nickname}님께 안내해드릴게요. 잠시 휴대폰을 내려놓고 다른 일을 하셔도 좋아요. 준비되면 알람을 드릴게요!`,
    [QueueReason.CashbackCheck]: '',
  };

  return (
    <InlineBubble {...props} icon={<></>}>
      {ReasonMessage[reason]}
    </InlineBubble>
  );
};

export default QueueMessage;
