import {
  UserOutlined,
  MonitorOutlined,
  CalendarOutlined,
  PoweroffOutlined,
  ExclamationCircleOutlined,
  LoginOutlined,
  FileTextOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  DollarOutlined,
  LogoutOutlined,
  ShopOutlined,
  WarningOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import { Avatar, Badge } from 'antd';
import React from 'react';
import { TableCellProps } from 'react-virtualized';
import styled from 'styled-components';

import { TMergedTableRow } from './';

import { Image, Video } from '@utils/client';
import {
  chatListItemTimestampFormatter,
  messageStringifier,
} from '@utils/formatters';

type LastMessageCellProps = TableCellProps;
const LastMessageCell: React.FC<LastMessageCellProps> = ({ rowData }) => {
  const { lastMessage, unreads } = rowData as TMergedTableRow;
  const messageComponent = React.useMemo(() => {
    if (!lastMessage) {
      return <SLastMessageText>전송된 메시지가 없습니다.</SLastMessageText>;
    }

    const messageBody = messageStringifier(lastMessage.data);

    switch (lastMessage.data.__typename) {
      case 'AreaInputMessage':
        return (
          <>
            <MonitorOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
      case 'EndMessage':
        return (
          <>
            <PoweroffOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
      case 'SuspendedMessage':
        return (
          <>
            <ExclamationCircleOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
      case 'EnterMessage':
        return (
          <>
            <LoginOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
      case 'EstimateMessage':
        return (
          <>
            <FileTextOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
      case 'FileMessage': {
        const fileMessage = lastMessage.data;
        const file = fileMessage.file as Image | Video;

        let icon: React.ReactNode;
        if (file) {
          icon = <PictureOutlined />;
        } else {
          icon = <VideoCameraOutlined />;
        }

        return (
          <>
            {icon}
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
      }
      case 'InvoiceMessage':
        return (
          <>
            <DollarOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'LeaveMessage':
        return (
          <>
            <LogoutOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'PlateNumberInputMessage':
        return (
          <>
            <MonitorOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'QueueMessage':
        return (
          <>
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'RestartMessage':
        return (
          <>
            <ExclamationCircleOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'CompanyMessage':
        return (
          <>
            <ShopOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'TextMessage':
        return (
          <>
            <Avatar
              size="small"
              src={lastMessage?.avatar}
              icon={<UserOutlined />}
              style={{ flexShrink: 0 }}
            />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'VinInputMessage':
        return (
          <>
            <MonitorOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      case 'AppReviewMessage':
        return (
          <>
            <AppstoreOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );

      default:
        return (
          <>
            <WarningOutlined style={{ flexShrink: 0 }} />
            <SLastMessageText>{messageBody}</SLastMessageText>
          </>
        );
    }
  }, [lastMessage]);

  return (
    <SLastMessage>
      <SLastMessageTextWrapper>{messageComponent}</SLastMessageTextWrapper>
      <STimestamp>
        {chatListItemTimestampFormatter(lastMessage?.createdAt as Date)}
      </STimestamp>
      <Badge count={unreads} overflowCount={99} />
    </SLastMessage>
  );
};

export default React.memo(
  LastMessageCell,
  (prevProps, nextProps) =>
    prevProps.rowData.id === nextProps.rowData.id &&
    prevProps.rowData.lastMessage?.id === nextProps.rowData.lastMessage?.id &&
    prevProps.rowData.unreads === nextProps.rowData.unreads,
);

const SLastMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 8px;
  padding-right: 16px;
`;
const SLastMessageTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  min-width: 0px;
`;
const SLastMessageText = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 8px;
`;
const STimestamp = styled.span`
  flex-shrink: 0;
  font-size: 12px;
  color: ${props => props.theme.colors.TEXT_MUTED};
  margin-right: 4px;
`;
