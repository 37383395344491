/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import { Input } from 'antd';
import React from 'react';
import {
  AutoSizer as _AutoSizer,
  Table as _Table,
  Column as _Column,
  TableProps,
  ColumnProps,
  AutoSizerProps,
} from 'react-virtualized';
import styled from 'styled-components';

import InquiryDateCell from '../../cells/InquiryDateCell';
import LastMessageCell from '../../cells/LastMessageCell';
import RegionCell from '../../cells/RegionCell';
import InquiryInfoCell from '../../cells/TagsCell';
import UsersCell from '../../cells/UsersCell';
import CounselSubscriber from '../../utils/CounselSubscriber';
import Loading from '../../utils/Loading';
import NoData from '../../utils/NoData';
import Placeholder from '../../utils/Placeholder';

import { MessageInterface } from '@components/CounselRoom/Message';
import MemoCell from '@components/CounselTableTabs/cells/memoCell';
import SimpleTextCell from '@components/CounselTableTabs/cells/SimpleTextCell';
import VehicleInfoCell from '@components/CounselTableTabs/cells/VehicleInfoCell';
import { TableFilter } from '@components/CounselTableTabs/CounselTableTabs';
import ErrorTemplate from '@components/ErrorTemplate';
import { useMe } from '@hooks';
import { openCounselWindow } from '@routes/CounselWindow';
import {
  CounselStatus,
  Area,
  useOngoingCounselsQuery,
  OngoingCounselsResultFragment,
  Insurance,
} from '@utils/client';
import { fuelTypeStringifer, tableRowDateFormatter } from '@utils/formatters';
import { COUNSEL_TABLE_ROW_MESSAGE } from '@utils/fragments';

const Table = _Table as unknown as React.FC<TableProps>;
const Column = _Column as unknown as React.FC<ColumnProps>;
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;

export const ONGOING_COUNSELS_RESULT = gql`
  ${COUNSEL_TABLE_ROW_MESSAGE}
  fragment ongoingCounselsResult on Counsel {
    id
    index
    status
    areas {
      zone {
        province
        district
        neighborhood
      }
    }
    type {
      id
      name
    }
    createdAt
    vehicle {
      id
      brand {
        id
        logo {
          url
        }
      }
      plateNumber
      fullModelName
      fuelType {
        base
        hybrid
      }
    }
    memo
    unreadCount
    messageConnection(last: 1) {
      __typename
      edges {
        node {
          ...CounselTableRowMessage
        }
      }
    }
    post {
      id
      hashtags {
        id
        name
      }
    }
    creator {
      id
      nickname
      avatar {
        url
      }
    }
    members {
      id
      nickname
      avatar {
        url
      }
    }
    insurance
  }
`;

export const ONGOING_COUNSELS = gql`
  ${ONGOING_COUNSELS_RESULT}
  query ongoingCounsels {
    ongoingCounsels {
      ...ongoingCounselsResult
    }
  }
`;

type TTableRowUser = {
  id: string;
  nickname: string;
  avatar?: string;
};

export type TableColumnMessage = {
  id: string;
  avatar?: React.ReactNode;
  data: MessageInterface;
  createdAt: Date;
};

export type TOngoingTableRow = {
  id: string;
  inquiryDate: string;
  index: number;
  inquiryInfo: string[];
  memo?: string;
  vehicleInfo: {
    brandLogo?: string;
    modelName?: string;
    plateNumber?: string;
  };
  areas: string[];
  lastMessage?: TableColumnMessage | null;
  unreads?: number;
  members: TTableRowUser[];
  creator?: TTableRowUser;
};

const OngoingTable = () => {
  const [filter, setFilter] = React.useState<TableFilter>({});
  const { viewOnly } = useMe();
  const { data, loading, error } = useOngoingCounselsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const areasFormatHandler = (areas: Area[]): string[] =>
    areas
      .map(area => {
        let result = '';
        if (!area.zone) {
          return result;
        }

        if (area.zone.province) {
          result += `${area.zone.province} `;
        }

        if (area.zone.district) {
          result += `${area.zone.district} `;
        }

        if (area.zone.neighborhood) {
          result += `${area.zone.neighborhood} `;
        }

        return result.slice(0, -1);
      })
      .filter(area => area !== '');

  const filteredCounsels = React.useMemo<
    OngoingCounselsResultFragment[]
  >(() => {
    if (!data) {
      return [];
    }

    if (filter.term && filter.term.length < 2) {
      return data.ongoingCounsels;
    }

    const filtered = data.ongoingCounsels.filter(counsel => {
      const vehicle = counsel.vehicle;

      if (!filter.term) {
        return true;
      }

      if (filter.term.startsWith('#')) {
        return `#${counsel.index}` === filter.term;
      }
      return (
        vehicle?.plateNumber?.includes(filter.term) ||
        vehicle?.fullModelName?.includes(filter.term) ||
        counsel.memo?.includes(filter.term)
      );
    });

    return filtered;
  }, [data, filter]);

  const sortedCounsels = React.useMemo<OngoingCounselsResultFragment[]>(() => {
    const unreads: OngoingCounselsResultFragment[] = [];
    const reads: OngoingCounselsResultFragment[] = [];

    for (const counsel of filteredCounsels) {
      if (counsel.unreadCount > 0) {
        unreads.push(counsel);
      } else {
        reads.push(counsel);
      }
    }

    const sortedUnreads = unreads.sort((a, b) => {
      const frontMessage = a.messageConnection.edges[0]?.node;
      const backMessage = b.messageConnection.edges[0]?.node;
      return backMessage?.createdAt - frontMessage?.createdAt;
    });

    const sortedReads = reads.sort((a, b) => {
      const frontMessage = a.messageConnection.edges[0]?.node;
      const backMessage = b.messageConnection.edges[0]?.node;
      return backMessage?.createdAt - frontMessage?.createdAt;
    });

    return [...sortedUnreads, ...sortedReads];
  }, [filteredCounsels]);

  const formatCounselToRow = React.useCallback(
    (counsel: OngoingCounselsResultFragment): TOngoingTableRow => {
      // date formate handling
      const lastMessageObject = counsel.messageConnection.edges[0]
        ?.node as MessageInterface;
      let lastMessage = null;
      if (lastMessageObject) {
        lastMessage = {
          id: lastMessageObject.id,
          avatar: lastMessageObject.author?.avatar?.url,
          data: lastMessageObject,
          createdAt: new Date(lastMessageObject.createdAt),
        };
      }

      const inquiryRowDate = tableRowDateFormatter(new Date(counsel.createdAt));

      const members = counsel.members.map(user => ({
        id: user.id,
        nickname: user.nickname,
        avatar: user.avatar?.url,
      }));

      const creator = counsel.creator && {
        id: counsel.creator.id,
        nickname: counsel.creator.nickname,
        avatar: counsel.creator.avatar?.url,
      };

      const inquiryInfo = [];
      counsel?.type?.name && inquiryInfo.push(counsel.type.name);
      if (counsel.insurance) {
        if (counsel.insurance === Insurance.Cdw) {
          inquiryInfo.push('자차보험');
        } else if (counsel.insurance === Insurance.Lp) {
          inquiryInfo.push('대물보험');
        } else if (counsel.insurance === Insurance.None) {
          inquiryInfo.push('자비처리');
        }
      }

      if (counsel.post?.hashtags) {
        counsel.post.hashtags.forEach(hashtag => {
          inquiryInfo.push(hashtag.name);
        });
      }

      let modelName = counsel.vehicle?.fullModelName;
      if (counsel.vehicle?.fuelType) {
        modelName += ` (${fuelTypeStringifer(counsel.vehicle.fuelType)})`;
      }

      return {
        id: counsel.id,
        index: counsel.index,
        inquiryDate: inquiryRowDate,
        memo: counsel.memo,
        unreads: counsel.unreadCount,
        lastMessage,
        inquiryInfo,
        areas: areasFormatHandler(counsel.areas as Area[]),
        vehicleInfo: {
          brandLogo: counsel.vehicle?.brand.logo?.url,
          modelName,
          plateNumber: counsel.vehicle?.plateNumber,
        },
        members,
        creator,
      };
    },
    [],
  );

  const formatedCounselsForRow: TOngoingTableRow[] = React.useMemo(
    () => sortedCounsels.map(formatCounselToRow),
    [sortedCounsels, formatCounselToRow],
  );

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  return (
    <SSearchableChatTable>
      {data &&
        data.ongoingCounsels.map(counsel => (
          <CounselSubscriber
            key={`ongoing-table-${counsel.id}`}
            counselId={counsel.id}
          />
        ))}
      <SCounsellingSearchContainer>
        <Input.Search
          allowClear
          placeholder="상담번호, 차량번호, 차종, 메카닉 메모로 검색..."
          value={filter.term}
          onChange={e => {
            setFilter({ ...filter, term: e.target.value });
          }}
          style={{ width: '400px' }}
        />
      </SCounsellingSearchContainer>
      <SChatTableContainer>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={40}
              rowHeight={160}
              rowCount={formatedCounselsForRow.length}
              rowGetter={({ index }) => formatedCounselsForRow[index]}
              noRowsRenderer={() =>
                error ? (
                  <SError error={error} message="상담을 불러오지 못했습니다" />
                ) : (
                  <Placeholder status={CounselStatus.Ongoing} />
                )
              }
              onRowDoubleClick={({ rowData }) => {
                const counsel = rowData as TOngoingTableRow;
                openCounselWindow(counsel.id);
              }}
            >
              <Column
                label="문의일시"
                dataKey="inquiryDate"
                width={64}
                minWidth={64}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => <InquiryDateCell {...props} />}
              />
              <Column
                label="번호"
                dataKey="index"
                width={64}
                minWidth={64}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <SimpleTextCell text={props.rowData['index']} />
                )}
              />
              <Column
                label="문의유형"
                dataKey="inquiryInfo"
                width={300}
                minWidth={180}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <InquiryInfoCell tags={props.rowData['inquiryInfo']} />
                )}
              />

              <Column
                label="차량정보"
                dataKey="vehicleInfo"
                width={300}
                minWidth={120}
                headerStyle={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <VehicleInfoCell vehicleInfo={props.rowData['vehicleInfo']} />
                )}
              />
              <Column
                label="문의지역"
                dataKey="areas"
                width={297}
                minWidth={90}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <RegionCell disabled={viewOnly} {...props} />
                )}
              />
              <Column
                label="최근 메세지"
                dataKey="lastMessage"
                width={642}
                minWidth={240}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                flexGrow={2}
                cellRenderer={props => <LastMessageCell {...props} />}
              />
              <Column
                label="메카닉 메모"
                dataKey="memo"
                width={300}
                minWidth={110}
                headerStyle={{ textAlign: 'center' }}
                cellRenderer={props => (
                  <MemoCell {...props} disabled={viewOnly} />
                )}
              />
              <Column
                label="참여 현황"
                dataKey={['members', 'creator']}
                width={110}
                minWidth={110}
                headerStyle={{ textAlign: 'center' }}
                style={{ textAlign: 'center' }}
                cellRenderer={props => <UsersCell {...props} />}
              />
            </Table>
          )}
        </AutoSizer>
      </SChatTableContainer>
    </SSearchableChatTable>
  );
};

export default OngoingTable;

const SSearchableChatTable = styled.div`
  flex: 1;
`;

const SCounsellingSearchContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.BORDER_BACKGROUND};
  display: flex;
  gap: 26px;
`;

const SChatTableContainer = styled.div`
  height: calc(100vh - 95px);
`;

const SError = styled(ErrorTemplate)`
  height: 100%;
  background-color: #fafafa;
`;
