import { gql } from '@apollo/client';
import { notification } from 'antd';

import { useMe } from './useMe';

import { loggedInVar } from '@utils/cache';
import { useLoginMutation, useLogoutMutation } from '@utils/client';

gql`
  mutation login($email: String!, $password: String!) {
    localLogin(email: $email, password: $password) {
      accessToken
      expiresAt
    }
  }
`;
gql`
  mutation logout {
    logout
  }
`;

function useAuth() {
  const [loginMutation] = useLoginMutation();
  const [logoutMutation] = useLogoutMutation();
  const { fetchMe, clearMe } = useMe();
  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const { data } = await loginMutation({
        variables: {
          email,
          password,
        },
        fetchPolicy: 'no-cache',
      });

      if (!data) {
        notification.error({
          message: '로그인에 실패했습니다. 개발팀에 문의해주세요.',
        });
        return;
      }

      if (data.localLogin === null) {
        notification.error({
          message: '비밀번호가 맞지 않습니다.',
        });
        return;
      }

      const token = data?.localLogin?.accessToken;
      if (token) {
        localStorage.setItem('token', token);
        const result = await fetchMe();
        if (result) {
          loggedInVar(true);
        } else {
          localStorage.removeItem('token');
          notification.error({
            message: '상담사 계정이 아닙니다.',
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('token');
      loggedInVar(false);
      await logoutMutation();
      clearMe();
    } catch (err) {
      window.location.reload();
    }
  };

  return { login, logout };
}

export default useAuth;

export type Login = ReturnType<typeof useAuth>['login'];
export type Logout = ReturnType<typeof useAuth>['logout'];
