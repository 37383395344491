import { gql } from '@apollo/client';
import React from 'react';

import EditTemplatePresenter from './EditTemplatePresenter';

import { UpdateTemplateData, useUpdateTemplateMutation } from '@utils/client';

gql`
  mutation updateTemplate($data: UpdateTemplateData!, $templateId: ID!) {
    updateTemplate(data: $data, templateId: $templateId) {
      template {
        ...TemplateDetail
      }
    }
  }
`;

export interface EditTemplateContainerProps {
  className?: string;
  templateId: string;
  onClose: () => void;
  onChangeDirectory: (directoryId: string) => void;
}
const EditTemplateContainer: React.FC<EditTemplateContainerProps> = props => {
  const [updateTemplateMutation] = useUpdateTemplateMutation();

  const updateTemplate = async (data: UpdateTemplateData) => {
    await updateTemplateMutation({
      variables: { data, templateId: props.templateId },
    });
  };

  return <EditTemplatePresenter {...props} updateTemplate={updateTemplate} />;
};

export default EditTemplateContainer;
